import { Slider } from "@mui/material";
import { ChangeEvent, Component } from "react";
import { Bar } from "react-chartjs-2";
import { Subscription } from "rxjs";
import { OutcomeByPhaseLine } from "../../services/percent-distribution-service";
import { BowlOutcome, bowlOutcomeNames } from "../../types/enums/bowl-outcome";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../types/enums/push-bracket";
import { UserPreferences } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import {
  PercentAdjustModuleType,
  PercentBiasAdjustData,
} from "../../types/simulator/modules/percent-adjust-modules";
import { GroundStats } from "../../types/stats/ground-stats";
import { cloneObject } from "../../types/util-functions";
import { buildChartOptions, propsEqual } from "../component-utils";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NumberArcSlider } from "./number-arc-slider";


const chartOptions = buildChartOptions("top", 1);

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (groundStats: GroundStats) => void;
  initialStats: GroundStats;
  comparedStats: GroundStats;
  comparedUserName: string;
  percentDistributionBiasProperty: string;
  percentDistributionConfidenceProperty: string;
  percentDistributionBiasPropertyName: string;
  userPreferences: UserPreferences;
}

interface State {
  stats: GroundStats;
  pushBracketIndex: number;
  strikeRate: number;
  outcomesByPhase: Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>;
}

type PercentDistributionValue =
  | "boundaryToRunsBias"
  | "fourToSixBias"
  | "oneToTwoBias"
  | "oneAndTwoToThreeBias";

export class GlobalPercentsDistributionModal extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      stats: null,
      pushBracketIndex: 2,
      strikeRate: 120,
      outcomesByPhase: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.percentDistributionService.outcomesByPhaseSubject.subscribe(
        (
          outcomesByPhase: Map<
            PushBracket,
            Map<BowlOutcome, OutcomeByPhaseLine>
          >
        ) => this.setState({ outcomesByPhase })
      )
    );
    this.updateState();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateState() {
    const stats: GroundStats = cloneObject(this.props.initialStats);
    this.setState({
      stats,
    });
  }

  private invalid(): boolean {
    return (
      !this.state.stats ||
      !this.state.stats[this.props.percentDistributionBiasProperty] ||
      (!this.state.stats[this.props.percentDistributionBiasProperty]
        .boundaryToRunsBias &&
        this.state.stats[this.props.percentDistributionBiasProperty]
          .boundaryToRunsBias !== 0) ||
      (!this.state.stats[this.props.percentDistributionBiasProperty]
        .fourToSixBias &&
        this.state.stats[this.props.percentDistributionBiasProperty]
          .fourToSixBias !== 0) ||
      (!this.state.stats[this.props.percentDistributionBiasProperty]
        .oneToTwoBias &&
        this.state.stats[this.props.percentDistributionBiasProperty]
          .oneToTwoBias !== 0) ||
      (!this.state.stats[this.props.percentDistributionBiasProperty]
        .oneAndTwoToThreeBias &&
        this.state.stats[this.props.percentDistributionBiasProperty]
          .oneAndTwoToThreeBias !== 0)
    );
  }

  private updateValue(property: PercentDistributionValue, value: number) {
    const percentDistributionBiasData =
      this.state.stats[this.props.percentDistributionBiasProperty];
    percentDistributionBiasData[property] = Math.round(value * 100) / 100;

    this.setState({
      stats: {
        ...this.state.stats,
        [this.props.percentDistributionBiasProperty]:
          percentDistributionBiasData,
      },
    });
  }

  private buildChartData() {
    const percents: number[] = services.percentDistributionService.getPercents(
      this.state.outcomesByPhase,
      Object.values(PushBracket)[this.state.pushBracketIndex],
      this.state.strikeRate / 100,
      this.state.stats[this.props.percentDistributionBiasProperty]
    );
    const chartData = {
      labels: Object.values(BowlOutcome).map((v) => bowlOutcomeNames[v]),
      datasets: [
        {
          yAxisID: "yAxis",
          label: "Percents Distriubtion",
          data: percents,
          backgroundColor: "#348feb",
          borderColor: "#36A2EB",
        },
      ],
    };

    if (
      this.props.percentDistributionBiasPropertyName !== "Global" &&
      !!this.props.userPreferences
    ) {
      const moduleData = this.props.userPreferences.percentAdjustModules.get(
        PercentAdjustModuleType.PERCENT_BIAS
      ) as PercentBiasAdjustData;
      const percentsWithGlobal: number[] =
        services.percentDistributionService.getPercents(
          this.state.outcomesByPhase,
          Object.values(PushBracket)[this.state.pushBracketIndex],
          this.state.strikeRate / 100,
          services.percentDistributionService.multiplyPercentDistributionBiases(
            moduleData.confidenceLimit,
            this.state.stats.globalPercentDistributionBiasData,
            this.state.stats.globalConfidence,
            this.state.stats[this.props.percentDistributionBiasProperty],
            this.state.stats[this.props.percentDistributionConfidenceProperty]
          )
        );
      chartData.datasets.push({
        yAxisID: "yAxis",
        label: "Multiplied by Global and Weighted by Confidence",
        data: percentsWithGlobal,
        backgroundColor: "#d742f5",
        borderColor: "#d742f5",
      });
    }

    if (this.props.comparedStats) {
      const comparedPercents: number[] =
        services.percentDistributionService.getPercents(
          this.state.outcomesByPhase,
          Object.values(PushBracket)[this.state.pushBracketIndex],
          this.state.strikeRate / 100,
          this.props.comparedStats[this.props.percentDistributionBiasProperty]
        );
      chartData.datasets.push({
        yAxisID: "yAxis",
        label: `${this.props.comparedUserName}'s Distribution`,
        data: comparedPercents,
        backgroundColor: "#82e060",
        borderColor: "#82e060",
      });
    }

    return chartData;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={`${this.props.percentDistributionBiasPropertyName} Strike Rate Distribution`}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.stats)}
        proceedText="OK"
        colour="#34ebb4"
      >
        <div className="percent-distribution-modal-buttons">
          <TooltipIconButton
            title="Previous"
            disabled={this.state.pushBracketIndex === 0}
            onClick={() =>
              this.setState({
                pushBracketIndex: this.state.pushBracketIndex - 1,
              })
            }
            icon="chevron_left"
          />
          {
            humanReadablePushBrackets[
              Object.values(PushBracket)[this.state.pushBracketIndex]
            ]
          }
          <TooltipIconButton
            title="Next"
            disabled={
              this.state.pushBracketIndex ===
              Object.keys(PushBracket).length - 1
            }
            onClick={() =>
              this.setState({
                pushBracketIndex: this.state.pushBracketIndex + 1,
              })
            }
            icon="navigate_next"
          />
        </div>
        {!!this.state.stats && (
          <div className="percent-distribution-modal-content">
            <div className="bias-adjustment-knobs">
              <NumberArcSlider
                initialValue={
                  this.state.stats[this.props.percentDistributionBiasProperty]
                    .boundaryToRunsBias
                }
                onChange={(value: number) =>
                  this.updateValue("boundaryToRunsBias", value)
                }
                propertyDescription="Boundaries to Runs Bias"
              />
              <NumberArcSlider
                initialValue={
                  this.state.stats[this.props.percentDistributionBiasProperty]
                    .fourToSixBias
                }
                onChange={(value: number) =>
                  this.updateValue("fourToSixBias", value)
                }
                propertyDescription="Fours to Sixes Bias"
              />
              <NumberArcSlider
                initialValue={
                  this.state.stats[this.props.percentDistributionBiasProperty]
                    .oneToTwoBias
                }
                onChange={(value: number) =>
                  this.updateValue("oneToTwoBias", value)
                }
                propertyDescription="Ones to Twos Bias"
              />
              <NumberArcSlider
                initialValue={
                  this.state.stats[this.props.percentDistributionBiasProperty]
                    .oneAndTwoToThreeBias
                }
                onChange={(value: number) =>
                  this.updateValue("oneAndTwoToThreeBias", value)
                }
                propertyDescription="Ones and Twos to Threes Bias"
              />
            </div>
            <div>
              Distribution Preview
              <hr />
              <Bar
                width="500px"
                height="400px"
                options={chartOptions}
                data={this.buildChartData()}
              />
              <div className="strike-rate-slider-container">
                <div>Strike Rate</div>
                <div className="weighting-slider">
                  <Slider
                    aria-label="Strike Rate"
                    step={1}
                    min={0}
                    max={300}
                    valueLabelDisplay="auto"
                    value={this.state.strikeRate}
                    onChange={(evt: Event, newValue: number | number[]) =>
                      this.setState({ strikeRate: newValue as number })
                    }
                    onChangeCommitted={(
                      evt: ChangeEvent,
                      newValue: number | number[]
                    ) => this.setState({ strikeRate: newValue as number })}
                  />
                  <div className="weighting-slider-label">
                    {this.state.strikeRate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CreationDialog>
    );
  }
}
