import { EventType } from "../enums/event-type";
import { Ball } from "./ball";
import { BetweenBallsEvent } from "./between-balls-event";
import { HistoricPush } from "./historic-push";

export type CricketEvent = Ball | BetweenBallsEvent;
export interface EventWithHistoricPush {
  event: CricketEvent;
  historicPush: HistoricPush;
}

export function deserializeCricketEventList(json: any): CricketEvent[] {
  const events: CricketEvent[] = [];
  json.forEach((element) => {
    events.push(deserializeCricketEvent(element));
  });
  return events;
}

export function deserializeEventWithHistoricPushList(
  json: any
): EventWithHistoricPush[] {
  const events: EventWithHistoricPush[] = [];
  json.forEach((element) => {
    events.push({
      event: deserializeCricketEvent(element.event),
      historicPush: HistoricPush.deserializeOne(element.historicPush),
    });
  });
  return events;
}

function deserializeCricketEvent(responseJSON: any): CricketEvent {
  return responseJSON.eventType === EventType.BOWL
    ? Ball.deserializeOne(responseJSON)
    : BetweenBallsEvent.deserializeOne(responseJSON);
}

export function asBall(event: CricketEvent): Ball {
  return event as Ball;
}

export function asBetweenBallsEvent(event: CricketEvent): BetweenBallsEvent {
  return event as BetweenBallsEvent;
}

export function getOver(event: CricketEvent): number {
  return Math.floor(Number(event.over));
}
