import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { StrikeRateAndWicketPercentBiasData } from "../../../types/form/ground-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export default function strikeRateAndWicketPercentBiasDataComponent(
  data: StrikeRateAndWicketPercentBiasData
) {
  const headers: string[] = [
    "Occurrences",
    "Expected Runs",
    "Actual Runs",
    "Expected Wickets",
    "Actual Wickets",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={"data-row"}>
          <TableCell>{format(data.occurrences)}</TableCell>
          <TableCell>{format(data.expectedRuns)}</TableCell>
          <TableCell>{format(data.actualRuns)}</TableCell>
          <TableCell>{format(data.expectedWickets)}</TableCell>
          <TableCell>{format(data.actualWickets)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
