import { ReplaySubject, Subject } from "rxjs";
import { Season } from "../../types/entities/season";
import { Series } from "../../types/entities/series";
import { showMessage } from "../../types/services";
import { EntityService } from "./entity-service";

export class SeriesService extends EntityService<Series> {
  public seriesForSeasonSubject: Subject<Series[]> = new ReplaySubject(1);

  public getSeriesForSeason(season: Season) {
    if (!!season) {
      const params: Map<string, string> = new Map();
      params.set("seasonId", `${season.seasonId.value}`);
      this.httpService
        .get("/api/" + this.controllerName + "/get-for-season", params)
        .then((response) => {
          const series: Series[] = Series.deserializeList(response);
          this.seriesForSeasonSubject.next(series);
        })
        .catch((reason) => {
          showMessage(`Failed to get series for season: ${reason}`, "error");
        });
    } else {
      this.seriesForSeasonSubject.next([]);
    }
  }
}
