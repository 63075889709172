import { Country } from "../enums/country";
import { UUID } from "../uuid";
import { Entity } from "./entity";

export class Team implements Entity {
  public entityId: UUID;
  public teamId: UUID;
  public name: string;
  public homeGroundId: UUID;
  public createdBy: UUID;
  public createdAt: number;
  public colour: string;
  public logo: UUID;
  public country: Country;

  public static readonly DEFAULT_COLOUR: string = "#ffffff";

  constructor(
    entityId: UUID,
    teamId: UUID,
    name: string,
    homeGroundId: UUID,
    createdBy: UUID,
    createdAt: number,
    colour: string,
    logo: UUID,
    country: Country
  ) {
    this.entityId = entityId;
    this.teamId = teamId;
    this.name = name;
    this.homeGroundId = homeGroundId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.colour = colour;
    this.logo = logo;
    this.country = country;
  }

  public toString(): string {
    return this.name;
  }

  public static deserializeList(json: any) {
    const teams: Team[] = [];
    json.forEach((element) => {
      teams.push(this.deserializeOne(element));
    });
    return teams;
  }

  public static deserializeOne(responseJSON: any): Team {
    return new Team(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.teamId),
      responseJSON.name,
      UUID.fromString(responseJSON.homeGroundId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      responseJSON.colour,
      UUID.fromString(responseJSON.logo),
      responseJSON.country
    );
  }

  public static emptyWithName(name: string): Team {
    return new Team(
      null,
      null,
      name,
      null,
      null,
      null,
      null,
      null,
      Country.UNKNOWN
    );
  }

  public static getTypeName(): string {
    return "team";
  }

  public static serialize(team: Team): any {
    return {
      entityId: team.entityId === null ? null : team.entityId.value,
      teamId: team.teamId === null ? null : team.teamId.value,
      name: team.name,
      homeGroundId: team.homeGroundId === null ? null : team.homeGroundId.value,
      createdBy: team.createdBy === null ? null : team.createdBy.value,
      createdAt: team.createdAt,
      colour: team.colour,
      logo: team.logo === null ? null : team.logo.value,
      country: team.country,
    };
  }
}
