import { TransactionType } from "../enums/transaction-type";
import { UUID } from "../uuid";

export interface Transaction {
  transactionId: UUID;
  userId: UUID;
  createdAt: number;
  transactionData: any;
  transactionType: TransactionType;
}

export const deserializeTransactions: (json: any) => Transaction[] = (json) => {
  const array: Transaction[] = [];
  json.forEach((transactionJson: any) =>
    array.push(deserializeTransaction(transactionJson))
  );
  return array;
};

export const deserializeTransaction: (json: any) => Transaction = (json) => {
  return {
    transactionId: !!json.transactionId
      ? UUID.fromString(json.transactionId)
      : null,
    userId: !!json.userId ? UUID.fromString(json.userId) : null,
    createdAt: json.createdAt,
    transactionData: json.transactionData,
    transactionType: TransactionType[json.transactionType],
  };
};
