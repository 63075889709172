import { Icon, IconButton, Tooltip } from "@mui/material";
import React, { CSSProperties } from "react";

type Props = {
  title: string;
  disabled?: boolean;
  onClick;
  onContextMenu?;
  icon;
  testId?: string;
  colour?: string;
  style?: CSSProperties;
};

export default function TooltipIconButton({
  title,
  disabled = false,
  onClick,
  onContextMenu,
  icon,
  testId = "default-test-id",
  colour = "black",
  style,
}: Props): React.JSX.Element {
  const effectiveColour = disabled ? "lightgrey" : colour;

  return (
    <Tooltip title={title}>
      <div>
        <IconButton
          data-testid={testId}
          className="tooltip-icon-button"
          disabled={disabled}
          onClick={onClick}
          onContextMenu={onContextMenu}
          aria-label={title}
        >
          <Icon style={{ ...style, color: effectiveColour }}>{icon}</Icon>
        </IconButton>
      </div>
    </Tooltip>
  );
}
