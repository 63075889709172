import { ReactNode } from "react";

export enum BattingAttackModuleType {
  ELIGIBLE_TO_BAT = "ELIGIBLE_TO_BAT",
  NEXT_BATSMAN = "NEXT_BATSMAN",
}

export class BattingAttackData {
  subType: string;
  enabled: boolean;
  award: number;
  constructor(enabled: boolean, subType: string, award: number) {
    this.enabled = enabled;
    this.subType = subType;
    this.award = award;
  }
}

export const battingAttackModuleToDataTypes: Record<
  BattingAttackModuleType,
  (any) => BattingAttackData
> = {
  ELIGIBLE_TO_BAT: (json: any) =>
    new BattingAttackData(json.enabled, json.subType, json.award),
  NEXT_BATSMAN: (json: any) =>
    new BattingAttackData(json.enabled, json.subType, json.award),
};

export const battingAttackModuleNames: Record<BattingAttackModuleType, string> =
  {
    ELIGIBLE_TO_BAT: "Eligible to bat",
    NEXT_BATSMAN: "Next batsman",
  };

export const battingAttackModuleTooltips: Record<
  BattingAttackModuleType,
  string
> = {
  ELIGIBLE_TO_BAT: "Eligible to bat",
  NEXT_BATSMAN: "Next batsman",
};

export interface BattingAttackModuleDataProperty {
  humanReadableName: string;
  description?: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const battingAttackDataProperties: Record<
  BattingAttackModuleType,
  BattingAttackModuleDataProperty[]
> = {
  ELIGIBLE_TO_BAT: [],
  NEXT_BATSMAN: [
    {
      humanReadableName: "Award for next batsman in the line-up",
      propertyName: "award",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
    },
  ],
};

export function deserializeBattingAttackModules(
  json: any
): Map<BattingAttackModuleType, BattingAttackData> {
  const battingAttackModules: Map<BattingAttackModuleType, BattingAttackData> =
    new Map();
  Object.keys(BattingAttackModuleType).forEach((moduleType) => {
    battingAttackModules.set(
      BattingAttackModuleType[moduleType],
      battingAttackModuleToDataTypes[BattingAttackModuleType[moduleType]](
        json[moduleType]
      )
    );
  });

  return battingAttackModules;
}

export function serializeBattingAttackModules(
  battingAttackModules: Map<BattingAttackModuleType, BattingAttackData>
): any {
  const result: any = {};
  battingAttackModules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });

  return result;
}
