import { Tooltip } from "@mui/material";
import React from "react";

import { IconAndTextButton } from "../navigation-bar/icon-and-text-button";

type Props = {
  isDisabled: boolean;
  onClick: () => void;
  colourOverride?: { trueColour: string; falseColour: string };
};

export default function RunSimulatorButton({
  isDisabled,
  onClick,
  colourOverride = { trueColour: "#555555", falseColour: "#f8f8ff" },
}: Props): React.JSX.Element {
  return (
    <Tooltip
      title={
        isDisabled &&
        "Check if you have at least 1 simulation enabled in settings and that the simulator is enabled."
      }
      style={{ cursor: "not-allowed" }}
    >
      <div>
        <IconAndTextButton
          title="Run Simulator"
          onClick={onClick}
          icon="refresh"
          disabled={isDisabled}
          buttonColour={
            isDisabled ? colourOverride.trueColour : colourOverride.falseColour
          }
        />
      </div>
    </Tooltip>
  );
}
