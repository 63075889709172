import { TextField } from "@mui/material";
import { Component } from "react";
import { Ground } from "../../../types/entities/ground";
import { Country, countryNames } from "../../../types/enums/country";
import { services } from "../../../types/services";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { EnumSelector } from "../entity-selectors/enum-selector";
import NumberSelector, {
  columnNumberSelector,
} from "../entity-selectors/number-selector";
import { GroundMap } from "./ground-map";

interface Props {
  value: Ground;
  open: boolean;
  label: string;
  buttonText: string;
  onProceed: Function;
  onCancel: Function;
  isNew: boolean;
}

interface State {
  value: Ground;
}

export class EditGroundComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  componentDidMount(): void {
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  private clearAndCancel() {
    this.props.onCancel(this.props.isNew);
  }

  public valid(): boolean {
    return (
      this.state.value &&
      this.state.value.country !== null &&
      this.state.value.town !== null &&
      this.state.value.town.trim().length > 0 &&
      this.state.value.name !== null &&
      this.state.value.name.trim().length > 0 &&
      this.state.value.timeZone !== null &&
      this.state.value.timeZone.trim().length > 0 &&
      this.state.value.latitude !== null &&
      this.state.value.longitude !== null &&
      this.state.value.gmtOffset !== null &&
      this.state.value.gmtOffset >= -86400 &&
      this.state.value.gmtOffset <= 86400
    );
  }

  private disabled(): boolean {
    return !this.props.isNew && this.state.value && !this.state.value.createdBy;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        onProceed={() => this.props.onProceed(this.state.value)}
        onCancel={() => this.clearAndCancel()}
        invalid={!this.valid()}
        disabled={this.disabled()}
        proceedText={this.props.buttonText}
      >
        <div className="edit-ground-dialog">
          <div className="match-format-edit">
            <div className="dialog-content">
              <span>Name:</span>
              <TextField
                value={this.state.value === null ? "" : this.state.value.name}
                onChange={(newValue) =>
                  this.handlePropertyChange(newValue, "name")
                }
                placeholder={"Ground name"}
                variant="outlined"
                disabled={this.disabled()}
              />
            </div>
            <div className="dialog-content">
              <span>Town:</span>
              <TextField
                value={this.state.value === null ? "" : this.state.value.town}
                onChange={(newValue) =>
                  this.handlePropertyChange(newValue, "town")
                }
                placeholder={"Town"}
                variant="outlined"
                disabled={this.disabled()}
              />
            </div>
            <EnumSelector
              classes="dialog-content"
              enumObject={Country}
              label="Country"
              value={
                this.state.value === null ? null : this.state.value.country
              }
              readableValues={countryNames}
              onSelect={(value) => this.handleCountryChange(value)}
              disabled={this.disabled()}
            />
            <div className="dialog-content">
              <span>Timezone:</span>
              <TextField
                value={
                  this.state.value === null ? "" : this.state.value.timeZone
                }
                onChange={(newValue) =>
                  this.handlePropertyChange(newValue, "timeZone")
                }
                placeholder={"Timezone"}
                variant="outlined"
                disabled={this.disabled()}
              />
            </div>
            <NumberSelector
              style={columnNumberSelector}
              label="GMT Offset (Seconds):"
              min={-86400}
              max={86400}
              initial={this.state.value && this.state.value.gmtOffset}
              onValid={(newValue) =>
                this.handleNumberChange(newValue, "gmtOffset")
              }
              onInvalid={(newValue) =>
                this.handleNumberChange(newValue, "gmtOffset")
              }
              disabled={this.disabled()}
            />
          </div>
          <div className="map-container">
            <GroundMap
              longitude={
                this.state.value === null ? null : this.state.value.longitude
              }
              latitude={
                this.state.value === null ? null : this.state.value.latitude
              }
              onSelect={(position) => this.onPositionSelected(position)}
              disabled={this.disabled()}
            />
            <div className="dialog-content">
              <div className="match-format-edit-item">
                <span>
                  {"Latitude: " +
                    (this.state.value &&
                      this.state.value.latitude &&
                      this.state.value.latitude.toFixed(3))}
                </span>
              </div>
              <div className="match-format-edit-item">
                <span>
                  {"Longitude: " +
                    (this.state.value &&
                      this.state.value.longitude &&
                      this.state.value.longitude.toFixed(3))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CreationDialog>
    );
  }

  private handleNumberChange(newValue, property) {
    this.setState({
      value: {
        ...this.state.value,
        [property]: newValue,
      },
    });
  }

  private onPositionSelected(position: [number, number]) {
    this.setState({
      value: {
        ...this.state.value,
        latitude: position[0],
        longitude: position[1],
      },
    });
    services.groundService.getLocationData(this.state.value).then((updated) => {
      this.setState({ value: updated });
    });
  }

  private handleCountryChange(country) {
    this.setState({
      value: {
        ...this.state.value,
        country: country,
      },
    });
  }

  private handlePropertyChange(newValue, property) {
    this.setState({
      value: {
        ...this.state.value,
        [property]: newValue.target.value,
      },
    });
  }
}
