import { AveragePositionData } from "../../../types/form/player-form-modules";

export const ordersComponent = (data: AveragePositionData) => {
  return (
    <div>
      <div>Orders:</div>
      <div>{data.orders.join(", ")}</div>
    </div>
  );
};
