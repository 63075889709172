import { Icon } from "@mui/material";
import { useEffect } from "react";
import "../App.css";
import { services } from "../types/services";

export interface Error {
  message: string;
  stack: string;
}

export interface Props {
  error: any;
}

export default function ErrorPage({ error }: Props): React.JSX.Element {
  console.log(error);

  useEffect(() => {
    console.error(error);
    services.loggingService.logException(error);
  }, [error]);

  return (
    <div className="error-page full-height">
      <Icon color="error" fontSize="large">
        report_gmailerrorred
      </Icon>
      <div className="error-title">Oops, something went wrong!</div>
      <div className="error-title-2">
        Please refresh the page and try again, if the problem persists contact{" "}
        <a href="mailto:info@fullpush.co.uk">info@fullpush.co.uk</a>.
      </div>
      <div className="error">
        <div className="error-text">{error && error.message}</div>
        <div className="error-stack">
          {error &&
            error.stack &&
            error.stack
              .split(" at ")
              .map((line, index) => (
                <div key={`Error-Line-${index}`}>{line}</div>
              ))}
        </div>
      </div>
    </div>
  );
}
