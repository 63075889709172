import {
  Autocomplete,
  createFilterOptions,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Component, createRef, ReactNode } from "react";
import { Observable, Subject, Subscription } from "rxjs";
import { EntityService } from "../../../services/entity-services/entity-service";
import { Entity } from "../../../types/entities/entity";
import { showMessage } from "../../../types/services";
import { UUID } from "../../../types/uuid";
import { levenshteinDistance } from "../../component-utils";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";

export interface Option<T> {
  object: T;
  new: boolean;
  optionText: string;
}

interface Props<T> {
  value?: T;
  label?: string;
  options?: Observable<T[]>;
  onSelect?: (entity: Entity) => void;
  onCreateOrEdit?: (isNew: boolean, entity: T) => void;
  optionService?: EntityService<T>;
  newEntityRequiresModal?: boolean;
  canAddNew?: boolean;
  canEdit?: boolean;
  canType?: boolean;
  canClear?: boolean;
  disabled?: boolean;
  maxDropdownSize?: number;
  classes?: string;
  renderer?: (props: any, option: Option<T>) => ReactNode;
  focussed?: boolean;
  placeholder?: string;
}

interface State<T> {
  options: Option<T>[];
}

export abstract class EntityAutoSelector<T extends Entity> extends Component<
  Props<T>,
  State<T>
> {
  private selectRef;

  static defaultProps = {
    value: null,
    label: "Select entity",
    options: new Subject(),
    onSelect: () => {},
    onCreateOrEdit: () => {},
    optionsService: null,
    newEntityRequiresModal: true,
    canAddNew: true,
    canEdit: true,
    canType: true,
    canClear: true,
    disabled: false,
    maxDropdownSize: 10,
    classes: "auto-select",
    renderer: (props, option) => <div {...props}>{option.optionText}</div>,
    focussed: false,
    placeholder: null,
  };

  private readonly newEntrySubstring = `Add new ${this.props.optionService
    .getClassToCreate()
    .getTypeName()}: `;
  private readonly filter: (options: Option<T>[], params) => Option<T>[] =
    createFilterOptions({ limit: this.props.maxDropdownSize });
  private autocompleteKey: string;
  private subscriptions: Subscription[] = [];

  public constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.selectRef = createRef();
    this.updateAutocompleteKey();
  }

  componentDidMount(): void {
    this.updateOptions();
    this.updateAutocompleteKey();
  }

  componentDidUpdate(prevProps): void {
    if (prevProps.options !== this.props.options) {
      this.updateOptions();
    }
    if (prevProps.focussed !== this.props.focussed && this.props.focussed) {
      this.selectRef.current.focus();
    }
    this.updateAutocompleteKey();
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateOptions() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
    this.subscriptions = [];
    this.subscriptions.push(
      this.props.options.subscribe((results: T[]) => {
        const optionArray: Option<T>[] = [];
        results.forEach((option: T) => optionArray.push(this.optionOf(option)));
        this.setState({ options: optionArray });
      })
    );
  }

  private optionOf(entity: T): Option<T> {
    return entity === null || entity === undefined
      ? null
      : { object: entity, new: false, optionText: entity.toString() };
  }

  private addEntity(entity: T) {
    this.props.optionService
      .add(entity)
      .then((entityWithId: T) => {
        this.props.onSelect(entityWithId);
      })
      .catch((reason) =>
        showMessage(`Failed to add entity: ${reason}`, "error")
      );
  }

  public render() {
    return (
      <div className={this.props.classes}>
        {!!this.props.label && <span>{this.props.label}</span>}
        <div className="dialog-content-sideways-item">
          {this.props.canType && (
            <Autocomplete
              key={this.autocompleteKey}
              value={this.optionOf(this.props.value)}
              options={this.state.options}
              blurOnSelect={true}
              onKeyDown={(event) => this.onKeyDown(event)}
              onChange={(event, newValue) => this.handleChange(event, newValue)}
              clearOnEscape={true}
              disableClearable={!this.props.canClear}
              filterOptions={(options, params) =>
                this.filterOptions(options, params)
              }
              isOptionEqualToValue={(option: Option<T>, value: Option<T>) =>
                option.object === value.object
              }
              getOptionLabel={(option) => this.getOptionText(option)}
              className="autocomplete"
              disabled={this.props.disabled}
              renderOption={(props, option, state) =>
                this.props.renderer(props, option)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    this.props.placeholder ||
                    `Select ${this.props.optionService
                      .getClassToCreate()
                      .getTypeName()}`
                  }
                  variant="outlined"
                />
              )}
            />
          )}
          {!this.props.canType && (
            <Select
              id="entity-select"
              className="select"
              value={
                this.props.value ? (this.props.value as any).entityId.value : ""
              }
              onChange={(event, newValue) =>
                this.handleSelection(event, newValue)
              }
              disabled={this.props.disabled}
              inputRef={this.selectRef}
              variant="standard"
            >
              {this.state.options.map((option: Option<T>) => {
                return (
                  <MenuItem
                    value={(option.object as any).entityId.value}
                    key={(option.object as any).entityId.value}
                  >
                    {this.props.renderer({}, option)}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          {this.props.canEdit && (
            <TooltipIconButton
              title="Edit"
              disabled={this.props.value === null}
              onClick={() => this.props.onCreateOrEdit(false, this.props.value)}
              icon="edit_circle"
            />
          )}
        </div>
      </div>
    );
  }

  /**
   * AUTOCOMPLETE FUNCTIONS
   */
  private getOptionText(option: Option<T>): string {
    if (
      option === null ||
      option.object === null ||
      option === undefined ||
      option.object === undefined
    ) {
      return "";
    } else {
      return option.object.toString();
    }
  }

  private filterOptions(options: Option<T>[], params): any[] {
    const sorted: Option<T>[] = options.sort((a: Option<T>, b: Option<T>) => {
      return (
        levenshteinDistance(
          a.optionText.toLowerCase(),
          params.inputValue.toLowerCase()
        ) -
        levenshteinDistance(
          b.optionText.toLowerCase(),
          params.inputValue.toLowerCase()
        )
      );
    });
    const filtered = this.filter(sorted, params);

    if (params.inputValue !== "" && this.props.canAddNew) {
      filtered.push({
        object: this.props.optionService
          .getClassToCreate()
          .emptyWithName(params.inputValue),
        optionText: `${this.newEntrySubstring}${params.inputValue}`,
        new: true,
      });
    }
    return filtered;
  }

  private onKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  private handleChange(event, newValue) {
    if (newValue && newValue.new) {
      const entity: T = this.props.optionService
        .getClassToCreate()
        .emptyWithName(
          newValue.optionText.substring(this.newEntrySubstring.length)
        );
      if (this.props.newEntityRequiresModal) {
        this.props.onCreateOrEdit(true, entity);
      } else {
        this.addEntity(entity);
      }
    } else {
      const entity: T = newValue !== null ? newValue.object : null;
      this.props.onSelect(entity);
    }
  }

  private handleSelection(event, newValue) {
    const entity: Option<T> = this.state.options.find(
      (option) => (option.object as any).entityId.value === newValue.props.value
    );
    this.props.onSelect(entity === undefined ? null : entity.object);
  }

  private updateAutocompleteKey() {
    this.autocompleteKey = UUID.randomUUID().value;
  }
}
