export enum BowlOutcome {
  DOT = "DOT",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  SIX = "SIX",
}

export const bowlOutcomeNames: Record<BowlOutcome, string> = {
  DOT: "Dot",
  ONE: "One",
  TWO: "Two",
  THREE: "Three",
  FOUR: "Four",
  SIX: "Six",
};
