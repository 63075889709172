import "../App.css";
import { Component } from "react";
import { Icon } from "@mui/material";

export class NoRolesPage extends Component {
  public render() {
    return (
      <div className="no-roles-page full-height">
        <Icon color="error" fontSize="large">
          report_gmailerrorred
        </Icon>
        <div className="error-title">Oops, you have no permissions!</div>
        <div className="error-title-2">Please contact your administrator</div>
      </div>
    );
  }
}
