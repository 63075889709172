import { ReplaySubject, Subject } from "rxjs";
import { BetfairEvent } from "../types/betfair/betfair-event";
import {
  BetfairMessage,
  BetfairPrice,
  BetfairPriceUpdateMessage,
  MarketType,
} from "../types/betfair/betfair-message";
import { AutoBallEntryMessage } from "../types/betfair/bowl-entry";
import { SubscribedEvents } from "../types/betfair/subscribed-events";
import {
  BetfairStatus,
  BetfairSubscription,
  SubscriptionStatus,
} from "../types/enums/betfair-status";
import { services, showMessage } from "../types/services";
import { UUID } from "../types/uuid";
import { HttpService } from "./http-service";

export class BetfairService {
  private httpService: HttpService;

  public betfairWebSocketLastHeartBeat: number = null;
  public currentBetfairEvents: Map<string, BetfairMessage[]> = new Map();
  public currentBetfairEventsSubject: Subject<BetfairMessage[]> =
    new ReplaySubject(1);
  public latestAutoBallEntryMessageSubject: Subject<AutoBallEntryMessage> =
    new ReplaySubject(1);
  public statusSubject: Subject<BetfairStatus> = new ReplaySubject(1);
  public priceSubject: Subject<Map<MarketType, Map<string, BetfairPrice>>> =
    new ReplaySubject(1);
  public betfairWebSocketLastHeartBeatSubject: Subject<number> =
    new ReplaySubject(1);
  public matchSubscriptionsSubject: Subject<Map<string, BetfairSubscription>> =
    new ReplaySubject(1);
  public matchSubscriptions: Map<string, BetfairSubscription> = new Map();
  private awaitingSubscription: String = null;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  private baseUrl: string = "/api/betfair-controller";

  public init() {
    this.httpService
      .get(this.baseUrl + "/get-subscriptions")
      .then((response: any) => {
        this.matchSubscriptions = this.deserializeSubscriptionMap(response);
        this.matchSubscriptionsSubject.next(this.matchSubscriptions);
      })
      .catch((reason) => {
        showMessage(`Failed to get betfair subscriptions: ${reason}`, "error");
        this.matchSubscriptions = new Map();
        this.matchSubscriptionsSubject.next(this.matchSubscriptions);
      });
  }

  public getEvents(): Promise<BetfairEvent[]> {
    return this.httpService
      .get(this.baseUrl + "/get-events")
      .then((response: any[]) => {
        const events: BetfairEvent[] = [];
        response.forEach((element: any) => {
          events.push({
            id: element.id,
            name: element.name,
            countryCode: element.countryCode,
            timezone: element.timezone,
            venue: element.venue,
            openDate: element.openDate,
          });
        });
        return events;
      })
      .catch((reason) => {
        showMessage(`Failed to get betfair event list: ${reason}`, "error");
        return [];
      });
  }

  public subscribe(eventId: string): Promise<boolean> {
    const matchId: UUID = services.currentGameService.currentMatchId;
    this.awaitingSubscription = eventId;
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("eventId", eventId);
    return this.httpService
      .get(this.baseUrl + "/subscribe", params)
      .catch((reason) => {
        showMessage(`Failed to subscribe to betfair event: ${reason}`, "error");
        this.awaitingSubscription = null;
      });
  }

  public unsubscribe(): Promise<boolean> {
    const matchId: UUID = services.currentGameService.currentMatchId;
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return this.httpService
      .get(this.baseUrl + "/unsubscribe", params)
      .catch((reason) =>
        showMessage(`Failed to unsubscribe: ${reason}`, "error")
      );
  }

  public getMarketHistory(
    marketType: MarketType,
    runner: string
  ): Promise<Map<string, Map<number, number>>> {
    const matchId: UUID = services.currentGameService.currentMatchId;
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("runner", runner);
    params.set("marketType", marketType);
    return this.httpService
      .get("/api/stored-odds-controller/market-history", params)
      .then((response) => this.deserializeMarketHistory(response))
      .catch((reason) => {
        showMessage(`Failed to get market history: ${reason}`, "error");
        return null;
      });
  }

  private deserializeMarketHistory(
    response: any
  ): Map<string, Map<number, number>> {
    const map: Map<string, Map<number, number>> = new Map();
    Object.keys(response).forEach((timeKey) => {
      const dataPoint = response[timeKey];
      Object.keys(dataPoint).forEach((userKey) => {
        let userMap;
        if (!!map.get(userKey)) {
          userMap = map.get(userKey);
        } else {
          userMap = new Map();
        }

        userMap.set(Number(timeKey), Number(dataPoint[userKey]));
        map.set(userKey, userMap);
      });
    });

    return map;
  }

  public togglePause() {
    const matchId: UUID = services.currentGameService.currentMatchId;
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return this.httpService
      .get(this.baseUrl + "/toggle-pause", params)
      .catch((reason) =>
        showMessage(
          `Failed to toggle pause betfair subscription: ${reason}`,
          "error"
        )
      );
  }

  public changeBetfairEventId(eventId: string) {
    this.latestAutoBallEntryMessageSubject.next(null);
    this.priceSubject.next(null);
    if (eventId !== null) {
      services.betfairService
        .subscribe(eventId)
        .then(() => {
          if (this.currentBetfairEvents.get(eventId) === undefined) {
            this.currentBetfairEvents.set(eventId, []);
          }
          this.currentBetfairEventsSubject.next(
            this.currentBetfairEvents.get(eventId)
          );
        })
        .catch(() => {
          showMessage(`Failed to subscribe to event Id ${eventId}`, "error");
        });
    } else {
      this.unsubscribe();
    }
  }

  public addBetfairEventMessage(msg: any) {
    const event: BetfairMessage = BetfairMessage.deserialize(msg);
    const payload: any = JSON.parse(event.payload);

    if (event.eventId === "event-id-update") {
      const acceptedEventIds = (payload as SubscribedEvents).events;

      if (
        this.awaitingSubscription != null &&
        acceptedEventIds.find(
          (event) => event === this.awaitingSubscription
        ) === undefined
      ) {
        showMessage("Failed to subscribe", "error");
        this.unsubscribe();
      }
      this.awaitingSubscription = null;
    }

    const currentSubscription =
      !!services.currentGameService.currentMatchId &&
      this.matchSubscriptions.get(
        services.currentGameService.currentMatchId.value
      );
    if (!!currentSubscription) {
      if (
        payload.status !== undefined &&
        event.eventId === currentSubscription.eventId
      ) {
        this.statusSubject.next(payload.status as BetfairStatus);
      } else if (event.eventId === currentSubscription.eventId) {
        const events: BetfairMessage[] =
          this.currentBetfairEvents.get(event.eventId) || [];
        events.push(event);
        this.currentBetfairEvents.set(event.eventId, events);
        this.currentBetfairEventsSubject.next(
          this.currentBetfairEvents.get(event.eventId)
        );
      }
    }
    this.betfairWebSocketLastHeartBeat = event.createdAt;
    this.betfairWebSocketLastHeartBeatSubject.next(
      this.betfairWebSocketLastHeartBeat
    );
  }

  public addBetfairPriceMessage(msg: any) {
    const event: BetfairPriceUpdateMessage =
      BetfairPriceUpdateMessage.deserialize(msg);

    const currentSubscription =
      !!services.currentGameService.currentMatchId &&
      this.matchSubscriptions.get(
        services.currentGameService.currentMatchId.value
      );
    if (!!currentSubscription) {
      if (event.eventId === currentSubscription.eventId) {
        this.priceSubject.next(event.subscription.latestPrices);
      }
    }
  }

  public handleSubscriptionMessage(message: any) {
    if (!message.subscription) {
      this.matchSubscriptions.delete(message.matchId);
    } else {
      this.matchSubscriptions.set(
        message.matchId,
        this.deserializeBetfairSubscription(message.subscription)
      );
    }
    this.matchSubscriptionsSubject.next(this.matchSubscriptions);
  }

  public handleAutoBallEntryMessage(message: any): void {
    if (
      services.currentGameService.currentMatchId &&
      services.currentGameService.currentMatchId.value === message.matchId
    ) {
      showMessage(
        message.message,
        message.severity === "INFO" ? "info" : "warning"
      );
      this.latestAutoBallEntryMessageSubject.next({
        message: message.message,
        severity: message.severity,
      });
    }
  }

  private deserializeSubscriptionMap(
    response: any
  ): Map<string, BetfairSubscription> {
    const map = new Map();
    Object.keys(response).forEach((matchId: string) => {
      map.set(matchId, this.deserializeBetfairSubscription(response[matchId]));
    });
    return map;
  }

  private deserializeBetfairSubscription(json: any): BetfairSubscription {
    return {
      eventId: json.eventId,
      status: SubscriptionStatus[json.status],
    };
  }
}
