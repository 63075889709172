import { Match } from "./match";
import { Squad } from "./squad";
import { Team } from "./team";

export class RecentSquad {
  public thisTeam: Team;
  public thatTeam: Team;
  public squad: Squad;
  public match: Match;

  constructor(squad: Squad, thisTeam: Team, thatTeam: Team, match: Match) {
    this.squad = squad;
    this.thisTeam = thisTeam;
    this.thatTeam = thatTeam;
    this.match = match;
  }

  public static deserializeList(responseJSON: any): RecentSquad[] {
    const list: RecentSquad[] = [];
    responseJSON.forEach((json: any) => list.push(this.deserializeOne(json)));
    return list;
  }

  public static deserializeOne(responseJSON: any): RecentSquad {
    return new RecentSquad(
      Squad.deserializeOne(responseJSON.squad),
      Team.deserializeOne(responseJSON.thisTeam),
      Team.deserializeOne(responseJSON.thatTeam),
      Match.deserializeOne(responseJSON.match)
    );
  }
}
