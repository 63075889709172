export enum InningsSimulationQuestionStatistic {
  WINS = "WINS",
  LOSES = "LOSES",
  SCORE = "SCORE",
  WICKETS = "WICKETS",
  SURGE_SCORE = "SURGE_SCORE",
  SURGE_WICKETS = "SURGE_WICKETS",
  POWERPLAY_SCORE = "POWERPLAY_SCORE",
  POWERPLAY_WICKETS = "POWERPLAY_WICKETS",
  SCORE_AT_OVER = "SCORE_AT_OVER",
  WICKETS_AT_OVER = "WICKETS_AT_OVER",
  RUNS_AT_WICKET = "RUNS_AT_WICKET",
  FOURS = "FOURS",
  SIXES = "SIXES",
  FIFTIES = "FIFTIES",
  BOUNDARIES = "BOUNDARIES",
  NO_BALLS = "NO_BALLS",
  WIDES = "WIDES",
  BYES = "BYES",
  LEG_BYES = "LEG_BYES",
  EXTRAS = "EXTRAS",
  SUPREMACY = "SUPREMACY",
}

export enum PlayerSimulationQuestionStatistic {
  SCORE = "SCORE",
  BOUNDARIES = "BOUNDARIES",
  BALLS_FACED = "BALLS_FACED",
  STRIKE_RATE = "STRIKE_RATE",
}

export const inningsSimulationQuestionStatisticNames: Record<
  InningsSimulationQuestionStatistic,
  string
> = {
  WINS: "Wins (inc. draws)",
  LOSES: "Loses (inc. draws)",
  SCORE: "Total Score",
  WICKETS: "Wickets",
  SURGE_SCORE: "Surge Runs",
  SURGE_WICKETS: "Surge Wickets",
  POWERPLAY_SCORE: "Powerplay Runs",
  POWERPLAY_WICKETS: "Powerplay Wickets",
  SCORE_AT_OVER: "Score at Over",
  WICKETS_AT_OVER: "Wickets at Over",
  RUNS_AT_WICKET: "Runs at Wicket Fall",
  FOURS: "Fours",
  SIXES: "Sixes",
  FIFTIES: "Fifties",
  BOUNDARIES: "Boundaries",
  NO_BALLS: "No Balls",
  WIDES: "Wides",
  BYES: "Byes",
  LEG_BYES: "Leg Byes",
  EXTRAS: "Extras",
  SUPREMACY: "Supremacy",
};

export const playerSimulationQuestionStatisticNames: Record<
  PlayerSimulationQuestionStatistic,
  string
> = {
  SCORE: "Score",
  BOUNDARIES: "Boundaries",
  BALLS_FACED: "Balls Faced",
  STRIKE_RATE: "Strike Rate",
};

export const inningsSimulationQuestionStatisticRequiresInputs: Record<
  InningsSimulationQuestionStatistic,
  [boolean, boolean]
> = {
  // [requires over input, requires wicket input]
  WINS: [false, false],
  LOSES: [false, false],
  SCORE: [false, false],
  WICKETS: [false, false],
  SURGE_SCORE: [false, false],
  SURGE_WICKETS: [false, false],
  POWERPLAY_SCORE: [false, false],
  POWERPLAY_WICKETS: [false, false],
  SCORE_AT_OVER: [true, false],
  WICKETS_AT_OVER: [true, false],
  RUNS_AT_WICKET: [false, true],
  FOURS: [false, false],
  SIXES: [false, false],
  FIFTIES: [false, false],
  BOUNDARIES: [false, false],
  NO_BALLS: [false, false],
  WIDES: [false, false],
  BYES: [false, false],
  LEG_BYES: [false, false],
  EXTRAS: [false, false],
  SUPREMACY: [false, false],
};

export const inningsSimulationQuestionStatisticWithoutNumbers: InningsSimulationQuestionStatistic[] =
  [
    InningsSimulationQuestionStatistic.WINS,
    InningsSimulationQuestionStatistic.LOSES,
  ];

export const unavailableMedianInningsSimulatorQuestionStatistics: InningsSimulationQuestionStatistic[] =
  [
    InningsSimulationQuestionStatistic.WINS,
    InningsSimulationQuestionStatistic.LOSES,
  ];
