import { MenuItem, Select } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { Team } from "../../types/entities/team";
import { services } from "../../types/services";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { UUID } from "../../types/uuid";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onSave: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
}

interface State {
  teams: Team[];
  homeTeam: Team;
}

export class HomeAdvantageComponent extends Component<Props, State> {
  BATTING_STATS_TO_STEAL: string[] = [
    "homeAdvantageBattingStrikeRateBias",
    "homeAdvantageBattingWicketBias",
  ];
  BOWLING_STATS_TO_STEAL: string[] = [
    "homeAdvantageBowlingStrikeRateBias",
    "homeAdvantageBowlingWicketBias",
  ];

  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      teams: null,
      homeTeam: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.currentGameService.teamsSubject.subscribe((teams: Team[]) => {
        this.setState({ teams });
      })
    );
  }

  private propsLoaded(): boolean {
    return !!this.props.currentStats;
  }

  private onClickHandler(updates: string[]) {
    updates.forEach((update) => {
      this.updateStats(update, this.props.comparedStats.matchStats[update]);
    });
  }

  private updateStats(property: string, value) {
    const updatedStats: MatchStatsWrapper = this.props.currentStats;
    updatedStats.matchStats[property] = value;
    this.props.onSave(updatedStats, true);
  }

  private updateHomeTeam(value) {
    const teamId = value !== "Neutral" ? UUID.fromString(value) : null;
    const updatedStats: MatchStatsWrapper = this.props.currentStats;
    updatedStats.matchStats.homeTeamId = teamId;
    this.props.onSave(updatedStats, true);
  }

  public render() {
    const homeTeam: UUID =
      !!this.props.currentStats &&
      !!this.props.currentStats.matchStats &&
      this.props.currentStats.matchStats.homeTeamId;

    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">Home Advantage</div>

            <div className="home-advantage-team-select">
              Home Team:
              <Select
                className="enum-multi-select"
                value={!!homeTeam ? homeTeam.value : "Neutral"}
                placeholder={"Home Team"}
                onChange={(selection) =>
                  this.updateHomeTeam(selection.target.value)
                }
                disabled={false}
                variant="standard"
              >
                {!!this.state.teams &&
                  this.state.teams.map((team: Team, index: number) => (
                    <MenuItem
                      key={`team-name-${index}`}
                      value={team.teamId.value}
                    >
                      {team.name}
                    </MenuItem>
                  ))}
                <MenuItem key={"team-name-null"} value={"Neutral"}>
                  Neutral
                </MenuItem>
              </Select>
            </div>

            <div className="home-advantage-batting-and-bowling">
              <div className="home-advantage-section">
                <div className="home-advantage-subtitle">Batting</div>
                <div>
                  <div className="home-advantage-input">
                    <NumberSelector
                      label="Strike Rate Bias"
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .homeAdvantageBattingStrikeRateBias
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "homeAdvantageBattingStrikeRateBias",
                          newValue
                        )
                      }
                    />
                    <NumberSelector
                      label="Wicket Bias"
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .homeAdvantageBattingWicketBias
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "homeAdvantageBattingWicketBias",
                          newValue
                        )
                      }
                    />
                  </div>

                  {!!this.props.comparedStats && (
                    <div className="comparison">
                      <StealStatsHeadingAndButton
                        comparedUserName={this.props.comparedUserName}
                      >
                        <StealStatsButton
                          comparedUserName={this.props.comparedUserName}
                          disabled={areStatsEqual(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.BATTING_STATS_TO_STEAL,
                            "matchStats"
                          )}
                          tooltipMessage={getToolTipMessage(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.BATTING_STATS_TO_STEAL,
                            this.props.comparedUserName,
                            "matchStats"
                          )}
                          onClickHandler={this.onClickHandler.bind(
                            this,
                            this.BATTING_STATS_TO_STEAL
                          )}
                        />
                      </StealStatsHeadingAndButton>

                      <div className="match-stats-weights">
                        <NumberComparison
                          label="Strike Rate Bias"
                          originalValue={
                            this.props.currentStats.matchStats
                              .homeAdvantageBattingStrikeRateBias
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .homeAdvantageBattingStrikeRateBias
                          }
                        />
                        <NumberComparison
                          label="Wicket Bias"
                          originalValue={
                            this.props.currentStats.matchStats
                              .homeAdvantageBattingWicketBias
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .homeAdvantageBattingWicketBias
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="home-advantage-section">
                <div className="home-advantage-subtitle">Bowling</div>
                <div>
                  <div className="home-advantage-input">
                    <NumberSelector
                      label="Strike Rate Bias"
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .homeAdvantageBowlingStrikeRateBias
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "homeAdvantageBowlingStrikeRateBias",
                          newValue
                        )
                      }
                    />
                    <NumberSelector
                      label="Wicket Bias"
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .homeAdvantageBowlingWicketBias
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "homeAdvantageBowlingWicketBias",
                          newValue
                        )
                      }
                    />
                  </div>

                  {!!this.props.comparedStats && (
                    <div className="comparison">
                      <StealStatsHeadingAndButton
                        comparedUserName={this.props.comparedUserName}
                      >
                        <StealStatsButton
                          comparedUserName={this.props.comparedUserName}
                          disabled={areStatsEqual(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.BOWLING_STATS_TO_STEAL,
                            "matchStats"
                          )}
                          tooltipMessage={getToolTipMessage(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.BOWLING_STATS_TO_STEAL,
                            this.props.comparedUserName,
                            "matchStats"
                          )}
                          onClickHandler={this.onClickHandler.bind(
                            this,
                            this.BOWLING_STATS_TO_STEAL
                          )}
                        />
                      </StealStatsHeadingAndButton>

                      <div className="match-stats-weights">
                        <NumberComparison
                          label="Strike Rate Bias"
                          originalValue={
                            this.props.currentStats.matchStats
                              .homeAdvantageBowlingStrikeRateBias
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .homeAdvantageBowlingStrikeRateBias
                          }
                        />
                        <NumberComparison
                          label="Wicket Bias"
                          originalValue={
                            this.props.currentStats.matchStats
                              .homeAdvantageBowlingWicketBias
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .homeAdvantageBowlingWicketBias
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
