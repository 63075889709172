import { Button, CircularProgress } from "@mui/material";

interface Props {
  onProceed: () => void;
  onCancel: () => void;
  disabled: boolean;
  invalid: boolean;
  cancelText: string;
  proceedText: string;
  colour: string;
  disabledText: string;
  invalidText: string;
  loading: boolean;
  showCancelButton: boolean;
  autofocus: boolean;
}

export function DialogFooter({
  onProceed,
  onCancel,
  disabled,
  invalid,
  cancelText,
  proceedText,
  colour,
  disabledText,
  invalidText,
  loading,
  showCancelButton,
  autofocus,
}: Props): React.JSX.Element {
  return (
    <div>
      <div style={{ backgroundColor: colour }}>
        <div className="dialog-error-message-container">
          {disabled && disabledText}
          {invalid && invalidText}
        </div>
        {!loading && (
          <div className="dialog-button-container">
            {showCancelButton && (
              <Button variant="contained" color="secondary" onClick={onCancel}>
                {cancelText}
              </Button>
            )}
            {!disabled && (
              <Button
                variant="contained"
                color="primary"
                disabled={invalid}
                onClick={onProceed}
                autoFocus={autofocus}
              >
                {proceedText}
              </Button>
            )}
          </div>
        )}
        {loading && (
          <div className="dialog-button-container">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
