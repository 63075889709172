import { Switch, Typography } from "@mui/material";

import { services } from "../../types/services";
import {
  BetSource,
  betSourceReadableNames,
} from "../../types/stored-bets/bet-source";

interface Props {
  alertsEnabled: Map<BetSource, boolean>;
  betSource: BetSource;
}

export default function BetSourceScrapeTitle({
  alertsEnabled,
  betSource,
}: Readonly<Props>) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {betSourceReadableNames[betSource]}
      </Typography>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        Notifications:
        <Switch
          checked={alertsEnabled.get(betSource)}
          onChange={() =>
            services.betScraperService.toggleAlertsEnabled(betSource)
          }
        />
      </div>
    </div>
  );
}
