import { Component } from "react";
import { services, showMessage } from "../../../types/services";
import { Entity } from "../../../types/entities/entity";
import { CreateSquadModal } from "./create-squad-modal";
import { Squad } from "../../../types/entities/squad";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { Team } from "../../../types/entities/team";
import { Ground } from "../../../types/entities/ground";
import { MatchFormat } from "../../../types/entities/match-format";
import { Series } from "../../../types/entities/series";
import { Modal } from "../../../types/enums/modal";
import { CreateMatchModal } from "./create-match-modal";
import { Player } from "../../../types/entities/player";
import { propsEqual } from "../../component-utils";
import { DecideMatchCreationSourceModal } from "./decide-match-creation-source-modal";
import { CricInfoMatchCreationModal } from "./cricinfo-match-creation-modal";
import { CricInfoMatch } from "../../../services/scraper-service";

export interface Selection {
  entity: Entity;
  role: string;
}

interface Props {
  open: boolean;
  onClose: Function;
}

interface State {
  openModal: Modal;
  team1: Team;
  team2: Team;
  ground: Ground;
  series: Series;
  matchFormat: MatchFormat;
  squad1: Squad;
  squad2: Squad;
  allPlayers: Player[];
  selectedPlayers: Player[];
}

export class MatchCreationModalManager extends Component<Props, State> {
  public subscriptions: Subscription[] = [];
  public entitySelectedSubject: Subject<Selection>;

  constructor(props) {
    super(props);
    this.state = this.originalState();
  }

  private originalState() {
    return {
      openModal: Modal.MATCH_CREATION_SOURCE_DECIDER,
      team1: null,
      team2: null,
      ground: null,
      series: null,
      matchFormat: null,
      squad1: null,
      squad2: null,
      allPlayers: [],
      selectedPlayers: [],
    };
  }

  componentDidMount() {
    this.updateSubscriptions();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateSubscriptions();
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateSubscriptions() {
    this.entitySelectedSubject = new ReplaySubject(1);
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
    this.subscriptions = [];

    this.subscriptions.push(
      this.entitySelectedSubject.subscribe((selection: Selection) => {
        this.updateEntityInState(selection.entity, selection.role);
      })
    );

    this.subscriptions.push(
      services.playerService
        .getAll()
        .subscribe((players: Player[]) =>
          this.setState({ allPlayers: players })
        )
    );
  }

  private updateEntityInState(entity: Entity, type: string) {
    if (entity !== undefined) {
      switch (type) {
        case "team1":
          this.setState({ team1: entity as Team });
          break;
        case "team2":
          this.setState({ team2: entity as Team });
          break;
        case "ground":
          this.setState({ ground: entity as Ground });
          break;
        case "series":
          this.setState({ series: entity as Series });
          break;
        case "match-format":
          this.setState({ matchFormat: entity as MatchFormat });
          break;
        default:
          return null;
      }
    }
  }

  private canCreateMatch(): boolean {
    return (
      !services.matchService.getIsCreating() &&
      !!this.state.team1 &&
      !!this.state.team2 &&
      !!this.state.series &&
      !!this.state.ground &&
      !!this.state.matchFormat
    );
  }

  private createMatch() {
    services.matchService
      .createMatch(
        this.state.team1,
        this.state.team2,
        this.state.series,
        this.state.ground,
        this.state.matchFormat,
        this.state.squad1,
        this.state.squad2
      )
      .then(() => {
        showMessage("Match created!");
        services.history.push("/match");
        services.history.goForward();
      })
      .catch((reason) => {
        showMessage("Could not create match: " + reason, "error");
      });
  }

  private createMatchWithCricInfoMatch(match: CricInfoMatch): Promise<boolean> {
    return services.matchService
      .createMatchFromEspnNum(match)
      .then(() => {
        showMessage("Match created!");
        services.history.push("/match");
        services.history.goForward();
        return true;
      })
      .catch((reason) => {
        showMessage("Could not create match: " + reason, "error");
        return false;
      });
  }

  public render() {
    return (
      <div>
        {this.props.open && (
          <div>
            <DecideMatchCreationSourceModal
              onProceed={(choice) => this.handleCreationSourceChoice(choice)}
              onCancel={() =>
                this.cancelDialog(Modal.MATCH_CREATION_SOURCE_DECIDER)
              }
              open={
                this.state.openModal === Modal.MATCH_CREATION_SOURCE_DECIDER
              }
            />
            <CricInfoMatchCreationModal
              onProceed={(match: CricInfoMatch) =>
                this.handleCricInfoSelection(match)
              }
              onCancel={() => this.cancelDialog(Modal.CRICINFO)}
              open={this.state.openModal === Modal.CRICINFO}
            />
            <CreateMatchModal
              onProceed={() => this.goToSquadSelection()}
              onCancel={() => this.cancelDialog(Modal.MATCH_CREATOR)}
              canProceed={() => this.canCreateMatch()}
              entitySelectedSubject={this.entitySelectedSubject}
              open={this.state.openModal === Modal.MATCH_CREATOR}
              label="Create Match"
              team1={this.state.team1}
              team2={this.state.team2}
              ground={this.state.ground}
              series={this.state.series}
              matchFormat={this.state.matchFormat}
            />
            <CreateSquadModal
              value={this.state.squad1}
              open={this.state.openModal === Modal.SQUAD_CREATOR_1}
              label={
                "Create " +
                (this.state.team1 === null
                  ? "unknown"
                  : this.state.team1.name) +
                " squad"
              }
              team={0}
              onCancel={() => this.cancelDialog(Modal.SQUAD_CREATOR_1)}
              onProceed={(teamNumber, squad) =>
                this.selectSquad(teamNumber, squad)
              }
              onPlayersAdded={(players, squad, team) =>
                this.playersAdded(players, squad, team)
              }
              onPlayersRemoved={(players, squad, team) =>
                this.playersRemoved(players, squad, team)
              }
              onPlayerUpdated={(player, squad, team) =>
                this.playerUpdated(player, squad, team)
              }
              eligiblePlayers={this.calculateEligiblePlayers()}
            />
            <CreateSquadModal
              value={this.state.squad2}
              open={this.state.openModal === Modal.SQUAD_CREATOR_2}
              label={
                "Create " +
                (this.state.team2 === null
                  ? "unknown"
                  : this.state.team2.name) +
                " squad"
              }
              team={1}
              onCancel={() => this.cancelDialog(Modal.SQUAD_CREATOR_2)}
              onProceed={(teamNumber, squad) =>
                this.selectSquad(teamNumber, squad)
              }
              onPlayersAdded={(players, squad, team) =>
                this.playersAdded(players, squad, team)
              }
              onPlayersRemoved={(players, squad, team) =>
                this.playersRemoved(players, squad, team)
              }
              onPlayerUpdated={(player, squad, team) =>
                this.playerUpdated(player, squad, team)
              }
              eligiblePlayers={this.calculateEligiblePlayers()}
            />
          </div>
        )}
      </div>
    );
  }

  private calculateEligiblePlayers() {
    return this.state.allPlayers.filter(
      (player) =>
        this.state.selectedPlayers.find(
          (p) => p.playerId.value === player.playerId.value
        ) === undefined
    );
  }

  private playersAdded(players: Player[], squad: Squad, team: number) {
    this.setState({
      squad1: team === 0 ? squad : this.state.squad1,
      squad2: team === 1 ? squad : this.state.squad2,
      selectedPlayers: [...this.state.selectedPlayers, ...players],
    });
  }

  private playersRemoved(players: Player[], squad: Squad, team: number) {
    this.setState({
      squad1: team === 0 ? squad : this.state.squad1,
      squad2: team === 1 ? squad : this.state.squad2,
      selectedPlayers: this.state.selectedPlayers.filter(
        (p) =>
          players.find(
            (player) => player.playerId.value === p.playerId.value
          ) === undefined
      ),
    });
  }

  private playerUpdated(player: Player, squad: Squad, team: number) {
    this.setState({
      squad1: team === 0 ? squad : this.state.squad1,
      squad2: team === 1 ? squad : this.state.squad2,
      selectedPlayers: [
        ...this.state.selectedPlayers.filter(
          (p) => p.playerId.value !== player.playerId.value
        ),
        player,
      ],
    });
  }

  private cancelDialog(cancelledModal: Modal) {
    if (cancelledModal === Modal.MATCH_CREATION_SOURCE_DECIDER) {
      this.subscriptions.forEach((subscription: Subscription) =>
        subscription.unsubscribe()
      );
      this.setState(this.originalState());
      this.props.onClose();
    } else if (
      cancelledModal === Modal.MATCH_CREATOR ||
      cancelledModal === Modal.CRICINFO
    ) {
      this.setState({ openModal: Modal.MATCH_CREATION_SOURCE_DECIDER });
    } else if (cancelledModal === Modal.SQUAD_CREATOR_1) {
      this.setState({ openModal: Modal.MATCH_CREATOR });
    } else if (cancelledModal === Modal.SQUAD_CREATOR_2) {
      this.setState({ openModal: Modal.SQUAD_CREATOR_1 });
    }
  }

  private selectSquad(team: number, entity: Squad) {
    if (team === 0) {
      this.setState({ squad1: entity, openModal: Modal.SQUAD_CREATOR_2 });
    } else if (team === 1) {
      this.props.onClose();
      this.createMatch();
      this.setState({
        squad2: entity,
        openModal: Modal.MATCH_CREATION_SOURCE_DECIDER,
      });
    }
  }

  private handleCricInfoSelection(match: CricInfoMatch): Promise<void> {
    return this.createMatchWithCricInfoMatch(match).then(() => {
      this.props.onClose();
      this.setState({ openModal: Modal.MATCH_CREATION_SOURCE_DECIDER });
      return;
    });
  }

  private goToSquadSelection() {
    const squad1: Squad = new Squad(
      this.state.team1.teamId,
      null,
      this.state.squad1 === null ? [] : this.state.squad1.players
    );
    const squad2: Squad = new Squad(
      this.state.team2.teamId,
      null,
      this.state.squad2 === null ? [] : this.state.squad2.players
    );
    this.setState({ openModal: Modal.SQUAD_CREATOR_1, squad1, squad2 });
  }

  private handleCreationSourceChoice(choice: Modal) {
    this.setState({ openModal: choice });
  }
}
