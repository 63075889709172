import { Component } from "react";
import { GroundStats } from "../../types/stats/ground-stats";

interface Props {
  groundStats: GroundStats;
  title: string;
  strikeRateProperty: string;
  wicketPercentProperty: string;
  confidenceProperty: string;
}

export class HistoricGroundStrikeRateAndWicketPercentStats extends Component<
  Props,
  {}
> {
  public render() {
    return (
      <div className="stats-modal-section">
        {!!this.props.groundStats && (
          <div>
            <div className="stats-modal-section-head">{this.props.title}</div>
            <div className="historic-stats-uneditable">
              <div className="historic-stat-uneditable">
                <div>Strike Rate</div>
                <div>
                  {this.props.groundStats[
                    this.props.strikeRateProperty
                  ].toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="historic-stat-uneditable">
                <div>Wicket Percent</div>
                <div>
                  {this.props.groundStats[
                    this.props.wicketPercentProperty
                  ].toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="historic-stat-uneditable">
                <div>Confidence</div>
                <div>
                  {this.props.groundStats[
                    this.props.confidenceProperty
                  ].toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
