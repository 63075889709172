import { Component } from "react";
import { Subscription } from "rxjs";
import TimeAgo from "timeago-react";
import {
  defaultNodeConfiguration,
  NodeConfiguration,
} from "../../types/preferences/node-configuration";
import { SimulatorScenario } from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { NodeHealth } from "../../types/simulator/node-health";
import { SimulationResult } from "../../types/simulator/simulation-result";
import AppAlert from "../common-components/app-alert";
import { propsEqual } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NodeConfigurationDisplay } from "./node-configuration-display";
import { NodeConfigurationModal } from "./node-configuration-modal";

interface State {
  nodeConfigurations: NodeConfiguration[];
  configurationToEdit: NodeConfiguration;
  creatingNodeConfiguration: boolean;
  nodeConfigurationModalOpen: boolean;
  latestNodeHealth: NodeHealth[];
  latestNodeHealthTime: number;
  latestNodeVersion: string;
  latestSimulation: SimulationResult;
}

export class NodesPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    preferences: null,
    nodeConfigurations: [],
    configurationToEdit: null,
    creatingNodeConfiguration: false,
    nodeConfigurationModalOpen: false,
    latestNodeHealth: null,
    latestNodeHealthTime: null,
    latestNodeVersion: null,
    latestSimulation: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...NodesPage.DEFAULT_STATE,
    };
    this.subscriptions = [];
  }

  componentDidMount() {
    this.subscriptions.push(
      services.simulationService.latestNodeHealthSubject.subscribe(
        (latestNodeHealth) => {
          this.setState({ latestNodeHealth });
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeHealthTimeSubject.subscribe(
        (latestNodeHealthTime) => {
          this.setState({ latestNodeHealthTime });
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeVersionSubject.subscribe(
        (latestNodeVersion) => {
          this.setState({ latestNodeVersion });
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        (scenarioResults: [SimulatorScenario, SimulationResult]) => {
          if (!!scenarioResults) {
            this.setState({ latestSimulation: scenarioResults[1] });
          } else {
            this.setState({ latestSimulation: null });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.nodeConfigurationSubject.subscribe(
        (nodes: NodeConfiguration[]) => {
          if (nodes) {
            this.setState({
              nodeConfigurations: nodes,
            });
          }
        }
      )
    );

    this.updateNodes();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateNodes();
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateNodes() {
    services.simulationService.getNodes();
    this.refresh();
  }

  private onModalCancel() {
    this.setState({
      nodeConfigurationModalOpen: false,
    });
  }

  private onModalProceed(configuration: NodeConfiguration) {
    services.simulationService.saveNode(configuration).then(() =>
      this.setState({
        nodeConfigurationModalOpen: false,
      })
    );
  }

  private addNodeConfiguration() {
    this.setState({
      nodeConfigurationModalOpen: true,
      creatingNodeConfiguration: true,
      configurationToEdit: defaultNodeConfiguration(),
    });
  }

  private editNodeConfiguration(configuration: NodeConfiguration) {
    this.setState({
      nodeConfigurationModalOpen: true,
      creatingNodeConfiguration: false,
      configurationToEdit: configuration,
    });
  }

  private deleteNodeConfiguration(configuration: NodeConfiguration) {
    services.simulationService.deleteNode(configuration).then(() =>
      this.setState({
        nodeConfigurationModalOpen: false,
      })
    );
  }

  private refresh() {
    services.simulationService.refreshNodeHealth();
  }

  public render() {
    return (
      <SimulatorRoute>
        <div className="full-push-background-light with-navbar nodes-page">
          {this.state.nodeConfigurations.length === 0 && (
            <AppAlert severity="warning">
              You have no nodes, please set them up to run the simulator
            </AppAlert>
          )}
          <div className="page-title">Simulator Worker Nodes</div>
          <div className="nodes-display">
            {this.state.nodeConfigurations &&
              this.state.nodeConfigurations.map((configuration, index) => {
                const nodeHealth =
                  this.state.latestNodeHealth &&
                  this.state.latestNodeHealth.find(
                    (nodeHealth) =>
                      nodeHealth.nodeConfigurationId.value ===
                      configuration.nodeConfigurationId.value
                  );

                return (
                  <NodeConfigurationDisplay
                    key={`node-${index}`}
                    onEdit={() => this.editNodeConfiguration(configuration)}
                    onDelete={() => this.deleteNodeConfiguration(configuration)}
                    configuration={configuration}
                    health={nodeHealth}
                    latestVersion={this.state.latestNodeVersion}
                    latestSimulation={this.state.latestSimulation}
                  />
                );
              })}
            <div
              className="add-node node-display"
              onClick={() => this.addNodeConfiguration()}
            >
              Add Node
            </div>
          </div>
          <div className="node-update-time">
            <TooltipIconButton
              title={"Refresh Node Health"}
              onClick={() => this.refresh()}
              icon={"refresh"}
              colour={"white"}
            />

            {this.state.latestNodeHealthTime && (
              <div className="italic">
                Last updated:{" "}
                <TimeAgo datetime={new Date(this.state.latestNodeHealthTime)} />
              </div>
            )}
          </div>
          <NodeConfigurationModal
            initialConfiguration={this.state.configurationToEdit}
            open={this.state.nodeConfigurationModalOpen}
            onCancel={() => this.onModalCancel()}
            onProceed={(configuration) => this.onModalProceed(configuration)}
          />
        </div>
      </SimulatorRoute>
    );
  }
}
