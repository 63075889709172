import { v4 as uuid } from "uuid";

export interface UUID {
  value: string;
}

// eslint-disable-next-line
export const UUID = {
  randomUUID(): UUID {
    return { value: uuid() };
  },

  fromString(value: string): UUID {
    if (value === "" || value === undefined || value === null) {
      return null;
    }
    return { value };
  },
};

export function deserializeUUIDArray(json: any[]): UUID[] {
  const uuids: UUID[] = [];
  json.forEach((element) => {
    uuids.push(UUID.fromString(element));
  });
  return uuids;
}
