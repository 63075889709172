import { Entity } from "../entities/entity";
import { ExtrasType } from "../enums/extras-type";
import { MatchType } from "../enums/match-type";
import { UUID } from "../uuid";

export interface HistoricPlayerStats {
  battingStrikeRateBiasByDate: Map<number, number>;
  battingStrikeRateBiasByDateRolling: Map<number, number>;
  battingWicketBiasByDate: Map<number, number>;
  battingWicketBiasByDateRolling: Map<number, number>;
  battingConfidenceByDate: Map<number, number>;
  battingConfidenceByDateRolling: Map<number, number>;
  bowlingStrikeRateBiasByDate: Map<number, number>;
  bowlingStrikeRateBiasByDateRolling: Map<number, number>;
  bowlingWicketBiasByDate: Map<number, number>;
  bowlingWicketBiasByDateRolling: Map<number, number>;
  bowlingExtrasBiasByDate: Map<number, number>;
  bowlingExtrasBiasByDateRolling: Map<number, number>;
  bowlingExtrasBiasesByTypeByDate: Map<number, Map<ExtrasType, number>>;
  bowlingExtrasBiasesByTypeByDateRolling: Map<number, Map<ExtrasType, number>>;
  bowlingConfidenceByDate: Map<number, number>;
  bowlingConfidenceByDateRolling: Map<number, number>;
  orderByDate: Map<number, number>;
  orderByDateRolling: Map<number, number>;
}

export class HistoricPlayerStatsWrapper implements Entity {
  public historicPlayerStatsId: UUID;
  public playerId: UUID;
  public entityId: UUID;
  public createdBy: UUID;
  public createdAt: number;
  public name: string;
  public historicPlayerStats: HistoricPlayerStats;
  public matchType: MatchType;

  constructor(
    historicPlayerStatsId: UUID,
    playerId: UUID,
    entityId: UUID,
    createdBy: UUID,
    createdAt: number,
    name: string,
    historicPlayerStats: HistoricPlayerStats,
    matchType: MatchType
  ) {
    this.historicPlayerStatsId = historicPlayerStatsId;
    this.entityId = entityId;
    this.playerId = playerId;
    this.historicPlayerStats = historicPlayerStats;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.name = name;
    this.matchType = matchType;
  }

  public static deserializeMap(
    json: any
  ): Map<string, HistoricPlayerStatsWrapper> {
    const map: Map<string, HistoricPlayerStatsWrapper> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, this.deserializeOne(value));
    }
    return map;
  }

  public static deserializeOne(responseJSON: any): HistoricPlayerStatsWrapper {
    return new HistoricPlayerStatsWrapper(
      UUID.fromString(responseJSON.historicPlayerStatsId),
      UUID.fromString(responseJSON.playerId),
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      responseJSON.name,
      this.deserializeHistoricPlayerStats(responseJSON.historicPlayerStats),
      MatchType[responseJSON.matchType]
    );
  }

  public static deserializeHistoricPlayerStats(json: any): HistoricPlayerStats {
    return json === null
      ? null
      : {
          battingStrikeRateBiasByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingStrikeRateBiasByDate
            ),
          battingStrikeRateBiasByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingStrikeRateBiasByDateRolling
            ),
          battingWicketBiasByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingWicketBiasByDate
            ),
          battingWicketBiasByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingWicketBiasByDateRolling
            ),
          battingConfidenceByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingConfidenceByDate
            ),
          battingConfidenceByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.battingConfidenceByDateRolling
            ),
          bowlingStrikeRateBiasByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingStrikeRateBiasByDate
            ),
          bowlingStrikeRateBiasByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingStrikeRateBiasByDateRolling
            ),
          bowlingWicketBiasByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingWicketBiasByDate
            ),
          bowlingWicketBiasByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingWicketBiasByDateRolling
            ),
          bowlingExtrasBiasByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingExtrasBiasByDate
            ),
          bowlingExtrasBiasByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingExtrasBiasByDateRolling
            ),
          bowlingExtrasBiasesByTypeByDate:
            HistoricPlayerStatsWrapper.deserializeExtrasTypeToNumberMap(
              json.bowlingExtrasBiasesByTypeByDate
            ),
          bowlingExtrasBiasesByTypeByDateRolling:
            HistoricPlayerStatsWrapper.deserializeExtrasTypeToNumberMap(
              json.bowlingExtrasBiasesByTypeByDateRolling
            ),
          bowlingConfidenceByDate:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingConfidenceByDate
            ),
          bowlingConfidenceByDateRolling:
            HistoricPlayerStatsWrapper.deserializeNumberMap(
              json.bowlingConfidenceByDateRolling
            ),
          orderByDate: HistoricPlayerStatsWrapper.deserializeNumberMap(
            json.orderByDate
          ),
          orderByDateRolling: HistoricPlayerStatsWrapper.deserializeNumberMap(
            json.orderByDateRolling
          ),
        };
  }

  public static deserializeNumberMap(json: any): Map<number, number> {
    const map: Map<number, number> = new Map();
    Object.keys(json).forEach((key) => {
      map.set(Number(key), Number(json[key]));
    });
    return map;
  }

  private static deserializeExtrasTypeMap(json: any): Map<ExtrasType, number> {
    const map: Map<ExtrasType, number> = new Map();
    Object.keys(json).forEach((key) => {
      map.set(ExtrasType[key], Number(json[key]));
    });
    return map;
  }

  private static deserializeExtrasTypeToNumberMap(
    json: any
  ): Map<number, Map<ExtrasType, number>> {
    const map: Map<number, Map<ExtrasType, number>> = new Map();
    Object.keys(json).forEach((key) => {
      map.set(
        Number(key),
        HistoricPlayerStatsWrapper.deserializeExtrasTypeMap(json[key])
      );
    });
    return map;
  }

  public static emptyWithName(name: string): HistoricPlayerStatsWrapper {
    return new HistoricPlayerStatsWrapper(
      null,
      null,
      null,
      null,
      null,
      name,
      null,
      MatchType.T20_AND_HUNDRED
    );
  }

  public static getTypeName(): string {
    return "historic player form";
  }

  public toString(): string {
    return this.name;
  }
}
