import { Component } from "react";
import { services, showMessage } from "../../../types/services";
import { EntityAutoSelector } from "../../entity-management/entity-selectors/entity-auto-selector";
import { EditGroundComponent } from "../../entity-management/entity-editing-dialogs/edit-ground-component";
import { Entity } from "../../../types/entities/entity";
import { EditTeamComponent } from "../../entity-management/entity-editing-dialogs/edit-team-component";
import { EditSeriesComponent } from "../../entity-management/entity-editing-dialogs/edit-series-component";
import { EditMatchFormatComponent } from "../../entity-management/entity-editing-dialogs/edit-match-format-component";
import { Subject, Subscription } from "rxjs";
import { Team } from "../../../types/entities/team";
import { Ground } from "../../../types/entities/ground";
import { MatchFormat } from "../../../types/entities/match-format";
import { Series } from "../../../types/entities/series";
import { CreationDialog } from "./creation-dialog";
import { observableFrom } from "../../component-utils";

export interface Selection {
  entity: Entity;
  role: string;
}

interface Props {
  onProceed: Function;
  onCancel: Function;
  canProceed: Function;
  entitySelectedSubject: Subject<Selection>;
  open: boolean;
  label: string;
  team1: Team;
  team2: Team;
  ground: Ground;
  series: Series;
  matchFormat: MatchFormat;
}

interface ModalState {
  modalOpen: boolean;
  modalLabel: string;
  modalButtonText: string;
  isNew: boolean;
}

interface State {
  modalStates: Map<String, ModalState>;
  eligibleTeams: Team[];
}

export class CreateMatchModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    const defaultModalState: ModalState = {
      modalOpen: false,
      modalLabel: "Create Series",
      modalButtonText: "CREATE",
      isNew: true,
    };
    const defaultModalStates = new Map();
    defaultModalStates.set("series", Object.assign({}, defaultModalState));
    defaultModalStates.set("team1", Object.assign({}, defaultModalState));
    defaultModalStates.set("team2", Object.assign({}, defaultModalState));
    defaultModalStates.set("ground", Object.assign({}, defaultModalState));
    defaultModalStates.set(
      "match-format",
      Object.assign({}, defaultModalState)
    );
    this.state = {
      modalStates: defaultModalStates,
      eligibleTeams: [],
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.teamService
        .getAll()
        .subscribe((teams: Team[]) =>
          this.setState({ eligibleTeams: this.filterTeams(teams) })
        )
    );
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      this.props.team1 !== prevProps.team1 ||
      this.props.team2 !== prevProps.team2
    ) {
      this.subscriptions.forEach((subscription: Subscription) =>
        subscription.unsubscribe()
      );
      this.subscriptions = [];
      this.subscriptions.push(
        services.teamService
          .getAll()
          .subscribe((teams: Team[]) =>
            this.setState({ eligibleTeams: this.filterTeams(teams) })
          )
      );
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private filterTeams(teams: Team[]): Team[] {
    return teams.filter((team: Team) => {
      const notEqualToTeam1: boolean =
        this.props.team1 === null ||
        (team.teamId !== null &&
          this.props.team1.teamId !== null &&
          team.teamId.value !== this.props.team1.teamId.value);
      const notEqualToTeam2: boolean =
        this.props.team2 === null ||
        (team.teamId !== null &&
          this.props.team2.teamId !== null &&
          team.teamId.value !== this.props.team2.teamId.value);
      return notEqualToTeam1 && notEqualToTeam2;
    });
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Create Match"}
        invalid={!this.props.canProceed()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed()}
        proceedText="NEXT"
        colour="#31b788"
        classes="create-match-dialog"
      >
        <div className="dialog-container">
          <EntityAutoSelector
            value={this.props.series}
            label="Series:"
            options={services.seriesService.getAll()}
            optionService={services.seriesService}
            onSelect={(entity) => this.onChange(entity, "series")}
            onCreateOrEdit={(isNew, entity) =>
              this.showModal(isNew, entity, "series")
            }
          />
          <EditSeriesComponent
            value={this.props.series}
            open={this.state.modalStates.get("series").modalOpen}
            label={this.state.modalStates.get("series").modalLabel}
            buttonText={this.state.modalStates.get("series").modalButtonText}
            isNew={this.state.modalStates.get("series").isNew}
            onProceed={(entity) => this.onModalProceed(entity, "series")}
            onCancel={(isNew) => this.onModalCancel(isNew, "series")}
          />

          <EntityAutoSelector
            value={this.props.team1}
            label="Team 1:"
            options={observableFrom(this.state.eligibleTeams)}
            optionService={services.teamService}
            onSelect={(entity) => this.onChange(entity, "team1")}
            onCreateOrEdit={(isNew, entity) =>
              this.showModal(isNew, entity, "team1")
            }
          />
          <EditTeamComponent
            value={this.props.team1}
            open={this.state.modalStates.get("team1").modalOpen}
            label={this.state.modalStates.get("team1").modalLabel}
            buttonText={this.state.modalStates.get("team1").modalButtonText}
            isNew={this.state.modalStates.get("team1").isNew}
            onProceed={(entity) => this.onModalProceed(entity, "team1")}
            onCancel={(isNew) => this.onModalCancel(isNew, "team1")}
          />

          <EntityAutoSelector
            value={this.props.team2}
            label="Team 2:"
            optionService={services.teamService}
            options={observableFrom(this.state.eligibleTeams)}
            onSelect={(entity) => this.onChange(entity, "team2")}
            onCreateOrEdit={(isNew, entity) =>
              this.showModal(isNew, entity, "team2")
            }
          />
          <EditTeamComponent
            value={this.props.team2}
            open={this.state.modalStates.get("team2").modalOpen}
            label={this.state.modalStates.get("team2").modalLabel}
            buttonText={this.state.modalStates.get("team2").modalButtonText}
            isNew={this.state.modalStates.get("team2").isNew}
            onProceed={(entity) => this.onModalProceed(entity, "team2")}
            onCancel={(isNew) => this.onModalCancel(isNew, "team2")}
          />

          <EntityAutoSelector
            value={this.props.ground}
            label="Ground:"
            options={services.groundService.getAll()}
            optionService={services.groundService}
            onSelect={(entity) => this.onChange(entity, "ground")}
            onCreateOrEdit={(isNew, entity) =>
              this.showModal(isNew, entity, "ground")
            }
          />
          <EditGroundComponent
            value={this.props.ground}
            open={this.state.modalStates.get("ground").modalOpen}
            label={this.state.modalStates.get("ground").modalLabel}
            buttonText={this.state.modalStates.get("ground").modalButtonText}
            isNew={this.state.modalStates.get("ground").isNew}
            onProceed={(entity) => this.onModalProceed(entity, "ground")}
            onCancel={(isNew) => this.onModalCancel(isNew, "ground")}
          />

          <EntityAutoSelector
            value={this.props.matchFormat}
            label="Format:"
            options={services.matchFormatService.getAllParents()}
            optionService={services.matchFormatService}
            onSelect={(entity) => this.onChange(entity, "match-format")}
            onCreateOrEdit={(isNew, entity) =>
              this.showModal(isNew, entity, "match-format")
            }
          />
          <EditMatchFormatComponent
            initialValue={this.props.matchFormat}
            open={this.state.modalStates.get("match-format").modalOpen}
            label={this.state.modalStates.get("match-format").modalLabel}
            buttonText={
              this.state.modalStates.get("match-format").modalButtonText
            }
            isNew={this.state.modalStates.get("match-format").isNew}
            onProceed={(entity) => this.onModalProceed(entity, "match-format")}
            onCancel={(isNew) => this.onModalCancel(isNew, "match-format")}
          />
        </div>
      </CreationDialog>
    );
  }

  private onChange(entity: Entity, role: string) {
    this.props.entitySelectedSubject.next({ entity, role });
  }

  private onModalProceed(entity: Entity, type: string) {
    let promise: Promise<Entity>;
    switch (type) {
      case "series":
        promise = services.seriesService.add(entity as Series);
        break;
      case "team1":
      case "team2":
        promise = services.teamService.add(entity as Team);
        break;
      case "ground":
        promise = services.groundService.add(entity as Ground);
        break;
      case "match-format":
        promise = services.matchFormatService.add(entity as MatchFormat);
        break;
      default:
        promise = Promise.reject("Unrecognised entity type");
    }
    promise
      .then((entity: Entity) => {
        this.onChange(entity, type);
        this.closeModal(type);
      })
      .catch((reason) =>
        showMessage(`Failed to add ${type}: ${reason}`, "error")
      );
  }

  private showModal(isNew: boolean, entity: Entity, type: string) {
    this.onChange(entity, type);
    const modalState: ModalState = {
      modalOpen: true,
      modalButtonText: isNew ? "CREATE" : "UPDATE",
      modalLabel: isNew
        ? `Create ${type.replaceAll("-", " ")}`
        : `Update ${type.replaceAll("-", " ")}`,
      isNew,
    };
    this.updateStateForType(modalState, type);
  }

  private onModalCancel(isNew: boolean, type: string) {
    if (isNew) {
      this.onChange(null, type);
    }
    this.closeModal(type);
  }

  private closeModal(type: string) {
    const modalState: ModalState = {
      modalOpen: false,
      modalButtonText: "CREATE",
      modalLabel: `Create ${type}`,
      isNew: true,
    };
    this.updateStateForType(modalState, type);
  }

  private updateStateForType(modalState: ModalState, type: string) {
    const modalStates = this.state.modalStates;
    modalStates.set(type, modalState);

    this.setState({
      modalStates: modalStates,
    });
  }
}
