import { ReplaySubject, Subject } from "rxjs";
import { Ball } from "../../types/entities/ball";
import { GameState } from "../../types/entities/game-state";
import { services, showMessage } from "../../types/services";
import { EntityService } from "./entity-service";
import {
  EventWithHistoricPush,
  deserializeEventWithHistoricPushList,
} from "../../types/entities/cricket-event";
import { UUID } from "../../types/uuid";

export class BallService extends EntityService<Ball> {
  public ballsForMatchSubject: Subject<EventWithHistoricPush[]> =
    new ReplaySubject<EventWithHistoricPush[]>(1);

  public getEventsForMatch(matchId: UUID) {
    if (!!matchId) {
      const params: Map<string, string> = new Map();
      params.set("matchId", `${matchId.value}`);
      this.httpService
        .get("/api/" + this.controllerName + "/get-all-events", params)
        .then((response) => {
          const events: EventWithHistoricPush[] =
            deserializeEventWithHistoricPushList(response);
          this.ballsForMatchSubject.next(events);
        })
        .catch((reason) => {
          showMessage(`Failed to get events for match: ${reason}`, "error");
        });
    } else {
      this.ballsForMatchSubject.next([]);
    }
  }

  public async addForState(ball: Ball): Promise<GameState> {
    services.simulationService.removeSimulationResults();
    services.simulationService.removeDefaultSimulationResults();
    const payload: any = Ball.serialize(ball);
    return await this.httpService
      .post("/api/" + this.controllerName + "/add-for-state", payload)
      .then((response) => {
        const gameState: GameState = GameState.deserializeOne(
          response.gameState
        );
        return gameState;
      })
      .catch((reason) => {
        showMessage("Error adding ball - " + reason);
        return Promise.reject(reason);
      });
  }
}
