export function translateDate(epoch: number): string {
  const date: Date = new Date(epoch);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day}/${month}/${year}`;
}

export function dateToAge(epoch) {
  var today = new Date();
  var birthDate = new Date(epoch);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function cloneObject(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function nanSafeWithDefault(value, defaultValue: number): number {
  return Number.isNaN(Number(value)) ? defaultValue : value;
}

export function nanSafe(value): number {
  return nanSafeWithDefault(value, 0);
}

export function infinitySafeWithDefault(value, defaultValue: number): number {
  return Number.isFinite(Number(value)) ? value : defaultValue;
}

export function infinitySafe(value): number {
  return infinitySafeWithDefault(value, 0);
}

export function round(num, decimalPlaces = 0) {
  const trailingZeroString = getTrailingZerosAndDecimalPoint(num);

  var p = Math.pow(10, decimalPlaces);
  var n = num * p * (1 + Number.EPSILON);
  const withTrailingZeros = Math.round(n) / p + trailingZeroString;
  const parts = withTrailingZeros.split(".");

  if (parts.length === 2 && parts[1].length > decimalPlaces) {
    const shortenedDecimalPart =
      decimalPlaces > 0 ? "." + parts[1].substring(0, decimalPlaces) : "";
    return parts[0] + shortenedDecimalPart;
  } else {
    return decimalPlaces > 0 ? withTrailingZeros : parts[0];
  }
}

function getTrailingZerosAndDecimalPoint(num: number) {
  const numString = "" + num;
  let indexOfFirstTrailing = null;
  let containsDecimalPoint: boolean = false;
  let trailingZeros: boolean = true;
  Array.from(numString).forEach((value, index) => {
    if ((value === "0" && containsDecimalPoint) || value === ".") {
      if (!trailingZeros) {
        indexOfFirstTrailing = index;
      }
      trailingZeros = true;
    } else {
      trailingZeros = false;
    }

    if (value === ".") {
      containsDecimalPoint = true;
    }
  });
  return containsDecimalPoint && trailingZeros
    ? numString.substring(indexOfFirstTrailing)
    : "";
}
