import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import {
  CalculatedStatisticType,
  StatisticType,
} from "../../types/enums/statistic-type";
import { SimulatorRoute } from "../../types/route-helpers";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { nanSafeWithDefault } from "../../types/util-functions";
import { compareValues } from "../component-utils";

interface Props {
  player: Player;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  team: number;
  gameState: GameState;
  matchFormat: MatchFormat;
  fromThisPoint: boolean;
}

interface TableValues {
  battingAvg: number;
  battingSr: number;
  battingBallsAvg: number;
  battingMean: number;
  battingMeanPush: number;
  battingMeanSrBias: number;
  battingMeanWpcBias: number;
  battingMedianRuns: number;
  battingMedianBoundaries: number;
  totalBattingBalls: number;
  currentBalls: number;
  playerBatted: boolean;
}

export class PlayerBattingSimulationDisplay extends Component<Props> {
  private readonly didNotBat = (
    <TableRow key={"sim-stats" + this.props.player.playerId.value}>
      <TableCell colSpan={6} id="player-simulation-stats-table-cell">
        Did Not Bat
      </TableCell>
    </TableRow>
  );

  private buildRow(values: TableValues, comparedValues: TableValues) {
    const comparedStyle = !!comparedValues
      ? {
          backgroundColor: "#282c34",
          fontSize: "x-small",
          color: "white",
        }
      : null;
    const battingAvgColour = !!comparedValues
      ? compareValues(comparedValues.battingAvg, values.battingAvg)
      : "black";
    const battingSrColour = !!comparedValues
      ? compareValues(comparedValues.battingSr, values.battingSr)
      : "black";
    const battingBallsColour = !!comparedValues
      ? compareValues(comparedValues.battingBallsAvg, values.battingBallsAvg)
      : "black";
    const battingMeanColour = !!comparedValues
      ? compareValues(comparedValues.battingMean, values.battingMean)
      : "black";
    const battingMedianRunsColour = !!comparedValues
      ? compareValues(
          comparedValues.battingMedianRuns,
          values.battingMedianRuns
        )
      : "black";
    const battingMedianBoundariesColour = !!comparedValues
      ? compareValues(
          comparedValues.battingMedianBoundaries,
          values.battingMedianBoundaries
        )
      : "black";
    const battingMeanPushColour = !!comparedValues
      ? compareValues(comparedValues.battingMeanPush, values.battingMeanPush)
      : "black";
    const battingMeanSrBiasColour = !!comparedValues
      ? compareValues(
          comparedValues.battingMeanSrBias,
          values.battingMeanSrBias
        )
      : "black";
    const battingMeanWpcBiasColour = !!comparedValues
      ? compareValues(
          comparedValues.battingMeanWpcBias,
          values.battingMeanWpcBias
        )
      : "black";

    return this.props.gameState.started && !values.playerBatted ? (
      this.didNotBat
    ) : (
      <TableRow
        key={"sim-stats" + this.props.player.playerId.value}
        className={!!comparedValues ? "comparison" : ""}
      >
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingAvgColour }}
        >
          {values.battingAvg}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingSrColour }}
        >
          {values.battingSr}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingBallsColour }}
        >
          {values.battingBallsAvg}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMeanColour }}
        >
          {values.battingMean}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMeanPushColour }}
        >
          {values.battingMeanPush}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMedianRunsColour }}
        >
          {values.battingMedianRuns}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMedianBoundariesColour }}
        >
          {values.battingMedianBoundaries}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMeanSrBiasColour }}
        >
          {values.battingMeanSrBias}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: battingMeanWpcBiasColour }}
        >
          {values.battingMeanWpcBias}
        </TableCell>
      </TableRow>
    );
  }

  private getValues(
    result: SimulationResult,
    innings: number,
    currentBalls: number
  ): TableValues {
    const battingRunsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_RUNS
        : CalculatedStatisticType.TEAM_2_BATSMAN_RUNS;
    const battingBallsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_BALLS
        : CalculatedStatisticType.TEAM_2_BATSMAN_BALLS;
    const battingDismissalsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_WICKETS
        : CalculatedStatisticType.TEAM_2_BATSMAN_WICKETS;
    const battingPushStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_PUSH
        : CalculatedStatisticType.TEAM_2_BATSMAN_PUSH;
    const battingSRBiasStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_EXPECTED_SR
        : CalculatedStatisticType.TEAM_2_BATSMAN_EXPECTED_SR;
    const battingWPCBiasStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BATSMAN_EXPECTED_WPC
        : CalculatedStatisticType.TEAM_2_BATSMAN_EXPECTED_WPC;

    const numberOfSimulations = result.numberOfSimulations;
    const currentRuns = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_RUNS_SCORED,
      this.props.player.playerId
    );
    const currentFours = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_FOURS,
      this.props.player.playerId
    );
    const currentSixes = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_SIXES,
      this.props.player.playerId
    );
    const currentPush = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_TOTAL_PUSH,
      this.props.player.playerId
    );
    const currentSrBias = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_TOTAL_EXPECTED_SR,
      this.props.player.playerId
    );
    const currentWpcBias = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_TOTAL_EXPECTED_WPC,
      this.props.player.playerId
    );
    const currentBoundaries = currentFours + currentSixes;

    const totalBattingRuns =
      (result &&
        result.playerTotals.get(battingRunsStatistic) &&
        result.playerTotals
          .get(battingRunsStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint ? currentRuns * numberOfSimulations : 0)) ||
      0;
    const totalBattingPush =
      (result &&
        result.playerTotals.get(battingPushStatistic) &&
        result.playerTotals
          .get(battingPushStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint ? currentPush * numberOfSimulations : 0)) ||
      0;
    const totalBattingSrBias =
      (result &&
        result.playerTotals.get(battingSRBiasStatistic) &&
        result.playerTotals
          .get(battingSRBiasStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint
            ? currentSrBias * numberOfSimulations
            : 0)) ||
      0;
    const totalBattingWpcBias =
      (result &&
        result.playerTotals.get(battingWPCBiasStatistic) &&
        result.playerTotals
          .get(battingWPCBiasStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint
            ? currentWpcBias * numberOfSimulations
            : 0)) ||
      0;
    const totalBattingBalls =
      result &&
      result.playerTotals.get(battingBallsStatistic) &&
      result.playerTotals
        .get(battingBallsStatistic)
        .get(this.props.player.playerId.value) -
        (this.props.fromThisPoint ? currentBalls * numberOfSimulations : 0);
    const totalBattingDismissals =
      (result &&
        result.playerTotals.get(battingDismissalsStatistic) &&
        result.playerTotals
          .get(battingDismissalsStatistic)
          .get(this.props.player.playerId.value)) ||
      0;

    const battingMedianRuns =
      (result &&
        result.playerMedians.get(this.props.player.playerId.value) &&
        result.playerMedians
          .get(this.props.player.playerId.value)
          .get(CalculatedStatisticType.MEDIAN_BATSMAN_RUNS) -
          (this.props.fromThisPoint ? currentRuns : 0)) ||
      0;
    const battingMedianBoundaries =
      (result &&
        result.playerMedians.get(this.props.player.playerId.value) &&
        result.playerMedians
          .get(this.props.player.playerId.value)
          .get(CalculatedStatisticType.MEDIAN_BATSMAN_BOUNDARIES) -
          (this.props.fromThisPoint ? currentBoundaries : 0)) ||
      0;

    const battingAvg = nanSafeWithDefault(
      (totalBattingRuns / totalBattingDismissals).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingSr = nanSafeWithDefault(
      (totalBattingRuns / totalBattingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingBallsAvg = nanSafeWithDefault(
      (totalBattingBalls / numberOfSimulations).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingMean = nanSafeWithDefault(
      (totalBattingRuns / numberOfSimulations).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingMeanPush = nanSafeWithDefault(
      (totalBattingPush / totalBattingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingMeanSrBias = nanSafeWithDefault(
      (totalBattingSrBias / totalBattingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const battingMeanWpcBias = nanSafeWithDefault(
      (totalBattingWpcBias / totalBattingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const playerBatted =
      (!!totalBattingBalls && totalBattingBalls !== 0) ||
      (!!currentBalls && currentBalls !== 0);

    return {
      battingAvg,
      battingSr,
      battingBallsAvg,
      battingMean,
      battingMeanPush,
      battingMeanSrBias,
      battingMeanWpcBias,
      battingMedianRuns,
      battingMedianBoundaries,
      totalBattingBalls,
      currentBalls,
      playerBatted,
    };
  }

  public render() {
    if (!this.props.simulationResult) {
      return <div />;
    }

    const innings = this.props.gameState.findFirstBattingInningsForTeam(
      this.props.team,
      this.props.matchFormat
    );
    const currentBalls = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_BALLS_ON_STRIKE,
      this.props.player.playerId
    );
    const dismissalMethod = this.props.gameState.getPlayerStringStatForInnings(
      innings + 1,
      StatisticType.BATSMAN_DISMISSAL_METHOD,
      this.props.player.playerId
    );
    const playerNotOut =
      !dismissalMethod ||
      dismissalMethod === "" ||
      dismissalMethod === "Not Out";
    const playerRetired =
      !!dismissalMethod && dismissalMethod === "Retired Hurt";

    const currentValues = this.getValues(
      this.props.simulationResult,
      innings,
      currentBalls
    );
    const rows = [this.buildRow(currentValues, null)];
    let comparedValues = null;
    if (!!this.props.comparedResult) {
      comparedValues = this.getValues(
        this.props.comparedResult,
        innings,
        currentBalls
      );
      rows.push(this.buildRow(comparedValues, currentValues));
    }

    let neverBatted = false;

    if (
      !currentValues.playerBatted &&
      (!comparedValues || !comparedValues.playerBatted)
    ) {
      neverBatted = true;
    }

    return (
      <SimulatorRoute>
        <div className="player-simulation-stats">
          {(playerNotOut || playerRetired) && !neverBatted && (
            <TableContainer>
              <Table aria-label="player simulator stats table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-0"
                      colSpan={3}
                    >
                      Batting
                    </TableCell>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-1"
                      colSpan={2}
                    >
                      Mean
                    </TableCell>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-2"
                      colSpan={2}
                    >
                      Median (If Batted)
                    </TableCell>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-3"
                      colSpan={2}
                    >
                      Bias
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Average
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      SR
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Faced
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Runs
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Push
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Runs
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Boundaries
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      SR
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      W%
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          )}
          {this.props.gameState.started && playerNotOut && neverBatted && (
            <div className="player-simulation-stats-blank">Did Not Bat</div>
          )}
          {this.props.gameState.started && !playerNotOut && !playerRetired && (
            <div className="player-simulation-stats-blank">
              {dismissalMethod}
            </div>
          )}
        </div>
      </SimulatorRoute>
    );
  }
}
