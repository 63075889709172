export enum Country {
  AFGHANISTAN = "AFGHANISTAN",
  ALBANIA = "ALBANIA",
  ALGERIA = "ALGERIA",
  ANDORRA = "ANDORRA",
  ANGOLA = "ANGOLA",
  ANTIGUA_AND_BARBUDA = "ANTIGUA_AND_BARBUDA",
  ARGENTINA = "ARGENTINA",
  ARMENIA = "ARMENIA",
  AUSTRALIA = "AUSTRALIA",
  AUSTRIA = "AUSTRIA",
  AZERBAIJAN = "AZERBAIJAN",
  BAHAMAS = "BAHAMAS",
  BAHRAIN = "BAHRAIN",
  BANGLADESH = "BANGLADESH",
  BARBADOS = "BARBADOS",
  BELARUS = "BELARUS",
  BELGIUM = "BELGIUM",
  BELIZE = "BELIZE",
  BENIN = "BENIN",
  BERMUDA = "BERMUDA",
  BHUTAN = "BHUTAN",
  BOLIVIA = "BOLIVIA",
  BOSNIA_AND_HERZEGOVINA = "BOSNIA_AND_HERZEGOVINA",
  BOTSWANA = "BOTSWANA",
  BRAZIL = "BRAZIL",
  BRUNEI = "BRUNEI",
  BULGARIA = "BULGARIA",
  BURKINA_FASO = "BURKINA_FASO",
  BURUNDI = "BURUNDI",
  IVORY_COAST = "IVORY_COAST",
  CAPE_VERDE = "CAPE_VERDE",
  CAMBODIA = "CAMBODIA",
  CAMEROON = "CAMEROON",
  CANADA = "CANADA",
  CAYMAN_ISLANDS = "CAYMAN_ISLANDS",
  CENTRAL_AFRICAN_REPUBLIC = "CENTRAL_AFRICAN_REPUBLIC",
  CHAD = "CHAD",
  CHILE = "CHILE",
  CHINA = "CHINA",
  COLOMBIA = "COLOMBIA",
  COMOROS = "COMOROS",
  CONGO = "CONGO",
  COSTA_RICA = "COSTA_RICA",
  COOK_ISLANDS = "COOK_ISLANDS",
  CROATIA = "CROATIA",
  CUBA = "CUBA",
  CYPRUS = "CYPRUS",
  CZECH_REPUBLIC = "CZECH_REPUBLIC",
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO = "DEMOCRATIC_REPUBLIC_OF_THE_CONGO",
  DENMARK = "DENMARK",
  DJIBOUTI = "DJIBOUTI",
  DOMINICA = "DOMINICA",
  DOMINICAN_REPUBLIC = "DOMINICAN_REPUBLIC",
  ECUADOR = "ECUADOR",
  EGYPT = "EGYPT",
  EL_SALVADOR = "EL_SALVADOR",
  EQUATORIAL_GUINEA = "EQUATORIAL_GUINEA",
  ERITREA = "ERITREA",
  ESTONIA = "ESTONIA",
  ESWATINI = "ESWATINI",
  ETHIOPIA = "ETHIOPIA",
  FIJI = "FIJI",
  FINLAND = "FINLAND",
  FRANCE = "FRANCE",
  GABON = "GABON",
  GAMBIA = "GAMBIA",
  GEORGIA = "GEORGIA",
  GERMANY = "GERMANY",
  GHANA = "GHANA",
  GIBRALTAR = "GIBRALTAR",
  GREECE = "GREECE",
  GRENADA = "GRENADA",
  GUATEMALA = "GUATEMALA",
  GUERNSEY = "GUERNSEY",
  GUINEA = "GUINEA",
  GUINEA_BISSAU = "GUINEA_BISSAU",
  GUYANA = "GUYANA",
  HAITI = "HAITI",
  HONDURAS = "HONDURAS",
  HONG_KONG = "HONG_KONG",
  HUNGARY = "HUNGARY",
  ICELAND = "ICELAND",
  INDIA = "INDIA",
  INDONESIA = "INDONESIA",
  IRAN = "IRAN",
  IRAQ = "IRAQ",
  IRELAND = "IRELAND",
  ISRAEL = "ISRAEL",
  ITALY = "ITALY",
  JAMAICA = "JAMAICA",
  JAPAN = "JAPAN",
  JERSEY = "JERSEY",
  JORDAN = "JORDAN",
  KAZAKHSTAN = "KAZAKHSTAN",
  KENYA = "KENYA",
  KIRIBATI = "KIRIBATI",
  KUWAIT = "KUWAIT",
  KYRGYZSTAN = "KYRGYZSTAN",
  LAOS = "LAOS",
  LATVIA = "LATVIA",
  LEBANON = "LEBANON",
  LESOTHO = "LESOTHO",
  LIBERIA = "LIBERIA",
  LIBYA = "LIBYA",
  LIECHTENSTEIN = "LIECHTENSTEIN",
  LITHUANIA = "LITHUANIA",
  LUXEMBOURG = "LUXEMBOURG",
  MADAGASCAR = "MADAGASCAR",
  MALAWI = "MALAWI",
  MALAYSIA = "MALAYSIA",
  MALDIVES = "MALDIVES",
  MALI = "MALI",
  MALTA = "MALTA",
  MARSHALL_ISLANDS = "MARSHALL_ISLANDS",
  MAURITANIA = "MAURITANIA",
  MAURITIUS = "MAURITIUS",
  MEXICO = "MEXICO",
  MICRONESIA = "MICRONESIA",
  MOLDOVA = "MOLDOVA",
  MONACO = "MONACO",
  MONGOLIA = "MONGOLIA",
  MONTENEGRO = "MONTENEGRO",
  MOROCCO = "MOROCCO",
  MOZAMBIQUE = "MOZAMBIQUE",
  MYANMAR = "MYANMAR",
  NAMIBIA = "NAMIBIA",
  NAURU = "NAURU",
  NEPAL = "NEPAL",
  NETHERLANDS = "NETHERLANDS",
  NEW_ZEALAND = "NEW_ZEALAND",
  NICARAGUA = "NICARAGUA",
  NIGER = "NIGER",
  NIGERIA = "NIGERIA",
  NORTH_KOREA = "NORTH_KOREA",
  NORTH_MACEDONIA = "NORTH_MACEDONIA",
  NORWAY = "NORWAY",
  OMAN = "OMAN",
  PAKISTAN = "PAKISTAN",
  PALAU = "PALAU",
  PALESTINE_STATE = "PALESTINE_STATE",
  PANAMA = "PANAMA",
  PAPUA_NEW_GUINEA = "PAPUA_NEW_GUINEA",
  PARAGUAY = "PARAGUAY",
  PERU = "PERU",
  PHILIPPINES = "PHILIPPINES",
  POLAND = "POLAND",
  PORTUGAL = "PORTUGAL",
  QATAR = "QATAR",
  ROMANIA = "ROMANIA",
  RUSSIA = "RUSSIA",
  RWANDA = "RWANDA",
  SAINT_KITTS_AND_NEVIS = "SAINT_KITTS_AND_NEVIS",
  SAINT_LUCIA = "SAINT_LUCIA",
  SAINT_VINCENT_AND_THE_GRENADINES = "SAINT_VINCENT_AND_THE_GRENADINES",
  SAMOA = "SAMOA",
  SAN_MARINO = "SAN_MARINO",
  SAO_TOME_AND_PRINCIPE = "SAO_TOME_AND_PRINCIPE",
  SAUDI_ARABIA = "SAUDI_ARABIA",
  SENEGAL = "SENEGAL",
  SERBIA = "SERBIA",
  SEYCHELLES = "SEYCHELLES",
  SIERRA_LEONE = "SIERRA_LEONE",
  SINGAPORE = "SINGAPORE",
  SLOVAKIA = "SLOVAKIA",
  SLOVENIA = "SLOVENIA",
  SOLOMON_ISLANDS = "SOLOMON_ISLANDS",
  SOMALIA = "SOMALIA",
  SOUTH_AFRICA = "SOUTH_AFRICA",
  SOUTH_KOREA = "SOUTH_KOREA",
  SOUTH_SUDAN = "SOUTH_SUDAN",
  SPAIN = "SPAIN",
  SRI_LANKA = "SRI_LANKA",
  SUDAN = "SUDAN",
  SURINAME = "SURINAME",
  SWEDEN = "SWEDEN",
  SWITZERLAND = "SWITZERLAND",
  SYRIA = "SYRIA",
  TAJIKISTAN = "TAJIKISTAN",
  TANZANIA = "TANZANIA",
  THAILAND = "THAILAND",
  TIMOR_LESTE = "TIMOR_LESTE",
  TOGO = "TOGO",
  TONGA = "TONGA",
  TRINIDAD_AND_TOBAGO = "TRINIDAD_AND_TOBAGO",
  TUNISIA = "TUNISIA",
  TURKEY = "TURKEY",
  TURKMENISTAN = "TURKMENISTAN",
  TUVALU = "TUVALU",
  UGANDA = "UGANDA",
  UKRAINE = "UKRAINE",
  UNITED_ARAB_EMIRATES = "UNITED_ARAB_EMIRATES",
  UNITED_KINGDOM = "UNITED_KINGDOM",
  ENGLAND = "ENGLAND",
  WALES = "WALES",
  SCOTLAND = "SCOTLAND",
  NORTHERN_IRELAND = "NORTHERN_IRELAND",
  UNITED_STATES_OF_AMERICA = "UNITED_STATES_OF_AMERICA",
  URUGUAY = "URUGUAY",
  UZBEKISTAN = "UZBEKISTAN",
  VANUATU = "VANUATU",
  VATICAN_CITY = "VATICAN_CITY",
  VENEZUELA = "VENEZUELA",
  VIETNAM = "VIETNAM",
  YEMEN = "YEMEN",
  ZAMBIA = "ZAMBIA",
  ZIMBABWE = "ZIMBABWE",
  WEST_INDIES = "WEST_INDIES",
  UNKNOWN = "UNKNOWN",
  ISLE_OF_MAN = "ISLE_OF_MAN",
  ISLE_OF_WIGHT = "ISLE_OF_WIGHT",
  ST_HELENA = "ST_HELENA",
}

export const countryNames: Record<Country, string> = {
  AFGHANISTAN: "Afghanistan",
  ALBANIA: "Albania",
  ALGERIA: "Algeria",
  ANDORRA: "Andorra",
  ANGOLA: "Angola",
  ANTIGUA_AND_BARBUDA: "Antigua and Barbuda",
  ARGENTINA: "Argentina",
  ARMENIA: "Armenia",
  AUSTRALIA: "Australia",
  AUSTRIA: "Austria",
  AZERBAIJAN: "Azerbaijan",
  BAHAMAS: "Bahamas",
  BAHRAIN: "Bahrain",
  BANGLADESH: "Bangladesh",
  BARBADOS: "Barbados",
  BELARUS: "Belarus",
  BELGIUM: "Belgium",
  BELIZE: "Belize",
  BENIN: "Benin",
  BERMUDA: "Bermuda",
  BHUTAN: "Bhutan",
  BOLIVIA: "Bolivia",
  BOSNIA_AND_HERZEGOVINA: "Bosnia and Herzegovina",
  BOTSWANA: "Botswana",
  BRAZIL: "Brazil",
  BRUNEI: "Brunei",
  BULGARIA: "Bulgaria",
  BURKINA_FASO: "Burkina Faso",
  BURUNDI: "Burundi",
  IVORY_COAST: "Ivory Coast",
  CAPE_VERDE: "Cape Verde",
  CAMBODIA: "Cambodia",
  CAMEROON: "Cameroon",
  CANADA: "Canada",
  CAYMAN_ISLANDS: "Cayman Islands",
  CENTRAL_AFRICAN_REPUBLIC: "Central African Republic",
  CHAD: "Chad",
  CHILE: "Chile",
  CHINA: "China",
  COLOMBIA: "Colombia",
  COMOROS: "Comoros",
  CONGO: "Congo",
  COOK_ISLANDS: "Cook Islands",
  COSTA_RICA: "Costa Rica",
  CROATIA: "Croatia",
  CUBA: "Cuba",
  CYPRUS: "Cyprus",
  CZECH_REPUBLIC: "Czech Republic",
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: "Democratic Republic of the Congo",
  DENMARK: "Denmark",
  DJIBOUTI: "Djibouti",
  DOMINICA: "Dominica",
  DOMINICAN_REPUBLIC: "Dominican Republic",
  ECUADOR: "Ecuador",
  EGYPT: "Egypt",
  EL_SALVADOR: "El Salvador",
  EQUATORIAL_GUINEA: "Equatorial Guinea",
  ERITREA: "Eritrea",
  ESTONIA: "Estonia",
  ESWATINI: "Eswatini",
  ETHIOPIA: "Ethiopia",
  FIJI: "Fiji",
  FINLAND: "Finland",
  FRANCE: "France",
  GABON: "Gabon",
  GAMBIA: "Gambia",
  GEORGIA: "Georgia",
  GERMANY: "Germany",
  GHANA: "Ghana",
  GIBRALTAR: "Gibraltar",
  GREECE: "Greece",
  GRENADA: "Grenada",
  GUATEMALA: "Guatemala",
  GUERNSEY: "Guernsey",
  GUINEA: "Guinea",
  GUINEA_BISSAU: "Guinea Bissau",
  GUYANA: "Guyana",
  HAITI: "Haiti",
  HONDURAS: "Honduras",
  HONG_KONG: "Hong Kong",
  HUNGARY: "Hungary",
  ICELAND: "Iceland",
  INDIA: "India",
  INDONESIA: "Indonesia",
  IRAN: "Iran",
  IRAQ: "Iraq",
  IRELAND: "Ireland",
  ISRAEL: "Israel",
  ITALY: "Italy",
  JAMAICA: "Jamaica",
  JAPAN: "Japan",
  JERSEY: "Jersey",
  JORDAN: "Jordan",
  KAZAKHSTAN: "Kazakhstan",
  KENYA: "Kenya",
  KIRIBATI: "Kiribati",
  KUWAIT: "Kuwait",
  KYRGYZSTAN: "Kyrgyzstan",
  LAOS: "Laos",
  LATVIA: "Latvia",
  LEBANON: "Lebanon",
  LESOTHO: "Lesotho",
  LIBERIA: "Liberia",
  LIBYA: "Libya",
  LIECHTENSTEIN: "Liechtenstein",
  LITHUANIA: "Lithuania",
  LUXEMBOURG: "Luxembourg",
  MADAGASCAR: "Madagascar",
  MALAWI: "Malawi",
  MALAYSIA: "Malaysia",
  MALDIVES: "Maldives",
  MALI: "Mali",
  MALTA: "Malta",
  MARSHALL_ISLANDS: "Marshall Islands",
  MAURITANIA: "Mauritania",
  MAURITIUS: "Mauritius",
  MEXICO: "Mexico",
  MICRONESIA: "Micronesia",
  MOLDOVA: "Moldova",
  MONACO: "Monaco",
  MONGOLIA: "Mongolia",
  MONTENEGRO: "Montenegro",
  MOROCCO: "Morocco",
  MOZAMBIQUE: "Mozambique",
  MYANMAR: "Myanmar",
  NAMIBIA: "Namibia",
  NAURU: "Nauru",
  NEPAL: "Nepal",
  NETHERLANDS: "Netherlands",
  NEW_ZEALAND: "New Zealand",
  NICARAGUA: "Nicaragua",
  NIGER: "Niger",
  NIGERIA: "Nigeria",
  NORTH_KOREA: "North Korea",
  NORTH_MACEDONIA: "North Macedonia",
  NORWAY: "Norway",
  OMAN: "Oman",
  PAKISTAN: "Pakistan",
  PALAU: "Palau",
  PALESTINE_STATE: "Palestine State",
  PANAMA: "Panama",
  PAPUA_NEW_GUINEA: "Papua New Guinea",
  PARAGUAY: "Paraguay",
  PERU: "Peru",
  PHILIPPINES: "Philippines",
  POLAND: "Poland",
  PORTUGAL: "Portugal",
  QATAR: "Qatar",
  ROMANIA: "Romania",
  RUSSIA: "Russia",
  RWANDA: "Rwanda",
  SAINT_KITTS_AND_NEVIS: "Saint Kitts and Nevis",
  SAINT_LUCIA: "Saint Lucia",
  SAINT_VINCENT_AND_THE_GRENADINES: "Saint Vincent and the Grenadines",
  SAMOA: "Samoa",
  SAN_MARINO: "San Marino",
  SAO_TOME_AND_PRINCIPE: "Sao Tome and Principe",
  SAUDI_ARABIA: "Saudi Arabia",
  SENEGAL: "Senegal",
  SERBIA: "Serbia",
  SEYCHELLES: "Seychelles",
  SIERRA_LEONE: "Sierra Leone",
  SINGAPORE: "Singapore",
  SLOVAKIA: "Slovakia",
  SLOVENIA: "Slovenia",
  SOLOMON_ISLANDS: "Solomon Islands",
  SOMALIA: "Somalia",
  SOUTH_AFRICA: "South Africa",
  SOUTH_KOREA: "South Korea",
  SOUTH_SUDAN: "South Sudan",
  SPAIN: "Spain",
  SRI_LANKA: "Sri Lanka",
  SUDAN: "Sudan",
  SURINAME: "Suriname",
  SWEDEN: "Sweden",
  SWITZERLAND: "Switzerland",
  SYRIA: "Syria",
  TAJIKISTAN: "Tajikistan",
  TANZANIA: "Tanzania",
  THAILAND: "Thailand",
  TIMOR_LESTE: "Timor Leste",
  TOGO: "Togo",
  TONGA: "Tonga",
  TRINIDAD_AND_TOBAGO: "Trinidad and Tobago",
  TUNISIA: "Tunisia",
  TURKEY: "Turkey",
  TURKMENISTAN: "Turkmenistan",
  TUVALU: "Tuvalu",
  UGANDA: "Uganda",
  UKRAINE: "Ukraine",
  UNITED_ARAB_EMIRATES: "United Arab Emirates",
  UNITED_KINGDOM: "United Kingdom",
  ENGLAND: "England",
  WALES: "Wales",
  SCOTLAND: "Scotland",
  NORTHERN_IRELAND: "Northern Ireland",
  UNITED_STATES_OF_AMERICA: "United States of America",
  URUGUAY: "Uruguay",
  UZBEKISTAN: "Uzbekistan",
  VANUATU: "Vanuatu",
  VATICAN_CITY: "Vatican City",
  VENEZUELA: "Venezuela",
  VIETNAM: "Vietnam",
  YEMEN: "Yemen",
  ZAMBIA: "Zambia",
  ZIMBABWE: "Zimbabwe",
  WEST_INDIES: "West Indies",
  UNKNOWN: "Unknown",
  ISLE_OF_MAN: "Isle of Man",
  ISLE_OF_WIGHT: "Isle of Wight",
  ST_HELENA: "St Helena",
};
