import { ReactNode } from "react";
import {
  AdminPreferences,
  isToggleEnabled,
  ToggleType,
} from "../../preferences/admin-preferences";

export enum StrikeRateAndWicketPercentModuleType {
  BATSMAN_BOWLER_STRENGTH = "BATSMAN_BOWLER_STRENGTH",
  BATSMAN_BY_BALL = "BATSMAN_BY_BALL",
  BATSMAN_STATS = "BATSMAN_STATS",
  POWERPLAY = "POWERPLAY",
  BOWLER_STATS = "BOWLER_STATS",
  GROUND_GLOBAL = "GROUND_GLOBAL",
  GROUND_SWING = "GROUND_SWING",
  HOME_ADVANTAGE = "HOME_ADVANTAGE",
  MATCH_BY_BALL = "MATCH_BY_BALL",
  BOWLER_AGAINST_BAT_TYPE = "BOWLER_AGAINST_BAT_TYPE",
  BOWLING_TYPE_BY_PHASE = "BOWLING_TYPE_BY_PHASE",
  PLAYER_EXPERIENCE = "PLAYER_EXPERIENCE",
  SURGE = "SURGE",
  LEAGUE_STRENGTH = "LEAGUE_STRENGTH",
}

export class StrikeRateAndWicketPercentData {
  subType: string;
  enabled: boolean;
  constructor(enabled: boolean, subType: string) {
    this.enabled = enabled;
    this.subType = subType;
  }
}

export class LeagueStrengthData extends StrikeRateAndWicketPercentData {
  forBatsman: boolean;

  constructor(forBatsman: boolean, enabled: boolean, subType: string) {
    super(enabled, subType);
    this.forBatsman = forBatsman;
  }
}

export class SurgeStrikeRateAndWicketPercentData extends StrikeRateAndWicketPercentData {
  strikeRateBiasInnings1: number;
  wicketPercentBiasInnings1: number;
  strikeRateBiasInnings2: number;
  wicketPercentBiasInnings2: number;

  constructor(
    strikeRateBiasInnings1: number,
    wicketPercentBiasInnings1: number,
    strikeRateBiasInnings2: number,
    wicketPercentBiasInnings2: number,
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.strikeRateBiasInnings1 = strikeRateBiasInnings1;
    this.wicketPercentBiasInnings1 = wicketPercentBiasInnings1;
    this.strikeRateBiasInnings2 = strikeRateBiasInnings2;
    this.wicketPercentBiasInnings2 = wicketPercentBiasInnings2;
  }
}

export class PowerplayStrikeRateAndWicketPercentData extends StrikeRateAndWicketPercentData {
  wicketBias: number;

  constructor(wicketBias: number, enabled: boolean, subType: string) {
    super(enabled, subType);
    this.wicketBias = wicketBias;
  }
}

export class PlayerExperienceData extends StrikeRateAndWicketPercentData {
  battingNoExperienceBiasSr: number;
  battingNoExperienceBiasWpc: number;
  battingFullExperienceBiasSr: number;
  battingFullExperienceBiasWpc: number;

  battingPowerplayNoExperienceBiasSr: number;
  battingPowerplayNoExperienceBiasWpc: number;
  battingPowerplayFullExperienceBiasSr: number;
  battingPowerplayFullExperienceBiasWpc: number;

  battingNonPowerplayNoExperienceBiasSr: number;
  battingNonPowerplayNoExperienceBiasWpc: number;
  battingNonPowerplayFullExperienceBiasSr: number;
  battingNonPowerplayFullExperienceBiasWpc: number;

  bowlingNoExperienceBiasSr: number;
  bowlingNoExperienceBiasWpc: number;
  bowlingFullExperienceBiasSr: number;
  bowlingFullExperienceBiasWpc: number;

  constructor(
    battingNoExperienceBiasSr: number,
    battingNoExperienceBiasWpc: number,
    battingFullExperienceBiasSr: number,
    battingFullExperienceBiasWpc: number,
    battingPowerplayNoExperienceBiasSr: number,
    battingPowerplayNoExperienceBiasWpc: number,
    battingPowerplayFullExperienceBiasSr: number,
    battingPowerplayFullExperienceBiasWpc: number,
    battingNonPowerplayNoExperienceBiasSr: number,
    battingNonPowerplayNoExperienceBiasWpc: number,
    battingNonPowerplayFullExperienceBiasSr: number,
    battingNonPowerplayFullExperienceBiasWpc: number,
    bowlingNoExperienceBiasSr: number,
    bowlingNoExperienceBiasWpc: number,
    bowlingFullExperienceBiasSr: number,
    bowlingFullExperienceBiasWpc: number,
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.battingNoExperienceBiasSr = battingNoExperienceBiasSr;
    this.battingNoExperienceBiasWpc = battingNoExperienceBiasWpc;
    this.battingFullExperienceBiasSr = battingFullExperienceBiasSr;
    this.battingFullExperienceBiasWpc = battingFullExperienceBiasWpc;
    this.battingPowerplayNoExperienceBiasSr =
      battingPowerplayNoExperienceBiasSr;
    this.battingPowerplayNoExperienceBiasWpc =
      battingPowerplayNoExperienceBiasWpc;
    this.battingPowerplayFullExperienceBiasSr =
      battingPowerplayFullExperienceBiasSr;
    this.battingPowerplayFullExperienceBiasWpc =
      battingPowerplayFullExperienceBiasWpc;
    this.battingNonPowerplayNoExperienceBiasSr =
      battingNonPowerplayNoExperienceBiasSr;
    this.battingNonPowerplayNoExperienceBiasWpc =
      battingNonPowerplayNoExperienceBiasWpc;
    this.battingNonPowerplayFullExperienceBiasSr =
      battingNonPowerplayFullExperienceBiasSr;
    this.battingNonPowerplayFullExperienceBiasWpc =
      battingNonPowerplayFullExperienceBiasWpc;
    this.bowlingNoExperienceBiasSr = bowlingNoExperienceBiasSr;
    this.bowlingNoExperienceBiasWpc = bowlingNoExperienceBiasWpc;
    this.bowlingFullExperienceBiasSr = bowlingFullExperienceBiasSr;
    this.bowlingFullExperienceBiasWpc = bowlingFullExperienceBiasWpc;
  }
}

export const strikeRateAndWicketPercentModuleToDataTypes: Record<
  StrikeRateAndWicketPercentModuleType,
  (any) => StrikeRateAndWicketPercentData
> = {
  BATSMAN_BOWLER_STRENGTH: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  BATSMAN_BY_BALL: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  BOWLER_STATS: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  BATSMAN_STATS: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  GROUND_GLOBAL: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  POWERPLAY: (json: any) =>
    new PowerplayStrikeRateAndWicketPercentData(
      json.wicketBias,
      json.enabled,
      json.subType
    ),
  MATCH_BY_BALL: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  HOME_ADVANTAGE: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  GROUND_SWING: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  BOWLER_AGAINST_BAT_TYPE: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  SURGE: (json: any) =>
    new SurgeStrikeRateAndWicketPercentData(
      json.strikeRateBiasInnings1,
      json.wicketPercentBiasInnings1,
      json.strikeRateBiasInnings2,
      json.wicketPercentBiasInnings2,
      json.enabled,
      json.subType
    ),
  PLAYER_EXPERIENCE: (json: any) =>
    new PlayerExperienceData(
      json.battingNoExperienceBiasSr,
      json.battingNoExperienceBiasWpc,
      json.battingFullExperienceBiasSr,
      json.battingFullExperienceBiasWpc,
      json.battingPowerplayNoExperienceBiasSr,
      json.battingPowerplayNoExperienceBiasWpc,
      json.battingPowerplayFullExperienceBiasSr,
      json.battingPowerplayFullExperienceBiasWpc,
      json.battingNonPowerplayNoExperienceBiasSr,
      json.battingNonPowerplayNoExperienceBiasWpc,
      json.battingNonPowerplayFullExperienceBiasSr,
      json.battingNonPowerplayFullExperienceBiasWpc,
      json.bowlingNoExperienceBiasSr,
      json.bowlingNoExperienceBiasWpc,
      json.bowlingFullExperienceBiasSr,
      json.bowlingFullExperienceBiasWpc,
      json.enabled,
      json.subType
    ),
  BOWLING_TYPE_BY_PHASE: (json: any) =>
    new StrikeRateAndWicketPercentData(json.enabled, json.subType),
  LEAGUE_STRENGTH: (json: any) =>
    new LeagueStrengthData(json.forBatsman, json.enabled, json.subType),
};

export const strikeRateAndWicketPercentModuleNames: Record<
  StrikeRateAndWicketPercentModuleType,
  string
> = {
  BATSMAN_BOWLER_STRENGTH: "Batsman bowler style strengths",
  BATSMAN_BY_BALL: "Batsman Acceleration",
  BATSMAN_STATS: "Batting stats by push",
  BOWLER_STATS: "Bowling stats by push",
  POWERPLAY: "Powerplay Wicket Resistance",
  GROUND_GLOBAL: "Ground modifiers",
  GROUND_SWING: "Ground swing up to ball",
  HOME_ADVANTAGE: "Home advantage",
  MATCH_BY_BALL: "Modifiers by ball",
  SURGE: "Surge modifiers",
  BOWLER_AGAINST_BAT_TYPE: "Bowler against Bat Type",
  PLAYER_EXPERIENCE: "Batsman Experience",
  BOWLING_TYPE_BY_PHASE: "Bowling Type by Phase",
  LEAGUE_STRENGTH: "League Strength",
};

export const strikeRateAndWicketPercentModuleTooltips: Record<
  StrikeRateAndWicketPercentModuleType,
  string
> = {
  BATSMAN_BOWLER_STRENGTH:
    "See player batting stats (See admin page for confidence settings)",
  BATSMAN_BY_BALL:
    "See player batting stats (See admin page for confidence settings)",
  BATSMAN_STATS:
    "Batting stats by push settings (See admin page for confidence settings)",
  BOWLER_STATS:
    "Bowling stats by push settings (See admin page for confidence settings)",
  POWERPLAY:
    "Powerplay wicket resistance (See admin page for powerplay settings)",
  GROUND_GLOBAL:
    "Ground stats by push settings (See admin page for confidence settings)",
  GROUND_SWING: "See ground stats - swing up to ball",
  HOME_ADVANTAGE: "See match stats - home advantage",
  MATCH_BY_BALL: "See match stats - match conditions by ball",
  SURGE: "Surge (See admin page for settings)",
  BOWLER_AGAINST_BAT_TYPE:
    "Bowler against Bat Type Settings (See admin page for confidence settings)",
  PLAYER_EXPERIENCE: "Biases for batsmen with less experience",
  BOWLING_TYPE_BY_PHASE:
    "See ground settings (bowling type by phase multipliers)",
  LEAGUE_STRENGTH: "See admin page (league strength settings)",
};

export function deserializeStrikeRateAndWicketPercentModules(
  json: any
): Map<StrikeRateAndWicketPercentModuleType, StrikeRateAndWicketPercentData> {
  const modules: Map<
    StrikeRateAndWicketPercentModuleType,
    StrikeRateAndWicketPercentData
  > = new Map();
  Object.keys(StrikeRateAndWicketPercentModuleType).forEach((moduleType) => {
    modules.set(
      StrikeRateAndWicketPercentModuleType[moduleType],
      strikeRateAndWicketPercentModuleToDataTypes[
        StrikeRateAndWicketPercentModuleType[moduleType]
      ](json[moduleType])
    );
  });
  return modules;
}

export interface StrikeRateAndWicketPercentModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

const playerExperienceNewConfig = [
  {
    humanReadableName: "Powerplay Strike Rate Bias for No Experience",
    propertyName: "battingPowerplayNoExperienceBiasSr",
  },
  {
    humanReadableName: "Powerplay Wicket Bias for No Experience",
    propertyName: "battingPowerplayNoExperienceBiasWpc",
  },
  {
    humanReadableName: "Powerplay Strike Rate Bias for Full Experience",
    propertyName: "battingPowerplayFullExperienceBiasSr",
  },
  {
    humanReadableName: "Powerplay Wicket Bias for Full Experience",
    propertyName: "battingPowerplayFullExperienceBiasWpc",
  },
  {
    humanReadableName: "Non-Powerplay Strike Rate Bias for No Experience",
    propertyName: "battingNonPowerplayNoExperienceBiasSr",
  },
  {
    humanReadableName: "Non-Powerplay Wicket Bias for No Experience",
    propertyName: "battingNonPowerplayNoExperienceBiasWpc",
  },
  {
    humanReadableName: "Non-Powerplay Strike Rate Bias for Full Experience",
    propertyName: "battingNonPowerplayFullExperienceBiasSr",
  },
  {
    humanReadableName: "Non-Powerplay Wicket Bias for Full Experience",
    propertyName: "battingNonPowerplayFullExperienceBiasWpc",
  },
];

const playerExperienceOldConfig = [
  {
    humanReadableName: "Strike Rate Bias for No Experience",
    propertyName: "battingNoExperienceBiasSr",
  },
  {
    humanReadableName: "Wicket Bias for No Experience",
    propertyName: "battingNoExperienceBiasWpc",
  },
  {
    humanReadableName: "Strike Rate Bias for Full Experience",
    propertyName: "battingFullExperienceBiasSr",
  },
  {
    humanReadableName: "Wicket Bias for Full Experience",
    propertyName: "battingFullExperienceBiasWpc",
  },
];

export function strikeRateAndWicketPercentDataProperties(
  adminPreferences: AdminPreferences
): Record<
  StrikeRateAndWicketPercentModuleType,
  StrikeRateAndWicketPercentModuleDataProperty[]
> {
  return {
    BATSMAN_BOWLER_STRENGTH: [],
    BATSMAN_BY_BALL: [],
    BOWLER_STATS: [],
    BATSMAN_STATS: [],
    GROUND_GLOBAL: [],
    GROUND_SWING: [],
    MATCH_BY_BALL: [],
    POWERPLAY: [],
    HOME_ADVANTAGE: [],
    SURGE: [],
    PLAYER_EXPERIENCE: (isToggleEnabled(
      adminPreferences,
      ToggleType.SPLIT_POWERPLAY_AND_NON_POWERPLAY
    )
      ? playerExperienceNewConfig
      : playerExperienceOldConfig
    ).map((config) => ({
      humanReadableName: config.humanReadableName,
      propertyName: config.propertyName,
      type: "number",
      constraints: { min: 0, max: 2, step: 0.01, decimalPlaces: 2 },
      description: null,
    })),
    BOWLER_AGAINST_BAT_TYPE: [],
    BOWLING_TYPE_BY_PHASE: [],
    LEAGUE_STRENGTH: [],
  };
}

export function serializeStrikeRateAndWicketPercentModules(
  modules: Map<
    StrikeRateAndWicketPercentModuleType,
    StrikeRateAndWicketPercentData
  >
): any {
  const result: any = {};
  modules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });
  return result;
}
