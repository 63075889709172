import { Squad } from "../../../types/entities/squad";
import { PlayerSimulationQuestionStatistic } from "../../../types/enums/question-statistic";
import { SimulatorOddsQuestionOperator } from "../../../types/enums/questions-operator";
import { PlayerSimulationMedianQuestion } from "../../../types/simulator/questions/simulation-median-question";
import { PlayerSimulationOddsQuestion } from "../../../types/simulator/questions/simulation-odds-question";
import { UUID } from "../../../types/uuid";

export function buildMedianQuestion(
  playerId: UUID,
  questionStatistic: PlayerSimulationQuestionStatistic
): PlayerSimulationMedianQuestion[] {
  return [
    {
      subType: ".PlayerSimulationMedianQuestion",
      questionStatistic: questionStatistic,
      playerId: playerId.value,
    },
  ];
}

export function buildMedianQuestionsForSquad(
  squad: Squad,
  questionStatistic: PlayerSimulationQuestionStatistic
): PlayerSimulationMedianQuestion[] {
  return squad.players
    .map((player) => {
      return buildMedianQuestion(player.playerId, questionStatistic);
    })
    .flat();
}

export function buildOddsQuestion(
  playerId: UUID,
  questionStatistic: PlayerSimulationQuestionStatistic,
  operand: number
): PlayerSimulationOddsQuestion[] {
  return [
    {
      subType: ".PlayerSimulationOddsQuestion",
      questionStatistic: questionStatistic,
      playerId: playerId.value,
      operator: SimulatorOddsQuestionOperator.GREATER_THAN_OR_EQUAL_TO,
      operand: operand,
    },
  ];
}

export function buildOddsQuestionsForSquad(
  squad: Squad,
  questionStatistic: PlayerSimulationQuestionStatistic,
  medianResponses: number[][]
): PlayerSimulationOddsQuestion[] {
  return medianResponses && medianResponses
    .map((response, index) => {
      if (!squad.players[index]) {
        return null;
      }

      return buildOddsQuestion(
        squad.players[index].playerId,
        questionStatistic,
        response[0]
      );
    })
    .flat();
}
