import { AlertColor } from "@mui/material";
import { createBrowserHistory } from "history";
import { Subject } from "rxjs";

import { ConfirmationDialogProps } from "../components/common-components/confirmation-dialog";
import { BetScraperService } from "../services/bet-scraper-service";
import { BetfairService } from "../services/betfair-service";
import { BallService } from "../services/entity-services/ball-service";
import { CurrentGameService } from "../services/entity-services/current-game-service";
import { GroundService } from "../services/entity-services/ground-service";
import { ImageService } from "../services/entity-services/image-service";
import { MatchFormatService } from "../services/entity-services/match-format-service";
import { MatchService } from "../services/entity-services/match-service";
import { PlayerService } from "../services/entity-services/player-service";
import { SeasonService } from "../services/entity-services/season-service";
import { SeriesService } from "../services/entity-services/series-service";
import { TeamPlayerService } from "../services/entity-services/team-player-service";
import { TeamService } from "../services/entity-services/team-service";
import { GroundStatsService } from "../services/ground-stats-service";
import { HistoricPushService } from "../services/historic-push-service";
import { HttpService } from "../services/http-service";
import { InvestmentService } from "../services/investor-service";
import { KeycloakService } from "../services/keycloak-service";
import { LoggingService } from "../services/logging-service";
import { MatchStatsService } from "../services/match-stats-service";
import { MultipleStageUpdateService } from "../services/multiple-stage-update-service";
import { PercentDistributionService } from "../services/percent-distribution-service";
import { PlayerStatsService } from "../services/player-stats-service";
import { QuestionService } from "../services/question-service";
import { ScraperService } from "../services/scraper-service";
import { SimulationService } from "../services/simulation-service";
import { UserService } from "../services/user-service";
import { WebSocketService } from "../services/web-socket-service";
import { Ball } from "./entities/ball";
import { GameState } from "./entities/game-state";
import { Ground } from "./entities/ground";
import { Image } from "./entities/image";
import { Match } from "./entities/match";
import { MatchFormat } from "./entities/match-format";
import { Player } from "./entities/player";
import { Season } from "./entities/season";
import { Series } from "./entities/series";
import { Team } from "./entities/team";
import { TeamPlayer } from "./entities/team-player";
import { SnackBarMessage } from "./snack-bar-message";
import { GroundStatsWrapper } from "./stats/ground-stats";
import { MatchStatsWrapper } from "./stats/match-stats";
import { PlayerStatsWrapper } from "./stats/player-stats";

const httpService = new HttpService();
const ballService = new BallService(Ball, httpService, "bowl-controller");
const matchService = new MatchService(Match, httpService, "match-controller");
const currentGameService = new CurrentGameService(
  GameState,
  httpService,
  "game-state-controller",
  matchService
);
const teamService = new TeamService(Team, httpService, "team-controller");
const seriesService = new SeriesService(
  Series,
  httpService,
  "series-controller"
);
const seasonService = new SeasonService(
  Season,
  httpService,
  "season-controller"
);
const groundService = new GroundService(
  Ground,
  httpService,
  "ground-controller"
);
const playerService = new PlayerService(
  Player,
  httpService,
  "player-controller"
);
const imageService = new ImageService(Image, httpService, "image-controller");
const teamPlayerService = new TeamPlayerService(
  TeamPlayer,
  httpService,
  "team-player-controller"
);
const matchFormatService = new MatchFormatService(
  MatchFormat,
  httpService,
  "match-format-controller"
);
const keycloakService = new KeycloakService(httpService);
const betfairService = new BetfairService(httpService);
const simulationService = new SimulationService(httpService);
const userService = new UserService(httpService);
const playerStatsService = new PlayerStatsService(
  PlayerStatsWrapper,
  httpService,
  "player-stats-controller"
);
const groundStatsService = new GroundStatsService(
  GroundStatsWrapper,
  httpService,
  "ground-stats-controller"
);
const matchStatsService = new MatchStatsService(
  MatchStatsWrapper,
  httpService,
  "match-stats-controller"
);
const investmentService = new InvestmentService(
  httpService,
  "investment-controller"
);
const webSocketService = new WebSocketService();
const scraperService = new ScraperService(httpService);
const percentDistributionService = new PercentDistributionService(httpService);
const loggingService = new LoggingService(httpService);
const snackbarSubject = new Subject<SnackBarMessage>();
const confirmationSubject = new Subject<ConfirmationDialogProps>();
const historicPushService = new HistoricPushService(httpService);
const questionService = new QuestionService(httpService);
const multipleStageUpdateService = new MultipleStageUpdateService();
const betScraperService = new BetScraperService();

const history = createBrowserHistory();

export interface Services {
  refreshServices();
  httpService: HttpService;
  ballService: BallService;
  matchService: MatchService;
  currentGameService: CurrentGameService;
  teamService: TeamService;
  seriesService: SeriesService;
  seasonService: SeasonService;
  groundService: GroundService;
  playerService: PlayerService;
  imageService: ImageService;
  teamPlayerService: TeamPlayerService;
  matchFormatService: MatchFormatService;
  keycloakService: KeycloakService;
  webSocketService: WebSocketService;
  betfairService: BetfairService;
  simulationService: SimulationService;
  userService: UserService;
  playerStatsService: PlayerStatsService;
  groundStatsService: GroundStatsService;
  matchStatsService: MatchStatsService;
  history;
  snackbarSubject: Subject<SnackBarMessage>;
  confirmationSubject: Subject<ConfirmationDialogProps>;
  scraperService: ScraperService;
  percentDistributionService: PercentDistributionService;
  loggingService: LoggingService;
  investmentService: InvestmentService;
  historicPushService: HistoricPushService;
  questionService: QuestionService;
  multipleStageUpdateService: MultipleStageUpdateService;
  betScraperService: BetScraperService;
}

export const services: Services = {
  refreshServices,
  httpService,
  ballService,
  matchService,
  currentGameService,
  teamService,
  seriesService,
  seasonService,
  groundService,
  playerService,
  imageService,
  teamPlayerService,
  matchFormatService,
  keycloakService,
  webSocketService,
  betfairService,
  simulationService,
  userService,
  playerStatsService,
  groundStatsService,
  matchStatsService,
  history,
  snackbarSubject,
  confirmationSubject,
  scraperService,
  percentDistributionService,
  loggingService,
  investmentService,
  historicPushService,
  questionService,
  multipleStageUpdateService,
  betScraperService,
};

export function showMessage(message: string, color: AlertColor = "info") {
  snackbarSubject.next({ message, color });
}

export function closeConfirmationDialog() {
  confirmationSubject.next(null);
}

export function showConfirmationDialog(
  onProceed: () => void,
  title: string,
  text: string,
  cancelText: string = "NO",
  disabled: boolean = false
) {
  confirmationSubject.next({
    open: true,
    onProceed,
    title,
    text,
    cancelText,
    disabled,
  });
}

export function refreshServices() {
  const isUser = keycloakService.isUser();
  const isBetfair = keycloakService.isBetfair();
  const isAdmin = keycloakService.isAdmin();
  const isInvestorAdmin = keycloakService.isInvestorAdmin();
  const isBetBuilder = keycloakService.isBetBuilder();

  if (isBetBuilder) {
    currentGameService.initMatch();
    keycloakService.updateKnownSimulators();
  }

  if (isUser) {
    playerService.init();
    imageService.init();
    matchFormatService.init();
    teamService.init();
    currentGameService.initMatch();
    seriesService.init();
    seasonService.init();
    groundService.init();
    playerStatsService.initiateLoadedStats();
    groundStatsService.initiateLoadedStats();
    matchStatsService.initiateLoadedStats();
    scraperService.refresh();
    percentDistributionService.refresh();
    simulationService.init();
    keycloakService.updateKnownUsers();
    currentGameService.initBetfairSubscriptions(betfairService);
    betScraperService.init();
  }

  if (isBetfair) {
    betfairService.init();
  }

  if (isInvestorAdmin) {
    keycloakService.updateInvestors();
  }

  investmentService.initiatePots();

  if (isAdmin) {
    historicPushService.initiate();
  }

  keycloakService.init();

  webSocketService.init();

  services.userService.init();
}
