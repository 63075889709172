import { Component, ReactNode } from "react";
import { ReplaySubject, Subject } from "rxjs";
import { Player } from "../../../types/entities/player";
import { services } from "../../../types/services";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import { EntityAutoSelector } from "./entity-auto-selector";

interface Props {
  squad: Squad;
  selectedPlayer: UUID;
  notAvailable: UUID[];
  retired: UUID[];
  label: string;
  onSelect: Function;
  disabled: boolean;
  focussed: boolean;
}

export class TeamPlayerSelector extends Component<Props> {
  private teamPlayers: Subject<Player[]>;
  static defaultProps = {
    disabled: false,
    retired: [],
    focussed: false,
  };

  constructor(props) {
    super(props);
    this.teamPlayers = new ReplaySubject(1);
    this.teamPlayers.next([]);
  }

  componentDidMount(): void {
    this.updateList();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateList();
    }
  }

  private renderOption(props, player: Player): ReactNode {
    if (
      this.props.retired.find((id) => player.playerId.value === id.value) !==
      undefined
    ) {
      return (
        <div {...props} className="retired-batsman italic">
          {player.toString()}
          <div>Retired</div>
        </div>
      );
    } else {
      return player.toString();
    }
  }

  private updateList() {
    let players: Player[];

    if (this.props.squad && this.props.squad.players) {
      players = this.props.squad.players
        .filter(
          (player) =>
            this.props.notAvailable.find(
              (uuid) => uuid.value === player.playerId.value
            ) === undefined
        )
        .sort((player1, player2) =>
          this.props.retired.find(
            (id) => player1.playerId.value === id.value
          ) !== undefined
            ? 1
            : this.props.retired.find(
                (id) => player2.playerId.value === id.value
              ) !== undefined
            ? -1
            : 0
        );
    } else {
      players = [];
    }

    this.teamPlayers.next(players);
  }

  public render() {
    return (
      <div>
        <EntityAutoSelector
          value={
            this.props.squad &&
            this.props.selectedPlayer &&
            this.props.squad.players.find(
              (player) =>
                player.playerId.value === this.props.selectedPlayer.value
            )
          }
          label={this.props.label}
          options={this.teamPlayers}
          optionService={services.playerService}
          canEdit={false}
          canType={false}
          canAddNew={false}
          maxDropdownSize={
            this.props.squad &&
            this.props.squad.players &&
            this.props.squad.players.length
          }
          onSelect={(player) => this.props.onSelect(player)}
          classes="squad-selector"
          disabled={this.props.disabled}
          renderer={(props, option) =>
            this.renderOption(props, option.object as Player)
          }
          focussed={this.props.focussed}
        />
      </div>
    );
  }
}
