import { closeConfirmationDialog } from "../../types/services";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

export interface ConfirmationDialogProps {
  open: boolean;
  onProceed: () => void;
  title: string;
  text: string;
  cancelText?: string;
  disabled?: boolean;
}

export default function ConfirmationDialog({
  open,
  onProceed,
  title,
  text,
  cancelText = "NO",
  disabled = false,
}: Readonly<ConfirmationDialogProps>): React.JSX.Element {
  return (
    <CreationDialog
      open={open}
      label={title}
      invalid={false}
      disabled={disabled}
      onCancel={() => closeConfirmationDialog()}
      onProceed={() => {
        onProceed();
        closeConfirmationDialog();
      }}
      cancelText={cancelText}
      disabledText=""
      proceedText="YES"
      colour="#c83937"
    >
      <div className="confirmation-modal-content">{text}</div>
    </CreationDialog>
  );
}
