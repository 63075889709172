import { UUID } from "../uuid";
import { BetData, deserializeBetData } from "./bet-data";
import { BetDirection } from "./bet-direction";
import { BetResult } from "./bet-result";
import { BetSource } from "./bet-source";
import { BetType } from "./bet-type";

export interface StoredBet {
  storedBetId: UUID;
  sourceId: string;
  sourceEventId: string;
  sourceMarketId: string;
  marketName: string;
  matchId: UUID;
  matchName: string;
  gameStateId: UUID;
  userId: UUID;
  placedTime: number;
  matchedTime: number;
  odds: number;
  originalStake: number;
  matchedStake: number;
  source: BetSource;
  betDirection: BetDirection;
  betResult: BetResult;
  betType: BetType;
  betData: BetData;
}

export function deserializeStoredBets(json: any): {
  [sourceEventId: string]: StoredBet[];
} {
  const groupedBets: { [sourceEventId: string]: StoredBet[] } = {};

  Object.keys(json).forEach((sourceEventId) => {
    groupedBets[sourceEventId] = json[sourceEventId].map((element: any) => ({
      storedBetId:
        (element.storedBetId && UUID.fromString(element.storedBetId)) || null,
      sourceId: element.sourceId,
      sourceEventId: element.sourceEventId,
      sourceMarketId: element.sourceMarketId,
      marketName: element.marketName,
      matchId: (element.matchId && UUID.fromString(element.matchId)) || null,
      matchName: element.matchName,
      gameStateId:
        (element.gameStateId && UUID.fromString(element.gameStateId)) || null,
      userId: (element.userId && UUID.fromString(element.userId)) || null,
      placedTime: element.placedTime,
      matchedTime: element.matchedTime,
      odds: element.odds,
      originalStake: element.originalStake,
      matchedStake: element.matchedStake,
      source: BetSource[element.source],
      betDirection: BetDirection[element.betDirection],
      betResult: BetResult[element.betResult],
      betType: BetType[element.betType],
      betData: deserializeBetData(BetType[element.betType], element.betData),
    }));
  });

  return groupedBets;
}
