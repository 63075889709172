import React from "react";
import { Chart } from "react-chartjs-2";

import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import {
  InningsStatisticType,
  StatisticType,
} from "../../types/enums/statistic-type";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { UUID } from "../../types/uuid";
import { SimulatorOversDistributionDisplay } from "../simulator-page/simulator-overs-distribution-display";

export enum ChartType {
  PLAYER_STRIKE_RATE = "PLAYER_STRIKE_RATE",
  PLAYER_WICKET_PERCENT = "PLAYER_WICKET_PERCENT",
  PLAYER_AVERAGE_PUSH = "PLAYER_AVERAGE_PUSH",

  INNINGS_STRIKE_RATE = "INNINGS_STRIKE_RATE",
  INNINGS_WICKET_PERCENT = "INNINGS_WICKET_PERCENT",
  INNINGS_AVERAGE_PUSH = "INNINGS_AVERAGE_PUSH",
}

export const chartTypeNames: Record<ChartType, string> = {
  PLAYER_STRIKE_RATE: "Strike Rate",
  PLAYER_WICKET_PERCENT: "Wicket Percent",
  PLAYER_AVERAGE_PUSH: "Player Average Push",

  INNINGS_STRIKE_RATE: "Strike Rate",
  INNINGS_WICKET_PERCENT: "Wicket Percent",
  INNINGS_AVERAGE_PUSH: "Innings Average Push",
};

const chartTypeProperties: Record<ChartType, string> = {
  PLAYER_STRIKE_RATE: "playerMeanStrikeRateByBallsFaced",
  PLAYER_WICKET_PERCENT: "playerMeanWicketPercentByBallsFaced",
  PLAYER_AVERAGE_PUSH: "playerMeanPushByBallsFaced",

  INNINGS_STRIKE_RATE: "meanStrikeRateByBall",
  INNINGS_WICKET_PERCENT: "meanWicketPercentByBall",
  INNINGS_AVERAGE_PUSH: "meanPushByBall",
};

interface Props {
  entityId: UUID;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  defaultResult: SimulationResult;
  chartType: ChartType;
  cumulative: boolean;
  includeCurrent: boolean;
  versusDefault: boolean;
  gameState: GameState;
  matchFormat: MatchFormat;
  match: Match;
}

export function BallByBallInningsProgressionChart({
  entityId,
  simulationResult,
  comparedResult,
  defaultResult,
  chartType,
  cumulative,
  includeCurrent,
  versusDefault,
  gameState,
  matchFormat,
  match,
}: Props): React.JSX.Element {
  const getCurrentScore = () => {
    switch (chartType) {
      case ChartType.PLAYER_STRIKE_RATE:
        return gameState.getPlayerIntegerStatAllInnings(
          StatisticType.BATSMAN_RUNS_SCORED,
          entityId
        );
      case ChartType.PLAYER_WICKET_PERCENT:
        return 0;
      case ChartType.INNINGS_STRIKE_RATE:
        return gameState.getBattingTeamIntegerStatAllInnings(
          entityId,
          matchFormat,
          match,
          InningsStatisticType.TOTAL_RUNS
        );
      case ChartType.INNINGS_WICKET_PERCENT:
        return gameState.getBattingTeamIntegerStatAllInnings(
          entityId,
          matchFormat,
          match,
          InningsStatisticType.WICKETS
        );
    }
  };

  const getDataPoint = (
    result: SimulationResult,
    key: number,
    data: number[]
  ) => {
    if (
      !result ||
      !result[chartTypeProperties[chartType]] ||
      !result[chartTypeProperties[chartType]].get(entityId.value) ||
      !result[chartTypeProperties[chartType]].get(entityId.value).get(key)
    ) {
      return null;
    }
    return (
      result[chartTypeProperties[chartType]].get(entityId.value).get(key) /
        (chartType === ChartType.PLAYER_STRIKE_RATE ||
        chartType === ChartType.INNINGS_STRIKE_RATE
          ? 100
          : 1) +
      (cumulative ? data[data.length - 1] || 0 : 0) +
      (cumulative && includeCurrent && key === 0 ? getCurrentScore() : 0)
    );
  };

  /*
    Builds a 2d array of points for display in the chart:
    array[0] = x axis labels
    array[1] = y values for the latest user simulation
    array[2] = y values for the latest compared user simulation
    array[3] = y values for the latest default simulation
  */
  const buildData = () => {
    const data: number[][] = [[], [], [], []];
    const result = simulationResult || defaultResult || comparedResult;
    if (result && entityId) {
      const map: Map<number, number> = result[
        chartTypeProperties[chartType]
      ].get(entityId.value);
      map &&
        map.forEach((value, key) => {
          data[0].push(key);
          data[1].push(getDataPoint(simulationResult, key, data[1]));
          data[2].push(getDataPoint(comparedResult, key, data[2]));
          data[3].push(getDataPoint(defaultResult, key, data[3]));
        });
    }

    return data;
  };

  const buildDataset = (
    dataLabels: number[],
    chartLabel: string,
    data: number[],
    colourIndex: number
  ) => {
    return {
      type: "line" as const,
      label: chartLabel,
      fill: true,
      labels: dataLabels,
      data,
      borderColor: SimulatorOversDistributionDisplay.playerColours[colourIndex],
      backgroundColor:
        SimulatorOversDistributionDisplay.playerColours[colourIndex] + "20",
    };
  };

  const data = buildData();
  const datasets = [];
  datasets.push(buildDataset(data[0], "User Sim", data[1], 0));
  datasets.push(buildDataset(data[0], "Compared User Sim", data[2], 1));
  datasets.push(buildDataset(data[0], "Default Sim", data[3], 2));

  if (versusDefault) {
    data[3].forEach((value, index) => {
      if (!!data[1][index]) {
        data[1][index] = data[1][index] - value;
      }
      if (!!data[2][index]) {
        data[2][index] = data[2][index] - value;
      }
      data[3][index] = 0;
    });
  }

  return (
    <div className="player-innings-progression-chart">
      {data[0].length > 0 && (
        <Chart
          type="bar"
          height={300}
          width={800}
          options={{
            scales: {
              x: {
                title: {
                  text: "Ball Number",
                },
                stacked: false,
              },
            },
          }}
          data={{
            labels: data[0],
            datasets,
          }}
        />
      )}
      {data[0].length === 0 && (
        <div className="italic" style={{ minHeight: "300px" }}>
          No simulation data
        </div>
      )}
    </div>
  );
}
