import { Button } from "@mui/material";
import { Component } from "react";
import { services } from "../../types/services";
import NumberSelector from "../entity-management/entity-selectors/number-selector";

interface State {
  profit: number;
  volumeTraded: number;
}

export class AdminTransactionInputComponent extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      profit: 0,
      volumeTraded: 0,
    };
  }

  private valid() {
    return !!this.state.profit && !!this.state.volumeTraded;
  }

  private resetData() {
    return this.setState({ profit: 0, volumeTraded: 0 });
  }

  public render() {
    return (
      <div className="deposit-and-withdrawal-components">
        <div className="investor-page-title">Add Profit/Loss</div>
        <div className="amount-selector">
          <div>Profit:</div>
          <NumberSelector
            min={-1000000}
            max={1000000}
            step={1}
            decimalPlaces={2}
            initial={this.state.profit}
            onValid={(valid: number) => this.setState({ profit: valid })}
          />
        </div>

        <div className="amount-selector">
          <div>Volume Traded:</div>
          <NumberSelector
            min={0}
            max={100000000}
            step={1}
            decimalPlaces={2}
            initial={this.state.volumeTraded}
            onValid={(valid: number) => this.setState({ volumeTraded: valid })}
          />
        </div>

        <Button
          onClick={() =>
            services.investmentService
              .profitAndLossUpdate(this.state.profit, this.state.volumeTraded)
              .then(() => this.resetData())
          }
          disabled={!this.valid()}
          variant="contained"
          color="primary"
        >
          Go
        </Button>
      </div>
    );
  }
}
