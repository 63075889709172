import { Component } from "react";
import { Subscription, Observable } from "rxjs";
import { GameState } from "../../types/entities/game-state";
import { Player } from "../../types/entities/player";
import { MatchRole } from "../../types/enums/match-role";
import { services } from "../../types/services";
import { Squad } from "../../types/entities/squad";
import { propsEqual } from "../component-utils";
import { PlayerSelector } from "../entity-management/entity-selectors/player-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (newPlayer: Player) => void;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
}

interface State {
  newPlayer: Player;
  allPlayers: Player[];
}

export class AddPlayerModal extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.state = {
      newPlayer: null,
      allPlayers: [],
    };
    this.subscriptions = [];
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.playerService.getAll().subscribe((players: Player[]) => {
        this.setState({ allPlayers: players });
      })
    );
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({
        newPlayer: null,
      });
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private handlePlayerChange(newPlayer: Player) {
    this.setState({ newPlayer });
  }

  private invalid(): boolean {
    return this.state.newPlayer === null;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Add Player"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.newPlayer)}
        proceedText="OK"
        colour="#a834eb"
      >
        <div className="substitute-modal-content">
          New Player:
          <PlayerSelector
            value={this.state.newPlayer}
            onSelect={(player: Player) => this.handlePlayerChange(player)}
            options={this.eligiblePlayersToComeOnObservable()}
          />
        </div>
      </CreationDialog>
    );
  }

  private eligiblePlayersToComeOnObservable(): Observable<Player[]> {
    const eligiblePlayers = this.state.allPlayers.filter((p) => {
      const playerInSquad1 =
        !!this.props.squad1 &&
        this.props.squad1.players.find(
          (squadPlayer) => p.playerId.value === squadPlayer.playerId.value
        );
      const playerInSquad2 =
        !!this.props.squad2 &&
        this.props.squad2.players.find(
          (squadPlayer) => p.playerId.value === squadPlayer.playerId.value
        );

      const teamPlayerInSquad1 =
        !!this.props.gameState &&
        this.props.gameState.squads[0].find(
          (tp) => tp.playerId.value === p.playerId.value
        );
      const teamPlayerInSquad2 =
        !!this.props.gameState &&
        this.props.gameState.squads[1].find(
          (tp) => tp.playerId.value === p.playerId.value
        );

      return (
        (!this.props.squad1 ||
          playerInSquad1 === undefined ||
          (playerInSquad1 !== undefined &&
            !!teamPlayerInSquad1 &&
            teamPlayerInSquad1.matchRole === MatchRole.REMOVED)) &&
        (!this.props.squad2 ||
          playerInSquad2 === undefined ||
          (playerInSquad2 !== undefined &&
            !!teamPlayerInSquad2 &&
            teamPlayerInSquad2.matchRole === MatchRole.REMOVED))
      );
    });

    return new Observable((subscriber) => subscriber.next(eligiblePlayers));
  }
}
