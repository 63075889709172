import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { KeycloakUser } from "../../services/keycloak-service";
import { services } from "../../types/services";
import {
  format,
  sortInvestorListRowsByColumnNumber,
} from "../simulator-page/simulator-utils";
import {
  InvestorPotStatus,
  PotSummary,
} from "../../types/investment/pot-summary";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  investors: KeycloakUser[];
  potSummary: PotSummary;
}

interface State {
  sortColumn: number;
  sortDirection: "asc" | "desc";
  loading: boolean;
  selectedUser: KeycloakUser;
}

export class InvestorList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortColumn: 0,
      sortDirection: "asc",
      loading: false,
      selectedUser: null,
    };
  }

  private sort(column: number, direction: "asc" | "desc") {
    this.setState({
      sortColumn: column,
      sortDirection: direction,
    });
  }

  private selectRow(user: KeycloakUser) {
    this.setState({
      selectedUser: this.state.selectedUser === user ? null : user,
    });
  }

  private buildTableRow(user: KeycloakUser) {
    return (
      <TableRow
        key={`investor-table-row-${user.id}`}
        style={{
          fontSize: "small",
          backgroundColor:
            !!this.state.selectedUser && this.state.selectedUser.id === user.id
              ? "#cccccc"
              : "",
        }}
        id="investor-table-row"
        onClick={() => this.selectRow(user)}
      >
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`investor-table-cell-${user.id}-id`}
        >
          {this.getAttribute(user, "Party ID")}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`investor-table-cell-${user.id}-name`}
        >
          {user.name}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`investor-table-cell-${user.id}-type`}
        >
          {services.keycloakService.getInvestorType(user)}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`investor-table-cell-${user.id}-percent`}
        >
          {this.getPercentOfPot(user)}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`investor-table-cell-${user.id}-percent`}
        >
          {this.getPot(user)}
        </TableCell>
      </TableRow>
    );
  }

  private buildAddress(user: KeycloakUser): ReactNode {
    if (!!user.attributes) {
      const address = [
        user.attributes.get("Address 1"),
        user.attributes.get("Address 2"),
        user.attributes.get("Address 3"),
        user.attributes.get("Address 4"),
        user.attributes.get("Postcode"),
      ]
        .filter((part) => !!part)
        .join(", ");
      return address !== "" ? address : <div className="italic">Unknown</div>;
    } else {
      return <div className="italic">Unknown</div>;
    }
  }

  private getAttribute(user: KeycloakUser, attributeName: string) {
    if (
      !!user.attributes &&
      user.attributes.get(attributeName) !== null &&
      user.attributes.get(attributeName) !== undefined
    ) {
      return user.attributes.get(attributeName);
    } else {
      return <div className="italic">Unknown</div>;
    }
  }

  private getPercentOfPot(user: KeycloakUser): ReactNode {
    if (!!user && !!this.props.potSummary) {
      const potTotal: number = this.props.potSummary.potStatus.totalHoldings;
      const userStatus: InvestorPotStatus =
        this.props.potSummary.investorPotStatusMap.get(user.id);
      const userTotal = !!userStatus ? userStatus.totalHoldings : 0;

      return format(potTotal === 0 ? 0 : 100 * (userTotal / potTotal));
    } else {
      return "Unknown";
    }
  }

  private getPot(user: KeycloakUser): ReactNode {
    if (!!user && !!this.props.potSummary) {
      const userStatus: InvestorPotStatus =
        this.props.potSummary.investorPotStatusMap.get(user.id);
      const userTotal = !!userStatus ? userStatus.totalHoldings : 0;

      return format(userTotal);
    } else {
      return "Unknown";
    }
  }

  private buildTableHeader(headerName: string, index: number) {
    return (
      <TableCell
        align="left"
        id={`investor-list-table-header-${index}`}
        style={{ backgroundColor: "#dddddd" }}
      >
        <TableSortLabel
          active={this.state.sortColumn === index}
          direction={
            this.state.sortColumn === index ? this.state.sortDirection : "asc"
          }
          onClick={() =>
            this.sort(
              index,
              this.state.sortColumn === index &&
                this.state.sortDirection === "asc"
                ? "desc"
                : "asc"
            )
          }
        >
          {headerName}
        </TableSortLabel>
      </TableCell>
    );
  }

  public render() {
    const headers: ReactNode[] = [
      "ID",
      "Name",
      "Investor Type",
      "Investment %",
      "Investment £",
    ].map((headerName: string, index: number) =>
      this.buildTableHeader(headerName, index)
    );
    const unsorted = this.props.investors.map(
      (user: KeycloakUser, index: number) => this.buildTableRow(user)
    );
    const rows: ReactNode[] = sortInvestorListRowsByColumnNumber(
      unsorted,
      this.state.sortColumn,
      this.state.sortDirection
    );

    return (
      <div className="investor-list-container">
        <div className="investor-list-title">
          <div className="investor-page-title">Investors</div>
          <TooltipIconButton
            icon={"refresh"}
            disabled={false}
            onClick={() => services.keycloakService.updateInvestors()}
            title={"Refresh Investors"}
          />
        </div>
        <div
          className={
            !!this.state.selectedUser
              ? "investor-list-expanded"
              : "investor-list-collapsed"
          }
        >
          <TableContainer>
            <Table size="small" aria-label="investor list table">
              <TableHead>
                <TableRow>{headers}</TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
          {!!this.state.selectedUser && (
            <div className="investor-more-info">
              <div>
                <div className="investor-info-subtitle">Personal Details</div>
                <div>
                  Nickname:{" "}
                  {this.getAttribute(this.state.selectedUser, "Nickname")}
                </div>
                <div>Address: {this.buildAddress(this.state.selectedUser)}</div>
                <div>Email: {this.state.selectedUser.email}</div>
              </div>

              <div>
                <div className="investor-info-subtitle">Bank Details</div>
                <div>
                  Bank: {this.getAttribute(this.state.selectedUser, "Bank")}
                </div>
                <div>
                  Account Name:{" "}
                  {this.getAttribute(
                    this.state.selectedUser,
                    "Bank Account Name"
                  )}
                </div>
                <div>
                  Account Number:{" "}
                  {this.getAttribute(
                    this.state.selectedUser,
                    "Bank Account Number"
                  )}
                </div>
                <div>
                  Sort Code:{" "}
                  {this.getAttribute(this.state.selectedUser, "Bank Sort Code")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
