import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import {
  CalculatedStatisticType,
  StatisticType,
} from "../../types/enums/statistic-type";
import { SimulatorRoute } from "../../types/route-helpers";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { nanSafeWithDefault } from "../../types/util-functions";
import { compareValues } from "../component-utils";

interface Props {
  player: Player;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  team: number;
  gameState: GameState;
  matchFormat: MatchFormat;
  fromThisPoint: boolean;
}

interface TableValues {
  bowlingAvg: number;
  bowlingSr: number;
  bowlingEco: number;
  bowlingBallsAvg: number;
  bowlingMeanPush: number;
  bowlingMeanSrBias: number;
  bowlingMeanWpcBias: number;
  currentBalls: number;
  playerBowled: boolean;
}

export class PlayerBowlingSimulationDisplay extends Component<Props> {
  private readonly didNotBowl = (
    <TableRow key={"sim-stats" + this.props.player.playerId.value}>
      <TableCell colSpan={6} id="player-simulation-stats-table-cell">
        Did Not Bowl
      </TableCell>
    </TableRow>
  );

  private buildRow(values: TableValues, comparedValues: TableValues) {
    const comparedStyle = !!comparedValues
      ? {
          backgroundColor: "#282c34",
          fontSize: "x-small",
          color: "white",
        }
      : null;
    const bowlingAvgColour = !!comparedValues
      ? compareValues(comparedValues.bowlingAvg, values.bowlingAvg)
      : "black";
    const bowlingSrColour = !!comparedValues
      ? compareValues(comparedValues.bowlingSr, values.bowlingSr)
      : "black";
    const bowlingEcoColour = !!comparedValues
      ? compareValues(comparedValues.bowlingEco, values.bowlingEco)
      : "black";
    const bowlingBallsAvgColour = !!comparedValues
      ? compareValues(comparedValues.bowlingBallsAvg, values.bowlingBallsAvg)
      : "black";
    const bowlingMeanPushColour = !!comparedValues
      ? compareValues(comparedValues.bowlingMeanPush, values.bowlingMeanPush)
      : "black";
    const bowlingMeanSrBiasColour = !!comparedValues
      ? compareValues(
          comparedValues.bowlingMeanSrBias,
          values.bowlingMeanSrBias
        )
      : "black";
    const bowlingMeanWpcBiasColour = !!comparedValues
      ? compareValues(
          comparedValues.bowlingMeanWpcBias,
          values.bowlingMeanWpcBias
        )
      : "black";

    return this.props.gameState.started && !values.playerBowled ? (
      this.didNotBowl
    ) : (
      <TableRow
        key={"sim-stats" + this.props.player.playerId.value}
        className={!!comparedValues ? "comparison" : ""}
      >
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingAvgColour }}
        >
          {values.bowlingAvg}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingSrColour }}
        >
          {values.bowlingSr}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingEcoColour }}
        >
          {values.bowlingEco}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingBallsAvgColour }}
        >
          {values.bowlingBallsAvg}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingMeanPushColour }}
        >
          {values.bowlingMeanPush}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingMeanSrBiasColour }}
        >
          {values.bowlingMeanSrBias}
        </TableCell>
        <TableCell
          align="right"
          id="player-simulation-stats-table-cell"
          style={{ ...comparedStyle, color: bowlingMeanWpcBiasColour }}
        >
          {values.bowlingMeanWpcBias}
        </TableCell>
      </TableRow>
    );
  }

  private getValues(result: SimulationResult, innings: number): TableValues {
    const bowlingRunsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_RUNS
        : CalculatedStatisticType.TEAM_2_BOWLER_RUNS;
    const bowlingBallsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_BALLS
        : CalculatedStatisticType.TEAM_2_BOWLER_BALLS;
    const bowlingDismissalsStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_WICKETS
        : CalculatedStatisticType.TEAM_2_BOWLER_WICKETS;
    const bowlingPushStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_PUSH
        : CalculatedStatisticType.TEAM_2_BOWLER_PUSH;
    const bowlingSRBiasStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_EXPECTED_SR
        : CalculatedStatisticType.TEAM_2_BOWLER_EXPECTED_SR;
    const bowlingWPCBiasStatistic: CalculatedStatisticType =
      this.props.team === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_EXPECTED_WPC
        : CalculatedStatisticType.TEAM_2_BOWLER_EXPECTED_WPC;

    const numberOfSimulations = result.numberOfSimulations;
    const currentRuns = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_RUNS_CONCEDED,
      this.props.player.playerId
    );
    const currentWickets = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_WICKETS_TAKEN,
      this.props.player.playerId
    );
    const currentBalls = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_BALLS_BOWLED,
      this.props.player.playerId
    );
    const currentPush = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_TOTAL_PUSH,
      this.props.player.playerId
    );
    const currentSrBias = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_TOTAL_EXPECTED_SR,
      this.props.player.playerId
    );
    const currentWpcBias = this.props.gameState.getPlayerNumberStatForInnings(
      innings + 1,
      StatisticType.BOWLER_TOTAL_EXPECTED_WPC,
      this.props.player.playerId
    );

    const totalBowlingRuns =
      (result &&
        result.playerTotals.get(bowlingRunsStatistic) &&
        result.playerTotals
          .get(bowlingRunsStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint ? currentRuns * numberOfSimulations : 0)) ||
      0;
    const totalBowlingBalls =
      result &&
      result.playerTotals.get(bowlingBallsStatistic) &&
      result.playerTotals
        .get(bowlingBallsStatistic)
        .get(this.props.player.playerId.value) -
        (this.props.fromThisPoint ? currentBalls * numberOfSimulations : 0);
    const totalBowlingDismissals =
      (result &&
        result.playerTotals.get(bowlingDismissalsStatistic) &&
        result.playerTotals
          .get(bowlingDismissalsStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint
            ? currentWickets * numberOfSimulations
            : 0)) ||
      0;
    const totalBowlingPush =
      (result &&
        result.playerTotals.get(bowlingPushStatistic) &&
        result.playerTotals
          .get(bowlingPushStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint ? currentPush * numberOfSimulations : 0)) ||
      0;
    const totalBowlingSrBias =
      (result &&
        result.playerTotals.get(bowlingSRBiasStatistic) &&
        result.playerTotals
          .get(bowlingSRBiasStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint
            ? currentSrBias * numberOfSimulations
            : 0)) ||
      0;
    const totalBowlingWpcBias =
      (result &&
        result.playerTotals.get(bowlingWPCBiasStatistic) &&
        result.playerTotals
          .get(bowlingWPCBiasStatistic)
          .get(this.props.player.playerId.value) -
          (this.props.fromThisPoint
            ? currentWpcBias * numberOfSimulations
            : 0)) ||
      0;

    const bowlingAvg = nanSafeWithDefault(
      (totalBowlingRuns / totalBowlingDismissals).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingSr = nanSafeWithDefault(
      (totalBowlingBalls / totalBowlingDismissals).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingEco = nanSafeWithDefault(
      (totalBowlingRuns / totalBowlingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingBallsAvg = nanSafeWithDefault(
      (totalBowlingBalls / numberOfSimulations).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingMeanPush = nanSafeWithDefault(
      (totalBowlingPush / totalBowlingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingMeanSrBias = nanSafeWithDefault(
      (totalBowlingSrBias / totalBowlingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
    const bowlingMeanWpcBias = nanSafeWithDefault(
      (totalBowlingWpcBias / totalBowlingBalls).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );

    const playerBowled =
      (!!totalBowlingBalls && totalBowlingBalls !== 0) ||
      (!!currentBalls && currentBalls !== 0);

    return {
      bowlingAvg,
      bowlingSr,
      bowlingEco,
      bowlingBallsAvg,
      bowlingMeanPush,
      bowlingMeanSrBias,
      bowlingMeanWpcBias,
      currentBalls,
      playerBowled,
    };
  }

  public render() {
    if (!this.props.simulationResult) {
      return <div />;
    }

    const innings = this.props.gameState.findFirstBowlingInningsForTeam(
      this.props.team,
      this.props.matchFormat
    );

    const currentValues = this.getValues(this.props.simulationResult, innings);
    const rows = [this.buildRow(currentValues, null)];
    let comparedValues = null;
    if (!!this.props.comparedResult) {
      comparedValues = this.getValues(this.props.comparedResult, innings);
      rows.push(this.buildRow(comparedValues, currentValues));
    }

    let neverBowled = false;

    if (
      !currentValues.playerBowled &&
      (!comparedValues || !comparedValues.playerBowled)
    ) {
      neverBowled = true;
    }

    return (
      <SimulatorRoute>
        <div className="player-simulation-stats">
          {!neverBowled && (
            <TableContainer>
              <Table aria-label="player simulator stats table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-0"
                      colSpan={5}
                    >
                      Bowling
                    </TableCell>
                    <TableCell
                      align="left"
                      id="player-simulation-stats-table-header-group-3"
                      colSpan={2}
                    >
                      Bias
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Average
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      SR
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Eco
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Balls
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      Push
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      SR
                    </TableCell>
                    <TableCell
                      align="right"
                      id="player-simulation-stats-table-cell"
                    >
                      W%
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          )}
          {neverBowled && (
            <div className="player-simulation-stats-blank">Did Not Bowl</div>
          )}
        </div>
      </SimulatorRoute>
    );
  }
}
