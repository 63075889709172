import { UUID } from "../uuid";
import { Entity } from "./entity";

export class Series implements Entity {
  public entityId: UUID;
  public seriesId: UUID;
  public seasonId: UUID;
  public name: string;
  public dateDescription: string;
  public createdAt: number;
  public international: boolean;
  public createdBy: UUID;

  constructor(
    entityId: UUID,
    seriesId: UUID,
    seasonId: UUID,
    name: string,
    dateDescription: string,
    createdAt: number,
    international: boolean,
    createdBy: UUID
  ) {
    this.entityId = entityId;
    this.seriesId = seriesId;
    this.seasonId = seasonId;
    this.name = name;
    this.dateDescription = dateDescription;
    this.createdAt = createdAt;
    this.international = international;
    this.createdBy = createdBy;
  }

  public toString(): string {
    return this.name + " (" + this.dateDescription + ")";
  }

  public static deserializeList(json: any) {
    const series: Series[] = [];
    json.forEach((element) => {
      series.push(this.deserializeOne(element));
    });
    return series.sort();
  }

  public static deserializeOne(responseJSON: any): Series {
    return new Series(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.seriesId),
      UUID.fromString(responseJSON.seasonId),
      responseJSON.name,
      responseJSON.dateDescription,
      responseJSON.createdAt,
      responseJSON.international,
      UUID.fromString(responseJSON.createdBy)
    );
  }

  public static emptyWithName(name: string): Series {
    return new Series(null, null, null, name, null, null, false, null);
  }

  public static getTypeName(): string {
    return "series";
  }

  public static serialize(series: Series): any {
    return {
      entityId: series.entityId === null ? null : series.entityId.value,
      seriesId: series.seriesId === null ? null : series.seriesId.value,
      seasonId: series.seasonId === null ? null : series.seasonId.value,
      name: series.name,
      dateDescription: series.dateDescription,
      createdAt: series.createdAt,
      international: series.international,
      createdBy: series.createdBy === null ? null : series.createdBy.value,
    };
  }
}
