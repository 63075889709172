import { Component } from "react";
import { Subscription } from "rxjs";
import { services } from "../../types/services";
import { Series } from "../../types/entities/series";
import { Season } from "../../types/entities/season";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { MatchInfo } from "../../types/entities/match-info";
import { observableFrom } from "../component-utils";
import { CircularProgress } from "@mui/material";

interface State {
  serieses: Series[];
  matches: MatchInfo[];
  selectedMatch: MatchInfo;
  selectedSeason: Season;
  selectedSeries: Series;
  seriesLoading: boolean;
  matchesLoading: boolean;
}

export class ScrapedMatchFilter extends Component<{}, State> {
  public subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      serieses: [],
      matches: [],
      selectedMatch: null,
      selectedSeason: null,
      selectedSeries: null,
      seriesLoading: false,
      matchesLoading: false,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.seriesService.seriesForSeasonSubject.subscribe(
        (serieses: Series[]) =>
          this.setState({ serieses, seriesLoading: false })
      )
    );

    this.subscriptions.push(
      services.matchService.matchesForSeriesSubject.subscribe(
        (matches: MatchInfo[]) =>
          this.setState({ matches, matchesLoading: false })
      )
    );

    this.subscriptions.push(
      services.historicPushService.historicPushSelectedMatch.subscribe(
        (selectedMatch: MatchInfo) =>
          this.setState({ selectedMatch }, () => this.updateEventsForMatch())
      )
    );

    this.subscriptions.push(
      services.historicPushService.historicPushSelectedSeries.subscribe(
        (selectedSeries: Series) =>
          this.setState({ selectedSeries }, () => this.updateMatchesForSeries())
      )
    );

    this.subscriptions.push(
      services.historicPushService.historicPushSelectedSeason.subscribe(
        (selectedSeason: Season) =>
          this.setState({ selectedSeason }, () => this.updateSeriesForSeason())
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateSeriesForSeason() {
    this.setState({ seriesLoading: true }, () =>
      services.seriesService.getSeriesForSeason(this.state.selectedSeason)
    );
  }

  private updateMatchesForSeries() {
    this.setState({ matchesLoading: true }, () =>
      services.matchService.getMatchesForSeries(this.state.selectedSeries)
    );
  }

  private selectEntities(season: Season, series: Series) {
    services.historicPushService.historicPushSelectedSeason.next(season);
    services.historicPushService.historicPushSelectedSeries.next(series);
    services.historicPushService.historicPushSelectedMatch.next(null);
  }

  private updateEventsForMatch() {
    if (!!this.state.selectedMatch) {
      services.ballService.getEventsForMatch(this.state.selectedMatch.matchId);
      services.matchService.getScrapedMatch(this.state.selectedMatch.matchId);
    } else {
      services.ballService.ballsForMatchSubject.next([]);
      services.matchService.getScrapedMatch(null);
    }
  }

  public render() {
    return (
      <div className="scraped-matches-filters">
        <EntityAutoSelector
          classes="scraped-match-filter"
          value={this.state.selectedSeason}
          label={null}
          options={services.seasonService.getAll()}
          optionService={services.seasonService}
          canAddNew={false}
          canEdit={false}
          disabled={false}
          onSelect={(entity: Season) => this.selectEntities(entity, null)}
        />
        {!this.state.seriesLoading && (
          <EntityAutoSelector
            classes="scraped-match-filter"
            value={this.state.selectedSeries}
            label={null}
            options={observableFrom(this.state.serieses)}
            optionService={services.seriesService}
            canAddNew={false}
            disabled={!!!this.state.selectedSeason}
            canEdit={false}
            onSelect={(entity: Series) =>
              this.selectEntities(this.state.selectedSeason, entity)
            }
          />
        )}
        {this.state.seriesLoading && <CircularProgress />}
        {!this.state.matchesLoading && (
          <EntityAutoSelector
            classes="scraped-match-filter"
            value={this.state.selectedMatch}
            label={null}
            options={observableFrom(this.state.matches)}
            optionService={services.matchService}
            canAddNew={false}
            disabled={!!!this.state.selectedSeries}
            canEdit={false}
            onSelect={(entity: MatchInfo) =>
              services.historicPushService.historicPushSelectedMatch.next(
                entity
              )
            }
          />
        )}
        {this.state.matchesLoading && <CircularProgress />}
      </div>
    );
  }
}
