import { Squad } from "../../types/entities/squad";
import { TeamPlayer } from "../../types/entities/team-player";
import { MatchRole } from "../../types/enums/match-role";
import { EntityService } from "./entity-service";
import { UUID } from "../../types/uuid";
import { showMessage } from "../../types/services";
import { Player } from "../../types/entities/player";
import { RecentSquad } from "../../types/entities/recent-squad";

export class TeamPlayerService extends EntityService<TeamPlayer> {
  public addSquad(squad: Squad): Promise<TeamPlayer>[] {
    const promises: Promise<TeamPlayer>[] = [];
    squad.players.forEach((player, index) => {
      const teamPlayer: TeamPlayer = new TeamPlayer(
        null,
        null,
        null,
        null,
        squad.matchId,
        squad.teamId,
        player.playerId,
        index,
        MatchRole.PLAYER,
        -1
      );

      promises.push(
        this.add(teamPlayer).catch((reason) => {
          showMessage(`Failed to add team player: ${reason}`, "error");
          return null;
        })
      );
    });
    return promises;
  }

  public async getSquad(matchId: UUID, teamId: UUID): Promise<Squad> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("teamId", teamId.value);
    return await this.httpService
      .get("/api/" + this.controllerName + "/get-squad", params)
      .then((response: any) => {
        return Squad.deserializeOne(response);
      })
      .catch((reason: any) => {
        showMessage(`Failed to load squad: ${reason}`, "error");
        return null;
      });
  }

  public async getRecentSquads(
    page: number,
    teamId: UUID
  ): Promise<RecentSquad[]> {
    const params: Map<string, string> = new Map();
    params.set("page", "" + page);
    params.set("teamId", teamId.value);
    return await this.httpService
      .get("/api/" + this.controllerName + "/get-recent-squads", params)
      .then((response: any) => {
        return RecentSquad.deserializeList(response);
      })
      .catch((reason: any) => {
        showMessage(
          `Failed to load page ${page} of recent squads: ${reason}`,
          "error"
        );
        return null;
      });
  }

  public getPlayerName(playerId: UUID, squad: Squad): string {
    if (squad && squad.players && playerId) {
      const player: Player = squad.players.find(
        (player) => player.playerId.value === playerId.value
      );
      if (player === undefined) {
        return "Unknown";
      } else {
        return player.longName;
      }
    } else {
      return "Unknown";
    }
  }

  public getPlayer(playerId: UUID, squad1: Squad, squad2: Squad): Player {
    if (squad1 && squad1.players && squad2 && squad2.players && playerId) {
      const playerInSquad1: Player = squad1.players.find(
        (player) => player.playerId.value === playerId.value
      );
      if (!!playerInSquad1) {
        return playerInSquad1;
      }
      return (
        squad2.players.find(
          (player) => player.playerId.value === playerId.value
        ) || null
      );
    } else {
      return null;
    }
  }
}
