export enum IncidentType {
  UNKNOWN_INCIDENT = "UNKNOWN_INCIDENT",
  OUT = "OUT",
  STRIKE = "STRIKE",
  INNING_END = "INNING_END",
  BYE = "BYE",
  LEG_BYE = "LEG_BYE",
  NO_BALL = "NO_BALL",
  WIDE = "WIDE",
}

export const incidentTypeNames: Record<IncidentType, string> = {
  UNKNOWN_INCIDENT: "",
  OUT: "Out",
  STRIKE: "Run",
  INNING_END: "Innings End",
  BYE: "Bye",
  LEG_BYE: "Leg Bye",
  NO_BALL: "No Ball",
  WIDE: "Wide",
};
