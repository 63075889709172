import { Button, Checkbox } from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";

import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TeamPlayer } from "../../types/entities/team-player";
import { activeMatchRoles } from "../../types/enums/match-role";
import { UserPreferences } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { SimulatorSettingsModal } from "../simulator-page/simulator-settings-modal";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";

import {
  BallByBallInningsProgressionChart,
  ChartType,
  chartTypeNames,
} from "./ball-by-ball-progression-chart";
import { PlayerStatsTitle } from "./player-stats-title";

interface Props {
  open: boolean;
  onProceed: () => void;
  teamNumber: number;
  gameState: GameState;
  matchFormat: MatchFormat;
  squad: Squad;
  team1: Team;
  team2: Team;
  match: Match;
  initialPlayer: number;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
}

export function PlayerInningsProgressionModal({
  open,
  onProceed,
  teamNumber,
  gameState,
  matchFormat,
  squad,
  team1,
  team2,
  match,
  initialPlayer,
  simulationResult,
  comparedResult,
}: Props): React.JSX.Element {
  const [currentPlayer, setCurrentPlayer] = useState<number>(initialPlayer);
  const [player, setPlayer] = useState<Player>(null);
  const [defaultResultLoading, setDefaultResultLoading] =
    useState<boolean>(false);
  const [userResultLoading, setUserResultLoading] = useState<boolean>(false);
  const [simPreferencesModalOpen, setSimPreferencesModalOpen] =
    useState<boolean>(false);
  const [defaultResult, setDefaultResult] = useState<SimulationResult>(null);
  const [chartType, setChartType] = useState<ChartType>(
    ChartType.PLAYER_STRIKE_RATE
  );
  const [cumulativeChart, setCumulativeChart] = useState<boolean>(false);
  const [versusDefault, setVersusDefault] = useState<boolean>(false);
  const [includeCurrent, setIncludeCurrent] = useState<boolean>(true);

  const [useDefaultGroundStats, setUseDefaultGroundStats] =
    useState<boolean>(true);
  const [useDefaultMatchStats, setUseDefaultMatchStats] =
    useState<boolean>(true);
  const [useDefaultTeam1Stats, setUseDefaultTeam1Stats] =
    useState<boolean>(true);
  const [useDefaultTeam2Stats, setUseDefaultTeam2Stats] =
    useState<boolean>(true);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.simulationService.defaultResultsSubject.subscribe(
        (defaultResult: SimulationResult) => setDefaultResult(defaultResult)
      ),
      services.simulationService.defaultResultsLoadingSubject.subscribe(
        (defaultResultLoading: boolean) =>
          setDefaultResultLoading(defaultResultLoading)
      ),
      services.simulationService.loadingSubject.subscribe(
        (userResultLoading: boolean) => setUserResultLoading(userResultLoading)
      ),
    ];

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    setPlayer(squad.players[currentPlayer]);
  }, [currentPlayer, squad.players]);

  useEffect(() => {
    setCurrentPlayer(initialPlayer);
  }, [initialPlayer, open]);

  const cannotGoRight = (): boolean => {
    const squadInOrder = gameState.squads[teamNumber - 1].sort(
      (a: TeamPlayer, b: TeamPlayer) => a.battingOrder - b.battingOrder
    );
    return (
      currentPlayer >= squad.players.length - 1 ||
      !activeMatchRoles.find(
        (role) => role === squadInOrder[currentPlayer + 1].matchRole
      )
    );
  };

  const updatePreferences = (settings: UserPreferences) => {
    services.userService
      .updateUserPreferences(settings)
      .then(() => setSimPreferencesModalOpen(false));
  };

  return (
    <CreationDialog
      open={open}
      label={"Expected Innings Progression"}
      invalid={false}
      disabled={false}
      onCancel={() => {}}
      onProceed={() => onProceed()}
      showCancelButton={false}
      proceedText="OK"
      colour="#535455"
    >
      <LoadingOverlay
        active={defaultResultLoading || userResultLoading}
        spinner
        text={userResultLoading ? "Simulating..." : "Simulating Default..."}
      >
        {player && (
          <div className="player-progression-modal">
            <PlayerStatsTitle
              onClickPrevious={() => {
                setCurrentPlayer(currentPlayer - 1);
              }}
              previousDisabled={currentPlayer === 0}
              onClickNext={() => {
                setCurrentPlayer(currentPlayer + 1);
              }}
              nextDisabled={cannotGoRight()}
              player={player}
            />
            <div className="player-progression-buttons">
              <div>Use Defaults?</div>
              <div>
                Ground:
                <Checkbox
                  checked={useDefaultGroundStats}
                  onChange={(event) =>
                    setUseDefaultGroundStats(event.target.checked)
                  }
                />
              </div>
              <div>
                Match:
                <Checkbox
                  checked={useDefaultMatchStats}
                  onChange={(event) =>
                    setUseDefaultMatchStats(event.target.checked)
                  }
                />
              </div>
              <div>
                {team1.name} Stats:
                <Checkbox
                  checked={useDefaultTeam1Stats}
                  onChange={(event) =>
                    setUseDefaultTeam1Stats(event.target.checked)
                  }
                />
              </div>
              <div>
                {team2.name} Stats:
                <Checkbox
                  checked={useDefaultTeam2Stats}
                  onChange={(event) =>
                    setUseDefaultTeam2Stats(event.target.checked)
                  }
                />
              </div>
            </div>
            <div className="player-progression-buttons">
              <Button
                onClick={() => services.simulationService.simulate()}
                variant="contained"
                color="primary"
              >
                Simulate
              </Button>
              <Button
                onClick={() =>
                  services.simulationService.simulateDefault(
                    useDefaultGroundStats,
                    useDefaultMatchStats,
                    useDefaultTeam1Stats,
                    useDefaultTeam2Stats
                  )
                }
                variant="contained"
                color="secondary"
              >
                Simulate Default Stats
              </Button>
              <TooltipIconButton
                title="Simulator Settings"
                disabled={false}
                onClick={() => setSimPreferencesModalOpen(true)}
                icon="settings"
              />
              <ComparedUserSelector />
            </div>
            <BallByBallInningsProgressionChart
              entityId={player.playerId}
              simulationResult={simulationResult}
              comparedResult={comparedResult}
              defaultResult={defaultResult}
              chartType={chartType}
              cumulative={cumulativeChart}
              includeCurrent={includeCurrent}
              versusDefault={versusDefault}
              gameState={gameState}
              matchFormat={matchFormat}
              match={match}
            />
            <div className="player-progression-buttons">
              <div className="checkbox-and-label">
                Chart Type:
                <EnumSelector
                  enumObject={ChartType}
                  value={chartType}
                  unavailable={[
                    ChartType.INNINGS_STRIKE_RATE,
                    ChartType.INNINGS_WICKET_PERCENT,
                    ChartType.INNINGS_AVERAGE_PUSH,
                  ]}
                  readableValues={chartTypeNames}
                  onSelect={(type) => setChartType(type)}
                  disabled={false}
                  canClear={false}
                  classes="wide-enum-selector"
                />
              </div>
              <div>
                VS Default:
                <Checkbox
                  checked={versusDefault}
                  onChange={(event) => setVersusDefault(event.target.checked)}
                />
              </div>
              <div>
                Cumulative:
                <Checkbox
                  checked={cumulativeChart}
                  onChange={(event) => setCumulativeChart(event.target.checked)}
                />
              </div>
              {cumulativeChart && (
                <div>
                  Include Current:
                  <Checkbox
                    checked={includeCurrent}
                    onChange={(event) =>
                      setIncludeCurrent(event.target.checked)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </LoadingOverlay>
      <SimulatorSettingsModal
        open={simPreferencesModalOpen}
        onCancel={() => setSimPreferencesModalOpen(false)}
        onProceed={(settings) => updatePreferences(settings)}
      />
    </CreationDialog>
  );
}
