import { AdminSettingsPage } from "./components/admin-settings-page/admin-settings-page";
import BetBuilderPage from "./components/bet-builder-page/bet-builder-page";
import { BetfairPage } from "./components/betfair-page/betfair-page";
import BetsPage from "./components/bets-page/bets-page";
import { DefaultPlayerStatsPage } from "./components/default-player-stats-page/default-player-stats-page";
import { GroundPage } from "./components/ground-page/ground-page";
import { HistoricGroundStatsPage } from "./components/historic-ground-stats-page/historic-ground-stats-page";
import { HistoricMatchesPage } from "./components/historic-matches-page/historic-matches-page";
import { HistoricPlayerStatsPage } from "./components/historic-player-stats-page/historic-player-stats-page";
import InDeploymentPage from "./components/in-deployment-page";
import { InvestorAdminPage } from "./components/investor-admin-page/investor-admin-page";
import { InvestorPage } from "./components/investor-page/investor-page";
import { MatchPage } from "./components/match-page/match-page";
import { MatchStatsPage } from "./components/match-stats-page/match-stats-page";
import MyMatches from "./components/my-matches/my-matches";
import { NoRolesPage } from "./components/no-roles-page";
import { NodesPage } from "./components/nodes-page/nodes-page";
import { SimulatorPage } from "./components/simulator-page/simulator-page";
import { SquadPage } from "./components/squads-page/squad-page";

export enum RouteEnum {
  RECENT_MATCHES = "RECENT_MATCHES",
  MATCH = "MATCH",
  BETFAIR = "BETFAIR",
  SIMULATOR = "SIMULATOR",
  NODES = "NODES",
  SQUADS = "SQUADS",
  GROUND = "GROUND",
  MATCH_STATS = "MATCH_STATS",
  NO_ROLES = "NO_ROLES",
  INVESTMENTS = "INVESTMENTS",
  INVESTMENT_ADMIN = "INVESTMENT_ADMIN",
  HISTORIC_GROUND_STATS = "HISTORIC_GROUND_STATS",
  HISTORIC_PLAYER_STATS = "HISTORIC_PLAYER_STATS",
  DEFAULT_PLAYER_STATS = "DEFAULT_PLAYER_STATS",
  SCRAPED_MATCHES = "SCRAPED_MATCHES",
  ADMIN_SETTINGS = "ADMIN_SETTINGS",
  BET_BUILDER = "BET_BUILDER",
  IN_DEPLOYMENT = "IN_DEPLOYMENT",
  BETS = "BETS",
}

export const routePaths: Record<RouteEnum, string> = {
  RECENT_MATCHES: "/recent-matches",
  MATCH: "/match",
  BETFAIR: "/betfair",
  SIMULATOR: "/simulator",
  NODES: "/nodes",
  SQUADS: "/squads",
  GROUND: "/ground",
  MATCH_STATS: "/match-stats",
  NO_ROLES: "/no-roles",
  INVESTMENTS: "/investments",
  INVESTMENT_ADMIN: "/investment-admin",
  HISTORIC_GROUND_STATS: "/historic-ground-stats",
  HISTORIC_PLAYER_STATS: "/historic-player-stats",
  DEFAULT_PLAYER_STATS: "/default-player-stats",
  SCRAPED_MATCHES: "/scraped-matches",
  ADMIN_SETTINGS: "/admin-settings",
  BET_BUILDER: "/bet-builder",
  IN_DEPLOYMENT: "/in-deployment",
  BETS: "/bets",
};

export const navBarShownOnRoute: Record<RouteEnum, boolean> = {
  RECENT_MATCHES: true,
  MATCH: true,
  BETFAIR: true,
  SIMULATOR: true,
  NODES: true,
  SQUADS: true,
  GROUND: true,
  MATCH_STATS: true,
  NO_ROLES: true,
  INVESTMENTS: true,
  INVESTMENT_ADMIN: true,
  HISTORIC_GROUND_STATS: true,
  HISTORIC_PLAYER_STATS: true,
  DEFAULT_PLAYER_STATS: true,
  SCRAPED_MATCHES: true,
  ADMIN_SETTINGS: true,
  BET_BUILDER: true,
  IN_DEPLOYMENT: false,
  BETS: true,
};

export const routeComponents: Record<RouteEnum, React.ComponentType<any>> = {
  RECENT_MATCHES: MyMatches,
  MATCH: MatchPage,
  BETFAIR: BetfairPage,
  SIMULATOR: SimulatorPage,
  NODES: NodesPage,
  SQUADS: SquadPage,
  GROUND: GroundPage,
  MATCH_STATS: MatchStatsPage,
  NO_ROLES: NoRolesPage,
  INVESTMENTS: InvestorPage,
  INVESTMENT_ADMIN: InvestorAdminPage,
  HISTORIC_GROUND_STATS: HistoricGroundStatsPage,
  HISTORIC_PLAYER_STATS: HistoricPlayerStatsPage,
  DEFAULT_PLAYER_STATS: DefaultPlayerStatsPage,
  SCRAPED_MATCHES: HistoricMatchesPage,
  ADMIN_SETTINGS: AdminSettingsPage,
  BET_BUILDER: BetBuilderPage,
  IN_DEPLOYMENT: InDeploymentPage,
  BETS: BetsPage,
};

export const routeFromPath: Record<string, RouteEnum> =
  reverseRecord(routePaths);

function reverseRecord<T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>
) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key])
  ) as Record<U, T>;
}
