export interface WebappVersion {
  version: string;
  timestamp: number;
}

const currentVersionName = "full-push-version";
const previousVersionName = "previous-full-push-version";

export function getWebappVersion(): WebappVersion {
  return getWebappVersionWithName(currentVersionName);
}

export function setWebappVersion(webappVersion: string) {
  setWebappVersionWithName(webappVersion, currentVersionName);
}

export function getPreviousWebappVersion(): WebappVersion {
  return getWebappVersionWithName(previousVersionName);
}

export function setPreviousWebappVersion(webappVersion: string) {
  setWebappVersionWithName(webappVersion, previousVersionName);
}

export function removePreviousWebappVersion() {
  sessionStorage.removeItem(previousVersionName);
}

function getWebappVersionWithName(name: string): WebappVersion {
  return JSON.parse(sessionStorage.getItem(name)) as WebappVersion;
}

function setWebappVersionWithName(version: string, name: string) {
  sessionStorage.setItem(
    name,
    JSON.stringify({
      version,
      timestamp: new Date().getTime(),
    })
  );
}
