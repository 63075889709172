export enum PlayingRole {
  MIDDLE_ORDER_BATTER = "MIDDLE_ORDER_BATTER",
  ALLROUNDER = "ALLROUNDER",
  TOP_ORDER_BATTER = "TOP_ORDER_BATTER",
  OPENING_BATTER = "OPENING_BATTER",
  WICKETKEEPER_BATTER = "WICKETKEEPER_BATTER",
  BATTER = "BATTER",
  BATTING_ALLROUNDER = "BATTING_ALLROUNDER",
  BOWLER = "BOWLER",
  WICKETKEEPER = "WICKETKEEPER",
  BOWLING_ALLROUNDER = "BOWLING_ALLROUNDER",
  UNKNOWN = "UNKNOWN",
}

export const humanReadablePlayingRoles: Record<PlayingRole, string> = {
  MIDDLE_ORDER_BATTER: "Middle Order Batsman",
  ALLROUNDER: "Allrounder",
  TOP_ORDER_BATTER: "Top Order Batsman",
  OPENING_BATTER: "Opening Batsman",
  WICKETKEEPER_BATTER: "Wicketkeeper Batsman",
  BATTER: "Batsman",
  BATTING_ALLROUNDER: "Batting Allrounder",
  BOWLER: "Bowler",
  WICKETKEEPER: "Wicketkeeper",
  BOWLING_ALLROUNDER: "Bowling Allrounder",
  UNKNOWN: "Unknown Role",
};
