import { ReplaySubject, Subject } from "rxjs";

import { MatchType } from "../types/enums/match-type";
import { services, showMessage } from "../types/services";
import { UUID } from "../types/uuid";

export interface MultipleStageUpdateMessage {
  causingUser: UUID;
  currentStage: number;
  requestId: UUID;
  totalStages: number;
}

export class MultipleStageUpdateService {
  public multipleStageSubject: Subject<MultipleStageUpdateMessage> =
    new ReplaySubject(1);

  private calculateAllRequestId: UUID;

  public multipleStageUpdate(message: MultipleStageUpdateMessage): void {
    if (!message.requestId) {
      return null;
    }

    this.multipleStageSubject.next(
      this.deserializeMultipleStageUpdateMessage(message)
    );
  }

  public deserializeMultipleStageUpdateMessage(
    message: any
  ): MultipleStageUpdateMessage {
    return {
      causingUser: UUID.fromString(message.causingUser),
      currentStage: message.currentStage,
      requestId: UUID.fromString(message.requestId),
      totalStages: message.totalStages,
    };
  }

  public async recalculateAllRelatedStats(matchType: MatchType): Promise<void> {
    const requestId: UUID = UUID.randomUUID();
    this.calculateAllRequestId = requestId;
    services.historicPushService.setCalculateHistoricPushRequestId(requestId);
    services.playerStatsService.setCalculateRequestId(requestId);
    services.groundStatsService.setCalculateAllRequestId(requestId);

    const params: Map<string, string> = new Map();
    params.set("requestId", this.calculateAllRequestId.value);
    params.set("matchType", matchType);

    services.httpService
      .get(
        "/api/historic-stats-controller/recalculate-all-related-stats",
        params,
        false
      )
      .catch((reason) => {
        showMessage(
          `Failed to recalculate all related stats: ${reason}`,
          "error"
        );

        return null;
      });
  }
}
