import LoadingOverlay from "@ronchalant/react-loading-overlay";
import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";

import { MatchInfo } from "../../types/entities/match-info";
import { UserOrBetBuilderRoute, UserRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { UUID } from "../../types/uuid";
import PaginationButtons from "../common-components/pagination/pagination-buttons";
import { MatchInfoDisplay } from "../match-page/game-state-display/match-info-display";

import { MatchCreationModalManager } from "./match-creation-modals/match-creation-modal-manager";
import { MatchShareModal } from "./match-creation-modals/match-share-modal";

export default function MyMatches(): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [matches, setMatches] = useState<MatchInfo[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [creatingMatch, setCreatingMatch] = useState<boolean>(false);
  const [matchShareModalOpen, setMatchShareModalOpen] =
    useState<boolean>(false);
  const [matchShareId, setMatchShareId] = useState<UUID | null>(null);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.matchService
        .getMyMatchesSubject()
        .subscribe((matches: MatchInfo[]) => {
          setMatches(matches);
          setLoading(false);
        }),
      services.matchService.pageCountSubject.subscribe((pageCount: number) => {
        setPageCount(pageCount);
      }),
      services.matchService.getMatchIdSubject().subscribe((matchId: UUID) => {
        services.matchService.getMyMatches(
          // Fetch the first page on initial load
          0,
          services.matchService.MAX_MATCHES
        );
      }),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []); // Only runs this on initial load

  useEffect(() => {
    services.matchService.getMyMatches(
      // The backend indexes pages by 0, whereas in the fronend we index by 1, hence the adjustment
      currentPage - 1,
      services.matchService.MAX_MATCHES
    );
  }, [currentPage]); // We only want to re-fetch / re-render when currentPage changes

  const createMatch = () => {
    setCreatingMatch(true);
  };

  const onCloseMatchModal = () => {
    setCreatingMatch(false);
  };

  const goToMatch = (matchId: UUID) => {
    services.matchService.getMatchIdSubject().next(matchId);
    services.history.push(`match?matchId=${matchId.value}`);
    services.history.goForward();
  };

  const goToBetBuilder = (matchId: UUID) => {
    services.matchService.getMatchIdSubject().next(matchId);
    services.history.push(`bet-builder?matchId=${matchId.value}`);
    services.history.goForward();
  };

  const deleteMatch = async (matchId: UUID): Promise<UUID> => {
    // We want to jump back to the previous page if we're deleting the last match of that page.
    if (matches.length === 1) {
      return services.matchService.delete(matchId, currentPage - 2);
    }
    return services.matchService.delete(matchId, currentPage - 1);
  };

  const showPagination = (): boolean => {
    return pageCount > 1;
  };

  const paginationChangeHandler = (
    event: React.ChangeEvent,
    value: number
  ): void => {
    setCurrentPage(value);
  };

  return (
    <UserOrBetBuilderRoute>
      <LoadingOverlay active={loading} spinner text="Loading Matches...">
        <div className="full-push-background-light with-navbar">
          <div className="page-title">Recent Matches</div>
          <div className="my-matches">
            {matches.map((match) => (
              <MatchInfoDisplay
                key={`matchInfo${match.matchId.value}`}
                onClick={() => goToMatch(match.matchId)}
                onBetBuilder={() => goToBetBuilder(match.matchId)}
                onShare={() => {
                  setMatchShareModalOpen(true);
                  setMatchShareId(match.matchId);
                }}
                onDelete={() => deleteMatch(match.matchId)}
                matchInfo={match}
              />
            ))}
            <UserRoute>
              <MatchInfoDisplay
                buttonText="Create New Match"
                onClick={createMatch}
                matchInfo={null}
              />
              <MatchCreationModalManager
                open={creatingMatch}
                onClose={onCloseMatchModal}
              />
              <MatchShareModal
                matchId={matchShareId}
                key={matchShareId && matchShareId.value}
                open={matchShareModalOpen}
                onCancel={() => {
                  setMatchShareModalOpen(false);
                  setMatchShareId(null);
                }}
              />
            </UserRoute>
          </div>
          {showPagination() && (
            <PaginationButtons
              page={currentPage}
              pageCount={pageCount}
              onChange={paginationChangeHandler}
            />
          )}
        </div>
      </LoadingOverlay>
    </UserOrBetBuilderRoute>
  );
}
