import { Chip, CircularProgress, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { KeycloakUser } from "../../../services/keycloak-service";
import { Match } from "../../../types/entities/match";
import { services, showMessage } from "../../../types/services";
import { UUID } from "../../../types/uuid";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

export interface MatchShare {
  userId;
  matchEntityId;
}

interface Props {
  matchId: UUID;
  open: boolean;
  onCancel: () => void;
}

export function MatchShareModal({
  matchId,
  open,
  onCancel,
}: Props): React.JSX.Element {
  const [sharedUsers, setSharedUsers] = useState<UUID[]>([]);
  const [match, setMatch] = useState<Match>(null);
  const [users, setUsers] = useState<KeycloakUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // set up subscriptions
  useEffect(() => {
    const updateMatch = () => {
      if (matchId) {
        services.matchService
          .getOne(matchId)
          .then(setMatch)
          .catch((reason) =>
            showMessage(`Could not get match: ${reason}`, "error")
          );
      } else {
        setLoading(false);
        setMatch(null);
        setSharedUsers([]);
      }
    };

    const updateShares = () => {
      if (matchId) {
        services.matchService
          .getShares(matchId)
          .then(setSharedUsers)
          .catch((reason) =>
            showMessage(`Could not get shares: ${reason}`, "error")
          );
      }
    };

    const subscriptions: Subscription[] = [
      services.currentGameService.shareSubject.subscribe(
        (sharedMatchId: UUID) => {
          if (
            sharedMatchId &&
            matchId &&
            sharedMatchId.value === matchId.value
          ) {
            updateShares();
          }
        }
      ),
      services.keycloakService.knownUsersSubject.subscribe(
        (knownUsers: KeycloakUser[]) => setUsers(knownUsers)
      ),
    ];

    updateMatch();
    updateShares();

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [matchId]);

  // set loading to false when sharedUsers is updated
  useEffect(() => {
    setLoading(false);
  }, [sharedUsers]);

  const handleShareAdded = (selection) => {
    if (!!selection.target.value && !!match) {
      setLoading(true);
      services.matchService
        .share({
          userId: UUID.fromString(selection.target.value),
          matchEntityId: match.entityId,
        })
        .catch((reason) => {
          showMessage(`Could not share match: ${reason}`, "error");
        });
    }
  };

  const handleShareRemoved = (userId: string) => {
    setLoading(true);
    services.matchService.removeShare(matchId, userId).catch((reason) => {
      showMessage(`Could not remove share: ${reason}`, "error");
    });
  };

  const userIsSharable = (user: KeycloakUser) =>
    !userAlreadyShared(user.id) && !matchCreatedBy(user.id);

  const userAlreadyShared = (userId: string) =>
    sharedUsers && sharedUsers.find((id) => id.value === userId);

  const matchCreatedBy = (user: string) =>
    match.createdBy && match.createdBy.value === user;

  const matchCreatedByThisUser = () =>
    matchCreatedBy(services.keycloakService.getUserId());

  return (
    <CreationDialog
      open={open}
      label={"Share Match"}
      invalid={false}
      disabled={true}
      disabledText={""}
      onCancel={() => {
        setLoading(false);
        onCancel();
      }}
      onProceed={() => {}}
      proceedText=""
      colour="#34ebb4"
    >
      <div className="share-match-modal-content">
        {loading && <CircularProgress />}

        {!loading && !!users && !!match && (
          <>
            <Select
              className="share-match-user-select"
              value={""}
              placeholder={"Select User"}
              onChange={(selection) => handleShareAdded(selection)}
              variant="standard"
            >
              {!users.find((user) => userIsSharable(user)) && (
                <MenuItem disabled={true}>No users available</MenuItem>
              )}
              {users.map(
                (user, index) =>
                  userIsSharable(user) && (
                    <MenuItem key={`user-${index}`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  )
              )}
            </Select>

            <div className="already-shared-users">
              {users
                .filter((user) => userAlreadyShared(user.id))
                .map((user, index) =>
                  matchCreatedByThisUser() ? (
                    <Chip
                      label={user.name}
                      key={`already-shared-user-${index}`}
                      variant="outlined"
                      onDelete={() => handleShareRemoved(user.id)}
                    />
                  ) : (
                    <Chip
                      label={user.name}
                      key={`already-shared-user-${index}`}
                      variant="outlined"
                    />
                  )
                )}
            </div>
          </>
        )}
      </div>
    </CreationDialog>
  );
}
