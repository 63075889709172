import { Icon, Tooltip } from "@mui/material";
import { Component } from "react";
import { ReactComponent as MonitorSVG } from "../../img/simulator/monitor.svg";
import { NodeConfiguration } from "../../types/preferences/node-configuration";
import { NodeHealth } from "../../types/simulator/node-health";
import { SimulationResult } from "../../types/simulator/simulation-result";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  health: NodeHealth;
  configuration: NodeConfiguration;
  latestVersion: string;
  latestSimulation: SimulationResult;
  onEdit: () => void;
  onDelete: () => void;
}

interface State {}

export class NodeConfigurationDisplay extends Component<Props, State> {
  public render() {
    return (
      <div className="node-display">
        {this.props.health &&
          this.props.health.status === "Up" &&
          this.props.health.version === this.props.latestVersion && (
            <Tooltip title={`Latency: ${this.props.health.ping}ms`}>
              <Icon className="node-status-ok">check_circle</Icon>
            </Tooltip>
          )}
        {this.props.health &&
          this.props.health.status === "Up" &&
          this.props.health.version !== this.props.latestVersion && (
            <Tooltip title="Node requires update">
              <Icon className="node-status-out-of-date">warning_circle</Icon>
            </Tooltip>
          )}
        {this.props.health && this.props.health.status === "Timed Out" && (
          <Tooltip title="Node timed out">
            <Icon className="node-status-timed-out">hourglass_bottom</Icon>
          </Tooltip>
        )}
        {this.props.health && this.props.health.status === "Down" && (
          <Tooltip title="Cannot use this configuration">
            <Icon className="node-status-down">error</Icon>
          </Tooltip>
        )}
        <div className="node-image">
          <MonitorSVG />
        </div>
        <div className="node-name">{this.props.configuration.name}</div>
        <div>Host: {this.props.configuration.host}</div>
        <div>Port: {this.props.configuration.port}</div>
        <div>Cores: {this.props.configuration.maxCores}</div>
        {!!this.props.latestSimulation &&
          !!this.props.latestSimulation.nodeSimulationSummaries.get(
            this.props.configuration.nodeConfigurationId.value
          ) && (
            <div>
              <hr />
              Latest Simulation
              <div>
                Number of Simulations:{" "}
                {
                  this.props.latestSimulation.nodeSimulationSummaries.get(
                    this.props.configuration.nodeConfigurationId.value
                  ).numberOfSimulations
                }
              </div>
              <div>
                Time Taken:{" "}
                {
                  this.props.latestSimulation.nodeSimulationSummaries.get(
                    this.props.configuration.nodeConfigurationId.value
                  ).timeTaken
                }
                ms
              </div>
            </div>
          )}
        {this.props.configuration.createdBy !== null && (
          <div className="node-buttons">
            <TooltipIconButton
              title={"Edit Configuration"}
              onClick={() => this.props.onEdit()}
              icon={"edit"}
            />
            <TooltipIconButton
              title={"Delete Configuration"}
              onClick={() => this.props.onDelete()}
              icon={"delete"}
            />
          </div>
        )}
      </div>
    );
  }
}
