import { Component } from "react";
import { Team } from "../../types/entities/team";
import { Squad } from "../../types/entities/squad";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { ReactComponent as BallSVG } from "../../img/events/bowler-selected.svg";
import { ReactComponent as GreyBallSVG } from "../../img/events/bowler-not-selected.svg";
import { GameState } from "../../types/entities/game-state";
import { SimulatorRoute } from "../../types/route-helpers";
import { Direction } from "../../types/enums/direction";
import { UUID } from "../../types/uuid";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { Player } from "../../types/entities/player";
import { TeamPlayer } from "../../types/entities/team-player";

interface Props {
  gameState: GameState;
  player: Player;
  teamPlayer: TeamPlayer;
  order: number;
  team: Team;
  squad: Squad;
  playerStats: PlayerStatsWrapper;
  movePlayer: (playerId: UUID, direction: Direction, steps: number) => void;
  removePlayer: (playerId: UUID, teamId: UUID) => void;
  toggleBowler: (playerStats: PlayerStatsWrapper) => void;
  showStatsModal: (order: number) => void;
  showInningsProgressionModal: (order: number) => void;
}

export class PlayerTableRowButtons extends Component<Props, {}> {
  public render() {
    return (
      <div className="player-stats-button-container">
        <div>
          <TooltipIconButton
            title="Move Up"
            disabled={this.props.teamPlayer.battingOrder === 0}
            onClick={() =>
              this.props.movePlayer(this.props.player.playerId, Direction.UP, 1)
            }
            icon="keyboard_arrow_up"
          />
          <TooltipIconButton
            title="Move Down"
            disabled={
              this.props.teamPlayer.battingOrder >=
              this.props.squad.players.length - 1
            }
            onClick={() =>
              this.props.movePlayer(
                this.props.player.playerId,
                Direction.DOWN,
                1
              )
            }
            icon="keyboard_arrow_down"
          />
        </div>
        <SimulatorRoute>
          <TooltipIconButton
            title="Stats"
            onClick={() => this.props.showStatsModal(this.props.order)}
            icon="assignment"
          />
          <TooltipIconButton
            title="Innings progression"
            onClick={() => this.props.showInningsProgressionModal(this.props.order)}
            icon="ssid_chart"
          />
        </SimulatorRoute>
        <SimulatorRoute>
          <TooltipIconButton
            title={
              this.props.playerStats.playerStats.bowler
                ? "Block bowler"
                : "Unblock bowler"
            }
            onClick={() => this.props.toggleBowler(this.props.playerStats)}
            icon={
              this.props.playerStats.playerStats.bowler ? (
                <BallSVG className="tooltip-icon-button" />
              ) : (
                <GreyBallSVG className="tooltip-icon-button" />
              )
            }
          />
        </SimulatorRoute>
        {!!this.props.gameState && !this.props.gameState.started && (
          <TooltipIconButton
            title="Remove Player"
            onClick={() =>
              this.props.removePlayer(
                this.props.player.playerId,
                this.props.team.teamId
              )
            }
            icon="cancel"
          />
        )}
      </div>
    );
  }
}
