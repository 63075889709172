export enum DismissalMethod {
  BOWLED = "BOWLED",
  LBW = "LBW",
  CAUGHT = "CAUGHT",
  STUMPED = "STUMPED",
  HIT_WICKET = "HIT_WICKET",
  RUN_OUT = "RUN_OUT",
  HIT_THE_BALL_TWICE = "HIT_THE_BALL_TWICE",
  TIMED_OUT = "TIMED_OUT",
  OBSTRUCTING_THE_FIELD = "OBSTRUCTING_THE_FIELD",
  RETIRED_OUT = "RETIRED_OUT",
}

export const dismissalMethodNames: Record<DismissalMethod, string> = {
  BOWLED: "Bowled",
  LBW: "LBW",
  CAUGHT: "Caught",
  STUMPED: "Stumped",
  HIT_WICKET: "Hit Wicket",
  RUN_OUT: "Run Out",
  HIT_THE_BALL_TWICE: "Hit the Ball Twice",
  TIMED_OUT: "Timed Out",
  OBSTRUCTING_THE_FIELD: "Obstructing the Field",
  RETIRED_OUT: "Retired Out",
};

export const dismissalMethodRequiresFielder: Record<DismissalMethod, boolean> =
  {
    BOWLED: false,
    LBW: false,
    CAUGHT: true,
    STUMPED: true,
    HIT_WICKET: false,
    RUN_OUT: true,
    HIT_THE_BALL_TWICE: false,
    TIMED_OUT: false,
    OBSTRUCTING_THE_FIELD: false,
    RETIRED_OUT: false,
  };
