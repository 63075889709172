export enum MatchType {
  T20_AND_HUNDRED = "T20_AND_HUNDRED",
  ODI = "ODI",
  T10 = "T10",
  WOMENS_T20_AND_HUNDRED = "WOMENS_T20_AND_HUNDRED",
  WOMENS_ODI = "WOMENS_ODI",
  WOMENS_T10 = "WOMENS_T10",
}

export const matchTypeNames: Record<MatchType, string> = {
  T20_AND_HUNDRED: "T20/Hundred",
  ODI: "ODI",
  WOMENS_T20_AND_HUNDRED: `Women's T20/Hundred`,
  WOMENS_ODI: `Women's ODI`,
  WOMENS_T10: `Women's T10`,
  T10: `T10`,
};

export const matchTypeMapNames: Record<
  MatchType,
  | "t20Defaults"
  | "odiDefaults"
  | "t20DefaultsWomen"
  | "odiDefaultsWomen"
  | "t10DefaultsWomen"
  | "t10Defaults"
> = {
  T20_AND_HUNDRED: "t20Defaults",
  ODI: "odiDefaults",
  WOMENS_T20_AND_HUNDRED: `t20DefaultsWomen`,
  WOMENS_ODI: `odiDefaultsWomen`,
  WOMENS_T10: `t10DefaultsWomen`,
  T10: `t10Defaults`,
};

export const matchTypeDefaultMapNames: Record<
  MatchType,
  | "t20DefaultsOriginal"
  | "odiDefaultsOriginal"
  | "t10DefaultsOriginal"
  | "t20DefaultsWomenOriginal"
  | "odiDefaultsWomenOriginal"
  | "t10DefaultsWomenOriginal"
> = {
  T20_AND_HUNDRED: "t20DefaultsOriginal",
  ODI: "odiDefaultsOriginal",
  WOMENS_T20_AND_HUNDRED: `t20DefaultsWomenOriginal`,
  WOMENS_ODI: `odiDefaultsWomenOriginal`,
  WOMENS_T10: `t10DefaultsWomenOriginal`,
  T10: `t10DefaultsOriginal`,
};
