import { Component } from "react";
import { Subscription } from "rxjs";
import { InvestorPotStatus } from "../../types/investment/pot-summary";
import { InvestorRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { TransactionHistoryDisplay } from "../investor-admin-page/transaction-history-display";
import { DepositAndWithdrawalRequestComponent } from "./deposit-and-withdrawal-request-component";
import { InvestorPotStatusDisplay } from "./investor-pot-status-display";

interface State {
  myPotStatus: InvestorPotStatus;
  tabNumber: number;
}

export class InvestorPage extends Component<{}, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      myPotStatus: null,
      tabNumber: 0,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.investmentService.myPotStatusSubject.subscribe(
        (myPotStatus: InvestorPotStatus) => this.setState({ myPotStatus })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public render() {
    return (
      <InvestorRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Investor Page</div>
          </div>
          {!!this.state.myPotStatus && (
            <div className="investor-page">
              <div className="summary-and-actions">
                <InvestorPotStatusDisplay potStatus={this.state.myPotStatus} />
                <DepositAndWithdrawalRequestComponent
                  potStatus={this.state.myPotStatus}
                />
              </div>

              <TransactionHistoryDisplay
                investors={[]}
                allTransactions={
                  !!this.state.myPotStatus
                    ? this.state.myPotStatus.eventHistory
                    : []
                }
                displayedTransactions={
                  !!this.state.myPotStatus
                    ? this.state.myPotStatus.eventHistory
                    : []
                }
                canAcceptAndReject={false}
                showProfitVolume={false}
                showInvestorName={false}
              />
            </div>
          )}
        </div>
      </InvestorRoute>
    );
  }
}
