import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { OpponentSkillFormData } from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const opponentSkillFormDataComponent = (data: OpponentSkillFormData) => {
  const headers: string[] = [
    "Total Ground SR Bias",
    "Total Ground W% Bias",
    "Total Opponent SR Bias",
    "Total Opponent W% Bias",
    "Total Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{format(data.totalGroundSRBias)}</TableCell>
          <TableCell>{format(data.totalGroundWicketBias)}</TableCell>
          <TableCell>{format(data.totalOpponentSRBias)}</TableCell>
          <TableCell>{format(data.totalOpponentWicketBias)}</TableCell>
          <TableCell>{format(data.totalWeight)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
