import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { MatchInfo } from "../../../types/entities/match-info";
import { Match } from "../../../types/entities/match";
import { MatchFormat } from "../../../types/entities/match-format";
import { InningsSummary } from "./innings-summary";
import Marquee from "react-fast-marquee";

interface Props {
  match: Match;
  gameState: GameState;
  matchInfo: MatchInfo;
  matchFormat: MatchFormat;
}

export class GameStateDisplay extends Component<Props> {
  public render() {
    return (
      <div className="match-info-text">
        {this.props.gameState &&
          this.props.matchFormat &&
          this.props.matchInfo && (
            <div className="match-info-contents">
              <div
                className="innings-summary"
                style={{
                  fontWeight:
                    this.props.gameState.getBattingTeam(
                      this.props.matchFormat
                    ) === 1
                      ? "bold"
                      : "normal",
                }}
              >
                {this.props.matchInfo.team1Name}:
                <InningsSummary
                  data={this.props.gameState.calculateInningsSummary(
                    1,
                    this.props.matchFormat
                  )}
                />
              </div>
              <div
                className="innings-summary"
                style={{
                  fontWeight:
                    this.props.gameState.getBattingTeam(
                      this.props.matchFormat
                    ) === 2
                      ? "bold"
                      : "normal",
                }}
              >
                {this.props.matchInfo.team2Name}:
                <InningsSummary
                  data={this.props.gameState.calculateInningsSummary(
                    2,
                    this.props.matchFormat
                  )}
                />
              </div>

              {this.props.gameState &&
                this.props.gameState.started &&
                !this.props.gameState.ended && (
                  <div>
                    <div>Innings: {this.props.gameState.innings}</div>
                    <div>Over: {this.props.gameState.getOverText()}</div>
                    <div>Format: {this.props.matchFormat.name}</div>
                    <div>
                      {this.props.gameState.calculateMatchStatusText(
                        this.props.matchInfo.team1Name,
                        this.props.matchInfo.team2Name,
                        this.props.matchFormat.inningsConfiguration.length,
                        this.props.matchFormat
                      )}
                    </div>
                  </div>
                )}
              {this.props.gameState &&
                !this.props.gameState.ended &&
                this.props.gameState.freeHit && (
                  <Marquee direction="right" speed={80} gradient={false}>
                    <div className="multicolortext">FREE HIT!</div>
                  </Marquee>
                )}
              {this.props.gameState && this.props.gameState.ended && (
                <div>
                  <p>
                    Match Complete:{" "}
                    {this.props.gameState.calculateWinningText(
                      this.props.matchInfo.team1Name,
                      this.props.matchInfo.team2Name,
                      this.props.matchFormat
                    )}
                  </p>
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}
