import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { PlayerSelector } from "../../entity-management/entity-selectors/player-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { Observable, Subscription } from "rxjs";
import { services } from "../../../types/services";
import { propsEqual } from "../../component-utils";
import { Checkbox } from "@mui/material";
import { MatchRole } from "../../../types/enums/match-role";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (
    substitute: Player,
    playerReplaced: Player,
    isConcussionSub: boolean
  ) => void;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
  team: number;
}

interface State {
  substitute: Player;
  playerReplaced: Player;
  isConcussionSub: boolean;
  allPlayers: Player[];
}

export class SubstituteModal extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.state = {
      substitute: null,
      playerReplaced: null,
      isConcussionSub: false,
      allPlayers: [],
    };
    this.subscriptions = [];
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.playerService.getAll().subscribe((players: Player[]) => {
        this.setState({ allPlayers: players });
      })
    );
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({
        substitute: null,
        playerReplaced: null,
        isConcussionSub: false,
      });
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private handleSubChange(substitute: Player) {
    this.setState({ substitute });
  }

  private handlePlayerReplacedChange(playerReplaced: Player) {
    this.setState({ playerReplaced });
  }

  private handleIsConcussionSubChange(event) {
    const isConcussionSub = event.target.checked;
    if (isConcussionSub) {
      this.setState({ isConcussionSub });
    } else {
      this.setState({ isConcussionSub, playerReplaced: null });
    }
  }

  private invalid(): boolean {
    return (
      this.state.substitute === null ||
      (this.state.isConcussionSub && this.state.playerReplaced === null)
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Substitute"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(
            this.state.substitute,
            this.state.playerReplaced,
            this.state.isConcussionSub
          )
        }
        proceedText="OK"
        colour="#a834eb"
      >
        <div className="substitute-modal-content">
          New Player:
          <PlayerSelector
            value={this.state.substitute}
            onSelect={(player: Player) => this.handleSubChange(player)}
            options={this.eligiblePlayersToComeOnObservable()}
          />
          <div className="checkbox-and-label">
            <div>Concussion Substitute?</div>
            <Checkbox
              checked={this.state.isConcussionSub}
              onChange={(event) => this.handleIsConcussionSubChange(event)}
              disabled={false}
            />
          </div>
          {this.state.isConcussionSub && (
            <div>
              Player Replaced:
              <PlayerSelector
                value={this.state.playerReplaced}
                onSelect={(player: Player) =>
                  this.handlePlayerReplacedChange(player)
                }
                options={this.eligiblePlayersToBeReplacedObservable()}
              />
            </div>
          )}
        </div>
      </CreationDialog>
    );
  }

  private eligiblePlayersToComeOnObservable(): Observable<Player[]> {
    const eligiblePlayers = this.state.allPlayers.filter((p) => {
      const teamPlayerInThisTeam =
        !!this.props.gameState &&
        this.props.gameState.squads[this.props.team - 1].find(
          (teamPlayer) => p.playerId.value === teamPlayer.playerId.value
        );
      const teamPlayerInOtherTeam =
        !!this.props.gameState &&
        this.props.gameState.squads[this.props.team % 2].find(
          (teamPlayer) => p.playerId.value === teamPlayer.playerId.value
        );
      const playerInSquad1 =
        !!this.props.squad1 &&
        this.props.squad1.players.find(
          (squadPlayer) => p.playerId.value === squadPlayer.playerId.value
        );
      const playerInSquad2 =
        !!this.props.squad2 &&
        this.props.squad2.players.find(
          (squadPlayer) => p.playerId.value === squadPlayer.playerId.value
        );

      return (
        ((!this.props.squad1 || playerInSquad1 === undefined) &&
          (!this.props.squad2 || playerInSquad2 === undefined)) ||
        (!!teamPlayerInThisTeam &&
          teamPlayerInThisTeam.matchRole === MatchRole.REMOVED) ||
        (!!teamPlayerInOtherTeam &&
          teamPlayerInOtherTeam.matchRole === MatchRole.REMOVED) ||
        (this.state.isConcussionSub &&
          !!teamPlayerInThisTeam &&
          teamPlayerInThisTeam.matchRole === MatchRole.SUBSTITUTE)
      );
    });

    return new Observable((subscriber) => subscriber.next(eligiblePlayers));
  }

  private eligiblePlayersToBeReplacedObservable(): Observable<Player[]> {
    const eligiblePlayers = this.state.allPlayers.filter((p) => {
      const teamPlayerInTeam =
        !!this.props.gameState &&
        this.props.gameState.squads[this.props.team - 1].find(
          (teamPlayer) => p.playerId.value === teamPlayer.playerId.value
        );
      return (
        !!teamPlayerInTeam &&
        teamPlayerInTeam.matchRole !== MatchRole.SUBSTITUTE &&
        teamPlayerInTeam.matchRole !== MatchRole.REPLACED
      );
    });

    return new Observable((subscriber) => subscriber.next(eligiblePlayers));
  }
}
