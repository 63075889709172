import { LoginPage } from "../components/login-page";
import { UnderDevelopmentPage } from "../components/under-development-page";
import keycloak from "../keycloak";
import { services } from "./services";

// show login page if condition not met
export const PrivateRoute = ({ children }) => {
  return SwitchedChildrenOnCondition({
    hasConditionForChildren: keycloak.authenticated,
    children,
    orElseChildren: <LoginPage />,
  });
};

// show under dev page if doesn't have role
export const UnderDevelopmentRoute = ({ children }) => {
  return SwitchedChildrenOnRole({
    roles: ["developer"],
    children,
    orElseChildren: <UnderDevelopmentPage />,
  });
};

export const DeveloperRoute = ({ children }) => {
  return SwitchedChildrenOnRole({
    roles: ["developer"],
    children,
    orElseChildren: <></>,
  });
};

// show nothing if doesn't have role
export const UserRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["user"], children });
};

export const UserOrBetBuilderRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["user", "bet_builder"], children });
};

export const BetBuilderRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["bet_builder"], children });
};

export const InvestorMenuRoute = ({ children }) => {
  return ShowChildrenIfHasRole({
    roles: ["investor_admin", "investor"],
    children,
  });
};

export const InvestorRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["investor"], children });
};

export const InvestorAdminRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["investor_admin"], children });
};

export const BetfairRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["betfair"], children });
};

export const SimulatorRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["simulator"], children });
};

export const EmployeeRoute = ({ children }) => {
  return ShowChildrenIfHasRole({ roles: ["employee"], children });
};

const ShowChildrenIfHasRole = (props: { roles: string[]; children }) => {
  return SwitchedChildrenOnRole({
    ...props,
    orElseChildren: <div className="show-none"></div>,
  });
};

const SwitchedChildrenOnRole = (props: {
  roles: string[];
  orElseChildren;
  children;
}) => {
  const hasConditionForChildren = props.roles.some((r) =>
    services.keycloakService.hasRole(r)
  );
  return SwitchedChildrenOnCondition({
    hasConditionForChildren,
    orElseChildren: props.orElseChildren,
    children: props.children || null,
  });
};

const SwitchedChildrenOnCondition = (props: {
  hasConditionForChildren: boolean;
  orElseChildren;
  children;
}) => {
  return props.hasConditionForChildren ? props.children : props.orElseChildren;
};
