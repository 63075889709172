import { TableCell, TableRow } from "@mui/material";
import React from "react";

import { UUID } from "../../../types/uuid";
import { formatToDpWithDash } from "../simulator-utils";
import { PlayerData } from "./player-median-and-odds-types";
import SimulatorPlayerOddsQuestionEditor from "./simulator-player-odds-question-editor";

type PlayerRowProps = {
  player: PlayerData;
  teamId: string;
  updateOddsOperand: (
    playerId: UUID,
    teamId: string,
    newOperand: number
  ) => void;
};

export default function SimulatorPlayerMedianRow({
  player,
  teamId,
  updateOddsOperand,
}: PlayerRowProps): React.JSX.Element {
  const handleOddsOperandChange = (operand: number) => {
    updateOddsOperand(player.playerId, teamId, operand);
  };

  return (
    <TableRow key={player.playerId.value}>
      <TableCell component="th" scope="row" id="scorecard-table-cell">
        {player.name}
      </TableCell>
      <TableCell align="center" id="scorecard-table-cell">
        {formatToDpWithDash(player.median, 2)}
      </TableCell>
      <TableCell align="right" id="scorecard-table-cell">
        <SimulatorPlayerOddsQuestionEditor
          odds={player.odds}
          value={player.oddsOperand}
          onChange={handleOddsOperandChange}
        />
      </TableCell>
    </TableRow>
  );
}
