export enum DecisionType {
  RETURNING_RETIRED_BATSMAN = "RETURNING_RETIRED_BATSMAN",
  SUPER_OVER = "SUPER_OVER",
}

export interface ReturningRetiredDecision {
  retiredBatsmanReturns: boolean;
  returningBatsman: string;
}

export interface SuperOverDecision {
  superOverTaken: boolean;
  ballsPerOver: number;
  maxWickets: number;
  teamBattingFirst: number;
}
