import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Squad } from "../../../types/entities/squad";
import ReactToPrint from "react-to-print";
import { ScorecardPrintComponent } from "./scorecard-print-component";
import { Team } from "../../../types/entities/team";
import { Match } from "../../../types/entities/match";
import { BattingScorecardDisplay } from "./batting-scorecard-display";
import { BowlingScorecardDisplay } from "./bowling-scorecard-display";
import { MatchFormat } from "../../../types/entities/match-format";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { Ground } from "../../../types/entities/ground";
import { propsEqual } from "../../component-utils";
import { InningsStatisticType } from "../../../types/enums/statistic-type";
import { translateDate } from "../../../types/util-functions";

interface Props {
  open: boolean;
  onCancel: () => void;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
  team1: Team;
  team2: Team;
  match: Match;
  matchFormat: MatchFormat;
  ground: Ground;
}

interface State {
  children;
}

export class ScorecardPrintButton extends Component<Props, State> {
  componentRef = null;

  constructor(props) {
    super(props);
    this.state = {
      children: <div></div>,
    };
  }

  componentDidMount(): void {
    this.updateScorecard();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateScorecard();
    }
  }

  private updateScorecard(): void {
    let scorecard = this.invalid() ? (
      <div>Unable to calculate scorecard.</div>
    ) : (
      <div>
        <h1>
          {this.props.team1.name} vs {this.props.team2.name}
        </h1>
        <h4>
          {translateDate(this.props.match.date)} at {this.props.ground.name}
        </h4>
        {this.getInnings()}
      </div>
    );

    this.setState({ children: scorecard });
  }

  private invalid(): boolean {
    return (
      Object.values(this.props).find(
        (value) => value === undefined || value === null
      ) !== undefined
    );
  }

  private getInnings() {
    let tables: any[] = [];

    for (
      let i = 1;
      i <= this.props.gameState.innings &&
      i <= this.props.matchFormat.inningsConfiguration.length;
      i++
    ) {
      tables.push(
        <div className="scorecard-innings" key={`scorecard-innings-${i}`}>
          <h2>
            <div>
              {this.props.gameState.getBattingTeamForInnings(
                i,
                this.props.matchFormat
              ) === 1
                ? this.props.team1.name
                : this.props.team2.name}{" "}
              Innings {Math.ceil(i / 2)}
            </div>
            <div>
              {
                this.props.gameState.inningsStats[
                  InningsStatisticType.TOTAL_RUNS
                ][i - 1]
              }
              /
              {
                this.props.gameState.inningsStats[InningsStatisticType.WICKETS][
                  i - 1
                ]
              }
            </div>
          </h2>
          <BattingScorecardDisplay
            gameState={this.props.gameState}
            squad={
              this.props.gameState.getBattingTeamForInnings(
                i,
                this.props.matchFormat
              ) === 1
                ? this.props.squad1
                : this.props.squad2
            }
            innings={i}
          />
          <h4>
            {this.props.gameState.getBattingTeamForInnings(
              i,
              this.props.matchFormat
            ) === 1
              ? this.props.team2.name
              : this.props.team1.name}{" "}
            Bowling
          </h4>
          <BowlingScorecardDisplay
            gameState={this.props.gameState}
            squad={
              this.props.gameState.getBattingTeamForInnings(
                i,
                this.props.matchFormat
              ) === 1
                ? this.props.squad2
                : this.props.squad1
            }
            innings={i}
          />
        </div>
      );
    }

    return <div>{tables}</div>;
  }

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    return (
      <TooltipIconButton
        title="Print"
        onClick={() => {}}
        icon="print"
        disabled={false}
      />
    );
  };

  public render() {
    return (
      <div>
        <ScorecardPrintComponent ref={this.setComponentRef}>
          {this.state.children}
        </ScorecardPrintComponent>
        <ReactToPrint
          content={this.reactToPrintContent}
          documentTitle={
            this.invalid()
              ? "Scorecard"
              : `Scorecard-${this.props.team1.name}-vs-${
                  this.props.team2.name
                }-${translateDate(this.props.match.date)}`
          }
          removeAfterPrint
          trigger={this.reactToPrintTrigger}
        />
      </div>
    );
  }
}
