import { Component } from "react";
import { Modal } from "../../../types/enums/modal";
import { CreationDialog } from "./creation-dialog";

interface Props {
  onProceed: (choice: Modal) => void;
  onCancel: () => void;
  open: boolean;
}

export class DecideMatchCreationSourceModal extends Component<Props, {}> {
  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Create Match"}
        invalid={false}
        disabled={true}
        onCancel={() => this.props.onCancel()}
        onProceed={() => {}}
        cancelText="CANCEL"
        disabledText=""
        proceedText=""
        colour="#724bd6"
      >
        <div className="match-source-decision-divs">
          <div
            className="match-source-decision-div"
            onClick={() => this.props.onProceed(Modal.CRICINFO)}
          >
            Upcoming Game
          </div>
          <div
            className="match-source-decision-div"
            onClick={() => this.props.onProceed(Modal.MATCH_CREATOR)}
          >
            Manual
          </div>
        </div>
      </CreationDialog>
    );
  }
}
