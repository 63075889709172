import { Box, Tab, Tabs } from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Ground } from "../../../types/entities/ground";
import { Match } from "../../../types/entities/match";
import { MatchFormat } from "../../../types/entities/match-format";
import { MatchInfo } from "../../../types/entities/match-info";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { AdminPreferences } from "../../../types/preferences/admin-preferences";
import { services } from "../../../types/services";
import { MatchStatsWrapper } from "../../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../../types/stats/player-stats";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import { IconAndTextButton } from "../../navigation-bar/icon-and-text-button";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { pSBC } from "../game-state-display/tsbc";
import { BatsmanSelector } from "./batsman-selector";
import { BattingScorecardDisplay } from "./batting-scorecard-display";
import { BowlerSelector } from "./bowler-selector";
import { BowlingScorecardDisplay } from "./bowling-scorecard-display";
import { ScorecardPrintButton } from "./scorecard-print-button";
import { TabPanel } from "./tab-panel";

interface Props {
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
  ground: Ground;
  matchInfo: MatchInfo;
  matchStats: MatchStatsWrapper;
  playerStats: Map<string, PlayerStatsWrapper>;
  onModalOpen: () => void;
  onModalClose: () => void;
  onSpinnerEdit: (spinnerBeingEdited: boolean) => void;
  externalModalOpen: boolean;
  allowToggleView: boolean;
  allowPrint: boolean;
  fullWidth: boolean;
  adminPreferences?: AdminPreferences;
}

interface State {
  scorecardInnings: number;
  tabNumber: number;
  scorecardModalOpen: boolean;
  fullViewOpen: boolean;
  batsman1Focussed: boolean;
  batsman2Focussed: boolean;
  bowlerFocussed: boolean;
}

export class ScorecardDisplay extends Component<Props, State> {
  static defaultProps = {
    allowToggleView: true,
    allowPrint: true,
    fullWidth: false,
    adminPreferences: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      scorecardInnings: this.props.gameState && this.props.gameState.innings,
      tabNumber: 0,
      scorecardModalOpen: false,
      fullViewOpen: false,
      batsman1Focussed: false,
      batsman2Focussed: false,
      bowlerFocussed: false,
    };
  }

  componentDidMount(): void {
    this.updateInnings();
    document.addEventListener("keydown", this.handleKeyEvent);
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateInnings();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyEvent);
  }

  private readonly handleKeyEvent = (event) => {
    if ("Tab" === event.key && !this.props.externalModalOpen) {
      this.jumpToFirstEmptyPlayerSelector();
      event.preventDefault();
    }
  };

  private jumpToFirstEmptyPlayerSelector() {
    const batsman1Filled =
      this.props.gameState &&
      (this.props.gameState.batsman1 || this.state.batsman1Focussed);
    const batsman2Filled =
      this.props.gameState &&
      (this.props.gameState.batsman2 || this.state.batsman2Focussed);
    const bowlerFilled =
      this.props.gameState &&
      (this.props.gameState.bowler || this.state.bowlerFocussed);
    const optionToFocus =
      batsman1Filled && batsman2Filled && bowlerFilled
        ? "none"
        : batsman1Filled
        ? batsman2Filled
          ? "bowler"
          : "batsman2"
        : "batsman1";

    this.setState({
      batsman1Focussed: optionToFocus === "batsman1",
      batsman2Focussed: optionToFocus === "batsman2",
      bowlerFocussed: optionToFocus === "bowler",
    });
  }

  private updateInnings() {
    this.setState({
      scorecardInnings: this.props.gameState && this.props.gameState.innings,
      fullViewOpen:
        this.props.gameState &&
        this.state.fullViewOpen &&
        this.props.gameState.batsman1 !== null &&
        this.props.gameState.batsman2 !== null &&
        this.props.gameState.bowler !== null,
      batsman1Focussed: false,
      batsman2Focussed: false,
      bowlerFocussed: false,
    });
  }

  private toggleFullView(fullViewOpen: boolean) {
    this.setState({ fullViewOpen });
  }

  private squadForId(teamId: UUID): Squad {
    return this.props.squad1 && this.props.squad1.teamId.value === teamId.value
      ? this.props.squad1
      : this.props.squad2;
  }

  public render() {
    if (!this.props.gameState || !this.props.matchFormat) {
      return <div></div>;
    }

    const battingTeamId =
      this.props.gameState.started &&
      this.props.gameState.getTeamId(
        this.props.gameState.getBattingTeam(this.props.matchFormat)
      );
    const bowlingTeamId =
      this.props.gameState.started &&
      this.props.gameState.getTeamId(
        this.props.gameState.getBowlingTeam(this.props.matchFormat)
      );

    const battingTeam: number = this.props.gameState.getBattingTeam(
      this.props.matchFormat
    );

    const battingTeamPrimaryColour: string =
      battingTeam === 1
        ? this.props.matchInfo && this.props.matchInfo.team1Colour
        : this.props.matchInfo && this.props.matchInfo.team2Colour;
    const bowlingTeamPrimaryColour: string =
      battingTeam === 1
        ? this.props.matchInfo && this.props.matchInfo.team2Colour
        : this.props.matchInfo && this.props.matchInfo.team1Colour;
    const battingTeamSecondaryColour: string = pSBC(
      -0.5,
      battingTeamPrimaryColour
    );
    const bowlingTeamSecondaryColour: string = pSBC(
      -0.5,
      bowlingTeamPrimaryColour
    );

    const scorecardBattingTeamId =
      this.props.gameState.started &&
      this.props.gameState.getTeamId(
        this.props.gameState.getBattingTeamForInnings(
          this.state.scorecardInnings,
          this.props.matchFormat
        )
      );
    const scorecardBowlingTeamId =
      this.props.gameState.started &&
      this.props.gameState.getTeamId(
        this.props.gameState.getBowlingTeamForInnings(
          this.state.scorecardInnings,
          this.props.matchFormat
        )
      );

    return (
      <div>
        <div
          className={
            "scorecards-container" +
            (this.props.fullWidth ? " full-width-scorecard" : "")
          }
        >
          {!this.props.gameState.ended && this.props.allowToggleView && (
            <div className="scorecard-switch-full-view-button">
              <IconAndTextButton
                title={
                  this.state.fullViewOpen
                    ? "Back To Current Scores"
                    : "Show Full Scorecard"
                }
                onClick={() => this.toggleFullView(!this.state.fullViewOpen)}
                icon={this.state.fullViewOpen ? "expand_less" : "expand_more"}
              />
            </div>
          )}
          {!this.state.fullViewOpen &&
            !this.props.gameState.ended &&
            this.props.matchInfo && (
              <div className="player-selectors-container">
                <div className="batsman-selectors-container">
                  <div className="batsman-swapper">
                    <TooltipIconButton
                      title="Switch Batsmen"
                      onClick={() =>
                        services.currentGameService.switchBatsmen()
                      }
                      icon="swap_vert_circle"
                    />
                  </div>
                  <div className="batsman-selectors">
                    <BatsmanSelector
                      batsmanNumber={1}
                      matchInfo={this.props.matchInfo}
                      matchFormat={this.props.matchFormat}
                      selectedBatsman={this.props.gameState.batsman1}
                      otherBatsman={this.props.gameState.batsman2}
                      gameState={this.props.gameState}
                      squad={this.squadForId(battingTeamId)}
                      primaryColour={battingTeamPrimaryColour}
                      secondaryColour={battingTeamSecondaryColour}
                      onModalOpen={this.props.onModalOpen}
                      onModalClose={this.props.onModalClose}
                      onSpinnerEdit={this.props.onSpinnerEdit}
                      focussed={this.state.batsman1Focussed}
                      matchStats={this.props.matchStats}
                      playerStats={this.props.playerStats}
                      adminPreferences={this.props.adminPreferences}
                    />
                    <BatsmanSelector
                      batsmanNumber={2}
                      matchInfo={this.props.matchInfo}
                      matchFormat={this.props.matchFormat}
                      selectedBatsman={this.props.gameState.batsman2}
                      otherBatsman={this.props.gameState.batsman1}
                      gameState={this.props.gameState}
                      squad={this.squadForId(battingTeamId)}
                      primaryColour={battingTeamPrimaryColour}
                      secondaryColour={battingTeamSecondaryColour}
                      onModalOpen={this.props.onModalOpen}
                      onModalClose={this.props.onModalClose}
                      onSpinnerEdit={this.props.onSpinnerEdit}
                      focussed={this.state.batsman2Focussed}
                      matchStats={this.props.matchStats}
                      playerStats={this.props.playerStats}
                      adminPreferences={this.props.adminPreferences}
                    />
                  </div>
                </div>
                <div className="bowler-selector">
                  <BowlerSelector
                    selectedBowler={this.props.gameState.bowler}
                    matchInfo={this.props.matchInfo}
                    gameState={this.props.gameState}
                    squad={this.squadForId(bowlingTeamId)}
                    matchFormat={this.props.matchFormat}
                    primaryColour={bowlingTeamPrimaryColour}
                    secondaryColour={bowlingTeamSecondaryColour}
                    onModalOpen={this.props.onModalOpen}
                    onModalClose={this.props.onModalClose}
                    onSpinnerEdit={this.props.onSpinnerEdit}
                    focussed={this.state.bowlerFocussed}
                    matchStats={this.props.matchStats}
                    playerStats={this.props.playerStats}
                  />
                </div>
              </div>
            )}

          {(this.state.fullViewOpen || this.props.gameState.ended) && (
            <div>
              <Box className="scorecard-top-buttons">
                <Tabs
                  value={this.state.tabNumber}
                  onChange={(value, newValue) =>
                    this.setState({ tabNumber: newValue })
                  }
                  aria-label="Scorecard Tabs"
                >
                  <Tab
                    label="Batting"
                    id="scorecard-tab-0"
                    aria-controls="scorecard-tabpanel-0"
                  />
                  <Tab
                    label="Bowling"
                    id="scorecard-tab-1"
                    aria-controls="scorecard-tabpanel-1"
                  />
                </Tabs>
                <div className="scorecard-innings-switch-buttons">
                  <TooltipIconButton
                    title="Previous"
                    onClick={() =>
                      this.setState({
                        scorecardInnings: this.state.scorecardInnings - 1,
                      })
                    }
                    icon="chevron_left"
                    disabled={this.state.scorecardInnings === 1}
                  />
                  {`INNINGS ${this.state.scorecardInnings}`}
                  <TooltipIconButton
                    title="Next"
                    onClick={() =>
                      this.setState({
                        scorecardInnings: this.state.scorecardInnings + 1,
                      })
                    }
                    icon="chevron_right"
                    disabled={
                      this.state.scorecardInnings ===
                      this.props.gameState.innings
                    }
                  />
                  {this.props.allowPrint && (
                    <ScorecardPrintButton
                      open={this.state.scorecardModalOpen}
                      onCancel={() =>
                        this.setState({ scorecardModalOpen: false })
                      }
                      gameState={this.props.gameState}
                      squad1={this.props.squad1}
                      squad2={this.props.squad2}
                      team1={this.props.team1}
                      team2={this.props.team2}
                      match={this.props.match}
                      matchFormat={this.props.matchFormat}
                      ground={this.props.ground}
                    />
                  )}
                </div>
              </Box>

              <TabPanel
                value={this.state.tabNumber}
                index={0}
                className="scorecard-container"
              >
                <BattingScorecardDisplay
                  gameState={this.props.gameState}
                  squad={this.squadForId(scorecardBattingTeamId)}
                  innings={this.state.scorecardInnings}
                  primaryColour={battingTeamPrimaryColour}
                  secondaryColour={battingTeamSecondaryColour}
                />
              </TabPanel>

              <TabPanel
                value={this.state.tabNumber}
                index={1}
                className="scorecard-container"
              >
                <BowlingScorecardDisplay
                  gameState={this.props.gameState}
                  squad={this.squadForId(scorecardBowlingTeamId)}
                  innings={this.state.scorecardInnings}
                  primaryColour={bowlingTeamPrimaryColour}
                />
              </TabPanel>
            </div>
          )}
        </div>
      </div>
    );
  }
}
