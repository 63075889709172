import { Component } from "react";
import { PotSummary } from "../../types/investment/pot-summary";
import { Icon } from "@mui/material";
import { formatCurrency } from "../simulator-page/simulator-utils";

interface Props {
  potSummaryCurrent: PotSummary;
  potSummaryPreview: PotSummary;
}

export class PotComparison extends Component<Props> {
  private propsLoaded(): boolean {
    return !!this.props.potSummaryCurrent && !!this.props.potSummaryPreview;
  }

  public render() {
    if (!this.propsLoaded()) {
      return <div></div>;
    }

    const currentHoldingsStyle = {
      color:
        this.props.potSummaryCurrent.potStatus.totalHoldings < 0
          ? "red"
          : "black",
    };
    const currentDepositsStyle = {
      color:
        this.props.potSummaryCurrent.potStatus.totalDeposits < 0
          ? "red"
          : "black",
    };
    const currentWithdrawalsStyle = {
      color:
        this.props.potSummaryCurrent.potStatus.totalWithdrawals < 0
          ? "red"
          : "black",
    };
    const previewHoldingsStyle = {
      color:
        this.props.potSummaryPreview.potStatus.totalHoldings < 0
          ? "red"
          : "black",
    };
    const previewDepositsStyle = {
      color:
        this.props.potSummaryPreview.potStatus.totalDeposits < 0
          ? "red"
          : "black",
    };
    const previewWithdrawalsStyle = {
      color:
        this.props.potSummaryPreview.potStatus.totalWithdrawals < 0
          ? "red"
          : "black",
    };

    return (
      <div>
        <hr />
        <div className="pot-comparison-title">Total Pot</div>
        <hr />
        <div className="pot-summary-comparison">
          <div>Total Holdings</div>
          <div
            className="pot-summary-comparison-value"
            style={currentHoldingsStyle}
          >
            {formatCurrency(
              this.props.potSummaryCurrent.potStatus.totalHoldings
            )}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewHoldingsStyle}
          >
            {formatCurrency(
              this.props.potSummaryPreview.potStatus.totalHoldings
            )}
          </div>
        </div>
        <div className="pot-summary-comparison">
          <div>Total Deposits</div>
          <div
            className="pot-summary-comparison-value"
            style={currentDepositsStyle}
          >
            {formatCurrency(
              this.props.potSummaryCurrent.potStatus.totalDeposits
            )}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewDepositsStyle}
          >
            {formatCurrency(
              this.props.potSummaryPreview.potStatus.totalDeposits
            )}
          </div>
        </div>
        <div className="pot-summary-comparison">
          <div>Total Withdrawals</div>
          <div
            className="pot-summary-comparison-value"
            style={currentWithdrawalsStyle}
          >
            {formatCurrency(
              this.props.potSummaryCurrent.potStatus.totalWithdrawals
            )}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewWithdrawalsStyle}
          >
            {formatCurrency(
              this.props.potSummaryPreview.potStatus.totalWithdrawals
            )}
          </div>
        </div>
      </div>
    );
  }
}
