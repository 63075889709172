import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { KeycloakUser } from "../../services/keycloak-service";

interface InningsProps {
  simulatingUser: string;
  knownSimulators: KeycloakUser[];
  onChange: (simulatingUser: string) => void;
}

export function BetBuilderOddsProviderControl({
  simulatingUser,
  knownSimulators,
  onChange,
}: InningsProps): React.JSX.Element {
  return (
    <div
      className="compare-user-select-light"
    >
      Odds Provider:
      <FormControl>
        <Select
          value={simulatingUser ? simulatingUser : ""}
          onChange={(selection) =>
            onChange(
              selection.target.value === "" ? null : selection.target.value
            )
          }
          variant="standard"
        >
          {knownSimulators.map((user, index) => (
            <MenuItem key={`user-${index}`} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
