import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Icon,
  Table,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { services } from "../../../types/services";
import {
  bowlingAttackModuleNames,
  BowlingAttackModuleType,
} from "../../../types/simulator/modules/bowling-attack-modules";
import { BowlerChoice } from "../../../types/simulator/simulation-result";
import { Squad } from "../../../types/entities/squad";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  modalOpen: boolean;
  bowlerChoice: BowlerChoice;
  squad1: Squad;
  squad2: Squad;
  onClose: () => void;
}

export class BowlerDecisionModal extends Component<Props> {
  private cloneMap(originalMap: Map<BowlingAttackModuleType, any>) {
    const newMap: Map<BowlingAttackModuleType, any> = new Map();
    originalMap.forEach((value: any, moduleType: BowlingAttackModuleType) => {
      newMap.set(moduleType, value);
    });
    return newMap;
  }

  private sortRowsByTotal(rows: ReactNode[], column: number): ReactNode[] {
    return rows.sort((row1: ReactNode, row2: ReactNode) => {
      let value1Number = 0;
      let value2Number = 0;
      try {
        const value = (row1 as any).props.children[column].props
          .children as string;
        value1Number = value === "-" ? 0 : Number(value);
      } catch (err) {
        value1Number = 0;
      }
      try {
        const value = (row2 as any).props.children[column].props
          .children as string;
        value2Number = value === "-" ? 0 : Number(value);
      } catch (err) {
        value2Number = 0;
      }
      return value2Number - value1Number;
    });
  }

  public render() {
    const tableHeaders = [
      <TableCell key={`bowler-decision-table-header-name`}>Name</TableCell>,
    ];
    const rowData: Map<string, Map<BowlingAttackModuleType, any>> = new Map();
    const defaultMap: Map<BowlingAttackModuleType, any> = new Map();
    const tableRows = [];

    Object.keys(BowlingAttackModuleType).forEach((moduleType) => {
      tableHeaders.push(
        <TableCell key={`bowler-decision-table-header-${moduleType}`}>
          {" "}
          {bowlingAttackModuleNames[moduleType]}{" "}
        </TableCell>
      );
      defaultMap.set(BowlingAttackModuleType[moduleType], null);
    });
    tableHeaders.push(
      <TableCell key={`bowler-decision-table-header-total`}>Total</TableCell>
    );

    const latestValues: Map<string, number> = new Map();
    if (!!this.props.bowlerChoice) {
      this.props.bowlerChoice.rewards.forEach(
        (value: [BowlingAttackModuleType, Map<String, number>]) => {
          value[1].forEach((valueAtModule: number, playerId: string) => {
            if (!rowData.get(playerId)) {
              rowData.set(playerId, this.cloneMap(defaultMap));
            }

            const latestValue = latestValues.get(playerId) || 0;
            if (
              BowlingAttackModuleType[value[0]] ===
              BowlingAttackModuleType.ELIGIBLE_TO_BOWL
            ) {
              rowData
                .get(playerId)
                .set(BowlingAttackModuleType[value[0]], true);
            } else {
              rowData
                .get(playerId)
                .set(
                  BowlingAttackModuleType[value[0]],
                  valueAtModule - latestValue
                );
            }
            latestValues.set(playerId, valueAtModule);
          });
        }
      );
    }

    if (
      !!this.props.squad1 &&
      !!this.props.squad2 &&
      !!this.props.bowlerChoice
    ) {
      const bowlerInSquad1: boolean =
        this.props.squad1 &&
        this.props.squad1.players &&
        this.props.bowlerChoice &&
        this.props.squad1.players.find(
          (p) => p.playerId.value === this.props.bowlerChoice.bowler
        ) !== undefined;
      const bowlingSquad = bowlerInSquad1
        ? this.props.squad1
        : this.props.squad2;

      bowlingSquad.players.forEach((player, index) => {
        const tableCells = [];
        tableCells.push(
          <TableCell key={`bowler-decision-table-row-${index}-name`}>
            {services.teamPlayerService.getPlayerName(
              player.playerId,
              bowlerInSquad1 ? this.props.squad1 : this.props.squad2
            )}
          </TableCell>
        );
        const thisPlayerData = rowData.get(player.playerId.value);
        if (!!thisPlayerData) {
          thisPlayerData.forEach((value: any, key: BowlingAttackModuleType) => {
            tableCells.push(
              <TableCell key={`bowler-decision-table-row-${index}-${key}`}>
                {typeof value === "boolean" ? (
                  value ? (
                    <Icon color="primary">check_circle</Icon>
                  ) : (
                    <Icon>cancel</Icon>
                  )
                ) : value === null ? (
                  "-"
                ) : (
                  value.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                )}
              </TableCell>
            );
          });
        } else {
          Object.keys(BowlingAttackModuleType).forEach((moduleType) => {
            tableCells.push(
              <TableCell
                key={`bowler-decision-table-row-${index}-${moduleType}`}
              >
                {moduleType === BowlingAttackModuleType.ELIGIBLE_TO_BOWL ? (
                  <Icon>cancel</Icon>
                ) : (
                  "-"
                )}
              </TableCell>
            );
          });
        }

        const playerTotal = latestValues.get(player.playerId.value);
        tableCells.push(
          <TableCell key={`bowler-decision-table-row-${index}-total`}>
            {(playerTotal &&
              playerTotal.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })) ||
              "-"}
          </TableCell>
        );
        tableRows.push(
          <TableRow
            key={`bowler-decision-table-row-${index}`}
            style={{
              backgroundColor:
                player.playerId.value === this.props.bowlerChoice.bowler
                  ? "yellow"
                  : "transparent",
            }}
          >
            {tableCells}
          </TableRow>
        );
      });
    }

    this.sortRowsByTotal(tableRows, tableHeaders.length - 1);

    return (
      <CreationDialog
        open={this.props.modalOpen}
        label={"Bowler Decision"}
        invalid={false}
        disabled={true}
        onCancel={() => this.props.onClose()}
        onProceed={() => this.props.onClose()}
        proceedText=""
        disabledText=""
        colour="#31b788"
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>{tableHeaders}</TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </CreationDialog>
    );
  }
}
