import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { Component } from "react";

import { humanReadablePushBrackets } from "../../types/enums/push-bracket";
import { AdminConfidences } from "../../types/preferences/admin-preferences";
import { UUID } from "../../types/uuid";
import { TabPanel } from "../match-page/scorecard/tab-panel";

import { ConfidenceSrAndWpcSetterRow } from "./confidence-sr-and-wpc-setter-row";

interface Props {
  adminConfidences: AdminConfidences;
  onUpdate: (type: string, field: string, value: number) => void;
}

interface State {
  confidencesTabNumber: number;
}

export class ConfidenceSettingTable extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      confidencesTabNumber: 0,
    };
  }

  public render() {
    return (
      <div>
        <Box>
          <Tabs
            value={this.state.confidencesTabNumber}
            onChange={(value, newValue) =>
              this.setState({ confidencesTabNumber: newValue })
            }
            aria-label="default-confidences-tabs"
          >
            <Tab
              label={`Batting`}
              key={`default-confidences-tab-0`}
              aria-controls={`default-confidences-tab-0`}
            />
            <Tab
              label={`Bowling`}
              key={`default-confidences-tab-1`}
              aria-controls={`default-confidences-tab-1`}
            />
            <Tab
              label={`Ground`}
              key={`default-confidences-tab-2`}
              aria-controls={`default-confidences-tab-2`}
            />
          </Tabs>
        </Box>

        <TabPanel
          value={this.state.confidencesTabNumber}
          index={0}
          key={`default-confidences-tab-panel-0`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Batting</TableCell>
                <TableCell align="right">Strike Rate</TableCell>
                <TableCell align="right">Wicket Percent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                { field: "battingGlobalConfidenceLimit", title: "Global" },
                {
                  field: "battingBlockingConfidenceLimit",
                  title: humanReadablePushBrackets.BLOCKING,
                },
                {
                  field: "battingDefendingConfidenceLimit",
                  title: humanReadablePushBrackets.DEFENSIVE,
                },
                {
                  field: "battingNormalConfidenceLimit",
                  title: humanReadablePushBrackets.NORMAL,
                },
                {
                  field: "battingPushingConfidenceLimit",
                  title: humanReadablePushBrackets.PUSHING,
                },
                {
                  field: "battingFullPushConfidenceLimit",
                  title: humanReadablePushBrackets.FULL_PUSH,
                },
                {
                  field: "battingBowlTypeConfidenceLimit",
                  title: "Bowler Type",
                },
                {
                  field: "battingBallsFacedPhase1ConfidenceLimit",
                  title: "Up to Balls Faced 1",
                },
                {
                  field: "battingBallsFacedPhase2ConfidenceLimit",
                  title: "Up to Balls Faced 2",
                },
                {
                  field: "battingBallsFacedPhase3ConfidenceLimit",
                  title: "After Balls Faced 2",
                },
              ].map(({ field, title }) => (
                <ConfidenceSrAndWpcSetterRow
                  key={UUID.randomUUID().value}
                  adminConfidences={this.props.adminConfidences}
                  field={field}
                  title={title}
                  onUpdate={(type, field, value) =>
                    this.props.onUpdate(type, field, value)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TabPanel>

        <TabPanel
          value={this.state.confidencesTabNumber}
          index={1}
          key={`default-confidences-tab-panel-1`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bowling</TableCell>
                <TableCell align="right">Strike Rate</TableCell>
                <TableCell align="right">Wicket Percent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                { field: "bowlingGlobalConfidenceLimit", title: "Global" },
                {
                  field: "bowlingBlockingConfidenceLimit",
                  title: humanReadablePushBrackets.BLOCKING,
                },
                {
                  field: "bowlingDefendingConfidenceLimit",
                  title: humanReadablePushBrackets.DEFENSIVE,
                },
                {
                  field: "bowlingNormalConfidenceLimit",
                  title: humanReadablePushBrackets.NORMAL,
                },
                {
                  field: "bowlingPushingConfidenceLimit",
                  title: humanReadablePushBrackets.PUSHING,
                },
                {
                  field: "bowlingFullPushConfidenceLimit",
                  title: humanReadablePushBrackets.FULL_PUSH,
                },
                {
                  field: "bowlingBatTypeConfidenceLimit",
                  title: "Batsman Type",
                },
              ].map(({ field, title }) => (
                <ConfidenceSrAndWpcSetterRow
                  key={UUID.randomUUID().value}
                  adminConfidences={this.props.adminConfidences}
                  field={field}
                  title={title}
                  onUpdate={(type, field, value) =>
                    this.props.onUpdate(type, field, value)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TabPanel>

        <TabPanel
          value={this.state.confidencesTabNumber}
          index={2}
          key={`default-confidences-tab-panel-2`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ground</TableCell>
                <TableCell align="right">Strike Rate</TableCell>
                <TableCell align="right">Wicket Percent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                { field: "groundGlobalConfidenceLimit", title: "Global" },
                {
                  field: "groundBlockingConfidenceLimit",
                  title: humanReadablePushBrackets.BLOCKING,
                },
                {
                  field: "groundDefendingConfidenceLimit",
                  title: humanReadablePushBrackets.DEFENSIVE,
                },
                {
                  field: "groundNormalConfidenceLimit",
                  title: humanReadablePushBrackets.NORMAL,
                },
                {
                  field: "groundPushingConfidenceLimit",
                  title: humanReadablePushBrackets.PUSHING,
                },
                {
                  field: "groundFullPushConfidenceLimit",
                  title: humanReadablePushBrackets.FULL_PUSH,
                },
                {
                  field: "groundBowlTypeConfidenceLimit",
                  title: "Bowler Type",
                },
              ].map(({ field, title }) => (
                <ConfidenceSrAndWpcSetterRow
                  key={UUID.randomUUID().value}
                  adminConfidences={this.props.adminConfidences}
                  field={field}
                  title={title}
                  onUpdate={(type, field, value) =>
                    this.props.onUpdate(type, field, value)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TabPanel>
      </div>
    );
  }
}
