import { Tooltip } from "@mui/material";
import { Component, ReactNode } from "react";
import { Ball } from "../../types/entities/ball";
import { MatchFormat } from "../../types/entities/match-format";
import {
  OverSummary,
  SimulatorRecordedBowl,
} from "../../types/simulator/simulation-result";
import { Squad } from "../../types/entities/squad";
import { round } from "../../types/util-functions";
import { BallDisplay } from "../match-page/event-display/ball-display";
import { recordedBallOutcomeNames } from "./simulator-utils";

interface Props {
  recordedBall: SimulatorRecordedBowl;
  ball: Ball;
  overToggle: boolean;
  overSummary: OverSummary;
  squad1: Squad;
  squad2: Squad;
  matchFormat: MatchFormat;
}

export class SimulationBallDisplay extends Component<Props> {
  private calculatePercentsText(
    recordedBall: SimulatorRecordedBowl
  ): ReactNode {
    return (
      <div className="simulation-ball-percents">
        <div className="simulation-ball-percents-row">
          <div>0:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[0] * 100, 2)}
          </div>
        </div>
        <div className="simulation-ball-percents-row">
          <div>1:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[1] * 100, 2)}
          </div>
        </div>
        <div className="simulation-ball-percents-row">
          <div>2:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[2] * 100, 2)}
          </div>
        </div>
        <div className="simulation-ball-percents-row">
          <div>3:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[3] * 100, 2)}
          </div>
        </div>
        <div className="simulation-ball-percents-row">
          <div>4:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[4] * 100, 2)}
          </div>
        </div>
        <div className="simulation-ball-percents-row">
          <div>6:</div>{" "}
          <div>
            {round(this.props.recordedBall.adjustedBattingPercents[5] * 100, 2)}
          </div>
        </div>
      </div>
    );
  }

  private calculateDesperateRunText(
    recordedBall: SimulatorRecordedBowl
  ): ReactNode {
    return (
      <div className="simulation-ball-percents">
        <div className="simulation-ball-percents-row">
          Originally {recordedBallOutcomeNames[recordedBall.originalOutcome]}
        </div>
      </div>
    );
  }

  public render() {
    const push = this.props.recordedBall.aggression;
    const adjustedPush = this.props.recordedBall.adjustedAggression;
    const sr = this.props.recordedBall.strikeRateAndWicketPercent[0];
    const adjustedSr =
      this.props.recordedBall.adjustedStrikeRateAndWicketPercent[0];
    const wpc = this.props.recordedBall.strikeRateAndWicketPercent[1] * 100;
    const adjustedWpc =
      this.props.recordedBall.adjustedStrikeRateAndWicketPercent[1] * 100;
    return (
      <div>
        <BallDisplay
          ball={this.props.ball}
          overSummary={this.props.overSummary}
          overToggle={Number(this.props.ball.over.split(".")[0]) % 2 === 1}
          squad1={this.props.squad1}
          squad2={this.props.squad2}
          matchFormat={this.props.matchFormat}
        />
        <div>
          <Tooltip title={this.calculatePercentsText(this.props.recordedBall)}>
            <div>
              <div
                className="simulation-ball-display-stats"
                style={{ backgroundColor: `rgb(${push * 2.5}, 140, 80)` }}
              >
                {round(push)}
              </div>
              <div
                className="simulation-ball-display-stats"
                style={{
                  backgroundColor: `rgb(${adjustedPush * 2.5}, 140, 80)`,
                }}
              >
                {round(adjustedPush)}
              </div>
              <div
                className="simulation-ball-display-stats"
                style={{
                  backgroundColor: `rgb(${(adjustedSr / sr) * 1.3}, 140, 80)`,
                }}
              >
                {round(adjustedSr / sr, 2)}
              </div>
              <div
                className="simulation-ball-display-stats"
                style={{ backgroundColor: `rgb(${adjustedSr * 1.3}, 140, 80)` }}
              >
                {round(adjustedSr)}
              </div>
              <div
                className="simulation-ball-display-stats"
                style={{
                  backgroundColor: `rgb(${(adjustedWpc / wpc) * 25}, 140, 80)`,
                }}
              >
                {round(adjustedWpc / wpc, 2)}
              </div>
              <div
                className="simulation-ball-display-stats"
                style={{ backgroundColor: `rgb(${adjustedWpc * 25}, 140, 80)` }}
              >
                {round(adjustedWpc, 2)}
              </div>
            </div>
          </Tooltip>
          {this.props.recordedBall.desperateRun && (
            <Tooltip
              title={this.calculateDesperateRunText(this.props.recordedBall)}
            >
              <div
                className="simulation-ball-display-stats"
                style={{ backgroundColor: `rgb(255, 140, 80)` }}
              >
                true
              </div>
            </Tooltip>
          )}
          {!this.props.recordedBall.desperateRun && (
            <div
              className="simulation-ball-display-stats"
              style={{ backgroundColor: `rgb(1, 140, 80)` }}
            >
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }
}
