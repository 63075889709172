import { Component } from "react";
import { Subscription } from "rxjs";

import { MarketType } from "../../types/betfair/betfair-message";
import { Match } from "../../types/entities/match";
import { BetfairRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { BetfairDisplay } from "../betfair-page/betfair-display/betfair-display";

import { MatchFormat } from "../../types/entities/match-format";
import { BetfairMarketHistoryDisplay } from "./betfair-display/betfair-market-history-display";
import { BetfairPriceDisplay } from "./betfair-display/betfair-price-display";
import { BetfairMatchDisplay } from "./betfair-match-display";

interface State {
  marketHistory: Map<string, Map<number, number>>;
  marketHistoryType: MarketType;
  marketHistorySelection: string;
  currentMatch: Match;
  currentMatchFormat: MatchFormat;
}

export class BetfairPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  componentDidMount(): void {
    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(() => {
        if (!!this.state.marketHistory) {
          this.showMarketHistory(
            this.state.marketHistoryType,
            this.state.marketHistorySelection
          );
        }
      })
    );
    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (currentMatch: Match) => {
          this.setState({ currentMatch });
        }
      )
    );
    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (currentMatchFormat: MatchFormat) => {
          this.setState({ currentMatchFormat });
        }
      )
    );
    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        () => {
          if (!!this.state.marketHistory) {
            this.showMarketHistory(
              this.state.marketHistoryType,
              this.state.marketHistorySelection
            );
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      marketHistory: null,
      marketHistoryType: null,
      marketHistorySelection: null,
      currentMatch: null,
      currentMatchFormat: null,
    };
    this.subscriptions = [];
  }

  private showMarketHistory(marketType: MarketType, selection: string): void {
    services.betfairService
      .getMarketHistory(marketType, selection)
      .then((marketHistory) => {
        this.setState({
          marketHistory,
          marketHistoryType: marketType,
          marketHistorySelection: selection,
        });
      });
  }

  public render() {
    return (
      <div className="full-push-background-light with-navbar betfair-page">
        <BetfairRoute>
          {!!this.state.currentMatch && !!this.state.currentMatch.matchId && (
            <div className="betfair-page-rows">
              <div className="page-title">Betfair</div>
              <BetfairMatchDisplay />
              <div
                className={
                  !!this.state.marketHistory
                    ? "betfair-page-layout-2"
                    : "betfair-page-layout"
                }
              >
                <BetfairDisplay />
                <BetfairPriceDisplay
                  onShowMarketHistory={(marketType, selection) =>
                    this.showMarketHistory(marketType, selection)
                  }
                  matchFormat={this.state.currentMatchFormat}
                />
                {!!this.state.marketHistory && (
                  <BetfairMarketHistoryDisplay
                    marketHistory={this.state.marketHistory}
                    marketType={this.state.marketHistoryType}
                    selection={this.state.marketHistorySelection}
                  />
                )}
              </div>
            </div>
          )}
          {!(this.state.currentMatch && this.state.currentMatch.matchId) && (
            <div className="page-title">No match selected</div>
          )}
        </BetfairRoute>
      </div>
    );
  }
}
