import { Subject, ReplaySubject } from "rxjs";
import { showMessage } from "../types/services";
import { UUID } from "../types/uuid";
import { HttpService } from "./http-service";

enum MatchGrade {
  PROPER = "PROPER",
  SHIT = "SHIT",
  CROSSOVER = "CROSSOVER",
  IGNORE = "IGNORE",
  MANUAL = "MANUAL",
}

const matchGradeImportance: Record<MatchGrade, number> = {
  PROPER: 0,
  SHIT: 2,
  CROSSOVER: 1,
  IGNORE: 4,
  MANUAL: 3,
};

export const matchGradeColours: Record<MatchGrade, string> = {
  PROPER: "#b0f768",
  SHIT: "#f7c368",
  CROSSOVER: "#b0f768",
  IGNORE: "#9a68f7",
  MANUAL: "#68adf7",
};

export interface CricInfoMatch {
  team1Id: UUID;
  team2Id: UUID;
  espnNum: number;
  seriesId: UUID;
  groundId: UUID;
  description: string;
  date: string;
  matchGrade: MatchGrade;
  status: string;
  statusText: string;
  timestamp: number;
}

export class ScraperService {
  public cricinfoMatchesSubject: Subject<CricInfoMatch[]> = new ReplaySubject(
    1
  );
  public cricInfoMatchesLoadingSubject: Subject<boolean> = new ReplaySubject(1);
  private httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public refresh() {
    this.cricInfoMatchesLoadingSubject.next(true);
    this.getCricInfoMatches().then((matches: CricInfoMatch[]) => {
      this.cricinfoMatchesSubject.next(matches);
      this.cricInfoMatchesLoadingSubject.next(false);
    });
  }

  private async getCricInfoMatches(): Promise<CricInfoMatch[]> {
    return await this.httpService
      .get(`/api/scrape-controller/get-recent`)
      .then((response: any[]) => {
        const result: CricInfoMatch[] = this.deserializeMatches(response);
        result.sort((match1, match2) => {
          if (
            match1.status !== match2.status &&
            (match1.status === "Live" || match2.status === "Live")
          ) {
            return match1.status === "Live" ? -1 : 1;
          } else if (match1.matchGrade !== match2.matchGrade) {
            return (
              matchGradeImportance[match1.matchGrade] -
              matchGradeImportance[match2.matchGrade]
            );
          } else {
            return (
              new Date(match1.date).getMilliseconds() -
              new Date(match2.date).getMilliseconds()
            );
          }
        });
        return result;
      })
      .catch((reason) => {
        showMessage(`Failed to load recent matches: ${reason}`, "error");
        return null;
      });
  }

  private deserializeMatches(response: any[]): CricInfoMatch[] {
    const matches: CricInfoMatch[] = [];
    response.forEach((element: any) =>
      matches.push(this.deserializeOneMatch(element))
    );
    return matches;
  }

  private deserializeOneMatch(response: any): CricInfoMatch {
    return {
      team1Id:
        (!!response.team1Id && UUID.fromString(response.team1Id)) || null,
      team2Id:
        (!!response.team2Id && UUID.fromString(response.team2Id)) || null,
      espnNum: response.espnNum,
      seriesId:
        (!!response.seriesId && UUID.fromString(response.seriesId)) || null,
      groundId:
        (!!response.groundId && UUID.fromString(response.groundId)) || null,
      description: response.description,
      date: response.date,
      matchGrade: MatchGrade[response.matchGrade],
      status: response.status,
      statusText: response.statusText,
      timestamp: response.timestamp,
    };
  }
}
