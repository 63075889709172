import { Component } from "react";
import { Subscription } from "rxjs";
import { SimulatorRoute } from "../../types/route-helpers";
import {
  CircularProgress,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { services } from "../../types/services";
import { GroundStatsLoad, PlayerStatsLoad } from "../../types/stats/stats-load";
import { UUID } from "../../types/uuid";
import { Player } from "../../types/entities/player";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { Ground } from "../../types/entities/ground";

interface State {
  playerStatsLoads: [UUID, Player, PlayerStatsLoad][];
  groundStatsLoad: [UUID, Ground, GroundStatsLoad];
}

export class StatsLoadingPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    playerStatsLoads: [],
    groundStatsLoad: null,
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...StatsLoadingPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.playerStatsService.loadedPlayerSubject.subscribe(
        (playerStatsLoads: [UUID, Player, PlayerStatsLoad][]) => {
          this.setState({ playerStatsLoads });
        }
      )
    );
    this.subscriptions.push(
      services.groundStatsService.loadedGroundSubject.subscribe(
        (groundStatsLoad: [UUID, Ground, GroundStatsLoad]) => {
          this.setState({ groundStatsLoad });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public render() {
    return (
      <SimulatorRoute>
        <div className="full-push-background-light with-navbar">
          <div className="stats-loading-page">
            <div className="stats-loading-page-body">
              <div className="stats-loading-refresh">
                <TooltipIconButton
                  title={"Recalculate Stats"}
                  disabled={false}
                  onClick={() => services.simulationService.simulate()}
                  icon={"refresh"}
                />
              </div>
              <div className="stats-loading">
                <CircularProgress size={20} />
                <div>Loading stats..</div>
              </div>
              <div className="stats-loading-players">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Cause</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!this.state.playerStatsLoads &&
                      this.state.playerStatsLoads.map(
                        ([id, player, playerStatsLoad]) => {
                          return (
                            <TableRow key={id.value}>
                              <TableCell>{player.longName}</TableCell>
                              <TableCell>
                                {!!playerStatsLoad &&
                                  (playerStatsLoad.result === "SUCCESS" ? (
                                    <Icon color="action">done</Icon>
                                  ) : (
                                    <Icon color="error">close</Icon>
                                  ))}
                              </TableCell>
                              <TableCell>
                                {!!playerStatsLoad &&
                                  (playerStatsLoad.description === "Success"
                                    ? ""
                                    : playerStatsLoad.description)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    <TableRow>
                      <TableCell>
                        {!!this.state.groundStatsLoad &&
                          this.state.groundStatsLoad[1].name}
                      </TableCell>
                      <TableCell>
                        {!!this.state.groundStatsLoad &&
                          !!this.state.groundStatsLoad[2] &&
                          (this.state.groundStatsLoad[2].result ===
                          "SUCCESS" ? (
                            <Icon color="action">done</Icon>
                          ) : (
                            <Icon color="error">close</Icon>
                          ))}
                      </TableCell>
                      <TableCell>
                        {!!this.state.groundStatsLoad &&
                          !!this.state.groundStatsLoad[2] &&
                          (this.state.groundStatsLoad[2].description ===
                          "Success"
                            ? ""
                            : this.state.groundStatsLoad[2].description)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </SimulatorRoute>
    );
  }
}
