import { ReactComponent as MALE } from "../../img/genders/male.svg";
import { ReactComponent as FEMALE } from "../../img/genders/female.svg";
import { ReactNode } from "react";

export enum Gender {
  M = "M",
  F = "F",
}

export const genderNames: Record<Gender, string> = {
  M: "Male",
  F: "Female",
};

export const genderImages: Record<Gender, ReactNode> = {
  M: <MALE />,
  F: <FEMALE />,
};
