import { Component } from "react";
import { compareValues } from "../component-utils";
import { formatToDp } from "../simulator-page/simulator-utils";

interface Props {
  label: string;
  originalValue: number;
  comparedValue: number;
  decimalPlaces: number;
}

export class NumberComparison extends Component<Props, {}> {
  static defaultProps = {
    decimalPlaces: 2,
  };

  public render() {
    return (
      <div className="number-comparison">
        <div
          style={{
            color: compareValues(
              this.props.originalValue,
              this.props.comparedValue
            ),
          }}
        >
          {this.props.label}
        </div>
        <div
          style={{
            color: compareValues(
              this.props.originalValue,
              this.props.comparedValue
            ),
          }}
        >
          {formatToDp(this.props.comparedValue, this.props.decimalPlaces)}
        </div>
      </div>
    );
  }
}
