import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component } from "react";
import { Subscription } from "rxjs";

import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { PlayerSimulationQuestionStatistic } from "../../../types/enums/question-statistic";
import { SimulatorScenario } from "../../../types/preferences/preferences";
import { services } from "../../../types/services";
import { SimulationResult } from "../../../types/simulator/simulation-result";
import { UUID } from "../../../types/uuid";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { PlayerData } from "./player-median-and-odds-types";
import {
  buildMedianQuestionsForSquad,
  buildOddsQuestion,
  buildOddsQuestionsForSquad,
} from "./player-median-and-odds-utils";
import { SimulatorPlayerInningsMedianEditor } from "./simulator-player-median-editor";
import SimulatorPlayerMedianRow from "./simulator-player-median-row";

type Props = {
  gameState: GameState;
  matchFormat: MatchFormat;
  team1: Team;
  team2: Team;
  squad1: Squad;
  squad2: Squad;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
};

interface State {
  refreshing: boolean;
  playerData: {
    [teamId: string]: PlayerData[];
  };
  questionType: PlayerSimulationQuestionStatistic;
}

export class SimulatorPlayerMedianQuestionDisplay extends Component<
  Props,
  State
> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      refreshing: false,
      playerData: {},
      questionType: PlayerSimulationQuestionStatistic.SCORE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        (scenarioResults: [SimulatorScenario, SimulationResult]) => {
          if (!!scenarioResults) {
            this.refreshAllData();
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async loadDataForSquad(squad: Squad, teamId: string) {
    this.setState({ refreshing: true });

    if (!squad) {
      return;
    }

    const medianResponses = await services.questionService.askMedianQuestions(
      this.props.gameState.matchId,
      this.props.gameState.eventSequence,
      buildMedianQuestionsForSquad(squad, this.state.questionType),
      this.props.matchFormat
    );

    const oddsResponses = await services.questionService.askOddsQuestions(
      this.props.gameState.matchId,
      this.props.gameState.eventSequence,
      buildOddsQuestionsForSquad(
        squad,
        this.state.questionType,
        medianResponses
      ),
      this.props.matchFormat
    );

    const playerData = squad.players.map((player, index) => {
      // Sometimes the medianResponse for a given player / index doesn't exist
      const medianResponse = Array.isArray(medianResponses[index])
        ? medianResponses[index][0]
        : 0;

      return {
        playerId: player.playerId,
        name: player.longName,
        median: medianResponse,
        odds: oddsResponses[index],
        oddsOperand: medianResponse,
      };
    });

    this.setState((prevState) => ({
      playerData: {
        ...prevState.playerData,
        [teamId]: playerData,
      },
      refreshing: false,
    }));
  }

  refreshAllData() {
    this.loadDataForSquad(this.props.squad1, this.props.squad1.teamId.value);
    this.loadDataForSquad(this.props.squad2, this.props.squad2.teamId.value);
  }

  renderPlayerData(teamId: string) {
    const playerData = this.state.playerData[teamId];
    if (!playerData) {
      return null;
    }

    const playersWithData = playerData.filter(
      (player) => player.median !== null
    );

    if (playersWithData.length === 0) {
      return null;
    }

    const tableHead = (
      <TableHead>
        <TableRow>
          <TableCell id="scorecard-table-cell">Name</TableCell>
          <TableCell align="center" id="scorecard-table-cell">
            Median
          </TableCell>
          <TableCell align="right" id="scorecard-table-cell">
            Odds
          </TableCell>
        </TableRow>
      </TableHead>
    );

    return (
      <TableContainer>
        <Table aria-label="simple table">
          {tableHead}
          <TableBody>
            {playersWithData.map((player) => (
              <SimulatorPlayerMedianRow
                key={player.playerId.value}
                player={player}
                teamId={teamId}
                updateOddsOperand={this.handleOddsOperandChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  handleOddsOperandChange = async (
    playerId: UUID,
    teamId: string,
    newOddsOperand: number
  ) => {
    const newQuestionResponse = await services.questionService.askOddsQuestions(
      this.props.gameState.matchId,
      this.props.gameState.eventSequence,
      buildOddsQuestion(playerId, this.state.questionType, newOddsOperand),
      this.props.matchFormat
    );

    this.setState((prevState) => ({
      playerData: {
        ...prevState.playerData,
        [teamId]: prevState.playerData[teamId].map((player) =>
          player.playerId.value === playerId.value
            ? {
                ...player,
                oddsOperand: newOddsOperand,
                odds: newQuestionResponse[0],
              }
            : player
        ),
      },
    }));
  };

  public render() {
    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Player Median Question Machine
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading || this.state.refreshing}
              spinner
              text={
                this.props.loading ? "Simulating..." : "Asking Questions..."
              }
            >
              {!!Object.keys(this.state.playerData).length && (
                <div className="simulation-medians-question">
                  <SimulatorPlayerInningsMedianEditor
                    gameState={this.props.gameState}
                    matchFormat={this.props.matchFormat}
                    onChange={(questionStatistic) => {
                      this.setState(
                        (prevState) => ({
                          ...prevState,
                          questionType: questionStatistic,
                        }),
                        () => {
                          this.refreshAllData();
                        }
                      );
                    }}
                  />
                  <div>
                    {this.renderPlayerData(this.props.squad1.teamId.value)}
                  </div>
                  <div>
                    {this.renderPlayerData(this.props.squad2.teamId.value)}
                  </div>
                </div>
              )}
              {!Object.keys(this.state.playerData).length && (
                <div className="simulation-medians-list italic">
                  Data will display on Simulation Refresh
                </div>
              )}
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
