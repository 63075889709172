import { ReplaySubject, Subject } from "rxjs";

import { Ground } from "../types/entities/ground";
import { Match } from "../types/entities/match";
import { Country } from "../types/enums/country";
import { MatchType } from "../types/enums/match-type";
import { services, showMessage } from "../types/services";
import {
  GroundFormExplanation,
  deserializeGroundFormExplanation,
} from "../types/stats/form-explanation";
import {
  GroundStats,
  GroundStatsWrapper,
  MatchGroundStats,
  serializeMatchGroundStats,
} from "../types/stats/ground-stats";
import { GroundStatsLoad } from "../types/stats/stats-load";
import { UUID } from "../types/uuid";

import { EntityService } from "./entity-services/entity-service";
import { KeycloakUser } from "./keycloak-service";

export interface HistoricFormUpdateMessage {
  requestId: UUID;
  message: string;
  done: number;
  size: number;
}

export class GroundStatsService extends EntityService<GroundStatsWrapper> {
  public loadedGroundSubject: Subject<[UUID, Ground, GroundStatsLoad]> =
    new ReplaySubject(1);
  public loadedGroundStats: [UUID, Ground, GroundStatsLoad];

  public groundStatsSubject: Subject<GroundStatsWrapper> = new ReplaySubject(1);
  public groundStats: GroundStatsWrapper;

  public comparedGroundStatsSubject: Subject<GroundStatsWrapper> =
    new ReplaySubject(1);
  public teamGroundStats: Map<string, GroundStatsWrapper>;

  public historicFormRequestSubject: Subject<HistoricFormUpdateMessage> =
    new ReplaySubject(1);

  public ground: Ground;
  private calculateAllRequestId: UUID;

  public initiateLoadedStats() {
    services.currentGameService.currentMatchSubject.subscribe(
      (currentMatch: Match) => this.updateStats(currentMatch)
    );
    services.currentGameService.groundSubject.subscribe((ground: Ground) =>
      this.updateGround(ground)
    );
    services.keycloakService.comparedUserSubject.subscribe(
      (comparedUser: KeycloakUser) => this.broadcastComparedStats(comparedUser)
    );
  }

  public setCalculateAllRequestId(requestId: UUID) {
    this.calculateAllRequestId = requestId;
  }

  private broadcastComparedStats(comparedUser: KeycloakUser) {
    if (!!comparedUser) {
      this.comparedGroundStatsSubject.next(
        this.teamGroundStats.get(comparedUser.id) || null
      );
    } else {
      this.comparedGroundStatsSubject.next(null);
    }
  }

  private updateStats(match: Match) {
    if (!!match) {
      this.getGroundStats(match.matchId).then((result: GroundStatsWrapper) => {
        if (!!result) {
          this.groundStats = result;
          this.groundStatsSubject.next(result);
        }
      });
      this.getTeamGroundStats(match.matchId).then(
        (result: Map<string, GroundStatsWrapper>) => {
          if (!!result) {
            this.teamGroundStats = result;
            this.broadcastComparedStats(services.keycloakService.comparedUser);
          }
        }
      );
    } else {
      this.groundStats = null;
      this.groundStatsSubject.next(null);
      this.teamGroundStats = new Map();
      this.broadcastComparedStats(services.keycloakService.comparedUser);
    }
  }

  private updateGround(ground: Ground) {
    if (!!ground) {
      this.ground = ground;
      this.loadedGroundStats = [ground.groundId, ground, null];
      this.loadedGroundSubject.next(this.loadedGroundStats);
    } else {
      this.ground = null;
      this.loadedGroundStats = null;
      this.loadedGroundSubject.next(this.loadedGroundStats);
    }
  }

  public recalculateAllHistoric() {
    this.calculateAllRequestId = UUID.randomUUID();
    const params: Map<string, string> = new Map();
    params.set("requestId", this.calculateAllRequestId.value);

    services.httpService
      .get(`/api/historic-stats-controller/recalculate-all`, params, false)
      .catch((reason) => {
        showMessage(`Failed to recalculate all stats: ${reason}`, "error");

        return null;
      });
  }

  private async getGroundStats(matchId: UUID): Promise<GroundStatsWrapper> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/ground-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return null;
        }

        return GroundStatsWrapper.deserializeOne(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load ground stats: ${reason}`, "error");

        return null;
      });
  }

  private async getTeamGroundStats(
    matchId: UUID
  ): Promise<Map<string, GroundStatsWrapper>> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/team-ground-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return new Map();
        }

        const map: Map<string, GroundStatsWrapper> = new Map();
        Object.keys(response).forEach((userId) => {
          map.set(userId, GroundStatsWrapper.deserializeOne(response[userId]));
        });

        return map;
      })
      .catch((reason) => {
        showMessage(`Failed to load team ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async generateGroundStats(
    matchFormatId: UUID,
    groundId: UUID
  ): Promise<GroundStats> {
    const params: Map<string, string> = new Map();
    params.set("matchFormatId", matchFormatId.value);
    params.set("matchId", services.currentGameService.currentMatchId.value);
    params.set("groundId", groundId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/generate-ground-stats`, params)
      .then((response: any) => {
        return response as GroundStats;
      })
      .catch((reason) => {
        showMessage(`Failed to generate ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async explainGroundStats(
    matchFormatId: UUID,
    groundId: UUID
  ): Promise<GroundFormExplanation> {
    const params: Map<string, string> = new Map();
    params.set("matchFormatId", matchFormatId.value);
    params.set("matchId", services.currentGameService.currentMatchId.value);
    params.set("groundId", groundId.value);

    return await this.httpService
      .get(
        `/api/${this.controllerName}/generate-ground-stats-explanation`,
        params
      )
      .then((response: any) => {
        return deserializeGroundFormExplanation(response);
      })
      .catch((reason) => {
        showMessage(
          `Failed to generate ground stats explanation: ${reason}`,
          "error"
        );

        return null;
      });
  }

  public async getGroundDefaultStats(
    matchId: UUID,
    groundId: UUID
  ): Promise<GroundStats> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("groundId", groundId.value);

    return await this.httpService
      .get(
        `/api/${this.controllerName}/restore-from-ground-default-stats`,
        params
      )
      .then((response: any) => {
        return response as GroundStats;
      })
      .catch((reason) => {
        showMessage(`Failed to get ground default stats: ${reason}`, "error");

        return null;
      });
  }

  public async saveGroundDefaultStats(
    matchType: MatchType,
    groundId: UUID,
    groundStats: GroundStats
  ): Promise<boolean> {
    const request = {
      matchType,
      groundId: groundId.value,
      stats: groundStats,
    };

    return await this.httpService
      .post(`/api/${this.controllerName}/update-ground-default-stats`, request)
      .then(() => {
        showMessage(`Updated default ground stats`, "info");

        return true;
      })
      .catch((reason) => {
        showMessage(
          `Failed to update ground default stats: ${reason}`,
          "error"
        );

        return null;
      });
  }

  public async getDefaultGroundStats(
    matchType: MatchType
  ): Promise<GroundStats> {
    const params: Map<string, string> = new Map();
    params.set("matchType", matchType);

    return await this.httpService
      .get(`/api/${this.controllerName}/get-default-ground-stats`, params)
      .then((response: any) => {
        return response as GroundStats;
      })
      .catch((reason) => {
        showMessage(`Failed to get default ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async getCountryGroundStats(
    country: Country,
    matchType: MatchType
  ): Promise<GroundStats> {
    const params: Map<string, string> = new Map();
    params.set("country", country);
    params.set("matchType", matchType);

    return await this.httpService
      .get(`/api/${this.controllerName}/get-country-ground-stats`, params)
      .then((response: any) => {
        return response as GroundStats;
      })
      .catch((reason) => {
        showMessage(
          `Failed to get ${country} ground stats: ${reason}`,
          "error"
        );

        return null;
      });
  }

  public async getHistoricGroundStats(
    ground: Ground,
    matchType: MatchType
  ): Promise<GroundStats> {
    const params: Map<string, string> = new Map();
    params.set(
      "groundId",
      !!ground && !!ground.groundId && ground.groundId.value
    );
    params.set("matchType", matchType);

    return await this.httpService
      .get(`/api/${this.controllerName}/get-historic-ground-stats`, params)
      .then((response: any) => {
        return response as GroundStats;
      })
      .catch((reason) => {
        showMessage(`Failed to get historic ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async getAllForGround(
    groundId: UUID,
    matchId: UUID
  ): Promise<GroundStatsWrapper[]> {
    const params: Map<string, string> = new Map();
    params.set("groundId", groundId.value);
    params.set("matchId", matchId.value);

    return await this.httpService
      .get(`/api/${this.controllerName}/recent-ground-stats`, params)
      .then((response: any) => {
        return GroundStatsWrapper.deserializeList(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load recent ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async updateGroundStats(
    groundStats: GroundStatsWrapper
  ): Promise<GroundStatsWrapper> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/update-ground-stats`,
        GroundStatsWrapper.serialize(groundStats)
      )
      .catch((reason) => {
        showMessage(`Failed to save ground stats: ${reason}`, "error");

        return null;
      });
  }

  public async useGroundStats(
    groundId: UUID,
    groundStats: GroundStatsWrapper
  ): Promise<boolean> {
    const matchGroundStats: MatchGroundStats = {
      groundId: groundId,
      matchId: services.currentGameService.currentMatchId,
      groundStatsId: groundStats.groundStatsId,
      matchGroundStatsId: null,
      createdBy: UUID.fromString(services.keycloakService.getUserId()),
    };
    this.groundStatsSubject.next(groundStats);

    return await this.httpService
      .post(
        `/api/${this.controllerName}/use-ground-stats`,
        serializeMatchGroundStats(matchGroundStats)
      )
      .then((response: any) => {
        return response as boolean;
      })
      .catch((reason) => {
        showMessage(`Failed to use ground stats: ${reason}`, "error");

        return null;
      });
  }

  public webSocketStatsLoaded(message: any) {
    this.groundStatsLoaded({
      groundId: !!message.entityId ? UUID.fromString(message.entityId) : null,
      result: message.result,
      description: message.description,
    });
  }

  public historicGroundFormUpdate(message: any) {
    if (
      !!message.requestId &&
      !!this.calculateAllRequestId &&
      this.calculateAllRequestId.value === message.requestId
    ) {
      this.historicFormRequestSubject.next(
        this.deserializeHistoricFormUpdateMessage(message)
      );
    }
  }

  public webSocketStatsUpdated(message: any) {
    if (!!message.stats) {
      const newGroundStats = GroundStatsWrapper.deserializeOne(message.stats);
      if (message.causingUser === services.keycloakService.getUserId()) {
        if (
          !!newGroundStats &&
          !!newGroundStats.groundStatsId &&
          !!this.groundStats &&
          !!this.groundStats.groundStatsId &&
          newGroundStats.groundStatsId.value ===
            this.groundStats.groundStatsId.value
        ) {
          this.groundStats = newGroundStats;
          this.groundStatsSubject.next(newGroundStats);
        }
      } else {
        const oldGroundStats = this.teamGroundStats.get(message.causingUser);
        if (
          !!newGroundStats &&
          !!oldGroundStats &&
          !!newGroundStats.groundStatsId &&
          !!oldGroundStats.groundStatsId &&
          newGroundStats.groundStatsId.value ===
            oldGroundStats.groundStatsId.value
        )
          this.teamGroundStats.set(message.causingUser, newGroundStats);
        showMessage(`${message.name} updated ground stats`);
        this.broadcastComparedStats(services.keycloakService.comparedUser);
      }
    }
  }

  private groundStatsLoaded(groundStatsLoad: GroundStatsLoad) {
    this.loadedGroundStats = [
      groundStatsLoad.groundId,
      this.ground,
      groundStatsLoad,
    ];
    this.loadedGroundSubject.next(this.loadedGroundStats);
  }

  private deserializeHistoricFormUpdateMessage(
    message: any
  ): HistoricFormUpdateMessage {
    return {
      requestId: UUID.fromString(message.requestId),
      message: message.message,
      done: message.done,
      size: message.size,
    };
  }
}
