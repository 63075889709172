import { ReactNode } from "react";
import { AdminPreferences } from "../preferences/admin-preferences";
import { HistoricPlayerStats } from "../stats/historic-form";
import { MenuItem } from "@mui/material";
import { ExtrasType } from "./extras-type";

export enum PlayerFormChartType {
  EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE = "EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE",
  EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE = "EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE",
  EFFECTIVE_BATTING_CONFIDENCE_BY_DATE = "EFFECTIVE_BATTING_CONFIDENCE_BY_DATE",
  EFFECTIVE_BATTING_ORDER = "EFFECTIVE_BATTING_ORDER",

  BATTING_STRIKE_RATE_BIAS_BY_DATE = "BATTING_STRIKE_RATE_BIAS_BY_DATE",
  BATTING_WICKET_BIAS_BY_DATE = "BATTING_WICKET_BIAS_BY_DATE",
  BATTING_CONFIDENCE_BY_DATE = "BATTING_CONFIDENCE_BY_DATE",
  BATTING_ORDER_ALL_TIME = "BATTING_ORDER_ALL_TIME",

  BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING = "BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING",
  BATTING_WICKET_BIAS_BY_DATE_ROLLING = "BATTING_WICKET_BIAS_BY_DATE_ROLLING",
  BATTING_CONFIDENCE_BY_DATE_ROLLING = "BATTING_CONFIDENCE_BY_DATE_ROLLING",
  BATTING_ORDER_ROLLING = "BATTING_ORDER_ROLLING",

  EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE = "EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE = "EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE = "EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE = "EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE = "EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE = "EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE = "EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE",
  EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE = "EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE",

  BOWLING_STRIKE_RATE_BIAS_BY_DATE = "BOWLING_STRIKE_RATE_BIAS_BY_DATE",
  BOWLING_WICKET_BIAS_BY_DATE = "BOWLING_WICKET_BIAS_BY_DATE",
  BOWLING_EXTRAS_BIAS_BY_DATE = "BOWLING_EXTRAS_BIAS_BY_DATE",
  BOWLING_WIDES_BIAS_BY_DATE = "BOWLING_WIDES_BIAS_BY_DATE",
  BOWLING_NO_BALLS_BIAS_BY_DATE = "BOWLING_NO_BALLS_BIAS_BY_DATE",
  BOWLING_BYES_BIAS_BY_DATE = "BOWLING_BYES_BIAS_BY_DATE",
  BOWLING_LEG_BYES_BIAS_BY_DATE = "BOWLING_LEG_BYES_BIAS_BY_DATE",
  BOWLING_CONFIDENCE_BY_DATE = "BOWLING_CONFIDENCE_BY_DATE",

  BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING = "BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING",
  BOWLING_WICKET_BIAS_BY_DATE_ROLLING = "BOWLING_WICKET_BIAS_BY_DATE_ROLLING",
  BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING = "BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING",
  BOWLING_WIDES_BIAS_BY_DATE_ROLLING = "BOWLING_WIDES_BIAS_BY_DATE_ROLLING",
  BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING = "BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING",
  BOWLING_BYES_BIAS_BY_DATE_ROLLING = "BOWLING_BYES_BIAS_BY_DATE_ROLLING",
  BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING = "BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING",
  BOWLING_CONFIDENCE_BY_DATE_ROLLING = "BOWLING_CONFIDENCE_BY_DATE_ROLLING",
}

export const playerFormChartTypeNames: Record<PlayerFormChartType, string> = {
  EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE: "Batting SR Bias Effective",
  EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE: "Batting Wicket Bias Effective",
  EFFECTIVE_BATTING_CONFIDENCE_BY_DATE: "Batting Confidence Effective",
  BATTING_STRIKE_RATE_BIAS_BY_DATE: "Batting SR Bias All Time",
  BATTING_WICKET_BIAS_BY_DATE: "Batting Wicket Bias All Time",
  BATTING_CONFIDENCE_BY_DATE: "Batting Confidence All Time",
  BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: "Batting SR Bias Rolling",
  BATTING_WICKET_BIAS_BY_DATE_ROLLING: "Batting Wicket Bias Rolling",
  BATTING_CONFIDENCE_BY_DATE_ROLLING: "Batting Confidence Rolling",

  EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE: "Bowling SR Bias Effective",
  EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE: "Bowling Wicket Bias Effective",
  EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE: "Bowling Confidence Effective",
  EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE: "Bowling Extras Bias Effective",
  EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE: "Bowling Wides Bias Effective",
  EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE: "Bowling No Balls Bias Effective",
  EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE: "Bowling Byes Bias Effective",
  EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE: "Bowling Leg Byes Bias Effective",
  BOWLING_STRIKE_RATE_BIAS_BY_DATE: "Bowling SR Bias All Time",
  BOWLING_WICKET_BIAS_BY_DATE: "Bowling Wicket Bias All Time",
  BOWLING_CONFIDENCE_BY_DATE: "Bowling Confidence All Time",
  BOWLING_EXTRAS_BIAS_BY_DATE: "Bowling Extras Bias All Time",
  BOWLING_WIDES_BIAS_BY_DATE: "Bowling Wides Bias All Time",
  BOWLING_NO_BALLS_BIAS_BY_DATE: "Bowling No Balls Bias All Time",
  BOWLING_BYES_BIAS_BY_DATE: "Bowling Byes Bias All Time",
  BOWLING_LEG_BYES_BIAS_BY_DATE: "Bowling Leg Byes Bias All Time",
  BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: "Bowling SR Bias Rolling",
  BOWLING_WICKET_BIAS_BY_DATE_ROLLING: "Bowling Wicket Bias Rolling",
  BOWLING_CONFIDENCE_BY_DATE_ROLLING: "Bowling Confidence Rolling",
  BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING: "Bowling Extras Bias Rolling",
  BOWLING_WIDES_BIAS_BY_DATE_ROLLING: "Bowling Wides Bias Rolling",
  BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING: "Bowling No Balls Bias Rolling",
  BOWLING_BYES_BIAS_BY_DATE_ROLLING: "Bowling Byes Bias Rolling",
  BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING: "Bowling Leg Byes Bias Rolling",

  EFFECTIVE_BATTING_ORDER: "Batting Order Effective",
  BATTING_ORDER_ALL_TIME: "Batting Order All Time",
  BATTING_ORDER_ROLLING: "Batting Order Rolling",
};

const playerFormChartMenuNames: Record<PlayerFormChartType, string> = {
  EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE: "SR Bias",
  EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE: "Wicket Bias",
  EFFECTIVE_BATTING_CONFIDENCE_BY_DATE: "Confidence",
  BATTING_STRIKE_RATE_BIAS_BY_DATE: "SR Bias",
  BATTING_WICKET_BIAS_BY_DATE: "Wicket Bias",
  BATTING_CONFIDENCE_BY_DATE: "Confidence",
  BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: "SR Bias",
  BATTING_WICKET_BIAS_BY_DATE_ROLLING: "Wicket Bias",
  BATTING_CONFIDENCE_BY_DATE_ROLLING: "Confidence",

  EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE: "SR Bias",
  EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE: "Wicket Bias",
  EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE: "Confidence",
  EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE: "Extras Bias",
  EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE: "Wides Bias",
  EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE: "No Balls Bias",
  EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE: "Byes Bias",
  EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE: "Leg Byes Bias",
  BOWLING_STRIKE_RATE_BIAS_BY_DATE: "SR Bias",
  BOWLING_WICKET_BIAS_BY_DATE: "Wicket Bias",
  BOWLING_CONFIDENCE_BY_DATE: "Confidence",
  BOWLING_EXTRAS_BIAS_BY_DATE: "Extras Bias",
  BOWLING_WIDES_BIAS_BY_DATE: "Wides Bias",
  BOWLING_NO_BALLS_BIAS_BY_DATE: "No Balls Bias",
  BOWLING_BYES_BIAS_BY_DATE: "Byes Bias",
  BOWLING_LEG_BYES_BIAS_BY_DATE: "Leg Byes Bias",
  BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: "SR Bias",
  BOWLING_WICKET_BIAS_BY_DATE_ROLLING: "Wicket Bias",
  BOWLING_CONFIDENCE_BY_DATE_ROLLING: "Confidence",
  BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING: "Extras Bias",
  BOWLING_WIDES_BIAS_BY_DATE_ROLLING: "Wides Bias",
  BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING: "No Balls Bias",
  BOWLING_BYES_BIAS_BY_DATE_ROLLING: "Byes Bias",
  BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING: "Leg Byes Bias",

  EFFECTIVE_BATTING_ORDER: "Batting Order",
  BATTING_ORDER_ALL_TIME: "Batting Order",
  BATTING_ORDER_ROLLING: "Batting Order",
};

const batsmanEffectiveColour = "#f03532";
const batsmanAllTimeColour = "#f09132";
const batsmanRollingColour = "#f0dd32";
const bowlerEffectiveColour = "#4287f5";
const bowlerAllTimeColour = "#2eecf2";
const bowlerRollingColour = "#32f062";

const playerFormChartTypeColours: Record<PlayerFormChartType, string> = {
  EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE: batsmanEffectiveColour,
  EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE: batsmanEffectiveColour,
  EFFECTIVE_BATTING_CONFIDENCE_BY_DATE: batsmanEffectiveColour,
  BATTING_STRIKE_RATE_BIAS_BY_DATE: batsmanAllTimeColour,
  BATTING_WICKET_BIAS_BY_DATE: batsmanAllTimeColour,
  BATTING_CONFIDENCE_BY_DATE: batsmanAllTimeColour,
  BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: batsmanRollingColour,
  BATTING_WICKET_BIAS_BY_DATE_ROLLING: batsmanRollingColour,
  BATTING_CONFIDENCE_BY_DATE_ROLLING: batsmanRollingColour,

  EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE: bowlerEffectiveColour,
  EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE: bowlerEffectiveColour,
  BOWLING_STRIKE_RATE_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_WICKET_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_CONFIDENCE_BY_DATE: bowlerAllTimeColour,
  BOWLING_EXTRAS_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_WIDES_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_NO_BALLS_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_BYES_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_LEG_BYES_BIAS_BY_DATE: bowlerAllTimeColour,
  BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_WICKET_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_CONFIDENCE_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_WIDES_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_BYES_BIAS_BY_DATE_ROLLING: bowlerRollingColour,
  BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING: bowlerRollingColour,

  EFFECTIVE_BATTING_ORDER: batsmanEffectiveColour,
  BATTING_ORDER_ALL_TIME: batsmanAllTimeColour,
  BATTING_ORDER_ROLLING: batsmanRollingColour,
};

const menuItemForOption = (option: PlayerFormChartType): ReactNode => {
  return (
    <MenuItem value={option.toString()} key={option.toString()}>
      <div>{playerFormChartMenuNames[option]}</div>
    </MenuItem>
  );
};

const buildSubheading = (text: string, type: PlayerFormChartType) => {
  return (
    <div className="player-form-chart-type-renderer" key={`sub-heading-{text}`}>
      <div
        className="player-form-chart-type-colour"
        style={{ backgroundColor: playerFormChartTypeColours[type] }}
      >
        &nbsp;
      </div>
      <div
        style={{
          paddingLeft: "1em",
          width: "100%",
          backgroundColor: playerFormChartTypeColours[type] + "55",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export const playerFormChartTypeMenuItems: ReactNode[] = [];
playerFormChartTypeMenuItems.push(
  <div className="player-form-chart-type-menu-header" key="batting-heading">
    Batting
  </div>
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "Effective",
    PlayerFormChartType.EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(
    PlayerFormChartType.EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BATTING_CONFIDENCE_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BATTING_ORDER)
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "All Time",
    PlayerFormChartType.BATTING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_STRIKE_RATE_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_WICKET_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_CONFIDENCE_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_ORDER_ALL_TIME)
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "Rolling",
    PlayerFormChartType.BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(
    PlayerFormChartType.BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_WICKET_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_CONFIDENCE_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_ORDER_ROLLING)
);
playerFormChartTypeMenuItems.push(
  <div className="player-form-chart-type-menu-header" key="bowling-heading">
    Bowling
  </div>
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "Effective",
    PlayerFormChartType.EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(
    PlayerFormChartType.EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "All Time",
    PlayerFormChartType.BOWLING_STRIKE_RATE_BIAS_BY_DATE
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_STRIKE_RATE_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_WICKET_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_EXTRAS_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_WIDES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_NO_BALLS_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_BYES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_LEG_BYES_BIAS_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BATTING_CONFIDENCE_BY_DATE)
);
playerFormChartTypeMenuItems.push(
  buildSubheading(
    "Rolling",
    PlayerFormChartType.BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(
    PlayerFormChartType.BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING
  )
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_WICKET_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_WIDES_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_BYES_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING)
);
playerFormChartTypeMenuItems.push(
  menuItemForOption(PlayerFormChartType.BOWLING_CONFIDENCE_BY_DATE_ROLLING)
);

export const playerFormChartTypeStatsExtractors: Record<
  PlayerFormChartType,
  (HistoricPlayerStats, AdminPreferences, number) => number
> = {
  EFFECTIVE_BATTING_STRIKE_RATE_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    calculateEffectiveValue(
      adminPreferences,
      date,
      stats.battingStrikeRateBiasByDate.get(date),
      stats.battingStrikeRateBiasByDateRolling.get(date),
      stats.battingConfidenceByDate.get(date),
      stats.battingConfidenceByDateRolling.get(date)
    ),
  EFFECTIVE_BATTING_WICKET_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    calculateEffectiveValue(
      adminPreferences,
      date,
      stats.battingWicketBiasByDate.get(date),
      stats.battingWicketBiasByDateRolling.get(date),
      stats.battingConfidenceByDate.get(date),
      stats.battingConfidenceByDateRolling.get(date)
    ),
  EFFECTIVE_BATTING_CONFIDENCE_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const amountAllTime = adminPreferences.effectivePercentForAllTimeStats;
    const amountRolling = 1 - amountAllTime;
    return (
      amountAllTime * stats.battingConfidenceByDate.get(date) +
      amountRolling * stats.battingConfidenceByDateRolling.get(date)
    );
  },
  BATTING_STRIKE_RATE_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingStrikeRateBiasByDate.get(date),
  BATTING_WICKET_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingWicketBiasByDate.get(date),
  BATTING_CONFIDENCE_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingConfidenceByDate.get(date),
  BATTING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingStrikeRateBiasByDateRolling.get(date),
  BATTING_WICKET_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingWicketBiasByDateRolling.get(date),
  BATTING_CONFIDENCE_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.battingConfidenceByDateRolling.get(date),

  EFFECTIVE_BOWLING_STRIKE_RATE_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    calculateEffectiveValue(
      adminPreferences,
      date,
      stats.bowlingStrikeRateBiasByDate.get(date),
      stats.bowlingStrikeRateBiasByDateRolling.get(date),
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    ),
  EFFECTIVE_BOWLING_WICKET_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    calculateEffectiveValue(
      adminPreferences,
      date,
      stats.bowlingWicketBiasByDate.get(date),
      stats.bowlingWicketBiasByDateRolling.get(date),
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    ),
  EFFECTIVE_BOWLING_EXTRAS_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    calculateEffectiveValue(
      adminPreferences,
      date,
      stats.bowlingExtrasBiasByDate.get(date),
      stats.bowlingExtrasBiasByDateRolling.get(date),
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    ),
  EFFECTIVE_BOWLING_WIDES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const byDate = stats.bowlingExtrasBiasesByTypeByDate.get(date);
    const rolling = stats.bowlingExtrasBiasesByTypeByDateRolling.get(date);
    return calculateEffectiveValue(
      adminPreferences,
      date,
      byDate ? byDate.get(ExtrasType.WIDE) : undefined,
      rolling ? rolling.get(ExtrasType.WIDE) : undefined,
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    );
  },
  EFFECTIVE_BOWLING_NO_BALLS_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const byDate = stats.bowlingExtrasBiasesByTypeByDate.get(date);
    const rolling = stats.bowlingExtrasBiasesByTypeByDateRolling.get(date);
    return calculateEffectiveValue(
      adminPreferences,
      date,
      byDate ? byDate.get(ExtrasType.NO_BALL) : undefined,
      rolling ? rolling.get(ExtrasType.NO_BALL) : undefined,
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    );
  },
  EFFECTIVE_BOWLING_BYES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const byDate = stats.bowlingExtrasBiasesByTypeByDate.get(date);
    const rolling = stats.bowlingExtrasBiasesByTypeByDateRolling.get(date);
    return calculateEffectiveValue(
      adminPreferences,
      date,
      byDate ? byDate.get(ExtrasType.BYE) : undefined,
      rolling ? rolling.get(ExtrasType.BYE) : undefined,
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    );
  },
  EFFECTIVE_BOWLING_LEG_BYES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const byDate = stats.bowlingExtrasBiasesByTypeByDate.get(date);
    const rolling = stats.bowlingExtrasBiasesByTypeByDateRolling.get(date);
    return calculateEffectiveValue(
      adminPreferences,
      date,
      byDate ? byDate.get(ExtrasType.LEG_BYE) : undefined,
      rolling ? rolling.get(ExtrasType.LEG_BYE) : undefined,
      stats.bowlingConfidenceByDate.get(date),
      stats.bowlingConfidenceByDateRolling.get(date)
    );
  },
  EFFECTIVE_BOWLING_CONFIDENCE_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const amountAllTime = adminPreferences.effectivePercentForAllTimeStats;
    const amountRolling = 1 - amountAllTime;
    return (
      amountAllTime * stats.bowlingConfidenceByDate.get(date) +
      amountRolling * stats.bowlingConfidenceByDateRolling.get(date)
    );
  },
  BOWLING_STRIKE_RATE_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingStrikeRateBiasByDate.get(date),
  BOWLING_WICKET_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingWicketBiasByDate.get(date),
  BOWLING_EXTRAS_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingExtrasBiasByDate.get(date),
  BOWLING_WIDES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDate.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDate.get(date).get(ExtrasType.WIDE)
      : undefined,
  BOWLING_NO_BALLS_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDate.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDate.get(date).get(ExtrasType.NO_BALL)
      : undefined,
  BOWLING_BYES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDate.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDate.get(date).get(ExtrasType.BYE)
      : undefined,
  BOWLING_LEG_BYES_BIAS_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDate.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDate.get(date).get(ExtrasType.LEG_BYE)
      : undefined,
  BOWLING_CONFIDENCE_BY_DATE: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingConfidenceByDate.get(date),
  BOWLING_STRIKE_RATE_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingStrikeRateBiasByDateRolling.get(date),
  BOWLING_WICKET_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingWicketBiasByDateRolling.get(date),
  BOWLING_EXTRAS_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingExtrasBiasByDateRolling.get(date),
  BOWLING_WIDES_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDateRolling.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDateRolling
          .get(date)
          .get(ExtrasType.WIDE)
      : undefined,
  BOWLING_NO_BALLS_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDateRolling.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDateRolling
          .get(date)
          .get(ExtrasType.NO_BALL)
      : undefined,
  BOWLING_BYES_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDateRolling.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDateRolling
          .get(date)
          .get(ExtrasType.BYE)
      : undefined,
  BOWLING_LEG_BYES_BIAS_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) =>
    stats.bowlingExtrasBiasesByTypeByDateRolling.get(date)
      ? stats.bowlingExtrasBiasesByTypeByDateRolling
          .get(date)
          .get(ExtrasType.LEG_BYE)
      : undefined,
  BOWLING_CONFIDENCE_BY_DATE_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.bowlingConfidenceByDateRolling.get(date),

  EFFECTIVE_BATTING_ORDER: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => {
    const amountAllTime = adminPreferences.effectivePercentForAllTimeStats;
    const amountRolling = 1 - amountAllTime;
    return (
      amountAllTime * stats.orderByDate.get(date) +
      amountRolling * stats.orderByDateRolling.get(date) +
      1
    );
  },
  BATTING_ORDER_ALL_TIME: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.orderByDate.get(date) + 1,
  BATTING_ORDER_ROLLING: (
    stats: HistoricPlayerStats,
    adminPreferences: AdminPreferences,
    date: number
  ) => stats.orderByDateRolling.get(date) + 1,
};

function calculateEffectiveValue(
  adminPreferences: AdminPreferences,
  date: number,
  allTimeByDate: number,
  rollingByDate: number,
  allTimeConfidence: number,
  rollingConfidence: number
): number {
  const amountAllTime = adminPreferences.effectivePercentForAllTimeStats;
  const amountRolling = 1 - amountAllTime;
  const bias = amountRolling * rollingByDate + amountAllTime * allTimeByDate;
  const confidence =
    amountRolling * rollingConfidence + amountAllTime * allTimeConfidence;
  return confidence * bias + (1 - confidence);
}
