import { services, showMessage } from "../types/services";
import { Subject, ReplaySubject } from "rxjs";
import {
  deserializeInvestorPotStatus,
  deserializePotSummary,
  InvestorPotStatus,
  PotSummary,
} from "../types/investment/pot-summary";
import { HttpService } from "./http-service";
import {
  deserializeTransactions,
  Transaction,
} from "../types/investment/transaction";

export class InvestmentService {
  public potSummarySubject: Subject<PotSummary> = new ReplaySubject(1);
  public potSummary: PotSummary;

  public allTransactionsSubject: Subject<Transaction[]> = new ReplaySubject(1);
  public allTransactions: Transaction[];

  public myPotStatusSubject: Subject<InvestorPotStatus> = new ReplaySubject(1);
  public myPotStatus: InvestorPotStatus;

  private httpService: HttpService;
  private controllerName: string;

  constructor(httpService: HttpService, controllerName: string) {
    this.httpService = httpService;
    this.controllerName = controllerName;
  }

  public initiatePots() {
    if (services.keycloakService.isInvestorAdmin()) {
      this.loadPotSummary();
      this.loadMyPotStatus();
      this.loadAllTransactions();
    } else if (services.keycloakService.isInvestor()) {
      this.loadMyPotStatus();
    }
  }

  private loadMyPotStatus() {
    this.getMyPotStatus().then((myPotStatus: InvestorPotStatus) => {
      this.myPotStatus = myPotStatus;
      this.myPotStatusSubject.next(myPotStatus);
    });
  }

  private loadAllTransactions() {
    this.getAllTransactions().then((transactions: Transaction[]) => {
      this.allTransactions = transactions;
      this.allTransactionsSubject.next(transactions);
    });
  }

  private loadPotSummary() {
    this.getPotSummary().then((potSummary: PotSummary) => {
      this.potSummary = potSummary;
      this.potSummarySubject.next(potSummary);
    });
  }

  public async endOfPeriod(
    deposits: Transaction[],
    withdrawals: Transaction[]
  ): Promise<boolean> {
    const endOfPeriodData = {
      clearedDeposits: deposits.map((t) => t.transactionId.value),
      clearedWithdrawals: withdrawals.map((t) => t.transactionId.value),
    };
    return await this.httpService
      .post(`/api/${this.controllerName}/admin/end-of-period`, endOfPeriodData)
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to end trading period: ${reason}`, "error");
        return null;
      });
  }

  public async profitAndLossUpdate(
    amount: number,
    volume: number
  ): Promise<boolean> {
    const profitUpdate = {
      profit: amount,
      tradingVolume: volume,
    };
    return await this.httpService
      .post(`/api/${this.controllerName}/admin/profit-update`, profitUpdate)
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send profit update: ${reason}`, "error");
        return null;
      });
  }

  public async depositRequest(amount: number, date: number): Promise<boolean> {
    const depositRequest = {
      amount: amount,
      desiredDate: date,
    };
    return await this.httpService
      .post(`/api/${this.controllerName}/deposit-request`, depositRequest)
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send deposit request: ${reason}`, "error");
        return null;
      });
  }

  public async approveDeposit(transaction: Transaction): Promise<boolean> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/admin/deposit-approval`,
        transaction.transactionId.value
      )
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send deposit approval: ${reason}`, "error");
        return null;
      });
  }

  public async rejectDeposit(transaction: Transaction): Promise<boolean> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/admin/deposit-rejection`,
        transaction.transactionId.value
      )
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send deposit rejection: ${reason}`, "error");
        return null;
      });
  }

  public async withdrawalRequest(
    amount: number,
    date: number
  ): Promise<boolean> {
    const withdrawalRequest = {
      amount: amount,
      desiredDate: date,
    };
    return await this.httpService
      .post(`/api/${this.controllerName}/withdrawal-request`, withdrawalRequest)
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send withdrawal request: ${reason}`, "error");
        return null;
      });
  }

  public async approveWithdrawal(transaction: Transaction): Promise<boolean> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/admin/withdrawal-approval`,
        transaction.transactionId.value
      )
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send withdrawal approval: ${reason}`, "error");
        return null;
      });
  }

  public async rejectWithdrawal(transaction: Transaction): Promise<boolean> {
    return await this.httpService
      .post(
        `/api/${this.controllerName}/admin/withdrawal-rejection`,
        transaction.transactionId.value
      )
      .then((response: any) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to send withdrawal rejection: ${reason}`, "error");
        return null;
      });
  }

  private async getPotSummary(): Promise<PotSummary> {
    return await this.httpService
      .get(`/api/${this.controllerName}/admin/get-pot-summary`)
      .then((response: any) => {
        return deserializePotSummary(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load pot summary: ${reason}`, "error");
        return null;
      });
  }

  public async getPotSummaryPreview(
    deposits: Transaction[],
    withdrawals: Transaction[]
  ): Promise<PotSummary> {
    const endOfPeriodData = {
      clearedDeposits: deposits.map((t) => t.transactionId.value),
      clearedWithdrawals: withdrawals.map((t) => t.transactionId.value),
    };
    return await this.httpService
      .post(
        `/api/${this.controllerName}/admin/get-pot-summary-preview`,
        endOfPeriodData
      )
      .then((response: any) => {
        return deserializePotSummary(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load pot summary: ${reason}`, "error");
        return null;
      });
  }

  private async getMyPotStatus(): Promise<InvestorPotStatus> {
    return await this.httpService
      .get(`/api/${this.controllerName}/my-pot-status`)
      .then((response: any) => {
        return deserializeInvestorPotStatus(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load my pot status: ${reason}`, "error");
        return null;
      });
  }

  private async getAllTransactions(): Promise<Transaction[]> {
    return await this.httpService
      .get(`/api/${this.controllerName}/admin/transaction-history`)
      .then((response: any) => {
        return deserializeTransactions(response);
      })
      .catch((reason) => {
        showMessage(`Failed to load transaction history: ${reason}`, "error");
        return null;
      });
  }
}
