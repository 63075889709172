import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { KeycloakUser } from "../../services/keycloak-service";
import {
  displayTransactionData,
  TransactionType,
  transactionTypeNames,
} from "../../types/enums/transaction-type";
import { Transaction } from "../../types/investment/transaction";
import { services } from "../../types/services";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  investors: KeycloakUser[];
  allTransactions: Transaction[];
  displayedTransactions: Transaction[];
  canAcceptAndReject: boolean;
  canClear: boolean;
  canDelete: boolean;
  title: string;
  allowToggleTransactionId: boolean;
  showInvestorName: boolean;
  showProfitVolume: boolean;
  onClear: (transaction: Transaction) => void;
  onDelete: (transaction: Transaction) => void;
}

interface State {
  showTransactionId: boolean;
}

export class TransactionHistoryList extends Component<Props, State> {
  static defaultProps = {
    title: "Transaction History",
    canClear: false,
    onClear: () => {},
    canDelete: false,
    onDelete: () => {},
    canAcceptAndReject: false,
    allowToggleTransactionId: true,
    showInvestorName: true,
    showProfitVolume: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTransactionId: false,
    };
  }

  private getUserName(transaction: Transaction) {
    const unknown = <div className="italic">Unknown</div>;
    if (!!this.props.investors && !!transaction.userId) {
      const investor = this.props.investors.find(
        (user) => user.id === transaction.userId.value
      );
      return !!investor ? investor.name : unknown;
    } else {
      return unknown;
    }
  }

  private getDate(transaction: Transaction) {
    return new Date(transaction.createdAt).toLocaleString();
  }

  private getActions(transaction: Transaction) {
    if (
      transaction.transactionType === TransactionType.DEPOSIT_REQUEST ||
      transaction.transactionType === TransactionType.WITHDRAWAL_REQUEST
    ) {
      return this.getRequestActions(transaction);
    }
  }

  private getRequestActions(transaction: Transaction) {
    const [matchingApproval, matchingRejection, matchingClearance] =
      this.findMatchingTransactions(transaction);
    if (!!matchingClearance) {
      return (
        <div className="italic">
          Cleared on{" "}
          {new Date(matchingClearance.createdAt).toLocaleDateString()}
        </div>
      );
    } else if (!!matchingApproval) {
      return this.buildAddOrRemoveButtons(transaction, matchingApproval);
    } else if (!!matchingRejection) {
      return (
        <div className="italic">
          Rejected on{" "}
          {new Date(matchingRejection.createdAt).toLocaleDateString()}
        </div>
      );
    } else if (this.props.canAcceptAndReject) {
      return this.buildAcceptAndRejectButtons(transaction);
    }
  }

  private findMatchingTransactions(
    transaction: Transaction
  ): [Transaction, Transaction, Transaction] {
    let matchingApproval;
    let matchingRejection;
    let matchingClearance;
    for (let t of this.props.allTransactions) {
      switch (t.transactionType) {
        case TransactionType.DEPOSIT_APPROVAL:
        case TransactionType.WITHDRAWAL_APPROVAL:
          if (
            !!t.transactionData &&
            t.transactionData.requestTransactionId ===
              transaction.transactionId.value
          ) {
            matchingApproval = t;
          }
          break;
        case TransactionType.DEPOSIT_REJECTION:
        case TransactionType.WITHDRAWAL_REJECTION:
          if (
            !!t.transactionData &&
            t.transactionData.requestTransactionId ===
              transaction.transactionId.value
          ) {
            matchingRejection = t;
          }
          break;
        case TransactionType.END_OF_PERIOD:
          if (
            !!t.transactionData &&
            (t.transactionData.clearedDeposits.includes(
              transaction.transactionId.value
            ) ||
              t.transactionData.clearedWithdrawals.includes(
                transaction.transactionId.value
              ))
          ) {
            matchingClearance = t;
          }
          break;
        default:
          break;
      }
    }
    return [matchingApproval, matchingRejection, matchingClearance];
  }

  private buildAddOrRemoveButtons(
    transaction: Transaction,
    matchingApproval: Transaction
  ) {
    if (this.props.canClear) {
      return (
        <div className="approve-reject-buttons">
          <Button
            onClick={() => this.props.onClear(transaction)}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </div>
      );
    } else if (this.props.canDelete) {
      return (
        <div className="approve-reject-buttons">
          <Button
            onClick={() => this.props.onDelete(transaction)}
            variant="outlined"
            color="secondary"
          >
            Remove
          </Button>
        </div>
      );
    } else {
      return (
        <div className="italic">
          Approved on{" "}
          {new Date(matchingApproval.createdAt).toLocaleDateString()}
        </div>
      );
    }
  }

  private buildAcceptAndRejectButtons(transaction: Transaction) {
    return (
      <div className="approve-reject-buttons">
        <Button
          onClick={() => {
            if (
              transaction.transactionType === TransactionType.DEPOSIT_REQUEST
            ) {
              services.investmentService.approveDeposit(transaction);
            } else if (
              transaction.transactionType === TransactionType.WITHDRAWAL_REQUEST
            ) {
              services.investmentService.approveWithdrawal(transaction);
            }
          }}
          variant="contained"
          color="primary"
        >
          Approve
        </Button>
        <Button
          onClick={() => {
            if (
              transaction.transactionType === TransactionType.DEPOSIT_REQUEST
            ) {
              services.investmentService.rejectDeposit(transaction);
            } else if (
              transaction.transactionType === TransactionType.WITHDRAWAL_REQUEST
            ) {
              services.investmentService.rejectWithdrawal(transaction);
            }
          }}
          variant="outlined"
          color="secondary"
        >
          Reject
        </Button>
      </div>
    );
  }

  private buildTableRow(transaction: Transaction) {
    return (
      <TableRow
        key={`transaction-history-table-row-${transaction.transactionId.value}`}
        style={{ fontSize: "small" }}
      >
        {this.state.showTransactionId && (
          <TableCell
            scope="row"
            style={{ fontSize: "small" }}
            id={`transaction-history-table-cell-${transaction.transactionId.value}-id`}
          >
            {transaction.transactionId.value}
          </TableCell>
        )}
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`transaction-history-table-cell-${transaction.transactionId.value}-date`}
        >
          {this.getDate(transaction)}
        </TableCell>
        {this.props.showInvestorName && (
          <TableCell
            scope="row"
            style={{ fontSize: "small" }}
            id={`transaction-history-table-cell-${transaction.transactionId.value}-user`}
          >
            {this.getUserName(transaction)}
          </TableCell>
        )}
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`transaction-history-table-cell-${transaction.transactionId.value}-type`}
        >
          {transactionTypeNames[transaction.transactionType]}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`transaction-history-table-cell-${transaction.transactionId.value}-data`}
        >
          {displayTransactionData(transaction, this.props.showProfitVolume)}
        </TableCell>
        <TableCell
          scope="row"
          style={{ fontSize: "small" }}
          id={`transaction-history-table-cell-${transaction.transactionId.value}-actions`}
        >
          {this.getActions(transaction)}
        </TableCell>
      </TableRow>
    );
  }

  private buildTableHeader(headerName: string, index: number) {
    return (
      <TableCell
        align="left"
        id={`transaction-history-list-table-header-${index}`}
        key={`transaction-history-list-table-header-${index}`}
        style={{ backgroundColor: "#dddddd" }}
      >
        {headerName}
      </TableCell>
    );
  }

  public render() {
    const headers: ReactNode[] = ["Date/Time", "Type", "Data", "Actions"].map(
      (headerName: string, index: number) =>
        this.buildTableHeader(headerName, index + 10)
    );
    this.state.showTransactionId &&
      headers.unshift(this.buildTableHeader("Transaction ID", 0));
    this.props.showInvestorName &&
      headers.splice(
        this.state.showTransactionId ? 2 : 1,
        0,
        this.buildTableHeader("User", 1)
      );

    const sorted = this.props.displayedTransactions
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((transaction: Transaction) => this.buildTableRow(transaction));

    return (
      <div className="investor-list-container">
        <div className="investor-list-title">
          <div className="investor-page-title">{this.props.title}</div>
          {this.props.allowToggleTransactionId && (
            <TooltipIconButton
              title={"Toggle Show Transaction IDs"}
              onClick={() =>
                this.setState({
                  showTransactionId: !this.state.showTransactionId,
                })
              }
              icon={"data_object"}
              colour="dddddd"
            />
          )}
        </div>
        <div>
          <TableContainer>
            <Table size="small" aria-label="transaction history list table">
              <TableHead>
                <TableRow>{headers}</TableRow>
              </TableHead>
              <TableBody>{sorted}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}
