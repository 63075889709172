import { Component } from "react";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import { MatchTypeSkew } from "../../services/historic-push-service";
import { SimulatorOversDistributionDisplay } from "../simulator-page/simulator-overs-distribution-display";
import { Chart } from "react-chartjs-2";
import { MenuItem, Select, Slider } from "@mui/material";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { services } from "../../types/services";
import {
  infinitySafeWithDefault,
  nanSafeWithDefault,
} from "../../types/util-functions";

interface Props {
  matchTypeSkews: Map<MatchType, Map<MatchType, MatchTypeSkew>>;
  adminPreferences: AdminPreferences;
}

interface State {
  currentSource: MatchType;
  currentDestination: MatchType;
  currentStat: StrikeRateOrWicketPercent;
}

enum StrikeRateOrWicketPercent {
  STRIKE_RATE = "STRIKE_RATE",
  WICKET_PERCENT = "WICKET_PERCENT",
}

const strikeRateOrWicketPercentNames: Record<
  StrikeRateOrWicketPercent,
  string
> = {
  STRIKE_RATE: "Strike Rate",
  WICKET_PERCENT: "Wicket Percent",
};

export class MatchTypeSkewsDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentSource: MatchType.T20_AND_HUNDRED,
      currentDestination: MatchType.ODI,
      currentStat: StrikeRateOrWicketPercent.STRIKE_RATE,
    };
  }

  private buildLabels(): number[] {
    return [
      0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
      0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1,
    ];
  }

  private updateAdminPreferences(property: string, value: number): void {
    const adminPreferences = {
      ...this.props.adminPreferences,
      [property]: value,
    };
    services.userService.updateAdminPreferences(adminPreferences);
  }

  private getWeights(): number[] {
    if (this.props.adminPreferences.matchTypeSkewSmoothness === 100) {
      return [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      ];
    } else {
      const index = Math.floor(
        (this.props.adminPreferences.matchTypeSkewSmoothness * 20) / 100
      );
      const window = [];
      for (let i = 0; i < 41; i++) {
        const value = index + (window.length >= 20 ? 21 - i : -20 + i);
        window.push(Math.max(0, value));
      }
      return window;
    }
  }

  private smooth(weights, confidences, values, confidenceLimit): number[] {
    const smoothedData: number[] = [];
    const windowRadius = (weights.length - 1) / 2;
    for (let i = 0; i < values.length; i++) {
      let totalWeight: number = 0;
      let totalValue: number = 0;
      for (let j = -windowRadius; j < windowRadius + 1; j++) {
        const weightIndex: number = j + windowRadius;
        if (i + j >= 0 && i + j < values.length) {
          const confidence: number = confidences[i + j];
          const weight: number =
            weights[weightIndex] * Math.min(1, confidence / confidenceLimit);
          totalWeight += weight;
          totalValue += weight * values[i + j];
        }
      }
      smoothedData.push(
        infinitySafeWithDefault(
          nanSafeWithDefault(totalValue / totalWeight, 1),
          1
        )
      );
    }
    return smoothedData;
  }

  public render() {
    const datasets = [];
    const allLabels: number[] = this.buildLabels();
    if (!!this.props.matchTypeSkews && !!this.props.adminPreferences) {
      const sourceSkew: MatchTypeSkew = this.props.matchTypeSkews
        .get(this.state.currentSource)
        .get(this.state.currentSource);
      const destinationSkew: MatchTypeSkew = this.props.matchTypeSkews
        .get(this.state.currentSource)
        .get(this.state.currentDestination);
      if (!!sourceSkew && !!destinationSkew) {
        const weights: number[] = this.getWeights();
        const confidenceLimit: number =
          this.props.adminPreferences.matchTypeSkewConfidenceLimit;
        const values: number[] = [];
        const confidences: number[] = [];
        const skewsByPushSrc = sourceSkew.skewByPushMap;
        const skewsByPushDest = destinationSkew.skewByPushMap;

        for (let push of allLabels) {
          const confidenceMultiplier = Math.min(
            1,
            skewsByPushDest.get(push).confidence / confidenceLimit
          );
          const valueSrc =
            this.state.currentStat === StrikeRateOrWicketPercent.STRIKE_RATE
              ? skewsByPushSrc.get(push).averageActualSr /
                skewsByPushSrc.get(push).averageExpectedSr
              : skewsByPushSrc.get(push).averageActualWpc /
                skewsByPushSrc.get(push).averageExpectedWpc;
          const valueDest =
            this.state.currentStat === StrikeRateOrWicketPercent.STRIKE_RATE
              ? skewsByPushDest.get(push).averageActualSr /
                skewsByPushDest.get(push).averageExpectedSr
              : skewsByPushDest.get(push).averageActualWpc /
                skewsByPushDest.get(push).averageExpectedWpc;
          confidences.push(skewsByPushDest.get(push).confidence);
          values.push(
            infinitySafeWithDefault(
              nanSafeWithDefault(
                confidenceMultiplier * (valueSrc / valueDest) +
                  (1 - confidenceMultiplier),
                1
              ),
              1
            )
          );
        }

        const smoothedData: number[] = this.smooth(
          weights,
          confidences,
          values,
          confidenceLimit
        );

        datasets.push({
          type: "line" as const,
          label: matchTypeNames[this.state.currentDestination],
          fill: false,
          labels: allLabels,
          lineTension: 0.3,
          data: smoothedData,
          borderColor:
            SimulatorOversDistributionDisplay.playerColours[
              datasets.length % 11
            ],
        });

        datasets.push({
          type: "bar" as const,
          label: matchTypeNames[this.state.currentDestination],
          fill: true,
          labels: allLabels,
          data: smoothedData,
          borderColor:
            SimulatorOversDistributionDisplay.playerColours[
              datasets.length % 11
            ],
          backgroundColor:
            SimulatorOversDistributionDisplay.playerColours[
              datasets.length % 11
            ] + "30",
        });
      }
    }

    return (
      <div className="match-type-skew-chart-and-selector">
        {!!this.props.adminPreferences && !!this.props.matchTypeSkews && (
          <div className="match-type-skew-selectors">
            <div className="match-type-skew-selector">
              Source Match Type:
              <Select
                value={this.state.currentSource}
                renderValue={(val: MatchType) => matchTypeNames[val]}
                className="enum-multi-select"
                onChange={(selection) =>
                  this.setState({
                    currentSource: selection.target.value as MatchType,
                  })
                }
                disabled={false}
                variant="standard"
              >
                {Object.keys(MatchType).map(
                  (matchTypeValue: string, index: number) => (
                    <MenuItem
                      key={`strike-rate-or-wicket-percent-${index}`}
                      value={MatchType[matchTypeValue]}
                    >
                      {matchTypeNames[matchTypeValue]}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            <div className="match-type-skew-selector">
              Destination Match Type:
              <Select
                value={this.state.currentDestination}
                renderValue={(val: MatchType) => matchTypeNames[val]}
                className="enum-multi-select"
                onChange={(selection) =>
                  this.setState({
                    currentDestination: selection.target.value as MatchType,
                  })
                }
                disabled={false}
                variant="standard"
              >
                {Object.keys(MatchType).map(
                  (matchTypeValue: string, index: number) => (
                    <MenuItem
                      key={`strike-rate-or-wicket-percent-${index}`}
                      value={MatchType[matchTypeValue]}
                    >
                      {matchTypeNames[matchTypeValue]}
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            <div className="match-type-skew-selector">
              Statistic:
              <Select
                value={this.state.currentStat}
                renderValue={(val: StrikeRateOrWicketPercent) =>
                  strikeRateOrWicketPercentNames[val]
                }
                className="enum-multi-select"
                onChange={(selection) =>
                  this.setState({
                    currentStat: selection.target
                      .value as StrikeRateOrWicketPercent,
                  })
                }
                disabled={false}
                variant="standard"
              >
                {Object.keys(StrikeRateOrWicketPercent).map(
                  (strikeRateOrWicketPercentValue: string, index: number) => (
                    <MenuItem
                      key={`strike-rate-or-wicket-percent-${index}`}
                      value={
                        StrikeRateOrWicketPercent[
                          strikeRateOrWicketPercentValue
                        ]
                      }
                    >
                      {
                        strikeRateOrWicketPercentNames[
                          strikeRateOrWicketPercentValue
                        ]
                      }
                    </MenuItem>
                  )
                )}
              </Select>
            </div>
            <div className="match-type-skew-selector">
              Smoothing:
              <Slider
                aria-label="Match Type Skew Smoothness"
                value={this.props.adminPreferences.matchTypeSkewSmoothness}
                onChange={(event, smoothness: number) =>
                  this.updateAdminPreferences(
                    "matchTypeSkewSmoothness",
                    smoothness
                  )
                }
              />
            </div>
          </div>
        )}

        <div className="match-type-skew-chart">
          <Chart
            type="line"
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={{
              labels: allLabels,
              datasets,
            }}
          />
        </div>
      </div>
    );
  }
}
