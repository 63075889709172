import { Button, Icon, Tooltip } from "@mui/material";
import { Component, ReactNode } from "react";

import { Player } from "../../../types/entities/player";
import { RecentSquad } from "../../../types/entities/recent-squad";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { services, showMessage } from "../../../types/services";
import { dateToAge } from "../../../types/util-functions";
import { getLogo, observableFrom, propsEqual } from "../../component-utils";
import { Option } from "../../entity-management/entity-selectors/entity-auto-selector";
import { PlayerSelector } from "../../entity-management/entity-selectors/player-selector";

import { CreationDialog } from "./creation-dialog";
import SquadView from "./squad-view";

interface Props {
  value: Squad;
  team: number;
  label: string;
  open: boolean;
  onProceed: (number, Squad) => void;
  onCancel: Function;
  onPlayersAdded: (players: Player[], squad: Squad, team: number) => void;
  onPlayersRemoved: (players: Player[], squad: Squad, team: number) => void;
  onPlayerUpdated: (player: Player, squad: Squad, team: number) => void;
  eligiblePlayers: Player[];
}

interface State {
  value: Squad;
  recentSquads: RecentSquad[];
  recentSquadPage: number;
  colour: string;
}

export class CreateSquadModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      recentSquads: [],
      recentSquadPage: 0,
      colour: null,
    };
  }

  componentDidMount(): void {
    this.setState({ value: this.props.value });
    this.updateColour();
    this.updateRecentSquads();
  }

  componentDidUpdate(prevProps) {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({ value: this.props.value });
      this.updateColour();
      this.updateRecentSquads();
    }
  }

  private updateRecentSquads() {
    if (!!this.props.value) {
      services.teamPlayerService
        .getRecentSquads(this.state.recentSquadPage, this.props.value.teamId)
        .then((recentSquads: RecentSquad[]) => this.setState({ recentSquads }))
        .catch((reason) => {
          showMessage(
            `Could not get page ${this.state.recentSquadPage} of recent squads: ${reason}`,
            "error"
          );
        });
    }
  }

  private addPlayer(player: Player) {
    if (player.headshot !== null) {
      services.imageService
        .getOne(player.headshot)
        .then((image) => {
          services.imageService.addImageToCache(player.headshot.value, image);
        })
        .catch((reason) => {
          showMessage(`Could not get image: ${reason}`, "error");
        });
    }
    const updatedSquad = {
      ...this.state.value,
      players: [...this.state.value.players, player],
    };
    this.setState({
      value: updatedSquad,
    });
    this.props.onPlayersAdded([player], updatedSquad, this.props.team);
  }

  private removePlayer(player: Player) {
    const updatedSquad = {
      ...this.state.value,
      players: this.state.value.players.filter(
        (p) => p.playerId.value !== player.playerId.value
      ),
    };
    this.props.onPlayersRemoved([player], updatedSquad, this.props.team);
    this.setState({
      value: updatedSquad,
    });
  }

  private updatePlayer(player: Player) {
    const updatedSquad = {
      ...this.state.value,
      players: [
        ...this.state.value.players.filter(
          (p) => p.playerId.value !== player.playerId.value
        ),
        player,
      ],
    };
    this.setState({
      value: updatedSquad,
    });
    this.props.onPlayerUpdated(player, updatedSquad, this.props.team);
  }

  updateColour() {
    if (this.props.value !== null) {
      services.teamService
        .getOne(this.props.value.teamId)
        .then((team: Team) => {
          this.setState({ colour: team.colour });
        })
        .catch((reason) => {
          showMessage(`Could not get team info: ${reason}`, "error");
        });
    }
  }

  private playerRenderer(props, option: Option<Player>): ReactNode {
    const player = option.object as Player;
    const logo = getLogo(player);

    let im: ReactNode = services.teamService.findFlagForNationalTeam(
      player.nationalTeam
    );
    if (!option.new) {
      return (
        <div {...props} className="player-select-render">
          <div>
            {logo}
            {player.toString()}
          </div>
          <div className="player-select-side-text italic">
            {dateToAge(player.dob)}y<div className="small-image">{im}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div {...props}>
          {logo}Add player: {player.toString()}
        </div>
      );
    }
  }

  private clearSquad() {
    const updatedSquad = {
      ...this.state.value,
      players: [],
    };
    this.props.onPlayersRemoved(
      this.state.value.players,
      updatedSquad,
      this.props.team
    );
    this.setState({
      value: updatedSquad,
    });
  }

  private applySquad(index: number) {
    this.clearSquad();
    const recentSquad: RecentSquad = this.state.recentSquads[index];
    let players = [];

    if (!!recentSquad) {
      recentSquad.squad.players.forEach((player: Player) => {
        if (player.headshot !== null) {
          services.imageService
            .getOne(player.headshot)
            .then((image) => {
              services.imageService.addImageToCache(
                player.headshot.value,
                image
              );
            })
            .catch((reason) => {
              showMessage(`Could not get image: ${reason}`, "error");
            });
        }

        players.push(player);
      });

      const updatedSquad = {
        ...this.state.value,
        players,
      };
      this.setState({
        value: updatedSquad,
      });

      this.props.onPlayersAdded(players, updatedSquad, this.props.team);
    }
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        invalid={!this.canProceed()}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.props.team, this.state.value)
        }
        proceedText="NEXT"
        disabled={false}
        colour={this.state.colour || "#aaaaaa"}
      >
        <div className="dialog-container">
          <div className="select-squad-container">
            {!!this.state.recentSquads &&
              this.state.recentSquads.length > 0 && (
                <div className="recent-squad-container">
                  Recent Squads
                  {this.state.recentSquads.map(
                    (squad: RecentSquad, index: number) => {
                      return (
                        <div
                          key={`recent-squad-${index}`}
                          className="recent-squad-box"
                          onClick={() => this.applySquad(index)}
                        >
                          <div
                            className="recent-squad-colour-bar"
                            style={{
                              backgroundColor:
                                squad.thatTeam.colour || "#aaaaaa",
                            }}
                          ></div>
                          <div className="recent-squad-description-and-user-icon">
                            <div className="recent-squad-description">
                              <div>vs {squad.thatTeam.name}</div>
                              <div>
                                <div className="recent-squad-extra-info italic small">
                                  {new Date(
                                    squad.match.date
                                  ).toLocaleDateString()}
                                </div>
                              </div>
                            </div>
                            {!!squad.match.createdBy && (
                              <Tooltip title="User Created">
                                <Icon color="disabled">account_circle</Icon>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                  <div className="recent-squad-buttons">
                    <Button
                      onClick={() =>
                        this.setState(
                          { recentSquadPage: this.state.recentSquadPage - 1 },
                          () => this.updateRecentSquads()
                        )
                      }
                      disabled={this.state.recentSquadPage === 0}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      onClick={() =>
                        this.setState(
                          { recentSquadPage: this.state.recentSquadPage + 1 },
                          () => this.updateRecentSquads()
                        )
                      }
                      disabled={
                        !!this.state.recentSquads &&
                        this.state.recentSquads.length < 10
                      }
                    >
                      {">>"}
                    </Button>
                  </div>
                </div>
              )}
            {this.state.value !== null && (
              <div className="squad-display-container">
                <div className="squad-selection-and-clear">
                  <PlayerSelector
                    value={null}
                    onSelect={(player: Player) => this.addPlayer(player)}
                    options={observableFrom(this.props.eligiblePlayers)}
                    renderer={(props, option) =>
                      this.playerRenderer(props, option)
                    }
                    classes="squad-player-selector"
                  />
                  <Button onClick={() => this.clearSquad()}>Clear Squad</Button>
                </div>
                <hr />
                <SquadView
                  players={this.state.value.players}
                  onRemove={(player: Player) => this.removePlayer(player)}
                  onUpdate={(player: Player) => this.updatePlayer(player)}
                />
              </div>
            )}
          </div>
        </div>
      </CreationDialog>
    );
  }

  private canProceed(): boolean {
    return this.state.value !== null && this.state.value.players.length >= 1;
  }
}
