import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component, ReactNode } from "react";
import { Line } from "react-chartjs-2";
import { Subscription } from "rxjs";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { InningsSimulationQuestionStatistic } from "../../types/enums/question-statistic";
import { SimulatorScenario } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { InningsSimulationMedianQuestion } from "../../types/simulator/questions/simulation-median-question";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { buildChartOptions } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { SimulatorOversDistributionDisplay } from "./simulator-overs-distribution-display";

const chartOptions = buildChartOptions("bottom");

interface Props {
  gameState: GameState;
  matchFormat: MatchFormat;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface State {
  scrollTop: number;
  refreshing: boolean;
  medians: number[][];
  questions: InningsSimulationMedianQuestion[];
}

export class SimulatorMedianScoreAtOverDisplay extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      scrollTop: 0,
      refreshing: false,
      medians: null,
      questions: null,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        (scenarioResults: [SimulatorScenario, SimulationResult]) => {
          if (!!scenarioResults) {
            this.refreshQuestions();
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private refreshQuestions() {
    if (!!this.props.gameState && !!this.props.matchFormat) {
      let maxOvers = 0;
      this.props.matchFormat.inningsConfiguration.forEach(
        (_inningsConfig, index) => {
          maxOvers = Math.max(
            maxOvers,
            this.props.matchFormat.overConfiguration[index].length
          );
        }
      );

      const questions: InningsSimulationMedianQuestion[] = [];
      this.props.matchFormat.inningsConfiguration.forEach(
        (_inningsConfig, index) => {
          const innings = index + 1;
          for (let i = 0; i <= maxOvers; i = i + 5) {
            questions.push({
              subType: ".InningsSimulationMedianQuestion",
              questionStatistic:
                InningsSimulationQuestionStatistic.SCORE_AT_OVER,
              innings,
              over: i,
              wicketsFallen: 0,
              teamId: null,
            });
            questions.push({
              subType: ".InningsSimulationMedianQuestion",
              questionStatistic:
                InningsSimulationQuestionStatistic.WICKETS_AT_OVER,
              innings,
              over: i,
              wicketsFallen: 0,
              teamId: null,
            });
          }
        }
      );

      services.questionService
        .askMedianQuestions(
          this.props.gameState.matchId,
          this.props.gameState.eventSequence,
          questions,
          this.props.matchFormat
        )
        .then((response) =>
          this.setState({ questions, medians: response, refreshing: false })
        );
    }
  }

  private buildTableRows(): ReactNode[] {
    const rows: ReactNode[] = [];
    if (!!this.state.questions && !!this.state.medians) {
      let questionNumber = 0;
      this.props.matchFormat.inningsConfiguration.forEach(
        (_inningsConfig, index) => {
          const innings = index + 1;
          const cells: ReactNode[] = [];
          cells.push(
            <TableCell
              id="median-table-cell"
              key={`score-at-over-innings-${innings}`}
            >
              {innings}
            </TableCell>
          );
          this.state.questions
            .filter(
              (q) =>
                q.innings === innings &&
                q.questionStatistic ===
                  InningsSimulationQuestionStatistic.SCORE_AT_OVER
            )
            .forEach((question: InningsSimulationMedianQuestion) => {
              cells.push(
                <TableCell
                  id="median-table-cell"
                  key={`score-at-over-${questionNumber}`}
                >
                  {!!this.state.medians &&
                    !!this.state.medians[questionNumber] &&
                    !!this.state.medians[questionNumber + 1] &&
                    `${this.state.medians[questionNumber][0]}/${
                      this.state.medians[questionNumber + 1][0]
                    }`}
                </TableCell>
              );
              questionNumber = questionNumber + 2;
            });
          const row = (
            <TableRow
              id="median-table-row"
              key={`score-at-over-row-${innings}`}
            >
              {cells}
            </TableRow>
          );
          rows.push(row);
        }
      );
    }
    return rows;
  }

  private buildTableHeader(): ReactNode {
    const cells: ReactNode[] = [];
    if (!!this.state.questions) {
      cells.push(
        <TableCell
          id="median-table-header-cell"
          key={`score-at-over-header-innings`}
        >
          Innings
        </TableCell>
      );
      const overs = [...new Set(this.state.questions.map((q) => q.over))];
      overs.forEach((over: number) => {
        cells.push(
          <TableCell
            id="median-table-header-cell"
            key={`score-at-over-header-${over}`}
          >
            {over}
          </TableCell>
        );
      });
    }
    return <TableRow id="median-table-header-row">{cells}</TableRow>;
  }

  private buildChartData() {
    if (!!this.state.questions && !!this.state.medians) {
      const datasets = [];
      const overs = [...new Set(this.state.questions.map((q) => q.over))];
      let questionNumber = 0;
      this.props.matchFormat.inningsConfiguration.forEach(
        (_inningsConfig, index) => {
          const innings = index + 1;
          const scores = [];
          this.state.questions
            .filter((q) => q.innings === innings)
            .forEach((question: InningsSimulationMedianQuestion) => {
              if (
                question.questionStatistic ===
                InningsSimulationQuestionStatistic.SCORE_AT_OVER
              ) {
                scores.push(
                  !!this.state.medians &&
                    !!this.state.medians[questionNumber] &&
                    this.state.medians[questionNumber][0]
                );
              }
              questionNumber++;
            });
          datasets.push({
            yAxisID: "yAxis",
            label: "Innings " + innings + " score",
            data: scores,
            backgroundColor:
              SimulatorOversDistributionDisplay.playerColours[innings % 2],
            borderColor:
              SimulatorOversDistributionDisplay.playerColours[innings % 2],
          });
        }
      );

      return {
        labels: overs,
        datasets,
      };
    } else {
      return null;
    }
  }

  public render() {
    const tableRows = this.buildTableRows();
    const tableHeader = this.buildTableHeader();
    const chartData = this.buildChartData();

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Median Score at Over
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading || this.state.refreshing}
              spinner
              text={
                this.props.loading ? "Simulating..." : "Asking Questions..."
              }
            >
              {!!this.state.medians && (
                <div className="simulation-medians-list">
                  <TableContainer>
                    <Table>
                      <TableHead>{tableHeader}</TableHead>
                      <TableBody>{tableRows}</TableBody>
                    </Table>
                  </TableContainer>
                  <div>
                    <Line options={chartOptions} data={chartData} />
                  </div>
                </div>
              )}
              {!this.state.medians && (
                <div className="simulation-medians-list italic">
                  Medians will display on Simulation Refresh
                </div>
              )}
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
