import { EntityService } from "./entity-service";
import { Player } from "../../types/entities/player";
import { UUID } from "../../types/uuid";
import { services, showMessage } from "../../types/services";

export class PlayerService extends EntityService<Player> {
  public async updateScrapedPlayer(playerId: UUID): Promise<void> {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);

    return await this.httpService
      .get("/api/" + this.controllerName + "/update-player", params)
      .then(() => {
        this.init();
        services.currentGameService.updateTeams();
        showMessage("Successfully re-scraped player");
      })
      .catch((reason) => {
        showMessage("Error updating player from espn - " + reason, "error");
        return Promise.reject(reason);
      });
  }

  public async add(player: Player): Promise<Player> {
    return super.add(player).then((player) => {
      services.currentGameService.updateTeams();
      return player;
    });
  }
}
