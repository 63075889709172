import { Checkbox } from "@mui/material";
import { Component } from "react";

import { MatchStatsWrapper } from "../../types/stats/match-stats";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onSave: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
}

export class AheadOrBehindOverrideComponent extends Component<Props, {}> {
  STATS_TO_STEAL: string[] = [
    "overrideAheadAndBehindLimit",
    "behindLimit",
    "aheadLimit",
  ];

  private propsLoaded(): boolean {
    return !!this.props.currentStats;
  }

  private onClickHandler(updates: string[]) {
    updates.forEach((update) => {
      this.updateStats(update, this.props.comparedStats.matchStats[update]);
    });
  }

  private updateStats(property: string, value) {
    const updatedStats: MatchStatsWrapper = this.props.currentStats;
    updatedStats.matchStats[property] = value;
    this.props.onSave(updatedStats, true);
  }

  public render() {
    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">
              Second Innings Ahead/Behind Override
            </div>

            <div className="match-stats-weights-comparison">
              <div className="match-stats-weights">
                <div className="checkbox-and-label-bowler-stats">
                  <div>Override Player Stats</div>
                  <Checkbox
                    checked={
                      this.props.currentStats.matchStats
                        .overrideAheadAndBehindLimit
                    }
                    onChange={(event) =>
                      this.updateStats(
                        "overrideAheadAndBehindLimit",
                        event.target.checked
                      )
                    }
                  />
                </div>
                <NumberSelector
                  label="Behind by"
                  min={0}
                  max={10000}
                  step={1}
                  decimalPlaces={0}
                  initial={this.props.currentStats.matchStats.behindLimit}
                  onValid={(newValue) =>
                    this.updateStats("behindLimit", newValue)
                  }
                />
                <NumberSelector
                  label="Ahead by"
                  min={0}
                  max={10000}
                  step={1}
                  decimalPlaces={0}
                  initial={this.props.currentStats.matchStats.aheadLimit}
                  onValid={(newValue) =>
                    this.updateStats("aheadLimit", newValue)
                  }
                />
              </div>

              {!!this.props.comparedStats && (
                <div className="comparison">
                  <StealStatsHeadingAndButton
                    comparedUserName={this.props.comparedUserName}
                  >
                    <StealStatsButton
                      comparedUserName={this.props.comparedUserName}
                      disabled={areStatsEqual(
                        this.props.currentStats,
                        this.props.comparedStats,
                        this.STATS_TO_STEAL,
                        "matchStats"
                      )}
                      tooltipMessage={getToolTipMessage(
                        this.props.currentStats,
                        this.props.comparedStats,
                        this.STATS_TO_STEAL,
                        this.props.comparedUserName,
                        "matchStats"
                      )}
                      onClickHandler={this.onClickHandler.bind(
                        this,
                        this.STATS_TO_STEAL
                      )}
                    />
                  </StealStatsHeadingAndButton>

                  <div className="match-stats-weights">
                    <div className="checkbox-and-label-bowler-stats-comparison">
                      <div>Override Player Stats</div>
                      <Checkbox
                        checked={
                          this.props.comparedStats.matchStats
                            .overrideAheadAndBehindLimit
                        }
                        onChange={() => {}}
                        disabled={true}
                      />
                    </div>
                    <NumberComparison
                      label="Behind by"
                      originalValue={
                        this.props.currentStats.matchStats.behindLimit
                      }
                      comparedValue={
                        this.props.comparedStats.matchStats.behindLimit
                      }
                    />
                    <NumberComparison
                      label="Ahead by"
                      originalValue={
                        this.props.currentStats.matchStats.aheadLimit
                      }
                      comparedValue={
                        this.props.comparedStats.matchStats.aheadLimit
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
