import React from "react";

import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { UserPreferences } from "../../types/preferences/preferences";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

import BatsmanAccelerationDisplay from "./batsman-acceleration-display";
import BattingStatsByPush from "./batting-stats-by-push";
import { batchUpdate } from "./squad-page-utils";
import BattingStrikeRateAndWicketPercentStats from "./strike-rate-and-wicket-percent-stats";
import {
  AdminPreferences,
  isToggleEnabled,
  ToggleType,
} from "../../types/preferences/admin-preferences";

type Props = {
  player: Player;
  squad: Squad;
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  userPreferences: UserPreferences;
  adminPreferences: AdminPreferences;
  matchStats: MatchStatsWrapper;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onValid: (property: string, value) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
};

const minPushAdjustment = -100;
const maxPushAdjustment = 100;
const minExperience = 0;
const maxExperience = 1;
const minMultiplier = 0;
const maxMultiplier = 10;
const minPosition = 0;
const maxPosition = 10;
const minMiscellaneousAdjustment = 0;
const maxMiscellaneousAdjustment = 10000;

export function PlayerBattingStatsDisplay({
  currentStats,
  comparedStats = null,
  comparedUserName = null,
  userPreferences,
  adminPreferences,
  matchStats,
  player,
  squad,
  onUpdate,
  onValid,
  onArrayElementValid,
}: Props): React.JSX.Element {
  const onClickHandler = (updates: string[]) => {
    batchUpdate(currentStats, comparedStats, updates, onUpdate);
  };

  const MISCELLANEOUS_STATS_TO_STEAL: string[] = [
    "widesMultiplier",
    "averagePosition",
    "battingExperience",
    "battingPowerplayExperience",
    "battingNonPowerplayExperience",
  ];
  const PUSH_STATS_TO_STEAL: string[] = [
    "pushAdjustment",
    "pushAdjustmentUpToBall",
  ];
  const SECOND_INNINGS_STATS_TO_STEAL: string[] = ["behindLimit", "aheadLimit"];
  const POWERPLAY_STATS_TO_STEAL: string[] = [
    "powerplayPushAdjustment",
    "powerplayWicketBias",
  ];

  const miscellaneousConfig = [
    {
      label: "Wides Multiplier",
      field: "widesMultiplier",
      min: minMultiplier,
      max: maxMultiplier,
    },
    {
      label: "Average Position",
      field: "averagePosition",
      min: minPosition,
      max: maxPosition,
    },
    {
      label: "Batting Experience",
      field: "battingExperience",
      min: minExperience,
      max: maxExperience,
    },
  ];

  const pushSettingsConfig = [
    {
      label: "Adjust Push...",
      field: "pushAdjustment",
      min: minPushAdjustment,
      max: maxPushAdjustment,
    },
    {
      label: "...Up to Ball",
      field: "pushAdjustmentUpToBall",
      min: minMiscellaneousAdjustment,
      max: maxMiscellaneousAdjustment,
    },
  ];

  const secondInningsConfig = [
    {
      label: "Behind by",
      field: "behindLimit",
    },
    {
      label: "Ahead by",
      field: "aheadLimit",
    },
  ];

  const powerplayConfig = [
    {
      label: "Push Adjustment",
      field: "powerplayPushAdjustment",
      min: minPushAdjustment,
      max: maxPushAdjustment,
    },
    {
      label: "Wicket Bias",
      field: "powerplayWicketBias",
      min: minMultiplier,
      max: maxMultiplier,
    },
  ];

  const splitPowerplay = isToggleEnabled(adminPreferences, ToggleType.SPLIT_POWERPLAY_AND_NON_POWERPLAY);
  if (splitPowerplay) {
    PUSH_STATS_TO_STEAL.push(...["powerplayPushAdjustment"]);
    miscellaneousConfig.splice(
      2,
      1,
      ...[
        {
          label: "Powerplay Batting Experience",
          field: "battingPowerplayExperience",
          min: minExperience,
          max: maxExperience,
        },
        {
          label: "Non-Powerplay Batting Experience",
          field: "battingNonPowerplayExperience",
          min: minExperience,
          max: maxExperience,
        },
      ]
    );
    pushSettingsConfig.push({
      label: "Powerplay Adjustment",
      field: "powerplayPushAdjustment",
      min: minPushAdjustment,
      max: maxPushAdjustment,
    });
  }

  return (
    <div className="stats-modal-content">
      <BattingStatsByPush
        currentStats={currentStats}
        comparedStats={comparedStats}
        comparedUserName={comparedUserName}
        userPreferences={userPreferences}
        onArrayElementValid={onArrayElementValid}
        onUpdate={onUpdate}
      />

      <BattingStrikeRateAndWicketPercentStats
        currentStats={currentStats}
        comparedStats={comparedStats}
        comparedUserName={comparedUserName}
        userPreferences={userPreferences}
        adminPreferences={adminPreferences}
        onUpdate={onUpdate}
        matchStats={matchStats}
        player={player}
        squad={squad}
      />

      <div className="stats-modal-section-pair">
        <BatsmanAccelerationDisplay
          currentStats={currentStats}
          comparedStats={comparedStats}
          comparedUserName={comparedUserName}
          onArrayElementValid={onArrayElementValid}
          onUpdate={onUpdate}
        />

        <div className="stats-modal-section">
          <div className="stats-modal-section-head">Other Stats</div>
          <div
            className={
              splitPowerplay
                ? "batting-stats-modal-3-columns"
                : "batting-stats-modal-4-columns"
            }
          >
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Miscellaneous</div>
              <div className="input-and-comparison">
                <div>
                  {miscellaneousConfig.map((config) => (
                    <NumberSelector
                      key={config.field}
                      label={config.label}
                      min={config.min}
                      max={config.max}
                      step={0.01}
                      decimalPlaces={2}
                      initial={currentStats.playerStats[config.field]}
                      onValid={(newValue) => onValid(config.field, newValue)}
                    />
                  ))}
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <StealStatsHeadingAndButton
                      comparedUserName={comparedUserName}
                    >
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          MISCELLANEOUS_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          MISCELLANEOUS_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(MISCELLANEOUS_STATS_TO_STEAL)
                        }
                      />
                    </StealStatsHeadingAndButton>
                    {miscellaneousConfig.map((config) => (
                      <NumberComparison
                        key={config.field}
                        label={config.label}
                        originalValue={currentStats.playerStats[config.field]}
                        comparedValue={comparedStats.playerStats[config.field]}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Push Settings</div>
              <div className="input-and-comparison">
                <div>
                  {pushSettingsConfig.map((config) => (
                    <NumberSelector
                      key={config.field}
                      label={config.label}
                      min={config.min}
                      max={config.max}
                      initial={currentStats.playerStats[config.field]}
                      onValid={(newValue) => onValid(config.field, newValue)}
                    />
                  ))}
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <StealStatsHeadingAndButton
                      comparedUserName={comparedUserName}
                    >
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          PUSH_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          PUSH_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(PUSH_STATS_TO_STEAL)
                        }
                      />
                    </StealStatsHeadingAndButton>
                    {pushSettingsConfig.map((config) => (
                      <NumberComparison
                        key={config.field}
                        label={config.label}
                        originalValue={currentStats.playerStats[config.field]}
                        comparedValue={comparedStats.playerStats[config.field]}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Second innings</div>
              <div className="input-and-comparison">
                <div>
                  <div
                    style={{
                      paddingLeft: "2px",
                    }}
                  >
                    Adjust push when...
                  </div>
                  {secondInningsConfig.map((config) => (
                    <NumberSelector
                      key={config.field}
                      label={config.label}
                      min={minMiscellaneousAdjustment}
                      max={maxMiscellaneousAdjustment}
                      initial={currentStats.playerStats[config.field]}
                      onValid={(newValue) => onValid(config.field, newValue)}
                    />
                  ))}
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <StealStatsHeadingAndButton
                      comparedUserName={comparedUserName}
                    >
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          SECOND_INNINGS_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          SECOND_INNINGS_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(SECOND_INNINGS_STATS_TO_STEAL)
                        }
                      />
                    </StealStatsHeadingAndButton>
                    {secondInningsConfig.map((config) => (
                      <NumberComparison
                        key={config.field}
                        label={config.label}
                        originalValue={currentStats.playerStats[config.field]}
                        comparedValue={comparedStats.playerStats[config.field]}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!splitPowerplay && <div className="stats-modal-section">
              <div className="stats-modal-section-head">Powerplay</div>
              <div className="input-and-comparison">
                <div>
                {powerplayConfig.map((config) => (
                    <NumberSelector
                      key={config.field}
                      label={config.label}
                      min={config.min}
                      max={config.max}
                      initial={currentStats.playerStats[config.field]}
                      onValid={(newValue) => onValid(config.field, newValue)}
                    />
                  ))}
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <StealStatsHeadingAndButton
                      comparedUserName={comparedUserName}
                    >
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          POWERPLAY_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          POWERPLAY_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(POWERPLAY_STATS_TO_STEAL)
                        }
                      />
                    </StealStatsHeadingAndButton>
                    {powerplayConfig.map((config) => (
                      <NumberComparison
                        key={config.field}
                        label={config.label}
                        originalValue={currentStats.playerStats[config.field]}
                        comparedValue={comparedStats.playerStats[config.field]}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
