import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";

import {
  GroundFormModuleType,
  groundFormDataDisplayComponents,
  groundFormModuleNames,
} from "../../types/form/ground-form-modules";
import { GroundFormExplanation } from "../../types/stats/form-explanation";
import BowlWithExpectationTable from "../common-components/bowl-with-expectation-table";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { TabPanel } from "../match-page/scorecard/tab-panel";

interface Props {
  formExplanation: GroundFormExplanation;
}

export default function GroundFormExplanationDisplay({
  formExplanation,
}: Props): React.JSX.Element {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [displayedModule, setDisplayedModule] = useState<GroundFormModuleType>(
    GroundFormModuleType.PUSH_BRACKET_STRIKE_RATE_BIAS
  );

  return (
    <>
      {formExplanation && (
        <div className="form-explanation-container">
          <EnumSelector
            disabled={false}
            enumObject={GroundFormModuleType}
            value={displayedModule}
            readableValues={groundFormModuleNames}
            onSelect={setDisplayedModule}
          />

          <Box>
            <Tabs
              value={tabNumber}
              onChange={(_value, tab) => setTabNumber(tab)}
              aria-label="form-explanation-tabs"
            >
              <Tab
                label={"Collected Data"}
                key={"form-explanation-tab-0"}
                aria-controls={"form-explanation-tab-0"}
              />
              <Tab
                label={"Balls"}
                key={"form-explanation-tab-1"}
                aria-controls={"form-explanation-tab-1"}
              />
            </Tabs>
          </Box>

          <TabPanel
            value={tabNumber}
            index={0}
            key={"form-explanation-tab-panel-0"}
          >
            {groundFormDataDisplayComponents[displayedModule](
              formExplanation.formModules.get(displayedModule)
            )}
          </TabPanel>

          <TabPanel
            value={tabNumber}
            index={1}
            key={"form-explanation-tab-panel-1"}
          >
            <BowlWithExpectationTable
              balls={formExplanation.balls.get(displayedModule)}
              displayedModuleType={displayedModule}
            />
          </TabPanel>
        </div>
      )}
    </>
  );
}
