import PropTypes from "prop-types";

InningsSummary.propTypes = {
  data: PropTypes.array,
};

export function InningsSummary(props) {
  const { data } = props;

  return (
    <div className="innings-scores">
      {data.length > 0 ? (
        data.map(
          ([runs, wickets, totalWickets, declaration]: number[], index) => (
            <div key={"innings-summary-" + index}>
              {(wickets === totalWickets ? `${runs}` : `${runs}/${wickets}`) +
                (declaration ? "d" : "")}
            </div>
          )
        )
      ) : (
        <div>Yet to Bat</div>
      )}
    </div>
  );
}
