import { Box, Button } from "@mui/material";
import { Component } from "react";
import { Observable, Subscription } from "rxjs";

import { HistoricFormUpdateMessage } from "../../services/ground-stats-service";
import { Ground } from "../../types/entities/ground";
import { Country, countryNames } from "../../types/enums/country";
import { countryFlags } from "../../types/enums/flags";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import { SinceDate, sinceDateNames } from "../../types/enums/since-date";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services, showMessage } from "../../types/services";
import { GroundStats } from "../../types/stats/ground-stats";
import { calculateProgress } from "../component-utils";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { LinearProgressWithLabel } from "../historic-player-stats-page/all-player-stats-component";

import { HistoricGroundStrikeRateAndWicketPercentByPush } from "./historic-ground-strike-rate-and-wicket-percent-by-push";
import { HistoricGroundStrikeRateAndWicketPercentStats } from "./historic-ground-strike-rate-and-wicket-percent-stats";

interface State {
  selectedCountry: Country;
  countryStats: GroundStats;
  selectedGround: Ground;
  grounds: Ground[];
  groundStats: GroundStats;
  matchType: MatchType;
  generatingHistoricStats: boolean;
  latestUpdate: HistoricFormUpdateMessage;
  adminPreferences: AdminPreferences;
}

export class HistoricGroundStatsPage extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private static readonly DEFAULT_STATE = {
    selectedCountry: Country.AFGHANISTAN,
    countryStats: null,
    selectedGround: null,
    groundStats: null,
    grounds: [],
    matchType: MatchType.T20_AND_HUNDRED,
    generatingHistoricStats: false,
    latestUpdate: null,
    adminPreferences: null,
  };

  constructor(props) {
    super(props);
    this.state = HistoricGroundStatsPage.DEFAULT_STATE;
  }

  componentDidMount() {
    this.updateCountry(false);

    this.subscriptions.push(
      services.currentGameService.groundSubject.subscribe((ground: Ground) => {
        if (!!ground) {
          this.setState(
            {
              selectedCountry: ground.country,
              selectedGround: ground,
            },
            () => {
              this.updateCountry(false);
              this.updateGround();
            }
          );
        } else {
          this.setState({
            ...HistoricGroundStatsPage.DEFAULT_STATE,
          });
        }
      })
    );

    this.subscriptions.push(
      services.groundStatsService.historicFormRequestSubject.subscribe(
        (update: HistoricFormUpdateMessage) => {
          if (!!update && update.message === "done") {
            this.setState({
              latestUpdate: update,
              generatingHistoricStats: false,
            });
            this.updateCountry(false);
            this.updateGround();
          } else {
            this.setState({
              latestUpdate: update,
              generatingHistoricStats: true,
            });
          }
        }
      )
    );

    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) =>
          this.setState({ adminPreferences: update })
      )
    );
  }

  private updateCountry(updateGround: boolean): void {
    services.groundService
      .getGroundsForCountry(this.state.selectedCountry)
      .then((grounds: Ground[]) => {
        this.setState(
          {
            selectedGround: updateGround
              ? grounds.length > 0
                ? grounds[0]
                : null
              : this.state.selectedGround,
            grounds,
          },
          () => {
            if (!!this.state.selectedGround && updateGround) {
              this.updateGround();
            }
          }
        );
      })
      .catch((reason) => {
        showMessage(
          `Could not get grounds for ${this.state.selectedCountry}: ${reason}`,
          "error"
        );
      });

    services.groundStatsService
      .getCountryGroundStats(this.state.selectedCountry, this.state.matchType)
      .then((countryStats: GroundStats) => {
        this.setState({
          countryStats,
        });
      })
      .catch((reason) => {
        showMessage(
          `Could not get ground stats for ${this.state.selectedCountry}: ${reason}`,
          "error"
        );
      });
  }

  private updateGround(): void {
    services.groundStatsService
      .getHistoricGroundStats(this.state.selectedGround, this.state.matchType)
      .then((groundStats: GroundStats) => {
        this.setState({
          groundStats,
        });
      });
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="historic-ground-stats-title-and-radio">
            <div className="page-title">Calculated Ground Stats</div>
            <div className="historic-ground-stats-buttons">
              <Button
                onClick={() =>
                  services.groundStatsService.recalculateAllHistoric()
                }
                variant={"contained"}
                color="primary"
                disabled={this.state.generatingHistoricStats}
              >
                Recalculate Historic Stats
              </Button>

              <div className="historic-ground-stats-radio">
                <EnumSelector
                  classes="match-type-selector"
                  enumObject={MatchType}
                  label={"Match Type"}
                  value={this.state.matchType}
                  readableValues={matchTypeNames}
                  onSelect={(matchType) =>
                    this.setState({ matchType }, () => this.updateCountry(true))
                  }
                  disabled={false}
                  canType={false}
                />
              </div>
            </div>
          </div>

          {this.state.adminPreferences && (
            <div className="ground-display">
              <EnumSelector
                enumObject={SinceDate}
                label={"Timespan for stats"}
                value={this.state.adminPreferences.sinceDate}
                readableValues={sinceDateNames}
                onSelect={(sinceDate) => {
                  this.setState(
                    (prevState) => ({
                      adminPreferences: {
                        ...prevState.adminPreferences,
                        sinceDate: sinceDate,
                      },
                    }),
                    () => {
                      services.userService.updateAdminPreferences(
                        this.state.adminPreferences
                      );
                    }
                  );
                }}
                disabled={false}
              />
            </div>
          )}

          {!!this.state.selectedCountry &&
            !this.state.generatingHistoricStats && (
              <div className="ground-display">
                <div className="historic-stats-country-description-and-selector">
                  {!!this.state.selectedCountry && (
                    <div className="historic-stats-country-flag">
                      {countryFlags[this.state.selectedCountry]}
                    </div>
                  )}
                  <EnumSelector
                    classes="historic-stats-country-selector"
                    enumObject={Country}
                    value={this.state.selectedCountry}
                    readableValues={countryNames}
                    images={countryFlags}
                    onSelect={(selectedCountry: Country) =>
                      this.setState({ selectedCountry }, () =>
                        this.updateCountry(true)
                      )
                    }
                    disabled={false}
                    canClear={false}
                  />
                </div>
                <div className="ground-stats-body">
                  <div className="ground-stats-by-push">
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.countryStats}
                      index={0}
                      title={"Blocking"}
                      classes="blocking"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.countryStats}
                      index={1}
                      title={"Defensive"}
                      classes="defensive"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.countryStats}
                      index={2}
                      title={"Normal"}
                      classes="normal"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.countryStats}
                      index={3}
                      title={"Pushing"}
                      classes="pushing"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.countryStats}
                      index={4}
                      title={"Full Push"}
                      classes="full-push"
                    />
                  </div>

                  <div className="ground-stats-miscellaneous">
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.countryStats}
                      title="Global"
                      strikeRateProperty="globalStrikeRateBias"
                      wicketPercentProperty="globalWicketBias"
                      confidenceProperty="globalConfidence"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.countryStats}
                      title="Against Pace"
                      strikeRateProperty="paceStrikeRateBias"
                      wicketPercentProperty="paceWicketBias"
                      confidenceProperty="paceConfidence"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.countryStats}
                      title="Against Spin"
                      strikeRateProperty="spinStrikeRateBias"
                      wicketPercentProperty="spinWicketBias"
                      confidenceProperty="spinConfidence"
                    />
                  </div>
                </div>
              </div>
            )}

          {!!this.state.selectedGround &&
            !this.state.generatingHistoricStats && (
              <div className="ground-display">
                <div className="historic-stats-country-description-and-selector">
                  <EntityAutoSelector
                    classes="historic-stats-country-selector"
                    value={this.state.selectedGround}
                    label={null}
                    options={
                      new Observable<Ground[]>((subscriber) =>
                        subscriber.next(this.state.grounds)
                      )
                    }
                    optionService={services.groundService}
                    onSelect={(selectedGround) =>
                      this.setState(
                        { selectedGround: selectedGround as Ground },
                        () => this.updateGround()
                      )
                    }
                    canAddNew={false}
                    canEdit={false}
                    canClear={false}
                  />
                </div>
                <div className="ground-stats-body">
                  <div className="ground-stats-by-push">
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.groundStats}
                      index={0}
                      title={"Blocking"}
                      classes="blocking"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.groundStats}
                      index={1}
                      title={"Defensive"}
                      classes="defensive"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.groundStats}
                      index={2}
                      title={"Normal"}
                      classes="normal"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.groundStats}
                      index={3}
                      title={"Pushing"}
                      classes="pushing"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentByPush
                      groundStats={this.state.groundStats}
                      index={4}
                      title={"Full Push"}
                      classes="full-push"
                    />
                  </div>

                  <div className="ground-stats-miscellaneous">
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.groundStats}
                      title="Global"
                      strikeRateProperty="globalStrikeRateBias"
                      wicketPercentProperty="globalWicketBias"
                      confidenceProperty="globalConfidence"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.groundStats}
                      title="Against Pace"
                      strikeRateProperty="paceStrikeRateBias"
                      wicketPercentProperty="paceWicketBias"
                      confidenceProperty="paceConfidence"
                    />
                    <HistoricGroundStrikeRateAndWicketPercentStats
                      groundStats={this.state.groundStats}
                      title="Against Spin"
                      strikeRateProperty="spinStrikeRateBias"
                      wicketPercentProperty="spinWicketBias"
                      confidenceProperty="spinConfidence"
                    />
                  </div>
                </div>
              </div>
            )}
          {this.state.generatingHistoricStats && (
            <div className="ground-display loading-bar">
              Loading...
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel
                  value={calculateProgress(this.state.latestUpdate)}
                />
              </Box>
              {!!this.state.latestUpdate && (
                <div>
                  {this.state.latestUpdate.done}/{this.state.latestUpdate.size}{" "}
                  {this.state.latestUpdate.message}
                </div>
              )}
            </div>
          )}
        </div>
      </EmployeeRoute>
    );
  }
}
