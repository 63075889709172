import { Component } from "react";
import { MatchInfo } from "../../../types/entities/match-info";
import TimeAgo from "timeago-react";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { CircularProgress } from "@mui/material";
import { propsEqual } from "../../component-utils";
import { services, showConfirmationDialog } from "../../../types/services";
import { BetBuilderRoute, UserRoute } from "../../../types/route-helpers";

interface Props {
  matchInfo: MatchInfo;
  onClick: Function;
  onBetBuilder?: Function;
  onShare?: Function;
  onDelete?: Function;
  buttonText?: String;
}

interface State {
  leftBannerStyle;
  rightBannerStyle;
  deleting;
}

export class MatchInfoDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      leftBannerStyle: null,
      rightBannerStyle: null,
      deleting: false,
    };
  }

  componentDidMount(): void {
    this.updateBannerStyles();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateBannerStyles();
    }
  }

  private updateBannerStyles() {
    const leftBannerStyle = {
      ...this.state.leftBannerStyle,
      backgroundColor:
        this.props.matchInfo == null
          ? "#f8f8ff"
          : this.props.matchInfo.team1Colour === null
          ? "#888888"
          : this.props.matchInfo.team1Colour,
    };
    const rightBannerStyle = {
      ...this.state.rightBannerStyle,
      backgroundColor:
        this.props.matchInfo == null
          ? "#f8f8ff"
          : this.props.matchInfo.team2Colour === null
          ? "#888888"
          : this.props.matchInfo.team2Colour,
    };
    this.setState({ leftBannerStyle, rightBannerStyle });
  }

  private toDate(epoch: number): Date {
    return new Date(epoch);
  }

  private delete() {
    this.setState({ deleting: true });
    this.props.onDelete().then(() => {
      this.setState({ deleting: false });
    });
  }

  private deleteDisabled(): boolean {
    return (
      this.props.matchInfo &&
      this.props.matchInfo.createdBy &&
      this.props.matchInfo.createdBy.value !==
        services.keycloakService.getUserId()
    );
  }

  public render() {
    return (
      <div className="my-matches-box-content">
        {this.props.matchInfo === null && (
          <div className="full-width">
            <div className="team-colour-container-hidden">
              <div
                className="team-colour-banner-left"
                style={this.state.leftBannerStyle}
              />
              <div
                className="team-colour-banner-right"
                style={this.state.rightBannerStyle}
              />
            </div>
            <div
              className="my-matches-box dark-background create-match"
              onClick={() => this.props.onClick()}
            >
              {this.props.buttonText}
            </div>
          </div>
        )}
        {this.props.matchInfo !== null && (
          <div className="full-width">
            <div className="team-colour-container">
              <div
                className="team-colour-banner-left"
                style={this.state.leftBannerStyle}
              />
              <div
                className="team-colour-banner-right"
                style={this.state.rightBannerStyle}
              />
            </div>
            <div className="my-matches-box">
              <div className="match-details-stack">
                <h2>
                  {this.props.matchInfo.team1Name} vs{" "}
                  {this.props.matchInfo.team2Name}
                </h2>
                <div>
                  <hr></hr>
                  <div className="match-details-side">
                    <div>
                      {this.props.matchInfo.runs === null ? (
                        <div>Match not started</div>
                      ) : (
                        <div>
                          Score: {this.props.matchInfo.runs}/
                          {this.props.matchInfo.wickets}
                        </div>
                      )}
                      <div className="italic">
                        Last updated:{" "}
                        <TimeAgo
                          datetime={this.toDate(
                            this.props.matchInfo.lastUpdated
                          )}
                        />
                      </div>
                    </div>
                    <div className="my-matches-edit-buttons">
                      <UserRoute>
                        <TooltipIconButton
                          icon="edit_circle"
                          title="Edit"
                          disabled={this.state.deleting}
                          onClick={this.props.onClick}
                        />
                        {!this.state.deleting && (
                          <TooltipIconButton
                            icon="share"
                            title={"Share"}
                            disabled={this.state.deleting}
                            onClick={this.props.onShare}
                          />
                        )}
                        {!this.state.deleting && (
                          <TooltipIconButton
                            icon="delete_circle"
                            title={
                              this.deleteDisabled()
                                ? "Cannot delete shared match"
                                : "Delete"
                            }
                            disabled={this.deleteDisabled()}
                            onClick={() =>
                              showConfirmationDialog(
                                () => this.delete(),
                                "Delete Match",
                                "Are you sure?"
                              )
                            }
                          />
                        )}
                        {this.state.deleting && (
                          <div className="match-info-progress-container">
                            <CircularProgress size="14px" />
                          </div>
                        )}
                      </UserRoute>
                      <BetBuilderRoute>
                        <TooltipIconButton
                          icon="casino"
                          title="Bet Builder"
                          onClick={this.props.onBetBuilder}
                        />
                      </BetBuilderRoute>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
