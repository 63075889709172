import { EntityService } from "./entity-service";
import { Ground } from "../../types/entities/ground";
import { services, showMessage } from "../../types/services";
import { Country } from "../../types/enums/country";

export class GroundService extends EntityService<Ground> {
  async getLocationData(entity: Ground): Promise<Ground> {
    return await services.httpService
      .post(
        "/api/" + this.controllerName + "/get-location-data",
        Ground.serialize(entity)
      )
      .then((response: any) => {
        const ground: Ground = Ground.deserializeOne(response);
        return ground;
      })
      .catch((reason) => {
        showMessage(`Failed to get ground location data: ${reason}`, "error");
        return null;
      });
  }

  async getGroundsForCountry(selectedCountry: Country): Promise<Ground[]> {
    return await services.httpService
      .get(
        "/api/" +
          this.controllerName +
          "/get-grounds-for-country?country=" +
          selectedCountry
      )
      .then((response: any) => {
        const grounds: Ground[] = Ground.deserializeList(response);
        return grounds;
      })
      .catch((reason) => {
        showMessage(
          `Failed to get grounds for ${selectedCountry}: ${reason}`,
          "error"
        );
        return null;
      });
  }
}
