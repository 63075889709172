import { Component } from "react";
import { UserPreferences } from "../../types/preferences/preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { FormSettingsModal } from "../squads-page/form-settings-modal";
import { AllPlayerStatsComponent } from "./all-player-stats-component";
import { AllPlayerFormComponent } from "./all-player-form-component";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";

interface State {
  formSettingsModalOpen: boolean;
  matchType: MatchType;
}

export class HistoricPlayerStatsPage extends Component<{}, State> {
  private static readonly DEFAULT_STATE = {
    formSettingsModalOpen: false,
    matchType: MatchType.T20_AND_HUNDRED,
  };

  constructor(props) {
    super(props);
    this.state = HistoricPlayerStatsPage.DEFAULT_STATE;
  }

  private updatePreferences(settings: UserPreferences) {
    services.userService
      .updateUserPreferences(settings)
      .then(() => this.setState({ formSettingsModalOpen: false }));
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Historic Player Stats</div>
            <div className="squad-buttons">
              <TooltipIconButton
                title="Form Calculation Settings"
                onClick={() => this.setState({ formSettingsModalOpen: true })}
                icon="settings"
                colour="#f8f8ff"
                disabled={false}
              />
              <div className="historic-ground-stats-radio">
                <EnumSelector
                  classes="match-type-selector"
                  enumObject={MatchType}
                  label={"Match Type"}
                  value={this.state.matchType}
                  readableValues={matchTypeNames}
                  onSelect={(matchType) => this.setState({ matchType })}
                  disabled={false}
                  canType={false}
                />
              </div>
            </div>
          </div>
          <AllPlayerFormComponent matchType={this.state.matchType} />
          <AllPlayerStatsComponent matchType={this.state.matchType} />
        </div>

        <FormSettingsModal
          open={this.state.formSettingsModalOpen}
          onCancel={() => {
            this.setState({ formSettingsModalOpen: false });
          }}
          onProceed={(settings) => this.updatePreferences(settings)}
        />
      </EmployeeRoute>
    );
  }
}
