import { Component, ReactNode } from "react";
import { Subscription } from "rxjs";
import { services, showMessage } from "../../types/services";
import {
  CricketEvent,
  EventWithHistoricPush,
  asBall,
  asBetweenBallsEvent,
  getOver,
} from "../../types/entities/cricket-event";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
} from "@mui/material";
import { Squad } from "../../types/entities/squad";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import { Match } from "../../types/entities/match";
import { EventType } from "../../types/enums/event-type";
import { BallDisplay } from "../match-page/event-display/ball-display";
import { EndOfOverDisplay } from "../match-page/event-display/end-of-over-display";
import { BetweenBallsEventDisplay } from "../match-page/event-display/between-balls-event-display";
import { BetweenBallsEvent } from "../../types/entities/between-balls-event";
import { Ball } from "../../types/entities/ball";
import { calculateBallText } from "../match-page/bowl-input/bowl-input-templates";
import { HistoricPush } from "../../types/entities/historic-push";
import { format } from "../simulator-page/simulator-utils";

interface State {
  events: EventWithHistoricPush[];
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
}

export class HistoricEventTable extends Component<{}, State> {
  public subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      squad1: null,
      squad2: null,
      team1: null,
      team2: null,
      matchFormat: null,
      match: null,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.matchService.scrapedMatchSubject.subscribe((match: Match) =>
        this.setState({ match }, () => this.updateMatchObjects())
      )
    );
    this.subscriptions.push(
      services.ballService.ballsForMatchSubject.subscribe(
        (events: EventWithHistoricPush[]) => this.setState({ events })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateMatchObjects() {
    if (!!this.state.match) {
      Promise.all<Team | Squad | MatchFormat>([
        services.teamService.getOne(this.state.match.team1Id),
        services.teamPlayerService.getSquad(
          this.state.match.matchId,
          this.state.match.team1Id
        ),
        services.teamService.getOne(this.state.match.team2Id),
        services.teamPlayerService.getSquad(
          this.state.match.matchId,
          this.state.match.team2Id
        ),
        services.matchFormatService.getOne(this.state.match.matchFormatId),
      ])
        .then(([team1, squad1, team2, squad2, matchFormat]) => {
          this.setState({
            squad1: squad1 as Squad,
            squad2: squad2 as Squad,
            team1: team1 as Team,
            team2: team2 as Team,
            matchFormat: matchFormat as MatchFormat,
          });
        })
        .catch((reason) => {
          showMessage(`Could not get teams: ${reason}`, "error");
        });
    } else {
      this.setState({
        squad1: null,
        squad2: null,
        team1: null,
        team2: null,
        matchFormat: null,
      });
    }
  }

  private buildTableHeader(): ReactNode[] {
    const headers = [
      "Event",
      "Description",
      "Score",
      "Push",
      "Expected SR",
      "Expected W%",
    ];
    return headers.map((h) => <TableCell key={h}>{h}</TableCell>);
  }

  private buildTableRows(): ReactNode[] {
    let overToggle: boolean = false;
    let inningsNumber: number = -1;
    let overNumber: number = -1;
    let runs = 0;
    let wickets = 0;
    let target = 0;
    const events: ReactNode[] = [];
    let betweenBallsEvents: ReactNode[] = [];

    this.state.events.forEach((eventWithHistoricPush) => {
      const event: CricketEvent = eventWithHistoricPush.event;
      const historicPush: HistoricPush = eventWithHistoricPush.historicPush;
      const over = getOver(event);
      const innings = event.innings;

      if (over !== overNumber || innings !== inningsNumber) {
        overToggle = !overToggle;
        if (over === 0) {
          target = runs;
          runs = 0;
          wickets = 0;
          events.push(
            <TableRow>
              <TableCell colSpan={6} id="historic-matches-table-cell">
                <EndOfOverDisplay
                  key={"endOfInnings-" + event.eventId.value}
                  endOfInnings={true}
                  event={event}
                  overSummary={null}
                />
              </TableCell>
            </TableRow>
          );
        }
        events.push(
          <TableRow>
            <TableCell colSpan={6} id="historic-matches-table-cell">
              <EndOfOverDisplay
                key={"endOfOver-" + event.eventId.value}
                endOfInnings={false}
                event={event}
                overSummary={null}
              />
            </TableCell>
          </TableRow>
        );
        events.push(this.buildTableHeader());
      }

      if (event.eventType === EventType.BOWL) {
        if (betweenBallsEvents.length > 0) {
          events.push(
            <TableRow className={overToggle ? "over-even" : "over-odd"}>
              <TableCell colSpan={6} id="historic-matches-table-cell">
                <div className="historic-between-balls-events-row">
                  {betweenBallsEvents}
                </div>
              </TableCell>
            </TableRow>
          );
          betweenBallsEvents = [];
        }
        const ball = asBall(event);
        events.push(
          <TableRow className={overToggle ? "over-even" : "over-odd"}>
            <TableCell id="historic-matches-table-cell">
              <BallDisplay
                key={"event-" + event.eventId.value}
                ball={ball}
                overToggle={overToggle}
                overSummary={null}
                squad1={this.state.squad1}
                squad2={this.state.squad2}
                matchFormat={this.state.matchFormat}
              />
            </TableCell>
            <TableCell id="historic-matches-table-cell">
              {calculateBallText(
                event as Ball,
                this.state.matchFormat &&
                  this.state.matchFormat.inningsConfiguration[event.innings - 1]
                    .battingTeam,
                this.state.squad1,
                this.state.squad2,
                this.state.matchFormat
              )}
            </TableCell>
            <TableCell id="historic-matches-table-cell">
              {runs}/{wickets} {target > 0 ? `(Target: ${target})` : ""}
            </TableCell>
            <TableCell id="historic-matches-table-cell">
              {format(historicPush.push)}
            </TableCell>
            <TableCell id="historic-matches-table-cell">
              {format(historicPush.expectedSr * 100)}
            </TableCell>
            <TableCell id="historic-matches-table-cell">
              {format(historicPush.expectedWpc * 100)}
            </TableCell>
          </TableRow>
        );
        runs += ball.runs + ball.extras;
        wickets += ball.dismissal ? 1 : 0;
      } else {
        betweenBallsEvents.push(
          <div className="historic-between-balls-event">
            <BetweenBallsEventDisplay
              key={"event-" + event.eventId.value}
              event={asBetweenBallsEvent(event)}
              overToggle={overToggle}
              gameState={null}
              squad1={this.state.squad1}
              squad2={this.state.squad2}
              matchFormat={this.state.matchFormat}
              team1={this.state.team1}
              team2={this.state.team2}
            />
            {BetweenBallsEventDisplay.getEventTooltip(
              this.state.squad1,
              this.state.squad2,
              this.state.team1,
              this.state.team2,
              this.state.matchFormat,
              event as BetweenBallsEvent
            )}
          </div>
        );
      }

      overNumber = over;
      inningsNumber = innings;
    });

    return events;
  }

  public render() {
    const tableHeaderRow = this.buildTableHeader();
    const tableRows = this.buildTableRows();

    return (
      <div className="scraped-matches-events">
        <TableContainer style={{ overflowX: "scroll" }}>
          <Table>
            <TableHead>
              <TableRow>{tableHeaderRow}</TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
