import { InningsSimulationQuestionStatistic } from "../../enums/question-statistic";
import { PlayerSimulationQuestionStatistic } from "../../enums/question-statistic";
import { SimulatorOddsQuestionOperator } from "../../enums/questions-operator";
import { sha1 } from "object-hash";

export enum SimulationQuestionType {
  PLAYER = "PLAYER",
  INNINGS = "INNINGS",
  MULTI = "MULTI",
}

export const questionTypesStringToEnum: Record<string, SimulationQuestionType> =
  {
    ".PlayerSimulationOddsQuestion": SimulationQuestionType.PLAYER,
    ".InningsSimulationOddsQuestion": SimulationQuestionType.INNINGS,
    ".MultiSimulationOddsQuestion": SimulationQuestionType.MULTI,
  };

export const questionTypesEnumToString: Record<SimulationQuestionType, string> =
  {
    PLAYER: ".PlayerSimulationOddsQuestion",
    INNINGS: ".InningsSimulationOddsQuestion",
    MULTI: ".MultiSimulationOddsQuestion",
  };

export const simulationQuestionTypeNames: Record<
  SimulationQuestionType,
  string
> = {
  PLAYER: "Player",
  INNINGS: "Innings",
  MULTI: "Multi",
};

export interface SimulationOddsQuestion {
  subType: string;
  operator: SimulatorOddsQuestionOperator;
  operand: number;
}

export interface InningsSimulationOddsQuestion extends SimulationOddsQuestion {
  over: number;
  innings: number;
  wicketsFallen: number;
  teamId: string;
  questionStatistic: InningsSimulationQuestionStatistic;
}

export interface PlayerSimulationOddsQuestion extends SimulationOddsQuestion {
  playerId: string;
  questionStatistic: PlayerSimulationQuestionStatistic;
}

export interface MultiSimulationOddsQuestion extends SimulationOddsQuestion {
  questions: SimulationOddsQuestion[];
  correctQuestionsToApply: number;
}

export function toHashCode(question: SimulationOddsQuestion) {
  return sha1(question);
}

export function serializeSimulationOddsQuestion(
  question: SimulationOddsQuestion
) {
  if (!question) {
    return null;
  }

  switch (questionTypesStringToEnum[question.subType]) {
    case SimulationQuestionType.INNINGS:
      return serializeInningsQuestion(
        question as InningsSimulationOddsQuestion
      );
    case SimulationQuestionType.PLAYER:
      return serializePlayerQuestion(question as PlayerSimulationOddsQuestion);
    case SimulationQuestionType.MULTI:
      return serializeMultiQuestion(question as MultiSimulationOddsQuestion);
    default:
      return null;
  }
}

export function serializeInningsQuestion(
  question: InningsSimulationOddsQuestion
) {
  return {
    subType: question.subType,
    innings: question.innings,
    operator: question.operator,
    operand: question.operand,
    over: question.over,
    wicketsFallen: question.wicketsFallen,
    teamId: question.teamId,
    questionStatistic: question.questionStatistic,
  };
}

export function serializePlayerQuestion(
  question: PlayerSimulationOddsQuestion
) {
  return {
    subType: question.subType,
    operator: question.operator,
    operand: question.operand,
    playerId: question.playerId,
    questionStatistic: question.questionStatistic,
  };
}

export function serializeMultiQuestion(question: MultiSimulationOddsQuestion) {
  return {
    subType: question.subType,
    operator: question.operator,
    operand: question.operand,
    correctQuestionsToApply: question.correctQuestionsToApply,
    questions: question.questions.map((q) =>
      serializeSimulationOddsQuestion(q)
    ),
  };
}
