import { EntityAutoSelector } from "../entity-selectors/entity-auto-selector";
import { TextField } from "@mui/material";
import { Ground } from "../../../types/entities/ground";
import { Team } from "../../../types/entities/team";
import { services, showMessage } from "../../../types/services";
import { Component } from "react";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { EditGroundComponent } from "./edit-ground-component";
import { HexColorPicker } from "react-colorful";

interface Props {
  value: Team;
  open: boolean;
  label: string;
  buttonText: string;
  onProceed: Function;
  onCancel: Function;
  isNew: boolean;
}

interface State {
  value: Team;
  homeGround: Ground;
  homeGroundModalOpen: boolean;
  homeGroundModalButtonText: string;
  homeGroundModalLabel: string;
  homeGroundIsNew: boolean;
}

export class EditTeamComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      homeGround: null,
      homeGroundModalOpen: false,
      homeGroundIsNew: true,
      homeGroundModalButtonText: "CREATE",
      homeGroundModalLabel: "Create Home Ground",
    };
  }

  componentDidMount(): void {
    this.setState({ value: this.props.value });
    this.updateHomeGround();
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
      this.updateHomeGround();
    }
  }

  private updateHomeGround() {
    if (this.props.value !== null && this.props.value.homeGroundId !== null) {
      services.groundService
        .getOne(this.props.value.homeGroundId)
        .then((ground) => this.setState({ homeGround: ground }))
        .catch((reason) => {
          showMessage(`Could not get ground: ${reason}`, "error");
        });
    } else {
      this.setState({ homeGround: null });
    }
  }

  private valid(): boolean {
    return (
      this.state.value &&
      this.state.value.name !== null &&
      this.state.value.name.trim().length > 0
    );
  }

  private clearAndCancel() {
    this.props.onCancel(this.props.isNew);
  }

  private disabled(): boolean {
    return !this.props.isNew && this.state.value && !this.state.value.createdBy;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        onProceed={() => this.props.onProceed(this.state.value)}
        onCancel={() => this.clearAndCancel()}
        invalid={!this.valid()}
        disabled={this.disabled()}
        proceedText={this.props.buttonText}
      >
        <div className="dialog-container">
          <div className="dialog-content">
            <span>Name:</span>
            <TextField
              value={this.state.value === null ? "" : this.state.value.name}
              onChange={(event) => this.handleNameChange(event)}
              placeholder={"Team name"}
              variant="outlined"
              disabled={this.disabled()}
            />
          </div>
          <div className="dialog-content">
            <EntityAutoSelector
              label="Home Ground (Optional):"
              value={this.state.homeGround}
              options={services.groundService.getAll()}
              optionService={services.groundService}
              onSelect={(ground) =>
                this.handleHomeGroundChange(ground as Ground)
              }
              onCreateOrEdit={(isNew, entity) =>
                this.showHomeGroundModal(isNew, entity)
              }
              disabled={this.disabled()}
            />
            <EditGroundComponent
              value={this.state.homeGround}
              open={this.state.homeGroundModalOpen}
              label={this.state.homeGroundModalLabel}
              buttonText={this.state.homeGroundModalButtonText}
              isNew={this.state.homeGroundIsNew}
              onProceed={(ground) => this.onModalProceed(ground)}
              onCancel={(isNew) => this.onModalCancel(isNew)}
            />
            Team Colour:
            <div className="dialog-content-sideways">
              {!this.disabled() && (
                <HexColorPicker
                  color={
                    this.state.value && this.state.value.colour
                      ? this.state.value.colour
                      : Team.DEFAULT_COLOUR
                  }
                  onChange={(colour) => this.handleColourChange(colour)}
                />
              )}
              <div
                className="colour-picker-display"
                style={{
                  backgroundColor:
                    this.state.value && this.state.value.colour
                      ? this.state.value.colour
                      : Team.DEFAULT_COLOUR,
                }}
              />
            </div>
          </div>
        </div>
      </CreationDialog>
    );
  }

  private showHomeGroundModal(isNew: boolean, entity: Ground) {
    this.setState({
      homeGround: entity,
      homeGroundModalOpen: true,
      homeGroundModalButtonText: isNew ? "CREATE" : "UPDATE",
      homeGroundModalLabel: isNew ? `Create Home Ground` : `Update Home Ground`,
      homeGroundIsNew: isNew,
    });
  }

  private onModalProceed(homeGround: Ground) {
    services.groundService
      .add(homeGround)
      .then((ground: Ground) => this.setState({ homeGround: ground }))
      .catch((reason) =>
        showMessage(`Failed to add home ground: ${reason}`, "error")
      );
    this.onModalCancel(false);
  }

  private onModalCancel(isNew: boolean) {
    if (isNew) {
      this.setState({ homeGround: null });
    }
    this.setState({
      homeGroundModalOpen: false,
      homeGroundModalButtonText: "CREATE",
      homeGroundModalLabel: `Create Home Ground`,
      homeGroundIsNew: true,
    });
  }

  private handleNameChange(event) {
    this.setState({ value: { ...this.state.value, name: event.target.value } });
  }

  private handleHomeGroundChange(entity: Ground) {
    this.setState({
      value: { ...this.state.value, homeGroundId: entity.groundId },
      homeGround: entity,
    });
  }

  private handleColourChange(colour) {
    this.setState({ value: { ...this.state.value, colour: colour } });
  }
}
