import { ReactNode } from "react";
import { BowlType } from "../../enums/bowl-type";
import { LongBowlType } from "../../enums/long-bowl-type";
import { PushBracket } from "../../enums/push-bracket";

export enum BowlingAttackModuleType {
  ELIGIBLE_TO_BOWL = "ELIGIBLE_TO_BOWL",
  REGULAR_AT_THIS_PUSH = "REGULAR_AT_THIS_PUSH",
  PLAYER_IS_A_BOWLER = "PLAYER_IS_A_BOWLER",
  BOWLER_TYPE = "BOWLER_TYPE",
  RESERVED_BOWLER = "RESERVED_BOWLER",
  CAPTAIN_SHORT_TERMISM = "CAPTAIN_SHORT_TERMISM",
  PHASE_PREFERENCE = "PHASE_PREFERENCE",
}

export interface BowlerTypeRule {
  name: string;
  longBowlTypes: LongBowlType[];
  bowlTypes: BowlType[];
  pushBrackets: PushBracket[];
  award: number;
}

export function generateDefaultBowlerTypeRule(): BowlerTypeRule {
  return {
    name: "New Rule",
    longBowlTypes: [],
    bowlTypes: [],
    pushBrackets: [],
    award: 10,
  };
}

export class BowlingAttackData {
  subType: string;
  enabled: boolean;
  award: number;
  constructor(enabled: boolean, subType: string, award: number) {
    this.enabled = enabled;
    this.subType = subType;
    this.award = award;
  }
}

export class PlayerIsABowlerData extends BowlingAttackData {
  numberOfBallsForExperience: number;
  awardForExperiencedBowler: number;
  constructor(
    numberOfBallsForExperience: number,
    awardForExperiencedBowler: number,
    enabled: boolean,
    subType: string,
    award: number
  ) {
    super(enabled, subType, award);
    this.numberOfBallsForExperience = numberOfBallsForExperience;
    this.awardForExperiencedBowler = awardForExperiencedBowler;
  }
}

export class CaptainShortTermismBowlingAttackData extends BowlingAttackData {
  meanScore: number;
  captainAggressiveness: number;

  constructor(
    meanScore: number,
    captainAggressiveness: number,
    enabled: boolean,
    subType: string,
    award: number
  ) {
    super(enabled, subType, award);
    this.meanScore = meanScore;
    this.captainAggressiveness = captainAggressiveness;
  }
}

export class BowlerTypeData extends BowlingAttackData {
  rules: BowlerTypeRule[];
  constructor(
    rules: BowlerTypeRule[],
    enabled: boolean,
    subType: string,
    award: number
  ) {
    super(enabled, subType, award);
    this.rules = rules;
  }
}

export class PhasePreferenceBowlingAttackData extends BowlingAttackData {
  overusePenalty: number;
  constructor(
    overusePenalty: number,
    enabled: boolean,
    subType: string,
    award: number
  ) {
    super(enabled, subType, award);
    this.overusePenalty = overusePenalty;
  }
}

export const bowlingAttackModuleToDataTypes: Record<
  BowlingAttackModuleType,
  (any) => BowlingAttackData
> = {
  REGULAR_AT_THIS_PUSH: (json: any) =>
    new BowlingAttackData(json.enabled, json.subType, json.award),
  ELIGIBLE_TO_BOWL: (json: any) =>
    new BowlingAttackData(json.enabled, json.subType, json.award),
  PLAYER_IS_A_BOWLER: (json: any) =>
    new PlayerIsABowlerData(
      json.numberOfBallsForExperience,
      json.awardForExperiencedBowler,
      json.enabled,
      json.subType,
      json.award
    ),
  BOWLER_TYPE: (json: any) =>
    new BowlerTypeData(json.rules, json.enabled, json.subType, json.award),
  RESERVED_BOWLER: (json: any) =>
    new BowlingAttackData(json.enabled, json.subType, json.award),
  CAPTAIN_SHORT_TERMISM: (json: any) =>
    new CaptainShortTermismBowlingAttackData(
      json.meanScore,
      json.captainAggressiveness,
      json.enabled,
      json.subType,
      json.award
    ),
  PHASE_PREFERENCE: (json: any) =>
    new PhasePreferenceBowlingAttackData(
      json.overusePenalty,
      json.enabled,
      json.subType,
      json.award
    ),
};

export const bowlingAttackModuleNames: Record<BowlingAttackModuleType, string> =
  {
    REGULAR_AT_THIS_PUSH: "Regular at this push",
    ELIGIBLE_TO_BOWL: "Eligible to bowl",
    PLAYER_IS_A_BOWLER: "Recognised bowler",
    BOWLER_TYPE: "Bowler type",
    RESERVED_BOWLER: "Reserved bowler",
    CAPTAIN_SHORT_TERMISM: "In game form",
    PHASE_PREFERENCE: "Phase preference",
  };

export const bowlingAttackModuleTooltips: Record<
  BowlingAttackModuleType,
  string
> = {
  REGULAR_AT_THIS_PUSH: "Regular at this push settings",
  ELIGIBLE_TO_BOWL:
    "See match format - number of consecutive overs/total overs per bowler",
  PLAYER_IS_A_BOWLER: "Recognised bowler settings",
  BOWLER_TYPE: "Bowler type settings",
  RESERVED_BOWLER: "Reserved bowler settings",
  CAPTAIN_SHORT_TERMISM: "In game form settings",
  PHASE_PREFERENCE: "Phase preference settings",
};

export interface BowlingAttackModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number" | "bowlerTypeRules";
  constraints: any;
}

export const bowlingAttackDataProperties: Record<
  BowlingAttackModuleType,
  BowlingAttackModuleDataProperty[]
> = {
  REGULAR_AT_THIS_PUSH: [
    {
      humanReadableName: "Award for most regular",
      propertyName: "award",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            Bowlers are sorted in order of experience at this push bracket.
            Award points to each bowler between this value and 0 depending on
            their ranking.
          </p>
        </div>
      ),
    },
  ],
  ELIGIBLE_TO_BOWL: [],
  PLAYER_IS_A_BOWLER: [
    {
      humanReadableName: "Award for bowling role",
      propertyName: "award",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many points should the bowler be awarded if their playing role
            is either 'bowler', 'bowling allrounder' or 'allrounder'.
          </p>
        </div>
      ),
    },
    {
      humanReadableName: "Award for being experienced",
      propertyName: "awardForExperiencedBowler",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many points should the bowler be awarded if their playing role
            is <b>NOT</b> 'bowler', 'bowling allrounder' or 'allrounder'{" "}
            <b>BUT</b> they have historically bowled enough balls to be deemed a
            recognised bowler.
          </p>
        </div>
      ),
    },
    {
      humanReadableName: "Number of balls to count as experienced",
      propertyName: "numberOfBallsForExperience",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            How many balls should this player have bowled historically before we
            count him as a recognised bowler? Any bowler for whom the sum of
            their confidence at each push level surpasses this number will be
            counted as a bowler, even if their playing role is batsman
          </p>
        </div>
      ),
    },
  ],
  BOWLER_TYPE: [
    {
      humanReadableName: "Bowler Type Rules",
      description: (
        <p className="italic max-width-300">
          <p>
            Each rule contains two sets of bowler type - Primary and Secondary.
          </p>
          <p>
            A player only needs to match one of these types to be awarded the
            points.
          </p>
          <p>Each rule should apply to a set of push brackets.</p>
          <p>
            The award will be given to bowlers of the chosen types when the push
            is within the chosen brackets.
          </p>
          <p>
            e.g. If you choose..
            <ul>
              <li>Primary bowl type "Left-Arm Pace" and "Right-Arm Pace"</li>
              <li>Secondary bowl type "Left-Arm Orthodox"</li>
              <li>Push Brackets "Full-Push" and "Blocking"</li>
            </ul>
            Then the model will be more likely to choose pace bowlers AND left
            arm spinners at the death and when the batting team are blocking.
          </p>
        </p>
      ),
      propertyName: "rules",
      type: "bowlerTypeRules",
      constraints: {},
    },
  ],
  RESERVED_BOWLER: [
    {
      humanReadableName: "Award for reserved bowler",
      propertyName: "award",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            Bowlers are reserved through the match settings page. Set this to a
            large number so that reserved bowlers outrank anyone else. Bowlers
            who have been reserved but are ineligible to bowl will be ignored.
          </p>
        </div>
      ),
    },
  ],
  CAPTAIN_SHORT_TERMISM: [
    {
      humanReadableName: "Mean Score for 20 Overs",
      propertyName: "meanScore",
      type: "number",
      constraints: { min: 0, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            Overs are assigned a rough expected run rate based on this mean
            score.
          </p>
          <p>{`Note: This module is only applied to formats with 20 or fewer overs. 
                The expected runs will be taken from over 20 - oversRemaining.
                For this reason do not halve this number for 10 over formats for example.`}</p>
        </div>
      ),
    },
    {
      humanReadableName: "Captain Aggressiveness",
      propertyName: "captainAggressiveness",
      type: "number",
      constraints: { min: -1000000, max: 1000000, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            A higher number here rewards/punishes the players more for
            over/under achieving in their previous over.
          </p>
        </div>
      ),
    },
  ],
  PHASE_PREFERENCE: [
    {
      humanReadableName: "Overuse Penalty",
      propertyName: "overusePenalty",
      type: "number",
      constraints: { min: 0, max: 100, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <p>
            A higher number increases the penalty for bowlers who are overused
            compared to their preferred phases.
          </p>
        </div>
      ),
    },
  ],
};

export function deserializeBowlingAttackModules(
  json: any
): Map<BowlingAttackModuleType, BowlingAttackData> {
  const bowlingAttackModules: Map<BowlingAttackModuleType, BowlingAttackData> =
    new Map();
  Object.keys(BowlingAttackModuleType).forEach((moduleType) => {
    bowlingAttackModules.set(
      BowlingAttackModuleType[moduleType],
      bowlingAttackModuleToDataTypes[BowlingAttackModuleType[moduleType]](
        json[moduleType]
      )
    );
  });
  return bowlingAttackModules;
}

export function serializeBowlingAttackModules(
  bowlingAttackModules: Map<BowlingAttackModuleType, BowlingAttackData>
): any {
  const result: any = {};
  bowlingAttackModules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });
  return result;
}
