import { ReplaySubject, Subject } from "rxjs";
import {
  NodeConfiguration,
  deserializeNodeArray,
  serializeNodeConfiguration,
} from "../types/preferences/node-configuration";
import { SimulatorScenario } from "../types/preferences/preferences";
import { services, showMessage } from "../types/services";
import {
  NodeHealth,
  deserializeNodeHealthMap,
} from "../types/simulator/node-health";
import {
  SimulationResult,
  deserializeSimulationResult,
} from "../types/simulator/simulation-result";
import { PlayerStatsWrapper } from "../types/stats/player-stats";
import { UUID } from "../types/uuid";

import { HttpService } from "./http-service";
import { KeycloakUser } from "./keycloak-service";

interface SimulateWithStatsRequest {
  matchId: string;
  playerId: string;
  playerStats: any;
}

export class SimulationService {
  private httpService: HttpService;
  private latestResults: Map<string, [SimulatorScenario, SimulationResult]>;
  public latestSimulationResultSubject: Subject<
    Map<string, [SimulatorScenario, SimulationResult]>
  >;
  public comparedUserResultsSubject: Subject<
    Map<string, [SimulatorScenario, SimulationResult]>
  >;
  public defaultResultsSubject: Subject<SimulationResult>;
  public defaultResultsLoadingSubject: Subject<boolean>;

  private latestTeamResults: Map<
    string,
    Map<string, [SimulatorScenario, SimulationResult]>
  >;

  public latestTruePriceSimulationResultSubject: Subject<
    [SimulatorScenario, SimulationResult]
  >;
  public latestNodeHealthSubject: Subject<NodeHealth[]>;
  public latestNodeHealthTimeSubject: Subject<number>;
  public latestNodeVersionSubject: Subject<string>;
  private latestNodeHealth: NodeHealth[];
  private latestNodeHealthTime: number;
  private latestNodeVersion: string;
  public loadingSubject: Subject<boolean>;
  public latestSimulationUpdateTimestamp: number = 0;
  public latestSimulationUpdateTimestampForUsers: Map<string, number> =
    new Map();
  public nodeConfigurationSubject: Subject<NodeConfiguration[]>;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
    this.latestResults = new Map();
    this.latestSimulationResultSubject = new ReplaySubject(1);
    this.latestTeamResults = new Map();
    this.comparedUserResultsSubject = new ReplaySubject(1);
    this.comparedUserResultsSubject.next(new Map());
    this.latestTruePriceSimulationResultSubject = new ReplaySubject(1);
    this.latestNodeHealthSubject = new ReplaySubject(1);
    this.latestNodeHealthTimeSubject = new ReplaySubject(1);
    this.latestNodeVersionSubject = new ReplaySubject(1);
    this.loadingSubject = new ReplaySubject(1);
    this.defaultResultsLoadingSubject = new ReplaySubject(1);
    this.defaultResultsSubject = new ReplaySubject(1);
    this.nodeConfigurationSubject = new ReplaySubject(1);
  }

  public init() {
    services.keycloakService.comparedUserSubject.subscribe(
      (comparedUser: KeycloakUser) =>
        this.broadcastComparedResults(comparedUser)
    );
    this.getNodes();
    this.refreshNodeHealth();
  }

  public async simulate() {
    this.loadingSubject.next(true);
    const params: Map<string, string> = new Map();
    params.set("matchId", services.currentGameService.currentMatchId.value);
    this.httpService
      .get("/api/simulator-controller/simulate", params)
      .catch((reason) => {
        showMessage(`Failed to run Simulator: ${reason}`, "error");
      });
  }

  public async simulateWithStats(
    playerId: UUID,
    playerStats: PlayerStatsWrapper
  ) {
    this.loadingSubject.next(true);
    const request: SimulateWithStatsRequest = {
      matchId: services.currentGameService.currentMatchId.value,
      playerId: playerId.value,
      playerStats: PlayerStatsWrapper.serialize(playerStats).playerStats,
    };
    this.httpService
      .post("/api/simulator-controller/simulate-with-stats", request)
      .catch((reason) => {
        showMessage(`Failed to run Simulator: ${reason}`, "error");
      });
  }

  public async simulateDefault(
    useDefaultGroundStats: boolean,
    useDefaultMatchStats: boolean,
    useDefaultTeam1Stats: boolean,
    useDefaultTeam2Stats: boolean
  ) {
    this.defaultResultsLoadingSubject.next(true);
    const defaultSimulationParams = {
      matchId: services.currentGameService.currentMatchId.value,
      useDefaultGroundStats,
      useDefaultMatchStats,
      useDefaultTeam1Stats,
      useDefaultTeam2Stats,
    };
    this.httpService
      .post(
        "/api/simulator-controller/simulate-with-defaults",
        defaultSimulationParams
      )
      .catch((reason) => {
        showMessage(`Failed to run default simulation: ${reason}`, "error");
      });
  }

  public updateNodeHealth(nodeHealthMessage: any) {
    this.latestNodeHealthTime = nodeHealthMessage.createdAt;
    this.latestNodeVersion = nodeHealthMessage.latestVersion;
    this.latestNodeHealth = deserializeNodeHealthMap(nodeHealthMessage.payload);
    this.latestNodeHealthSubject.next(this.latestNodeHealth);
    this.latestNodeHealthTimeSubject.next(this.latestNodeHealthTime);
    this.latestNodeVersionSubject.next(this.latestNodeVersion);
  }

  public getNodes(): Promise<void> {
    return this.httpService
      .get("/api/simulator-controller/nodes")
      .then((response: any) => {
        this.nodeConfigurationSubject.next(deserializeNodeArray(response));
      })
      .catch((reason) => {
        showMessage(`Failed to get nodes: ${reason}`, "error");
      });
  }

  public async refreshNodeHealth() {
    this.httpService
      .get("/api/simulator-controller/node-health")
      .catch((reason) => {
        showMessage(`Failed to get node health: ${reason}`, "error");
        return null;
      });
  }

  public saveNode(nodeConfiguration: NodeConfiguration): Promise<void> {
    return this.httpService
      .post(
        "/api/simulator-controller/save-node",
        serializeNodeConfiguration(nodeConfiguration)
      )
      .then((response: any) => {
        this.nodeConfigurationSubject.next(deserializeNodeArray(response));
      })
      .catch((reason) => {
        showMessage(`Failed to update node: ${reason}`, "error");
      });
  }

  public deleteNode(nodeConfiguration: NodeConfiguration): Promise<void> {
    return this.httpService
      .post(
        "/api/simulator-controller/delete-node",
        serializeNodeConfiguration(nodeConfiguration)
      )
      .then((response: any) => {
        this.nodeConfigurationSubject.next(deserializeNodeArray(response));
      })
      .catch((reason) => {
        showMessage(`Failed to delete node: ${reason}`, "error");
      });
  }

  public broadcast(
    scenario: SimulatorScenario,
    simulationResult: SimulationResult
  ) {
    const scenarioId = scenario.scenarioId;
    if (!scenarioId) {
      this.removeSimulationResults();
    }

    this.latestResults.set(scenarioId || "default", [
      scenario,
      simulationResult,
    ]);
    this.latestSimulationResultSubject.next(this.latestResults);
    if (scenario.scenarioId === null) {
      this.latestTruePriceSimulationResultSubject.next([
        scenario,
        simulationResult,
      ]);
    }
  }

  public broadcastTeamUpdate(
    userId: string,
    scenario: SimulatorScenario,
    simulationResult: SimulationResult
  ) {
    if (!scenario.scenarioId) {
      this.removeUserSimulationResults(userId);
    }

    const thisUserResults: Map<string, [SimulatorScenario, SimulationResult]> =
      this.latestTeamResults.get(userId) || new Map();
    thisUserResults.set(scenario.scenarioId || "default", [
      scenario,
      simulationResult,
    ]);

    this.latestTeamResults.set(userId, thisUserResults);
    this.broadcastComparedResults(services.keycloakService.comparedUser);
  }

  public resetSimulationResultsIfBeforeTime(
    latestGamestateCreationTime: number
  ) {
    if (this.latestSimulationUpdateTimestamp < latestGamestateCreationTime) {
      this.removeSimulationResults();
    }
    this.removeDefaultSimulationResults();
    this.latestSimulationUpdateTimestampForUsers.forEach(
      (timestamp, userId) => {
        if (timestamp < latestGamestateCreationTime) {
          this.removeUserSimulationResults(userId);
        }
      }
    );
  }

  public removeSimulationResults() {
    this.latestResults = new Map();
    this.latestSimulationResultSubject.next(this.latestResults);
    this.latestTruePriceSimulationResultSubject.next(null);
  }

  public removeDefaultSimulationResults() {
    this.defaultResultsSubject.next(null);
  }

  public removeUserSimulationResults(userId: string) {
    this.latestTeamResults.delete(userId);
    this.broadcastComparedResults(services.keycloakService.comparedUser);
  }

  public websocketUpdate(message: any) {
    const matchId = message.matchId;
    const causingUser = message.causingUser;
    const requestTime = message.requestTime;
    const scenario: SimulatorScenario = message.scenario as SimulatorScenario;
    const simulationResult: SimulationResult = deserializeSimulationResult(
      message.simulationResult
    );

    if (this.simulationResultValid(matchId)) {
      if (this.causedByCurrentUser(causingUser)) {
        const defaultSimulation = message.defaultSimulation;
        this.handleUserSimulationResult(
          requestTime,
          simulationResult,
          scenario,
          defaultSimulation
        );
      } else {
        this.handleComparedUserSimulationResult(
          requestTime,
          simulationResult,
          scenario,
          causingUser
        );
      }
    }
  }

  private causedByCurrentUser(causingUser: string): boolean {
    return causingUser === services.keycloakService.getUserId();
  }

  private simulationResultValid(matchId: string): boolean {
    const currentMatchId: string =
      !!services.currentGameService.currentMatchId &&
      services.currentGameService.currentMatchId.value;

    return currentMatchId === matchId;
  }

  private handleUserSimulationResult(
    requestTime: number,
    simulationResult: SimulationResult,
    scenario: SimulatorScenario,
    defaultSimulation: boolean
  ) {
    if (requestTime >= this.latestSimulationUpdateTimestamp) {
      this.latestSimulationUpdateTimestamp = requestTime;
      this.handleSimulationResultErrors(simulationResult, scenario);
      if (defaultSimulation) {
        this.defaultResultsLoadingSubject.next(false);
        this.defaultResultsSubject.next(simulationResult);
      } else {
        this.loadingSubject.next(false);
        this.broadcast(scenario, simulationResult);
      }
    }
  }

  private handleComparedUserSimulationResult(
    requestTime: number,
    simulationResult: SimulationResult,
    scenario: SimulatorScenario,
    causingUser: string
  ) {
    const latestRequestTimeForUser =
      this.latestSimulationUpdateTimestampForUsers.get(causingUser) || 0;
    if (requestTime >= latestRequestTimeForUser) {
      this.latestSimulationUpdateTimestampForUsers.set(
        causingUser,
        requestTime
      );
      this.broadcastTeamUpdate(causingUser, scenario, simulationResult);
    }
  }

  private handleSimulationResultErrors(
    simulationResult: SimulationResult,
    scenario: SimulatorScenario
  ): void {
    if (!!simulationResult.errors && simulationResult.errors.length > 0) {
      showMessage(
        `Simulation Errors for ${
          scenario.name
        } scenario: ${simulationResult.errors.join(", ")}`,
        "warning"
      );
    } else if (
      simulationResult.numberOfSimulations === 0 &&
      !scenario.scenarioId
    ) {
      showMessage("Simulation returned 0 sims", "warning");
    }
  }

  private broadcastComparedResults(comparedUser: KeycloakUser) {
    if (!!comparedUser) {
      this.comparedUserResultsSubject.next(
        this.latestTeamResults.get(comparedUser.id) || new Map()
      );
    } else {
      this.comparedUserResultsSubject.next(new Map());
    }
  }
}
