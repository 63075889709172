import { Component, CSSProperties } from "react";
import { Subscription } from "rxjs";
import { ReactComponent as HoverEditSVG } from "../../../img/hover/edit-hover.svg";
import { ReactComponent as HoverRemoveSVG } from "../../../img/hover/remove-hover.svg";
import { Image } from "../../../types/entities/image";
import { Player } from "../../../types/entities/player";
import { services, showMessage } from "../../../types/services";
import { propsEqual } from "../../component-utils";
import { EditPlayerComponent } from "../../entity-management/entity-editing-dialogs/edit-player-component";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";

interface Props {
  player: Player;
  onUpdate: (player: Player) => void;
  onRemove: (player: Player) => void;
  canEdit: boolean;
  canDelete: boolean;
  imgClasses: string;
  imgHeight: number;
  imgWidth: number;
  showName: boolean;
  removeText: string;
  editText: string;
  width: string;
}

interface State {
  buttonDisplayClass: string;
  playerModalOpen: boolean;
  playerModalLabel: string;
  playerModalButtonText: string;
  imageCropped: string;
}

export abstract class PlayerView extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  static defaultProps = {
    canEdit: true,
    canDelete: true,
    imgClasses: "player-image",
    onUpdate: () => {},
    onRemove: () => {},
    imgHeight: 125,
    imgWidth: 100,
    showName: true,
    removeText: "Remove Player",
    editText: "Edit Player",
    width: "150px",
  };

  public constructor(props) {
    super(props);
    this.state = {
      buttonDisplayClass: "player-select-button-display-hidden",
      playerModalOpen: false,
      playerModalLabel: `Update Player`,
      playerModalButtonText: "UPDATE",
      imageCropped:
        "data:image/png;base64, " +
        services.imageService.getDefaultImage().data,
    };
  }

  componentDidMount(): void {
    if (services.imageService.getImageRecord()) {
      this.subscriptions.push(
        services.imageService.getImageRecord().subscribe(() => {
          this.updateImage();
        })
      );
    }
  }

  componentDidUpdate(prevProps): void {
    if (
      !prevProps ||
      !this.props ||
      !prevProps.player ||
      !this.props.player ||
      !propsEqual(prevProps.player, this.props.player)
    ) {
      this.updateImage();
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateImage() {
    /* Temporary hacky fix: 
    following line stores the player ID from the props at the point this function is called.
     It is a bugfix where long running calls to crop would return images from previous player ids
     and overwrite the latest call. */
    const playerId = this.props.player && this.props.player.playerId;
    services.imageService
      .getImageForPlayer(this.props.player)
      .then((image: Image) => {
        const imageData = image.data.startsWith("data:")
          ? image.data
          : "data:image/png;base64, " + image.data;
        services.imageService
          .crop(imageData, this.props.imgWidth / this.props.imgHeight)
          .then((imageCropped) => {
            /*
            This makes sure the player ID we started this function with is still the player ID we want,
            otherwise do not update the image.
            */
            if (
              playerId &&
              this.props.player &&
              playerId.value === this.props.player.playerId.value
            ) {
              this.setState({ imageCropped });
            }
          });
      });
  }

  private toggleButtons() {
    this.setState({
      buttonDisplayClass:
        this.state.buttonDisplayClass === "player-select-button-display-hidden"
          ? "player-select-button-display-shown"
          : "player-select-button-display-hidden",
    });
  }

  private showButton() {
    this.setState({ buttonDisplayClass: "player-select-button-display-shown" });
  }

  private hideButton() {
    this.setState({
      buttonDisplayClass: "player-select-button-display-hidden",
    });
  }

  private showModal() {
    this.setState({ playerModalOpen: true });
  }

  private onModalProceed(player: Player) {
    services.playerService
      .add(player)
      .then((player) => {
        this.props.onUpdate(player);
        this.onModalCancel();
      })
      .catch((reason) =>
        showMessage(`Failed to add player: ${reason}`, "error")
      );
  }

  private onModalCancel() {
    this.setState({ playerModalOpen: false });
  }

  public render() {
    const imageStyle: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: this.props.width,
      alignItems: "center",
    };
    return (
      <div
        className="player-display-container"
        onMouseEnter={() => this.showButton()}
        onMouseLeave={() => this.hideButton()}
        onClick={() => this.toggleButtons()}
      >
        <div style={imageStyle}>
          <img
            className={this.props.imgClasses}
            alt="player"
            width={this.props.imgWidth}
            height={this.props.imgHeight}
            src={this.state.imageCropped}
          />
          {this.props.showName && (
            <span>{this.props.player && this.props.player.toString()}</span>
          )}
        </div>
        {(this.props.canEdit || this.props.canDelete) && (
          <div className={this.state.buttonDisplayClass}>
            {this.props.canDelete && (
              <TooltipIconButton
                title={this.props.removeText}
                disabled={false}
                onClick={() => this.props.onRemove(this.props.player)}
                icon={<HoverRemoveSVG className="tooltip-icon-button" />}
              />
            )}
            {this.props.canEdit && (
              <TooltipIconButton
                title={this.props.editText}
                disabled={false}
                onClick={() => this.showModal()}
                icon={<HoverEditSVG className="tooltip-icon-button" />}
              />
            )}
          </div>
        )}
        <EditPlayerComponent
          value={this.props.player}
          open={this.state.playerModalOpen}
          label={this.state.playerModalLabel}
          buttonText={this.state.playerModalButtonText}
          isNew={false}
          onProceed={(player) => this.onModalProceed(player)}
          onCancel={() => this.onModalCancel()}
        />
      </div>
    );
  }
}
