import { Component } from "react";
import { KeycloakUser } from "../../services/keycloak-service";
import { TransactionType } from "../../types/enums/transaction-type";
import { PotSummary } from "../../types/investment/pot-summary";
import { Transaction } from "../../types/investment/transaction";
import { services } from "../../types/services";
import { propsEqual } from "../component-utils";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import { PotSummaryPreview } from "./pot-summary-preview";
import { TransactionHistoryList } from "./transaction-history-list";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (
    deposits: Transaction[],
    withdrawals: Transaction[],
    onComplete: () => void
  ) => void;
  transactions: Transaction[];
  investors: KeycloakUser[];
  potSummary: PotSummary;
}

interface State {
  deposits: Transaction[];
  withdrawals: Transaction[];
  potSummaryPreview: PotSummary;
}

export class EndOfTradingPeriodModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      withdrawals: [],
      potSummaryPreview: null,
    };
  }

  componentDidMount(): void {
    this.updateSummaryPreview();
  }

  componentDidUpdate(props): void {
    if (!propsEqual(props, this.props)) {
      this.setState({ deposits: [], withdrawals: [] }, () =>
        this.updateSummaryPreview()
      );
    }
  }

  private updateSummaryPreview() {
    this.setState({ potSummaryPreview: null }, () =>
      services.investmentService
        .getPotSummaryPreview(this.state.deposits, this.state.withdrawals)
        .then((potSummaryPreview: PotSummary) =>
          this.setState({ potSummaryPreview })
        )
    );
  }

  private isUnclearedTransaction(transaction: Transaction): boolean {
    return (
      this.props.transactions
        .filter((t) =>
          [
            TransactionType.DEPOSIT_APPROVAL,
            TransactionType.WITHDRAWAL_APPROVAL,
          ].includes(t.transactionType)
        )
        .find(
          (t) =>
            t.transactionData.requestTransactionId ===
            transaction.transactionId.value
        ) !== undefined &&
      this.props.transactions
        .filter((t) => t.transactionType === TransactionType.END_OF_PERIOD)
        .find(
          (t) =>
            t.transactionData.clearedDeposits.includes(
              transaction.transactionId.value
            ) ||
            t.transactionData.clearedWithdrawals.includes(
              transaction.transactionId.value
            )
        ) === undefined
    );
  }

  private getUnclearedRequests() {
    return this.props.transactions
      .filter((t) =>
        [
          TransactionType.DEPOSIT_REQUEST,
          TransactionType.WITHDRAWAL_REQUEST,
        ].includes(t.transactionType)
      )
      .filter(
        (t) =>
          !this.state.deposits.includes(t) &&
          !this.state.withdrawals.includes(t)
      )
      .filter((t) => this.isUnclearedTransaction(t));
  }

  private clearTransaction(transaction: Transaction) {
    if (transaction.transactionType === TransactionType.WITHDRAWAL_REQUEST) {
      this.setState(
        { withdrawals: [...this.state.withdrawals, transaction] },
        () => this.updateSummaryPreview()
      );
    } else if (
      transaction.transactionType === TransactionType.DEPOSIT_REQUEST
    ) {
      this.setState({ deposits: [...this.state.deposits, transaction] }, () =>
        this.updateSummaryPreview()
      );
    }
  }

  private removeTransaction(transaction: Transaction) {
    if (transaction.transactionType === TransactionType.WITHDRAWAL_REQUEST) {
      this.setState(
        {
          withdrawals: this.state.withdrawals.filter(
            (t) => t.transactionId.value !== transaction.transactionId.value
          ),
        },
        () => this.updateSummaryPreview()
      );
    } else if (
      transaction.transactionType === TransactionType.DEPOSIT_REQUEST
    ) {
      this.setState(
        {
          deposits: this.state.deposits.filter(
            (t) => t.transactionId.value !== transaction.transactionId.value
          ),
        },
        () => this.updateSummaryPreview()
      );
    }
  }

  private cannotProceed(): boolean {
    return (
      !this.state.potSummaryPreview ||
      this.state.potSummaryPreview.potStatus.totalHoldings < 0 ||
      Array.from(
        this.state.potSummaryPreview.investorPotStatusMap.values()
      ).find((investorPotStatus) => investorPotStatus.totalHoldings < 0) !==
        undefined
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"End of Trading Period"}
        disabled={false}
        invalid={this.cannotProceed()}
        invalidText={
          "Cannot continue, cleared deposits/withdrawals cause negative investment"
        }
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(
            this.state.deposits,
            this.state.withdrawals,
            () => this.setState({ deposits: [], withdrawals: [] })
          )
        }
        proceedText="OK"
        colour="#1bc4ab"
      >
        <div className="end-of-period-modal-description">
          Select which approved Deposits/Withdrawals you would like to bring in
          to the next trading period..
        </div>
        <div className="cleared-and-uncleared-transactions-tables">
          <TransactionHistoryList
            title="Uncleared Deposits/Withdrawals"
            investors={this.props.investors}
            allTransactions={this.props.transactions}
            displayedTransactions={this.getUnclearedRequests()}
            canClear={true}
            onClear={(transaction) => this.clearTransaction(transaction)}
            allowToggleTransactionId={false}
          />

          <TransactionHistoryList
            title="Deposits/Withdrawals to Clear"
            investors={this.props.investors}
            allTransactions={this.props.transactions}
            displayedTransactions={[
              ...this.state.deposits,
              ...this.state.withdrawals,
            ].sort((a, b) => b.createdAt - a.createdAt)}
            canDelete={true}
            onDelete={(transaction) => this.removeTransaction(transaction)}
            allowToggleTransactionId={false}
          />
        </div>

        <PotSummaryPreview
          deposits={this.state.deposits}
          withdrawals={this.state.withdrawals}
          investors={this.props.investors}
          potSummaryCurrent={this.props.potSummary}
          potSummaryPreview={this.state.potSummaryPreview}
        />
      </CreationDialog>
    );
  }
}
