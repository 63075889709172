import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import { dismissalMethodNames } from "../../types/enums/dismissal-method";
import { BowlWithExpectationLight } from "../../types/stats/bowl-with-expectation";
import { cleanForCsv, format } from "../simulator-page/simulator-utils";

import PaginationButtons from "./pagination/pagination-buttons";

interface Props {
  balls: BowlWithExpectationLight[];
  displayedModuleType: string;
}

const headers = [
  "Date",
  "Innings",
  "Over",
  "Total Score",
  "Total Wickets",
  "Runs",
  "Extras",
  "Dismissal?",
  "Push",
  "Exp. SR",
  "Exp. W%",
  "Batsman",
  "SR Bias",
  "W% Bias",
  "Bowler",
  "SR Bias",
  "W% Bias",
  "Ground",
  "SR Bias",
  "W% Bias",
  "Weighting",
];

const mapBallToColumnData = (ball: BowlWithExpectationLight) => {
  return [
    new Date(ball.date).toLocaleDateString(),
    ball.innings,
    ball.over,
    ball.score,
    ball.wickets,
    ball.runs,
    ball.extras,
    ball.dismissalMethod ? dismissalMethodNames[ball.dismissalMethod] : "",
    format(ball.push),
    format(ball.strikeRate * 100),
    format(ball.wicketPercent * 100),
    cleanForCsv(ball.batsmanName),
    format(ball.batsmanStrikeRateBias),
    format(ball.batsmanWicketBias),
    cleanForCsv(ball.bowlerName),
    format(ball.bowlerStrikeRateBias),
    format(ball.bowlerWicketBias),
    cleanForCsv(ball.groundName),
    format(ball.groundStrikeRateBias),
    format(ball.groundWicketBias),
    format(ball.groundWeighting),
  ];
};

const createCSVContent = (balls: BowlWithExpectationLight[]) => {
  let csvContent: string = headers.join(",") + "\n";
  balls.forEach((ball) => {
    csvContent = csvContent + mapBallToColumnData(ball).join(",") + "\n";
  });

  return csvContent;
};

export default function BowlWithExpectationTable({
  balls,
  displayedModuleType,
}: Props): React.JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [csvContent, setCsvContent] = useState<string>(null);
  const rowsPerPage = 10;

  useEffect(() => {
    setCsvContent(null);
    setPage(1);
    setCsvContent(createCSVContent(balls));
  }, [balls]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - balls.length) : 0;

  const buildHeader = () => {
    return (
      <TableRow>
        {headers.map((header, index) => (
          <TableCell key={`header-${index}`}>{header}</TableCell>
        ))}
      </TableRow>
    );
  };

  const buildRows = () => {
    let index = 0;
    const rows = (
      rowsPerPage > 0
        ? balls.slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
        : balls
    ).map((ball) => {
      const row = (
        <TableRow key={`row-${index}`}>
          {mapBallToColumnData(ball).map((columnData, index2) => (
            <TableCell key={`row-${index}-col-${index2}`}>
              {columnData}
            </TableCell>
          ))}
        </TableRow>
      );
      index++;

      return row;
    });

    emptyRows > 0 &&
      rows.push(
        <TableRow key={`row-${index}`} style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={headers.length} />
        </TableRow>
      );

    return rows;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        {!csvContent && <CircularProgress />}
        {csvContent && (
          <CSVLink
            filename={`balls-${displayedModuleType}-${new Date().toLocaleString()}.csv`}
            data={csvContent}
            target="_blank"
            disabled={!csvContent}
          >
            Download CSV
          </CSVLink>
        )}
      </div>
      <Table>
        <TableHead>{buildHeader()}</TableHead>
        <TableBody>{buildRows()}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={headers.length}>
              <PaginationButtons
                page={page}
                pageCount={Math.round(balls.length / 10)}
                onChange={(_event, page) => setPage(page)}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
