import { UUID } from "../uuid";
import { BetSource } from "./bet-source";

export interface LastStoredBetScrape {
  userId: UUID;
  scrapeTimesBySource: Map<BetSource, number>;
  alertsEnabledBySource: Map<BetSource, boolean>;
}

export function deserializeLastStoredBetScrape(json: any): LastStoredBetScrape {
  return {
    userId: (json.userId && UUID.fromString(json.userId)) || null,
    scrapeTimesBySource: deserializeBetSourceToNumberMap(
      json.scrapeTimesBySource
    ),
    alertsEnabledBySource: deserializeBetSourceToBooleanMap(
      json.alertsEnabledBySource
    ),
  };
}

export function deserializeBetSourceToNumberMap(
  json: any
): Map<BetSource, number> {
  const map: Map<BetSource, number> = new Map();
  Object.keys(json).forEach((sourceString) => {
    map.set(BetSource[sourceString], Number(json[sourceString]));
  });
  return map;
}

export function deserializeBetSourceToBooleanMap(
  json: any
): Map<BetSource, boolean> {
  const map: Map<BetSource, boolean> = new Map();
  Object.keys(json).forEach((sourceString) => {
    map.set(BetSource[sourceString], Boolean(json[sourceString]));
  });
  return map;
}
