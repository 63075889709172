import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Component } from "react";
import { InvestorPotStatus } from "../../types/investment/pot-summary";
import { services } from "../../types/services";
import NumberSelector from "../entity-management/entity-selectors/number-selector";

interface Props {
  potStatus: InvestorPotStatus;
}

interface State {
  depositAmount: number;
  desiredDepositDate: number;
  withdrawalAmount: number;
  desiredWithdrawalDate: number;
}

export class DepositAndWithdrawalRequestComponent extends Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      depositAmount: 0,
      desiredDepositDate: Number(new Date()),
      withdrawalAmount: 0,
      desiredWithdrawalDate: Number(new Date()),
    };
  }

  private canDeposit() {
    return !!this.state.depositAmount && !!this.state.desiredDepositDate;
  }

  private resetDepositData() {
    return this.setState({
      depositAmount: 0,
      desiredDepositDate: Number(new Date()),
    });
  }

  private canWithdraw() {
    return !!this.state.withdrawalAmount && !!this.state.desiredWithdrawalDate;
  }

  private resetWithdrawalData() {
    return this.setState({
      withdrawalAmount: 0,
      desiredWithdrawalDate: Number(new Date()),
    });
  }

  private getMaximumWithdrawal() {
    return (
      this.props.potStatus.totalHoldings +
      this.props.potStatus.totalRequestedInvestment
    );
  }

  public render() {
    return (
      <div className="deposit-and-withdrawal">
        <div className="deposit-and-withdrawal-components">
          <div className="investor-page-title">Deposit</div>
          <div className="amount-selector">
            <div>Amount:</div>
            <NumberSelector
              min={0}
              max={1000000}
              step={1}
              decimalPlaces={2}
              initial={this.state.depositAmount}
              onValid={(valid: number) =>
                this.setState({ depositAmount: valid })
              }
            />
          </div>
          <div className="amount-selector">
            Desired Date:
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={this.state.desiredDepositDate}
                onChange={(newValue) =>
                  this.setState({ desiredDepositDate: Number(newValue) })
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <Button
            onClick={() =>
              services.investmentService
                .depositRequest(
                  this.state.depositAmount,
                  this.state.desiredDepositDate
                )
                .then(() => this.resetDepositData())
            }
            disabled={!this.canDeposit()}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
        </div>

        <div className="deposit-and-withdrawal-components">
          <div className="investor-page-title">Withdraw</div>
          <div className="amount-selector">
            <div>Amount:</div>
            <NumberSelector
              min={0}
              max={this.getMaximumWithdrawal()}
              step={1}
              decimalPlaces={2}
              initial={this.state.withdrawalAmount}
              onValid={(valid: number) =>
                this.setState({ withdrawalAmount: valid })
              }
            />
          </div>
          <div className="amount-selector">
            <div>Desired Date:</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={this.state.desiredWithdrawalDate}
                onChange={(newValue) =>
                  this.setState({ desiredWithdrawalDate: Number(newValue) })
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <Button
            onClick={() =>
              services.investmentService
                .withdrawalRequest(
                  this.state.withdrawalAmount,
                  this.state.desiredWithdrawalDate
                )
                .then(() => this.resetWithdrawalData())
            }
            disabled={!this.canWithdraw()}
            variant="contained"
            color="primary"
          >
            Go
          </Button>
        </div>
      </div>
    );
  }
}
