import { Player } from "../../types/entities/player";
import { Team } from "../../types/entities/team";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import {
  TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP,
  TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX,
  TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN,
  TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP,
} from "../entity-management/entity-selectors/player-selector-global-biases-component";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import { NumberComparison } from "../stats-editing-components/number-comparison";

interface Props {
  team: Team;
  player: Player;
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  strikeRateProperty: string;
  wicketPercentProperty: string;
  comparison: boolean;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

export default function PlayerTemporaryAdjustmentsComponent({
  team,
  player,
  currentStats,
  comparedStats,
  strikeRateProperty,
  wicketPercentProperty,
  comparison,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const updateMatchStats = (property: string, value: number) => {
    const updatedStats = MatchStatsWrapper.clone(currentStats);
    updatedStats.matchStats[property]
      .get(team.teamId.value)
      .set(player.playerId.value, value);
    onUpdate(updatedStats);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "1em",
        paddingLeft: "1em",
        paddingRight: "1em",
        alignItems: "center",
        width: "100%",
      }}
    >
      <PlayerView
        imgClasses={"scorecard-image"}
        player={player}
        canEdit={false}
        canDelete={false}
        showName={false}
        imgHeight={80}
        imgWidth={60}
        width={"60px"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1em",
          width: "100%",
        }}
      >
        <div>{player.longName}</div>
        {!comparison && (
          <>
            <NumberSelector
              label={"SR"}
              min={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN}
              max={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX}
              step={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP}
              decimalPlaces={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP}
              initial={
                currentStats.matchStats[strikeRateProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
              onValid={(value: number) =>
                updateMatchStats(strikeRateProperty, value)
              }
            />
            <NumberSelector
              label={"W%"}
              min={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MIN}
              max={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_MAX}
              step={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_STEP}
              decimalPlaces={TEMPORARY_ADJUSTMENT_NUMBER_SELECTOR_DP}
              initial={
                currentStats.matchStats[wicketPercentProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
              onValid={(value: number) =>
                updateMatchStats(wicketPercentProperty, value)
              }
            />
          </>
        )}
        {comparison && (
          <div>
            <NumberComparison
              label={"SR"}
              originalValue={
                currentStats.matchStats[strikeRateProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
              comparedValue={
                comparedStats.matchStats[strikeRateProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
            />
            <NumberComparison
              label={"W%"}
              originalValue={
                currentStats.matchStats[wicketPercentProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
              comparedValue={
                comparedStats.matchStats[wicketPercentProperty]
                  .get(team.teamId.value)
                  .get(player.playerId.value) || 0
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
