import { Player } from "../../../types/entities/player";

import { PlayerView } from "./player-view";

interface Props {
  players: Player[];
  onRemove: (player: Player) => void;
  onUpdate: (player: Player) => void;
}

export default function SquadView({
  players,
  onRemove,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  return (
    <div className="squad-selection-display">
      {players?.map((player, index) => (
        <PlayerView
          player={player}
          key={`player-${index}`}
          onRemove={(player) => onRemove(player)}
          onUpdate={(player) => onUpdate(player)}
        />
      ))}
      {(players === null || players.length === 0) && (
        <span className="italic">Add players using the box above.</span>
      )}
    </div>
  );
}
