import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../../types/enums/push-bracket";
import { BiasByPushBracketData } from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const pushBracketDataComponent = (data: BiasByPushBracketData) => {
  const headers: string[] = [
    "Phase",
    "Actual",
    "Expected",
    "Bias",
    "Balls",
    "Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(PushBracket).map((pushBracket: string, index: number) => (
          <TableRow key={pushBracket}>
            <TableCell>
              {humanReadablePushBrackets[PushBracket[pushBracket]]}
            </TableCell>
            <TableCell>{format(data.actual[index])}</TableCell>
            <TableCell>{format(data.expected[index])}</TableCell>
            <TableCell>
              {format(data.actual[index] / data.expected[index])}
            </TableCell>
            <TableCell>{format(data.occurrences[index])}</TableCell>
            <TableCell>{format(data.weight[index])}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
