import { ReactNode, useEffect, useState } from "react";
import { Observable } from "rxjs";

import { Player } from "../../../types/entities/player";
import { services, showMessage } from "../../../types/services";
import { getLogo } from "../../component-utils";
import { EditPlayerComponent } from "../entity-editing-dialogs/edit-player-component";

import { EntityAutoSelector, Option } from "./entity-auto-selector";

function defaultRenderer(props, option: Option<Player>) {
  const player = option.object as Player;
  const logo = getLogo(player);

  if (!option.new) {
    return (
      <div {...props}>
        {logo}
        {player.toString()}
      </div>
    );
  } else {
    return (
      <div {...props}>
        {logo}
        Add player: {player.toString()}
      </div>
    );
  }
}

interface Props {
  value: Player;
  onSelect: Function;
  options: Observable<Player[]>;
  renderer?: (props: any, player: Option<Player>) => ReactNode;
  classes?: string;
  title?: string;
  canAddNew?: boolean;
  canClear?: boolean;
}

export function PlayerSelector({
  value,
  onSelect,
  options,
  renderer = defaultRenderer,
  classes = "",
  title = null,
  canAddNew = true,
  canClear = true,
}: Props): React.JSX.Element {
  const [playerModalOpen, setPlayerModalOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<Player>(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  function onModalProceed(player: Player) {
    services.playerService
      .add(player)
      .then((player) => {
        onSelect(player);
        onModalCancel();
      })
      .catch((reason) =>
        showMessage(`Failed to add player: ${reason}`, "error")
      );
  }

  function onModalCancel() {
    setPlayerModalOpen(false);
  }

  return (
    <div className={classes}>
      <EntityAutoSelector
        label={title}
        options={options}
        optionService={services.playerService}
        onSelect={(value: Player) => onSelect(value)}
        onCreateOrEdit={(isNew: boolean, value: Player) => {
          setCurrentValue(value);
          setPlayerModalOpen(true);
        }}
        canAddNew={canAddNew}
        canClear={canClear}
        canEdit={false}
        value={value}
        renderer={(props, player) => renderer(props, player)}
      />
      <EditPlayerComponent
        value={currentValue}
        open={playerModalOpen}
        label="Create Player"
        buttonText="CREATE"
        isNew={true}
        onProceed={(player) => onModalProceed(player)}
        onCancel={() => onModalCancel()}
      />
    </div>
  );
}
