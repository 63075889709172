export enum BetfairStatus {
  MATCH_SCHEDULED = "MATCH_SCHEDULED",
  MATCH_STABLE = "MATCH_STABLE",
  BALL_IN_PROGRESS = "BALL_IN_PROGRESS",
  RUNS_LIKELY = "RUNS_LIKELY",
  POSSIBLE_WICKET = "POSSIBLE_WICKET",
  UMPIRE_REVIEW = "UMPIRE_REVIEW",
  BREAK_IN_PLAY = "BREAK_IN_PLAY",
  DATA_ASSEMBLY_ISSUE = "DATA_ASSEMBLY_ISSUE",
  SUPER_OVER = "SUPER_OVER",
  MATCH_COMPLETE = "MATCH_COMPLETE",
  EVENT_CLOSED = "EVENT_CLOSED",
  PAUSE = "PAUSE",
  UNKNOWN = "UNKNOWN",
}

export const betfairStatusNames: Record<BetfairStatus, string> = {
  MATCH_SCHEDULED: "Play has not begun",
  MATCH_STABLE: "Match in progress",
  BALL_IN_PROGRESS: "Bowler running in",
  RUNS_LIKELY: "Possible boundary",
  POSSIBLE_WICKET: "Possible wicket",
  UMPIRE_REVIEW: "Umpire review",
  BREAK_IN_PLAY: "Break in play",
  DATA_ASSEMBLY_ISSUE: "Score correction or sources temporarily unusable",
  SUPER_OVER: "Super Over",
  MATCH_COMPLETE: "Match has finished",
  EVENT_CLOSED: "Safe to disconnect",
  PAUSE: "Data may be out of sync",
  UNKNOWN: "Outcome of delivery is not immediately known",
};

export const betfairStatusColours: Record<BetfairStatus, string> = {
  MATCH_SCHEDULED: "#a8ffaa",
  MATCH_STABLE: "rgba(253, 182, 60, 1)",
  BALL_IN_PROGRESS: "rgba(253, 182, 60, 0.5)",
  RUNS_LIKELY: "#a8e2ff",
  POSSIBLE_WICKET: "#ffa8a8",
  UMPIRE_REVIEW: "#ffd37a",
  BREAK_IN_PLAY: "#f0adff",
  DATA_ASSEMBLY_ISSUE: "#ffffff",
  SUPER_OVER: "#ffc9ed",
  MATCH_COMPLETE: "#ccecff",
  EVENT_CLOSED: "#a8ffaa",
  PAUSE: "#ffffff",
  UNKNOWN: "#f0ffcc",
};

export interface BetfairSubscription {
  eventId: string;
  status: SubscriptionStatus;
}

export enum SubscriptionStatus {
  LIVE = "LIVE",
  PAUSED = "PAUSED",
}
