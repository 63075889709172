import { Alert, AlertColor } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  severity?: AlertColor;
  children?: ReactNode;
};

export default function AppAlert({
  severity = "info",
  children,
}: Props): React.JSX.Element {
  return (
    <Alert
      style={{
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "1rem",
      }}
      severity={severity}
    >
      {children}
    </Alert>
  );
}
