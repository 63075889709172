import { Component, ReactNode } from "react";
import { observableFrom, propsEqual } from "../component-utils";
import { MatchFormat } from "../../types/entities/match-format";
import { GameState } from "../../types/entities/game-state";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { Player } from "../../types/entities/player";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { UUID } from "../../types/uuid";
import { PlayerSelector } from "../entity-management/entity-selectors/player-selector";

interface Props {
  originalStats: MatchStatsWrapper;
  matchFormat: MatchFormat;
  gameState: GameState;
  bowlingTeamId: UUID;
  bowlingTeam: Player[];
  innings: number;
  onUpdate: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
  disabled: boolean;
}

interface State {
  currentStats: MatchStatsWrapper;
}

export class SurgeReservationInningsComponent extends Component<Props, State> {
  private static readonly DEFAULT_STATE = {
    currentStats: null,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...SurgeReservationInningsComponent.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private updateState() {
    this.setState({
      currentStats: MatchStatsWrapper.clone(this.props.originalStats),
    });
  }

  private removeReservation(playerId: UUID) {
    const newValues = this.state.currentStats.matchStats.surgeBowlerReservations
      .get(this.props.bowlingTeamId.value)
      .filter((e) => !!playerId && e !== playerId.value);
    this.state.currentStats.matchStats.surgeBowlerReservations.set(
      this.props.bowlingTeamId.value,
      newValues
    );
    this.props.onUpdate(this.state.currentStats, true);
  }

  private addReservation(playerId: UUID) {
    const newValues: string[] =
      this.state.currentStats.matchStats.surgeBowlerReservations.get(
        this.props.bowlingTeamId.value
      );
    newValues.push(playerId.value);
    this.state.currentStats.matchStats.surgeBowlerReservations.set(
      this.props.bowlingTeamId.value,
      newValues
    );
    this.props.onUpdate(this.state.currentStats, true);
  }

  public render() {
    const reservations: ReactNode[] = [];
    !!this.state.currentStats &&
      !!this.props.bowlingTeamId &&
      this.state.currentStats.matchStats.surgeBowlerReservations
        .get(this.props.bowlingTeamId.value)
        .forEach((playerId, index) => {
          const player: Player =
            this.props.bowlingTeam &&
            this.props.bowlingTeam.find(
              (player) => player.playerId.value === playerId
            );
          reservations.push(
            <div
              key={`reservation-row-${index}`}
              className="bowler-reservation-row"
            >
              <div>{player.longName}</div>
              <div className="bowler-reservation-player-view-and-buttons">
                <PlayerView
                  imgClasses={"scorecard-image"}
                  player={player}
                  canEdit={false}
                  canDelete={false}
                  showName={false}
                  imgHeight={80}
                  imgWidth={60}
                  width={"60px"}
                />
                <div className="bowler-reservation-buttons">
                  {!this.props.disabled && (
                    <TooltipIconButton
                      onClick={() => this.removeReservation(player.playerId)}
                      icon="delete"
                      title="Remove Reservation"
                      colour="#888888"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        });

    const currentSelection: string[] =
      !!this.state.currentStats &&
      this.state.currentStats.matchStats.surgeBowlerReservations.get(
        this.props.bowlingTeamId.value
      );

    return (
      <div className="bowler-reservation-innings-component">
        {!this.props.disabled && (
          <PlayerSelector
            value={null}
            onSelect={(player: Player) => this.addReservation(player.playerId)}
            options={observableFrom(
              this.props.bowlingTeam.filter(
                (p) =>
                  !!currentSelection &&
                  currentSelection.find(
                    (playerId) => p.playerId.value === playerId
                  ) === undefined
              )
            )}
          />
        )}
        {reservations.length > 0 && reservations}
        {reservations.length === 0 && <div>No bowler reservations</div>}
      </div>
    );
  }
}
