import { UUID } from "../uuid";
import { Entity } from "./entity";
import { Country, countryNames } from "../enums/country";

export class Ground implements Entity {
  public entityId: UUID;
  public groundId: UUID;
  public name: string;
  public town: string;
  public latitude: number;
  public longitude: number;
  public timeZone: string;
  public gmtOffset: number;
  public country: Country;
  public createdBy: UUID;
  public createdAt: number;

  constructor(
    entityId: UUID,
    groundId: UUID,
    name: string,
    country: Country,
    town: string,
    latitude: number,
    longitude: number,
    timeZone: string,
    gmtOffset: number,
    createdBy: UUID,
    createdAt: number
  ) {
    this.entityId = entityId;
    this.groundId = groundId;
    this.name = name;
    this.country = country;
    this.town = town;
    this.latitude = latitude;
    this.longitude = longitude;
    this.timeZone = timeZone;
    this.gmtOffset = gmtOffset;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }

  public toString(): string {
    return (
      this.name +
      (!!this.country ? " (" + countryNames[this.country] + ")" : "")
    );
  }

  public static deserializeList(json: any) {
    const grounds: Ground[] = [];
    json.forEach((element) => {
      grounds.push(this.deserializeOne(element));
    });
    return grounds;
  }

  public static deserializeOne(responseJSON: any): Ground {
    return new Ground(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.groundId),
      responseJSON.name,
      responseJSON.country,
      responseJSON.town,
      responseJSON.latitude,
      responseJSON.longitude,
      responseJSON.timeZone,
      responseJSON.gmtOffset,
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt
    );
  }

  public static emptyWithName(name: string): Ground {
    return new Ground(
      null,
      null,
      name,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  public static getTypeName(): string {
    return "ground";
  }

  public static serialize(ground: Ground): any {
    return {
      entityId: ground.entityId === null ? null : ground.entityId.value,
      groundId: ground.groundId === null ? null : ground.groundId.value,
      name: ground.name,
      country: ground.country,
      town: ground.town,
      latitude: ground.latitude,
      longitude: ground.longitude,
      timeZone: ground.timeZone,
      gmtOffset: ground.gmtOffset,
      createdBy: ground.createdBy === null ? null : ground.createdBy.value,
      createdAt: ground.createdAt,
    };
  }
}
