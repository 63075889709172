import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  BowlerSpeciality,
  bowlerSpecialityNames,
} from "../../../types/enums/bowler-speciality";
import { BowlerSpecialityPlayerFormData } from "../../../types/form/player-form-modules";

export const bowlerSpecialityDataComponent = (
  data: BowlerSpecialityPlayerFormData
) => {
  const headers: string[] = [
    ...Object.values(bowlerSpecialityNames),
    "Matches",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {Object.keys(BowlerSpeciality).map((key) => {
            const bowlerSpeciality = BowlerSpeciality[key];
            return (
              <TableCell key={bowlerSpeciality}>
                {data.totalOccurrences.get(bowlerSpeciality)}
              </TableCell>
            );
          })}
          <TableCell>{data.matches}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
