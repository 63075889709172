import React from "react";

import { UserPreferences } from "../../types/preferences/preferences";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { BattingStatByPushDisplay } from "./batting-stat-by-push-display";
import { OpponentSkillStats } from "./opponent-skill-stats";

type Props = {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  userPreferences: UserPreferences;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
};

const battingStatsByPushConfig = [
  { title: "Blocking", index: 0, classes: "blocking" },
  { title: "Defensive", index: 1, classes: "defensive" },
  { title: "Normal", index: 2, classes: "normal" },
  { title: "Pushing", index: 3, classes: "pushing" },
  { title: "Full Push", index: 4, classes: "full-push" },
];

export default function BattingStatsByPush({
  currentStats,
  comparedStats,
  comparedUserName,
  userPreferences,
  onArrayElementValid,
  onUpdate,
}: Props): React.JSX.Element {
  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">
        Multipliers at Aggression Levels (Each 20th Percentile)
      </div>
      <div className="batting-stats-modal-6-columns">
        {battingStatsByPushConfig.map(({ title, index, classes }) => (
          <BattingStatByPushDisplay
            key={title}
            title={title}
            index={index}
            currentStats={currentStats}
            comparedStats={comparedStats}
            comparedUserName={comparedUserName}
            userPreferences={userPreferences}
            onArrayElementValid={onArrayElementValid}
            classes={classes}
            onUpdate={onUpdate}
          />
        ))}

        <OpponentSkillStats
          currentStats={currentStats}
          comparedStats={comparedStats}
          comparedUserName={comparedUserName}
          title="Historic Averages"
          classes="global-sr-and-wpc"
          groundStrikeRateProperty="battingAverageGroundSRBias"
          groundWicketPercentProperty="battingAverageGroundWicketBias"
          opponentStrikeRateProperty="battingAverageOpponentSRBias"
          opponentWicketPercentProperty="battingAverageOpponentWicketBias"
          opponentType="Bowler"
        />
      </div>
    </div>
  );
}
