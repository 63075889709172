import { Tooltip } from "@mui/material";
import { Component } from "react";
import { Ball } from "../../../types/entities/ball";
import { MatchFormat } from "../../../types/entities/match-format";
import { Squad } from "../../../types/entities/squad";
import { calculateBallText } from "../bowl-input/bowl-input-templates";
import { getTitleColour } from "../game-state-display/tsbc";
import { OverSummary } from "../../../types/simulator/simulation-result";

interface Props {
  ball: Ball;
  overToggle: boolean;
  overSummary: OverSummary;
  squad1: Squad;
  squad2: Squad;
  matchFormat: MatchFormat;
}

export class BallDisplay extends Component<Props> {
  private calculateFillColour(): string {
    if (this.props.ball.dismissal) {
      return "#cc0000";
    }
    switch (this.props.ball.runs + this.props.ball.extras) {
      case 0:
        return "#ffffff";
      case 1:
        return "#ccccff";
      case 2:
        return "#aaaaff";
      case 3:
        return "#7777ff";
      case 4:
        return "#4444ff";
      case 5:
        return "#4444ff";
      case 6:
        return "#0000ff";
      default:
        return "#0000ff";
    }
  }

  public render() {
    const fillColour = this.calculateFillColour();
    const strokeColour = getTitleColour(fillColour);
    const ballInSurge =
      !!this.props.overSummary && this.props.overSummary.surge;

    const bowlerInSquad1: boolean =
      this.props.squad1 &&
      this.props.squad1.players &&
      this.props.squad1.players.find(
        (p) => p.playerId.value === this.props.ball.bowler.value
      ) !== undefined;
    const battingTeam: number = bowlerInSquad1 ? 2 : 1;
    return (
      <div>
        <Tooltip
          title={calculateBallText(
            this.props.ball,
            battingTeam,
            this.props.squad1,
            this.props.squad2,
            this.props.matchFormat
          )}
        >
          <div
            className={
              "ball-display" +
              (ballInSurge
                ? this.props.overToggle
                  ? " over-even-surge"
                  : " over-odd-surge"
                : this.props.overToggle
                ? " over-even"
                : " over-odd")
            }
          >
            <svg viewBox="0 0 64 64">
              <g id="ball">
                <circle cx="32" cy="37" r="20" fill={fillColour} />
                <text
                  x="50%"
                  y="13px"
                  textAnchor="middle"
                  fill={"#555"}
                  fontSize="8px"
                >
                  {this.props.ball.over}
                </text>
                {(this.props.ball.dismissal ||
                  this.props.ball.runs + this.props.ball.extras !== 0) && (
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    fill={strokeColour}
                    dy=".6em"
                  >
                    {this.props.ball.dismissal
                      ? "W"
                      : this.props.ball.runs + this.props.ball.extras}
                  </text>
                )}
                {!this.props.ball.dismissal &&
                  this.props.ball.runs + this.props.ball.extras === 0 && (
                    <circle cx="32" cy="37" r="2" fill={"#000"} />
                  )}
              </g>
              {(this.props.ball.noBall || this.props.ball.wide) && (
                <g id="no-ball-wide-berry">
                  <circle cx="48" cy="48" r="10" fill={"#fcc"} />
                  <text
                    x="75%"
                    y="75%"
                    textAnchor="middle"
                    fontSize="10px"
                    fill="#555"
                    dy=".3em"
                  >
                    {this.props.ball.wide ? "wd" : "nb"}
                  </text>
                </g>
              )}
              {(this.props.ball.legBye || this.props.ball.bye) && (
                <g id="byes-berry">
                  <circle cx="16" cy="48" r="10" fill={"#cfc"} />
                  <text
                    x="25%"
                    y="75%"
                    textAnchor="middle"
                    fontSize="10px"
                    fill="#555"
                    dy=".3em"
                  >
                    {this.props.ball.legBye ? "lb" : "b"}
                  </text>
                </g>
              )}
            </svg>
          </div>
        </Tooltip>
      </div>
    );
  }
}
