import { ReplaySubject, Subject } from "rxjs";
import { getHomepageRoute } from "../components/component-utils";
import keycloak from "../keycloak";
import { services, showMessage } from "../types/services";
import { HttpService } from "./http-service";

export interface KeycloakUser {
  id: string;
  name: string;
  email: string;
  attributes: Map<string, string[]>;
  roles: string[];
}

const onAuthSuccess = () => {
  services.refreshServices();
  updateWebsocketUrl();
};

const updateWebsocketUrl = () => {
  services.webSocketService.updateUrl(
    `${
      services.httpService.baseUrl
    }/api/full-push-websockets?access_token=${services.keycloakService.getAuthToken()}`
  );
};

export const initKeycloak = (renderComponentsCallback) => {
  keycloak.onAuthSuccess = onAuthSuccess;
  keycloak.onAuthRefreshSuccess = updateWebsocketUrl;
  keycloak.onTokenExpired = () => services.keycloakService.refresh();

  keycloak
    .init({
      onLoad: "check-sso",
    })
    .then(() => {
      if (
        services.history.location.pathname === "/" &&
        keycloak.authenticated
      ) {
        services.history.push(getHomepageRoute());
        services.history.goForward();
      }
      renderComponentsCallback();
    })
    .catch(console.error);
};

export class KeycloakService {
  private httpService: HttpService;

  public knownUsersSubject: Subject<KeycloakUser[]> = new ReplaySubject<
    KeycloakUser[]
  >(1);
  private knownUsers: KeycloakUser[];
  public knownSimulatorsSubject: Subject<KeycloakUser[]> = new ReplaySubject<
    KeycloakUser[]
  >(1);
  private knownSimulators: KeycloakUser[];

  public knownInvestorsSubject: Subject<KeycloakUser[]> = new ReplaySubject<
    KeycloakUser[]
  >(1);
  private knownInvestors: KeycloakUser[];

  public comparedUser: KeycloakUser;
  public comparedUserSubject: Subject<KeycloakUser> =
    new ReplaySubject<KeycloakUser>(1);

  constructor(httpService: HttpService) {
    this.httpService = httpService;
    this.knownUsers = [];
    this.knownInvestors = [];
  }

  public init() {
    this.comparedUser = null;
    this.comparedUserSubject.next(null);
  }

  public updateKnownUsers() {
    if (this.isUser()) {
      this.httpService
        .get(`/api/user-controller/get-known-users`)
        .then((response: any[]) => {
          this.knownUsers = this.deserializeUsers(response);
          this.knownUsersSubject.next(this.knownUsers);
        })
        .catch((reason) => {
          showMessage(`Failed to get users: ${reason}`, "error");
        });
    }
  }

  public updateKnownSimulators() {
    if (this.isUser() || this.isBetBuilder()) {
      this.httpService
        .get(`/api/user-controller/get-known-simulators`)
        .then((response: any[]) => {
          this.knownSimulators = this.deserializeUsers(response);
          this.knownSimulatorsSubject.next(this.knownSimulators);
        })
        .catch((reason) => {
          showMessage(`Failed to get simulators: ${reason}`, "error");
        });
    }
  }

  public updateInvestors() {
    if (this.isInvestorAdmin()) {
      this.httpService
        .get(`/api/user-controller/get-investors`)
        .then((response: any[]) => {
          this.knownInvestors = this.deserializeUsers(response);
          this.knownInvestorsSubject.next(this.knownInvestors);
        })
        .catch((reason) => {
          showMessage(`Failed to get investors list: ${reason}`, "error");
        });
    }
  }

  public handleComparedUserSelection(userId) {
    if (!!userId && userId !== "") {
      for (const user of this.knownUsers) {
        if (user.id === userId) {
          this.comparedUser = user;
          break;
        }
      }
    } else {
      this.comparedUser = null;
    }
    this.comparedUserSubject.next(this.comparedUser);
  }

  public logout(): void {
    this.httpService.get("/api/logout");
    keycloak.logout();
  }

  public getUsers(): KeycloakUser[] {
    return this.knownUsers;
  }

  public accountPageLink(): string {
    return (
      this.httpService.baseKeycloakUrl +
      "/realms/" +
      this.httpService.realm +
      "/account/"
    );
  }

  public login(): void {
    keycloak.login();
  }

  public refresh(): Promise<boolean> {
    return keycloak.updateToken(5).catch(() => {
      showMessage(
        "Failed to refresh the token, or the session has expired",
        "error"
      );
      keycloak.login();
      return false;
    });
  }

  public getUserName(): string {
    return keycloak.idTokenParsed && keycloak.idTokenParsed.name;
  }

  public getUserId(): string {
    return keycloak.idTokenParsed && keycloak.idTokenParsed.sub;
  }

  public getAuthToken() {
    return keycloak && keycloak.token;
  }

  public getInvestorType(user: KeycloakUser) {
    if (!!user.roles) {
      return user.roles.includes("developer")
        ? "Developer"
        : user.roles.includes("investor_admin") ||
          user.roles.includes("simulator")
        ? "Trader"
        : user.roles.includes("investor")
        ? "Investor"
        : "Unknown";
    } else {
      return "Unknown";
    }
  }

  public isAuthenticated() {
    return keycloak && keycloak.authenticated;
  }

  public isUser() {
    return this.hasRole("user");
  }

  public isBetBuilder() {
    return this.hasRole("bet_builder");
  }

  public isBetfair() {
    return this.hasRole("betfair");
  }

  public isInvestor() {
    return this.hasRole("investor");
  }

  public isInvestorAdmin() {
    return this.hasRole("investor_admin");
  }

  public isAdmin() {
    return this.hasRole("employee");
  }

  public hasRole(role: string) {
    return !!keycloak && keycloak.hasRealmRole(role);
  }

  private deserializeUsers(json: any): KeycloakUser[] {
    const users: KeycloakUser[] = [];
    json.forEach((element: any) => {
      users.push({
        id: element.id,
        name: element.name,
        email: element.email,
        attributes: this.deserializeAttributes(element.attributes),
        roles: element.roles,
      });
    });
    return users;
  }

  private deserializeAttributes(json: any): Map<string, string[]> {
    const attributes: Map<string, string[]> = new Map();
    json &&
      Object.keys(json).forEach((key: any) => {
        attributes.set(key, json[key] as string[]);
      });
    return attributes;
  }
}
