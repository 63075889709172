import {
  InningsRunsBetData,
  MatchOddsBetData,
  SessionBetData,
} from "../../types/stored-bets/bet-data";
import { StoredBet } from "../../types/stored-bets/stored-bet";
import {
  MatchOdd,
  RunsLineOdd,
  StoredGameStateOdds,
} from "../../types/stored-bets/stored-odds";

export type MatchOddsType = "simulatorMatchOdds" | "betfairMatchOdds";
export type RunsLineOddsType = "simulatorRunsLineOdds" | "betfairRunsLineOdds";

export function getMatchOdds(
  bet: StoredBet,
  gameStateOdds: StoredGameStateOdds,
  type: MatchOddsType
): MatchOdd {
  const teamId = (bet.betData as MatchOddsBetData).teamId;

  return (
    gameStateOdds[type]?.find(
      (matchOdds) => matchOdds.teamId === teamId.value
    ) || null
  );
}

export function getInningsRunsLineOdds(
  bet: StoredBet,
  gameStateOdds: StoredGameStateOdds,
  type: RunsLineOddsType
): RunsLineOdd {
  const inningsRunsBetData = bet.betData as InningsRunsBetData;
  const innings = inningsRunsBetData.innings;

  return (
    gameStateOdds[type]?.get(innings)?.reduce((maxOdds, currentOdds) => {
      return currentOdds.overs > (maxOdds?.overs || 0) ? currentOdds : maxOdds;
    }, null) || null
  );
}

export function getSessionRunsLineOdds(
  bet: StoredBet,
  gameStateOdds: StoredGameStateOdds,
  type: RunsLineOddsType
): RunsLineOdd {
  const sessionBetData = bet.betData as SessionBetData;
  let innings;
  if (sessionBetData.teamId === null) {
    innings = Number(sessionBetData.teamName.substring(0, 1));
  } else {
    innings = gameStateOdds.team1Id === sessionBetData.teamId.value ? 1 : 2;
  }

  return (
    gameStateOdds[type]
      ?.get(innings)
      ?.find((odds) => odds.overs === sessionBetData.overHigh) || null
  );
}
