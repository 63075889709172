import { Component } from "react";
import { KeycloakUser } from "../../services/keycloak-service";
import { TransactionType } from "../../types/enums/transaction-type";
import { PotSummary } from "../../types/investment/pot-summary";
import { Transaction } from "../../types/investment/transaction";
import { services } from "../../types/services";
import { getDirection } from "../component-utils";
import { formatCurrency } from "../simulator-page/simulator-utils";
import { EndOfTradingPeriodModal } from "./end-of-trading-period-modal";
import { Button } from "@mui/material";

interface Props {
  potSummary: PotSummary;
  transactions: Transaction[];
  investors: KeycloakUser[];
}

interface State {
  endOfPeriodModalOpen: boolean;
}

export class PotSummaryDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      endOfPeriodModalOpen: false,
    };
  }

  private getLatestEndOfPeriod() {
    const inOrder = this.props.transactions
      .filter((t) => t.transactionType === TransactionType.END_OF_PERIOD)
      .sort((a, b) => b.createdAt - a.createdAt);
    return inOrder.length > 0 ? (
      <div className="trading-period-label">
        Trading Period from {new Date(inOrder[0].createdAt).toDateString()}
      </div>
    ) : (
      <div className="trading-period-label"></div>
    );
  }

  public render() {
    const totalHoldings =
      this.props.potSummary && this.props.potSummary.potStatus.totalHoldings;
    const totalDeposits =
      this.props.potSummary && this.props.potSummary.potStatus.totalDeposits;
    const totalWithdrawals =
      this.props.potSummary && this.props.potSummary.potStatus.totalWithdrawals;
    const totalApprovedInvestment =
      this.props.potSummary &&
      this.props.potSummary.potStatus.totalApprovedInvestment;
    const totalRequestedInvestment =
      this.props.potSummary &&
      this.props.potSummary.potStatus.totalRequestedInvestment;
    return (
      <div className="pot-summary-container">
        <div className="pot-summary-title">
          <div className="investor-page-title">Pot Summary</div>
          <div className="trading-period-display">
            {this.getLatestEndOfPeriod()}
            <Button
              onClick={() => this.setState({ endOfPeriodModalOpen: true })}
              style={{
                backgroundColor: "#1bc4ab",
                border: "0px",
                color: "#000000",
              }}
            >
              End Trading Period
            </Button>
          </div>
        </div>
        {!!this.props.potSummary && (
          <div className="pot-summary-components">
            <div className="pot-summary pot-summary-item-a">
              <div className="pot-summary-holdings-title">Total Pot:</div>
              <div className="pot-summary-holdings">
                {formatCurrency(totalHoldings)}
                {getDirection(totalHoldings + totalWithdrawals, totalDeposits)}
              </div>
            </div>
            <div className="pot-summary pot-summary-item-b">
              <div className="pot-summary-holdings-title">Total Deposits:</div>
              <div className="pot-summary-holdings">
                {formatCurrency(totalDeposits)}
              </div>
            </div>
            <div className="pot-summary pot-summary-item-c">
              <div className="pot-summary-holdings-title">
                Total Withdrawals:
              </div>
              <div className="pot-summary-holdings">
                {formatCurrency(totalWithdrawals)}
              </div>
            </div>
            <div className="pot-summary pot-summary-item-d">
              <div className="pot-summary-holdings-title">
                Pending Clearance:
              </div>
              <div className="pot-summary-holdings">
                {formatCurrency(totalApprovedInvestment)}
              </div>
            </div>
            <div className="pot-summary pot-summary-item-e">
              <div className="pot-summary-holdings-title">
                Pending Approval:
              </div>
              <div className="pot-summary-holdings">
                {formatCurrency(
                  totalRequestedInvestment - totalApprovedInvestment
                )}
              </div>
            </div>
          </div>
        )}
        {!this.props.potSummary && <div className="italic">No Data...</div>}
        <EndOfTradingPeriodModal
          open={this.state.endOfPeriodModalOpen}
          onCancel={() => this.setState({ endOfPeriodModalOpen: false })}
          onProceed={(deposits, withdrawals, onComplete) => {
            this.setState({ endOfPeriodModalOpen: false }, () =>
              services.investmentService
                .endOfPeriod(deposits, withdrawals)
                .then(() => onComplete())
            );
          }}
          transactions={this.props.transactions}
          investors={this.props.investors}
          potSummary={this.props.potSummary}
        />
      </div>
    );
  }
}
