import { MatchStatsWrapper } from "../../types/stats/match-stats";

export function batchUpdate(
  currentStats: MatchStatsWrapper,
  comparedStats: MatchStatsWrapper,
  updates: string[],
  onUpdate: (updatedStats: MatchStatsWrapper) => void
): void {
  let updatedStats = currentStats.matchStats;
  updates.forEach((key) => {
    updatedStats = {
      ...updatedStats,
      [key]: comparedStats.matchStats[key],
    };
  });

  onUpdate(
    new MatchStatsWrapper(
      currentStats.matchStatsId,
      currentStats.matchId,
      currentStats.createdBy,
      currentStats.createdAt,
      updatedStats
    )
  );
}
