import { ReplaySubject, Subject } from "rxjs";
import { GetBetsResponse } from "../components/bets-page/bets-page";
import { services, showMessage } from "../types/services";
import { BetSource } from "../types/stored-bets/bet-source";
import {
  deserializeLastStoredBetScrape,
  LastStoredBetScrape,
} from "../types/stored-bets/last-stored-bet-scrape";
import { deserializeStoredBets } from "../types/stored-bets/stored-bet";
import { deserializeGroupedStoredOdds } from "../types/stored-bets/stored-odds";

export class BetScraperService {
  public readonly PAGE_SIZE: number = 8;
  public readonly BET_SCRAPER_CONTROLLER_URL: string =
    "/api/bet-scraper-controller";
  public lastStoredBetScrapeSubject: Subject<LastStoredBetScrape> =
    new ReplaySubject(1);

  public init() {
    services.httpService
      .get(`${this.BET_SCRAPER_CONTROLLER_URL}/last-bet-scrape-info`)
      .then((response) => {
        this.lastStoredBetScrapeSubject.next(
          deserializeLastStoredBetScrape(response)
        );
      })
      .catch((reason) => {
        showMessage(
          `Failed to get last stored bet scrape data: ${reason}`,
          "error"
        );
      });
  }

  public scrapeBets(
    source: string,
    username: string,
    password: string
  ): Promise<GetBetsResponse> {
    return services.httpService
      .post(`${this.BET_SCRAPER_CONTROLLER_URL}/scrape-bets`, {
        source,
        username,
        password,
      })
      .then((response) => {
        return {
          bets: deserializeStoredBets(response.bets),
          odds: deserializeGroupedStoredOdds(response.odds),
          linksBySourceEventId: response.linksBySourceEventId,
          error: response.error,
          totalPages: response.totalPages,
        };
      })
      .catch((reason) => {
        showMessage(`Failed to scrape bets for ${source}: ${reason}`, "error");
        return {
          bets: {},
          odds: {},
          linksBySourceEventId: {},
          error: reason,
          totalPages: -1,
        };
      });
  }

  public toggleAlertsEnabled(source: BetSource): Promise<boolean> {
    return services.httpService
      .post(`${this.BET_SCRAPER_CONTROLLER_URL}/toggle-alerts`, source, false)
      .then((response) => {
        return response;
      });
  }

  public reScrapeAllBets(): Promise<boolean> {
    return services.httpService
      .get(`${this.BET_SCRAPER_CONTROLLER_URL}/re-scrape-bets`)
      .then((response) => {
        return response;
      });
  }

  public linkBets(
    source: BetSource,
    sourceEventId: string,
    matchId: string
  ): Promise<void> {
    return services.httpService
      .post(`${this.BET_SCRAPER_CONTROLLER_URL}/link-bets`, {
        source,
        sourceEventId,
        matchId,
      })
      .then((response) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to link bets: ${reason}`, "error");
      });
  }

  public unlinkBets(sourceEventId: string): Promise<void> {
    return services.httpService
      .post(`${this.BET_SCRAPER_CONTROLLER_URL}/unlink-bets`, {
        sourceEventId,
      })
      .then((response) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to unlink bets: ${reason}`, "error");
      });
  }

  public getBets(
    page: number = 0,
    pageSize: number = this.PAGE_SIZE
  ): Promise<GetBetsResponse> {
    const params: Map<string, string> = new Map();
    params.set("page", `${page}`);
    params.set("size", `${pageSize}`);

    return services.httpService
      .get(`${this.BET_SCRAPER_CONTROLLER_URL}/get-bets`, params)
      .then((response) => {
        return {
          bets: deserializeStoredBets(response.bets),
          odds: deserializeGroupedStoredOdds(response.odds),
          linksBySourceEventId: response.linksBySourceEventId,
          error: response.error,
          totalPages: response.totalPages,
        };
      })
      .catch((reason) => {
        showMessage(`Failed to get bets: ${reason}`, "error");
        return {
          bets: {},
          odds: {},
          linksBySourceEventId: {},
          error: reason,
          totalPages: -1,
        };
      });
  }

  public storedBetScrapeUpdate(msg: any): void {
    const lastStoredBetScrape: LastStoredBetScrape =
      deserializeLastStoredBetScrape(msg.lastStoredBetScrape);
    this.lastStoredBetScrapeSubject.next(lastStoredBetScrape);
  }

  public getRequiredScrapes(
    lastStoredBetScrape: LastStoredBetScrape
  ): BetSource[] {
    const requiredScrapes: BetSource[] = [];
    const oneWeekInMillis = 604800000;
    const minTime = new Date().getTime() - oneWeekInMillis;

    Object.values(BetSource).forEach((betSource) => {
      if (
        lastStoredBetScrape?.scrapeTimesBySource.get(betSource) < minTime &&
        lastStoredBetScrape?.alertsEnabledBySource.get(betSource)
      ) {
        requiredScrapes.push(betSource);
      }
    });

    return requiredScrapes;
  }
}
