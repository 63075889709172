import { Fragment } from "react";
import {
  humanReadablePushBrackets,
  PushBracket,
} from "../../types/enums/push-bracket";
import NumberSelector from "../entity-management/entity-selectors/number-selector";

interface Props {
  title: string;
  value: number[][];
  canEdit?: boolean;
  onChange?: (updatedValue: number[][]) => void;
}

export default function BowlingTypeByPhaseMultipliersComponent({
  title,
  value,
  canEdit = true,
  onChange = () => {},
}: Readonly<Props>): React.JSX.Element {
  const rowTitles = ["Strike Rate", "Wicket %"];
  const numberSelectorMin = 0;
  const numberSelectorMax = 2;
  const numberSelectorStep = 0.01;
  const numberSelectorDp = 2;

  const build2dArrayTableRow = (rowNumber: number) => (
    <Fragment key={rowNumber}>
      <div key={rowNumber} style={{ padding: "0px" }}>
        {rowTitles[rowNumber]}
      </div>
      {Object.values(PushBracket).map(
        (pushBracket: PushBracket, colNumber: number) => (
          <div style={{ padding: "0px" }} key={pushBracket}>
            {canEdit && (
              <NumberSelector
                style={{ justifyContent: "center" }}
                textFieldStyle={{
                  paddingLeft: "5px",
                  width: "100%",
                }}
                min={numberSelectorMin}
                max={numberSelectorMax}
                step={numberSelectorStep}
                decimalPlaces={numberSelectorDp}
                initial={value[rowNumber][colNumber]}
                onValid={(valid) => update2dArray(valid, rowNumber, colNumber)}
              />
            )}
            {!canEdit && value[rowNumber][colNumber]}
          </div>
        )
      )}
    </Fragment>
  );

  const update2dArray = (
    valid: number,
    rowNumber: number,
    colNumber: number
  ) => {
    value[rowNumber][colNumber] = valid;
    onChange(value);
  };

  return (
    <div className="bowling-type-by-phase-multipliers">
      <div
        style={{ display: "flex", justifyContent: "left", fontWeight: "bold" }}
      >
        {title}
      </div>
      {Object.values(PushBracket).map((pushBracket: PushBracket) => (
        <div
          key={pushBracket}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {humanReadablePushBrackets[pushBracket]}
        </div>
      ))}

      {rowTitles.map((_title, rowNumber) => build2dArrayTableRow(rowNumber))}
    </div>
  );
}
