import { Squad } from "../../entities/squad";
import {
  InningsSimulationQuestionStatistic,
  PlayerSimulationQuestionStatistic,
} from "../../enums/question-statistic";
import { SimulatorOddsQuestionOperator } from "../../enums/questions-operator";
import { UUID } from "../../uuid";
import {
  InningsSimulationOddsQuestion,
  MultiSimulationOddsQuestion,
  PlayerSimulationOddsQuestion,
  SimulationOddsQuestion,
  SimulationQuestionType,
  questionTypesEnumToString,
  questionTypesStringToEnum,
  serializeSimulationOddsQuestion,
} from "./simulation-odds-question";

export interface StoredQuestion {
  storedQuestionId: UUID;
  createdBy: UUID;
  createdAt: number;
  matchId: UUID;
  question: SimulationOddsQuestion;
}

const createDefaultInningsQuestion: (
  squad: Squad
) => InningsSimulationOddsQuestion = (squad) => {
  const teamId = squad && squad.teamId ? squad.teamId.value : null;
  return {
    subType: questionTypesEnumToString[SimulationQuestionType.INNINGS],
    innings: 1,
    operator: SimulatorOddsQuestionOperator.EQUALS,
    operand: 100,
    over: 0,
    wicketsFallen: 0,
    teamId,
    questionStatistic: InningsSimulationQuestionStatistic.SCORE,
  };
};

const createDefaultPlayerQuestion: (
  squad: Squad
) => PlayerSimulationOddsQuestion = (squad) => {
  const playerId =
    squad && squad.players && squad.players.length > 0
      ? squad.players[0].playerId.value
      : null;
  return {
    subType: questionTypesEnumToString[SimulationQuestionType.PLAYER],
    operator: SimulatorOddsQuestionOperator.EQUALS,
    operand: 100,
    playerId: playerId,
    questionStatistic: PlayerSimulationQuestionStatistic.SCORE,
  };
};

const createDefaultMultiQuestion: () => MultiSimulationOddsQuestion = () => {
  return {
    subType: questionTypesEnumToString[SimulationQuestionType.MULTI],
    correctQuestionsToApply: 0,
    operator: SimulatorOddsQuestionOperator.EQUALS,
    operand: 100,
    questions: [],
  };
};

export const createDefaultQuestionOfType: (
  type: SimulationQuestionType,
  squad1: Squad
) => SimulationOddsQuestion = (type, squad1) => {
  switch (type) {
    case SimulationQuestionType.INNINGS:
      return createDefaultInningsQuestion(squad1);
    case SimulationQuestionType.PLAYER:
      return createDefaultPlayerQuestion(squad1);
    case SimulationQuestionType.MULTI:
      return createDefaultMultiQuestion();
    default:
      return null;
  }
};

export const deserializeStoredQuestionList: (
  json: any[]
) => StoredQuestion[] = (json) => {
  const result: StoredQuestion[] = [];
  json.forEach((element) =>
    result.push({
      storedQuestionId: !!element.storedQuestionId
        ? UUID.fromString(element.storedQuestionId)
        : null,
      createdAt: element.createdAt,
      createdBy: !!element.createdBy
        ? UUID.fromString(element.createdBy)
        : null,
      matchId: !!element.matchId ? UUID.fromString(element.matchId) : null,
      question: deserializeQuestion(element.question),
    })
  );
  return result;
};

export const deserializeQuestionList: (
  json: any[]
) => SimulationOddsQuestion[] = (json) => {
  return json.map((element) => deserializeQuestion(element));
};

export const deserializeQuestion: (question: any) => SimulationOddsQuestion = (
  question
) => {
  switch (questionTypesStringToEnum[question.subType]) {
    case SimulationQuestionType.INNINGS:
      return deserializeInningsQuestion(question);
    case SimulationQuestionType.PLAYER:
      return deserializePlayerQuestion(question);
    case SimulationQuestionType.MULTI:
      return deserializeMultiQuestion(question);
    default:
      console.log("Unknown question type: " + question.subType);
      return null;
  }
};

export const serializeStoredQuestion: (
  storedQuestion: StoredQuestion
) => any = (storedQuestion) => {
  if (!storedQuestion) {
    return null;
  }
  return {
    storedQuestionId: storedQuestion.storedQuestionId
      ? storedQuestion.storedQuestionId.value
      : null,
    createdAt: storedQuestion.createdAt,
    createdBy: storedQuestion.createdBy ? storedQuestion.createdBy.value : null,
    matchId: storedQuestion.matchId ? storedQuestion.matchId.value : null,
    question: serializeSimulationOddsQuestion(storedQuestion.question),
  };
};

function deserializeInningsQuestion(
  inningsQuestionJson: any
): InningsSimulationOddsQuestion {
  return {
    subType: inningsQuestionJson.subType,
    innings: inningsQuestionJson.innings,
    operator: !!inningsQuestionJson.operator
      ? SimulatorOddsQuestionOperator[inningsQuestionJson.operator]
      : null,
    operand: inningsQuestionJson.operand,
    over: inningsQuestionJson.over,
    wicketsFallen: inningsQuestionJson.wicketsFallen,
    teamId: inningsQuestionJson.teamId,
    questionStatistic: !!inningsQuestionJson.questionStatistic
      ? InningsSimulationQuestionStatistic[
          inningsQuestionJson.questionStatistic
        ]
      : null,
  };
}

function deserializePlayerQuestion(
  playerQuestionJson: any
): PlayerSimulationOddsQuestion {
  return {
    subType: playerQuestionJson.subType,
    operator: !!playerQuestionJson.operator
      ? SimulatorOddsQuestionOperator[playerQuestionJson.operator]
      : null,
    operand: playerQuestionJson.operand,
    playerId: playerQuestionJson.playerId,
    questionStatistic: !!playerQuestionJson.questionStatistic
      ? PlayerSimulationQuestionStatistic[playerQuestionJson.questionStatistic]
      : null,
  };
}

function deserializeMultiQuestion(
  multiQuestionJson: any
): MultiSimulationOddsQuestion {
  return {
    subType: multiQuestionJson.subType,
    operator: !!multiQuestionJson.operator
      ? SimulatorOddsQuestionOperator[multiQuestionJson.operator]
      : null,
    operand: multiQuestionJson.operand,
    correctQuestionsToApply: multiQuestionJson.correctQuestionsToApply,
    questions: deserializeQuestionList(multiQuestionJson.questions),
  };
}
