import React from "react";
import NumberSelector, {
  medianNumberSelector,
} from "../entity-management/entity-selectors/number-selector";

interface InningsProps {
  edge: number;
  onChange: (edge: number) => void;
}

export function BetBuilderEdgeControl({
  edge,
  onChange,
}: InningsProps): React.JSX.Element {
  return (
    <div className="compare-user-select-light">
      <NumberSelector
        label={"Edge (%):"}
        style={medianNumberSelector}
        min={0}
        max={100}
        initial={edge}
        onValid={onChange}
      />
    </div>
  );
}
