import { services } from "../../../types/services";
import { GameState } from "../../../types/entities/game-state";
import { Ball } from "../../../types/entities/ball";
import { DismissalMethod } from "../../../types/enums/dismissal-method";
import { UUID } from "../../../types/uuid";
import { Squad } from "../../../types/entities/squad";
import { MatchFormat } from "../../../types/entities/match-format";

const currentGameService = services.currentGameService;

export function buildDefault(): Ball {
  const gameState: GameState = currentGameService.currentState;
  return gameState === null
    ? Ball.emptyWithName("")
    : Ball.fromGameState(gameState);
}

export function buildRuns(ball: Ball, runs: number): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.runs = runs;
  return newBall;
}

export function buildExtras(ball: Ball, extras: number): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.extras = extras;
  return newBall;
}

export function buildOut(
  ball: Ball,
  dismissal: boolean,
  manOut: UUID,
  howOut: DismissalMethod,
  fielder: UUID
): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.dismissal = dismissal;
  newBall.dismissalBatsman = manOut;
  newBall.dismissalFielder = fielder;
  newBall.dismissalMethod = howOut;
  return newBall;
}

export function buildWide(ball: Ball, wide: boolean): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.wide = wide;
  return newBall;
}

export function buildNoBall(ball: Ball, noBall: boolean): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.noBall = noBall;
  return newBall;
}

export function buildByes(ball: Ball, bye: boolean): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.bye = bye;
  return newBall;
}

export function buildLegByes(ball: Ball, legBye: boolean): Ball {
  const newBall: Ball = Object.assign({}, ball);
  newBall.legBye = legBye;
  return newBall;
}

export function calculateBallText(
  ball: Ball,
  battingTeam: number,
  squad1: Squad,
  squad2: Squad,
  matchFormat: MatchFormat
) {
  const bowlerName: string = services.teamPlayerService.getPlayerName(
    ball.bowler,
    battingTeam === 1 ? squad2 : squad1
  );
  const batsmanName: string = services.teamPlayerService.getPlayerName(
    ball.batsman1,
    battingTeam === 1 ? squad1 : squad2
  );
  const noBallPenalty = !!matchFormat ? matchFormat.noBallPenalty : 0;
  const extras = ball.noBall ? ball.extras - noBallPenalty : ball.extras;
  let eventText: string = ball.dismissal ? "OUT!" : "";
  eventText = joinStrings(
    eventText,
    ball.wide ? `${extras} wide${extras !== 1 ? "s" : ""}` : ""
  );
  eventText = joinStrings(eventText, ball.noBall ? `no ball` : "");
  eventText = joinStrings(
    eventText,
    ball.legBye ? `${extras} leg bye${extras !== 1 ? "s" : ""}` : ""
  );
  eventText = joinStrings(
    eventText,
    ball.bye ? `${extras} bye${extras !== 1 ? "s" : ""}` : ""
  );
  eventText = joinStrings(
    eventText,
    eventText.length === 0 || ball.runs > 0
      ? `${ball.runs} run${ball.runs === 1 ? "" : "s"}`
      : ""
  );
  return `${bowlerName} to ${batsmanName}: ${eventText}`;
}

function joinStrings(part1: string, part2: string): string {
  return part1 + (part1.length > 0 ? " " : "") + part2;
}
