import { Component } from "react";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { format } from "../simulator-page/simulator-utils";
import { NumberComparison } from "../stats-editing-components/number-comparison";

interface Props {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  title: string;
  groundStrikeRateProperty: string;
  groundWicketPercentProperty: string;
  opponentStrikeRateProperty: string;
  opponentWicketPercentProperty: string;
  opponentType: string;
  classes: string;
}

export class OpponentSkillStats extends Component<Props, {}> {
  public render() {
    return (
      <div className="stats-modal-section">
        <div className={`stats-modal-section-head ${this.props.classes}`}>
          {this.props.title}
        </div>
        <div>
          <div className="opponent-skill-stats">
            <div>Ground SR Bias:</div>
            <div>
              {format(
                this.props.currentStats.playerStats[
                  this.props.groundStrikeRateProperty
                ]
              )}
            </div>
          </div>
          <div className="opponent-skill-stats">
            <div>Ground Wicket Bias:</div>
            <div>
              {format(
                this.props.currentStats.playerStats[
                  this.props.groundWicketPercentProperty
                ]
              )}
            </div>
          </div>
          <div className="opponent-skill-stats">
            <div>{this.props.opponentType} SR Bias:</div>
            <div>
              {format(
                this.props.currentStats.playerStats[
                  this.props.opponentStrikeRateProperty
                ]
              )}
            </div>
          </div>
          <div className="opponent-skill-stats">
            <div>{this.props.opponentType} Wicket Bias:</div>
            <div>
              {format(
                this.props.currentStats.playerStats[
                  this.props.opponentWicketPercentProperty
                ]
              )}
            </div>
          </div>
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title">
                Compared to {this.props.comparedUserName}
              </div>
              <NumberComparison
                label="Ground Strike Rate"
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.groundStrikeRateProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.groundStrikeRateProperty
                  ]
                }
              />
              <NumberComparison
                label="Ground Wicket Percent"
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.groundWicketPercentProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.groundWicketPercentProperty
                  ]
                }
              />
              <NumberComparison
                label={`${this.props.opponentType} Strike Rate`}
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.opponentStrikeRateProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.opponentStrikeRateProperty
                  ]
                }
              />
              <NumberComparison
                label={`${this.props.opponentType} Wicket Percent`}
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.opponentWicketPercentProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.opponentWicketPercentProperty
                  ]
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
