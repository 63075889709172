import { MarketType } from "../../types/betfair/betfair-message";
import { MatchFormat } from "../../types/entities/match-format";

function getPowerplayMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.POWERPLAY_RUNS_1];
  }

  return [MarketType.POWERPLAY_RUNS_2];
}

function getODIPowerplayMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.ODI_POWERPLAY_RUNS_1];
  }

  return [MarketType.ODI_POWERPLAY_RUNS_2];
}

function getInningsRunsMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.INNINGS_RUNS_1];
  }

  return [MarketType.INNINGS_RUNS_2];
}

function getODIRunsMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.ODI_RUNS_1];
  }

  return [MarketType.ODI_RUNS_2];
}

function getHundredMarkets(innings: number): MarketType[] {
  if (innings === 1) {
    return [MarketType.HUNDRED_25_RUNS_1, MarketType.HUNDRED_100_RUNS_1];
  }

  return [MarketType.HUNDRED_25_RUNS_2, MarketType.HUNDRED_100_RUNS_2];
}

function getNonHundredMarkets(
  innings: number,
  oversThisInnings: number
): MarketType[] {
  let marketTypes: MarketType[] = [];

  if (oversThisInnings >= 6) {
    marketTypes = [...marketTypes, ...getPowerplayMarkets(innings)];
  }

  if (oversThisInnings >= 10) {
    marketTypes = [...marketTypes, ...getODIPowerplayMarkets(innings)];
  }

  if (oversThisInnings >= 20) {
    marketTypes = [...marketTypes, ...getInningsRunsMarkets(innings)];
  }

  if (oversThisInnings >= 50) {
    marketTypes = [...marketTypes, ...getODIRunsMarkets(innings)];
  }

  return marketTypes;
}

export function getMarketsForMatchFormat(
  matchFormat: MatchFormat,
  innings: number
): MarketType[] {
  // Always include MATCH_ODDS
  const marketTypes: MarketType[] = [MarketType.MATCH_ODDS];

  const totalBallsInInnings = MatchFormat.getTotalBallsInInnings(
    innings,
    matchFormat
  );

  if (totalBallsInInnings === 100) {
    marketTypes.push(...getHundredMarkets(innings));
  } else {
    const oversThisInnings = matchFormat.overConfiguration[innings - 1].length;
    marketTypes.push(...getNonHundredMarkets(innings, oversThisInnings));
  }

  return marketTypes;
}
