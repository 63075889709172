import { useState } from "react";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { UserPreferences } from "../../types/preferences/preferences";
import { PercentAdjustModuleType } from "../../types/simulator/modules/percent-adjust-modules";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { format } from "../simulator-page/simulator-utils";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import PlayerPercentsDistributionModal from "../stats-editing-components/player-percents-distribution-modal";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";
import { batchUpdate } from "./squad-page-utils";

interface Props {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  title: string;
  strikeRateProperty: string;
  wicketPercentProperty: string;
  extrasProperty?: string;
  confidenceProperty: string;
  percentDistributionProperty: string;
  percentDistributionPropertyName: string;
  percentDistributionConfidence: number;
  globalDistributionProperty: string;
  globalDistributionConfidence: number;
  userPreferences: UserPreferences;
  classes: string;
  percentAdjustModuleType: PercentAdjustModuleType;
  matchStats: MatchStatsWrapper;
  matchStrikeRateAdjustmentProperty?: string;
  matchWicketPercentAdjustmentProperty?: string;
  player?: Player;
  squad?: Squad;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
}

export default function PlayerStrikeRateAndWicketPercentStats({
  currentStats,
  comparedStats,
  comparedUserName,
  title,
  strikeRateProperty,
  wicketPercentProperty,
  extrasProperty = null,
  confidenceProperty,
  percentDistributionProperty = null,
  percentDistributionPropertyName = null,
  percentDistributionConfidence = null,
  globalDistributionProperty,
  globalDistributionConfidence,
  userPreferences = null,
  classes,
  percentAdjustModuleType,
  matchStats,
  matchStrikeRateAdjustmentProperty = null,
  matchWicketPercentAdjustmentProperty = null,
  player = null,
  squad = null,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const [percentDistributionModalOpen, setPercentDistributionModalOpen] =
    useState<boolean>(false);

  const STATS_TO_STEAL: string[] = [
    strikeRateProperty,
    wicketPercentProperty,
    confidenceProperty,
    extrasProperty,
    percentDistributionProperty,
  ].filter((value) => value !== null);

  const onClickHandler = (updates: string[]) => {
    batchUpdate(currentStats, comparedStats, updates, onUpdate);
  };

  const callUpdateFunction = (propertyName: string, newValue: any) => {
    onUpdate(
      new PlayerStatsWrapper(
        currentStats.playerStatsId,
        currentStats.createdBy,
        currentStats.createdAt,
        currentStats.name,
        {
          ...currentStats.playerStats,
          [propertyName]: newValue,
        },
        currentStats.matchType
      )
    );
  };

  const callUpdateFunctionWithAdjustment = (
    propertyName: string,
    adjustmentPropertyName: string,
    newValue: any
  ) => {
    const adjustment = getMatchAdjustment(adjustmentPropertyName);
    callUpdateFunction(propertyName, newValue - adjustment);
  };

  const getMatchAdjustment = (matchAdjustmentProperty: string) => {
    if (!matchStats || !player || !squad || !matchAdjustmentProperty) {
      return 0;
    }

    const squadAdjustments =
      matchStats?.matchStats[matchAdjustmentProperty].get(
        squad?.teamId.value
      ) || new Map();
    const playerAdjustment = squadAdjustments.get(player?.playerId.value) || 0;
    return Number(format(playerAdjustment));
  };

  const getLabel = (label: string, matchAdjustmentProperty: string) => {
    const matchAdjustment: number = getMatchAdjustment(matchAdjustmentProperty);
    if (matchAdjustment !== 0) {
      label =
        label + " (" + (matchAdjustment > 0 ? "+" : "") + matchAdjustment + ")";
    }
    return label + ":";
  };

  const getStatsWithAdjustment = (
    playerStatsProperty: string,
    matchStatsProperty: string
  ) => {
    return (
      currentStats.playerStats[playerStatsProperty] +
      getMatchAdjustment(matchStatsProperty)
    );
  };

  return (
    <div className="stats-modal-section">
      <div className={`stats-modal-section-head ${classes}`}>{title}</div>
      <div>
        <NumberSelector
          label={getLabel("Strike Rate", matchStrikeRateAdjustmentProperty)}
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={getStatsWithAdjustment(
            strikeRateProperty,
            matchStrikeRateAdjustmentProperty
          )}
          onValid={(newValue) =>
            callUpdateFunctionWithAdjustment(
              strikeRateProperty,
              matchStrikeRateAdjustmentProperty,
              newValue
            )
          }
        >
          {percentDistributionProperty && (
            <TooltipIconButton
              title={"Percent Distribution"}
              onClick={() => setPercentDistributionModalOpen(true)}
              icon={"bar_chart"}
            />
          )}
        </NumberSelector>
        <NumberSelector
          label={getLabel(
            "Wicket Percent",
            matchWicketPercentAdjustmentProperty
          )}
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={getStatsWithAdjustment(
            wicketPercentProperty,
            matchWicketPercentAdjustmentProperty
          )}
          onValid={(newValue) =>
            callUpdateFunctionWithAdjustment(
              wicketPercentProperty,
              matchWicketPercentAdjustmentProperty,
              newValue
            )
          }
        />
        {extrasProperty != null && (
          <NumberSelector
            label="Extras:"
            min={0}
            max={3}
            step={0.01}
            decimalPlaces={2}
            initial={currentStats.playerStats[extrasProperty]}
            onValid={(newValue) => callUpdateFunction(extrasProperty, newValue)}
          />
        )}
        <NumberSelector
          label="Confidence:"
          min={0}
          max={1000000}
          step={1}
          initial={currentStats.playerStats[confidenceProperty]}
          onValid={(newValue) =>
            callUpdateFunction(confidenceProperty, newValue)
          }
        />
        {!!comparedStats && (
          <div className="comparison">
            <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
              <StealStatsButton
                comparedUserName={comparedUserName}
                disabled={areStatsEqual(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  "playerStats"
                )}
                tooltipMessage={getToolTipMessage(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  comparedUserName,
                  "playerStats"
                )}
                onClickHandler={() => onClickHandler(STATS_TO_STEAL)}
              />
            </StealStatsHeadingAndButton>
            <NumberComparison
              label="Strike Rate"
              originalValue={currentStats.playerStats[strikeRateProperty]}
              comparedValue={comparedStats.playerStats[strikeRateProperty]}
            />
            <NumberComparison
              label="Wicket Percent"
              originalValue={currentStats.playerStats[wicketPercentProperty]}
              comparedValue={comparedStats.playerStats[wicketPercentProperty]}
            />
            {extrasProperty != null && (
              <NumberComparison
                label="Extras"
                originalValue={currentStats.playerStats[extrasProperty]}
                comparedValue={comparedStats.playerStats[extrasProperty]}
              />
            )}
            <NumberComparison
              label="Confidence"
              originalValue={currentStats.playerStats[confidenceProperty]}
              comparedValue={comparedStats.playerStats[confidenceProperty]}
            />
          </div>
        )}
      </div>

      {percentDistributionProperty && (
        <PlayerPercentsDistributionModal
          open={percentDistributionModalOpen}
          onProceed={(stats: PlayerStats) => {
            setPercentDistributionModalOpen(false);
            onUpdate(
              new PlayerStatsWrapper(
                currentStats.playerStatsId,
                currentStats.createdBy,
                currentStats.createdAt,
                currentStats.name,
                stats,
                currentStats.matchType
              )
            );
          }}
          onCancel={() => setPercentDistributionModalOpen(false)}
          initialStats={currentStats.playerStats}
          percentDistributionBiasProperty={percentDistributionProperty}
          percentDistributionBiasPropertyName={percentDistributionPropertyName}
          percentDistributionConfidence={percentDistributionConfidence}
          userPreferences={userPreferences}
          comparedStats={comparedStats?.playerStats}
          comparedUserName={comparedUserName}
          globalDistributionProperty={globalDistributionProperty}
          globalDistributionConfidence={globalDistributionConfidence}
          percentAdjustModuleType={percentAdjustModuleType}
        />
      )}
    </div>
  );
}
