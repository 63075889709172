import { Pagination, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import React from "react";

type Props = {
  page: number;
  pageCount: number;
  onChange: (event: React.ChangeEvent, value: number) => void;
};

export default function PaginationButtons({
  page,
  pageCount,
  onChange,
}: Props): React.JSX.Element {
  const theme = useTheme();

  //Make a styled pagination component using styled API that overrides default style
  const StyledPagination = styled(Pagination)({
    ".Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    ".MuiPaginationItem-root": {
      backgroundColor: theme.palette.primary.main,
      hover: "#2e387a",
      color: "#fff",
    },
    ".MuiPaginationItem-page": {
      backgroundColor: theme.palette.primary.main,
      hover: "#2e387a",
      color: "#fff",
    },
  });

  return (
    <div className="my-matches-pagination">
      <Stack spacing={2}>
        <StyledPagination
          page={page}
          count={pageCount}
          shape="rounded"
          onChange={onChange}
        />
      </Stack>
    </div>
  );
}
