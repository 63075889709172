import { UUID } from "../uuid";

export class NodeConfiguration {
  public createdAt: number;
  public createdBy: UUID;
  public nodeConfigurationId: UUID;
  public host: string;
  public maxCores: number;
  public name: string;
  public port: number;

  constructor(
    createdAt: number,
    createdBy: UUID,
    nodeConfigurationId: UUID,
    host: string,
    maxCores: number,
    name: string,
    port: number
  ) {
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.nodeConfigurationId = nodeConfigurationId;
    this.host = host;
    this.maxCores = maxCores;
    this.name = name;
    this.port = port;
  }
}

export function defaultNodeConfiguration(): NodeConfiguration {
  return new NodeConfiguration(
    null,
    null,
    null,
    "",
    1,
    "New Configuration",
    80
  );
}

export const deserializeNodeArray: (array: any) => NodeConfiguration[] = (
  array: any
) => {
  const nodeConfigurations: NodeConfiguration[] = [];
  array.forEach((element) => {
    nodeConfigurations.push(deserializeNodeConfiguration(element));
  });
  return nodeConfigurations;
};

export function deserializeNodeConfiguration(json: any): NodeConfiguration {
  return new NodeConfiguration(
    json.createdAt,
    UUID.fromString(json.createdBy),
    UUID.fromString(json.nodeConfigurationId),
    json.host,
    json.maxCores,
    json.name,
    json.port
  );
}

export function serializeNodeConfiguration(
  configuration: NodeConfiguration
): any {
  return {
    createdAt: configuration.createdAt,
    createdBy:
      configuration.createdBy === null ? null : configuration.createdBy.value,
    nodeConfigurationId:
      configuration.nodeConfigurationId === null
        ? null
        : configuration.nodeConfigurationId.value,
    host: configuration.host,
    maxCores: configuration.maxCores,
    name: configuration.name,
    port: configuration.port,
  };
}
