export enum LongBowlType {
  LEFT_ARM_BOWLER = "LEFT_ARM_BOWLER",
  LEFT_ARM_FAST = "LEFT_ARM_FAST",
  LEFT_ARM_MEDIUM_FAST = "LEFT_ARM_MEDIUM_FAST",
  LEFT_ARM_FAST_MEDIUM = "LEFT_ARM_FAST_MEDIUM",
  LEFT_ARM_MEDIUM = "LEFT_ARM_MEDIUM",
  LEFT_ARM_SLOW_MEDIUM = "LEFT_ARM_SLOW_MEDIUM",
  LEFT_ARM_SLOW = "LEFT_ARM_SLOW",
  LEFT_ARM_WRIST_SPIN = "LEFT_ARM_WRIST_SPIN",
  SLOW_LEFT_ARM_ORTHODOX = "SLOW_LEFT_ARM_ORTHODOX",
  SLOW_LEFT_ARM_CHINAMAN = "SLOW_LEFT_ARM_CHINAMAN",
  RIGHT_ARM_FAST = "RIGHT_ARM_FAST",
  RIGHT_ARM_BOWLER = "RIGHT_ARM_BOWLER",
  RIGHT_ARM_MEDIUM_FAST = "RIGHT_ARM_MEDIUM_FAST",
  RIGHT_ARM_FAST_MEDIUM = "RIGHT_ARM_FAST_MEDIUM",
  RIGHT_ARM_MEDIUM = "RIGHT_ARM_MEDIUM",
  RIGHT_ARM_SLOW_MEDIUM = "RIGHT_ARM_SLOW_MEDIUM",
  RIGHT_ARM_SLOW = "RIGHT_ARM_SLOW",
  RIGHT_ARM_OFFBREAK = "RIGHT_ARM_OFFBREAK",
  LEGBREAK = "LEGBREAK",
  LEGBREAK_GOOGLY = "LEGBREAK_GOOGLY",
  UNKNOWN = "UNKNOWN",
}

export const humanReadableLongBowlTypes: Record<LongBowlType, string> = {
  LEFT_ARM_BOWLER: "Left-Arm Bowler",
  LEFT_ARM_FAST: "Left-Arm Fast",
  LEFT_ARM_MEDIUM_FAST: "Left-Arm Medium-Fast",
  LEFT_ARM_FAST_MEDIUM: "Left-Arm Fast-Medium",
  LEFT_ARM_MEDIUM: "Left-Arm Medium",
  LEFT_ARM_SLOW_MEDIUM: "Left-Arm Slow-Medium",
  LEFT_ARM_SLOW: "Left-Arm Slow",
  LEFT_ARM_WRIST_SPIN: "Left-Arm Wrist Spin",
  SLOW_LEFT_ARM_ORTHODOX: "Slow Left-Arm Orthodox",
  RIGHT_ARM_FAST: "Right-Arm Fast",
  RIGHT_ARM_BOWLER: "Right-Arm Bowler",
  RIGHT_ARM_MEDIUM_FAST: "Right-Arm Medium-Fast",
  RIGHT_ARM_FAST_MEDIUM: "Right-Arm Fast-Medium",
  RIGHT_ARM_MEDIUM: "Right-Arm Medium",
  RIGHT_ARM_SLOW_MEDIUM: "Right-Arm Slow-Medium",
  RIGHT_ARM_SLOW: "Right-Arm Slow",
  RIGHT_ARM_OFFBREAK: "Right-Arm Offbreak",
  LEGBREAK: "Legbreak",
  LEGBREAK_GOOGLY: "Legbreak Googly",
  SLOW_LEFT_ARM_CHINAMAN: "Slow Left-Arm Chinaman",
  UNKNOWN: "Unknown Bowling Style",
};
