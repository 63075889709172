import { Checkbox } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";
import { Country, countryNames } from "../../types/enums/country";
import { countryFlags } from "../../types/enums/flags";
import { SinceDate, sinceDateNames } from "../../types/enums/since-date";
import {
  BowlerSpecialityPlayerFormData,
  PlayerFormData,
  PlayerFormModuleType,
} from "../../types/form/player-form-modules";
import {
  AdminPreferences,
  isToggleEnabled,
  ToggleType,
} from "../../types/preferences/admin-preferences";
import {
  FormPreferences,
  UserPreferences,
} from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import CountryCriteriaSelector from "./country-criteria-selector";
import { MatchTypeConversionPreferencesComponent } from "./match-type-conversion-preferences-component";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (settings: UserPreferences) => void;
}

interface State {
  settings: UserPreferences;
  adminPreferences: AdminPreferences;
  playerFormModuleSettingsModalOpen: boolean;
  playerFormModuleModalType: PlayerFormModuleType;
  playerFormModuleModalData: PlayerFormData;
}

export class FormSettingsModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      adminPreferences: null,
      playerFormModuleSettingsModalOpen: false,
      playerFormModuleModalType: null,
      playerFormModuleModalData: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe(
        (settings: UserPreferences) => this.setState({ settings })
      )
    );
    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (adminPreferences: AdminPreferences) =>
          this.setState({ adminPreferences })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private setFormPreference(property: string, value: number | boolean): void {
    this.setState({
      settings: {
        ...this.state.settings,
        formPreferences: {
          ...this.state.settings.formPreferences,
          [property]: value,
        },
      },
    });
  }

  private updateSinceDate(value: SinceDate): void {
    this.setState({
      settings: {
        ...this.state.settings,
        formPreferences: {
          ...this.state.settings.formPreferences,
          sinceDate: value,
        },
      },
    });
  }

  private setFormSettings(formPreferences: FormPreferences) {
    this.setState({
      settings: {
        ...this.state.settings,
        formPreferences,
      },
    });
  }

  public render() {
    const splitPowerplay = isToggleEnabled(
      this.state.adminPreferences,
      ToggleType.SPLIT_POWERPLAY_AND_NON_POWERPLAY
    );
    const regressionConfig = [
      {
        title: "Player Regression Value",
        property: "playerRegressionValue",
      },

      {
        title: "Ground Regression Value",
        property: "groundRegressionValue",
      },
    ];
    const historicStatsWeightConfig = [
      {
        title: "Ground Stats Weight in Player Stats",
        property: "groundStatsInPlayerFormCalculationWeight",
      },
      {
        title: "Batsman Stats Weight in Bowler Stats",
        property: "batsmanStatsInBowlerFormCalculationWeight",
      },
      {
        title: "Bowler Stats Weight in Batsman Stats",
        property: "bowlerStatsInBatsmanFormCalculationWeight",
      },
    ];
    const ballWeightConfig = [
      {
        title: "First Innings Weight",
        property: "firstInningsWeight",
      },
      {
        title: "Second Innings Weight",
        property: "secondInningsWeight",
      },
      {
        title: "Powerplay Weight",
        property: "powerplayWeight",
      },
    ];
    const experienceLimitsConfig = splitPowerplay
      ? [
          {
            title: "Number of Balls for Full Experience in Powerplay",
            property: "ballsForBattingExperiencePowerplay",
          },

          {
            title: "Number of Balls for Full Experience in Non-Powerplay",
            property: "ballsForBattingExperienceNonPowerplay",
          },
        ]
      : [
          {
            title: "Number of Balls for Full Experience",
            property: "ballsForBattingExperience",
          },
        ];

    return (
      <CreationDialog
        open={this.props.open}
        label={"Form Settings"}
        invalid={false}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.settings)}
        proceedText="OK"
        colour="#535455"
      >
        {this.state.settings && (
          <div className="form-settings-modal-body">
            <div className="form-settings-subheading">
              Global Form Module Settings
            </div>
            {regressionConfig.map((config) => (
              <NumberSelector
                key={config.property}
                label={config.title}
                min={0}
                max={1}
                step={0.001}
                decimalPlaces={3}
                initial={this.state.settings.formPreferences[config.property]}
                onValid={(valid) =>
                  this.setFormPreference(config.property, valid)
                }
              />
            ))}
            <div className="checkbox-and-label-stretch">
              <div>Use Country Stats to Temper Ground Stats</div>
              <Checkbox
                checked={
                  this.state.settings.formPreferences
                    .useCountryStatsInGroundFormCalculation
                }
                onChange={(event) =>
                  this.setFormPreference(
                    "useCountryStatsInGroundFormCalculation",
                    event.target.checked
                  )
                }
                disabled={false}
              />
            </div>
            {historicStatsWeightConfig.map((config) => (
              <NumberSelector
                key={config.property}
                label={config.title}
                min={0}
                max={1}
                step={0.01}
                decimalPlaces={2}
                initial={this.state.settings.formPreferences[config.property]}
                onValid={(valid) =>
                  this.setFormPreference(config.property, valid)
                }
              />
            ))}
            <NumberSelector
              label="Number of Matches to count bowler desired balls"
              min={0}
              max={100000000}
              initial={this.getBowlerSpecialityMatchesLimit()}
              onValid={(valid) => this.setBowlerSpecialityMatchesLimit(valid)}
            />
            {experienceLimitsConfig.map((config) => (
              <NumberSelector
                key={config.property}
                label={config.title}
                min={0}
                max={100000000}
                initial={this.state.settings.formPreferences[config.property]}
                onValid={(valid) =>
                  this.setFormPreference(config.property, valid)
                }
              />
            ))}
            <div className="dialog-enum-selector">
              <div>Timespan</div>
              <EnumSelector
                enumObject={SinceDate}
                value={this.state.settings.formPreferences.sinceDate}
                readableValues={sinceDateNames}
                onSelect={(sinceDate) => this.updateSinceDate(sinceDate)}
                disabled={false}
                classes="form-settings-enum-selector"
              />
            </div>
            {ballWeightConfig.map((config) => (
              <NumberSelector
                key={config.property}
                label={config.title}
                min={0}
                max={2}
                step={0.01}
                decimalPlaces={2}
                initial={this.state.settings.formPreferences[config.property]}
                onValid={(valid) =>
                  this.setFormPreference(config.property, valid)
                }
              />
            ))}
            <MatchTypeConversionPreferencesComponent
              userPreferences={this.state.settings}
              onUpdate={(formSettings) => this.setFormSettings(formSettings)}
            />

            <CountryCriteriaSelector
              heading={"Country Weighting"}
              subHeading={"Individual Country Weights"}
              label1={"All Country Weights"}
              label2={"Country"}
              emptyMessage={"Add country weightings using the box above"}
              allWeights={
                this.state.settings &&
                this.state.settings.formPreferences &&
                this.state.settings.formPreferences.allCountryWeights
              }
              individualWeights={
                this.state.settings &&
                this.state.settings.formPreferences &&
                this.state.settings.formPreferences.countryWeights
              }
              readableNames={countryNames}
              images={countryFlags}
              unavailableKeys={this.getUnavailableCountries()}
              handleAllWeightsChange={(value) =>
                this.handleAllWeightsChange(value)
              }
              handleEntrySelected={(key) => this.handleEntrySelected(key)}
              handleEntryChanged={(key, value) =>
                this.handleEntryChanged(key, value)
              }
              handleEntryRemoved={(key) => this.handleEntryRemoved(key)}
            />
          </div>
        )}
      </CreationDialog>
    );
  }

  private handleAllWeightsChange(newValue) {
    this.setState({
      settings: {
        ...this.state.settings,
        formPreferences: {
          ...this.state.settings.formPreferences,
          allCountryWeights: newValue,
        },
      },
    });
  }

  private handleEntryChanged(key: Country, value: number) {
    return this.handleGroundWeightsMapChanged(key, value);
  }

  private handleEntrySelected(key: Country) {
    this.handleGroundWeightsMapChanged(key, 1);
  }

  private handleEntryRemoved(key: Country) {
    this.handleGroundWeightsMapChanged(key, null);
  }

  private handleGroundWeightsMapChanged(key: Country, value: number) {
    const map: Map<Country, number> =
      this.state.settings.formPreferences.countryWeights;
    if (value === null) {
      map.delete(key);
    } else {
      map.set(key, value);
    }
    this.setState({
      settings: {
        ...this.state.settings,
        formPreferences: {
          ...this.state.settings.formPreferences,
          countryWeights: map,
        },
      },
    });
  }

  private getUnavailableCountries(): Country[] {
    const map: Map<Country, number> =
      this.state.settings.formPreferences.countryWeights;
    return Array.from(map.keys());
  }

  private setBowlerSpecialityMatchesLimit(valid: number): void {
    const playerFormModules = this.state.settings.playerFormModules;
    const bowlerSpecialityPhaseData = playerFormModules.get(
      PlayerFormModuleType.BOWLER_SPECIALITY_PHASE
    ) as BowlerSpecialityPlayerFormData;
    bowlerSpecialityPhaseData.matchesLimit = valid;
    playerFormModules.set(
      PlayerFormModuleType.BOWLER_SPECIALITY_PHASE,
      bowlerSpecialityPhaseData
    );
    this.setState({
      settings: {
        ...this.state.settings,
        playerFormModules,
      },
    });
  }

  private getBowlerSpecialityMatchesLimit(): number {
    return (
      this.state.settings.playerFormModules.get(
        PlayerFormModuleType.BOWLER_SPECIALITY_PHASE
      ) as BowlerSpecialityPlayerFormData
    ).matchesLimit;
  }
}
