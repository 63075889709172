import { Chip, MenuItem, Select } from "@mui/material";
import { Component } from "react";
import { BowlerTypeRule } from "../../../types/simulator/modules/bowling-attack-modules";
import { propsEqual } from "../../component-utils";

interface Option<Key> {
  object: Key;
  optionText: string;
}

interface Props<Enum, Key extends keyof Enum> {
  enumObject: Enum;
  label: string;
  readableValues: Record<Key, string>;
  initialRules: BowlerTypeRule[];
  ruleIndex: number;
  attributeName: string;
  onUpdate: (rules: BowlerTypeRule[]) => void;
  disabled: boolean;
}

interface State<K> {
  options: Option<K>[];
}

export abstract class BowlerTypeRulesEnumMultiSelector<
  Enum,
  Key extends keyof Enum
> extends Component<Props<Enum, Key>, State<Key>> {
  public constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount(): void {
    this.updateOptions();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateOptions();
    }
  }

  private updateOptions() {
    const options: Option<Key>[] = [];
    Object.keys(this.props.enumObject).forEach((value) => {
      const option = {
        object: this.props.enumObject[value],
        optionText: this.props.readableValues[value],
      };
      options.push(option);
    });
    this.setState({ options });
  }

  private alreadySelected(type: Key): boolean {
    return (
      !!this.props.initialRules &&
      this.props.initialRules.length > this.props.ruleIndex &&
      this.props.initialRules[this.props.ruleIndex][
        this.props.attributeName
      ].find((t) => t === type) !== undefined
    );
  }

  private handleSelection(type: Key): void {
    if (
      !!this.props.initialRules &&
      this.props.initialRules.length > this.props.ruleIndex
    ) {
      const updatedRules = this.props.initialRules;
      updatedRules[this.props.ruleIndex][this.props.attributeName].push(type);
      this.props.onUpdate(updatedRules);
    }
  }

  private removeSelection(type: Key): void {
    if (
      !!this.props.initialRules &&
      this.props.initialRules.length > this.props.ruleIndex
    ) {
      const updatedRules = this.props.initialRules;
      const elementIndex =
        updatedRules[this.props.ruleIndex][this.props.attributeName].indexOf(
          type
        );
      if (elementIndex > -1) {
        updatedRules[this.props.ruleIndex][this.props.attributeName].splice(
          elementIndex,
          1
        );
      }
      this.props.onUpdate(updatedRules);
    }
  }

  public render() {
    return (
      <div>
        <span>{this.props.label + ":"}</span>
        <div>
          <Select
            className="enum-multi-select"
            value={""}
            placeholder={"Select Primary Bowl Type"}
            onChange={(selection) =>
              this.handleSelection(selection.target.value as Key)
            }
            disabled={this.props.disabled}
            variant="standard"
          >
            {this.state.options.find(
              (option: Option<Key>) => !this.alreadySelected(option.object)
            ) === undefined && (
              <MenuItem disabled={true}>None Available</MenuItem>
            )}

            {this.state.options.map(
              (option: Option<Key>, index: number) =>
                !this.alreadySelected(option.object) && (
                  <MenuItem
                    key={`bowl-type-${index}`}
                    value={option.object.toString()}
                  >
                    {this.props.readableValues[option.object]}
                  </MenuItem>
                )
            )}
          </Select>

          {this.state.options.find((option: Option<Key>) =>
            this.alreadySelected(option.object)
          ) === undefined && <div className="italic">None selected</div>}

          <div className="enum-multi-select-already-selected">
            {this.state.options.map(
              (option: Option<Key>, index: number) =>
                this.alreadySelected(option.object) && (
                  <Chip
                    label={this.props.readableValues[option.object]}
                    key={`already-selected-${index}`}
                    variant="outlined"
                    onDelete={() => this.removeSelection(option.object)}
                  />
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}
