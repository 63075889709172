import React from "react";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { BattingStatByBallDisplay } from "./batting-stat-by-ball-display";

type Props = {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
};

export default function BatsmanAccelerationDisplay({
  currentStats,
  comparedStats,
  comparedUserName,
  onArrayElementValid,
  onUpdate,
}: Props): React.JSX.Element {
  const upToBalls = currentStats?.playerStats?.upToBalls || [];

  const batsmanAccelerationConfig = [
    {
      title: "Up to ball..",
      index: 0,
      minBallNumber: 0,
      maxBallNumber: upToBalls[1] ? upToBalls[1] - 1 : 0,
      classes: "up-to-ball-1",
    },
    {
      title: "Up to ball..",
      index: 1,
      minBallNumber: upToBalls[0] ? upToBalls[0] + 1 : 0,
      maxBallNumber: 100000000,
      classes: "up-to-ball-2",
    },
    {
      title: "After..",
      index: 2,
      minBallNumber: upToBalls[1] || 0,
      maxBallNumber: 0,
      classes: "up-to-ball-3",
    },
  ];

  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">Batsman Acceleration</div>
      <div className="batting-stats-modal-3-columns">
        {batsmanAccelerationConfig.map(
          ({ title, index, minBallNumber, maxBallNumber, classes }) => (
            <BattingStatByBallDisplay
              key={index}
              title={title}
              index={index}
              minBallNumber={minBallNumber}
              maxBallNumber={maxBallNumber}
              currentStats={currentStats}
              comparedStats={comparedStats}
              comparedUserName={comparedUserName}
              onArrayElementValid={onArrayElementValid}
              classes={classes}
              onUpdate={onUpdate}
            />
          )
        )}
      </div>
    </div>
  );
}
