import { Component } from "react";
import { SimulatorRoute } from "../../types/route-helpers";
import { CircularProgress } from "@mui/material";
import { SimulationResult } from "../../types/simulator/simulation-result";

interface Props {
  simulationResult: SimulationResult;
  simulationLoading: boolean;
}

export class PlayerTableRowSimulationLoadingData extends Component<Props, {}> {
  public render() {
    return (
      <SimulatorRoute>
        {!this.props.simulationResult && !this.props.simulationLoading && (
          <div className="italic">No Simulation Data</div>
        )}
        {this.props.simulationLoading && (
          <div className="stats-loading">
            Loading...
            <CircularProgress size={"2em"} />
          </div>
        )}
      </SimulatorRoute>
    );
  }
}
