import { Slider } from "@mui/material";
import { ChangeEvent, Component } from "react";
import { Bar } from "react-chartjs-2";
import { Subscription } from "rxjs";
import { OutcomeByPhaseLine } from "../../services/percent-distribution-service";
import { BowlOutcome, bowlOutcomeNames } from "../../types/enums/bowl-outcome";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../types/enums/push-bracket";
import { services } from "../../types/services";
import { PercentDistributionBiasData } from "../../types/stats/ground-stats";
import { buildChartOptions } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NumberArcSlider } from "./number-arc-slider";

const chartOptions = buildChartOptions("top", 1);

interface Props {
  biases: PercentDistributionBiasData;
  updateValue: (property: PercentDistributionValue, value: number) => void;
}

interface State {
  pushBracketIndex: number;
  strikeRate: number;
  outcomesByPhase: Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>;
}

export type PercentDistributionValue =
  | "boundaryToRunsBias"
  | "fourToSixBias"
  | "oneToTwoBias"
  | "oneAndTwoToThreeBias";

export class PercentsDistributionBiasAdjuster extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      pushBracketIndex: 0,
      strikeRate: 120,
      outcomesByPhase: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.percentDistributionService.outcomesByPhaseSubject.subscribe(
        (
          outcomesByPhase: Map<
            PushBracket,
            Map<BowlOutcome, OutcomeByPhaseLine>
          >
        ) => this.setState({ outcomesByPhase })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private stateLoaded(): boolean {
    return !!this.state.outcomesByPhase;
  }

  private buildChartData() {
    const percents: number[] = services.percentDistributionService.getPercents(
      this.state.outcomesByPhase,
      Object.values(PushBracket)[this.state.pushBracketIndex],
      this.state.strikeRate / 100,
      this.props.biases
    );
    const chartData = {
      labels: Object.values(BowlOutcome).map((v) => bowlOutcomeNames[v]),
      datasets: [
        {
          yAxisID: "yAxis",
          label: "Percents Distriubtion",
          data: percents,
          backgroundColor: "#348feb",
          borderColor: "#36A2EB",
        },
      ],
    };

    return chartData;
  }

  public render() {
    return (
      <div className="percent-distribution-container">
        <div className="percent-distribution-buttons">
          <TooltipIconButton
            title="Previous"
            disabled={this.state.pushBracketIndex === 0}
            onClick={() =>
              this.setState({
                pushBracketIndex: this.state.pushBracketIndex - 1,
              })
            }
            icon="chevron_left"
          />
          {
            humanReadablePushBrackets[
              Object.values(PushBracket)[this.state.pushBracketIndex]
            ]
          }
          <TooltipIconButton
            title="Next"
            disabled={
              this.state.pushBracketIndex ===
              Object.keys(PushBracket).length - 1
            }
            onClick={() =>
              this.setState({
                pushBracketIndex: this.state.pushBracketIndex + 1,
              })
            }
            icon="navigate_next"
          />
        </div>
        {!!this.props.biases && this.stateLoaded() && (
          <div className="percent-distribution-chart">
            <div className="bias-adjustment-knobs">
              <NumberArcSlider
                initialValue={this.props.biases.boundaryToRunsBias}
                onChange={(value: number) =>
                  this.props.updateValue("boundaryToRunsBias", value)
                }
                propertyDescription="Boundaries to Runs Bias"
              />
              <NumberArcSlider
                initialValue={this.props.biases.fourToSixBias}
                onChange={(value: number) =>
                  this.props.updateValue("fourToSixBias", value)
                }
                propertyDescription="Fours to Sixes Bias"
              />
              <NumberArcSlider
                initialValue={this.props.biases.oneToTwoBias}
                onChange={(value: number) =>
                  this.props.updateValue("oneToTwoBias", value)
                }
                propertyDescription="Ones to Twos Bias"
              />
              <NumberArcSlider
                initialValue={this.props.biases.oneAndTwoToThreeBias}
                onChange={(value: number) =>
                  this.props.updateValue("oneAndTwoToThreeBias", value)
                }
                propertyDescription="Ones and Twos to Threes Bias"
              />
            </div>
            <div>
              Distribution Preview
              <hr />
              <Bar
                width="500px"
                height="400px"
                options={chartOptions}
                data={this.buildChartData()}
              />
              <div className="strike-rate-slider-container">
                <div>Strike Rate</div>
                <div className="weighting-slider">
                  <Slider
                    aria-label="Strike Rate"
                    step={1}
                    min={0}
                    max={300}
                    valueLabelDisplay="auto"
                    value={this.state.strikeRate}
                    onChange={(evt: Event, newValue: number | number[]) =>
                      this.setState({ strikeRate: newValue as number })
                    }
                    onChangeCommitted={(
                      evt: ChangeEvent,
                      newValue: number | number[]
                    ) => this.setState({ strikeRate: newValue as number })}
                  />
                  <div className="weighting-slider-label">
                    {this.state.strikeRate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
