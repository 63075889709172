import { Subject, ReplaySubject } from "rxjs";
import { BowlOutcome } from "../types/enums/bowl-outcome";
import { PushBracket } from "../types/enums/push-bracket";
import { services, showMessage } from "../types/services";
import { PercentDistributionBiasData } from "../types/stats/ground-stats";
import { nanSafe, nanSafeWithDefault } from "../types/util-functions";
import { HttpService } from "./http-service";
import { MatchType } from "../types/enums/match-type";
import { Match } from "../types/entities/match";

export interface OutcomeByPhaseLine {
  pushBracket: PushBracket;
  outcome: BowlOutcome;
  gradient: number;
  yintercept: number;
}

export class PercentDistributionService {
  public outcomesByPhaseSubject: Subject<
    Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>
  > = new ReplaySubject(1);
  private httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public refresh() {
    services.currentGameService.currentMatchSubject.subscribe(
      (match: Match) => {
        if (!!match) {
          this.getOutcomesByPhase(match.matchType).then(
            (
              outcomesByPhase: Map<
                PushBracket,
                Map<BowlOutcome, OutcomeByPhaseLine>
              >
            ) => {
              this.outcomesByPhaseSubject.next(outcomesByPhase);
            }
          );
        }
      }
    );
  }

  public multiplyPercentDistributionBiases(
    confidenceLimit: number,
    globalBiases: PercentDistributionBiasData,
    globalConfidence: number,
    biases: PercentDistributionBiasData,
    confidence: number
  ): PercentDistributionBiasData {
    if (confidenceLimit === 0) {
      return biases;
    } else {
      const globalWeighting =
        confidenceLimit === 0
          ? 1
          : Math.min(1, globalConfidence / confidenceLimit);

      const weightedByGlobal: PercentDistributionBiasData = {
        boundaryToRunsBias:
          1 -
          globalWeighting +
          globalBiases.boundaryToRunsBias * globalWeighting,
        fourToSixBias:
          1 - globalWeighting + globalBiases.fourToSixBias * globalWeighting,
        oneToTwoBias:
          1 - globalWeighting + globalBiases.oneToTwoBias * globalWeighting,
        oneAndTwoToThreeBias:
          1 -
          globalWeighting +
          globalBiases.oneAndTwoToThreeBias * globalWeighting,
      };

      const propertyWeighting =
        confidenceLimit === 0 ? 1 : Math.min(1, confidence / confidenceLimit);

      return {
        boundaryToRunsBias:
          (1 -
            propertyWeighting +
            propertyWeighting * biases.boundaryToRunsBias) *
          weightedByGlobal.boundaryToRunsBias,
        fourToSixBias:
          (1 - propertyWeighting + propertyWeighting * biases.fourToSixBias) *
          weightedByGlobal.fourToSixBias,
        oneToTwoBias:
          (1 - propertyWeighting + propertyWeighting * biases.oneToTwoBias) *
          weightedByGlobal.oneToTwoBias,
        oneAndTwoToThreeBias:
          (1 -
            propertyWeighting +
            propertyWeighting * biases.oneAndTwoToThreeBias) *
          weightedByGlobal.oneAndTwoToThreeBias,
      };
    }
  }

  public getPercents(
    outcomesByPhase: Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>,
    pushBracket: PushBracket,
    strikeRate: number,
    biases: PercentDistributionBiasData
  ): number[] {
    const src: number[] = Object.values(BowlOutcome).map((outcome) =>
      this.solveForSr(outcomesByPhase, pushBracket, strikeRate, outcome)
    );

    const sr = src[1] + 2 * src[2] + 3 * src[3] + 4 * src[4] + 6 * src[5];
    const currentSrFromBoundaries = Math.max(0, 4 * src[4] + 6 * src[5]);
    const boundaryToRunsRatio = Math.min(
      1,
      nanSafe((biases.boundaryToRunsBias * currentSrFromBoundaries) / sr)
    );

    const [fourPercent, sixPercent] = this.getFourAndSixPercent(
      biases,
      src,
      sr,
      boundaryToRunsRatio
    );
    const newSrFromRuns = Math.max(
      0,
      Math.min(sr, (1 - boundaryToRunsRatio) * sr)
    );
    const threePercent = this.getThreePercent(biases, src, newSrFromRuns);
    const [onePercent, twoPercent] = this.getOneAndTwoPercent(
      biases,
      src,
      newSrFromRuns,
      threePercent
    );

    return this.avertNegativePercents(sr, [
      1 - onePercent - twoPercent - threePercent - fourPercent - sixPercent,
      onePercent,
      twoPercent,
      threePercent,
      fourPercent,
      sixPercent,
    ]);
  }

  private getThreePercent(
    biases: PercentDistributionBiasData,
    src: number[],
    newSrFromRuns: number
  ): number {
    const amountRuns = src[1] + src[2] + src[3];
    const oneProportion = nanSafe(src[1] / amountRuns);
    const twoProportion = nanSafe(src[2] / amountRuns);
    const threeProportion = nanSafe(src[3] / amountRuns);
    const oneAndTwoToThreeRatio = Math.min(
      1,
      nanSafe((biases.oneAndTwoToThreeBias * (src[1] + src[2])) / amountRuns)
    );
    const avgRunsFromOneTwoOrThree =
      oneProportion + 2 * twoProportion + 3 * threeProportion;
    const runsPercentMultiplier = Math.max(
      1,
      nanSafe(avgRunsFromOneTwoOrThree / newSrFromRuns)
    );
    return Math.min(
      newSrFromRuns / 3,
      nanSafe((1 - oneAndTwoToThreeRatio) / runsPercentMultiplier)
    );
  }

  private getFourAndSixPercent(
    biases: PercentDistributionBiasData,
    src: number[],
    sr: number,
    boundaryToRunsRatio: number
  ): [number, number] {
    const amountBoundaries = src[4] + src[5];
    const fourProportion = nanSafe(src[4] / amountBoundaries);
    const fourToSixRatio = Math.min(1, biases.fourToSixBias * fourProportion);
    const newSrFromBoundaries = Math.max(
      0,
      Math.min(sr, sr * boundaryToRunsRatio)
    );
    const avgBoundarySr = 6 - fourToSixRatio * 2;
    const boundariesPercentMultiplier = Math.max(
      1,
      avgBoundarySr / newSrFromBoundaries
    );
    return [
      fourToSixRatio / boundariesPercentMultiplier,
      (1 - fourToSixRatio) / boundariesPercentMultiplier,
    ];
  }

  private getOneAndTwoPercent(
    biases: PercentDistributionBiasData,
    src: number[],
    newSrFromRuns: number,
    threePercent: number
  ): [number, number] {
    const oneToTwoRatio = Math.min(
      1,
      nanSafe((biases.oneToTwoBias * src[1]) / (src[1] + src[2]))
    );
    const newSrFromOnesAndTwos = newSrFromRuns - threePercent * 3;
    const avgRunsFromOneOrTwo = 2 - oneToTwoRatio;
    const oneAndTwoPercentMultiplier = Math.max(
      1,
      avgRunsFromOneOrTwo / newSrFromOnesAndTwos
    );

    if (this.equalsWithThreshold(newSrFromOnesAndTwos, 0, 0.000001)) {
      return [0, 0];
    } else {
      return [
        oneToTwoRatio / oneAndTwoPercentMultiplier,
        (1 - oneToTwoRatio) / oneAndTwoPercentMultiplier,
      ];
    }
  }

  private avertNegativePercents(goalSr: number, src: number[]): number[] {
    const sumPercents = src[1] + src[2] + src[3] + src[4] + src[5];
    const maxPercent = 1 - 0.1;

    if (sumPercents > maxPercent) {
      const percentToIncreaseDot = 1 - maxPercent / sumPercents;
      src[1] = src[1] - src[1] * percentToIncreaseDot;
      src[2] = src[2] - src[2] * percentToIncreaseDot;
      src[3] = src[3] - src[3] * percentToIncreaseDot;
      src[4] = src[4] - src[4] * percentToIncreaseDot;
      src[5] = src[5] - src[5] * percentToIncreaseDot;

      const srDifference =
        goalSr - (src[1] + 2 * src[2] + 3 * src[3] + 4 * src[4] + 6 * src[5]);
      const amountRuns = src[1] + src[2] + src[3];
      const amountBoundaries = src[4] + src[5];

      const oneProportion = nanSafe(src[1] / amountRuns);
      const twoProportion = nanSafe(src[2] / amountRuns);
      const threeProportion = nanSafe(src[3] / amountRuns);
      const fourProportion = nanSafeWithDefault(src[4] / amountBoundaries, 0.5);

      const boundariesAddedPerPercent =
        4 * fourProportion + 6 * (1 - fourProportion);
      const runsLostPerPercent =
        oneProportion + 2 * twoProportion + 3 * threeProportion;
      const netIncreasePerPercent =
        boundariesAddedPerPercent - runsLostPerPercent;
      const amountToAddToFours = nanSafe(
        (fourProportion * srDifference) / netIncreasePerPercent
      );
      const amountToAddToSixes = nanSafe(
        ((1 - fourProportion) * srDifference) / netIncreasePerPercent
      );
      const amountToAddToBoundaries = amountToAddToSixes + amountToAddToFours;
      const amountToTakeAwayFromOne = amountToAddToBoundaries * oneProportion;
      const amountToTakeAwayFromTwo = amountToAddToBoundaries * twoProportion;
      const amountToTakeAwayFromThree =
        amountToAddToBoundaries * threeProportion;

      src[1] = src[1] - amountToTakeAwayFromOne;
      src[2] = src[2] - amountToTakeAwayFromTwo;
      src[3] = src[3] - amountToTakeAwayFromThree;
      src[4] = src[4] + amountToAddToFours;
      src[5] = src[5] + amountToAddToSixes;
      src[0] = 1 - src[1] - src[2] - src[3] - src[4] - src[5];
    }

    return src;
  }

  private equalsWithThreshold(
    a: number,
    b: number,
    threshold: number
  ): boolean {
    return Math.abs(a - b) <= threshold;
  }

  private solveForSr(
    outcomesByPhase: Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>,
    pushBracket: PushBracket,
    strikeRate: number,
    outcome: BowlOutcome
  ): number {
    const line: OutcomeByPhaseLine = outcomesByPhase
      .get(pushBracket)
      .get(outcome);
    return line.yintercept + strikeRate * line.gradient;
  }

  private async getOutcomesByPhase(
    matchType: MatchType
  ): Promise<Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>> {
    const params: Map<string, string> = new Map();
    params.set("matchType", matchType);
    return await this.httpService
      .get(`/api/percent-distribution-controller/get-outcomes-by-phase`, params)
      .then((response: any) => this.deserializeOutcomeByPhases(response))
      .catch((reason) => {
        showMessage(`Failed to load outcomes by phases: ${reason}`, "error");
        return null;
      });
  }

  private deserializeOutcomeByPhases(
    response: any
  ): Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>> {
    const outcomesByPhase: Map<
      PushBracket,
      Map<BowlOutcome, OutcomeByPhaseLine>
    > = new Map();
    Object.values(PushBracket).forEach((pushBracket: any) => {
      outcomesByPhase.set(pushBracket, new Map());
      Object.values(BowlOutcome).forEach((bowlOutcome: BowlOutcome) => {
        const outcomeByPhaseLine: OutcomeByPhaseLine =
          this.deserializeOutcomeByPhase(response[pushBracket][bowlOutcome]);
        outcomesByPhase.get(pushBracket).set(bowlOutcome, outcomeByPhaseLine);
      });
    });
    return outcomesByPhase;
  }

  private deserializeOutcomeByPhase(response: any): OutcomeByPhaseLine {
    return {
      pushBracket: response.pushBracket,
      outcome: response.outcome,
      gradient: response.gradient,
      yintercept: response.yintercept,
    };
  }
}
