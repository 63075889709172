import "../App.css";
import { Component } from "react";
import { services } from "../types/services";
import { Button } from "@mui/material";

export class LoginPage extends Component<any> {
  public render() {
    return (
      <div className="login-page full-push-background-light full-height">
        <div className="full-push-background front-page-text">
          <svg
            className="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64.57 44.4"
          >
            <g id="Layer_2" data-name="Layer 2">
              <path
                className="logo-stumps-light"
                d="M7.49,1.92,0,44.4H13.31l7.5-42.48Z"
              />
              <path
                className="logo-stumps-light"
                d="M27.46,10.56l-6,33.84h8.17l6-33.84Z"
              />
              <polygon
                className="logo-stumps-light"
                points="27.45 10.56 21.45 44.4 16.3 44.4 22.3 10.56 27.45 10.56"
              />
              <path
                className="logo-stumps-light"
                d="M45.64,0l-4,22.65a13.37,13.37,0,0,1,8.87-3.94L53.81,0ZM50,21.2a5.17,5.17,0,0,0-.55.07,9.46,9.46,0,0,0-1.06.21l-.52.15-.56.19-.44.18-.44.19a11,11,0,0,0-4,3.26c-.1.13-.2.26-.29.4l-.27.41c-.18.28-.34.57-.49.86s-.15.3-.21.45a7.5,7.5,0,0,0-.37.93,4.42,4.42,0,0,0-.15.48c-.07.21-.12.42-.17.63s-.05.24-.07.36-.05.27-.07.4a5.17,5.17,0,0,0-.07.55A10,10,0,0,0,40.22,32a10.87,10.87,0,0,0,6.18,9.81l.39-2.17,3.27-18.48ZM39,37.58,37.78,44.4H46v-.07A13.43,13.43,0,0,1,39,37.58Z"
              />
              <path
                className="logo-stumps-dark"
                d="M12.64,1.92,5.15,44.4h8.16l7.5-42.48Z"
              />
              <path
                className="logo-stumps-dark"
                d="M27.46,10.56l-6,33.84h8.17l6-33.84Z"
              />
              <path
                className="logo-stumps-light"
                d="M45.64,0l-4,22.65h0a13.39,13.39,0,0,0-3.4,5.94A12.91,12.91,0,0,0,37.78,32,13.21,13.21,0,0,0,39,37.58L37.78,44.4H32.63l6-33.84L40.49,0Z"
              />
              <path
                className="logo-stumps-dark"
                d="M45.64,0l-4,22.65a13.37,13.37,0,0,1,8.87-3.94L53.81,0ZM50,21.2a5.17,5.17,0,0,0-.55.07,9.46,9.46,0,0,0-1.06.21l-.52.15-.56.19-.44.18-.44.19a11,11,0,0,0-4,3.26c-.1.13-.2.26-.29.4l-.27.41c-.18.28-.34.57-.49.86s-.15.3-.21.45a7.5,7.5,0,0,0-.37.93,4.42,4.42,0,0,0-.15.48c-.07.21-.12.42-.17.63s-.05.24-.07.36-.05.27-.07.4a5.17,5.17,0,0,0-.07.55A10,10,0,0,0,40.22,32a10.87,10.87,0,0,0,6.18,9.81l.39-2.17,3.27-18.48ZM39,37.58,37.78,44.4H46v-.07A13.43,13.43,0,0,1,39,37.58Z"
              />
              <path
                className="logo-ball-light"
                d="M62,32A10.89,10.89,0,0,1,51.11,42.92a10.62,10.62,0,0,1-4.71-1.08A10.87,10.87,0,0,1,40.22,32a10,10,0,0,1,.06-1.11,5.17,5.17,0,0,1,.07-.55c0-.13,0-.27.07-.4s0-.24.07-.36.1-.42.17-.63a4.42,4.42,0,0,1,.15-.48,7.5,7.5,0,0,1,.37-.93c.06-.15.14-.3.21-.45s.31-.58.49-.86l.27-.41c.09-.14.19-.27.29-.4a11,11,0,0,1,4-3.26l.44-.19.44-.18.56-.19.52-.15a9.46,9.46,0,0,1,1.06-.21A5.17,5.17,0,0,1,50,21.2h.06c.34,0,.69-.05,1-.05a11.14,11.14,0,0,1,1.74.15L51,32l9-2.54,1.6-.46A10.54,10.54,0,0,1,62,32Z"
              />
              <path
                className="logo-ball-light"
                d="M64.57,26l-1.6.45h0L54,29l1.86-10.75a10.82,10.82,0,0,1,6.2,3.29h0A10.76,10.76,0,0,1,64.57,26Z"
              />
              <path
                className="logo-ball-dark"
                d="M63,26c0,.16,0,.3,0,.46h0L54,29l1.86-10.75a10.82,10.82,0,0,1,6.2,3.29h0A10.81,10.81,0,0,1,63,26Z"
              />
              <path
                className="logo-ball-dark"
                d="M60,29.5A10.88,10.88,0,0,1,49.11,39.92a11.14,11.14,0,0,1-2.32-.25,10.83,10.83,0,0,1-5.62-3.19,10.86,10.86,0,0,1-1-4.45,10,10,0,0,1,.06-1.11,5.17,5.17,0,0,1,.07-.55c0-.13,0-.27.07-.4s0-.24.07-.36.1-.42.17-.63a4.42,4.42,0,0,1,.15-.48,7.5,7.5,0,0,1,.37-.93c.06-.15.14-.3.21-.45s.31-.58.49-.86l.27-.41c.09-.14.19-.27.29-.4a11,11,0,0,1,4-3.26l.44-.19.44-.18.56-.19.52-.15a9.46,9.46,0,0,1,1.06-.21A5.17,5.17,0,0,1,50,21.2h.06c.34,0,.69-.05,1-.05a11.14,11.14,0,0,1,1.74.15L51,32Z"
              />
            </g>
          </svg>
          FULL PUSH
        </div>
        <div className="intro-text">
          <p>
            Full Push Systems bring real-time cricket modelling and score
            prediction to the people.
          </p>
          <p>
            For more info contact us at{" "}
            <a href="mailto:info@fullpush.co.uk">info@fullpush.co.uk</a>.
          </p>
          <Button
            onClick={() => services.keycloakService.login()}
            variant="contained"
          >
            Login
          </Button>
        </div>
      </div>
    );
  }
}
