import {
  Button,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Component } from "react";
import { Ball } from "../../../types/entities/ball";
import { GameState } from "../../../types/entities/game-state";
import { InningsConfiguration } from "../../../types/entities/innings-configuration";
import { MatchFormat } from "../../../types/entities/match-format";
import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { BowlInputKey } from "../../../types/enums/bowl-input-key";
import {
  DecisionType,
  ReturningRetiredDecision,
  SuperOverDecision,
} from "../../../types/enums/decision-type";
import { DismissalMethod } from "../../../types/enums/dismissal-method";
import { InningsStatisticType } from "../../../types/enums/statistic-type";
import { services, showMessage } from "../../../types/services";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import NumberSelector from "../../entity-management/entity-selectors/number-selector";
import { IconAndTextButton } from "../../navigation-bar/icon-and-text-button";
import {
  buildByes,
  buildDefault,
  buildExtras,
  buildLegByes,
  buildNoBall,
  buildOut,
  buildRuns,
  buildWide,
  calculateBallText,
} from "./bowl-input-templates";
import { FormatChangeModal } from "./format-change-modal";
import { PenaltyModal } from "./penalty-modal";
import { ReturningRetiredDecisionModal } from "./returning-retired-decision-modal";
import { SubstituteModal } from "./substitute-modal";
import { SuperOverDecisionModal } from "./super-over-decision-modal";
import { TimedOutModal } from "./timed-out-modal";
import { WicketModal } from "./wicket-modal";

interface Props {
  disabled: boolean;
  keyListenerDisabled: boolean;
  gameState: GameState;
  matchFormat: MatchFormat;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  onModalOpen: () => void;
  onModalClose: () => void;
}

interface State {
  runs: number;
  runsValid: boolean;
  dismissal: boolean;
  manOut: UUID;
  fielder: UUID;
  howOut: DismissalMethod;
  extras: number;
  extrasValid: boolean;
  toggleButtonState: string[];
  loading: boolean;
  lastBall: number;
  moreMenuOpen: boolean;
  moreMenuAnchorElement: null | HTMLElement;
  outModalOpen: boolean;
  fieldingSubstituteModalOpen: boolean;
  battingSubstituteModalOpen: boolean;
  timedOutModalOpen: boolean;
  penaltyModalOpen: boolean;
  returningRetiredModalOpen: boolean;
  formatChangeModalOpen: boolean;
  superOverModalOpen: boolean;
  extrasBeingEdited: boolean;
  runsBeingEdited: boolean;
}

interface BowlInputMetadata {
  keys: string[];
  appliesWhen?: () => boolean;
  function: Function;
}

export class BowlInput extends Component<Props, State> {
  private static readonly SEND_BALL_DEBOUNCE_TIME: number = 300;
  private bowlInputMetadata: Record<BowlInputKey, BowlInputMetadata> = {
    WICKET: {
      keys: ["o", "O"],
      function: () => this.handleOutButtonClick(),
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
    },
    RUNS: {
      keys: ["r", "R", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: (key) =>
        this.setState({
          runs: key === "r" ? this.state.runs + 1 : Number(key),
        }),
    },
    EXTRAS: {
      keys: ["e", "E"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.setState({ extras: this.state.extras + 1 }),
    },
    WIDE: {
      keys: ["w", "W"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.toggleState("wide"),
    },
    NO_BALL: {
      keys: ["n", "N"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.toggleState("noBall"),
    },
    BYE: {
      keys: ["b", "B"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.toggleState("bye"),
    },
    LEG_BYE: {
      keys: ["l", "L"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.toggleState("legBye"),
    },
    GO: {
      keys: ["Enter"],
      appliesWhen: () =>
        !this.props.gameState.ended &&
        !this.state.loading &&
        !this.keyListenerDisabled(),
      function: () => this.sendBall(),
    },
    UNDO: {
      keys: ["u", "U"],
      appliesWhen: () =>
        this.props.gameState &&
        this.props.gameState.eventSequence > 1 &&
        !this.keyListenerDisabled(),
      function: () => services.currentGameService.undo(),
    },
    RESET: {
      keys: ["Escape"],
      appliesWhen: () =>
        !this.props.disabled &&
        !this.props.gameState.ended &&
        !this.keyListenerDisabled(),
      function: () => this.resetButtons(),
    },
  };

  constructor(props) {
    super(props);
    this.state = this.buildDefaultState();
  }

  componentDidMount(): void {
    this.resetButtons();
    document.addEventListener("keydown", this.handleKeyEvent);
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.resetButtons();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyEvent);
  }

  private resetButtons(): void {
    this.setState(this.buildDefaultState());
  }

  private keyListenerDisabled(): boolean {
    return (
      this.state.outModalOpen ||
      this.state.penaltyModalOpen ||
      this.state.fieldingSubstituteModalOpen ||
      this.state.battingSubstituteModalOpen ||
      this.state.timedOutModalOpen ||
      this.state.returningRetiredModalOpen ||
      this.state.formatChangeModalOpen ||
      this.state.superOverModalOpen ||
      this.state.extrasBeingEdited ||
      this.state.runsBeingEdited ||
      this.state.extrasBeingEdited ||
      this.props.keyListenerDisabled
    );
  }

  private buildDefaultState(): State {
    return {
      runsValid: true,
      runs: 0,
      manOut: null,
      fielder: null,
      howOut: null,
      dismissal: false,
      extras: 0,
      extrasValid: true,
      toggleButtonState: [],
      loading: false,
      lastBall: this.state ? this.state.lastBall : 0,
      moreMenuOpen: false,
      moreMenuAnchorElement: null,
      outModalOpen: false,
      fieldingSubstituteModalOpen: false,
      battingSubstituteModalOpen: false,
      timedOutModalOpen: false,
      penaltyModalOpen: false,
      formatChangeModalOpen: false,
      extrasBeingEdited: false,
      runsBeingEdited: false,
      returningRetiredModalOpen:
        this.props.gameState &&
        this.props.gameState.requiredDecisions &&
        this.props.gameState.requiredDecisions.find(
          (decision) => decision === DecisionType.RETURNING_RETIRED_BATSMAN
        ) !== undefined,
      superOverModalOpen:
        this.props.gameState &&
        this.props.gameState.requiredDecisions &&
        this.props.gameState.requiredDecisions.find(
          (decision) => decision === DecisionType.SUPER_OVER
        ) !== undefined,
    };
  }

  private readonly handleKeyEvent = (event) => {
    Object.values(this.bowlInputMetadata).forEach((element) => {
      if (element.keys.find((key) => key === event.key) !== undefined) {
        if (element.appliesWhen === undefined || element.appliesWhen()) {
          element.function(event.key);
          event.preventDefault();
        }
      }
    });
  };

  private handleOutButtonClick() {
    if (this.state.dismissal) {
      this.setState({
        dismissal: false,
        manOut: null,
        fielder: null,
        howOut: null,
      });
    } else {
      this.props.onModalOpen();
      this.setState({ outModalOpen: true });
    }
  }

  private sendBall(): void {
    if (!this.props.disabled) {
      if (
        Date.now() - this.state.lastBall >
        BowlInput.SEND_BALL_DEBOUNCE_TIME
      ) {
        const ball: Ball = this.buildBallFromState();
        this.setState({ loading: true, lastBall: Date.now() }, () =>
          services.ballService.addForState(ball).then(
            () => {
              const text = calculateBallText(
                ball,
                this.props.gameState.getBattingTeam(this.props.matchFormat),
                this.props.squad1,
                this.props.squad2,
                this.props.matchFormat
              );
              showMessage("Added ball: " + text);
              this.setState({ loading: false });
            },
            (reason) => {
              showMessage("Error adding ball - " + reason);
              this.setState({ loading: false });
            }
          )
        );
      }
    }
  }

  private buildBallFromState(): Ball {
    let ball: Ball = buildDefault();
    ball = buildRuns(ball, this.state.runs);
    ball = buildExtras(ball, this.state.extras);
    ball = buildWide(ball, this.toggleGroupHasProperty("wide"));
    ball = buildNoBall(ball, this.toggleGroupHasProperty("noBall"));
    ball = buildLegByes(ball, this.toggleGroupHasProperty("legBye"));
    ball = buildByes(ball, this.toggleGroupHasProperty("bye"));
    ball = buildOut(
      ball,
      this.state.dismissal,
      this.state.manOut,
      this.state.howOut,
      this.state.fielder
    );

    return ball;
  }

  private setRuns(value) {
    this.setState({ runsValid: true, runs: value });
  }

  private setRunsInvalid() {
    this.setState({ runsValid: false });
  }

  private setExtras(value) {
    this.setState({ extrasValid: true, extras: value });
  }

  private setExtrasInvalid() {
    this.setState({ extrasValid: false });
  }

  private allValid(): boolean {
    return this.state.runsValid && this.state.extrasValid;
  }

  private toggleGroupHasProperty(prop: string): boolean {
    return (
      this.state.toggleButtonState.find((entry) => entry === prop) !== undefined
    );
  }

  private toggleState(prop: string) {
    const newState = !this.toggleGroupHasProperty(prop);
    if (newState) {
      this.handleToggleChange([...this.state.toggleButtonState, prop]);
    } else {
      this.handleToggleChange(
        this.state.toggleButtonState.filter((state) => state !== prop)
      );
    }
  }

  private handleToggleChange(newValues: string[]) {
    if (newValues.length === 0) {
      this.setExtras(0);
    } else if (this.state.toggleButtonState.length < newValues.length) {
      const difference: string[] = newValues.filter(
        (x) => !this.state.toggleButtonState.includes(x)
      );
      const newValue = difference[0];
      switch (newValue) {
        case "noBall":
          newValues = newValues.filter((x) => x !== "wide");
          break;
        case "wide":
          newValues = ["wide"];
          break;
        case "legBye":
          newValues = newValues.filter((x) => x !== "bye" && x !== "wide");
          break;
        case "bye":
          newValues = newValues.filter((x) => x !== "legBye" && x !== "wide");
          break;
        default:
          break;
      }
      let extras = 0;
      newValues.forEach(
        (x) =>
          (extras =
            extras +
            (x === "noBall"
              ? this.props.matchFormat.noBallPenalty
              : x === "wide"
              ? this.props.matchFormat.widePenalty
              : 1))
      );
      this.setExtras(extras);
    } else if (this.state.toggleButtonState.length > newValues.length) {
      const difference: string[] = this.state.toggleButtonState.filter(
        (x) => !newValues.includes(x)
      );
      const removedValue = difference[0];
      switch (removedValue) {
        case "noBall":
          this.setExtras(
            this.state.extras - this.props.matchFormat.noBallPenalty
          );
          break;
        case "wide":
          this.setExtras(
            this.state.extras - this.props.matchFormat.widePenalty
          );
          break;
        default:
          this.setExtras(this.state.extras - 1);
          break;
      }
    }
    this.setState({ toggleButtonState: newValues });
  }

  private addSubstituteFielder(
    player: Player,
    playerReplaced: Player,
    isConcussionSub: boolean
  ) {
    this.setState({ fieldingSubstituteModalOpen: false });
    this.props.onModalClose();
    const bowlingTeam = this.props.gameState.getBowlingTeam(
      this.props.matchFormat
    );
    services.currentGameService.substitute(
      player,
      playerReplaced,
      isConcussionSub,
      bowlingTeam === 1 ? this.props.team1.teamId : this.props.team2.teamId
    );
  }

  private addSubstituteBatsman(
    player: Player,
    playerReplaced: Player,
    isConcussionSub: boolean
  ) {
    this.setState({ battingSubstituteModalOpen: false });
    this.props.onModalClose();
    const battingTeam = this.props.gameState.getBattingTeam(
      this.props.matchFormat
    );
    services.currentGameService.substitute(
      player,
      playerReplaced,
      isConcussionSub,
      battingTeam === 1 ? this.props.team1.teamId : this.props.team2.teamId
    );
  }

  private timeOutPlayer(player: Player) {
    this.setState({ timedOutModalOpen: false });
    this.props.onModalClose();
    services.currentGameService.timeOut(player);
  }

  private addPenalty(runs: number, innings: number) {
    this.setState({ penaltyModalOpen: false });
    this.props.onModalClose();
    services.currentGameService.addPenalty(runs, innings);
  }

  private changeFormat(
    duckworthTarget: number,
    duckworthTargetInnings: number,
    matchFormat: MatchFormat
  ) {
    services.matchFormatService
      .add(matchFormat)
      .then((createdMatchFormat) => {
        services.currentGameService.changeFormat(
          createdMatchFormat.matchFormatId,
          duckworthTarget,
          duckworthTargetInnings
        );
        this.setState({ formatChangeModalOpen: false });
        this.props.onModalClose();
      })
      .catch((reason) =>
        showMessage(`Failed to add new format: ${reason}`, "error")
      );
  }

  private canSurge(): boolean {
    if (
      !this.props.gameState ||
      !this.props.matchFormat ||
      this.props.gameState.matchFormatId.value !==
        this.props.matchFormat.matchFormatId.value
    ) {
      return false;
    }

    const currentOverParts: number[] = this.props.gameState.calculateOverParts(
      this.props.gameState.ballNumber,
      this.props.gameState.innings
    );
    const currentOver: number = currentOverParts[0];
    const ballInOver: number = currentOverParts[1];
    const inningsConfiguration: InningsConfiguration =
      this.props.matchFormat.inningsConfiguration[
        this.props.gameState.innings - 1
      ];

    return (
      ballInOver === 0 &&
      currentOver >= inningsConfiguration.surgeAvailableFromOver &&
      this.props.gameState.getInningsStat(InningsStatisticType.SURGE_OVER) ===
        -1 &&
      inningsConfiguration.surge
    );
  }

  public render() {
    return (
      <div className="bowl-input">
        <div className="bowl-input-button-container-1">
          <IconAndTextButton
            title="Undo"
            disabled={
              !!this.props.gameState && this.props.gameState.eventSequence <= 1
            }
            onClick={() => services.currentGameService.undo()}
            icon="undo"
          />

          {this.props.gameState &&
            !this.props.gameState.ended &&
            this.props.gameState.started && (
              <IconAndTextButton
                title="Send Ball"
                onClick={() => this.sendBall()}
                icon="forward"
                disabled={
                  this.props.disabled ||
                  this.keyListenerDisabled() ||
                  !this.allValid() ||
                  this.state.loading
                }
              />
            )}
        </div>
        {this.props.gameState &&
          !this.props.gameState.ended &&
          this.props.gameState.started && (
            <div className="bowl-input-content">
              <div className="runs-and-extras-input">
                <NumberSelector
                  label="Runs: "
                  min={0}
                  max={10}
                  initial={this.state.runs}
                  onValid={(newValue) => this.setRuns(newValue)}
                  onInvalid={() => this.setRunsInvalid()}
                  disabled={this.props.disabled}
                  onManualEdit={() => this.setState({ runsBeingEdited: true })}
                  onManualEditFinished={() =>
                    this.setState({ runsBeingEdited: false })
                  }
                />

                <NumberSelector
                  label="Extras: "
                  min={0}
                  max={10}
                  initial={this.state.extras}
                  onValid={(newValue) => this.setExtras(newValue)}
                  onInvalid={() => this.setExtrasInvalid()}
                  disabled={this.props.disabled}
                  onManualEdit={() =>
                    this.setState({ extrasBeingEdited: true })
                  }
                  onManualEditFinished={() =>
                    this.setState({ extrasBeingEdited: false })
                  }
                />
              </div>
              <div className="bowl-input-button-container-2">
                <ToggleButtonGroup
                  value={this.state.toggleButtonState}
                  onChange={(event, value) => this.handleToggleChange(value)}
                  aria-label="text formatting"
                >
                  <ToggleButton
                    value={"bye"}
                    className={
                      "bowl-input-button" +
                      (this.props.disabled || this.keyListenerDisabled()
                        ? " disabled-input"
                        : "")
                    }
                    disabled={this.props.disabled || this.keyListenerDisabled()}
                  >
                    Bye
                  </ToggleButton>
                  <ToggleButton
                    value={"legBye"}
                    className={
                      "bowl-input-button" +
                      (this.props.disabled || this.keyListenerDisabled()
                        ? " disabled-input"
                        : "")
                    }
                    disabled={this.props.disabled || this.keyListenerDisabled()}
                  >
                    Leg Bye
                  </ToggleButton>
                  <ToggleButton
                    value={"noBall"}
                    className={
                      "bowl-input-button" +
                      (this.props.disabled || this.keyListenerDisabled()
                        ? " disabled-input"
                        : "")
                    }
                    disabled={this.props.disabled || this.keyListenerDisabled()}
                  >
                    No Ball
                  </ToggleButton>
                  <ToggleButton
                    value={"wide"}
                    className={
                      "bowl-input-button" +
                      (this.props.disabled || this.keyListenerDisabled()
                        ? " disabled-input"
                        : "")
                    }
                    disabled={this.props.disabled || this.keyListenerDisabled()}
                  >
                    Wide
                  </ToggleButton>
                </ToggleButtonGroup>

                <Button
                  onClick={() => this.handleOutButtonClick()}
                  variant="outlined"
                  className={
                    "bowl-input-button" +
                    (this.props.disabled || this.keyListenerDisabled()
                      ? " disabled-input"
                      : "") +
                    (this.state.dismissal ? " dismissal" : "")
                  }
                  disabled={this.props.disabled || this.keyListenerDisabled()}
                >
                  Out
                </Button>

                <Button
                  onClick={(event) =>
                    this.setState({
                      moreMenuOpen: true,
                      moreMenuAnchorElement: event.currentTarget,
                    })
                  }
                  className="bowl-input-button"
                  variant="outlined"
                >
                  More..
                </Button>
                <Menu
                  anchorEl={this.state.moreMenuAnchorElement}
                  open={this.state.moreMenuOpen}
                  onClose={() =>
                    this.setState({
                      moreMenuOpen: false,
                      moreMenuAnchorElement: null,
                    })
                  }
                >
                  <MenuItem
                    onClick={() => {
                      this.setState({
                        moreMenuOpen: false,
                        fieldingSubstituteModalOpen: true,
                      });
                      this.props.onModalOpen();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>swap_vert</Icon>
                    </ListItemIcon>
                    Add Substitute Fielder
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({
                        moreMenuOpen: false,
                        battingSubstituteModalOpen: true,
                      });
                      this.props.onModalOpen();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>swap_vert</Icon>
                    </ListItemIcon>
                    Add Substitute Batsman
                  </MenuItem>
                  <MenuItem
                    disabled={!this.canSurge()}
                    onClick={() => {
                      services.currentGameService.surge();
                      this.setState({ moreMenuOpen: false });
                    }}
                  >
                    <ListItemIcon>
                      <Icon>bolt</Icon>
                    </ListItemIcon>
                    Surge
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      services.currentGameService.declare();
                      this.setState({ moreMenuOpen: false });
                    }}
                  >
                    <ListItemIcon>
                      <Icon>front_hand</Icon>
                    </ListItemIcon>
                    Declare
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({
                        moreMenuOpen: false,
                        penaltyModalOpen: true,
                      });
                      this.props.onModalOpen();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>warning</Icon>
                    </ListItemIcon>
                    Penalty Runs
                  </MenuItem>
                  <MenuItem
                    disabled={
                      this.props.gameState &&
                      this.props.gameState.batsman1 !== null &&
                      this.props.gameState.batsman2 !== null
                    }
                    onClick={() => {
                      this.setState({
                        moreMenuOpen: false,
                        timedOutModalOpen: true,
                      });
                      this.props.onModalOpen();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>alarm</Icon>
                    </ListItemIcon>
                    Batsman Timed Out
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.setState({
                        moreMenuOpen: false,
                        formatChangeModalOpen: true,
                      });
                      this.props.onModalOpen();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>cloud</Icon>
                    </ListItemIcon>
                    Format Change
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}

        {this.props.gameState && this.props.matchFormat && (
          <div>
            <WicketModal
              open={this.state.outModalOpen}
              onCancel={() => {
                this.setState({ outModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(manOut, howOut, fielder) => {
                this.setState(
                  {
                    outModalOpen: false,
                    dismissal: true,
                    manOut: manOut.playerId,
                    howOut,
                    fielder: fielder.playerId,
                  },
                  () => this.sendBall()
                );
                this.props.onModalClose();
              }}
              gameState={this.props.gameState}
              squad1={this.props.squad1}
              squad2={this.props.squad2}
              matchFormat={this.props.matchFormat}
            />

            <SuperOverDecisionModal
              gameState={this.props.gameState}
              open={this.state.superOverModalOpen}
              onCancel={() => services.currentGameService.undo()}
              onProceed={(decision: SuperOverDecision) => {
                services.currentGameService.clearMatchFormat();
                services.currentGameService.makeDecision(
                  DecisionType.SUPER_OVER,
                  decision
                );
              }}
              team1={this.props.team1}
              team2={this.props.team2}
            />

            <ReturningRetiredDecisionModal
              gameState={this.props.gameState}
              open={this.state.returningRetiredModalOpen}
              onCancel={() => services.currentGameService.undo()}
              onProceed={(decision: ReturningRetiredDecision) =>
                services.currentGameService.makeDecision(
                  DecisionType.RETURNING_RETIRED_BATSMAN,
                  decision
                )
              }
              squad={
                this.props.gameState &&
                this.props.gameState.getBattingTeam(this.props.matchFormat) ===
                  1
                  ? this.props.squad1
                  : this.props.squad2
              }
            />

            <SubstituteModal
              open={this.state.fieldingSubstituteModalOpen}
              onCancel={() => {
                this.setState({ fieldingSubstituteModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(player, playerReplaced, isConcussionSub) =>
                this.addSubstituteFielder(
                  player,
                  playerReplaced,
                  isConcussionSub
                )
              }
              squad1={this.props.squad1}
              squad2={this.props.squad2}
              gameState={this.props.gameState}
              team={this.props.gameState.getBowlingTeam(this.props.matchFormat)}
            />

            <SubstituteModal
              open={this.state.battingSubstituteModalOpen}
              onCancel={() => {
                this.setState({ battingSubstituteModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(player, playerReplaced, isConcussionSub) =>
                this.addSubstituteBatsman(
                  player,
                  playerReplaced,
                  isConcussionSub
                )
              }
              squad1={this.props.squad1}
              squad2={this.props.squad2}
              gameState={this.props.gameState}
              team={this.props.gameState.getBattingTeam(this.props.matchFormat)}
            />

            <TimedOutModal
              open={this.state.timedOutModalOpen}
              onCancel={() => {
                this.setState({ timedOutModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(player) => this.timeOutPlayer(player)}
              squad1={this.props.squad1}
              squad2={this.props.squad2}
              gameState={this.props.gameState}
              matchFormat={this.props.matchFormat}
            />

            <PenaltyModal
              open={this.state.penaltyModalOpen}
              onCancel={() => {
                this.setState({ penaltyModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(runs, innings) => this.addPenalty(runs, innings)}
              team1={this.props.team1}
              team2={this.props.team2}
              matchFormat={this.props.matchFormat}
            />

            <FormatChangeModal
              open={this.state.formatChangeModalOpen}
              onCancel={() => {
                this.setState({ formatChangeModalOpen: false });
                this.props.onModalClose();
              }}
              onProceed={(
                duckworthTarget,
                duckworthTargetInnings,
                matchFormat
              ) =>
                this.changeFormat(
                  duckworthTarget,
                  duckworthTargetInnings,
                  matchFormat
                )
              }
              team1={this.props.team1}
              team2={this.props.team2}
              currentMatchFormat={this.props.matchFormat}
            />
          </div>
        )}
      </div>
    );
  }
}
