import { Component } from "react";
import { Button, Chip, MenuItem, Select, TextField } from "@mui/material";
import { batTypeNames, BatType } from "../../../types/enums/bat-type";
import { bowlTypeNames, BowlType } from "../../../types/enums/bowl-type";
import { EnumSelector } from "../entity-selectors/enum-selector";
import { Image } from "../../../types/entities/image";
import { Player } from "../../../types/entities/player";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { services } from "../../../types/services";
import { Subscription } from "rxjs";
import {
  humanReadableLongBowlTypes,
  LongBowlType,
} from "../../../types/enums/long-bowl-type";
import {
  humanReadablePlayingRoles,
  PlayingRole,
} from "../../../types/enums/playing-role";
import { DeveloperRoute } from "../../../types/route-helpers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MuiTextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";

interface Props {
  value: Player;
  open: boolean;
  label: string;
  buttonText: string;
  onProceed: Function;
  onCancel: Function;
  isNew: boolean;
}

interface State {
  value: Player;
  imageCropped: string;
}

export class EditPlayerComponent extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      imageCropped:
        "data:image/png;base64, " +
        services.imageService.getDefaultImage().data,
    };
  }

  componentDidMount(): void {
    if (services.imageService.getImageRecord()) {
      this.subscriptions.push(
        services.imageService.getImageRecord().subscribe(() => {
          this.updateImage();
        })
      );
    }
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateImage() {
    services.imageService
      .getImageForPlayer(this.state.value)
      .then((image: Image) => {
        const imageData = image.data.startsWith("data:")
          ? image.data
          : "data:image/png;base64, " + image.data;
        services.imageService.crop(imageData, 0.8).then((imageCropped) => {
          this.setState({ imageCropped });
        });
      });
  }

  public valid(): boolean {
    return (
      this.state.value &&
      !!this.state.value.longName &&
      this.state.value.longName.trim().length > 0 &&
      !!this.state.value.shortName &&
      this.state.value.shortName.trim().length > 0 &&
      !!this.state.value.batType &&
      !!this.state.value.bowlType
    );
  }

  private disabled(): boolean {
    return !this.props.isNew && this.state.value && !this.state.value.createdBy;
  }

  private onFileLoad(e) {
    const file: File = e.target.files[0];
    services.imageService.upload(file, this.state.value, (image) =>
      this.handleImageUpload(image)
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        onProceed={() => this.props.onProceed(this.state.value)}
        onCancel={() => this.props.onCancel()}
        invalid={!this.valid()}
        disabled={this.disabled()}
        proceedText={this.props.buttonText}
      >
        {!!this.state.value && (
          <div className="edit-player-container">
            <div>
              <div className="dialog-content">
                Name:
                <br />
                <TextField
                  value={this.state.value.longName}
                  onChange={(event) => this.handleNameChange(event)}
                  placeholder={"Name"}
                  variant="outlined"
                  disabled={this.disabled()}
                />
              </div>
              <div className="dialog-content">
                <span>Picture:</span>
                {this.state.value.headshot && (
                  <div>
                    <img
                      className="player-image"
                      alt="player"
                      width={100}
                      height={125}
                      src={this.state.imageCropped}
                    />
                  </div>
                )}
                {!this.disabled() && (
                  <div>
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      id="select-image"
                      onChange={(e) => this.onFileLoad(e)}
                    />
                    <label htmlFor="select-image">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Upload Image
                      </Button>
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="dialog-content">
              Date of Birth:
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  openTo="day"
                  value={this.state.value.dob}
                  onChange={(newValue) => this.handleDobChange(newValue)}
                  renderInput={(params) => <MuiTextField {...params} />}
                  disabled={this.disabled()}
                />
              </LocalizationProvider>
            </div>

            <EnumSelector
              classes="dialog-content"
              enumObject={PlayingRole}
              label="Player Role"
              value={
                !!this.state.value.playingRoles &&
                this.state.value.playingRoles.length > 0
                  ? this.state.value.playingRoles[0]
                  : null
              }
              readableValues={humanReadablePlayingRoles}
              onSelect={(value) => this.handlePlayingRoleChange(value)}
              disabled={this.disabled()}
            />

            <EnumSelector
              classes="dialog-content"
              enumObject={BatType}
              label="Bat Type"
              value={this.state.value.batType}
              readableValues={batTypeNames}
              onSelect={(value) => this.handleBatTypeChange(value)}
              disabled={this.disabled()}
            />

            <EnumSelector
              classes="dialog-content"
              enumObject={BowlType}
              label="Bowl Type"
              value={this.state.value.bowlType}
              readableValues={bowlTypeNames}
              onSelect={(value) => this.handleBowlTypeChange(value)}
              disabled={this.disabled()}
            />

            <div className="dialog-content">
              Specific bowler types:
              <Select
                className="enum-multi-select"
                value={""}
                placeholder={"Select Specific Bowl Types"}
                onChange={(selection) =>
                  this.addLongBowlType(selection.target.value as LongBowlType)
                }
                disabled={this.disabled()}
                variant="standard"
              >
                {Object.keys(LongBowlType).every((l) =>
                  this.longBowlTypeAlreadySelected(LongBowlType[l])
                ) && <MenuItem disabled={true}>None Available</MenuItem>}

                {Object.keys(LongBowlType).map(
                  (longBowlType: string, index: number) =>
                    !this.longBowlTypeAlreadySelected(
                      LongBowlType[longBowlType]
                    ) && (
                      <MenuItem
                        key={`bowl-type-${index}`}
                        value={LongBowlType[longBowlType]}
                      >
                        {humanReadableLongBowlTypes[longBowlType]}
                      </MenuItem>
                    )
                )}
              </Select>
              {(this.state.value.longBowlTypes === null ||
                this.state.value.longBowlTypes.length === 0) && (
                <div className="italic">None selected</div>
              )}
              <div className="enum-multi-select-already-selected">
                {this.state.value.longBowlTypes.map(
                  (longBowlType: LongBowlType, index: number) => (
                    <Chip
                      label={humanReadableLongBowlTypes[longBowlType]}
                      key={`already-selected-${index}`}
                      variant="outlined"
                      onDelete={() => this.removeLongBowlType(longBowlType)}
                      disabled={this.disabled()}
                    />
                  )
                )}
              </div>
            </div>

            {!!this.state.value.espnNum && (
              <div className="dialog-content">
                <DeveloperRoute>
                  <Button
                    onClick={() =>
                      services.playerService.updateScrapedPlayer(
                        this.state.value.playerId
                      )
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Re-Scrape Player
                  </Button>
                </DeveloperRoute>
              </div>
            )}
          </div>
        )}
      </CreationDialog>
    );
  }

  private addLongBowlType(type: LongBowlType): void {
    this.setState({
      value: {
        ...this.state.value,
        longBowlTypes: !!this.state.value.longBowlTypes
          ? [...this.state.value.longBowlTypes, type]
          : [type],
      },
    });
  }

  private longBowlTypeAlreadySelected(longBowlType: LongBowlType) {
    return (
      this.state.value.longBowlTypes &&
      this.state.value.longBowlTypes.find((l) => longBowlType === l) !==
        undefined
    );
  }

  private removeLongBowlType(longBowlType: LongBowlType) {
    this.setState({
      value: {
        ...this.state.value,
        longBowlTypes:
          !!this.state.value.longBowlTypes &&
          this.state.value.longBowlTypes.filter((l) => l !== longBowlType),
      },
    });
  }

  private handleNameChange(newValue): void {
    this.setState({
      value: {
        ...this.state.value,
        longName: newValue.target.value,
        shortName: newValue.target.value,
      },
    });
  }

  private handleBowlTypeChange(value: BowlType): void {
    this.setState({
      value: {
        ...this.state.value,
        bowlType: value,
      },
    });
  }

  private handlePlayingRoleChange(value: PlayingRole): void {
    this.setState({
      value: {
        ...this.state.value,
        playingRoles: [value],
      },
    });
  }

  private handleBatTypeChange(value: BatType): void {
    this.setState({
      value: {
        ...this.state.value,
        batType: value,
      },
    });
  }

  private handleDobChange(dob) {
    const dateOfBirth: Date = dob.$d as Date;
    this.setState({
      value: {
        ...this.state.value,
        dob: dateOfBirth.getTime(),
      },
    });
  }

  private handleImageUpload(image: Image) {
    this.setState(
      {
        value: {
          ...this.state.value,
          headshot: image.imageId,
        },
      },
      () => services.imageService.addImageToCache(image.imageId.value, image)
    );
  }
}
