import React, { Component } from "react";
import { Chart } from "react-chartjs-2";
import { Player } from "../../types/entities/player";
import {
  PlayerFormChartType,
  playerFormChartTypeStatsExtractors,
} from "../../types/enums/player-form-chart-type";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { HistoricPlayerStatsWrapper } from "../../types/stats/historic-form";
import { SimulatorOversDistributionDisplay } from "../simulator-page/simulator-overs-distribution-display";

interface Props {
  selectedPlayers: Player[];
  playerStats: Map<string, HistoricPlayerStatsWrapper>;
  selectedChartType: PlayerFormChartType;
  adminPreferences: AdminPreferences;
}

export class PlayerFormChart extends Component<Props> {
  private buildLabels() {
    const allLabels: number[] = [];

    if (!!this.props.playerStats) {
      for (let player of this.props.selectedPlayers) {
        const playerWrapper = this.props.playerStats.get(player.playerId.value);
        if (!!playerWrapper) {
          const historicPlayerStats = playerWrapper.historicPlayerStats;
          for (let date of historicPlayerStats.battingConfidenceByDate.keys()) {
            allLabels.push(date);
          }
        }
      }
    }

    return [...new Set(allLabels)].sort();
  }

  public render() {
    const datasets = [];
    const allLabels: number[] = this.buildLabels();
    if (!!this.props.playerStats) {
      for (let player of this.props.selectedPlayers) {
        const playerWrapper = this.props.playerStats.get(player.playerId.value);
        if (!!playerWrapper) {
          const lineData: any[] = [];
          const historicPlayerStats = playerWrapper.historicPlayerStats;
          let latestValue = null;
          for (let date of allLabels) {
            const extractionFunction: (
              HistoricPlayerStats,
              adminPreferences,
              number
            ) => number =
              playerFormChartTypeStatsExtractors[this.props.selectedChartType];
            const value = extractionFunction(
              historicPlayerStats,
              this.props.adminPreferences,
              date
            );
            latestValue = !!value ? value : latestValue;
            lineData.push(latestValue);
          }

          datasets.push({
            type: "line" as const,
            label: player.longName,
            fill: true,
            labels: allLabels,
            data: lineData,
            borderColor:
              SimulatorOversDistributionDisplay.playerColours[
                datasets.length % 11
              ],
            backgroundColor:
              SimulatorOversDistributionDisplay.playerColours[
                datasets.length % 11
              ] + "20",
          });
        }
      }
    }

    return (
      <div className="player-form-chart">
        <Chart
          type="bar"
          options={{
            scales: {
              x: {
                type: "time",
                stacked: false,
              },
            },
          }}
          data={{
            labels: allLabels,
            datasets,
          }}
        />
      </div>
    );
  }
}
