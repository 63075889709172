import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

import {
  humanReadablePushBrackets,
  PushBracket,
} from "../../../types/enums/push-bracket";
import { SimulationResult } from "../../../types/simulator/simulation-result";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
  latestResult: SimulationResult;
  innings: number;
};

// Colours defined in App.css
const bracketColors: Record<PushBracket, string> = {
  [PushBracket.BLOCKING]: "#496999",
  [PushBracket.DEFENSIVE]: "green",
  [PushBracket.NORMAL]: "#edd57e",
  [PushBracket.PUSHING]: "coral",
  [PushBracket.FULL_PUSH]: "crimson",
};

function buildChartData(ballsInEachBracket: Map<PushBracket, number>) {
  if (!ballsInEachBracket || ballsInEachBracket.size === 0) {
    return null;
  }

  const labels: string[] = [];
  const data: number[] = [];
  const backgroundColors: string[] = [];
  let totalBalls = 0;

  Object.values(PushBracket).forEach((pushBracket) => {
    const bracketName = humanReadablePushBrackets[pushBracket];
    const balls = ballsInEachBracket.get(pushBracket);
    const color = bracketColors[pushBracket];

    labels.push(bracketName);
    data.push(balls);
    backgroundColors.push(color);

    totalBalls += balls;
  });

  const percentages = data.map((value) => (value / totalBalls) * 100);

  return {
    labels,
    datasets: [
      {
        label: "Percentage of Balls in Each Push Bracket",
        data: percentages,
        backgroundColor: backgroundColors,
      },
    ],
  };
}

export default function BallsInEachBracket({
  loading,
  collapsed,
  toggleCollapse,
  latestResult,
  innings,
}: Readonly<Props>): React.JSX.Element {
  const chartData = buildChartData(
    latestResult.ballsInEachBracket.get(innings)
  );

  return (
    <div className="simulator-container">
      <div className="simulator-title">
        Percentage of Balls in Each Bracket
        <TooltipIconButton
          icon={collapsed ? "expand_more" : "expand_less"}
          onClick={() => toggleCollapse()}
          title={collapsed ? "Expand" : "Collapse"}
        />
      </div>
      {!collapsed && (
        <LoadingOverlay
          active={loading}
          spinner
          text={loading ? "Simulating..." : ""}
        >
          <div
            className="chart-container"
            style={{
              maxWidth: "400px",
              maxHeight: "400px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Pie data={chartData} />
          </div>
        </LoadingOverlay>
      )}
    </div>
  );
}
