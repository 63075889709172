import { Box, Button } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import {
  HistoricPushMessage,
  MatchTypeSkew,
  PushBracketUpdateMessage,
} from "../../services/historic-push-service";
import { MatchType } from "../../types/enums/match-type";
import { PushBracket } from "../../types/enums/push-bracket";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { calculateProgress } from "../component-utils";
import { LinearProgressWithLabel } from "../historic-player-stats-page/all-player-stats-component";

import { HistoricEventTable } from "./historic-event-table";
import { MatchTypeSkewsDisplay } from "./match-type-skews-display";
import { PushBracketDisplay } from "./push-bracket-display";
import { ScrapedMatchFilter } from "./scraped-match-filter";

interface State {
  generatingHistoricPush: boolean;
  generatingPushBrackets: boolean;
  latestUpdate: HistoricPushMessage;
  pushBrackets: Map<MatchType, [PushBracket, number][]>;
  matchTypeSkews: Map<MatchType, Map<MatchType, MatchTypeSkew>>;
  adminPreferences: AdminPreferences;
  latestPushBracketUpdate: PushBracketUpdateMessage;
}

export class HistoricMatchesPage extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private static readonly DEFAULT_STATE = {
    generatingHistoricPush: false,
    generatingPushBrackets: false,
    latestUpdate: null,
    pushBrackets: null,
    matchTypeSkews: null,
    adminPreferences: null,
    latestPushBracketUpdate: null,
  };

  constructor(props) {
    super(props);
    this.state = HistoricMatchesPage.DEFAULT_STATE;
  }

  componentDidMount() {
    this.subscriptions.push(
      services.historicPushService.historicPushUpdateSubject.subscribe(
        (update: HistoricPushMessage) => {
          if (update.size === update.done) {
            this.setState({
              latestUpdate: update,
              generatingHistoricPush: false,
            });
          } else {
            this.setState({ latestUpdate: update });
          }
        }
      )
    );
    this.subscriptions.push(
      services.historicPushService.pushBracketsSubject.subscribe(
        (update: Map<MatchType, [PushBracket, number][]>) => {
          this.setState({ pushBrackets: update });
        }
      )
    );
    this.subscriptions.push(
      services.historicPushService.matchTypeSkewSubject.subscribe(
        (update: Map<MatchType, Map<MatchType, MatchTypeSkew>>) => {
          this.setState({ matchTypeSkews: update });
        }
      )
    );
    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) => {
          this.setState({ adminPreferences: update });
        }
      )
    );
    this.subscriptions.push(
      services.historicPushService.pushBracketsUpdateSubject.subscribe(
        (update: PushBracketUpdateMessage) => {
          if (update.done === update.size) {
            this.setState({
              latestPushBracketUpdate: update,
              generatingPushBrackets: false,
            });
          } else {
            this.setState({ latestPushBracketUpdate: update });
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Scraped Matches</div>
            <div className="squad-buttons">
              <Button
                onClick={() =>
                  this.setState({ generatingHistoricPush: true }, () =>
                    services.historicPushService.recalculateHistoricPush()
                  )
                }
                variant={"contained"}
                color="primary"
                disabled={this.state.generatingHistoricPush}
              >
                RECALCULATE DEFAULT HISTORIC PUSH
              </Button>
              <Button
                onClick={() =>
                  this.setState({ generatingPushBrackets: true }, () =>
                    services.historicPushService.recalculatePushBrackets()
                  )
                }
                variant={"contained"}
                color="secondary"
                disabled={this.state.generatingHistoricPush}
              >
                RECALCULATE PUSH BRACKETS
              </Button>
            </div>
          </div>
          <div className="historic-matches-content">
            {this.state.generatingPushBrackets && (
              <div className="loading-bar">
                Loading...
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={calculateProgress(
                      this.state.latestPushBracketUpdate
                    )}
                  />
                </Box>
                {!!this.state.latestPushBracketUpdate && (
                  <div>
                    Calculating push brackets{" "}
                    {this.state.latestPushBracketUpdate.done}/
                    {this.state.latestPushBracketUpdate.size}{" "}
                    {this.state.latestPushBracketUpdate.message}
                  </div>
                )}
              </div>
            )}
            {!this.state.generatingHistoricPush &&
              !!this.state.pushBrackets && (
                <PushBracketDisplay pushBrackets={this.state.pushBrackets} />
              )}
            {!this.state.generatingHistoricPush &&
              !!this.state.matchTypeSkews && (
                <MatchTypeSkewsDisplay
                  adminPreferences={this.state.adminPreferences}
                  matchTypeSkews={this.state.matchTypeSkews}
                />
              )}
            {!this.state.generatingHistoricPush && <ScrapedMatchFilter />}
            {!this.state.generatingHistoricPush && <HistoricEventTable />}
            {this.state.generatingHistoricPush && (
              <div className="loading-bar">
                Loading...
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={calculateProgress(this.state.latestUpdate)}
                  />
                </Box>
                {!!this.state.latestUpdate && (
                  <div>
                    Calculating historic push for match{" "}
                    {this.state.latestUpdate.done}/
                    {this.state.latestUpdate.size}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </EmployeeRoute>
    );
  }
}
