import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import { ReturningRetiredDecision } from "../../../types/enums/decision-type";
import { StatisticType } from "../../../types/enums/statistic-type";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import { TeamPlayerSelector } from "../../entity-management/entity-selectors/team-player-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (decision: ReturningRetiredDecision) => void;
  squad: Squad;
  gameState: GameState;
}

interface State {
  decision: ReturningRetiredDecision;
}

export class ReturningRetiredDecisionModal extends Component<Props, State> {
  private static readonly DEFAULT_DECISION = {
    retiredBatsmanReturns: false,
    returningBatsman: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      decision: ReturningRetiredDecisionModal.DEFAULT_DECISION,
    };
  }

  componentDidMount(): void {
    this.setState({ decision: ReturningRetiredDecisionModal.DEFAULT_DECISION });
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({
        decision: ReturningRetiredDecisionModal.DEFAULT_DECISION,
      });
    }
  }

  private handleDecisionChange(event) {
    const decision: string = event.target.value;
    if (decision === "next_innings") {
      this.setState({
        decision: { ...this.state.decision, retiredBatsmanReturns: false },
      });
    } else {
      this.setState({
        decision: { ...this.state.decision, retiredBatsmanReturns: true },
      });
    }
  }

  private invalid(): boolean {
    return (
      this.state.decision.retiredBatsmanReturns &&
      this.state.decision.returningBatsman === null
    );
  }

  private getNonRetiredBatsmen(): UUID[] {
    return (
      this.props.squad &&
      this.props.gameState &&
      this.props.squad.players
        .filter((player) => {
          const dismissal = this.props.gameState.getPlayerStringStat(
            StatisticType.BATSMAN_DISMISSAL_METHOD,
            player.playerId
          );
          return dismissal !== "Retired Hurt";
        })
        .map((player) => player.playerId)
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Decision Required"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.decision)}
        proceedText="OK"
        colour="#ebae34"
      >
        <FormControl>
          <FormLabel>
            The batting team is all out except for retired hurt batsmen. Is a
            retired batsmen going to return?
          </FormLabel>
          <RadioGroup
            row
            onChange={(event) => this.handleDecisionChange(event)}
            name="decision-group"
            value={
              this.state.decision.retiredBatsmanReturns
                ? "continue_innings"
                : "next_innings"
            }
          >
            <FormControlLabel
              value={"continue_innings"}
              control={<Radio />}
              label={"Yes"}
            />
            <FormControlLabel
              value={"next_innings"}
              control={<Radio />}
              label={"No"}
            />
          </RadioGroup>
        </FormControl>

        {this.state.decision.retiredBatsmanReturns && (
          <TeamPlayerSelector
            selectedPlayer={
              this.state.decision.returningBatsman &&
              UUID.fromString(this.state.decision.returningBatsman)
            }
            squad={this.props.squad}
            notAvailable={this.getNonRetiredBatsmen()}
            label={"Returning Batsman:"}
            onSelect={(batsman: Player) =>
              this.setState({
                decision: {
                  ...this.state.decision,
                  returningBatsman: batsman.playerId.value,
                },
              })
            }
          />
        )}
      </CreationDialog>
    );
  }
}
