import { Button } from "@mui/material";
import { Component } from "react";

import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchType } from "../../types/enums/match-type";
import { UserPreferences } from "../../types/preferences/preferences";
import {
  services,
  showConfirmationDialog,
  showMessage,
} from "../../types/services";
import { GroundFormExplanation } from "../../types/stats/form-explanation";
import {
  GroundStats,
  GroundStatsWrapper,
} from "../../types/stats/ground-stats";
import { UUID } from "../../types/uuid";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { noSimulationsAllowed } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { FormSettingsModal } from "../squads-page/form-settings-modal";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import { batchUpdate } from "./ground-page-utils";

interface Props {
  ground: Ground;
  match: Match;
  currentStats: GroundStatsWrapper;
  comparedStats?: GroundStatsWrapper;
  comparedUsername?: string;
  matchFormat: MatchFormat;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
  onFormExplanation: (formExplanation: GroundFormExplanation) => void;
  onFailure: () => void;
  setLoadingThenExecute: (callback: () => void) => void;
  matchType: MatchType;
  userPreferences: UserPreferences;
}

interface State {
  formSettingsModalOpen: boolean;
  formExplanation: GroundFormExplanation;
}

export class GroundStatsGenerationButtons extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      formSettingsModalOpen: false,
      formExplanation: null,
    };
  }

  private regenerateGroundStats(ground: Ground, matchFormat: MatchFormat) {
    this.props.setLoadingThenExecute(() => {
      const groundId: UUID = ground.groundId;
      const matchFormatId: UUID = matchFormat.matchFormatId;

      services.groundStatsService
        .generateGroundStats(matchFormatId, groundId)
        .then((groundStats: GroundStats) => this.updateGroundStats(groundStats))
        .catch((reason) => {
          showMessage(`Could not generate ground stats: ${reason}`, "error");
          this.props.onFailure();
        });
    });
  }

  private explainGroundStats(ground: Ground, matchFormat: MatchFormat): void {
    this.props.setLoadingThenExecute(() => {
      const groundId: UUID = ground.groundId;
      const matchFormatId: UUID = matchFormat.matchFormatId;

      services.groundStatsService
        .explainGroundStats(matchFormatId, groundId)
        .then((formExplanation: GroundFormExplanation) =>
          this.props.onFormExplanation(formExplanation)
        )
        .catch((reason) => {
          showMessage(
            `Could not generate ground stats explanation: ${reason}`,
            "error"
          );
          this.props.onFailure();
        });
    });
  }

  private getDefaultGroundStats() {
    this.props.setLoadingThenExecute(() => {
      services.groundStatsService
        .getDefaultGroundStats(this.props.matchType)
        .then((groundStats: GroundStats) => this.updateGroundStats(groundStats))
        .catch((reason) => {
          showMessage(
            `Could not get global default ground stats: ${reason}`,
            "error"
          );
          this.props.onFailure();
        });
    });
  }

  private getGroundDefaults() {
    this.props.setLoadingThenExecute(() => {
      services.groundStatsService
        .getGroundDefaultStats(
          this.props.match.matchId,
          this.props.ground.groundId
        )
        .then((groundStats: GroundStats) => {
          if (!!groundStats) {
            this.updateGroundStats(groundStats);
          } else {
            showMessage(`No defaults saved`, "error");
            this.props.onFailure();
          }
        })
        .catch((reason) => {
          showMessage(`Could not get default ground stats: ${reason}`, "error");
          this.props.onFailure();
        });
    });
  }

  private saveGroundDefaults() {
    services.groundStatsService.saveGroundDefaultStats(
      this.props.match.matchType,
      this.props.ground.groundId,
      this.props.currentStats.groundStats
    );
  }

  private updateGroundStats(groundStats: GroundStats) {
    const currentStats = new GroundStatsWrapper(
      this.props.currentStats.groundStatsId,
      this.props.currentStats.createdBy,
      this.props.currentStats.createdAt,
      this.props.currentStats.name,
      groundStats,
      this.props.currentStats.matchType
    );

    this.props.onUpdate(currentStats);
  }

  private refreshSim() {
    services.simulationService.removeSimulationResults();
    services.simulationService.simulate();
  }

  private updatePreferences(settings: UserPreferences) {
    services.userService
      .updateUserPreferences(settings)
      .then(() => this.setState({ formSettingsModalOpen: false }));
  }

  private onStealHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.props.comparedStats.getAllProperties(),
      this.props.onUpdate
    );
  };

  public render() {
    return (
      <div className="ground-stats-recalculation-buttons">
        <ComparedUserSelector />
        {this.props.comparedStats && (
          <StealStatsButton
            comparedUserName={this.props.comparedUsername}
            disabled={areStatsEqual(
              this.props.currentStats,
              this.props.comparedStats,
              this.props.comparedStats.getAllProperties(),
              "groundStats"
            )}
            tooltipMessage={getToolTipMessage(
              this.props.currentStats,
              this.props.comparedStats,
              this.props.comparedStats.getAllProperties(),
              this.props.comparedUsername,
              "groundStats"
            )}
            onClickHandler={this.onStealHandler}
          />
        )}
        <Button
          onClick={() =>
            showConfirmationDialog(
              () =>
                this.regenerateGroundStats(
                  this.props.ground,
                  this.props.matchFormat
                ),
              "Regenerate Ground Stats",
              "Are you sure?"
            )
          }
          variant="contained"
          color="secondary"
        >
          Regenerate Ground Stats
        </Button>
        <Button
          onClick={() =>
            this.explainGroundStats(this.props.ground, this.props.matchFormat)
          }
          variant="contained"
          color="secondary"
        >
          Explain Ground Stats
        </Button>
        <Button
          onClick={() =>
            showConfirmationDialog(
              () => this.getDefaultGroundStats(),
              "Reset Ground Stats to Default",
              "Are you sure?"
            )
          }
          variant="contained"
          color="primary"
        >
          Global Default
        </Button>
        <Button
          onClick={() =>
            showConfirmationDialog(
              () => this.getGroundDefaults(),
              "Restore Saved Stats",
              "Are you sure?"
            )
          }
          variant="contained"
          style={{ color: "#ffffff", backgroundColor: "#3fd166" }}
        >
          Ground Default
        </Button>
        <Button
          onClick={() => this.saveGroundDefaults()}
          variant="contained"
          color="primary"
          style={{ color: "#ffffff", backgroundColor: "#b63fd1" }}
        >
          Update Ground Default
        </Button>
        <RunSimulatorButton
          isDisabled={noSimulationsAllowed(
            this.props.match,
            this.props.userPreferences
          )}
          onClick={() => this.refreshSim()}
        />
        <TooltipIconButton
          title="Form Calculation Settings"
          disabled={false}
          onClick={() => this.setState({ formSettingsModalOpen: true })}
          icon="settings"
          colour="#f8f8ff"
        />
        <FormSettingsModal
          open={this.state.formSettingsModalOpen}
          onCancel={() => {
            this.setState({ formSettingsModalOpen: false });
          }}
          onProceed={(settings) => this.updatePreferences(settings)}
        />
      </div>
    );
  }
}
