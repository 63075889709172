import keycloak from "../keycloak";
import { services } from "../types/services";
import {
  WebappVersion,
  getPreviousWebappVersion,
  getWebappVersion,
  setPreviousWebappVersion,
} from "./version-service";

export class HttpService {
  public baseUrl: string = process.env.REACT_APP_BASE_URL;
  public baseKeycloakUrl: string = process.env.REACT_APP_KEYCLOAK_URL;
  public realm: string = process.env.REACT_APP_KEYCLOAK_REALM;

  public async post(
    url: string,
    body: any,
    expectResponse: boolean = true
  ): Promise<any> {
    if (keycloak.token !== undefined) {
      await services.keycloakService.refresh();
      const finalUrl = this.baseUrl + url;
      const currentVersion = getWebappVersion();
      const previousVersion = getPreviousWebappVersion();
      const currentLocation = services.history.location;
      const headers = new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.token,
        X_WEBAPP_VERSION: currentVersion.version,
      });

      return await fetch(finalUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response: Response) => {
          if (response.ok) {
            return expectResponse ? response.json() : true;
          } else if (response.status === 400) {
            // bad request, we have mapped a nice error message
            return response.json();
          } else if (response.status === 425) {
            // too early (stats not loaded yet when creating game)
            return true;
          } else if (response.status === 426) {
            // upgrade required, webapp version is not what the backend is expecting
            this.handleUpgradeRequired(
              currentLocation,
              currentVersion,
              previousVersion
            );
          } else {
            throw new Error(this.handleErrors(url, response));
          }
        })
        .catch((response) => {
          throw new Error(response);
        });
    } else {
      return Promise.reject("Unauthenticated");
    }
  }

  public async get(
    url: string,
    params?: Map<string, string>,
    expectResponse: boolean = true
  ): Promise<any> {
    if (keycloak.token !== undefined) {
      await services.keycloakService.refresh();
      const currentVersion = getWebappVersion();
      const previousVersion = getPreviousWebappVersion();
      const currentLocation = services.history.location;
      const headers = new Headers({
        Authorization: "Bearer " + keycloak.token,
        X_WEBAPP_VERSION: currentVersion.version,
      });

      return await fetch(
        this.baseUrl + url + this.constructParamString(params),
        {
          method: "GET",
          headers,
        }
      )
        .then((response: Response) => {
          if (response.ok) {
            return expectResponse
              ? response === null
                ? null
                : response.json()
              : true;
          } else if (response.status === 425) {
            // too early (stats not loaded yet when creating game)
            return true;
          } else if (response.status === 426) {
            // upgrade required, webapp version is not what the backend is expecting
            this.handleUpgradeRequired(
              currentLocation,
              currentVersion,
              previousVersion
            );
          } else {
            throw new Error(this.handleErrors(url, response));
          }
        })
        .catch((response) => {
          throw new Error(response.message);
        });
    } else {
      return Promise.reject("Unauthenticated");
    }
  }

  private handleErrors(url: string, response: Response): string {
    if (response.status === 404) {
      return `Call to ${url} returned not found`;
    } else if (response.status === 401) {
      return response.statusText;
    } else if (response.status === 413) {
      return `File size too large`;
    } else {
      return response.statusText;
    }
  }

  private constructParamString(params: Map<string, string>) {
    if (params === undefined) {
      return "";
    } else {
      let firstEntry: boolean = true;
      let paramString: string = "?";
      params.forEach((value, key) => {
        paramString = paramString + (firstEntry ? "" : "&") + key + "=" + value;
        firstEntry = false;
      });
      return paramString;
    }
  }

  private handleUpgradeRequired(
    currentLocation: Location,
    currentVersion: WebappVersion,
    previousVersion: WebappVersion
  ) {
    if (
      previousVersion &&
      currentVersion &&
      previousVersion.version === currentVersion.version &&
      previousVersion.timestamp < currentVersion.timestamp
    ) {
      if (!services.history.location.pathname.endsWith("/in-deployment")) {
        // if we've already refreshed the page and still get a 426, then the backend is out of date
        // move to the in-deployment page if we haven't already.
        services.history.push(
          `/in-deployment?returnPage=${currentLocation.pathname}${currentLocation.search}`
        );
      }
    } else {
      // if we've not yet refreshed the current version
      setPreviousWebappVersion(currentVersion.version);
      window.location.reload();
    }
  }
}
