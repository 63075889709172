import { ReactNode } from "react";
import { ReplaySubject, Subject } from "rxjs";
import { Team } from "../../types/entities/team";
import { countryFlags } from "../../types/enums/flags";
import { showMessage } from "../../types/services";
import { UUID } from "../../types/uuid";
import { EntityService } from "./entity-service";

export class TeamService extends EntityService<Team> {
  protected internationalTeams: Team[];
  public internationalTeamsSubject: Subject<Team[]>;

  public init() {
    super.init();
    this.internationalTeams = [];
    this.internationalTeamsSubject = new ReplaySubject(1);
    this.getInternationalTeams();
  }

  public getInternationalTeams(): void {
    this.httpService
      .get("/api/" + this.controllerName + "/get-international")
      .then((response) => {
        this.internationalTeams.push(...Team.deserializeList(response));
        this.internationalTeamsSubject.next(this.internationalTeams);
      })
      .catch((reason) =>
        showMessage(`Error fetching international teams: ${reason}`, "error")
      );
  }

  public findFlagForNationalTeam(teamId: UUID): ReactNode {
    if (!!teamId) {
      const team: Team = this.allEntities.find(
        (team) => team.teamId.value === teamId.value
      );
      if (team !== undefined) {
        return countryFlags[team.country];
      }
    }
    return null;
  }
}
