import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { BatsmanWidesFormData } from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const batsmanWidesFormDataComponent = (data: BatsmanWidesFormData) => {
  const headers: string[] = ["Actual Wides", "Expected Wides"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{format(data.actual)}</TableCell>
          <TableCell>{format(data.expected)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
