import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { GroundBiasByBowlerTypeData } from "../../../types/form/ground-form-modules";
import {
  StrikeRateBiasData,
  WicketBiasData,
} from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const standardSingleBiasDataComponent = (
  data: GroundBiasByBowlerTypeData | StrikeRateBiasData | WicketBiasData
) => {
  const headers: string[] = ["Actual", "Expected", "Bias", "Balls"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{format(data.actual)}</TableCell>
          <TableCell>{format(data.expected)}</TableCell>
          <TableCell>{format(data.actual / data.expected)}</TableCell>
          <TableCell>{format(data.occurrences)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
