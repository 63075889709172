import { FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { KeycloakUser } from "../../services/keycloak-service";
import { services } from "../../types/services";
import { UUID } from "../../types/uuid";
import { Match } from "../../types/entities/match";

export function ComparedUserSelector(): React.JSX.Element {
  const [users, setUsers] = useState<KeycloakUser[]>(null);
  const [sharedUsers, setSharedUsers] = useState<UUID[]>(null);
  const [comparedUser, setComparedUser] = useState<KeycloakUser>(null);
  const [createdByUser, setCreatedByUser] = useState<UUID>(null);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.keycloakService.knownUsersSubject.subscribe(
        (users: KeycloakUser[]) => setUsers(users)
      ),
      services.keycloakService.comparedUserSubject.subscribe(
        (comparedUser: KeycloakUser) => setComparedUser(comparedUser)
      ),
      services.matchService.sharedUsersSubject.subscribe(
        (sharedUsers: UUID[]) => setSharedUsers(sharedUsers)
      ),
      services.currentGameService.currentMatchSubject.subscribe(
        (match: Match) => match && setCreatedByUser(match.createdBy)
      ),
      services.currentGameService.shareSubject.subscribe((matchId: UUID) => {
        if (
          matchId &&
          services.currentGameService.currentMatchId &&
          matchId.value === services.currentGameService.currentMatchId.value
        ) {
          services.matchService.updateShares(matchId);
        }
      }),
    ];

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const getUsers: () => KeycloakUser[] = () => {
    if (!users || !sharedUsers) {
      return [];
    }

    return users.filter(
      (user) =>
        sharedUsers.find((userId) => user.id === userId.value) !== undefined ||
        createdByKnownUser(user)
    );
  };

  const createdByKnownUser = (user: KeycloakUser) => {
    return (
      createdByUser &&
      createdByUser.value === user.id &&
      user.id !== services.keycloakService.getUserId()
    );
  };

  return (
    <>
      {getUsers().length > 0 && (
        <div className="compare-user-select-light">
          Compare against:
          <FormControl className="compare-user-select">
            <Select
              value={comparedUser ? comparedUser.id : ""}
              placeholder={"Select User"}
              onChange={(selection) =>
                services.keycloakService.handleComparedUserSelection(
                  selection.target.value
                )
              }
              variant="standard"
            >
              {getUsers().map((user, index) => (
                <MenuItem key={`user-${index}`} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
              <MenuItem key={"user--1"} value={""}>
                None
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}
