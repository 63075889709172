import { Component } from "react";
import { MatchFormat } from "../../types/entities/match-format";
import { propsEqual } from "../component-utils";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (ballNumber: number, bias: number) => void;
  currentDataPoints: Map<number, number>;
  matchFormat: MatchFormat;
  dataPointType: string;
}

interface State {
  ballNumber: number;
  bias: number;
  ballNumberValid: boolean;
  biasValid: boolean;
}

export class AddPointModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      ballNumber: null,
      bias: 1,
      ballNumberValid: true,
      biasValid: true,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private updateState() {
    this.setState({
      ballNumber: MatchFormat.getTotalBalls(this.props.matchFormat),
      bias: 1,
      ballNumberValid: true,
      biasValid: true,
    });
  }

  private invalid(): boolean {
    return (
      this.props.currentDataPoints.get(this.state.ballNumber) !== undefined ||
      !this.state.ballNumberValid ||
      !this.state.biasValid
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Add Data Point"}
        invalid={this.invalid()}
        disabled={false}
        invalidText={
          this.state.ballNumberValid && this.state.biasValid
            ? `Data point already exists for ball ${this.state.ballNumber}`
            : ""
        }
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.state.ballNumber, this.state.bias)
        }
        proceedText="OK"
        colour="#34ebb4"
      >
        <div className="add-data-point-modal-content">
          <NumberSelector
            label="Ball Number"
            min={1}
            max={MatchFormat.getTotalBalls(this.props.matchFormat)}
            initial={this.state.ballNumber}
            onValid={(ballNumber) =>
              this.setState({ ballNumberValid: true, ballNumber })
            }
            onInvalid={() => this.setState({ ballNumberValid: false })}
          />

          <NumberSelector
            label={this.props.dataPointType}
            min={0}
            max={2}
            step={0.001}
            decimalPlaces={3}
            initial={this.state.bias}
            onValid={(bias) => this.setState({ biasValid: true, bias })}
            onInvalid={() => this.setState({ biasValid: false })}
          />
        </div>
      </CreationDialog>
    );
  }
}
