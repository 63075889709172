import { UUID } from "../uuid";
import { Entity } from "./entity";

export class Image implements Entity {
  public entityId: UUID;
  public imageId: UUID;
  public data: string;
  public width: number;
  public height: number;
  public createdAt: number;
  public createdBy: UUID;

  constructor(
    entityId: UUID,
    imageId: UUID,
    data: string,
    width: number,
    height: number,
    createdAt: number,
    createdBy: UUID
  ) {
    this.entityId = entityId;
    this.imageId = imageId;
    this.data = data;
    this.width = width;
    this.height = height;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
  }

  public toString(): string {
    return this.imageId.value;
  }

  public static deserializeList(json: any) {
    const images: Image[] = [];
    json.forEach((element) => {
      images.push(this.deserializeOne(element));
    });
    return images;
  }

  public static deserializeOne(responseJSON: any): Image {
    return new Image(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.imageId),
      responseJSON.data,
      responseJSON.width,
      responseJSON.height,
      responseJSON.createdAt,
      UUID.fromString(responseJSON.createdBy)
    );
  }

  public static emptyWithName(data: string): Image {
    return new Image(null, null, data, 0, 0, Date.now(), null);
  }

  public static getTypeName(): string {
    return "image";
  }

  public static serialize(image: Image): any {
    return {
      entityId: image.entityId === null ? null : image.entityId.value,
      imageId: image.imageId === null ? null : image.imageId.value,
      data: image.data,
      width: image.width,
      height: image.height,
      createdAt: image.createdAt,
      createdBy: image.createdBy === null ? null : image.createdBy.value,
    };
  }
}
