import { UUID } from "../uuid";

export class HistoricPush {
  public historicPushId: UUID;
  public matchId: UUID;
  public bowlId: UUID;
  public expectedSr: number;
  public expectedWides: number;
  public expectedNoBalls: number;
  public expectedByes: number;
  public expectedLegByes: number;
  public expectedWpc: number;
  public push: number;

  constructor(
    historicPushId: UUID,
    matchId: UUID,
    bowlId: UUID,
    expectedSr: number,
    expectedWides: number,
    expectedNoBalls: number,
    expectedByes: number,
    expectedLegByes: number,
    expectedWpc: number,
    push: number
  ) {
    this.historicPushId = historicPushId;
    this.matchId = matchId;
    this.bowlId = bowlId;
    this.expectedSr = expectedSr;
    this.expectedWpc = expectedWpc;
    this.expectedWides = expectedWides;
    this.expectedNoBalls = expectedNoBalls;
    this.expectedByes = expectedByes;
    this.expectedLegByes = expectedLegByes;
    this.push = push;
  }

  public static deserializeOne(responseJSON: any): HistoricPush {
    return !!responseJSON
      ? new HistoricPush(
          UUID.fromString(responseJSON.historicPushId),
          UUID.fromString(responseJSON.matchId),
          UUID.fromString(responseJSON.bowlId),
          responseJSON.expectedSr,
          responseJSON.expectedWides,
          responseJSON.expectedNoBalls,
          responseJSON.expectedByes,
          responseJSON.expectedLegByes,
          responseJSON.expectedWpc,
          responseJSON.push
        )
      : null;
  }
}
