import { TableCell, TableRow } from "@mui/material";

import { AdminConfidences } from "../../types/preferences/admin-preferences";
import NumberSelector from "../entity-management/entity-selectors/number-selector";

interface Props {
  adminConfidences: AdminConfidences;
  field: string;
  title: string;
  onUpdate: (type: string, field: string, value: number) => void;
}

export function ConfidenceSrAndWpcSetterRow({
  adminConfidences,
  field,
  title,
  onUpdate,
}: Readonly<Props>) {
  return (
    <TableRow>
      <TableCell id="confidence-limit-table-cell" style={{ width: "60%" }}>
        {title}
      </TableCell>
      <TableCell id="confidence-limit-table-cell" style={{ width: "20%" }}>
        <div className="second-innings-push-adjust-settings">
          <NumberSelector
            min={0}
            max={10000}
            step={1}
            decimalPlaces={0}
            initial={adminConfidences.strikeRateConfidenceLimits[field]}
            onValid={(valid: number) =>
              onUpdate("strikeRateConfidenceLimits", field, valid)
            }
          />
        </div>
      </TableCell>
      <TableCell id="confidence-limit-table-cell" style={{ width: "20%" }}>
        <div className="second-innings-push-adjust-settings">
          <NumberSelector
            min={0}
            max={10000}
            step={1}
            decimalPlaces={0}
            initial={adminConfidences.wicketPercentConfidenceLimits[field]}
            onValid={(valid: number) =>
              onUpdate("wicketPercentConfidenceLimits", field, valid)
            }
          />
        </div>
      </TableCell>
    </TableRow>
  );
}
