import { Component } from "react";
import {
  BowlerSpeciality,
  bowlerSpecialityColours,
  bowlerSpecialityDisabledColours,
  bowlerSpecialityNames,
  BowlerSpecialityPhase,
} from "../../../types/enums/bowler-speciality";

export interface BowlerSpecialityDisplay {
  name: string;
  color: string;
}

interface Props {
  phase: BowlerSpecialityPhase;
  width: number;
  onSliderSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  noSliderButton: boolean;
  disabled: boolean;
}

export class MatchFormatPhaseSection extends Component<Props> {
  private getColour(): string {
    return this.props.disabled
      ? bowlerSpecialityDisabledColours[
          BowlerSpeciality[this.props.phase.bowlerSpeciality]
        ]
      : bowlerSpecialityColours[
          BowlerSpeciality[this.props.phase.bowlerSpeciality]
        ];
  }
  private getName(): string {
    return bowlerSpecialityNames[
      BowlerSpeciality[this.props.phase.bowlerSpeciality]
    ];
  }

  public render() {
    return (
      <div
        className="bowler-speciality-chart-section"
        style={{
          background: this.getColour(),
          width: this.props.width + "%",
        }}
      >
        <span className="bowler-speciality-chart-section-text">
          {this.getName()}
        </span>
        <span className="bowler-speciality-chart-section-text">
          {this.props.phase.start} - {this.props.phase.end}
        </span>

        {!this.props.noSliderButton && (
          <div
            onPointerDown={
              this.props.disabled ? () => {} : this.props.onSliderSelect
            }
            className={
              this.props.disabled
                ? `bowler-speciality-slider-button-disabled`
                : `bowler-speciality-slider-button`
            }
          >
            <img
              src={"https://assets.codepen.io/576444/slider-arrows.svg"}
              alt="slider"
              width="10px"
            />
          </div>
        )}
      </div>
    );
  }
}
