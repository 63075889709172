import { CircularProgress } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";
import {
  CricInfoMatch,
  matchGradeColours,
} from "../../../services/scraper-service";
import { Ground } from "../../../types/entities/ground";
import { Series } from "../../../types/entities/series";
import { Team } from "../../../types/entities/team";
import { services } from "../../../types/services";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { CreationDialog } from "./creation-dialog";

interface Props {
  onProceed: (match: CricInfoMatch) => Promise<void>;
  onCancel: () => void;
  open: boolean;
}

interface State {
  matches: CricInfoMatch[];
  selectedMatch: CricInfoMatch;
  teams: Team[];
  grounds: Ground[];
  serieses: Series[];
  loading: boolean;
}

export class CricInfoMatchCreationModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      selectedMatch: null,
      teams: [],
      grounds: [],
      serieses: [],
      loading: false,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.scraperService.cricinfoMatchesSubject.subscribe(
        (matches: CricInfoMatch[]) => this.setState({ matches })
      )
    );

    this.subscriptions.push(
      services.scraperService.cricInfoMatchesLoadingSubject.subscribe(
        (loading: boolean) => this.setState({ loading })
      )
    );

    this.subscriptions.push(
      services.teamService
        .getAll()
        .subscribe((teams: Team[]) => this.setState({ teams }))
    );

    this.subscriptions.push(
      services.groundService
        .getAll()
        .subscribe((grounds: Ground[]) => this.setState({ grounds }))
    );

    this.subscriptions.push(
      services.seriesService
        .getAll()
        .subscribe((serieses: Series[]) => this.setState({ serieses }))
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updateCricinfoGames() {
    services.scraperService.refresh();
  }

  private buildRow(match: CricInfoMatch, index: number) {
    const team1 =
      this.state.teams &&
      this.state.teams.find((t) => t.teamId.value === match.team1Id.value);
    const team2 =
      this.state.teams &&
      this.state.teams.find((t) => t.teamId.value === match.team2Id.value);
    const ground =
      this.state.grounds &&
      this.state.grounds.find((g) => g.groundId.value === match.groundId.value);
    const series =
      this.state.serieses &&
      this.state.serieses.find(
        (s) => s.seriesId.value === match.seriesId.value
      );
    if (!team1 || !team2 || !ground || !series) {
      return null;
    } else {
      return (
        <div
          key={`cricinfo-match-${index}`}
          onClick={() => this.setState({ selectedMatch: match })}
        >
          <div
            className="cricinfo-match"
            style={{
              backgroundColor:
                this.state.selectedMatch &&
                this.state.selectedMatch.espnNum === match.espnNum
                  ? "rgba(0, 0, 255, 0.1)"
                  : "inherit",
            }}
          >
            <div
              className="cricinfo-match-grade-bar"
              style={{
                backgroundColor:
                  match.status === "Live"
                    ? "#552663"
                    : matchGradeColours[match.matchGrade],
              }}
            ></div>
            <div className="cricinfo-match-details">
              <div className="cricinfo-match-title">
                {(team1 && team1.name) || "Unknown"} vs{" "}
                {(team2 && team2.name) || "Unknown"}
              </div>
              <div className="italic small">
                {series.name} - {match.description}
              </div>
              <div className="italic small">
                {match.date} at {ground.name}
              </div>
              {match.status === "Live" && (
                <div className="italic small">{match.statusText}</div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  private proceed() {
    this.setState({ loading: true }, () => {
      this.props
        .onProceed(this.state.selectedMatch)
        .then(() => this.setState({ loading: false }));
    });
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Select Upcoming Match"}
        invalid={!this.state.selectedMatch}
        disabled={this.state.loading}
        showCancelButton={!this.state.loading}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.proceed()}
        disabledText=""
        cancelText="CANCEL"
        proceedText="OK"
        colour="#4ca6ee"
      >
        <div>
          {(!this.state.matches || this.state.loading) && <CircularProgress />}
          {this.state.matches && !this.state.loading && (
            <div>
              <div className="refresh-cricinfo">
                <div>Matches</div>
                <TooltipIconButton
                  onClick={() => this.updateCricinfoGames()}
                  icon="refresh"
                  title="Refresh"
                />
              </div>
              <hr />
              <div className="cricinfo-match-list">
                <div className="cricinfo-match-sub-heading">In Progress</div>
                {this.state.matches.map(
                  (match: CricInfoMatch, index: number) => {
                    return (
                      <div key={`in-progress-match-${index}`}>
                        {match.status !== "Live" &&
                          (index === 0 ||
                            this.state.matches[index - 1].status ===
                              "Live") && (
                            <div className="cricinfo-match-sub-heading">
                              Upcoming
                            </div>
                          )}
                        {this.buildRow(match, index)}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </CreationDialog>
    );
  }
}
