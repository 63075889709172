import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";

import { KeycloakUser } from "../../services/keycloak-service";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchInfo } from "../../types/entities/match-info";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { BetBuilderRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { SimulationQuestionType } from "../../types/simulator/questions/simulation-odds-question";
import {
  StoredQuestion,
  createDefaultQuestionOfType,
} from "../../types/simulator/questions/stored-questions";
import { EventDisplay } from "../match-page/event-display/event-display";

import { BetBuilderEdgeControl } from "./bet-builder-edge-control";
import { BetBuilderOddsProviderControl } from "./bet-builder-odds-provider-control";
import MultiRandomQuestionModal from "./multi-random-question-modal";
import StoredQuestionDisplay from "./stored-question-display";

export default function BetBuilderPage(): React.JSX.Element {
  const [match, setMatch] = useState<Match>(null);
  const [matchInfo, setMatchInfo] = useState<MatchInfo>(null);
  const [ground, setGround] = useState<Ground>(null);
  const [gameState, setGameState] = useState<GameState>(null);
  const [matchFormat, setMatchFormat] = useState<MatchFormat>(null);
  const [team1, setTeam1] = useState<Team>(null);
  const [team2, setTeam2] = useState<Team>(null);
  const [squad1, setSquad1] = useState<Squad>(null);
  const [squad2, setSquad2] = useState<Squad>(null);
  const [knownSimulators, setKnownSimulators] = useState<KeycloakUser[]>([]);
  const [simulatingUser, setSimulatingUser] = useState<string>(null);
  const [edge, setEdge] = useState<number>(5);
  const [storedQuestions, setStoredQuestions] =
    useState<StoredQuestion[]>(null);
  const [storedQuestionsOdds, setStoredQuestionsOdds] = useState<
    Map<string, Map<string, Map<string, number>>>
  >(new Map());
  const [MultiRandomQuestionModalOpen, setMultiRandomQuestionModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.currentGameService.currentStateSubject.subscribe(setGameState),
      services.currentGameService.currentMatchFormatSubject.subscribe(
        setMatchFormat
      ),
      services.currentGameService.groundSubject.subscribe(setGround),
      services.currentGameService.currentInfoSubject.subscribe(setMatchInfo),
      services.questionService.storedQuestionsSubject.subscribe(
        setStoredQuestions
      ),
      services.currentGameService.squadsSubject.subscribe(
        ([squad1, squad2]: Squad[]) => {
          setSquad1(squad1);
          setSquad2(squad2);
        }
      ),
      services.currentGameService.teamsSubject.subscribe(
        ([team1, team2]: Team[]) => {
          setTeam1(team1);
          setTeam2(team2);
        }
      ),
      services.currentGameService.currentMatchSubject.subscribe(
        (match: Match) => {
          setMatch(match);
          services.questionService.getStoredQuestions(match);
        }
      ),
      services.questionService.storedQuestionsOddsSubject.subscribe((map) =>
        setStoredQuestionsOdds(new Map(map))
      ),
      services.keycloakService.knownSimulatorsSubject.subscribe(
        (knownSimulators) => {
          setKnownSimulators(knownSimulators);
          setSimulatingUser(
            knownSimulators.length > 0 ? knownSimulators[0].id : null
          );
        }
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  const ready: boolean =
    match &&
    matchInfo &&
    ground &&
    gameState &&
    team1 &&
    team2 &&
    squad1 &&
    squad2 &&
    simulatingUser &&
    !!matchFormat;

  return (
    <BetBuilderRoute>
      <div className="full-push-background-light with-navbar">
        <div className="page-title-and-buttons">
          <div className="page-title">Bet Builder</div>
          <div className="squad-buttons">
            <BetBuilderEdgeControl edge={edge} onChange={setEdge} />
            <BetBuilderOddsProviderControl
              simulatingUser={simulatingUser}
              knownSimulators={knownSimulators}
              onChange={setSimulatingUser}
            />
            <Button
              onClick={() =>
                services.questionService.addStoredQuestion(
                  services.currentGameService.currentMatch,
                  createDefaultQuestionOfType(
                    SimulationQuestionType.INNINGS,
                    squad1
                  ),
                  simulatingUser
                )
              }
              variant="contained"
            >
              Create Question
            </Button>
            <Button
              onClick={() =>
                services.questionService.addStoredQuestion(
                  services.currentGameService.currentMatch,
                  createDefaultQuestionOfType(
                    SimulationQuestionType.MULTI,
                    squad1
                  ),
                  simulatingUser
                )
              }
              color="secondary"
              variant="contained"
            >
              Create Multi Question
            </Button>
            <Button
              onClick={() => setMultiRandomQuestionModalOpen(true)}
              color="success"
              variant="contained"
            >
              Wheel of Fortune
            </Button>
          </div>
        </div>

        <MultiRandomQuestionModal
          open={MultiRandomQuestionModalOpen}
          onCancel={() => setMultiRandomQuestionModalOpen(false)}
          onProceed={(numberOfQuestions) => {
            services.questionService.createRandomMultiQuestion(
              services.currentGameService.currentMatch,
              numberOfQuestions
            );
            setMultiRandomQuestionModalOpen(false);
          }}
        />

        {ready && (
          <div className="column-layout padded">
            {storedQuestions &&
              storedQuestions
                .sort((q1, q2) => {
                  return q1.createdAt > q2.createdAt ? 1 : -1;
                })
                .map((question) => (
                  <StoredQuestionDisplay
                    storedQuestion={question}
                    team1={team1}
                    team2={team2}
                    squad1={squad1}
                    squad2={squad2}
                    matchFormat={matchFormat}
                    gameState={gameState}
                    key={question.storedQuestionId.value}
                    edge={edge}
                    odds={
                      storedQuestionsOdds.get(simulatingUser) &&
                      storedQuestionsOdds.get(simulatingUser).get(match.matchId.value) &&
                      storedQuestionsOdds
                        .get(simulatingUser)
                        .get(match.matchId.value)
                        .get(question.storedQuestionId.value)
                    }
                    simulatingUser={simulatingUser}
                  />
                ))}
          </div>
        )}
        {!ready && <div className="page-title">No match selected</div>}
      </div>
      {ready && match && (
        <div className="stuck-to-bottom">
          <EventDisplay
            showMinimalScorecard={true}
            gameState={gameState}
            squad1={squad1}
            squad2={squad2}
            team1={team1}
            team2={team2}
            matchFormat={matchFormat}
            match={match}
            matchInfo={matchInfo}
          />
        </div>
      )}
    </BetBuilderRoute>
  );
}
