import { Component, createRef } from "react";
import {
  BowlerSpeciality,
  bowlerSpecialityColours,
  bowlerSpecialityDisabledColours,
  bowlerSpecialityNames,
} from "../../types/enums/bowler-speciality";
import { UUID } from "../../types/uuid";
import {
  BowlerSpecialityChartSection,
  BowlerSpecialityDisplay,
} from "./bowler-speciality-chart-section";
import { propsEqual } from "../component-utils";

interface Props {
  bowlerSpecialities: Map<BowlerSpeciality, number>;
  onEdit?: (bowlerSpecialities: Map<BowlerSpeciality, number>) => void;
  playerStatsId: UUID;
  disabled: boolean;
  desiredBalls: number;
}

interface State {
  bowlerSpecialities: Map<BowlerSpeciality, number>;
  bowlerSpecialityDisplays: BowlerSpecialityDisplay[];
}

export class BowlerSpecialityChartComponent extends Component<Props, State> {
  private sliderRef;
  static defaultProps: {
    disabled: false;
  };

  constructor(props) {
    super(props);
    const bowlerSpecialities: Map<BowlerSpeciality, number> = new Map();
    this.props.bowlerSpecialities.forEach((value, speciality) =>
      bowlerSpecialities.set(BowlerSpeciality[speciality], value)
    );
    this.state = {
      bowlerSpecialityDisplays: this.buildDisplays(props),
      bowlerSpecialities,
    };
    this.sliderRef = createRef();
  }

  private buildDisplays(props): BowlerSpecialityDisplay[] {
    return Object.keys(BowlerSpeciality).map((speciality) => {
      return {
        name: bowlerSpecialityNames[BowlerSpeciality[speciality]],
        color: this.props.disabled
          ? bowlerSpecialityDisabledColours[BowlerSpeciality[speciality]]
          : bowlerSpecialityColours[BowlerSpeciality[speciality]],
      };
    });
  }

  componentDidMount(): void {
    this.updateWidths();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateWidths();
    }
  }

  private updateWidths() {
    const bowlerSpecialities: Map<BowlerSpeciality, number> = new Map();
    this.props.bowlerSpecialities.forEach((value, speciality) =>
      bowlerSpecialities.set(BowlerSpeciality[speciality], value)
    );
    this.setState({
      bowlerSpecialities,
      bowlerSpecialityDisplays: this.buildDisplays(this.props),
    });
  }

  private getPercentage(containerWidth: number, distanceMoved: number): number {
    return 100 * (distanceMoved / containerWidth);
  }

  private limitNumberWithinRange(
    value: number,
    min: number,
    max: number
  ): number {
    return Math.min(Math.max(value, min), max);
  }

  private getWidth(index: number): number {
    return (
      100 *
      this.state.bowlerSpecialities.get(
        BowlerSpeciality[Object.keys(BowlerSpeciality)[index]]
      )
    );
  }

  public render() {
    return (
      <div ref={this.sliderRef} className="bowler-speciality-chart-component">
        {this.state.bowlerSpecialityDisplays.map((display, index) => (
          <BowlerSpecialityChartSection
            width={this.getWidth(index)}
            key={index}
            noSliderButton={
              index === this.state.bowlerSpecialityDisplays.length - 1
            }
            name={display.name}
            disabled={this.props.disabled}
            desiredBalls={this.props.desiredBalls}
            onSliderSelect={(e) => {
              e.preventDefault();
              let startDragX = e.clientX;
              const sliderWidth = this.sliderRef.current.offsetWidth;
              document.body.style.cursor = "ew-resize";

              const resize = (e: MouseEvent & TouchEvent) => {
                const endDragX = e.touches ? e.touches[0].clientX : e.clientX;

                const distanceMoved = endDragX - startDragX;
                const percentageMoved = this.getPercentage(
                  sliderWidth,
                  distanceMoved
                );
                const bowlerSpecialities: Map<BowlerSpeciality, number> =
                  this.state.bowlerSpecialities;

                const prevPercentage = this.getWidth(index);
                const newPercentage = prevPercentage + percentageMoved;
                const maxPercent =
                  this.getWidth(index) + this.getWidth(index + 1) - 1;

                const currentSectionWidth = this.limitNumberWithinRange(
                  newPercentage,
                  1,
                  maxPercent
                );

                const nextSectionIndex = index + 1;
                const nextSectionNewPercentage =
                  this.getWidth(nextSectionIndex) - percentageMoved;
                const nextSectionWidth = this.limitNumberWithinRange(
                  nextSectionNewPercentage,
                  1,
                  maxPercent
                );

                bowlerSpecialities.set(
                  BowlerSpeciality[Object.keys(BowlerSpeciality)[index]],
                  currentSectionWidth / 100
                );
                bowlerSpecialities.set(
                  BowlerSpeciality[
                    Object.keys(BowlerSpeciality)[nextSectionIndex]
                  ],
                  nextSectionWidth / 100
                );

                this.setState({ bowlerSpecialities });
                startDragX = endDragX;
              };

              const handleEventUp = (e: Event) => {
                e.preventDefault();
                document.body.style.cursor = "initial";
                window.removeEventListener("pointermove", resize);
                window.removeEventListener("touchmove", resize);
                window.removeEventListener("touchend", handleEventUp);
                window.removeEventListener("pointerup", handleEventUp);
                this.props.onEdit(this.state.bowlerSpecialities);
              };

              window.addEventListener("pointermove", resize);
              window.addEventListener("touchmove", resize);
              window.addEventListener("touchend", handleEventUp);
              window.addEventListener("pointerup", handleEventUp);
            }}
            color={display.color}
          />
        ))}
      </div>
    );
  }
}
