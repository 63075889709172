import {
  Box,
  Button,
  LinearProgress,
  LinearProgressProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { CSVLink } from "react-csv";
import { Subscription } from "rxjs";
import { HistoricStatsUpdateMessage } from "../../services/player-stats-service";
import { MatchType } from "../../types/enums/match-type";
import { services, showConfirmationDialog } from "../../types/services";
import { calculateProgress } from "../component-utils";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { format } from "../simulator-page/simulator-utils";

interface State {
  latestUpdate: HistoricStatsUpdateMessage;
  tablePage: number;
  generatingPlayerStats: boolean;
  renderCsv: boolean;
}

interface Props {
  matchType: MatchType;
}

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export class AllPlayerStatsComponent extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      tablePage: 1,
      latestUpdate: null,
      generatingPlayerStats: false,
      renderCsv: false,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.playerStatsService.historicStatsRequestSubject.subscribe(
        (update: HistoricStatsUpdateMessage) => {
          if (!!update.content) {
            this.setState({
              latestUpdate: update,
              generatingPlayerStats: false,
              renderCsv: true,
            });
          } else {
            this.setState({
              latestUpdate: update,
              generatingPlayerStats: true,
              renderCsv: false,
            });
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getPaginatedSlice(lines: string[]): string[] {
    return lines.slice(
      (this.state.tablePage - 1) * 50,
      (this.state.tablePage - 1) * 50 + 50
    );
  }

  private buildHeaderRow(headerString: string): ReactNode[] {
    const headers = headerString.split(",");
    return headers.map((h) => <TableCell>{h}</TableCell>);
  }

  private buildRows(rows: string[]): ReactNode[] {
    return rows
      .map((r) => r.split(","))
      .filter((r) => MatchType[r[1]] === this.props.matchType)
      .map((r) => (
        <TableRow>
          {r.map((value, index) => (
            <TableCell>
              {index === 0 ||
              index === 1 ||
              index === 2 ||
              value === "true" ||
              value === "false"
                ? value
                : format(Number(value))}
            </TableCell>
          ))}
        </TableRow>
      ));
  }

  public render() {
    let tableHeaderRow = null;
    let tableRows = [];
    let allRows = [];
    if (!!this.state.latestUpdate && !!this.state.latestUpdate.content) {
      allRows = this.state.latestUpdate.content.split("\n");
      tableHeaderRow = this.buildHeaderRow(allRows[0]);
      tableRows = this.buildRows(this.getPaginatedSlice(allRows.slice(1)));
    }

    return (
      <div className="historic-player-stats-body">
        <div className="historic-player-stats-header">
          {!this.state.generatingPlayerStats && (
            <div className="italic">
              Set your form settings using the settings button above, then
              'generate all player stats' to receive a CSV containing all the
              players' stats.
            </div>
          )}
          {!this.state.generatingPlayerStats && (
            <Button
              onClick={() =>
                showConfirmationDialog(
                  () => {
                    this.setState(
                      {
                        generatingPlayerStats: true,
                        latestUpdate: null,
                        renderCsv: false,
                      },
                      () =>
                        services.playerStatsService.generateAllHistoricPlayerStats()
                    );
                  },
                  "Regenerate All Player Stats",
                  "Are you sure?"
                )
              }
              variant={"contained"}
              color="secondary"
              disabled={this.state.generatingPlayerStats}
            >
              GENERATE ALL PLAYER STATS
            </Button>
          )}
          {this.state.generatingPlayerStats && (
            <div className="loading-bar">
              Loading...
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel
                  value={calculateProgress(this.state.latestUpdate)}
                />
              </Box>
              {!!this.state.latestUpdate && (
                <div>
                  {this.state.latestUpdate.done}/{this.state.latestUpdate.size}{" "}
                  {this.state.latestUpdate.lastPlayer}
                </div>
              )}
            </div>
          )}
        </div>
        {this.state.renderCsv && (
          <div className="historic-player-stats-table">
            <hr />
            <div className="page-title-and-buttons">
              Results:
              <div className="squad-buttons">
                <NumberSelector
                  label="Page:"
                  min={1}
                  max={Math.ceil((allRows.length - 1) / 50)}
                  initial={this.state.tablePage}
                  onValid={(tablePage) => this.setState({ tablePage })}
                />
                <CSVLink
                  filename="player-stats.csv"
                  data={
                    !!this.state.latestUpdate && this.state.latestUpdate.content
                  }
                  target="_blank"
                >
                  Download CSV
                </CSVLink>
              </div>
            </div>
            <hr />
            <TableContainer style={{ overflowX: "scroll" }}>
              <Table>
                <TableHead>
                  <TableRow>{tableHeaderRow}</TableRow>
                </TableHead>
                <TableBody>{tableRows}</TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}
