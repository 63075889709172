import { HttpService } from "../http-service";
import { Entity, EntityBuilder } from "../../types/entities/entity";
import { UUID } from "../../types/uuid";
import { ReplaySubject, Subject, Observable } from "rxjs";
import { showMessage } from "../../types/services";

export class EntityService<T extends Entity> {
  protected httpService: HttpService;
  protected controllerName: string;
  private classToCreate: EntityBuilder<T>;
  protected allEntities: T[];
  protected allEntitiesSubject: Subject<T[]>;
  private getAllOnInit: boolean;

  constructor(
    classToCreate: EntityBuilder<T>,
    httpService: HttpService,
    controllerName: string,
    getAllOnInit: boolean = true
  ) {
    this.classToCreate = classToCreate;
    this.httpService = httpService;
    this.controllerName = controllerName;
    this.getAllOnInit = getAllOnInit;
    this.allEntities = [];
    this.allEntitiesSubject = new ReplaySubject(1);
    this.init();
  }

  public init() {
    if (this.getAllOnInit) {
      this.httpService
        .get("/api/" + this.controllerName + "/getAll")
        .then((response) => {
          this.allEntities.push(
            ...this.classToCreate.deserializeList(response)
          );
          this.allEntitiesSubject.next(this.allEntities);
        })
        .catch((reason) =>
          showMessage(`Error fetching entities: ${reason}`, "error")
        );
    }
  }

  public async add(object: T): Promise<T> {
    if (!!object) {
      const payload: any = this.classToCreate.serialize(object);
      return await this.httpService
        .post("/api/" + this.controllerName + "/add", payload, true)
        .then((response) => {
          return this.deserializeAndBroadcast(response);
        })
        .catch((reason) => {
          return Promise.reject(reason);
        });
    }
  }

  public deserializeAndBroadcast(response: any): T {
    const entity: T = this.classToCreate.deserializeOne(response);
    this.allEntities = this.allEntities.filter(
      (item) => (item as any).entityId.value !== (entity as any).entityId.value
    );
    this.allEntities.unshift(entity);
    this.allEntitiesSubject.next(this.allEntities);
    return entity;
  }

  public getClassToCreate(): EntityBuilder<T> {
    return this.classToCreate;
  }

  public getAll(): Observable<T[]> {
    return this.allEntitiesSubject;
  }

  public async getOne(id: UUID): Promise<T> {
    return await this.httpService
      .get("/api/" + this.controllerName + "/getOne?id=" + id.value)
      .then((response) => {
        return this.classToCreate.deserializeOne(response);
      })
      .catch((reason) => {
        showMessage("Error fetching entity - " + reason);
        return Promise.reject(reason);
      });
  }

  public async getList(ids: UUID[]): Promise<T[]> {
    if (ids.length === 0) {
      return Promise.resolve([]);
    }
    return await this.httpService
      .get(
        "/api/" +
          this.controllerName +
          "/getList?ids=" +
          ids.map((id) => id.value).join(",")
      )
      .then((response) => {
        return this.classToCreate.deserializeList(response);
      })
      .catch((reason) => {
        showMessage("Error fetching entities - " + reason);
        return Promise.reject(reason);
      });
  }
}
