import { Component } from "react";

import { Match } from "../../types/entities/match";
import { Team } from "../../types/entities/team";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { UUID } from "../../types/uuid";
import { compareValues } from "../component-utils";
import { getTeamNameForId } from "../simulator-page/simulator-utils";
import { NumberArcSlider } from "../stats-editing-components/number-arc-slider";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  match: Match;
  team1: Team;
  team2: Team;
  onSave: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
}

export class WeightingsComponent extends Component<Props, {}> {
  STATS_TO_STEAL: string[] = ["groundWeight", "teamWeights"];

  private propsLoaded(): boolean {
    return (
      !!this.props.currentStats &&
      !!this.props.match &&
      !!this.props.team1 &&
      !!this.props.team2
    );
  }

  private onClickHandler(updates: string[]) {
    const newStats: MatchStatsWrapper = MatchStatsWrapper.clone(
      this.props.currentStats
    );

    updates.forEach((update) => {
      newStats.matchStats[update] = this.props.comparedStats.matchStats[update];
    });

    this.props.onSave(newStats, true);
  }

  private saveWeightProperty(property: string, value: number) {
    const newStats: MatchStatsWrapper = MatchStatsWrapper.clone(
      this.props.currentStats
    );
    newStats.matchStats[property] = value;
    this.props.onSave(newStats, true);
  }

  private saveTeamWeightProperty(teamId: UUID, value: number) {
    const newStats: MatchStatsWrapper = MatchStatsWrapper.clone(
      this.props.currentStats
    );
    newStats.matchStats.teamWeights.set(teamId.value, value);
    this.props.onSave(newStats, true);
  }

  private getTeamName(teamId: UUID) {
    return getTeamNameForId(teamId.value, this.props.team1, this.props.team2);
  }

  public render() {
    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">Weighting</div>

            <div
              className="match-stats-weights-comparison"
              style={{
                display: !!this.props.comparedStats ? "grid" : "flex",
                gridTemplateColumns: !!this.props.comparedStats
                  ? "1fr 1fr"
                  : "",
              }}
            >
              <div className="match-stats-weights">
                <NumberArcSlider
                  initialValue={this.props.currentStats.matchStats.groundWeight}
                  onChange={(value: number) =>
                    this.saveWeightProperty("groundWeight", value)
                  }
                  propertyDescription="Ground Stats"
                  minValue={0}
                  maxValue={1}
                  size={150}
                  startAngle={40}
                  endAngle={320}
                />
                <NumberArcSlider
                  initialValue={this.props.currentStats.matchStats.teamWeights.get(
                    this.props.match.team1Id.value
                  )}
                  onChange={(value: number) =>
                    this.saveTeamWeightProperty(this.props.match.team1Id, value)
                  }
                  propertyDescription={`${this.getTeamName(
                    this.props.match.team1Id
                  )} Stats`}
                  minValue={0}
                  maxValue={1}
                  size={150}
                  startAngle={40}
                  endAngle={320}
                />
                <NumberArcSlider
                  initialValue={this.props.currentStats.matchStats.teamWeights.get(
                    this.props.match.team2Id.value
                  )}
                  onChange={(value: number) =>
                    this.saveTeamWeightProperty(this.props.match.team2Id, value)
                  }
                  propertyDescription={`${this.getTeamName(
                    this.props.match.team2Id
                  )} Stats`}
                  minValue={0}
                  maxValue={1}
                  size={150}
                  startAngle={40}
                  endAngle={320}
                />
              </div>

              {!!this.props.comparedStats && (
                <div className="comparison">
                  <StealStatsHeadingAndButton
                    comparedUserName={this.props.comparedUserName}
                  >
                    <StealStatsButton
                      comparedUserName={this.props.comparedUserName}
                      disabled={areStatsEqual(
                        this.props.currentStats,
                        this.props.comparedStats,
                        this.STATS_TO_STEAL,
                        "matchStats"
                      )}
                      tooltipMessage={getToolTipMessage(
                        this.props.currentStats,
                        this.props.comparedStats,
                        this.STATS_TO_STEAL,
                        this.props.comparedUserName,
                        "matchStats"
                      )}
                      onClickHandler={this.onClickHandler.bind(
                        this,
                        this.STATS_TO_STEAL
                      )}
                    />
                  </StealStatsHeadingAndButton>

                  <div className="match-stats-weights">
                    <NumberArcSlider
                      initialValue={
                        this.props.comparedStats.matchStats.groundWeight
                      }
                      onChange={() => {}}
                      propertyDescription="Ground Stats"
                      minValue={0}
                      maxValue={1}
                      size={140}
                      startAngle={40}
                      endAngle={320}
                      disabled
                      colour={compareValues(
                        this.props.currentStats.matchStats.groundWeight,
                        this.props.comparedStats.matchStats.groundWeight
                      )}
                    />
                    <NumberArcSlider
                      initialValue={this.props.comparedStats.matchStats.teamWeights.get(
                        this.props.match.team1Id.value
                      )}
                      onChange={() => {}}
                      propertyDescription={`${this.getTeamName(
                        this.props.match.team1Id
                      )} Stats`}
                      minValue={0}
                      maxValue={1}
                      size={140}
                      startAngle={40}
                      endAngle={320}
                      disabled
                      colour={compareValues(
                        this.props.currentStats.matchStats.teamWeights.get(
                          this.props.match.team1Id.value
                        ),
                        this.props.comparedStats.matchStats.teamWeights.get(
                          this.props.match.team1Id.value
                        )
                      )}
                    />
                    <NumberArcSlider
                      initialValue={this.props.comparedStats.matchStats.teamWeights.get(
                        this.props.match.team1Id.value
                      )}
                      onChange={() => {}}
                      propertyDescription={`${this.getTeamName(
                        this.props.match.team2Id
                      )} Stats`}
                      minValue={0}
                      maxValue={1}
                      size={140}
                      startAngle={40}
                      endAngle={320}
                      disabled
                      colour={compareValues(
                        this.props.currentStats.matchStats.teamWeights.get(
                          this.props.match.team1Id.value
                        ),
                        this.props.comparedStats.matchStats.teamWeights.get(
                          this.props.match.team1Id.value
                        )
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
