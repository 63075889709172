import { Checkbox } from "@mui/material";

import { MatchFormat } from "../../../types/entities/match-format";
import NumberSelector, {
  matchFormatNumberSelector,
} from "../entity-selectors/number-selector";

type Props = {
  value: MatchFormat;
  onFreeHitChange: (event: any) => void;
  onNumberChange: (
    newValue: number,
    property: string,
    valid: boolean,
    validProperty: string
  ) => void;
  disabled: boolean;
};

const numberSelectorConfigs = [
  {
    label: "Runs for a Wide:",
    property: "widePenalty",
    min: 0,
    max: 10,
    validProperty: "widePenaltyValid",
  },
  {
    label: "Runs for a No Ball:",
    property: "noBallPenalty",
    min: 0,
    max: 10,
    validProperty: "noBallPenaltyValid",
  },
  {
    label: "Overs before Switch Ends:",
    property: "oversBeforeSwitch",
    min: 1,
    validProperty: "oversBeforeSwitchValid",
  },
  {
    label: "Max Consecutive Overs for a Bowler:",
    property: "maxConsecutiveOvers",
    min: 1,
    validProperty: "maxConsecutiveOversValid",
  },
];

export default function EditMatchFormatOtherRules({
  value,
  onFreeHitChange,
  onNumberChange,
  disabled,
}: Props): React.JSX.Element {
  return (
    <div>
      <div className="match-format-section-head">Other Rules</div>
      <div className="dialog-content-sideways match-format-edit-col">
        <div className="dialog-content-sideways-item">
          Free Hit for No Ball?
        </div>
        <Checkbox
          checked={value.noBallFreeHit}
          onChange={onFreeHitChange}
          disabled={disabled}
        />
      </div>
      {numberSelectorConfigs.map((config) => (
        <NumberSelector
          style={matchFormatNumberSelector}
          key={config.property}
          label={config.label}
          min={config.min}
          max={config.max || value.overConfiguration[0].length}
          initial={value[config.property]}
          onValid={(newValue) =>
            onNumberChange(
              newValue,
              config.property,
              true,
              config.validProperty
            )
          }
          onInvalid={(newValue) =>
            onNumberChange(
              newValue,
              config.property,
              false,
              config.validProperty
            )
          }
          disabled={disabled}
        />
      ))}
    </div>
  );
}
