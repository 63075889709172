import { Entity } from "../entities/entity";
import { MatchType } from "../enums/match-type";
import { UUID } from "../uuid";

export interface PercentDistributionBiasData {
  boundaryToRunsBias: number;
  fourToSixBias: number;
  oneToTwoBias: number;
  oneAndTwoToThreeBias: number;
}

export function clonePercentDistributionBiasData(
  original: PercentDistributionBiasData
): PercentDistributionBiasData {
  return {
    boundaryToRunsBias: original.boundaryToRunsBias,
    fourToSixBias: original.fourToSixBias,
    oneToTwoBias: original.oneToTwoBias,
    oneAndTwoToThreeBias: original.oneAndTwoToThreeBias,
  };
}

export interface GroundStats {
  powerplayChange: number;
  powerplayBoundarySplit: number;
  oldPowerplayWicketBias: number;

  swingUpToBall: number;
  swingStrikeRateBiasUpToBall: number;
  swingWicketBiasUpToBall: number;

  pushIncrease: number;
  pacePushIncrease: number;
  spinPushIncrease: number;

  globalStrikeRateBias: number;
  globalWicketBias: number;
  globalConfidence: number;

  surgeStrikeRateBiasInnings1: number;
  surgeWicketBiasInnings1: number;
  surgeStrikeRateBiasInnings2: number;
  surgeWicketBiasInnings2: number;

  spinConfidence: number;
  paceConfidence: number;
  spinStrikeRateBias: number;
  paceStrikeRateBias: number;
  spinWicketBias: number;
  paceWicketBias: number;

  strikeRateBiasByPush: number[];
  wicketBiasByPush: number[];
  confidenceByPush: number[];

  globalPercentDistributionBiasData: PercentDistributionBiasData;
  pacePercentDistributionBiasData: PercentDistributionBiasData;
  spinPercentDistributionBiasData: PercentDistributionBiasData;
  percentDistributionBiasDataByPush: PercentDistributionBiasData[];

  flatStrikeRateAdjustment: number;
  flatWicketPercentAdjustment: number;
}

export function serializeMatchGroundStats(matchGroundStats: MatchGroundStats) {
  return {
    groundStatsId:
      (!!matchGroundStats.groundStatsId &&
        matchGroundStats.groundStatsId.value) ||
      null,
    groundId:
      (!!matchGroundStats.groundId && matchGroundStats.groundId.value) || null,
    matchId:
      (!!matchGroundStats.matchId && matchGroundStats.matchId.value) || null,
    matchGroundStatsId:
      (!!matchGroundStats.matchGroundStatsId &&
        matchGroundStats.matchGroundStatsId.value) ||
      null,
    createdBy:
      (!!matchGroundStats.createdBy && matchGroundStats.createdBy.value) ||
      null,
  };
}

export interface MatchGroundStats {
  groundStatsId: UUID;
  groundId: UUID;
  matchId: UUID;
  matchGroundStatsId: UUID;
  createdBy: UUID;
}

export class GroundStatsWrapper implements Entity {
  public groundStatsId: UUID;
  public createdBy: UUID;
  public createdAt: number;
  public name: string;
  public groundStats: GroundStats;
  public matchType: MatchType;

  constructor(
    groundStatsId: UUID,
    createdBy: UUID,
    createdAt: number,
    name: string,
    groundStats: GroundStats,
    matchType: MatchType
  ) {
    this.groundStatsId = groundStatsId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.name = name;
    this.groundStats = groundStats;
    this.matchType = matchType;
  }

  public getStats(): GroundStats {
    return this.groundStats;
  }

  public getAllProperties(): string[] {
    return Object.keys(this.groundStats);
  }

  public static serialize(groundStatsWrapper: GroundStatsWrapper) {
    return {
      groundStatsId: !!groundStatsWrapper.groundStatsId
        ? groundStatsWrapper.groundStatsId.value
        : null,
      createdBy: !!groundStatsWrapper.createdBy
        ? groundStatsWrapper.createdBy.value
        : null,
      createdAt: groundStatsWrapper.createdAt,
      name: groundStatsWrapper.name,
      groundStats: groundStatsWrapper.groundStats,
      matchType: groundStatsWrapper.matchType,
    };
  }

  public static deserializeOne(responseJSON: any): GroundStatsWrapper {
    return new GroundStatsWrapper(
      UUID.fromString(responseJSON.groundStatsId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      responseJSON.name,
      responseJSON.groundStats,
      MatchType[responseJSON.matchType]
    );
  }

  public static deserializeList(json: any): GroundStatsWrapper[] {
    const stats: GroundStatsWrapper[] = [];
    json.forEach((element) => {
      stats.push(this.deserializeOne(element));
    });
    return stats;
  }

  public static emptyWithName(name: string): GroundStatsWrapper {
    return new GroundStatsWrapper(
      null,
      null,
      null,
      name,
      this.createDefaultStats(),
      MatchType.T20_AND_HUNDRED
    );
  }

  public static getTypeName(): string {
    return "groundStats";
  }

  public toString(): string {
    return this.name;
  }

  private static createDefaultStats(): GroundStats {
    return {
      powerplayChange: 0.4286,
      powerplayBoundarySplit: 0.5,
      oldPowerplayWicketBias: 1,
      pushIncrease: 0,
      pacePushIncrease: 0,
      spinPushIncrease: 0,
      swingUpToBall: 21,
      swingStrikeRateBiasUpToBall: 1,
      swingWicketBiasUpToBall: 1,
      spinConfidence: 0,
      paceConfidence: 0,
      spinStrikeRateBias: 1,
      paceStrikeRateBias: 1,
      spinWicketBias: 1,
      paceWicketBias: 1,
      globalConfidence: 0,
      globalStrikeRateBias: 1,
      globalWicketBias: 1,
      surgeStrikeRateBiasInnings1: 1,
      surgeWicketBiasInnings1: 1,
      surgeStrikeRateBiasInnings2: 1,
      surgeWicketBiasInnings2: 1,
      strikeRateBiasByPush: [1, 1, 1, 1, 1],
      wicketBiasByPush: [1, 1, 1, 1, 1],
      confidenceByPush: [1, 1, 1, 1, 1],
      globalPercentDistributionBiasData: {
        boundaryToRunsBias: 1,
        fourToSixBias: 1,
        oneToTwoBias: 1,
        oneAndTwoToThreeBias: 1,
      },
      pacePercentDistributionBiasData: {
        boundaryToRunsBias: 1,
        fourToSixBias: 1,
        oneToTwoBias: 1,
        oneAndTwoToThreeBias: 1,
      },
      spinPercentDistributionBiasData: {
        boundaryToRunsBias: 1,
        fourToSixBias: 1,
        oneToTwoBias: 1,
        oneAndTwoToThreeBias: 1,
      },
      percentDistributionBiasDataByPush: [
        {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
      ],
      flatStrikeRateAdjustment: 0,
      flatWicketPercentAdjustment: 0,
    };
  }
}
