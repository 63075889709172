import { Line } from "react-chartjs-2";
import { formatToDp } from "../simulator-page/simulator-utils";

type Props = {
  strikeRateData: { ball: number; value: number }[];
  wicketPercentData: { ball: number; value: number }[];
};

export default function BallByBallChart({
  strikeRateData,
  wicketPercentData,
}: Props) {
  const strikeRateChartData = {
    labels: strikeRateData.map((d) => d.ball),
    datasets: [
      {
        label: "Strike Rate",
        data: strikeRateData.map((d) => formatToDp(d.value, 4)),
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
      },
    ],
  };

  const wicketPercentChartData = {
    labels: wicketPercentData.map((d) => d.ball),
    datasets: [
      {
        label: "Wicket Percent",
        data: wicketPercentData.map((d) => formatToDp(d.value * 100, 4)),
        borderColor: "red",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
      },
    ],
  };

  const strikeRateOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Ball",
        },
      },
      y: {
        title: {
          display: true,
          text: "Strike Rate",
        },
      },
    },
  };

  const wicketPercentOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Ball",
        },
      },
      y: {
        title: {
          display: true,
          text: "Wicket Percent",
        },
      },
    },
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div style={{ width: "45%" }}>
        <Line data={strikeRateChartData} options={strikeRateOptions} />
      </div>
      <div style={{ width: "45%" }}>
        <Line data={wicketPercentChartData} options={wicketPercentOptions} />
      </div>
    </div>
  );
}
