import { UUID } from "../uuid";
import { EventType } from "../enums/event-type";

export class BetweenBallsEvent {
  public entityId: UUID;
  public createdAt: number;
  public createdBy: UUID;

  public eventId: UUID;
  public eventSequence: number;
  public eventType: EventType;
  public eventData: any;
  public matchId: UUID;
  public innings: number;
  public over: string;

  constructor(
    entityId: UUID,
    createdAt: number,
    createdBy: UUID,

    eventId: UUID,
    eventSequence: number,
    eventType: EventType,
    eventData: any,
    matchId: UUID,
    innings: number,
    over: string
  ) {
    this.entityId = entityId;
    this.createdAt = createdAt;
    this.createdBy = createdBy;

    this.eventId = eventId;
    this.eventSequence = eventSequence;
    this.eventType = eventType;
    this.eventData = eventData;
    this.matchId = matchId;
    this.innings = innings;
    this.over = over;
  }

  public static deserializeOne(responseJSON: any): BetweenBallsEvent {
    return new BetweenBallsEvent(
      UUID.fromString(responseJSON.entityId),
      responseJSON.createdAt,
      UUID.fromString(responseJSON.createdBy),
      UUID.fromString(responseJSON.eventId),
      responseJSON.eventSequence,
      responseJSON.eventType,
      responseJSON.eventData,
      UUID.fromString(responseJSON.matchId),
      responseJSON.innings,
      responseJSON.over
    );
  }
}
