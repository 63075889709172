import { Component } from "react";
import { Observable } from "rxjs";
import { services, showMessage } from "../../types/services";
import { Ground } from "../../types/entities/ground";
import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { TextField } from "@mui/material";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { propsEqual } from "../component-utils";

interface Props {
  ground: Ground;
  currentStats: GroundStatsWrapper;
  recentGroundStats: Observable<GroundStatsWrapper[]>;
  onUpdate: (updatedStats: GroundStatsWrapper, valid: boolean) => void;
}

interface State {
  renaming: boolean;
  nameValid: boolean;
  name: string;
}

export class GroundStatsSelector extends Component<Props, State> {
  private static readonly DEFAULT_STATE = {
    currentStats: null,
    recentGroundStats: null,
    renaming: false,
    nameValid: false,
    name: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...GroundStatsSelector.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private updateState() {
    this.setState({
      ...GroundStatsSelector.DEFAULT_STATE,
      nameValid: true,
      name: this.props.currentStats.name,
    });
  }

  public render() {
    return (
      <div>
        {!this.state.renaming && (
          <div className="ground-stats-name">
            <div className="ground-stats-name-textfield">
              <EntityAutoSelector
                value={this.props.currentStats}
                label={null}
                canEdit={false}
                canClear={false}
                options={this.props.recentGroundStats}
                optionService={services.groundStatsService}
                renderer={(props, option) => (
                  <div {...props}>{option.object.name}</div>
                )}
                classes=""
                maxDropdownSize={5}
                onSelect={(entity) => {
                  services.groundStatsService
                    .useGroundStats(
                      this.props.ground.groundId,
                      entity as GroundStatsWrapper
                    )
                    .then(() =>
                      this.props.onUpdate(entity as GroundStatsWrapper, true)
                    )
                    .catch((reason) =>
                      showMessage(
                        `Could not change ground stats: ${reason}`,
                        "error"
                      )
                    );
                }}
                newEntityRequiresModal={false}
              />
            </div>
            <TooltipIconButton
              title="Rename"
              disabled={false}
              onClick={() => this.setState({ renaming: true })}
              icon="edit"
            />
          </div>
        )}
        {this.state.renaming && (
          <div className="ground-stats-name">
            <div className="ground-stats-name-textfield">
              <TextField
                value={this.state.name}
                onChange={(event) =>
                  this.setState({
                    name: event.target.value,
                    nameValid: event.target.value.trimEnd().length > 0,
                  })
                }
                placeholder={"Name"}
                variant="outlined"
                disabled={false}
              />
            </div>
            <TooltipIconButton
              title="Done renaming"
              disabled={!this.state.nameValid}
              onClick={() =>
                this.setState({ renaming: false }, () =>
                  this.props.onUpdate(
                    new GroundStatsWrapper(
                      this.props.currentStats.groundStatsId,
                      this.props.currentStats.createdBy,
                      this.props.currentStats.createdAt,
                      this.state.name,
                      this.props.currentStats.groundStats,
                      this.props.currentStats.matchType
                    ),
                    this.state.nameValid
                  )
                )
              }
              icon="done"
            />
          </div>
        )}
      </div>
    );
  }
}
