import { Player } from "../../types/entities/player";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import { humanReadableLongBowlTypes } from "../../types/enums/long-bowl-type";
import { humanReadablePlayingRoles } from "../../types/enums/playing-role";
import { BatType } from "../../types/enums/bat-type";

interface Props {
  onClickPrevious: () => void;
  onClickNext: () => void;
  previousDisabled: boolean;
  nextDisabled: boolean;
  player: Player;
}

export function PlayerStatsTitle({
  onClickPrevious,
  onClickNext,
  previousDisabled,
  nextDisabled,
  player,
}: Props): React.JSX.Element {
  const showCricInfoPage = (espnNum) => {
    window.open(`https://www.espncricinfo.com/player/a-${espnNum}`);
  };

  return (
    <>
      <div className="stats-title">
        <TooltipIconButton
          title="Previous"
          onClick={onClickPrevious}
          disabled={previousDisabled}
          icon="chevron_left"
        />

        <div className="stats-modal-details">
          <PlayerView
            player={player}
            canEdit={true}
            canDelete={false}
            showName={false}
            imgWidth={80}
            imgHeight={100}
            imgClasses={"scorecard-image"}
            width={"80"}
          />
          <div className="stats-modal-details-text">
            {!!player.espnNum && (
              <div
                onClick={() => showCricInfoPage(player.espnNum)}
                className="cricinfo-buttons"
              >
                {player.longName}
              </div>
            )}
            {!player.espnNum && <div>{player.longName}</div>}

            <div className="italic small-text">
              {player.playingRoles
                .map((role) => humanReadablePlayingRoles[role])
                .join(", ")}
              /
              {player.batType === BatType.LEFT_HANDED_BATSMAN
                ? "Left-Handed Batsman"
                : "Right-Handed Batsman"}
              /
              {player.longBowlTypes
                .map((type) => humanReadableLongBowlTypes[type])
                .join(", ")}
            </div>
          </div>
        </div>

        <TooltipIconButton
          title="Next"
          disabled={nextDisabled}
          onClick={onClickNext}
          icon="navigate_next"
        />
      </div>
    </>
  );
}
