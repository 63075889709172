export enum MatchRole {
  PLAYER = "PLAYER",
  CAPTAIN = "CAPTAIN",
  VICE_CAPTAIN = "VICE_CAPTAIN",
  WICKET_KEEPER = "WICKET_KEEPER",
  WICKET_KEEPER_CAPTAIN = "WICKET_KEEPER_CAPTAIN",
  WICKET_KEEPER_VICE_CAPTAIN = "WICKET_KEEPER_VICE_CAPTAIN",
  SUBSTITUTE = "SUBSTITUTE",
  REPLACED = "REPLACED",
  UNKNOWN = "UNKNOWN",
  REMOVED = "REMOVED",
}

export const humanReadableMatchRoles: Record<MatchRole, string> = {
  PLAYER: "Player",
  CAPTAIN: "Captain",
  VICE_CAPTAIN: "Vice Captain",
  WICKET_KEEPER: "Wicketkeeper",
  WICKET_KEEPER_CAPTAIN: "Wicketkeeper Captain",
  WICKET_KEEPER_VICE_CAPTAIN: "Wicketkeeper Vice Captain",
  SUBSTITUTE: "Substitute",
  REPLACED: "Replaced",
  REMOVED: "Removed",
  UNKNOWN: "Unknown Match Role",
};

export const activeMatchRoles: MatchRole[] = [
  MatchRole.PLAYER,
  MatchRole.CAPTAIN,
  MatchRole.VICE_CAPTAIN,
  MatchRole.WICKET_KEEPER,
  MatchRole.WICKET_KEEPER_CAPTAIN,
  MatchRole.WICKET_KEEPER_VICE_CAPTAIN,
  MatchRole.SUBSTITUTE,
  MatchRole.REPLACED,
];
