import { Component } from "react";

import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import {
  PlayerSimulationQuestionStatistic,
  playerSimulationQuestionStatisticNames,
} from "../../../types/enums/question-statistic";
import { EnumSelector } from "../../entity-management/entity-selectors/enum-selector";

interface Props {
  matchFormat: MatchFormat;
  gameState: GameState;
  onChange: (questionStatistic: PlayerSimulationQuestionStatistic) => void;
}

interface State {
  questionStatistic: PlayerSimulationQuestionStatistic;
}

export class SimulatorPlayerInningsMedianEditor extends Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      questionStatistic: PlayerSimulationQuestionStatistic.SCORE,
    };
  }

  componentDidMount(): void {
    this.update();
  }

  private update() {
    this.props.onChange(this.state.questionStatistic);
  }

  public render() {
    return (
      <div>
        <div className="innings-player-median-editor-container">
          <EnumSelector
            classes="median-enum-selector"
            enumObject={PlayerSimulationQuestionStatistic}
            value={this.state.questionStatistic}
            readableValues={playerSimulationQuestionStatisticNames}
            onSelect={(value) =>
              this.setState({ questionStatistic: value }, () => this.update())
            }
            disabled={false}
          />
        </div>
      </div>
    );
  }
}
