import { ReplaySubject, Subject } from "rxjs";

import {
  AdminPreferences,
  deserializeAdminPreferences,
  serializeAdminPreferences,
} from "../types/preferences/admin-preferences";
import {
  UserPreferences,
  deserializeUserPreferences,
  serializeUserPreferences,
} from "../types/preferences/preferences";
import { services, showMessage } from "../types/services";

import { HttpService } from "./http-service";

export class UserService {
  public userPreferencesSubject: Subject<UserPreferences> =
    new ReplaySubject<UserPreferences>(1);
  public adminPreferencesSubject: Subject<AdminPreferences> =
    new ReplaySubject<AdminPreferences>(1);
  public teamPreferencesSubject: Subject<Map<string, UserPreferences>> =
    new ReplaySubject<Map<string, UserPreferences>>(1);
  private teamPreferences: Map<string, UserPreferences> = new Map();
  private httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public init() {
    if (services.keycloakService.isUser()) {
      this.getPreferences().then((userPreferences: UserPreferences) => {
        this.userPreferencesSubject.next(userPreferences);
      });
      this.getAdminPreferences().then((adminPreferences: AdminPreferences) => {
        this.adminPreferencesSubject.next(adminPreferences);
      });
    }
  }

  public websocketUpdateUserPreferences(message: any) {
    const userPreferences: UserPreferences = deserializeUserPreferences(
      message.userPreferences
    );
    if (message.causingUser === services.keycloakService.getUserId()) {
      this.userPreferencesSubject.next(userPreferences);
    } else {
      this.teamPreferences.set(message.causingUser, userPreferences);
      this.teamPreferencesSubject.next(this.teamPreferences);
      showMessage(`${message.name} updated their preferences`);
    }
  }

  public websocketUpdateAdminPreferences(message: any) {
    const adminPreferences: AdminPreferences = deserializeAdminPreferences(
      message.adminPreferences
    );
    this.adminPreferencesSubject.next(adminPreferences);
  }

  private getPreferences(): Promise<UserPreferences> {
    return this.httpService
      .get(`/api/preferences-controller/preferences`)
      .then((response: any) => {
        const result = deserializeUserPreferences(response);
        return result;
      })
      .catch((reason) => {
        showMessage(`Failed to get preferences: ${reason}`, "error");
        return null;
      });
  }

  public updateUserPreferences(userPreferences: UserPreferences) {
    return this.httpService
      .post(
        `/api/preferences-controller/update-simulator-settings`,
        serializeUserPreferences(userPreferences)
      )
      .catch((reason) => {
        showMessage(`Failed to update preferences: ${reason}`, "error");
        return null;
      });
  }

  public getAdminPreferences(): Promise<AdminPreferences> {
    return this.httpService
      .get(`/api/global-controller/get-preferences`)
      .then((response: any) => {
        const result = deserializeAdminPreferences(response);
        return result;
      })
      .catch((reason) => {
        showMessage(`Failed to get admin preferences: ${reason}`, "error");
        return null;
      });
  }

  public updateAdminPreferences(adminPreferences: AdminPreferences) {
    return this.httpService
      .post(
        `/api/global-controller/update-preferences`,
        serializeAdminPreferences(adminPreferences)
      )
      .catch((reason) => {
        showMessage(`Failed to update admin preferences: ${reason}`, "error");
        return null;
      });
  }
}
