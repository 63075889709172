import { Component } from "react";
import { TeamPlayer } from "../../types/entities/team-player";
import { Player } from "../../types/entities/player";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import { humanReadableMatchRoles } from "../../types/enums/match-role";

interface Props {
  player: Player;
  teamPlayer: TeamPlayer;
}

export class PlayerTableRowPlayerData extends Component<Props, {}> {
  public render() {
    return (
      <div className="player-stats-info">
        <PlayerView
          player={this.props.player}
          canEdit={true}
          canDelete={false}
          showName={false}
          imgWidth={88}
          imgHeight={110}
          imgClasses={"scorecard-image"}
          width={"80"}
        />
        <div className="player-stats-name-and-role">
          <div>{this.props.player.longName}</div>
          <div className="italic">
            {this.props.teamPlayer.matchRole !== "PLAYER"
              ? humanReadableMatchRoles[this.props.teamPlayer.matchRole]
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
