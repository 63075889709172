import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, useState } from "react";

import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { TabPanel } from "../match-page/scorecard/tab-panel";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

import { batchUpdate } from "./match-stats-utils";
import PlayerTemporaryAdjustmentsComponent from "./player-temporary-adjustments-component";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

export default function PlayerTemporaryAdjustmentsDisplay({
  currentStats,
  comparedStats,
  comparedUserName,
  squad1,
  squad2,
  team1,
  team2,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const BATTING_STATS_TO_STEAL = [
    "batsmanMatchStrikeRateAdjustments",
    "batsmanMatchWicketPercentAdjustments",
  ];
  const BOWLING_STATS_TO_STEAL = [
    "bowlerMatchStrikeRateAdjustments",
    "bowlerMatchWicketPercentAdjustments",
  ];

  const onStealHandler = (statsToSteal: string[]) => {
    batchUpdate(currentStats, comparedStats, statsToSteal, onUpdate);
  };

  const addPlayerComponents = (
    stats: MatchStatsWrapper,
    comparison: boolean,
    team: Team,
    player: Player,
    battingComponents: ReactNode[],
    bowlingComponents: ReactNode[]
  ) => {
    const teamId = team.teamId.value;
    const playerId = player.playerId.value;

    const batsmanSr = stats?.matchStats.batsmanMatchStrikeRateAdjustments;
    const batsmanWpc = stats?.matchStats.batsmanMatchWicketPercentAdjustments;
    const bowlerSr = stats?.matchStats.bowlerMatchStrikeRateAdjustments;
    const bowlerWpc = stats?.matchStats.bowlerMatchWicketPercentAdjustments;

    const playerBattingSr = batsmanSr.get(teamId)?.get(playerId);
    const playerBowlingSr = bowlerSr.get(teamId)?.get(playerId);
    const playerBattingWpc = batsmanWpc.get(teamId)?.get(playerId);
    const playerBowlingWpc = bowlerWpc.get(teamId)?.get(playerId);

    if (playerBattingSr || playerBattingWpc) {
      battingComponents.push(
        <PlayerTemporaryAdjustmentsComponent
          key={`player-batting-${playerId}`}
          player={player}
          team={team}
          currentStats={currentStats}
          comparedStats={comparedStats}
          strikeRateProperty="batsmanMatchStrikeRateAdjustments"
          wicketPercentProperty="batsmanMatchWicketPercentAdjustments"
          onUpdate={onUpdate}
          comparison={comparison}
        />
      );
    }

    if (playerBowlingSr || playerBowlingWpc) {
      bowlingComponents.push(
        <PlayerTemporaryAdjustmentsComponent
          key={`player-bowling-${playerId}`}
          player={player}
          team={team}
          currentStats={currentStats}
          comparedStats={comparedStats}
          strikeRateProperty="bowlerMatchStrikeRateAdjustments"
          wicketPercentProperty="bowlerMatchWicketPercentAdjustments"
          onUpdate={onUpdate}
          comparison={comparison}
        />
      );
    }
  };

  const teamTitleStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: "800",
  };

  const team1BattingComponents = [];
  const team2BattingComponents = [];
  const team1BowlingComponents = [];
  const team2BowlingComponents = [];

  const team1ComparedBattingComponents = [];
  const team2ComparedBattingComponents = [];
  const team1ComparedBowlingComponents = [];
  const team2ComparedBowlingComponents = [];

  const addSquadComponents = (
    stats: MatchStatsWrapper,
    comparison: boolean,
    team: Team,
    squad: Squad,
    battingComponents: ReactNode[],
    bowlingComponents: ReactNode[]
  ) => {
    squad?.players.forEach((player, _index) => {
      addPlayerComponents(
        stats,
        comparison,
        team,
        player,
        battingComponents,
        bowlingComponents
      );
    });
  };

  addSquadComponents(
    currentStats,
    false,
    team1,
    squad1,
    team1BattingComponents,
    team1BowlingComponents
  );

  addSquadComponents(
    currentStats,
    false,
    team2,
    squad2,
    team2BattingComponents,
    team2BowlingComponents
  );

  if (comparedStats) {
    addSquadComponents(
      comparedStats,
      true,
      team1,
      squad1,
      team1ComparedBattingComponents,
      team1ComparedBowlingComponents
    );

    addSquadComponents(
      comparedStats,
      true,
      team2,
      squad2,
      team2ComparedBattingComponents,
      team2ComparedBowlingComponents
    );
  }

  const buildTab = (
    team1Components: ReactNode[],
    team2Components: ReactNode[],
    team1ComparedComponents: ReactNode[],
    team2ComparedComponents: ReactNode[],
    statsToSteal: string[]
  ) => {
    return (
      <>
        <div style={{ paddingTop: "1em" }}>
          {team1Components.length > 0 && (
            <div style={teamTitleStyle}>{team1.name}</div>
          )}
          <div>{team1Components}</div>
          {team2Components.length > 0 && (
            <div style={teamTitleStyle}>{team2.name}</div>
          )}
          <div>{team2Components}</div>
        </div>
        {comparedStats && (
          <div className="comparison" style={{ width: "100%" }}>
            <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
              <StealStatsButton
                comparedUserName={comparedUserName}
                disabled={areStatsEqual(
                  currentStats,
                  comparedStats,
                  statsToSteal,
                  "matchStats"
                )}
                tooltipMessage={getToolTipMessage(
                  currentStats,
                  comparedStats,
                  statsToSteal,
                  comparedUserName,
                  "matchStats"
                )}
                onClickHandler={() => onStealHandler(statsToSteal)}
              />
            </StealStatsHeadingAndButton>
            {team1ComparedComponents.length > 0 && (
              <div style={teamTitleStyle}>{team1.name}</div>
            )}
            <div>{team1ComparedComponents}</div>
            {team2ComparedComponents.length > 0 && (
              <div style={teamTitleStyle}>{team2.name}</div>
            )}
            <div>{team2ComparedComponents}</div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">Player Adjustments</div>

      <Box className="scorecard-top-buttons">
        <Tabs
          value={tabNumber}
          onChange={(_value, newValue) => setTabNumber(newValue)}
          aria-label="Scorecard Tabs"
        >
          <Tab label={"Batting"} aria-controls={`player-adjustment-tab-0`} />
          <Tab label={"Bowling"} aria-controls={`player-adjustment-tab-1`} />
        </Tabs>
      </Box>

      <TabPanel value={tabNumber} index={0}>
        {buildTab(
          team1BattingComponents,
          team2BattingComponents,
          team1ComparedBattingComponents,
          team2ComparedBattingComponents,
          BATTING_STATS_TO_STEAL
        )}
      </TabPanel>
      <TabPanel value={tabNumber} index={1}>
        {buildTab(
          team1BowlingComponents,
          team2BowlingComponents,
          team1ComparedBowlingComponents,
          team2ComparedBowlingComponents,
          BOWLING_STATS_TO_STEAL
        )}
      </TabPanel>
    </div>
  );
}
