import { Component } from "react";
import { services } from "../../types/services";
import { Subscription } from "rxjs";
import { MatchInfo } from "../../types/entities/match-info";
import { Ground } from "../../types/entities/ground";
import { GameTitle } from "../match-page/game-state-display/game-title";

interface State {
  matchInfo: MatchInfo;
  ground: Ground;
}

export class BetfairMatchDisplay extends Component<{}, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      matchInfo: null,
      ground: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.currentGameService.currentInfoSubject.subscribe(
        (matchInfo: MatchInfo) => {
          this.setState({ matchInfo });
        }
      )
    );
    this.subscriptions.push(
      services.currentGameService.groundSubject.subscribe((ground: Ground) => {
        this.setState({ ground });
      })
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public render() {
    return (
      <div className="betfair-match-display">
        {!!this.state.ground && !!this.state.matchInfo && (
          <GameTitle
            matchInfo={this.state.matchInfo}
            ground={this.state.ground}
          />
        )}
      </div>
    );
  }
}
