import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { PlayerSelector } from "../../entity-management/entity-selectors/player-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { Observable } from "rxjs";
import { propsEqual } from "../../component-utils";
import { StatisticType } from "../../../types/enums/statistic-type";
import { MatchFormat } from "../../../types/entities/match-format";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (substitute: Player) => void;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
  matchFormat: MatchFormat;
}

interface State {
  player: Player;
}

export class TimedOutModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
    };
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({ player: null });
    }
  }

  private handlePlayerChange(player: Player) {
    this.setState({ player });
  }

  private invalid(): boolean {
    return this.state.player === null;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Timed Out"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.player)}
        proceedText="OK"
        colour="#eb9c36"
      >
        <div className="timed-out-modal-content">
          <PlayerSelector
            value={this.state.player}
            onSelect={(player: Player) => this.handlePlayerChange(player)}
            options={this.eligiblePlayersObservable()}
          />
        </div>
      </CreationDialog>
    );
  }

  private eligiblePlayersObservable(): Observable<Player[]> {
    const eligiblePlayers = [];

    if (this.props.gameState && this.props.squad1 && this.props.squad2) {
      const battingSquad =
        this.props.gameState.getBattingTeam(this.props.matchFormat) === 1
          ? this.props.squad1
          : this.props.squad2;
      eligiblePlayers.push(
        ...battingSquad.players.filter((p) => {
          const dismissalType: string =
            this.props.gameState.getPlayerStringStatForInnings(
              this.props.gameState.innings,
              StatisticType.BATSMAN_DISMISSAL_METHOD,
              p.playerId
            );
          return !dismissalType || dismissalType === "";
        })
      );
    }

    return new Observable((subscriber) => subscriber.next(eligiblePlayers));
  }
}
