import { Observable, ReplaySubject, Subject } from "rxjs";
import { MatchFormat } from "../../types/entities/match-format";
import { showMessage } from "../../types/services";
import { EntityService } from "./entity-service";

export class MatchFormatService extends EntityService<MatchFormat> {
  protected allParents: MatchFormat[];
  protected allParentsSubject: Subject<MatchFormat[]>;

  public init() {
    super.init();
    this.allParents = [];
    this.allParentsSubject = new ReplaySubject(1);
    this.loadParentEntities();
  }

  private loadParentEntities() {
    this.httpService
      .get("/api/" + this.controllerName + "/getAll")
      .then((response) => {
        this.allParents.push(
          ...MatchFormat.deserializeList(response).filter(
            (mf) => mf.parentId === null
          )
        );
        this.allParentsSubject.next(this.allParents);
      })
      .catch((reason) =>
        showMessage(`Error fetching parent match formats: ${reason}`, "error")
      );
  }

  public getAllParents(): Observable<MatchFormat[]> {
    return this.allParentsSubject;
  }
}
