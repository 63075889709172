export enum StatisticType {
  BATSMAN_BALLS_ON_STRIKE = "BATSMAN_BALLS_ON_STRIKE",
  BATSMAN_RUNS_SCORED = "BATSMAN_RUNS_SCORED",
  BATSMAN_BALLS_OFF_STRIKE = "BATSMAN_BALLS_OFF_STRIKE",
  BATSMAN_FOURS = "BATSMAN_FOURS",
  BATSMAN_SIXES = "BATSMAN_SIXES",
  BATSMAN_DOTS = "BATSMAN_DOTS",
  BATSMAN_DISMISSAL_METHOD = "BATSMAN_DISMISSAL_METHOD",
  BATSMAN_RETIRED = "BATSMAN_RETIRED",
  BATSMAN_ORDER = "BATSMAN_ORDER",
  BOWLER_BALLS_BOWLED = "BOWLER_BALLS_BOWLED",
  BOWLER_RUNS_CONCEDED = "BOWLER_RUNS_CONCEDED",
  BOWLER_MAIDENS = "BOWLER_MAIDENS",
  BOWLER_WICKETS_TAKEN = "BOWLER_WICKETS_TAKEN",
  BOWLER_FOURS_CONCEDED = "BOWLER_FOURS_CONCEDED",
  BOWLER_SIXES_CONCEDED = "BOWLER_SIXES_CONCEDED",
  BOWLER_DOTS = "BOWLER_DOTS",
  BOWLER_WIDES = "BOWLER_WIDES",
  BOWLER_NO_BALLS = "BOWLER_NO_BALLS",
  BOWLER_BYES = "BOWLER_BYES",
  BOWLER_LEG_BYES = "BOWLER_LEG_BYES",
  BOWLER_RETIRED = "BOWLER_RETIRED",
  BATSMAN_TOTAL_PUSH = "BATSMAN_TOTAL_PUSH",
  BATSMAN_TOTAL_EXPECTED_SR = "BATSMAN_TOTAL_EXPECTED_SR",
  BATSMAN_TOTAL_EXPECTED_WPC = "BATSMAN_TOTAL_EXPECTED_WPC",
  BOWLER_TOTAL_PUSH = "BOWLER_TOTAL_PUSH",
  BOWLER_TOTAL_EXPECTED_SR = "BOWLER_TOTAL_EXPECTED_SR",
  BOWLER_TOTAL_EXPECTED_WPC = "BOWLER_TOTAL_EXPECTED_WPC",
}

export enum InningsStatisticType {
  RUNS = "RUNS",
  WICKETS = "WICKETS",
  EXTRAS = "EXTRAS",
  TOTAL_RUNS = "TOTAL_RUNS",
  OVER_RUNS = "OVER_RUNS",
  OVER_WICKETS = "OVER_WICKETS",
  POWERPLAY_RUNS = "POWERPLAY_RUNS",
  POWERPLAY_WICKETS = "POWERPLAY_WICKETS",
  POWERPLAY_BALLS = "POWERPLAY_BALLS",
  SURGE_RUNS = "SURGE_RUNS",
  SURGE_WICKETS = "SURGE_WICKETS",
  SURGE_BALLS = "SURGE_BALLS",
  OVER_BYES = "OVER_BYES",
  OVER_LEG_BYES = "OVER_LEG_BYES",
  OVER_BOWLERS = "OVER_BOWLERS",
  DECLARATION = "DECLARATION",
  DUCKWORTH_TARGET = "DUCKWORTH_TARGET",
  TOTAL_BYES = "TOTAL_BYES",
  TOTAL_LEG_BYES = "TOTAL_LEG_BYES",
  TOTAL_WIDES = "TOTAL_WIDES",
  TOTAL_NO_BALLS = "TOTAL_NO_BALLS",
  SURGE_OVER = "SURGE_OVER",
  WICKET_FALLS = "WICKET_FALLS",
}

export enum CalculatedStatisticType {
  TEAM_1_PUSH = "TEAM_1_PUSH",
  TEAM_2_PUSH = "TEAM_2_PUSH",
  TEAM_1_RUNS = "TEAM_1_RUNS",
  TEAM_2_RUNS = "TEAM_2_RUNS",
  TEAM_1_POWERPLAY_RUNS = "TEAM_1_POWERPLAY_RUNS",
  TEAM_2_POWERPLAY_RUNS = "TEAM_2_POWERPLAY_RUNS",
  TEAM_1_POWERPLAY_WICKETS = "TEAM_1_POWERPLAY_WICKETS",
  TEAM_2_POWERPLAY_WICKETS = "TEAM_2_POWERPLAY_WICKETS",
  TEAM_1_POWERPLAY_BALLS = "TEAM_1_POWERPLAY_BALLS",
  TEAM_2_POWERPLAY_BALLS = "TEAM_2_POWERPLAY_BALLS",
  TEAM_1_SURGE_RUNS = "TEAM_1_SURGE_RUNS",
  TEAM_2_SURGE_RUNS = "TEAM_2_SURGE_RUNS",
  TEAM_1_SURGE_WICKETS = "TEAM_1_SURGE_WICKETS",
  TEAM_2_SURGE_WICKETS = "TEAM_2_SURGE_WICKETS",
  TEAM_1_SURGE_BALLS = "TEAM_1_SURGE_BALLS",
  TEAM_2_SURGE_BALLS = "TEAM_2_SURGE_BALLS",
  TEAM_1_SURGE_TAKEN = "TEAM_1_SURGE_TAKEN",
  TEAM_2_SURGE_TAKEN = "TEAM_2_SURGE_TAKEN",
  TEAM_1_WICKETS = "TEAM_1_WICKETS",
  TEAM_2_WICKETS = "TEAM_2_WICKETS",
  TEAM_1_ALL_OUTS = "TEAM_1_ALL_OUTS",
  TEAM_2_ALL_OUTS = "TEAM_2_ALL_OUTS",
  TEAM_1_EXTRAS = "TEAM_1_EXTRAS",
  TEAM_2_EXTRAS = "TEAM_2_EXTRAS",

  TEAM_1_BATSMAN_RUNS = "TEAM_1_BATSMAN_RUNS",
  TEAM_1_BATSMAN_BALLS = "TEAM_1_BATSMAN_BALLS",
  TEAM_1_BATSMAN_WICKETS = "TEAM_1_BATSMAN_WICKETS",
  TEAM_1_BATSMAN_PUSH = "TEAM_1_BATSMAN_PUSH",
  TEAM_1_BATSMAN_EXPECTED_SR = "TEAM_1_BATSMAN_EXPECTED_SR",
  TEAM_1_BATSMAN_EXPECTED_WPC = "TEAM_1_BATSMAN_EXPECTED_WPC",
  TEAM_2_BATSMAN_RUNS = "TEAM_2_BATSMAN_RUNS",
  TEAM_2_BATSMAN_BALLS = "TEAM_2_BATSMAN_BALLS",
  TEAM_2_BATSMAN_WICKETS = "TEAM_2_BATSMAN_WICKETS",
  TEAM_2_BATSMAN_PUSH = "TEAM_2_BATSMAN_PUSH",
  TEAM_2_BATSMAN_EXPECTED_SR = "TEAM_2_BATSMAN_EXPECTED_SR",
  TEAM_2_BATSMAN_EXPECTED_WPC = "TEAM_2_BATSMAN_EXPECTED_WPC",

  TEAM_1_BOWLER_RUNS = "TEAM_1_BOWLER_RUNS",
  TEAM_1_BOWLER_BALLS = "TEAM_1_BOWLER_BALLS",
  TEAM_1_BOWLER_WICKETS = "TEAM_1_BOWLER_WICKETS",
  TEAM_1_BOWLER_PUSH = "TEAM_1_BOWLER_PUSH",
  TEAM_1_BOWLER_EXPECTED_SR = "TEAM_1_BOWLER_EXPECTED_SR",
  TEAM_1_BOWLER_EXPECTED_WPC = "TEAM_1_BOWLER_EXPECTED_WPC",
  TEAM_2_BOWLER_RUNS = "TEAM_2_BOWLER_RUNS",
  TEAM_2_BOWLER_BALLS = "TEAM_2_BOWLER_BALLS",
  TEAM_2_BOWLER_WICKETS = "TEAM_2_BOWLER_WICKETS",
  TEAM_2_BOWLER_PUSH = "TEAM_2_BOWLER_PUSH",
  TEAM_2_BOWLER_EXPECTED_SR = "TEAM_2_BOWLER_EXPECTED_SR",
  TEAM_2_BOWLER_EXPECTED_WPC = "TEAM_2_BOWLER_EXPECTED_WPC",

  MEDIAN_BATSMAN_RUNS = "MEDIAN_BATSMAN_RUNS",
  MEDIAN_BATSMAN_BOUNDARIES = "MEDIAN_BATSMAN_BOUNDARIES",
  NEXT_MAN_OUT = "NEXT_MAN_OUT",
  TOP_BAT_INNINGS_1 = "TOP_BAT_INNINGS_1",
  TOP_BAT_INNINGS_2 = "TOP_BAT_INNINGS_2",
  TEAM_1_WINNING_TOTALS = "TEAM_1_WINNING_TOTALS",
  TEAM_1_LOSING_TOTALS = "TEAM_1_LOSING_TOTALS",
}
