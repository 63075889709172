import { Component } from "react";
import { PotSummary } from "../../types/investment/pot-summary";
import { CircularProgress } from "@mui/material";
import { Transaction } from "../../types/investment/transaction";
import { KeycloakUser } from "../../services/keycloak-service";
import { PotComparison } from "./pot-comparison";
import { InvestorPotComparison } from "./investor-pot-comparison";

interface Props {
  investors: KeycloakUser[];
  deposits: Transaction[];
  withdrawals: Transaction[];
  potSummaryCurrent: PotSummary;
  potSummaryPreview: PotSummary;
}

export class PotSummaryPreview extends Component<Props> {
  private investorInDepositOrWithdrawal(investor: KeycloakUser): boolean {
    return (
      (this.props.deposits &&
        this.props.deposits.find((t) => t.userId.value === investor.id) !==
          undefined) ||
      (this.props.withdrawals &&
        this.props.withdrawals.find((t) => t.userId.value === investor.id) !==
          undefined)
    );
  }

  public render() {
    return (
      <div className="pot-summary-preview">
        <div className="pot-summary-preview-title">Preview</div>

        <PotComparison
          potSummaryCurrent={this.props.potSummaryCurrent}
          potSummaryPreview={this.props.potSummaryPreview}
        />

        {this.props.investors
          .filter((investor) => this.investorInDepositOrWithdrawal(investor))
          .map((investor) => (
            <div key={`investor-comparison-${investor.id}`}>
              <InvestorPotComparison
                potSummaryCurrent={this.props.potSummaryCurrent}
                potSummaryPreview={this.props.potSummaryPreview}
                investor={investor}
              />
            </div>
          ))}
        {!this.props.potSummaryPreview && <CircularProgress />}
      </div>
    );
  }
}
