import { UUID } from "../uuid";
import { Player } from "./player";

export class Squad {
  public teamId: UUID;
  public players: Player[];
  public matchId: UUID;

  constructor(teamId: UUID, matchId: UUID, players: Player[]) {
    this.teamId = teamId;
    this.matchId = matchId;
    this.players = players;
  }

  public static findPlayer(
    squad1: Squad,
    squad2: Squad,
    playerId: string
  ): Player {
    const squad1Players = squad1 ? squad1.players : [];
    const squad2Players = squad2 ? squad2.players : [];
    return [...squad1Players, ...squad2Players].find(
      (player) => player.playerId.value === playerId
    );
  }

  public static deserializeOne(responseJSON: any): Squad {
    return new Squad(
      UUID.fromString(responseJSON.teamId),
      UUID.fromString(responseJSON.matchId),
      Player.deserializeList(responseJSON.players)
    );
  }
}
