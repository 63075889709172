import { Slider } from "@mui/material";
import { Component } from "react";
import { Player } from "../../../types/entities/player";
import { propsEqual } from "../../component-utils";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";

interface Props {
  matchFormat: MatchFormat;
  gameState: GameState;
  open: boolean;
  onCancel: () => void;
  onProceed: (percentToReturn: number, overToReturn: number) => void;
  player: Player;
}

interface State {
  percentToReturn: number;
  overToReturn: number;
}

export class RetireModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    const overConfiguration: Map<number, number> =
      props.matchFormat.overConfiguration[props.gameState.innings - 1];
    this.state = {
      percentToReturn: 100,
      overToReturn: overConfiguration.size,
    };
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({ percentToReturn: 100 });
    }
  }

  public render() {
    const overConfiguration: number[] =
      this.props.matchFormat.overConfiguration[
        this.props.gameState.innings - 1
      ];
    return (
      <CreationDialog
        open={this.props.open}
        label={"Retire Batsman"}
        invalid={false}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(
            this.state.percentToReturn,
            this.state.overToReturn
          )
        }
        proceedText="OK"
        colour="#31b788"
      >
        {`How likely is ${this.props.player.longName} to come back?`}
        <div className="percent-slider">
          <Slider
            aria-label="Return percent"
            value={this.state.percentToReturn}
            onChange={(event, percentToReturn: number) =>
              this.setState({ percentToReturn })
            }
          />
          Likelihood: {this.state.percentToReturn}%
          <Slider
            aria-label="From over"
            max={overConfiguration.length}
            min={1}
            value={this.state.overToReturn}
            onChange={(event, overToReturn: number) =>
              this.setState({ overToReturn })
            }
          />
          From Over: {this.state.overToReturn}
        </div>
      </CreationDialog>
    );
  }
}
