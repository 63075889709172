import { GroundStatsWrapper } from "../../types/stats/ground-stats";

export function batchUpdate(
  currentStats: GroundStatsWrapper,
  comparedStats: GroundStatsWrapper,
  updates: string[],
  onUpdate: (updatedStats: GroundStatsWrapper) => void,
  index: number = -1
) {
  // Create a new version of groundStats to include all updates
  let updatedStats = currentStats.groundStats;
  updates.forEach((key) => {
    if (index !== -1) {
      const array = currentStats.groundStats[key] as Array<unknown>;
      const valueToReplace = comparedStats.groundStats[key][index];
      array.splice(index, 1, valueToReplace);
      updatedStats = {
        ...updatedStats,
        [key]: array,
      };
    } else {
      updatedStats = {
        ...updatedStats,
        [key]: comparedStats.groundStats[key],
      };
    }
  });

  onUpdate(
    new GroundStatsWrapper(
      currentStats.groundStatsId,
      currentStats.createdBy,
      currentStats.createdAt,
      currentStats.name,
      updatedStats,
      currentStats.matchType
    )
  );
}
