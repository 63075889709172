import { Team } from "../../types/entities/team";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  onClickPrevious: () => void;
  onClickNext: () => void;
  previousDisabled: boolean;
  nextDisabled: boolean;
  team: Team;
}

export function InningsTitle({
  onClickPrevious,
  onClickNext,
  previousDisabled,
  nextDisabled,
  team,
}: Props): React.JSX.Element {
  return (
    <>
      <div className="stats-title">
        <TooltipIconButton
          title="Previous"
          onClick={onClickPrevious}
          disabled={previousDisabled}
          icon="chevron_left"
        />

        <h3 className="innings-title-text">{team.name}</h3>

        <TooltipIconButton
          title="Next"
          disabled={nextDisabled}
          onClick={onClickNext}
          icon="navigate_next"
        />
      </div>
    </>
  );
}
