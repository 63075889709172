import { Fragment } from "react";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import BowlingTypeByPhaseMultipliersComponent from "../admin-settings-page/bowling-type-by-phase-multipliers-component";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

export default function MatchStatsBowlingTypeByPhaseMultipliers({
  currentStats,
  comparedStats,
  comparedUserName,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const onClickHandler = (updates: string[]) => {
    updates.forEach((update) => {
      updateStats(update, comparedStats.matchStats[update]);
    });
  };

  const updateStats = (property: string, value) => {
    const updatedStats: MatchStatsWrapper = currentStats;
    updatedStats.matchStats[property] = value;
    onUpdate(updatedStats);
  };

  return (
    <>
      {[
        {
          title: "Spin",
          property: "spinMultipliers",
        },
        {
          title: "Pace",
          property: "paceMultipliers",
        },
      ].map((config) => (
        <Fragment key={config.title}>
          <BowlingTypeByPhaseMultipliersComponent
            title={config.title}
            value={currentStats.matchStats[config.property]}
            onChange={(updatedValue) => {
              const updatedStats = MatchStatsWrapper.clone(currentStats);
              updatedStats.matchStats[config.property] = updatedValue;
              onUpdate(updatedStats);
            }}
          />
          {comparedStats && (
            <div className="comparison">
              <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
                <StealStatsButton
                  comparedUserName={comparedUserName}
                  disabled={areStatsEqual(
                    currentStats,
                    comparedStats,
                    [config.property],
                    "matchStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    currentStats,
                    comparedStats,
                    [config.property],
                    comparedUserName,
                    "matchStats"
                  )}
                  onClickHandler={() => onClickHandler([config.property])}
                />
              </StealStatsHeadingAndButton>
              <BowlingTypeByPhaseMultipliersComponent
                title={config.title}
                canEdit={false}
                value={comparedStats.matchStats[config.property]}
              />
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
}
