import { ReactNode } from "react";
import Latex from "react-latex";

export enum PushAdjustModuleType {
  RANDOM_FACTOR = "RANDOM_FACTOR",
  SECOND_INNINGS_PUSH_ADJUST = "SECOND_INNINGS_PUSH_ADJUST",
  GROUND_GLOBAL_PUSH_ADJUST = "GROUND_GLOBAL_PUSH_ADJUST",
  BATSMAN_PUSH_ADJUST = "BATSMAN_PUSH_ADJUST",
  POWERPLAY_PUSH_ADJUST = "POWERPLAY_PUSH_ADJUST",
}

export class PushAdjustData {
  subType: string;
  enabled: boolean;
  constructor(enabled: boolean, subType: string) {
    this.enabled = enabled;
    this.subType = subType;
  }
}

export class RandomPushAdjustData extends PushAdjustData {
  randomnessFactor: number;
  maxAggression: number;
  constructor(
    randomnessFactor: number,
    maxAggression: number,
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.randomnessFactor = randomnessFactor;
    this.maxAggression = maxAggression;
  }
}

export class PowerplayPushAdjustData extends PushAdjustData {
  pushAdjustment: number;
  constructor(pushAdjustment: number, enabled: boolean, subType: string) {
    super(enabled, subType);
    this.pushAdjustment = pushAdjustment;
  }
}

export const pushAdjustModuleToDataTypes: Record<
  PushAdjustModuleType,
  (any) => PushAdjustData
> = {
  RANDOM_FACTOR: (json: any) =>
    new RandomPushAdjustData(
      json.randomnessFactor,
      json.maxAggression,
      json.enabled,
      json.subType
    ),
  SECOND_INNINGS_PUSH_ADJUST: (json: any) =>
    new PushAdjustData(json.enabled, json.subType),
  GROUND_GLOBAL_PUSH_ADJUST: (json: any) =>
    new PushAdjustData(json.enabled, json.subType),
  BATSMAN_PUSH_ADJUST: (json: any) =>
    new PushAdjustData(json.enabled, json.subType),
  POWERPLAY_PUSH_ADJUST: (json: any) =>
    new PowerplayPushAdjustData(
      json.pushAdjustment,
      json.enabled,
      json.subType
    ),
};

export const pushAdjustModuleNames: Record<PushAdjustModuleType, string> = {
  RANDOM_FACTOR: "Random push adjust",
  SECOND_INNINGS_PUSH_ADJUST: "Second innings push adjust",
  GROUND_GLOBAL_PUSH_ADJUST: "Ground push adjust",
  BATSMAN_PUSH_ADJUST: "Batsman push adjust",
  POWERPLAY_PUSH_ADJUST: "Powerplay push adjust",
};

export const pushAdjustModuleTooltips: Record<PushAdjustModuleType, string> = {
  RANDOM_FACTOR: "Random push adjustment settings",
  SECOND_INNINGS_PUSH_ADJUST:
    "See match stats - second innings push adjustment",
  GROUND_GLOBAL_PUSH_ADJUST: "See ground stats - push modifiers",
  BATSMAN_PUSH_ADJUST: "See batting stats - push settings",
  POWERPLAY_PUSH_ADJUST: "Powerplay push adjust managed by admin",
};

export interface PushAdjustModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number" | "boolean";
  constraints: any;
}

const randomFactorDescriptionLatex1 =
  "${\\text{Adjusts push by an amount such that:}}$"; // eslint-disable-line
const randomFactorDescriptionLatex2 = "${x - r \\leq x' \\leq x + r}$"; // eslint-disable-line
const randomFactorDescriptionLatex3 = "${\\text{Where:}}$"; // eslint-disable-line
const randomFactorDescriptionLatex4 = "${x' = \\text{new push}}$"; // eslint-disable-line
const randomFactorDescriptionLatex5 = "${x = \\text{original push}}$"; // eslint-disable-line
const randomFactorDescriptionLatex6 = "${r = min(x, 100 - x, Randomness/2)}$"; // eslint-disable-line

export const pushAdjustDataProperties: Record<
  PushAdjustModuleType,
  PushAdjustModuleDataProperty[]
> = {
  RANDOM_FACTOR: [
    {
      humanReadableName: "Randomness",
      propertyName: "randomnessFactor",
      type: "number",
      constraints: { min: 0, max: 100, step: 1, decimalPlaces: 0 },
      description: (
        <div className="formula">
          <div>
            <Latex>{randomFactorDescriptionLatex1}</Latex>
          </div>
          <div className="latex-center">
            <Latex>{randomFactorDescriptionLatex2}</Latex>
          </div>
          <div>
            <Latex>{randomFactorDescriptionLatex3}</Latex>
          </div>
          <div className="latex-margin">
            <Latex>{randomFactorDescriptionLatex4}</Latex>
          </div>
          <div className="latex-margin">
            <Latex>{randomFactorDescriptionLatex5}</Latex>
          </div>
          <div className="latex-margin">
            <Latex>{randomFactorDescriptionLatex6}</Latex>
          </div>
        </div>
      ),
    },
  ],
  SECOND_INNINGS_PUSH_ADJUST: [],
  GROUND_GLOBAL_PUSH_ADJUST: [],
  BATSMAN_PUSH_ADJUST: [],
  POWERPLAY_PUSH_ADJUST: [],
};

export function deserializePushAdjustModules(
  json: any
): Map<PushAdjustModuleType, PushAdjustData> {
  const pushAdjustModules: Map<PushAdjustModuleType, PushAdjustData> =
    new Map();
  Object.keys(PushAdjustModuleType).forEach((moduleType) => {
    pushAdjustModules.set(
      PushAdjustModuleType[moduleType],
      pushAdjustModuleToDataTypes[PushAdjustModuleType[moduleType]](
        json[moduleType]
      )
    );
  });
  return pushAdjustModules;
}

export function serializePushAdjustModules(
  pushAdjustModules: Map<PushAdjustModuleType, PushAdjustData>
): any {
  const result: any = {};
  pushAdjustModules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });
  return result;
}
