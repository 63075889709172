import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { removePreviousWebappVersion } from "../services/version-service";
import { services } from "../types/services";
import { getHomepageRoute } from "./component-utils";

export interface WorkerMessage {
  status: string;
  attempts: number;
}

export default function InDeploymentPage(): React.JSX.Element {
  useEffect(() => {
    const searchString: string = services.history.location.search;
    const returnUrl = searchString.substring("?returnPage=".length);

    const updateThread = setInterval(() => {
      services.httpService
        .get("/api/actuator/health")
        .then((response) => {
          if (response && response.status === "UP") {
            // if our connection to the back end is now in date,
            // go back to the original page that sent us here
            services.history.push(returnUrl || "/" + getHomepageRoute());
          }
        })
        .catch((error) => {
          // swallow error, wait until backend available
        });
    }, 5000);

    return () => {
      services.refreshServices();
      removePreviousWebappVersion();
      clearInterval(updateThread);
    };
  }, []);

  return (
    <div className="in-deployment-page full-height">
      <CircularProgress />
      <div className="error-title">Deploying New Version</div>
    </div>
  );
}
