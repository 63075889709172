import { Component } from "react";
import NumberSelector, {
  medianNumberSelector,
} from "../entity-management/entity-selectors/number-selector";
import { format } from "./simulator-utils";

interface Props {
  odds: number;
  median: number;
  mean: number;
  value: number;
  onChange: (operand: number) => void;
}

export class MedianQuestionResultsComponent extends Component<Props> {
  public render() {
    return (
      <div>
        <div className="odds-selector">
          <div className="odds-selector-and-answer">
            Median:
            <div className="median-answer">{this.props.median}</div>
            Mean:
            <div className="median-answer">{format(this.props.mean)}</div>
          </div>
          <div className="odds-selector-and-answer">
            <NumberSelector
              label={"Odds of: "}
              style={medianNumberSelector}
              min={-1000}
              max={1000}
              step={1}
              decimalPlaces={1}
              initial={this.props.value}
              onValid={(valid: number) => this.props.onChange(valid)}
            />
            <div className="median-answer">{format(this.props.odds)}</div>
          </div>
        </div>
      </div>
    );
  }
}
