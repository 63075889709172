import { Component } from "react";
import { CircularSliderWithChildren } from "react-circular-slider-svg";

interface Props {
  initialValue: number;
  propertyDescription: string;
  onChange: (value: number) => void;
  size: number;
  minValue: number;
  maxValue: number;
  startAngle: number;
  endAngle: number;
  disabled: boolean;
  colour: string;
}

interface State {
  value: number;
}

export class NumberArcSlider extends Component<Props, State> {
  static defaultProps = {
    size: 150,
    minValue: 0,
    maxValue: 2,
    startAngle: 40,
    endAngle: 320,
    disabled: false,
    colour: "#1980D0",
  };

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentDidMount(): void {
    this.setState({ value: this.props.initialValue });
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }

  public render() {
    return (
      <CircularSliderWithChildren
        size={this.props.size}
        minValue={this.props.minValue}
        maxValue={this.props.maxValue}
        startAngle={this.props.startAngle}
        endAngle={this.props.endAngle}
        angleType={{
          direction: "cw",
          axis: "-y",
        }}
        handle1={{
          value: this.state.value,
          onChange: (value: number) => this.setState({ value }),
        }}
        onControlFinished={() => this.props.onChange(this.state.value)}
        arcColor={this.props.colour}
        disabled={this.props.disabled}
      >
        <div style={{ paddingTop: "15px", textAlign: "center" }}>
          {Math.round(this.state.value * 100) / 100}
          <div style={{ fontSize: "10px", textAlign: "center" }}>
            {this.props.propertyDescription}
          </div>
        </div>
      </CircularSliderWithChildren>
    );
  }
}
