import { ReactNode } from "react";
import { percentsDistributionDataComponent } from "../../components/squads-page/form-explanation-components/percent-distribution-data-component";
import { pushBracketAndGlobalDataComponent } from "../../components/squads-page/form-explanation-components/push-bracket-and-global-data-component";
import { standardSingleBiasDataComponent } from "../../components/squads-page/form-explanation-components/standard-single-bias-data-component";
import strikeRateAndWicketPercentBiasDataComponent from "../../components/squads-page/form-explanation-components/strike-rate-and-wicket-percent-bias-data-component";
import { unusedModuleDataComponent } from "../../components/squads-page/form-explanation-components/unused-module-component";
import { BowlOutcome } from "../enums/bowl-outcome";
import { PushBracket } from "../enums/push-bracket";

export enum GroundFormModuleType {
  PUSH_BRACKET_STRIKE_RATE_BIAS = "PUSH_BRACKET_STRIKE_RATE_BIAS",
  PUSH_BRACKET_WICKET_BIAS = "PUSH_BRACKET_WICKET_BIAS",
  PACE_STRIKE_RATE_BIAS = "PACE_STRIKE_RATE_BIAS",
  PACE_WICKET_BIAS = "PACE_WICKET_BIAS",
  SPIN_STRIKE_RATE_BIAS = "SPIN_STRIKE_RATE_BIAS",
  SPIN_WICKET_BIAS = "SPIN_WICKET_BIAS",
  PERCENTS_DISTRIBUTION = "PERCENTS_DISTRIBUTION",
  POWERPLAY_FORM_MODULE = "POWERPLAY_FORM_MODULE",
  GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE = "GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE",
  GROUND_POWERPLAY = "GROUND_POWERPLAY",
  GROUND_NON_POWERPLAY = "GROUND_NON_POWERPLAY",
}

export class GroundFormData {
  enabled: boolean;
  subType: string;
  propertiesToSerialize: string[];

  constructor(json: any) {
    this.enabled = json.enabled;
    this.subType = json.subType;
    this.propertiesToSerialize = ["enabled", "subType"];
  }
}

export class GroundBiasByPushBracketData extends GroundFormData {
  totalBalls: number[];
  totalExpected: number[];
  totalActual: number[];
  totalWeights: number[];
  globalWeight: number;
  globalOccurrences: number;
  globalExpected: number;
  globalActual: number;

  constructor(json: any) {
    super(json);
    this.totalBalls = json.totalBalls;
    this.totalExpected = json.totalExpected;
    this.totalActual = json.totalActual;
    this.totalWeights = json.totalWeights;
    this.globalWeight = json.globalWeight;
    this.globalOccurrences = json.globalOccurrences;
    this.globalExpected = json.globalExpected;
    this.globalActual = json.globalActual;
  }
}

export class GroundBiasByBowlerTypeData extends GroundFormData {
  occurrences: number;
  expected: number;
  actual: number;

  constructor(json: any) {
    super(json);
    this.occurrences = json.occurrences;
    this.expected = json.expected;
    this.actual = json.actual;
  }
}

export class PercentsDistributionData extends GroundFormData {
  totalBalls: number;

  totalBallsByPhase: Map<PushBracket, number>;
  totalWeightByPhase: Map<PushBracket, number>;
  totalActualByPhase: Map<PushBracket, Map<BowlOutcome, number>>;

  constructor(json: any) {
    super(json);
    this.totalBalls = json.totalBalls;
    this.totalBallsByPhase = deserializePushBracketToNumberMap(
      json.totalBallsByPhase
    );
    this.totalWeightByPhase = deserializePushBracketToNumberMap(
      json.totalWeightByPhase
    );
    this.totalActualByPhase = deserializePushBracketToOutcomePercentsMap(
      json.totalActualByPhase
    );
  }
}

export class StrikeRateAndWicketPercentBiasData extends GroundFormData {
  occurrences: number;
  expectedRuns: number;
  actualRuns: number;
  expectedWickets: number;
  actualWickets: number;

  constructor(json: any) {
    super(json);
    this.occurrences = json.occurrences;
    this.expectedRuns = json.expectedRuns;
    this.actualRuns = json.actualRuns;
    this.expectedWickets = json.expectedWickets;
    this.actualWickets = json.actualWickets;
  }
}

export const groundFormModuleToDataTypes: Record<
  GroundFormModuleType,
  (any) => GroundFormData
> = {
  POWERPLAY_FORM_MODULE: (json) => new GroundFormData(json),
  PUSH_BRACKET_STRIKE_RATE_BIAS: (json) =>
    new GroundBiasByPushBracketData(json),
  PUSH_BRACKET_WICKET_BIAS: (json) => new GroundBiasByPushBracketData(json),
  PACE_STRIKE_RATE_BIAS: (json) => new GroundBiasByBowlerTypeData(json),
  PACE_WICKET_BIAS: (json) => new GroundBiasByBowlerTypeData(json),
  SPIN_STRIKE_RATE_BIAS: (json) => new GroundBiasByBowlerTypeData(json),
  SPIN_WICKET_BIAS: (json) => new GroundBiasByBowlerTypeData(json),
  PERCENTS_DISTRIBUTION: (json) => new PercentsDistributionData(json),
  GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE: (json) =>
    new StrikeRateAndWicketPercentBiasData(json),
  GROUND_POWERPLAY: (json) => new StrikeRateAndWicketPercentBiasData(json),
  GROUND_NON_POWERPLAY: (json) => new StrikeRateAndWicketPercentBiasData(json),
};

export const groundFormDataDisplayComponents: Record<
  GroundFormModuleType,
  (data: GroundFormData) => React.ReactElement
> = {
  [GroundFormModuleType.PUSH_BRACKET_STRIKE_RATE_BIAS]: (data) =>
    pushBracketAndGlobalDataComponent(data as GroundBiasByPushBracketData),
  [GroundFormModuleType.PUSH_BRACKET_WICKET_BIAS]: (data) =>
    pushBracketAndGlobalDataComponent(data as GroundBiasByPushBracketData),
  [GroundFormModuleType.PACE_STRIKE_RATE_BIAS]: (data) =>
    standardSingleBiasDataComponent(data as GroundBiasByBowlerTypeData),
  [GroundFormModuleType.PACE_WICKET_BIAS]: (data) =>
    standardSingleBiasDataComponent(data as GroundBiasByBowlerTypeData),
  [GroundFormModuleType.SPIN_STRIKE_RATE_BIAS]: (data) =>
    standardSingleBiasDataComponent(data as GroundBiasByBowlerTypeData),
  [GroundFormModuleType.SPIN_WICKET_BIAS]: (data) =>
    standardSingleBiasDataComponent(data as GroundBiasByBowlerTypeData),
  [GroundFormModuleType.PERCENTS_DISTRIBUTION]: (data) =>
    percentsDistributionDataComponent(data as PercentsDistributionData),
  [GroundFormModuleType.GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE]: (data) =>
    strikeRateAndWicketPercentBiasDataComponent(
      data as StrikeRateAndWicketPercentBiasData
    ),
  [GroundFormModuleType.GROUND_POWERPLAY]: (data) =>
    strikeRateAndWicketPercentBiasDataComponent(
      data as StrikeRateAndWicketPercentBiasData
    ),
  [GroundFormModuleType.GROUND_NON_POWERPLAY]: (data) =>
    strikeRateAndWicketPercentBiasDataComponent(
      data as StrikeRateAndWicketPercentBiasData
    ),
  [GroundFormModuleType.POWERPLAY_FORM_MODULE]: () => unusedModuleDataComponent,
};

export const groundFormModuleNames: Record<GroundFormModuleType, string> = {
  POWERPLAY_FORM_MODULE: "Powerplay Form",
  PUSH_BRACKET_STRIKE_RATE_BIAS: "Strike Rate Bias by Push Bracket",
  PUSH_BRACKET_WICKET_BIAS: "Wicket Bias by Push Bracket",
  PACE_STRIKE_RATE_BIAS: "Strike Rate Bias against Pace",
  PACE_WICKET_BIAS: "Wicket Bias against Pace",
  SPIN_STRIKE_RATE_BIAS: "Strike Rate Bias against Spin",
  SPIN_WICKET_BIAS: "Wicket Bias against Spin",
  PERCENTS_DISTRIBUTION: "Percents Distribution",
  GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE:
    "Global Strike Rate And Wicket Percent Bias",
  GROUND_POWERPLAY: "Powerplay Strike Rate and Wicket Percent Bias",
  GROUND_NON_POWERPLAY: "Non Powerplay Strike Rate and Wicket Percent Bias",
};

export const groundFormModuleTooltips: Record<GroundFormModuleType, string> = {
  POWERPLAY_FORM_MODULE: "See Ground Stats - Powerplay Stats",
  PUSH_BRACKET_STRIKE_RATE_BIAS: "See Ground Stats - Strike Rate Bias",
  PUSH_BRACKET_WICKET_BIAS: "See Ground Stats - Wicket Bias",
  PACE_STRIKE_RATE_BIAS: "See Ground Stats - Against Pace",
  PACE_WICKET_BIAS: "See Ground Stats - Against Pace",
  SPIN_STRIKE_RATE_BIAS: "See Ground Stats - Against Spin",
  SPIN_WICKET_BIAS: "See Ground Stats - Against Spin",
  PERCENTS_DISTRIBUTION: "See Ground Stats - Strike Rate Distributions",
  GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE:
    "See Ground Stats - Global Strike Rate And Wicket Percent Bias",
  GROUND_POWERPLAY:
    "See Ground Stats - Powerplay Strike Rate And Wicket Percent Bias",
  GROUND_NON_POWERPLAY:
    "See Ground Stats - Non Powerplay Strike Rate And Wicket Percent Bias",
};

export interface FormModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const groundFormDataProperties: Record<
  GroundFormModuleType,
  FormModuleDataProperty[]
> = {
  POWERPLAY_FORM_MODULE: [],
  PUSH_BRACKET_STRIKE_RATE_BIAS: [],
  PUSH_BRACKET_WICKET_BIAS: [],
  PACE_STRIKE_RATE_BIAS: [],
  PACE_WICKET_BIAS: [],
  SPIN_STRIKE_RATE_BIAS: [],
  SPIN_WICKET_BIAS: [],
  PERCENTS_DISTRIBUTION: [],
  GLOBAL_STRIKE_RATE_AND_WICKET_BIAS_MODULE: [],
  GROUND_POWERPLAY: [],
  GROUND_NON_POWERPLAY: [],
};

export function deserializeGroundFormModules(
  json: any
): Map<GroundFormModuleType, GroundFormData> {
  const groundFormModules: Map<GroundFormModuleType, GroundFormData> =
    new Map();
  Object.keys(GroundFormModuleType).forEach((moduleType) => {
    groundFormModules.set(
      GroundFormModuleType[moduleType],
      groundFormModuleToDataTypes[GroundFormModuleType[moduleType]](
        json[moduleType]
      )
    );
  });

  return groundFormModules;
}

export function serializeGroundFormModules(
  groundFormModules: Map<GroundFormModuleType, GroundFormData>
): any {
  const result: any = {};
  groundFormModules.forEach((data, moduleType) => {
    const serializedModule = {};
    data.propertiesToSerialize.map(
      (property) => (serializedModule[property] = data[property])
    );
    result[moduleType] = serializedModule;
  });

  return result;
}

const deserializePushBracketToOutcomePercentsMap = (json: any) => {
  const map: Map<PushBracket, Map<BowlOutcome, number>> = new Map();
  Object.keys(PushBracket).forEach((pushBracket) => {
    map.set(
      PushBracket[pushBracket],
      deserializeBowlOutcomeToNumberMap(json[pushBracket])
    );
  });

  return map;
};

const deserializePushBracketToNumberMap = (json: any) => {
  const map: Map<PushBracket, number> = new Map();
  Object.keys(PushBracket).forEach((pushBracket) => {
    map.set(PushBracket[pushBracket], json[pushBracket]);
  });

  return map;
};

const deserializeBowlOutcomeToNumberMap = (json: any) => {
  const map: Map<BowlOutcome, number> = new Map();
  Object.keys(BowlOutcome).forEach((outcome) => {
    map.set(BowlOutcome[outcome], json[outcome]);
  });

  return map;
};
