import { Button, Icon } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  icon: ReactNode;
  buttonColour?: string;
};

export function IconAndTextButton({
  title,
  disabled = false,
  onClick,
  icon,
  buttonColour = "default",
}: Props): React.JSX.Element {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      aria-label={title}
      variant="outlined"
      style={
        buttonColour === "default"
          ? {}
          : {
              color: buttonColour,
              borderColor: buttonColour,
            }
      }
    >
      <Icon>{icon}</Icon> {title}
    </Button>
  );
}
