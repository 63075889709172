import { ReactNode } from "react";
import { MatchType } from "../../enums/match-type";

export enum ExtrasModuleType {
  UNIFORM_EXTRAS = "UNIFORM_EXTRAS",
  OVER_DISTRIBUTION_EXTRAS = "OVER_DISTRIBUTION_EXTRAS",
  BATSMAN_EXTRAS = "BATSMAN_EXTRAS",
  BOWLER_EXTRAS = "BOWLER_EXTRAS",
}

export class ExtrasData {
  subType: string;
  enabled: boolean;
  constructor(enabled: boolean, subType: string) {
    this.enabled = enabled;
    this.subType = subType;
  }
}

export class UniformExtrasData extends ExtrasData {
  extrasPerBall: number;
  byePercent: number;
  legByePercent: number;
  widePercent: number;
  noBallPercent: number;

  constructor(
    extrasPerBall: number,
    byePercent: number,
    legByePercent: number,
    widePercent: number,
    noBallPercent: number,
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.extrasPerBall = extrasPerBall;
    this.byePercent = byePercent;
    this.legByePercent = legByePercent;
    this.widePercent = widePercent;
    this.noBallPercent = noBallPercent;
  }
}

export class BatsmanExtrasData extends ExtrasData {
  confidenceLimit: number;
  constructor(confidenceLimit: number, enabled: boolean, subType: string) {
    super(enabled, subType);
    this.confidenceLimit = confidenceLimit;
  }
}

export class BowlerExtrasData extends ExtrasData {
  confidenceLimit: number;
  confidenceLimitsByPush: number[];

  constructor(
    confidenceLimit: number,
    confidenceLimitsByPush: number[],
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.confidenceLimit = confidenceLimit;
    this.confidenceLimitsByPush = confidenceLimitsByPush;
  }
}

interface OverExtrasDistribution {
  extrasDistribution: number[];
  widePercents: number[];
  noBallPercents: number[];
  byePercents: number[];
  legByePercents: number[];
}

export class OversDistributionExtrasData extends ExtrasData {
  extrasDistributionByOverByMatchType: Map<MatchType, OverExtrasDistribution>;

  constructor(
    extrasDistributionByOverByMatchType: Map<MatchType, OverExtrasDistribution>,
    enabled: boolean,
    subType: string
  ) {
    super(enabled, subType);
    this.extrasDistributionByOverByMatchType =
      extrasDistributionByOverByMatchType;
  }
}

export const extrasModuleToDataTypes: Record<
  ExtrasModuleType,
  (any) => ExtrasData
> = {
  UNIFORM_EXTRAS: (json: any) =>
    new UniformExtrasData(
      json.extrasPerBall,
      json.byePercent,
      json.legByePercent,
      json.widePercent,
      json.noBallPercent,
      json.enabled,
      json.subType
    ),
  OVER_DISTRIBUTION_EXTRAS: (json: any) =>
    new OversDistributionExtrasData(
      deserializeOverDistributionMap(json.extrasDistributionByOverByMatchType),
      json.enabled,
      json.subType
    ),
  BATSMAN_EXTRAS: (json: any) => new BatsmanExtrasData(json.confidenceLimit, json.enabled, json.subType),
  BOWLER_EXTRAS: (json: any) =>
    new BowlerExtrasData(
      json.confidenceLimit,
      json.confidenceLimitsByPush,
      json.enabled,
      json.subType
    ),
};

export const extrasModuleNames: Record<ExtrasModuleType, string> = {
  UNIFORM_EXTRAS: "Uniform extras",
  BATSMAN_EXTRAS: "Batsman Extras",
  BOWLER_EXTRAS: "Bowler Extras",
  OVER_DISTRIBUTION_EXTRAS: "Extras by Over",
};

export const extrasModuleTooltips: Record<ExtrasModuleType, string> = {
  UNIFORM_EXTRAS: "Uniform extras settings",
  BATSMAN_EXTRAS: "See batting stats - extras settings",
  BOWLER_EXTRAS: "See bowler stats - extras biases",
  OVER_DISTRIBUTION_EXTRAS: "No settings",
};

export const extrasModuleCanBeDisabled: Record<ExtrasModuleType, boolean> = {
  UNIFORM_EXTRAS: false,
  BATSMAN_EXTRAS: true,
  OVER_DISTRIBUTION_EXTRAS: true,
  BOWLER_EXTRAS: true
};

export interface ExtrasModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const extrasDataProperties: Record<
  ExtrasModuleType,
  ExtrasModuleDataProperty[]
> = {
  UNIFORM_EXTRAS: [
    {
      humanReadableName: "Extras per Ball",
      description: "Average number of runs from extras per ball",
      propertyName: "extrasPerBall",
      type: "number",
      constraints: { min: 0, max: 6, step: 0.001, decimalPlaces: 3 },
    },
    {
      humanReadableName: "Bye Percent",
      description: "% of extras scored from byes",
      propertyName: "byePercent",
      type: "number",
      constraints: { min: 0, max: 1, step: 0.001, decimalPlaces: 3 },
    },
    {
      humanReadableName: "Leg bye Percent",
      description: "% of extras scored from leg byes",
      propertyName: "legByePercent",
      type: "number",
      constraints: { min: 0, max: 1, step: 0.001, decimalPlaces: 3 },
    },
    {
      humanReadableName: "Wide Percent",
      description: "% of extras scored from wides",
      propertyName: "widePercent",
      type: "number",
      constraints: { min: 0, max: 1, step: 0.001, decimalPlaces: 3 },
    },
    {
      humanReadableName: "No Ball Percent",
      description: "% of extras scored from no balls",
      propertyName: "noBallPercent",
      type: "number",
      constraints: { min: 0, max: 1, step: 0.001, decimalPlaces: 3 },
    },
  ],
  BATSMAN_EXTRAS: [],
  BOWLER_EXTRAS: [],
  OVER_DISTRIBUTION_EXTRAS: [],
};

export const extrasModuleSerializationFunction: Record<
  ExtrasModuleType,
  (src: ExtrasData) => any
> = {
  UNIFORM_EXTRAS: (src) => src,
  BATSMAN_EXTRAS: (src) => src,
  BOWLER_EXTRAS: (src) => src,
  OVER_DISTRIBUTION_EXTRAS: (src) => {
    const oversDistributionExtrasData: OversDistributionExtrasData =
      src as OversDistributionExtrasData;
    return {
      enabled: oversDistributionExtrasData.enabled,
      subType: oversDistributionExtrasData.subType,
      extrasDistributionByOverByMatchType: serializeOverDistributionMap(
        oversDistributionExtrasData.extrasDistributionByOverByMatchType
      ),
    };
  },
};

function deserializeOverDistributionMap(
  json: any
): Map<MatchType, OverExtrasDistribution> {
  const map: Map<MatchType, OverExtrasDistribution> = new Map();
  Object.keys(json).forEach((matchType) => {
    map.set(MatchType[matchType], json[matchType] as OverExtrasDistribution);
  });
  return map;
}

function serializeOverDistributionMap(
  map: Map<MatchType, OverExtrasDistribution>
): any {
  let object = {};
  map.forEach((value, key) => {
    object[key] = value as any;
  });
  return object;
}

export function deserializeExtrasModules(
  json: any
): Map<ExtrasModuleType, ExtrasData> {
  const extrasModules: Map<ExtrasModuleType, ExtrasData> = new Map();
  Object.keys(ExtrasModuleType).forEach((moduleType) => {
    extrasModules.set(
      ExtrasModuleType[moduleType],
      extrasModuleToDataTypes[ExtrasModuleType[moduleType]](json[moduleType])
    );
  });
  return extrasModules;
}

export function serializeExtrasModules(
  extrasModules: Map<ExtrasModuleType, ExtrasData>
): any {
  const result: any = {};
  extrasModules.forEach((data, moduleType) => {
    result[moduleType] = extrasModuleSerializationFunction[moduleType](data);
  });
  return result;
}
