export enum TossChoice {
  BAT = "BAT",
  BOWL = "BOWL",
  UNKNOWN = "UNKNOWN",
}

export const tossChoiceNames: Record<TossChoice, string> = {
  BAT: "Bat",
  BOWL: "Bowl",
  UNKNOWN: "Unknown",
};
