import { Button, CircularProgress } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { KeycloakUser } from "../../services/keycloak-service";
import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { UserPreferences } from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import {
  services,
  showConfirmationDialog,
  showMessage,
} from "../../types/services";
import { NodeHealth } from "../../types/simulator/node-health";
import {
  MatchStats,
  MatchStatsWrapper,
  readableMatchStatsByBallNames,
} from "../../types/stats/match-stats";
import AppAlert from "../common-components/app-alert";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { noHealthyNodes, noSimulationsAllowed } from "../component-utils";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import { MatchStatsEditorChart } from "../stats-editing-components/match-stats-editor-chart";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import { StatsLoadingPage } from "../stats-loading-page/stats-loading-page";

import { AheadOrBehindOverrideComponent } from "./ahead-or-behind-override-component";
import { BowlerReservationByOverComponent } from "./bowler-reservation-by-over-component";
import { BowlerReservationBySurgeComponent } from "./bowler-reservation-by-surge-component";
import { HomeAdvantageComponent } from "./home-advantage-component";
import MatchStatsBowlingTypeByPhaseMultipliers from "./match-stats-bowling-type-by-phase-multipliers";
import { batchUpdate } from "./match-stats-utils";
import PlayerTemporaryAdjustmentsDisplay from "./player-temporary-adjustments-display";
import { SecondInningsPushAdjustmentComponent } from "./second-innings-push-adjustment-component";
import { ThoughtWicketMultiplierComponent } from "./thought-wicket-multiplier-component";
import { WeightingsComponent } from "./weightings-component";

interface State {
  gameState: GameState;
  matchFormat: MatchFormat;
  loading: boolean;
  match: Match;
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  squad1: Squad;
  squad2: Squad;
  comparedUserName: string;
  teams: Team[];
  userPreferences: UserPreferences;
  nodeHealth: NodeHealth[];
  latestVersion: string;
}

export class MatchStatsPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    gameState: null,
    matchFormat: null,
    loading: false,
    match: null,
    currentStats: null,
    comparedStats: null,
    squad1: null,
    squad2: null,
    comparedUserName: null,
    homeTeam: null,
    ground: null,
    teams: null,
    userPreferences: null,
    nodeHealth: null,
    latestVersion: null,
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...MatchStatsPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (currentMatch: Match) => this.setState({ match: currentMatch })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.matchStatsSubject.subscribe(
        (matchStats: MatchStatsWrapper) =>
          this.setState({ currentStats: matchStats })
      )
    );

    this.subscriptions.push(
      services.currentGameService.squadsSubject.subscribe(
        ([squad1, squad2]: Squad[]) => this.setState({ squad1, squad2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (currentState: GameState) => this.setState({ gameState: currentState })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (matchFormat: MatchFormat) => this.setState({ matchFormat })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.comparedMatchStatsSubject.subscribe(
        (comparedStats: MatchStatsWrapper) => this.setState({ comparedStats })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.matchStatsSubject.subscribe(
        (currentStats: MatchStatsWrapper) => this.setState({ currentStats })
      )
    );

    this.subscriptions.push(
      services.keycloakService.comparedUserSubject.subscribe(
        (comparedUser: KeycloakUser) =>
          this.setState({
            comparedUserName: !!comparedUser ? comparedUser.name : null,
          })
      )
    );

    this.subscriptions.push(
      services.currentGameService.teamsSubject.subscribe((teams: Team[]) => {
        this.setState({ teams });
      })
    );

    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe((userPreferences) =>
        this.setState({ userPreferences })
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeHealthSubject.subscribe(
        (nodes: NodeHealth[]) => {
          if (nodes) {
            this.setState({
              nodeHealth: nodes,
            });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeVersionSubject.subscribe(
        (latestVersion: string) => {
          this.setState({ latestVersion: latestVersion });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private saveStats() {
    services.matchStatsService.updateMatchStats(this.state.currentStats);
  }

  private getDefaultMatchStats() {
    this.setState({ loading: true }, () => {
      services.matchStatsService
        .getDefaultMatchStats(
          this.state.matchFormat.matchFormatId,
          this.state.match.matchId
        )
        .then((matchStats: MatchStats) => {
          const currentStats = new MatchStatsWrapper(
            this.state.currentStats.matchStatsId,
            this.state.currentStats.matchId,
            this.state.currentStats.createdBy,
            this.state.currentStats.createdAt,
            matchStats
          );
          this.setState({ currentStats, loading: false }, () =>
            this.saveStats()
          );
        })
        .catch((reason) => {
          showMessage(`Could not get default match stats: ${reason}`, "error");
        });
    });
  }

  private refreshSim() {
    services.simulationService.removeSimulationResults();
    services.simulationService.simulate();
  }

  private onStealHandler = () => {
    batchUpdate(
      this.state.currentStats,
      this.state.comparedStats,
      this.state.comparedStats.getAllProperties(),
      (updatedStats: MatchStatsWrapper) => {
        this.setState({ currentStats: updatedStats }, () => {
          this.saveStats();
        });
      }
    );
  };

  public render() {
    return (
      <SimulatorRoute>
        <div className="full-push-background-light with-navbar">
          {noSimulationsAllowed(
            this.state.match,
            this.state.userPreferences
          ) && (
            <AppAlert>
              Check if you have at least 1 simulation enabled in settings and
              that the simulator is enabled.
            </AppAlert>
          )}
          {noHealthyNodes(this.state.nodeHealth, this.state.latestVersion) && (
            <AppAlert severity="warning">
              You have no nodes, please set them up to run the simulator
            </AppAlert>
          )}
          <div className="page-title-and-buttons">
            <div className="page-title">Match Settings</div>

            {!!this.state.match && !!this.state.currentStats && (
              <div className="match-stats-buttons">
                <ComparedUserSelector />
                {this.state.comparedStats && (
                  <StealStatsButton
                    comparedUserName={this.state.comparedUserName}
                    disabled={areStatsEqual(
                      this.state.currentStats,
                      this.state.comparedStats,
                      this.state.comparedStats.getAllProperties(),
                      "matchStats"
                    )}
                    tooltipMessage={getToolTipMessage(
                      this.state.currentStats,
                      this.state.comparedStats,
                      this.state.comparedStats.getAllProperties(),
                      this.state.comparedUserName,
                      "matchStats"
                    )}
                    onClickHandler={this.onStealHandler}
                  />
                )}
                <Button
                  onClick={() =>
                    showConfirmationDialog(
                      () => this.getDefaultMatchStats(),
                      "Reset Match Stats to Default",
                      "Are you sure?"
                    )
                  }
                  variant="contained"
                  color="primary"
                >
                  Default Match Stats
                </Button>
                {this.state.gameState &&
                  this.state.gameState.initialStatsLoaded && (
                    <RunSimulatorButton
                      isDisabled={noSimulationsAllowed(
                        this.state.match,
                        this.state.userPreferences
                      )}
                      onClick={() => this.refreshSim()}
                    />
                  )}
              </div>
            )}
          </div>
          {!!this.state.gameState && !!this.state.matchFormat && (
            <div className="match-stats-display">
              {this.state.loading && <CircularProgress />}
              {!!this.state.match && !!this.state.currentStats && (
                <div className="match-stats-body">
                  <div className="match-stats-boxes">
                    <div className="match-stats-boxes-vertical">
                      <WeightingsComponent
                        currentStats={this.state.currentStats}
                        comparedStats={this.state.comparedStats}
                        comparedUserName={this.state.comparedUserName}
                        onSave={(currentStats) =>
                          this.setState({ currentStats }, () =>
                            this.saveStats()
                          )
                        }
                        team1={this.state.teams?.[0]}
                        team2={this.state.teams?.[1]}
                        match={this.state.match}
                      />
                      <BowlerReservationByOverComponent
                        currentStats={this.state.currentStats}
                        comparedStats={this.state.comparedStats}
                        comparedUserName={this.state.comparedUserName}
                        matchFormat={this.state.matchFormat}
                        gameState={this.state.gameState}
                        squad1={this.state.squad1}
                        squad2={this.state.squad2}
                        onUpdate={(currentStats) =>
                          this.setState({ currentStats }, () =>
                            this.saveStats()
                          )
                        }
                      />
                      <BowlerReservationBySurgeComponent
                        currentStats={this.state.currentStats}
                        comparedStats={this.state.comparedStats}
                        comparedUserName={this.state.comparedUserName}
                        matchFormat={this.state.matchFormat}
                        gameState={this.state.gameState}
                        squad1={this.state.squad1}
                        squad2={this.state.squad2}
                        onUpdate={(currentStats) =>
                          this.setState({ currentStats }, () =>
                            this.saveStats()
                          )
                        }
                      />
                      <PlayerTemporaryAdjustmentsDisplay
                        currentStats={this.state.currentStats}
                        comparedStats={this.state.comparedStats}
                        comparedUserName={this.state.comparedUserName}
                        squad1={this.state.squad1}
                        squad2={this.state.squad2}
                        team1={this.state.teams?.[0]}
                        team2={this.state.teams?.[1]}
                        onUpdate={(currentStats) =>
                          this.setState({ currentStats }, () =>
                            this.saveStats()
                          )
                        }
                      />
                    </div>
                    <div className="match-stats-main-boxes-vertical">
                      <MatchStatsEditorChart
                        currentStats={this.state.currentStats}
                        comparedStats={this.state.comparedStats}
                        comparedUserName={this.state.comparedUserName}
                        matchFormat={this.state.matchFormat}
                        gameState={this.state.gameState}
                        onUpdate={(currentStats) =>
                          this.setState({ currentStats }, () =>
                            this.saveStats()
                          )
                        }
                        canAddOrDeletePoints={true}
                        graphsRecord={readableMatchStatsByBallNames}
                        defaultGraph={"strikeRateBiasByBall"}
                        title={"Match Conditions by Ball"}
                        xAxisLabel={"Ball Number:"}
                        showTitle={true}
                      />
                      <div className="match-stats-column-section">
                        <div className="stats-modal-section-head">
                          Bowling Type Multipliers by Phase
                        </div>
                        <div style={{ padding: "2px" }}>
                          <MatchStatsBowlingTypeByPhaseMultipliers
                            currentStats={this.state.currentStats}
                            comparedStats={this.state.comparedStats}
                            comparedUserName={this.state.comparedUserName}
                            onUpdate={(currentStats) =>
                              this.setState({ currentStats }, () =>
                                this.saveStats()
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="match-stats-bottom-boxes">
                        <AheadOrBehindOverrideComponent
                          currentStats={this.state.currentStats}
                          comparedStats={this.state.comparedStats}
                          comparedUserName={this.state.comparedUserName}
                          onSave={(currentStats) =>
                            this.setState(
                              {
                                currentStats,
                              },
                              () => this.saveStats()
                            )
                          }
                        />
                        <HomeAdvantageComponent
                          currentStats={this.state.currentStats}
                          comparedStats={this.state.comparedStats}
                          comparedUserName={this.state.comparedUserName}
                          onSave={(currentStats) =>
                            this.setState(
                              {
                                currentStats,
                              },
                              () => this.saveStats()
                            )
                          }
                        />
                        <SecondInningsPushAdjustmentComponent
                          currentStats={this.state.currentStats}
                          comparedStats={this.state.comparedStats}
                          comparedUserName={this.state.comparedUserName}
                          onSave={(currentStats) =>
                            this.setState(
                              {
                                currentStats,
                              },
                              () => this.saveStats()
                            )
                          }
                          teamBattingFirst={
                            this.state.gameState.started
                              ? this.state.gameState.getBattingTeamForInnings(
                                  1,
                                  this.state.matchFormat
                                )
                              : 0
                          }
                          team1Name={this.state.teams?.[0].name}
                          team2Name={this.state.teams?.[1].name}
                        />
                        <ThoughtWicketMultiplierComponent
                          currentStats={this.state.currentStats}
                          comparedStats={this.state.comparedStats}
                          comparedUserName={this.state.comparedUserName}
                          onSave={(currentStats) =>
                            this.setState(
                              {
                                currentStats,
                              },
                              () => this.saveStats()
                            )
                          }
                          teamBattingFirst={
                            this.state.gameState.started
                              ? this.state.gameState.getBattingTeamForInnings(
                                  1,
                                  this.state.matchFormat
                                )
                              : 0
                          }
                          team1Name={this.state.teams?.[0].name}
                          team2Name={this.state.teams?.[1].name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!!this.state.gameState &&
            !this.state.gameState.initialStatsLoaded && <StatsLoadingPage />}
          {!this.state.match && (
            <div className="page-title">No match selected</div>
          )}
        </div>
      </SimulatorRoute>
    );
  }
}
