import SockJsClient from "react-stomp";
import { ReplaySubject, Subject } from "rxjs";
import { services, showMessage } from "../types/services";

export class WebSocketService {
  private client: SockJsClient;
  private websocketUrlSubject: Subject<string> = new ReplaySubject(1);
  private websocketFunctions: Record<string, (msg: any) => void>;
  public readonly topicList: string[] = [
    "/user/queue/simulator-updates",
    "/user/queue/share-updates",
    "/user/queue/preferences-updates",
    "/user/queue/admin-preferences-updates",
    "/user/queue/player-stats-updates",
    "/user/queue/all-player-stats-updates",
    "/user/queue/historic-push-updates",
    "/user/queue/push-bracket-updates",
    "/user/queue/historic-player-stats-updates",
    "/user/queue/historic-player-form-updates",
    "/user/queue/historic-ground-form-updates",
    "/user/queue/ground-stats-updates",
    "/user/queue/match-stats-updates",
    "/user/queue/auto-ball-entry",
    "/user/queue/betfair-subscriptions",
    "/user/queue/stored-question-updates",
    "/user/queue/stored-question-odds-updates",
    "/user/queue/node-updates",
    "/user/queue/multiple-stage-updates",
    "/user/queue/stored-bet-scrape-updates",
    "/topic/stream-messages",
    "/topic/match-updates",
    "/topic/stats-updates",
    "/topic/investment-updates",
    "/topic/betfair-price-updates",
  ];

  public updateUrl(wsUrl: string) {
    this.websocketUrlSubject.next(wsUrl);
  }

  public setClient(client: SockJsClient) {
    this.client = client;
  }

  public getWebsocketUrlSubject(): Subject<string> {
    return this.websocketUrlSubject;
  }

  public init(): void {
    this.websocketFunctions = {
      BETFAIR: (msg) =>
        services.betfairService.addBetfairEventMessage(
          msg.betfairStreamMessage
        ),
      MATCH: (msg) => {
        if (
          services.currentGameService.currentMatch &&
          msg.matchId === services.currentGameService.currentMatch.matchId.value
        ) {
          services.currentGameService.updateMatch();
        }
      },
      SHARE_COMPLETE: (msg) => {
        showMessage(msg.removed ? "Share removed" : "Share complete");
        services.currentGameService.broadcastShareUpdate(msg.matchId);
      },
      SIMULATION: (msg) => services.simulationService.websocketUpdate(msg),
      NODES: (msg) => services.simulationService.updateNodeHealth(msg),
      STATS: (msg) => {
        switch (msg.type) {
          case "GROUND":
            services.groundStatsService.webSocketStatsLoaded(msg);
            break;
          case "PLAYER":
            services.playerStatsService.webSocketStatsLoaded(msg);
            break;
          default:
            break;
        }
      },
      ONE_PLAYER_STATS_UPDATE: (msg) =>
        services.playerStatsService.webSocketOneStatsUpdated(msg),
      GROUND_STATS_UPDATE: (msg) =>
        services.groundStatsService.webSocketStatsUpdated(msg),
      MATCH_STATS_UPDATE: (msg) =>
        services.matchStatsService.webSocketStatsUpdated(msg),
      ALL_PLAYER_STATS_UPDATE: (msg) =>
        services.playerStatsService.webSocketAllStatsUpdated(msg),
      HISTORIC_PLAYER_STATS_UPDATE: (msg) =>
        services.playerStatsService.historicPlayerStatsUpdate(msg),
      HISTORIC_PLAYER_FORM_UPDATE: (msg) =>
        services.playerStatsService.historicPlayerFormUpdate(msg),
      HISTORIC_GROUND_FORM_UPDATE: (msg) =>
        services.groundStatsService.historicGroundFormUpdate(msg),
      HISTORIC_PUSH_UPDATE: (msg) =>
        services.historicPushService.historicPushUpdate(msg),
      PUSH_BRACKET_UPDATE: (msg) =>
        services.historicPushService.pushBracketUpdate(msg),
      PREFERENCES_UPDATE: (msg) =>
        services.userService.websocketUpdateUserPreferences(msg),
      ADMIN_PREFERENCES_UPDATE: (msg) =>
        services.userService.websocketUpdateAdminPreferences(msg),
      INVESTMENT_UPDATE: () => services.investmentService.initiatePots(),
      AUTO_BALL_ENTRY: (msg) =>
        services.betfairService.handleAutoBallEntryMessage(msg),
      BETFAIR_SUBSCRIPTION: (msg) =>
        services.betfairService.handleSubscriptionMessage(msg),
      BETFAIR_PRICE: (msg) =>
        services.betfairService.addBetfairPriceMessage(msg),
      STORED_QUESTIONS_UPDATE: (msg) =>
        services.questionService.storedQuestionsWebsocketUpdate(msg),
      STORED_QUESTIONS_ODDS_UPDATE: (msg) =>
        services.questionService.storedQuestionsOddsWebsocketUpdate(msg),
      MULTIPLE_STAGE_UPDATE: (msg) =>
        services.multipleStageUpdateService.multipleStageUpdate(msg),
      STORED_BET_SCRAPE_UPDATE: (msg) =>
        services.betScraperService.storedBetScrapeUpdate(msg),
    };
  }

  public handleMessage(msg): void {
    if (!!msg) {
      const websocketFunction: (msg: any) => void =
        this.websocketFunctions[msg.updateType];
      if (!!websocketFunction) {
        websocketFunction(msg);
      } else {
        showMessage("Unhandled websocket message: " + msg.updateType, "error");
      }
    }
  }
}
