import React from "react";

import { ReactComponent as ThiefSVG } from "../../img/button-icons/thief.svg";
import { showConfirmationDialog } from "../../types/services";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

type Props = {
  comparedUserName: string;
  disabled: boolean;
  tooltipMessage: string;
  onClickHandler;
};

export default function StealStatsButton({
  comparedUserName,
  disabled,
  tooltipMessage,
  onClickHandler,
}: Props): React.JSX.Element {
  const effectiveColour = disabled ? "black" : "lightgrey";

  return (
    <TooltipIconButton
      title={tooltipMessage}
      disabled={disabled}
      onClick={() =>
        showConfirmationDialog(
          () => {
            onClickHandler();
          },
          `Steal ${comparedUserName}'s stats`,
          "Are you sure?"
        )
      }
      icon={
        <ThiefSVG
          style={{
            verticalAlign: "inherit",
            width: "1em",
            height: "1em",
            fontSize: "1rem",
          }}
          fill={effectiveColour}
        />
      }
      // Hack to vertically align the thief logo
      style={{ fontSize: "1rem" }}
    />
  );
}
