import { HttpService } from "./http-service";

export class LoggingService {
  private httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public logMessage(message: string): Promise<boolean> {
    const params: Map<string, string> = new Map();
    params.set("message", message);
    return this.httpService
      .get(`/api/front-end-logging-controller/log`, params)
      .then((response: boolean) => {
        return response;
      })
      .catch(() => {
        return false;
      });
  }

  public logException(error): Promise<boolean> {
    return this.httpService
      .post(`/api/front-end-logging-controller/log-exception`, {
        message: error && error.message,
        stackTrace: error && error.stack,
      })
      .then((response: boolean) => {
        return response;
      })
      .catch(() => {
        return false;
      });
  }
}
