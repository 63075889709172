import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ExtrasType,
  humanReadableExtrasType,
} from "../../../types/enums/extras-type";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../../types/enums/push-bracket";
import { BowlerExtrasFormData } from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const bowlerExtrasFormDataComponent = (data: BowlerExtrasFormData) => {
  const headers: string[] = ["Stat", "Actual", "Expected", "Bias"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={`data-row-global`}>
          <TableCell>Global Extras</TableCell>
          <TableCell>{format(data.totalActualExtras)}</TableCell>
          <TableCell>{format(data.totalExpectedExtras)}</TableCell>
          <TableCell>
            {format(data.totalActualExtras / data.totalExpectedExtras)}
          </TableCell>
        </TableRow>
        {Object.keys(PushBracket).map((key) => {
          const pushBracket = PushBracket[key];

          return (
            <TableRow key={pushBracket}>
              <TableCell>{humanReadablePushBrackets[pushBracket]}</TableCell>
              <TableCell>
                {format(data.totalActualExtrasByPush.get(pushBracket))}
              </TableCell>
              <TableCell>
                {format(data.totalExpectedExtrasByPush.get(pushBracket))}
              </TableCell>
              <TableCell>
                {format(
                  data.totalActualExtrasByPush.get(pushBracket) /
                    data.totalExpectedExtrasByPush.get(pushBracket)
                )}
              </TableCell>
            </TableRow>
          );
        })}
        {Object.keys(ExtrasType).map((key) => {
          const extrasType = ExtrasType[key];

          return (
            <TableRow key={extrasType}>
              <TableCell>{humanReadableExtrasType[extrasType]}s</TableCell>
              <TableCell>
                {format(data.totalActualExtrasByType.get(extrasType))}
              </TableCell>
              <TableCell>
                {format(data.totalExpectedExtrasByType.get(extrasType))}
              </TableCell>
              <TableCell>
                {format(
                  data.totalActualExtrasByType.get(extrasType) /
                    data.totalExpectedExtrasByType.get(extrasType)
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
