import { PlayerStatsWrapper } from "../../types/stats/player-stats";

export function batchUpdate(
  currentStats: PlayerStatsWrapper,
  comparedStats: PlayerStatsWrapper,
  updates: string[],
  onUpdate: (updatedStats: PlayerStatsWrapper) => void,
  index: number = -1
) {
  const clonedComparedStats = PlayerStatsWrapper.clone(
    comparedStats.playerStats
  );
  let updatedStats = currentStats.playerStats;
  updates.forEach((key) => {
    if (index !== -1) {
      const array = currentStats.playerStats[key] as Array<unknown>;
      const valueToReplace = clonedComparedStats[key][index];
      array.splice(index, 1, valueToReplace);
      updatedStats = {
        ...updatedStats,
        [key]: array,
      };
    } else {
      updatedStats = {
        ...updatedStats,
        [key]: clonedComparedStats[key],
      };
    }
  });

  onUpdate(
    new PlayerStatsWrapper(
      currentStats.playerStatsId,
      currentStats.createdBy,
      currentStats.createdAt,
      currentStats.name,
      updatedStats,
      currentStats.matchType
    )
  );
}

export function batchUpdateAll(
  currentStatsMap: Map<string, PlayerStatsWrapper>,
  comparedStatsMap: Map<string, PlayerStatsWrapper>,
  updates: string[],
  onBatchUpdate: (batchUpdatedStats: Map<string, PlayerStatsWrapper>) => void
) {
  let batchUpdatedStats = new Map();

  currentStatsMap.forEach((currentStats, playerId) => {
    const comparedStats = comparedStatsMap.get(playerId);
    if (!comparedStats) {
      return;
    }

    let updatedStats = currentStats.playerStats;

    updates.forEach((key) => {
      updatedStats = {
        ...updatedStats,
        [key]: comparedStats.playerStats[key],
      };
    });

    const updatedPlayerStatsWrapper = new PlayerStatsWrapper(
      currentStats.playerStatsId,
      currentStats.createdBy,
      currentStats.createdAt,
      currentStats.name,
      updatedStats,
      currentStats.matchType
    );

    batchUpdatedStats.set(playerId, updatedPlayerStatsWrapper);
  });

  onBatchUpdate(batchUpdatedStats);
}
