export interface InningsConfiguration {
  battingTeam: number;
  surge: boolean;
  surgeOvers: number;
  surgeAvailableFromOver: number;
  oversPerBowler: number;
  powerplayOversStart: number;
  powerplayOversEnd: number;
  maxWickets: number;
}

export function inningsConfigurationsEqual(
  config1: InningsConfiguration,
  config2: InningsConfiguration
): boolean {
  return (
    config1.surge === config2.surge &&
    config1.surgeOvers === config2.surgeOvers &&
    config1.surgeAvailableFromOver === config2.surgeAvailableFromOver &&
    config1.oversPerBowler === config2.oversPerBowler &&
    config1.powerplayOversStart === config2.powerplayOversStart &&
    config1.powerplayOversEnd === config2.powerplayOversEnd &&
    config1.maxWickets === config2.maxWickets
  );
}

export function overConfigurationsEqual(
  config1: number[],
  config2: number[]
): boolean {
  if (config1.length !== config2.length) {
    return false;
  } else {
    let equal = true;
    for (let i = 0; i < config1.length; i++) {
      if (config1[i] !== config2[i]) {
        equal = false;
      }
    }
    return equal;
  }
}

export function allOversEqual(overConfiguration: number[]): boolean {
  let equal = true;
  let previous = overConfiguration[0];
  for (let i = 1; i < overConfiguration.length; i++) {
    if (overConfiguration[i] !== previous) {
      equal = false;
    }
    previous = overConfiguration[i];
  }
  return equal;
}
