import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import {
  InningsStatisticType,
  StatisticType,
} from "../../../types/enums/statistic-type";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import { getTitleColour } from "../game-state-display/tsbc";

interface TableRowData {
  id: UUID;
  name: string;
  balls: number;
  runs: number;
  wickets: number;
  maidens: number;
  fours: number;
  sixes: number;
  dots: number;
  wides: number;
  noBalls: number;
  economy: string;
  average: string;
  strikeRate: string;
}

interface Props {
  gameState: GameState;
  squad: Squad;
  innings: number;
  primaryColour: string;
}

interface State {
  rows: TableRowData[];
}

export class BowlingScorecardDisplay extends Component<Props, State> {
  static defaultProps = {
    primaryColour: "#ffffff",
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }

  componentDidMount(): void {
    this.updateRows();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateRows();
    }
  }

  private updateRows() {
    const newRows: TableRowData[] = [];
    this.props.squad &&
      this.props.squad.players.forEach((player: Player) => {
        const isBowler =
          this.props.gameState &&
          this.props.gameState.bowler &&
          this.props.gameState.bowler.value === player.playerId.value;
        const balls: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_BALLS_BOWLED,
            player.playerId
          );
        const runs: number = this.props.gameState.getPlayerNumberStatForInnings(
          this.props.innings,
          StatisticType.BOWLER_RUNS_CONCEDED,
          player.playerId
        );
        const wickets: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_WICKETS_TAKEN,
            player.playerId
          );
        const maidens: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_MAIDENS,
            player.playerId
          );
        const fours: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_FOURS_CONCEDED,
            player.playerId
          );
        const sixes: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_SIXES_CONCEDED,
            player.playerId
          );
        const dots: number = this.props.gameState.getPlayerNumberStatForInnings(
          this.props.innings,
          StatisticType.BOWLER_DOTS,
          player.playerId
        );
        const wides: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_WIDES,
            player.playerId
          );
        const noBalls: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BOWLER_NO_BALLS,
            player.playerId
          );
        const economy: string =
          balls === 0
            ? null
            : ((runs / balls) * 6).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        const average: string =
          wickets === 0
            ? null
            : (runs / wickets).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });
        const strikeRate: string =
          wickets === 0
            ? null
            : (balls / wickets).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });

        if (balls !== 0 || isBowler) {
          newRows.push({
            id: player.playerId,
            name: player.longName,
            balls,
            runs,
            wickets,
            maidens,
            fours,
            sixes,
            dots,
            wides,
            noBalls,
            economy,
            average,
            strikeRate,
          });
        }
      });
    const overBowlers: Map<number, UUID[]> =
      this.props.gameState.inningsStats[InningsStatisticType.OVER_BOWLERS][
        this.props.innings - 1
      ];
    newRows.sort((row1, row2) => {
      let player1FirstOver: number = null;
      let player2FirstOver: number = null;
      for (let i: number = 0; i < overBowlers.size; i = i + 1) {
        const bowlers: UUID[] = overBowlers.get(i);
        if (bowlers.find((id) => id.value === row1.id.value) !== undefined) {
          player1FirstOver = i;
        }
        if (bowlers.find((id) => id.value === row2.id.value) !== undefined) {
          player2FirstOver = i;
        }
        if (player1FirstOver !== null || player2FirstOver !== null) {
          if (player1FirstOver === null) {
            player1FirstOver = i + 1;
          }
          if (player2FirstOver === null) {
            player2FirstOver = i + 1;
          }
          break;
        }
      }
      return player1FirstOver - player2FirstOver;
    });
    this.setState({ rows: newRows });
  }

  public render() {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell id="scorecard-table-cell">Name</TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Overs
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Runs
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Wickets
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Maidens
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                4s
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                6s
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                0s
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Wides
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                No Balls
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Economy
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Average
              </TableCell>
              <TableCell align="right" id="scorecard-table-cell">
                Strike Rate
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows.map((row) => {
              const backgroundColour =
                this.props.gameState &&
                this.props.gameState.bowler &&
                row.id.value === this.props.gameState.bowler.value &&
                this.props.innings === this.props.gameState.innings
                  ? this.props.primaryColour
                  : "";
              const textColour =
                backgroundColour === ""
                  ? "#000000"
                  : getTitleColour(backgroundColour);
              return (
                <TableRow key={row.name}>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {this.props.gameState.getOverTextForBalls(row.balls)}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.runs}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.wickets}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.maidens}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.fours}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.sixes}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.dots}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.wides}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.noBalls}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.economy}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.average}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    id="scorecard-table-cell"
                    align="right"
                  >
                    {row.strikeRate}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
