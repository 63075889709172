export enum SinceDate {
  ALL_TIME = "ALL_TIME",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
  LAST_1_YEARS = "LAST_1_YEARS",
  LAST_2_YEARS = "LAST_2_YEARS",
  LAST_3_YEARS = "LAST_3_YEARS",
  LAST_4_YEARS = "LAST_4_YEARS",
  LAST_5_YEARS = "LAST_5_YEARS",
  LAST_6_YEARS = "LAST_6_YEARS",
  LAST_7_YEARS = "LAST_7_YEARS",
  LAST_8_YEARS = "LAST_8_YEARS",
  LAST_9_YEARS = "LAST_9_YEARS",
  LAST_10_YEARS = "LAST_10_YEARS",
}

export const sinceDateNames: Record<SinceDate, string> = {
  ALL_TIME: "All Time",
  LAST_7_DAYS: "Last 7 Days",
  LAST_30_DAYS: "Last 30 Days",
  LAST_3_MONTHS: "Last 3 Months",
  LAST_6_MONTHS: "Last 6 Months",
  LAST_1_YEARS: "Last 1 Year",
  LAST_2_YEARS: "Last 2 Years",
  LAST_3_YEARS: "Last 3 Years",
  LAST_4_YEARS: "Last 4 Years",
  LAST_5_YEARS: "Last 5 Years",
  LAST_6_YEARS: "Last 6 Years",
  LAST_7_YEARS: "Last 7 Years",
  LAST_8_YEARS: "Last 8 Years",
  LAST_9_YEARS: "Last 9 Years",
  LAST_10_YEARS: "Last 10 Years",
};
