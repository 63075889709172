import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";
import { BowlOutcome } from "../../../types/enums/bowl-outcome";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../../types/enums/push-bracket";
import { PercentsDistributionData } from "../../../types/form/ground-form-modules";
import {
  BatsmanPercentsDistributionData,
  BowlerPercentsDistributionData,
} from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

type BatsmanOrBowlerPercentDistributionData =
  | PercentsDistributionData
  | BatsmanPercentsDistributionData
  | BowlerPercentsDistributionData;

export const percentsDistributionDataComponent = (
  data: BatsmanOrBowlerPercentDistributionData
) => {
  const headers: string[] = [
    "Phase",
    "0",
    "1",
    "2",
    "3",
    "4",
    "6",
    "Balls",
    "Weight",
  ];

  const tableConfig = [
    {
      title: "Global",
      totalActualProperty: "totalActualByPhase",
      totalBallsProperty: "totalBallsByPhase",
      totalWeightProperty: "totalWeightByPhase",
      condition: () => true,
    },
    {
      title: "Against Pace",
      totalActualProperty: "totalActualByPhasePace",
      totalBallsProperty: "totalBallsByPhasePace",
      totalWeightProperty: "totalWeightByPhasePace",
      condition: (data) => data instanceof BatsmanPercentsDistributionData,
    },
    {
      title: "Against Spin",
      totalActualProperty: "totalActualByPhaseSpin",
      totalBallsProperty: "totalBallsByPhaseSpin",
      totalWeightProperty: "totalWeightByPhaseSpin",
      condition: (data) => data instanceof BatsmanPercentsDistributionData,
    },
    {
      title: "Against Left Handers",
      totalActualProperty: "totalActualByPhaseLeftHanders",
      totalBallsProperty: "totalBallsByPhaseLeftHanders",
      totalWeightProperty: "totalWeightByPhaseLeftHanders",
      condition: (data) => data instanceof BowlerPercentsDistributionData,
    },
    {
      title: "Against Right Handers",
      totalActualProperty: "totalActualByPhaseRightHanders",
      totalBallsProperty: "totalBallsByPhaseRightHanders",
      totalWeightProperty: "totalWeightByPhaseRightHanders",
      condition: (data) => data instanceof BowlerPercentsDistributionData,
    },
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={6} align="center">
            Outcome %
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableConfig.map((config) => (
          <Fragment key={config.title}>
            {config.condition(data) && (
              <>
                <TableRow>
                  <TableCell colSpan={headers.length}>{config.title}</TableCell>
                </TableRow>
                {Object.keys(PushBracket).map((value: string) => {
                  const pushBracket = PushBracket[value];

                  return percentDistributionTableRow(
                    config.title,
                    data[config.totalActualProperty],
                    data[config.totalBallsProperty],
                    data[config.totalWeightProperty],
                    pushBracket
                  );
                })}
              </>
            )}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

const percentDistributionTableRow = (
  name: string,
  data: Map<PushBracket, Map<BowlOutcome, number>>,
  ballsByPhase: Map<PushBracket, number>,
  weightByPhase: Map<PushBracket, number>,
  pushBracket: PushBracket
) => {
  return (
    <TableRow key={`${name}-${pushBracket}`}>
      <TableCell>{humanReadablePushBrackets[pushBracket]}</TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.DOT))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.ONE))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.TWO))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.THREE))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.FOUR))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.SIX))}
      </TableCell>
      <TableCell>{format(ballsByPhase.get(pushBracket))}</TableCell>
      <TableCell>{format(weightByPhase.get(pushBracket))}</TableCell>
    </TableRow>
  );
};
