import { useEffect, useState } from "react";
import {
  StrikeRateAndWicketPercentData,
  strikeRateAndWicketPercentDataProperties,
  strikeRateAndWicketPercentModuleNames,
  StrikeRateAndWicketPercentModuleType,
} from "../../types/simulator/modules/strike-rate-and-wicket-percent-modules";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import { AdminPreferences } from "../../types/preferences/admin-preferences";

interface Props {
  open: boolean;
  moduleType: StrikeRateAndWicketPercentModuleType;
  initialModuleData: StrikeRateAndWicketPercentData;
  adminPreferences: AdminPreferences;
  onCancel: () => void;
  onProceed: (
    moduleData: StrikeRateAndWicketPercentData,
    moduleType: StrikeRateAndWicketPercentModuleType
  ) => void;
}

export default function StrikeRateAndWicketPercentModuleSettingsModal({
  open,
  moduleType,
  adminPreferences,
  initialModuleData,
  onCancel,
  onProceed,
}: Readonly<Props>): React.JSX.Element {
  const [moduleData, setModuleData] = useState<StrikeRateAndWicketPercentData>(
    JSON.parse(JSON.stringify(initialModuleData))
  );
  const [propertiesValid, setPropertiesValid] = useState<any>({});

  useEffect(() => {
    setModuleData(JSON.parse(JSON.stringify(initialModuleData)));
    setPropertiesValid({});
  }, [open, moduleType, initialModuleData]);

  const invalid = (): boolean => {
    return !Object.values(propertiesValid).every((value) => value);
  };

  return (
    <CreationDialog
      open={open}
      label={strikeRateAndWicketPercentModuleNames[moduleType] + " settings"}
      invalid={invalid()}
      disabled={false}
      onCancel={() => onCancel()}
      onProceed={() => onProceed(moduleData, moduleType)}
      proceedText="OK"
      colour="#535455"
    >
      {moduleData && (
        <div>
          {strikeRateAndWicketPercentDataProperties(adminPreferences)[moduleType].map(
            (property) => (
              <div key={property.propertyName}>
                <NumberSelector
                  label={property.humanReadableName}
                  min={property.constraints.min}
                  max={property.constraints.max}
                  step={property.constraints.step}
                  decimalPlaces={property.constraints.decimalPlaces}
                  initial={moduleData[property.propertyName]}
                  onValid={(valid) => {
                    setModuleData({
                      ...moduleData,
                      [property.propertyName]: valid,
                    });
                    setPropertiesValid({
                      ...propertiesValid,
                      [property.propertyName]: true,
                    });
                  }}
                  onInvalid={() =>
                    setPropertiesValid({
                      ...propertiesValid,
                      [property.propertyName]: false,
                    })
                  }
                />
                <div className="italic module-description">
                  {property.description}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </CreationDialog>
  );
}
