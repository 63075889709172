import { ReplaySubject, Subject } from "rxjs";
import { MatchInfo } from "../types/entities/match-info";
import { Season } from "../types/entities/season";
import { Series } from "../types/entities/series";
import { MatchType } from "../types/enums/match-type";
import { PushBracket } from "../types/enums/push-bracket";
import { showMessage } from "../types/services";
import { UUID } from "../types/uuid";
import { HttpService } from "./http-service";

export interface HistoricPushMessage {
  requestId: UUID;
  done: number;
  size: number;
}

export interface PushBracketUpdateMessage {
  message: string;
  done: number;
  size: number;
}

export interface SkewByPush {
  pushStart: number;
  pushEnd: number;
  averageExpectedSr: number;
  averageActualSr: number;
  averageExpectedWpc: number;
  averageActualWpc: number;
  confidence: number;
}

export interface MatchTypeSkew {
  matchTypeSkewId: UUID;
  source: MatchType;
  destination: MatchType;
  skewByPushMap: Map<number, SkewByPush>;
}

export class HistoricPushService {
  private httpService: HttpService;
  private calculateHistoricPushRequestId: UUID;
  public historicPushSelectedSeason: Subject<Season> = new ReplaySubject(1);
  public historicPushSelectedSeries: Subject<Series> = new ReplaySubject(1);
  public historicPushSelectedMatch: Subject<MatchInfo> = new ReplaySubject(1);
  public historicPushUpdateSubject: Subject<HistoricPushMessage> =
    new ReplaySubject(1);
  public pushBracketsUpdateSubject: Subject<PushBracketUpdateMessage> =
    new ReplaySubject(1);
  public pushBracketsSubject: Subject<Map<MatchType, [PushBracket, number][]>> =
    new ReplaySubject(1);
  public matchTypeSkewSubject: Subject<
    Map<MatchType, Map<MatchType, MatchTypeSkew>>
  > = new ReplaySubject(1);

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public initiate() {
    this.getMatchTypeSkews();
    this.getPushBrackets();
  }

  public setCalculateHistoricPushRequestId(requestId: UUID) {
    this.calculateHistoricPushRequestId = requestId;
  }

  public async recalculateHistoricPush() {
    const params: Map<string, string> = new Map();
    this.calculateHistoricPushRequestId = UUID.randomUUID();
    params.set("customSettings", "false");
    params.set("requestId", this.calculateHistoricPushRequestId.value);
    this.httpService
      .get(`/api/historic-push-controller/update-historic-push`, params, false)
      .catch((reason) => {
        showMessage(`Failed to update historic push: ${reason}`, "error");
        return null;
      });
  }

  public async getMatchTypeSkews() {
    this.httpService
      .get(`/api/historic-push-controller/get-match-type-skews`)
      .then((response) => {
        const fullResult: Map<
          MatchType,
          Map<MatchType, MatchTypeSkew>
        > = new Map();
        Object.keys(response).forEach((sourceKey) => {
          const source: MatchType = MatchType[sourceKey];
          const sourceMap = response[sourceKey];

          const sourceResult: Map<MatchType, MatchTypeSkew> = new Map();
          Object.keys(sourceMap).forEach((destinationKey) => {
            const destination: MatchType = MatchType[destinationKey];
            const matchTypeSkewRaw = sourceMap[destinationKey];
            const matchTypeSkew: MatchTypeSkew = {
              matchTypeSkewId: UUID.fromString(
                matchTypeSkewRaw.matchTypeSkewId
              ),
              source,
              destination,
              skewByPushMap: this.deserializeSkewByPushMap(
                matchTypeSkewRaw.skewByPushMap
              ),
            };
            sourceResult.set(destination, matchTypeSkew);
          });
          fullResult.set(source, sourceResult);
        });
        this.matchTypeSkewSubject.next(fullResult);
      })
      .catch((reason) => {
        showMessage(`Failed to get match type skews: ${reason}`, "error");
      });
  }

  public getPushBrackets() {
    this.pushBracketsSubject.next(null);
    this.httpService
      .get(`/api/historic-push-controller/get-push-brackets`)
      .then((response) => {
        this.pushBracketsSubject.next(this.deserializePushBrackets(response));
      })
      .catch((reason) => {
        showMessage(`Failed to get push brackets: ${reason}`, "error");
      });
  }

  public async recalculatePushBrackets(): Promise<boolean> {
    this.matchTypeSkewSubject.next(null);
    this.pushBracketsSubject.next(null);
    return await this.httpService
      .get(`/api/historic-push-controller/recalculate-push-brackets`)
      .then((response) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to update push brackets: ${reason}`, "error");
        return false;
      });
  }

  public pushBracketUpdate(message: PushBracketUpdateMessage): void {
    if (message.done === message.size) {
      this.getPushBrackets();
      this.getMatchTypeSkews();
      showMessage("Push Bracket Calculation Complete", "info");
    }
    this.pushBracketsUpdateSubject.next(
      this.deserializePushBracketUpdateMessage(message)
    );
  }

  public historicPushUpdate(message: any) {
    if (
      !!message.requestId &&
      !!this.calculateHistoricPushRequestId &&
      this.calculateHistoricPushRequestId.value === message.requestId
    ) {
      this.historicPushUpdateSubject.next(
        this.deserializeHistoricPushMessage(message)
      );
    }
  }

  private deserializeHistoricPushMessage(message: any): HistoricPushMessage {
    return {
      requestId: UUID.fromString(message.requestId),
      done: message.done,
      size: message.size,
    };
  }

  public deserializePushBracketUpdateMessage(
    message: any
  ): PushBracketUpdateMessage {
    return {
      message: message.message,
      done: message.done,
      size: message.size,
    };
  }

  private deserializePushBrackets(
    response: any
  ): Map<MatchType, [PushBracket, number][]> {
    const result: Map<MatchType, [PushBracket, number][]> = new Map();
    Object.keys(response).forEach((key) => {
      const matchType: MatchType = MatchType[key];
      const pushBrackets: [PushBracket, number][] = response[key].map(
        (element) => [PushBracket[element.pushBracket], element.bracketEnd]
      );
      result.set(matchType, pushBrackets);
    });
    return result;
  }

  private deserializeSkewByPushMap(message: any): Map<number, SkewByPush> {
    const map: Map<number, SkewByPush> = new Map();
    Object.keys(message).forEach((key) => {
      const value = message[key];
      const skewByPush: SkewByPush = {
        pushStart: value.pushStart,
        pushEnd: value.pushEnd,
        averageExpectedSr: value.averageExpectedSr,
        averageActualSr: value.averageActualSr,
        averageExpectedWpc: value.averageExpectedWpc,
        averageActualWpc: value.averageActualWpc,
        confidence: value.confidence,
      };
      map.set(Number(key), skewByPush);
    });
    return map;
  }
}
