import { UUID } from "../uuid";

export interface NodeHealth {
  nodeConfigurationId: UUID;
  status: string;
  ping: number;
  version: string;
  maxCores: number;
  master: boolean;
}

export function deserializeNodeHealthMap(json: any): NodeHealth[] {
  const array: NodeHealth[] = [];
  Object.keys(json).forEach((key) => {
    const nodeConfigurationId: UUID = UUID.fromString(key);
    const status = json[key].status;
    const ping = json[key].ping;
    const version = json[key].version;
    const maxCores = json[key].maxCores;
    const master = json[key].master;
    array.push({
      nodeConfigurationId,
      status,
      ping,
      version,
      maxCores,
      master,
    });
  });
  return array;
}
