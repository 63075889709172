import { UUID } from "../uuid";
import { deserializeTransactions, Transaction } from "./transaction";

export interface PotSummary {
  potStatus: PotStatus;
  investorPotStatusMap: Map<string, InvestorPotStatus>;
}

export interface PotStatus {
  potHistoryId: UUID;
  transactionId: UUID;
  createdAt: number;
  totalDeposits: number;
  totalWithdrawals: number;
  totalRequestedInvestment: number;
  totalApprovedInvestment: number;
  totalHoldings: number;
  eventHistory: Transaction[];
}

export interface InvestorPotStatus {
  investorPotHistoryId: UUID;
  transactionId: UUID;
  userId: UUID;
  createdAt: number;
  totalDeposits: number;
  totalWithdrawals: number;
  totalRequestedInvestment: number;
  totalApprovedInvestment: number;
  totalHoldings: number;
  eventHistory: Transaction[];
}

export const deserializePotSummary: (json: any) => PotSummary = (json) => {
  return {
    potStatus: deserializePotStatus(json.potStatus),
    investorPotStatusMap: deserializeInvestorPotStatusMap(
      json.investorPotStatusMap
    ),
  };
};

export const deserializePotStatus: (json: any) => PotStatus = (json) => {
  return {
    potHistoryId: !!json.potHistoryId
      ? UUID.fromString(json.potHistoryId)
      : null,
    transactionId: !!json.transactionId
      ? UUID.fromString(json.transactionId)
      : null,
    createdAt: json.createdAt,
    totalDeposits: json.totalDeposits,
    totalWithdrawals: json.totalWithdrawals,
    totalRequestedInvestment: json.totalRequestedInvestment,
    totalApprovedInvestment: json.totalApprovedInvestment,
    totalHoldings: json.totalHoldings,
    eventHistory: deserializeTransactions(json.eventHistory),
  };
};

export const deserializeInvestorPotStatusMap: (
  json: any
) => Map<string, InvestorPotStatus> = (json) => {
  const map: Map<string, InvestorPotStatus> = new Map();

  if (!!json) {
    Object.keys(json).forEach((userId: string) =>
      map.set(userId, deserializeInvestorPotStatus(json[userId]))
    );
  }

  return map;
};

export const deserializeInvestorPotStatus: (json: any) => InvestorPotStatus = (
  json
) => {
  return {
    investorPotHistoryId: !!json.investorPotHistoryId
      ? UUID.fromString(json.investorPotHistoryId)
      : null,
    transactionId: !!json.transactionId
      ? UUID.fromString(json.transactionId)
      : null,
    userId: !!json.userId ? UUID.fromString(json.userId) : null,
    createdAt: json.createdAt,
    totalDeposits: json.totalDeposits,
    totalWithdrawals: json.totalWithdrawals,
    totalRequestedInvestment: json.totalRequestedInvestment,
    totalApprovedInvestment: json.totalApprovedInvestment,
    totalHoldings: json.totalHoldings,
    eventHistory: deserializeTransactions(json.eventHistory),
  };
};
