import { Component } from "react";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { NumberArcSlider } from "../stats-editing-components/number-arc-slider";

interface Props {
  preferences: AdminPreferences;
  onUpdate: (field: string, value: number) => void;
}

export class PlayerFormPreferencesComponent extends Component<Props> {
  public render() {
    return (
      <div>
        {!!this.props.preferences && (
          <div className="player-form-settings">
            <NumberArcSlider
              initialValue={
                this.props.preferences.effectivePercentForAllTimeStats
              }
              onChange={(value: number) =>
                this.props.onUpdate("effectivePercentForAllTimeStats", value)
              }
              propertyDescription="All Time Percent"
              minValue={0}
              maxValue={1}
              size={150}
              startAngle={40}
              endAngle={320}
            />
            <div>
              Games for full confidence:
              <NumberSelector
                label="All Time:"
                min={0}
                max={100000}
                initial={this.props.preferences.confidenceForAllTimeStats}
                onValid={(value) =>
                  this.props.onUpdate("confidenceForAllTimeStats", value)
                }
              />
              <NumberSelector
                label="Rolling:"
                min={0}
                max={100000}
                initial={this.props.preferences.confidenceForRollingStats}
                onValid={(value) =>
                  this.props.onUpdate("confidenceForRollingStats", value)
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
