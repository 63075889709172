import { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from "chart.js";
import "./App.css";
import { NavigationBar } from "./components/navigation-bar/navigation-bar";
import { SnackBarManager } from "./components/snack-bar-manager";
import {
  RouteEnum,
  navBarShownOnRoute,
  routeComponents,
  routeFromPath,
  routePaths,
} from "./routes";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  TimeSeriesScale,
  LineController,
  BarController
);

export default function ComponentsWrapper(): React.JSX.Element {
  const [showNavBar, setShowNavBar] = useState<boolean>(false);

  let location = useLocation();

  useEffect(() => {
    if (location) {
      setShowNavBar(navBarShownOnRoute[routeFromPath[location.pathname]]);
    }
  }, [location]);

  return (
    <>
      {showNavBar && <NavigationBar />}
      {Object.keys(RouteEnum).map((key) => {
        const route: RouteEnum = RouteEnum[key];
        return (
          <Route
            key={`route-${key}`}
            path={routePaths[route]}
            component={routeComponents[route]}
          />
        );
      })}
      <SnackBarManager />
    </>
  );
}
