import { Component } from "react";
import { MatchInfo } from "../../../types/entities/match-info";
import { Ground } from "../../../types/entities/ground";

interface Props {
  matchInfo: MatchInfo;
  ground: Ground;
}

export class GameTitle extends Component<Props> {
  public render() {
    return (
      <div className="match-info-text">
        <div className="team-colour-container">
          <div
            className="team-colour-banner-left"
            style={{
              backgroundColor:
                this.props.matchInfo == null
                  ? "#f8f8ff"
                  : this.props.matchInfo.team1Colour === null
                  ? "#888888"
                  : this.props.matchInfo.team1Colour,
            }}
          />
          <div
            className="team-colour-banner-right"
            style={{
              backgroundColor:
                this.props.matchInfo == null
                  ? "#f8f8ff"
                  : this.props.matchInfo.team2Colour === null
                  ? "#888888"
                  : this.props.matchInfo.team2Colour,
            }}
          />
        </div>
        {this.props.matchInfo === null ? (
          <div className="no-match-selected">No match selected</div>
        ) : (
          <div className="game-title-container">
            <h2 className="versus-text">
              {this.props.matchInfo.team1Name}
              <div className="versus-divider">-vs-</div>
              {this.props.matchInfo.team2Name}
            </h2>
            <div className="ground-name">
              at {this.props.ground && this.props.ground.name}
            </div>
          </div>
        )}
      </div>
    );
  }
}
