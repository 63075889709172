import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { BiasByBallData } from "../../../types/form/player-form-modules";
import { format } from "../../simulator-page/simulator-utils";

export const upToBallsDataComponent = (data: BiasByBallData) => {
  const headers: string[] = [
    "Up to Balls Bracket",
    "Actual",
    "Expected",
    "Bias",
    "Balls",
    "Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[0, 1, 2].map((bracket: number) => (
          <TableRow key={bracket}>
            <TableCell>Bracket {bracket + 1}</TableCell>
            <TableCell>{format(data.actual[bracket])}</TableCell>
            <TableCell>{format(data.expected[bracket])}</TableCell>
            <TableCell>
              {format(data.actual[bracket] / data.expected[bracket])}
            </TableCell>
            <TableCell>{format(data.occurrences[bracket])}</TableCell>
            <TableCell>{format(data.weight[bracket])}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
