import { ReactNode } from "react";

type Props = {
  comparedUserName: string;
  children: ReactNode;
};

export default function StealStatsHeadingAndButton({
  comparedUserName,
  children,
}: Readonly<Props>) {
  return (
    <div className="comparison-title-and-steal-button">
      <span>Compared to {comparedUserName}</span>

      {children}
    </div>
  );
}
