import { UUID } from "../uuid";
import { Entity } from "./entity";

export class Season implements Entity {
  public entityId: UUID;
  public seasonId: UUID;
  public name: string;
  public createdAt: number;
  public createdBy: UUID;

  constructor(
    entityId: UUID,
    seasonId: UUID,
    name: string,
    createdAt: number,
    createdBy: UUID
  ) {
    this.entityId = entityId;
    this.seasonId = seasonId;
    this.name = name;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
  }

  public toString(): string {
    return this.name;
  }

  public static deserializeList(json: any) {
    const seasons: Season[] = [];
    json.forEach((element) => {
      seasons.push(this.deserializeOne(element));
    });
    return seasons.sort().reverse();
  }

  public static deserializeOne(responseJSON: any): Season {
    return new Season(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.seasonId),
      responseJSON.name,
      responseJSON.createdAt,
      UUID.fromString(responseJSON.createdBy)
    );
  }

  public static emptyWithName(name: string): Season {
    return new Season(null, null, name, Date.now(), null);
  }

  public static getTypeName(): string {
    return "season";
  }

  public static serialize(season: Season): any {
    return {
      entityId: season.entityId === null ? null : season.entityId.value,
      seasonId: season.seasonId === null ? null : season.seasonId.value,
      name: season.name,
      createdAt: season.createdAt,
      createdBy: season.createdBy === null ? null : season.createdBy.value,
    };
  }
}
