import React, { Component } from "react";
import { Chart } from "react-chartjs-2";
import { TransactionType } from "../../types/enums/transaction-type";
import { Transaction } from "../../types/investment/transaction";

interface Props {
  transactions: Transaction[];
}

export class TransactionHistoryChart extends Component<Props> {
  private getInvestmentDeltas(endOfPeriodTransaction: Transaction): number {
    let delta = 0;
    for (let t of this.props.transactions) {
      if (
        t.transactionType === TransactionType.DEPOSIT_REQUEST &&
        endOfPeriodTransaction.transactionData.clearedDeposits.includes(
          t.transactionId.value
        )
      ) {
        delta += t.transactionData.amount;
      } else if (
        t.transactionType === TransactionType.WITHDRAWAL_REQUEST &&
        endOfPeriodTransaction.transactionData.clearedWithdrawals.includes(
          t.transactionId.value
        )
      ) {
        delta -= t.transactionData.amount;
      }
    }
    return delta;
  }

  public render() {
    const positiveBarData: any[] = [];
    const negativeBarData: any[] = [];
    const depositData: any[] = [];
    const withdrawalData: any[] = [];
    const lineData: any[] = [];
    const labels: number[] = [];
    let current = 0;
    let newValue;
    let delta;

    for (let t of this.props.transactions.sort(
      (a, b) => a.createdAt - b.createdAt
    )) {
      switch (t.transactionType) {
        case TransactionType.PROFIT_AND_LOSS:
          delta = t.transactionData.profit;
          newValue = current + delta;
          positiveBarData.push(delta > 0 ? delta : null);
          negativeBarData.push(delta < 0 ? delta : null);
          depositData.push(null);
          withdrawalData.push(null);
          lineData.push(newValue);
          labels.push(t.createdAt);
          current = newValue;
          break;
        case TransactionType.END_OF_PERIOD:
          delta = this.getInvestmentDeltas(t);
          newValue = current + delta;
          positiveBarData.push(null);
          negativeBarData.push(null);
          depositData.push(delta > 0 ? delta : null);
          withdrawalData.push(delta < 0 ? delta : null);
          lineData.push(newValue);
          labels.push(t.createdAt);
          current = newValue;
          break;
        default:
          break;
      }
    }

    const lineDataset = {
      type: "line" as const,
      label: "Total Pot",
      fill: true,
      data: lineData,
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.2)",
    };

    const positiveBarDataset = {
      type: "bar" as const,
      label: "Profits",
      backgroundColor: "green",
      data: positiveBarData,
      barThickness: 10,
    };

    const negativeBarDataset = {
      type: "bar" as const,
      label: "Losses",
      backgroundColor: "red",
      data: negativeBarData,
      barThickness: 10,
    };

    const depositDataset = {
      type: "bar" as const,
      label: "Deposit",
      backgroundColor: "black",
      data: depositData,
      barThickness: 10,
    };

    const withdrawalDataset = {
      type: "bar" as const,
      label: "Withdrawal",
      backgroundColor: "orange",
      data: withdrawalData,
      barThickness: 10,
    };

    return (
      <div className="investor-list-container">
        <div>
          <Chart
            type="bar"
            options={{
              scales: {
                x: {
                  type: "time",
                  stacked: true,
                },
              },
            }}
            data={{
              labels,
              datasets: [
                lineDataset,
                positiveBarDataset,
                negativeBarDataset,
                depositDataset,
                withdrawalDataset,
              ],
            }}
          />
        </div>
      </div>
    );
  }
}
