import { Ball } from "../entities/ball";
import { GameState } from "../entities/game-state";
import { PushBracket } from "../enums/push-bracket";
import { CalculatedStatisticType } from "../enums/statistic-type";

import { BowlingAttackModuleType } from "./modules/bowling-attack-modules";

export interface SimulatorAverage {
  mean: number;
  sd: number;
}

export interface NodeSimulationSummary {
  timeTaken: number;
  numberOfSimulations: number;
}

export interface OverSummary {
  overSummary: string;
  surge: boolean;
}

export interface BowlerChoice {
  bowler: string;
  rewards: [BowlingAttackModuleType, Map<string, number>][];
}

export interface SimulatorRecordedBowl {
  balls: Ball[];
  aggression: number;
  adjustedAggression: number;
  strikeRateAndWicketPercent: number[];
  adjustedStrikeRateAndWicketPercent: number[];
  battingPercents: number[];
  adjustedBattingPercents: number[];
  accumulatedBattingPercents: number[];
  extrasPercents: number[];
  crossed: boolean;
  desperateRun: boolean;
  originalOutcome: number;
  bowlerChoice: BowlerChoice;
}

export interface SimulationResult {
  team1: string;
  team2: string;
  team1Wins: number;
  team2Wins: number;
  draws: number;
  numberOfSimulations: number;
  timeTaken: number;
  errors: string[];
  inningsMeans: Map<CalculatedStatisticType, number>;
  inningsMedians: Map<CalculatedStatisticType, number>;
  inningsMedianTotals: Map<CalculatedStatisticType, Map<number, number>>;
  playerMedians: Map<string, Map<CalculatedStatisticType, number>>;
  inningsStdDevs: Map<CalculatedStatisticType, number>;
  playerTotals: Map<CalculatedStatisticType, Map<string, number>>;
  playerOccurrences: Map<CalculatedStatisticType, Map<string, number>>;
  playerMeans: Map<CalculatedStatisticType, Map<string, number>>;
  playerStdDevs: Map<CalculatedStatisticType, Map<string, number>>;
  playerOversBowled: Map<string, Map<number, number>>;
  nodeSimulationSummaries: Map<string, NodeSimulationSummary>;
  recordedSimulations: SimulatorRecordedBowl[][];
  recordedFinalStates: GameState[];
  runsLines: Map<number, Map<number, number>>;
  playerStats: Map<CalculatedStatisticType, Map<string, number>>;
  halfTimeOddsForRunsLines: Map<number, number[]>;
  playerMeanWicketPercentByBallsFaced: Map<string, Map<number, number>>;
  playerMeanStrikeRateByBallsFaced: Map<string, Map<number, number>>;
  playerMeanPushByBallsFaced: Map<string, Map<number, number>>;
  meanStrikeRateByBall: Map<string, Map<number, number>>;
  meanWicketPercentByBall: Map<string, Map<number, number>>;
  meanPushByBall: Map<string, Map<number, number>>;
  ballsInEachBracket: Map<number, Map<PushBracket, number>>;
}

export const deserializeSimulationResult: (
  simulationResult: any
) => SimulationResult = (simulationResult: any) => {
  return {
    team1: simulationResult.team1,
    team2: simulationResult.team2,
    team1Wins: simulationResult.team1Wins,
    team2Wins: simulationResult.team2Wins,
    draws: simulationResult.draws,
    timeTaken: simulationResult.timeTaken,
    errors: simulationResult.errors,
    numberOfSimulations: simulationResult.numberOfSimulations,
    inningsMeans: deserializeInningsMap(simulationResult.inningsMeans),
    inningsMedians: deserializeInningsMap(simulationResult.inningsMedians),
    inningsMedianTotals: deserializeInningsMedianTotals(
      simulationResult.inningsMedianTotals
    ),
    playerMedians: deserializePlayerMediansMap(simulationResult.playerMedians),
    inningsStdDevs: deserializeInningsMap(simulationResult.inningsStdDevs),
    playerTotals: deserializePlayerMap(simulationResult.playerTotals),
    playerOccurrences: deserializePlayerMap(simulationResult.playerOccurrences),
    playerMeans: deserializePlayerMap(simulationResult.playerMeans),
    playerStdDevs: deserializePlayerMap(simulationResult.playerStdDevs),
    playerOversBowled: deserializeUUIDNumberMap(
      simulationResult.playerOversBowled
    ),
    nodeSimulationSummaries: deserializeNodeSimulationSummaries(
      simulationResult.nodeSimulationSummaries
    ),
    recordedSimulations: deserializeRecordedSimulations(
      simulationResult.recordedSimulations
    ),
    recordedFinalStates: GameState.deserializeList(
      simulationResult.recordedFinalStates || []
    ),
    runsLines: deserializeNumberToNumberMap(simulationResult.runsLines),
    playerStats: deserializePlayerMap(simulationResult.playerStats),
    halfTimeOddsForRunsLines: deserializeNumberToNumberArrayMap(
      simulationResult.halfTimeOddsForRunsLines
    ),
    playerMeanWicketPercentByBallsFaced: deserializeUUIDNumberMap(
      simulationResult.playerMeanWicketPercentByBallsFaced
    ),
    playerMeanStrikeRateByBallsFaced: deserializeUUIDNumberMap(
      simulationResult.playerMeanStrikeRateByBallsFaced
    ),
    playerMeanPushByBallsFaced: deserializeUUIDNumberMap(
      simulationResult.playerMeanPushByBallsFaced
    ),
    totalOccurrencesByBall: deserializeNumberMap(
      simulationResult.totalOccurrencesByBall
    ),
    meanStrikeRateByBall: deserializeUUIDNumberMap(
      simulationResult.meanStrikeRateByBall
    ),
    meanWicketPercentByBall: deserializeUUIDNumberMap(
      simulationResult.meanWicketPercentByBall
    ),
    meanPushByBall: deserializeUUIDNumberMap(simulationResult.meanPushByBall),
    ballsInEachBracket: deserializeNumberToPushBracketMap(
      simulationResult.ballsInEachBracket
    ),
  };
};

const deserializeNumberToNumberMap: (
  json: any
) => Map<number, Map<number, number>> = (json: any) => {
  const result: Map<number, Map<number, number>> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(Number(key), deserializeNumberMap(value));
    }
  }
  return result;
};

const deserializeNumberMap: (json: any) => Map<number, number> = (
  json: any
) => {
  const result: Map<number, number> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(Number(key), Number(value));
    }
  }
  return result;
};

const deserializeNumberToNumberArrayMap: (
  json: any
) => Map<number, number[]> = (json: any) => {
  const result: Map<number, number[]> = new Map();
  if (json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(Number(key), value as number[]);
    }
  }
  return result;
};

const deserializeRecordedSimulations: (
  json: any
) => SimulatorRecordedBowl[][] = (json: any) => {
  const result: SimulatorRecordedBowl[][] = [];
  !!json &&
    json.forEach((simulation) => {
      const simulationArray: SimulatorRecordedBowl[] = [];
      simulation.forEach((simulatorRecordedBowl) => {
        simulationArray.push(
          deserializeSimulatorRecordedBowl(simulatorRecordedBowl)
        );
      });
      result.push(simulationArray);
    });
  return result;
};

const deserializeSimulatorRecordedBowl: (json: any) => SimulatorRecordedBowl = (
  json: any
) => {
  return {
    balls: Ball.deserializeList(json.balls || []),
    aggression: json.aggression,
    adjustedAggression: json.adjustedAggression,
    strikeRateAndWicketPercent: json.strikeRateAndWicketPercent,
    adjustedStrikeRateAndWicketPercent: json.adjustedStrikeRateAndWicketPercent,
    battingPercents: json.battingPercents,
    adjustedBattingPercents: json.adjustedBattingPercents,
    accumulatedBattingPercents: json.accumulatedBattingPercents,
    extrasPercents: json.extrasPercents,
    crossed: json.crossed,
    desperateRun: json.desperateRun,
    originalOutcome: json.originalOutcome,
    bowlerChoice: deserializeBowlerChoice(json.bowlerChoice),
  };
};

const deserializeBowlerChoice: (json: any) => BowlerChoice = (json: any) => {
  if (!!json) {
    const rewards: [BowlingAttackModuleType, Map<string, number>][] = [];
    json.rewards.forEach((element: any) => {
      Object.keys(element).forEach((key) => {
        rewards.push([
          BowlingAttackModuleType[key],
          deserializePlayerStats(element[key]),
        ]);
      });
    });
    return {
      bowler: json.bowler,
      rewards,
    };
  } else {
    return null;
  }
};

const deserializePlayerMediansMap: (
  json: any
) => Map<string, Map<CalculatedStatisticType, number>> = (json: any) => {
  const result: Map<string, Map<CalculatedStatisticType, number>> = new Map();
  if (!!json) {
    Object.keys(json).forEach((playerId: string) => {
      result.set(playerId, new Map());
      Object.keys(json[playerId]).forEach((calculatedStatisticType) => {
        result
          .get(playerId)
          .set(
            CalculatedStatisticType[calculatedStatisticType],
            Number(json[playerId][calculatedStatisticType])
          );
      });
    });
  }
  return result;
};

const deserializeUUIDNumberMap: (
  json: any
) => Map<string, Map<number, number>> = (json: any) => {
  const result: Map<string, Map<number, number>> = new Map();
  if (!!json) {
    Object.keys(json).forEach((playerId: string) => {
      result.set(playerId, new Map());
      Object.keys(json[playerId]).forEach((overNumber) => {
        result
          .get(playerId)
          .set(Number(overNumber), Number(json[playerId][overNumber]));
      });
    });
  }
  return result;
};

const deserializeInningsMap: (
  json: any
) => Map<CalculatedStatisticType, number> = (json: any) => {
  const result: Map<CalculatedStatisticType, number> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(CalculatedStatisticType[key], Number(value));
    }
  }
  return result;
};

const deserializeInningsMedianTotals: (
  json: any
) => Map<CalculatedStatisticType, Map<number, number>> = (json: any) => {
  const result: Map<CalculatedStatisticType, Map<number, number>> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(CalculatedStatisticType[key], new Map());
      for (const [mapKey, mapValue] of Object.entries(value)) {
        result
          .get(CalculatedStatisticType[key])
          .set(Number(mapKey), Number(mapValue));
      }
    }
  }
  return result;
};

const deserializePlayerMap: (
  json: any
) => Map<CalculatedStatisticType, Map<string, number>> = (json: any) => {
  const result: Map<CalculatedStatisticType, Map<string, number>> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(CalculatedStatisticType[key], deserializePlayerStats(value));
    }
  }
  return result;
};

const deserializePlayerStats: (json: any) => Map<string, number> = (
  json: any
) => {
  const result: Map<string, number> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(key, Number(value));
    }
  }
  return result;
};

const deserializeNodeSimulationSummaries: (
  json: any
) => Map<string, NodeSimulationSummary> = (json: any) => {
  const result: Map<string, NodeSimulationSummary> = new Map();
  if (!!json) {
    for (const [key, value] of Object.entries(json)) {
      result.set(key, value as NodeSimulationSummary);
    }
  }
  return result;
};

const deserializeNumberToPushBracketMap = (json: any) => {
  const result: Map<number, Map<PushBracket, number>> = new Map();
  if (json) {
    Object.keys(json).forEach((innings) => {
      result.set(
        Number(innings),
        deserializePushBracketToNumberMap(json[innings])
      );
    });
  }

  return result;
};

const deserializePushBracketToNumberMap = (json: any) => {
  const result: Map<PushBracket, number> = new Map();
  if (json) {
    Object.keys(PushBracket).forEach((pushBracket) => {
      result.set(PushBracket[pushBracket], json[pushBracket]);
    });
  }

  return result;
};
