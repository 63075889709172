import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import {
  InningsStatisticType,
  StatisticType,
} from "../../../types/enums/statistic-type";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";
import { getTitleColour } from "../game-state-display/tsbc";

interface TableRowData {
  id: UUID;
  name: string;
  runs: number;
  dismissalMethod: string;
  faced: number;
  notFaced: number;
  fours: number;
  sixes: number;
  dots: number;
  strikeRate: string;
}

interface Total {
  totalWickets: number;
  totalBalls: number;
  totalRuns: number;
  totalFours: number;
  totalSixes: number;
  totalDots: number;
  totalStrikeRate: string;
}

interface Props {
  gameState: GameState;
  squad: Squad;
  innings: number;
  primaryColour: string;
  secondaryColour: string;
}

interface State {
  rows: TableRowData[];
  totals: Total;
  extras: number;
  noBalls: number;
  wides: number;
  byes: number;
  legByes: number;
  fow: string;
}

export class BattingScorecardDisplay extends Component<Props, State> {
  static defaultProps = {
    primaryColour: "#ffffff",
    secondaryColour: "#ffffff",
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      totals: {
        totalWickets: 0,
        totalBalls: 0,
        totalRuns: 0,
        totalFours: 0,
        totalSixes: 0,
        totalDots: 0,
        totalStrikeRate: null,
      },
      extras: 0,
      noBalls: 0,
      wides: 0,
      byes: 0,
      legByes: 0,
      fow: "",
    };
  }

  componentDidMount(): void {
    this.updateRows();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateRows();
    }
  }

  private updateRows() {
    const newRows: TableRowData[] = [];

    let totalBalls: number = 0;
    let totalFours: number = 0;
    let totalSixes: number = 0;
    let totalDots: number = 0;

    const totalWickets: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.WICKETS][
        this.props.innings - 1
      ];
    const totalRuns: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.TOTAL_RUNS][
        this.props.innings - 1
      ];
    const extras: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.EXTRAS][
        this.props.innings - 1
      ];
    const noBalls: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.TOTAL_NO_BALLS][
        this.props.innings - 1
      ];
    const wides: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.TOTAL_WIDES][
        this.props.innings - 1
      ];
    const byes: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.TOTAL_BYES][
        this.props.innings - 1
      ];
    const legByes: number =
      this.props.gameState &&
      this.props.gameState.inningsStats[InningsStatisticType.TOTAL_LEG_BYES][
        this.props.innings - 1
      ];

    this.props.squad &&
      this.props.squad.players.forEach((player: Player) => {
        const isBatsman1 =
          this.props.gameState &&
          this.props.gameState.batsman1 &&
          this.props.gameState.batsman1.value === player.playerId.value;
        const isBatsman2 =
          this.props.gameState &&
          this.props.gameState.batsman2 &&
          this.props.gameState.batsman2.value === player.playerId.value;
        const runs: number = this.props.gameState.getPlayerNumberStatForInnings(
          this.props.innings,
          StatisticType.BATSMAN_RUNS_SCORED,
          player.playerId
        );
        const faced: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BATSMAN_BALLS_ON_STRIKE,
            player.playerId
          );
        const notFaced: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BATSMAN_BALLS_OFF_STRIKE,
            player.playerId
          );
        const fours: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BATSMAN_FOURS,
            player.playerId
          );
        const sixes: number =
          this.props.gameState.getPlayerNumberStatForInnings(
            this.props.innings,
            StatisticType.BATSMAN_SIXES,
            player.playerId
          );
        const dots: number = this.props.gameState.getPlayerNumberStatForInnings(
          this.props.innings,
          StatisticType.BATSMAN_DOTS,
          player.playerId
        );
        const strikeRate: string =
          faced === 0
            ? null
            : ((runs / faced) * 100).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              });

        let dismissalMethod: string =
          this.props.gameState.getPlayerStringStatForInnings(
            this.props.innings,
            StatisticType.BATSMAN_DISMISSAL_METHOD,
            player.playerId
          );
        if (dismissalMethod === "" && (isBatsman1 || isBatsman2)) {
          dismissalMethod = "Not Out";
        }

        totalBalls += faced;
        totalFours += fours;
        totalSixes += sixes;
        totalDots += dots;

        if (
          faced !== 0 ||
          notFaced !== 0 ||
          isBatsman1 ||
          isBatsman2 ||
          dismissalMethod !== ""
        ) {
          newRows.push({
            id: player.playerId,
            name: player.longName,
            runs,
            dismissalMethod,
            faced,
            notFaced,
            fours,
            sixes,
            dots,
            strikeRate,
          });
        }
      });

    const fowList: number[] =
      this.props.gameState.inningsStats[InningsStatisticType.WICKET_FALLS][
        this.props.innings - 1
      ];
    const fowParts: string[] = [];
    fowList.forEach((runs, index) => {
      fowParts.push((index + 1) + "/" + runs);
    });
    const fow = fowParts.join(", ");

    const totalStrikeRate: string =
      totalBalls === 0
        ? null
        : ((totalRuns / totalBalls) * 100).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });

    this.setState({
      rows: newRows,
      totals: {
        totalWickets,
        totalBalls,
        totalRuns,
        totalFours,
        totalSixes,
        totalDots,
        totalStrikeRate,
      },
      extras,
      noBalls,
      wides,
      byes,
      legByes,
      fow,
    });
  }

  public render() {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell id="scorecard-table-cell">Name</TableCell>
              <TableCell id="scorecard-table-cell" align="right"></TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Runs
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Balls
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Fours
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Sixes
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Dots
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                Strike Rate
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows.map((row) => {
              const backgroundColour: string =
                this.props.gameState &&
                this.props.gameState.batsman1 &&
                row.id.value === this.props.gameState.batsman1.value &&
                this.props.innings === this.props.gameState.innings
                  ? this.props.primaryColour
                  : this.props.gameState &&
                    this.props.gameState.batsman2 &&
                    row.id.value === this.props.gameState.batsman2.value &&
                    this.props.innings === this.props.gameState.innings
                  ? this.props.secondaryColour
                  : "";
              const textColour =
                backgroundColour === ""
                  ? "#000000"
                  : getTitleColour(backgroundColour);
              return (
                <TableRow key={row.name}>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.dismissalMethod}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.runs}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.faced}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.fours}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.sixes}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.dots}
                  </TableCell>
                  <TableCell
                    id="scorecard-table-cell"
                    style={{
                      backgroundColor: backgroundColour,
                      color: textColour,
                    }}
                    align="right"
                  >
                    {row.strikeRate}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              key={"Extras" + this.props.innings}
              className="with-top-border"
            >
              <TableCell id="scorecard-table-cell" scope="row">
                Extras
              </TableCell>
              <TableCell id="scorecard-table-cell" align="left" colSpan={2}>
                {this.state.extras} ({this.state.byes}b, {this.state.legByes}lb,{" "}
                {this.state.wides}w, {this.state.noBalls}nb)
              </TableCell>
              <TableCell id="scorecard-table-cell"></TableCell>
              <TableCell id="scorecard-table-cell"></TableCell>
              <TableCell id="scorecard-table-cell"></TableCell>
              <TableCell id="scorecard-table-cell"></TableCell>
              <TableCell id="scorecard-table-cell"></TableCell>
            </TableRow>
            <TableRow
              key={"FoW" + this.props.innings}
              className="with-top-border"
            >
              <TableCell id="scorecard-table-cell" scope="row" colSpan={1}>
                Fall of Wickets
              </TableCell>
              <TableCell id="scorecard-table-cell" align="left" colSpan={7}>
                {this.state.fow}
              </TableCell>
            </TableRow>
            <TableRow
              key={"Totals" + this.props.innings}
              className="with-top-border"
            >
              <TableCell id="scorecard-table-cell" scope="row">
                Totals
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right" colSpan={2}>
                {this.state.totals.totalRuns}/{this.state.totals.totalWickets}
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                {this.state.totals.totalBalls}
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                {this.state.totals.totalFours}
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                {this.state.totals.totalSixes}
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                {this.state.totals.totalDots}
              </TableCell>
              <TableCell id="scorecard-table-cell" align="right">
                {this.state.totals.totalStrikeRate}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
