import { Component, ReactNode } from "react";
import { BetfairEvent } from "../../../types/betfair/betfair-event";
import { BetfairMessage } from "../../../types/betfair/betfair-message";
import { services } from "../../../types/services";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { Button } from "@mui/material";
import { Subscription } from "rxjs";
import { CricketChangeMessage } from "../../../types/betfair/cricket-change-message";
import { ReactComponent as BetfairSVG } from "../../../img/betfair/betfair.svg";
import TimeAgo from "timeago-react";
import { propsEqual } from "../../component-utils";
import {
  BetfairStatus,
  betfairStatusColours,
  betfairStatusNames,
  BetfairSubscription,
} from "../../../types/enums/betfair-status";
import BetfairBallSpeaker from "./betfair-ball-speaker";

interface Props {}

interface State {
  events: BetfairEvent[];
  currentMessages: BetfairMessage[];
  lastHeartBeat: number;
  lastStatus: BetfairStatus;
  speechEnabled: boolean;
  latestIncidentType: string;
  latestIncidentValue: string;
  latestIncidentOver: string;
  latestIncidentTime: number;
  latestStatus: string;
  matchesShown: boolean;
  betfairSubscriptions: Map<string, BetfairSubscription>;
}

export class BetfairDisplay extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      events: [],
      currentMessages: [],
      lastHeartBeat: null,
      speechEnabled: true,
      lastStatus: null,
      latestIncidentType: null,
      latestIncidentValue: null,
      latestIncidentOver: null,
      latestIncidentTime: null,
      latestStatus: null,
      matchesShown: true,
      betfairSubscriptions: new Map(),
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.betfairService.currentBetfairEventsSubject.subscribe(
        (currentMessages: BetfairMessage[]) => {
          this.updateLatestMessage(currentMessages);
          this.setState({ currentMessages });
        }
      )
    );
    this.subscriptions.push(
      services.betfairService.statusSubject.subscribe(
        (lastStatus: BetfairStatus) => {
          this.setState({
            lastStatus,
            latestStatus: betfairStatusNames[lastStatus],
          });
        }
      )
    );
    this.subscriptions.push(
      services.betfairService.betfairWebSocketLastHeartBeatSubject.subscribe(
        (lastHeartBeat: number) => {
          this.setState({ lastHeartBeat });
        }
      )
    );
    this.subscriptions.push(
      services.betfairService.matchSubscriptionsSubject.subscribe(
        (subscriptions: Map<string, BetfairSubscription>) => {
          this.setState({ betfairSubscriptions: subscriptions });
        }
      )
    );
    this.refresh();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.refresh();
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private refresh() {
    services.betfairService
      .getEvents()
      .then((events: BetfairEvent[]) => this.setState({ events }));
  }

  private updateLatestMessage(currentMessages: BetfairMessage[]) {
    let incidentListFound = false;
    currentMessages
      .slice(Math.max(0, this.state.currentMessages.length - 20))
      .reverse()
      .filter((message) => !!message)
      .forEach((message: BetfairMessage) => {
        const payloadObject = JSON.parse(message.payload);
        if (payloadObject.op !== undefined) {
          const parsed: CricketChangeMessage =
            payloadObject as CricketChangeMessage;
          if (parsed.cc && parsed.cc.length > 0) {
            if (
              !incidentListFound &&
              parsed.cc[0].incidentListWrapper &&
              parsed.cc[0].incidentListWrapper.incidentList &&
              parsed.cc[0].incidentListWrapper.incidentList.length > 0
            ) {
              incidentListFound = true;
              const incident = parsed.cc[0].incidentListWrapper.incidentList[0];
              if (
                !this.state.latestIncidentTime ||
                incident.actualTime > this.state.latestIncidentTime
              ) {
                this.setState({
                  latestIncidentType: incident.incidentType,
                  latestIncidentValue: incident.value,
                  latestIncidentOver: incident.overs,
                  latestIncidentTime: incident.actualTime,
                });
              }
            }
          }
        }
      });
  }

  private subscribeToEvent(id: string) {
    services.betfairService.changeBetfairEventId(id);
    if (id === null) {
      this.setState({
        currentMessages: [],
        lastHeartBeat: null,
        lastStatus: null,
      });
    }
  }

  private toggleShowMatches() {
    this.setState({ matchesShown: !this.state.matchesShown });
  }

  private mapMessagesToComponents(): ReactNode {
    const components: JSX.Element[] = [];
    let incidentListFound = false;
    let scoreFound = false;
    let playersFound = false;
    this.state.currentMessages
      .slice(Math.max(0, this.state.currentMessages.length - 20))
      .reverse()
      .filter((message) => !!message)
      .forEach((message: BetfairMessage) => {
        const payloadObject = JSON.parse(message.payload);
        if (payloadObject.op !== undefined) {
          const parsed: CricketChangeMessage =
            payloadObject as CricketChangeMessage;
          if (parsed.cc && parsed.cc.length > 0) {
            if (
              !incidentListFound &&
              parsed.cc[0].incidentListWrapper &&
              parsed.cc[0].incidentListWrapper.incidentList &&
              parsed.cc[0].incidentListWrapper.incidentList.length > 0
            ) {
              incidentListFound = true;
              components.push(
                ...parsed.cc[0].incidentListWrapper.incidentList.map(
                  (incident, index) => (
                    <div
                      key={
                        message.betfairStreamMessageId + "-incident-" + index
                      }
                    >
                      <div className="incident-text">
                        <div>{incident.overs}</div>
                        <div>{incident.value}</div>
                        <div>{incident.incidentType}</div>
                      </div>
                    </div>
                  )
                )
              );
            }
            if (
              !scoreFound &&
              parsed.cc[0].matchStats &&
              parsed.cc[0].matchStats.inningsStats &&
              parsed.cc[0].matchStats.inningsStats.length > 0
            ) {
              const inningsStats = parsed.cc[0].matchStats.inningsStats;
              const currentInnings = parsed.cc[0].matchStats.currentInnings;
              const currentInningsStats = inningsStats.find(
                (innings) => innings.inningsNum === currentInnings
              );
              if (currentInningsStats !== undefined) {
                scoreFound = true;
                components.push(
                  <div key={message.betfairStreamMessageId + "score"}>
                    <div className="incident-text">
                      <div>
                        {currentInningsStats.battingTeam}:{" "}
                        {currentInningsStats.inningsRuns}/
                        {currentInningsStats.inningsWickets} (
                        {currentInningsStats.inningsOvers})
                      </div>
                    </div>
                  </div>
                );
              }
            }
            if (
              !playersFound &&
              parsed.cc[0].matchStats &&
              parsed.cc[0].matchStats.battingTeamStats &&
              parsed.cc[0].matchStats.bowlingTeamStats
            ) {
              playersFound = true;
              const battingStats = parsed.cc[0].matchStats.battingTeamStats;
              const bowlingStats = parsed.cc[0].matchStats.bowlingTeamStats;
              components.push(
                <div key={message.betfairStreamMessageId + "player-data"}>
                  <div className="incident-text">
                    {battingStats.bat1Name}: {battingStats.bat1Runs} (
                    {battingStats.bat1Balls})
                  </div>
                  <div className="incident-text">
                    {battingStats.bat2Name}: {battingStats.bat2Runs} (
                    {battingStats.bat2Balls})
                  </div>
                  <div className="incident-text">
                    {bowlingStats.bowl1Name}: {bowlingStats.bowl1Runs}/
                    {bowlingStats.bowl1Wickets} ({bowlingStats.bowl1Overs})
                  </div>
                  <div className="incident-text">
                    {bowlingStats.bowl2Name}: {bowlingStats.bowl2Runs}/
                    {bowlingStats.bowl2Wickets} ({bowlingStats.bowl2Overs})
                  </div>
                </div>
              );
            }
          }
        }
      });
    return <div>{components}</div>;
  }

  public render() {
    const statusBackgroundStyle = {
      backgroundColor: !!this.state.lastStatus
        ? betfairStatusColours[this.state.lastStatus]
        : "#ffffff",
    };
    const subscription =
      !!services.currentGameService.currentMatchId &&
      !!this.state.betfairSubscriptions &&
      this.state.betfairSubscriptions.get(
        services.currentGameService.currentMatchId.value
      );
    const successfullySubscribed = !!subscription;
    return (
      <div className="betfair-display">
        <div className="betfair-logo">
          <BetfairSVG title="betfair logo" />
        </div>

        <div className="separator-title">
          Matches:
          <div className="separator-title-tooltip-buttons">
            <TooltipIconButton
              title={this.state.matchesShown ? "collapse" : "expand"}
              onClick={() => this.toggleShowMatches()}
              icon={this.state.matchesShown ? "expand_less" : "expand_more"}
            />
            <TooltipIconButton
              title={"Refresh"}
              onClick={() => this.refresh()}
              icon={"refresh"}
              testId="betfair-refresh-button"
            />
            <TooltipIconButton
              title={"Toggle Speech"}
              onClick={() =>
                this.setState({ speechEnabled: !this.state.speechEnabled })
              }
              icon={this.state.speechEnabled ? "volume_up" : "volume_off"}
            />
          </div>
        </div>
        <div className="betfair-event-list">
          {this.state.matchesShown &&
            this.state.events.map((event: BetfairEvent) => {
              const subscribedEvent: boolean =
                successfullySubscribed && subscription.eventId === event.id;
              return (
                <div
                  key={event.id}
                  className={
                    (subscribedEvent ? "successfully-subscribed " : "") +
                    "betfair-event-display"
                  }
                >
                  <div>{event.name}</div>
                  <Button
                    className="subscribe-button"
                    onClick={() =>
                      this.subscribeToEvent(subscribedEvent ? null : event.id)
                    }
                    variant="contained"
                  >
                    {subscribedEvent ? "unsubscribe" : "subscribe"}
                  </Button>
                </div>
              );
            })}
        </div>

        {successfullySubscribed && (
          <div>
            <div className="separator-title">Updates:</div>
            {this.state.lastStatus && (
              <div style={statusBackgroundStyle} className="status-display">
                {betfairStatusNames[this.state.lastStatus]}
              </div>
            )}
            {this.mapMessagesToComponents()}
            {this.state.lastHeartBeat && (
              <div className="italic">
                <hr />
                Last updated:{" "}
                <TimeAgo datetime={new Date(this.state.lastHeartBeat)} />
              </div>
            )}
          </div>
        )}

        <BetfairBallSpeaker
          incidentType={this.state.latestIncidentType}
          incidentValue={this.state.latestIncidentValue}
          incidentOver={this.state.latestIncidentOver}
          status={this.state.latestStatus}
          enabled={this.state.speechEnabled}
        />
      </div>
    );
  }
}
