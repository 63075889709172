import { Component } from "react";

export interface BowlerSpecialityDisplay {
  name: string;
  color: string;
}

interface Props {
  name: string;
  color: string;
  width: number;
  onSliderSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  noSliderButton: boolean;
  disabled: boolean;
  desiredBalls: number;
}

export class BowlerSpecialityChartSection extends Component<Props> {
  public render() {
    const desiredInPhase = (this.props.width * this.props.desiredBalls) / 100;
    return (
      <div
        className="bowler-speciality-chart-section"
        style={{
          background: this.props.color,
          width: this.props.width + "%",
        }}
      >
        <span className="bowler-speciality-chart-section-text">
          {this.props.name}
        </span>
        <span className="bowler-speciality-chart-section-text">
          {desiredInPhase.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>

        {!this.props.noSliderButton && (
          <div
            onPointerDown={
              this.props.disabled ? () => {} : this.props.onSliderSelect
            }
            className={
              this.props.disabled
                ? `bowler-speciality-slider-button-disabled`
                : `bowler-speciality-slider-button`
            }
          >
            <img
              src={"https://assets.codepen.io/576444/slider-arrows.svg"}
              alt="slider"
              width="10px"
            />
          </div>
        )}
      </div>
    );
  }
}
