export enum BowlType {
  RIGHT_ARM_PACE = "RIGHT_ARM_PACE",
  RIGHT_ARM_SPIN = "RIGHT_ARM_SPIN",
  LEFT_ARM_PACE = "LEFT_ARM_PACE",
  LEFT_ARM_SPIN = "LEFT_ARM_SPIN",
}

export const bowlTypeNames: Record<BowlType, string> = {
  RIGHT_ARM_PACE: "Right-Arm Pace",
  RIGHT_ARM_SPIN: "Right-Arm Spin",
  LEFT_ARM_PACE: "Left-Arm Pace",
  LEFT_ARM_SPIN: "Left-Arm Spin",
};
