import {
  BowlerSpeciality,
  BowlerSpecialityPhase,
} from "../enums/bowler-speciality";
import { UUID } from "../uuid";
import { Entity } from "./entity";
import { InningsConfiguration } from "./innings-configuration";

export class MatchFormat implements Entity {
  public entityId: UUID;
  public matchFormatId: UUID;
  public parentId: UUID;
  public createdBy: UUID;
  public createdAt: number;

  public name: string;
  public description: string;
  public noBallFreeHit: boolean;
  public noBallPenalty: number;
  public widePenalty: number;
  public oversBeforeSwitch: number;
  public maxConsecutiveOvers: number;
  public inningsConfiguration: InningsConfiguration[];
  public overConfiguration: number[][];
  public bowlerSpecialityPhases: BowlerSpecialityPhase[][];

  constructor(
    entityId: UUID,
    matchFormatId: UUID,
    parentId: UUID,
    createdBy: UUID,
    createdAt: number,
    name: string,
    description: string,
    noBallFreeHit: boolean,
    noBallPenalty: number,
    widePenalty: number,
    oversBeforeSwitch: number,
    maxConsecutiveOvers: number,
    inningsConfiguration: InningsConfiguration[],
    overConfiguration: number[][],
    bowlerSpecialityPhases: BowlerSpecialityPhase[][]
  ) {
    this.entityId = entityId;
    this.matchFormatId = matchFormatId;
    this.parentId = parentId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.name = name;
    this.description = description;
    this.noBallFreeHit = noBallFreeHit;
    this.noBallPenalty = noBallPenalty;
    this.widePenalty = widePenalty;
    this.oversBeforeSwitch = oversBeforeSwitch;
    this.maxConsecutiveOvers = maxConsecutiveOvers;
    this.inningsConfiguration = inningsConfiguration;
    this.overConfiguration = overConfiguration;
    this.bowlerSpecialityPhases = bowlerSpecialityPhases;
  }

  public static clone(matchFormat: MatchFormat): MatchFormat {
    const newMatchFormat = new MatchFormat(
      UUID.fromString(
        !!matchFormat.entityId ? matchFormat.entityId.value : null
      ),
      UUID.fromString(
        !!matchFormat.matchFormatId ? matchFormat.matchFormatId.value : null
      ),
      UUID.fromString(
        !!matchFormat.parentId ? matchFormat.parentId.value : null
      ),
      UUID.fromString(
        !!matchFormat.createdBy ? matchFormat.createdBy.value : null
      ),
      matchFormat.createdAt,
      matchFormat.name,
      matchFormat.description,
      matchFormat.noBallFreeHit,
      matchFormat.noBallPenalty,
      matchFormat.widePenalty,
      matchFormat.oversBeforeSwitch,
      matchFormat.maxConsecutiveOvers,
      MatchFormat.cloneInningsConfiguration(matchFormat.inningsConfiguration),
      MatchFormat.cloneOverConfiguration(matchFormat.overConfiguration),
      MatchFormat.cloneBowlerSpecialityPhases(
        matchFormat.bowlerSpecialityPhases
      )
    );
    return newMatchFormat;
  }

  public static deserializeList(json: any) {
    const formats: MatchFormat[] = [];
    json.forEach((element) => {
      formats.push(this.deserializeOne(element));
    });
    return formats;
  }

  public static deserializeOne(responseJSON: any): MatchFormat {
    return new MatchFormat(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.matchFormatId),
      UUID.fromString(responseJSON.parentId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      responseJSON.name,
      responseJSON.description,
      responseJSON.noBallFreeHit,
      responseJSON.noBallPenalty,
      responseJSON.widePenalty,
      responseJSON.oversBeforeSwitch,
      responseJSON.maxConsecutiveOvers,
      MatchFormat.deserializeInningsConfiguration(
        responseJSON.inningsConfiguration
      ),
      responseJSON.overConfiguration,
      MatchFormat.deserialiseBowlerSpecialityPhases(
        responseJSON.bowlerSpecialityPhases
      )
    );
  }

  public static deserializeInningsConfiguration(
    json: any
  ): InningsConfiguration[] {
    const result: InningsConfiguration[] = [];
    json.forEach((inningsConfig) => {
      result.push(inningsConfig as InningsConfiguration);
    });
    return result;
  }

  public static deserialiseBowlerSpecialityPhases(
    json: any
  ): BowlerSpecialityPhase[][] {
    const allSpecialities: BowlerSpecialityPhase[][] = [];
    json.forEach((array) => {
      const inningsSpecialities = [];
      array.forEach((element) => {
        inningsSpecialities.push({
          bowlerSpeciality: BowlerSpeciality[element.bowlerSpeciality],
          start: element.start,
          end: element.end,
        });
      });
      allSpecialities.push(inningsSpecialities);
    });
    return allSpecialities;
  }

  public static serialize(matchFormat: MatchFormat): any {
    return {
      entityId:
        matchFormat.entityId === null ? null : matchFormat.entityId.value,
      matchFormatId:
        matchFormat.matchFormatId === null
          ? null
          : matchFormat.matchFormatId.value,
      parentId:
        matchFormat.parentId === null ? null : matchFormat.parentId.value,
      createdBy:
        matchFormat.createdBy === null ? null : matchFormat.createdBy.value,
      createdAt: matchFormat.createdAt,
      name: matchFormat.name,
      description: matchFormat.description,
      noBallFreeHit: matchFormat.noBallFreeHit,
      noBallPenalty: matchFormat.noBallPenalty,
      widePenalty: matchFormat.widePenalty,
      oversBeforeSwitch: matchFormat.oversBeforeSwitch,
      maxConsecutiveOvers: matchFormat.maxConsecutiveOvers,
      inningsConfiguration: matchFormat.inningsConfiguration,
      overConfiguration: matchFormat.overConfiguration,
      bowlerSpecialityPhases: matchFormat.bowlerSpecialityPhases,
    };
  }

  public static cloneOverConfiguration(
    overConfiguration: number[][]
  ): number[][] {
    const result: number[][] = [];
    overConfiguration.forEach((element: number[]) => {
      result.push(new Array(...element));
    });
    return result;
  }

  public static cloneInningsConfiguration(
    inningsConfiguration: InningsConfiguration[]
  ): InningsConfiguration[] {
    const result: InningsConfiguration[] = [];
    inningsConfiguration.forEach((value: InningsConfiguration) => {
      result.push(Object.assign({}, value) as InningsConfiguration);
    });
    return result;
  }

  public static cloneBowlerSpecialityPhases(
    bowlerSpecialityPhases: BowlerSpecialityPhase[][]
  ): BowlerSpecialityPhase[][] {
    const bothInnings: BowlerSpecialityPhase[][] = [];
    bowlerSpecialityPhases.forEach((innings: BowlerSpecialityPhase[]) => {
      bothInnings.push(MatchFormat.cloneInningsBowlerSpecialityPhases(innings));
    });
    return bothInnings;
  }

  public static cloneInningsBowlerSpecialityPhases(
    inningsPhases: BowlerSpecialityPhase[]
  ) {
    const newInningsPhases = [];
    inningsPhases.forEach((phase: BowlerSpecialityPhase) => {
      newInningsPhases.push({
        bowlerSpeciality: phase.bowlerSpeciality,
        start: phase.start,
        end: phase.end,
      });
    });
    return newInningsPhases;
  }

  public static emptyWithName(name: string): MatchFormat {
    return new MatchFormat(
      null,
      UUID.randomUUID(),
      null,
      null,
      null,
      name,
      "",
      true,
      1,
      1,
      1,
      1,
      MatchFormat.buildDefaultInningsConfig(),
      MatchFormat.buildDefaultOverConfig(),
      [MatchFormat.buildDefaultT20Phases(), MatchFormat.buildDefaultT20Phases()]
    );
  }

  public static getTypeName(): string {
    return "match format";
  }

  public toString(): string {
    return this.name;
  }

  public static getTotalBalls(matchFormat: MatchFormat): number {
    let totalBalls = 0;
    matchFormat.inningsConfiguration.forEach(
      (value, index) =>
        (totalBalls =
          totalBalls +
          MatchFormat.getTotalBallsInInnings(index + 1, matchFormat))
    );
    return totalBalls;
  }

  public static hasSurge(matchFormat: MatchFormat): boolean {
    let hasSurge: boolean = false;
    matchFormat.inningsConfiguration.forEach(
      (inningsConfiguration: InningsConfiguration, index: number) => {
        hasSurge = hasSurge || inningsConfiguration.surge;
      }
    );
    return hasSurge;
  }

  public static getTotalBallsInInnings(
    innings: number,
    matchFormat: MatchFormat
  ): number {
    return this.sum(matchFormat.overConfiguration[innings - 1]);
  }

  public static buildDefaultInningsConfig(): InningsConfiguration[] {
    const defaultInningsConfig: InningsConfiguration[] = [];
    const defaultInningsConfiguration: InningsConfiguration = {
      battingTeam: 1,
      surge: false,
      surgeAvailableFromOver: null,
      surgeOvers: null,
      oversPerBowler: 4,
      powerplayOversStart: 0,
      powerplayOversEnd: 6,
      maxWickets: 10,
    };
    defaultInningsConfig.push(defaultInningsConfiguration);
    defaultInningsConfig.push({
      ...defaultInningsConfiguration,
      battingTeam: 2,
    });
    return defaultInningsConfig;
  }

  public static buildDefaultOverConfig(): number[][] {
    const innings1OverConfig: number[] = [];
    const innings2OverConfig: number[] = [];
    for (let i: number = 1; i < 21; i++) {
      innings1OverConfig.push(6);
      innings2OverConfig.push(6);
    }
    return [innings1OverConfig, innings2OverConfig];
  }

  public static buildDefaultT20Phases(): BowlerSpecialityPhase[] {
    const phases: BowlerSpecialityPhase[] = [];
    phases.push({
      bowlerSpeciality: BowlerSpeciality.SWING,
      start: 1,
      end: 3,
    });
    phases.push({
      bowlerSpeciality: BowlerSpeciality.FIRST_CHANGE,
      start: 4,
      end: 6,
    });
    phases.push({
      bowlerSpeciality: BowlerSpeciality.EARLY_MIDDLE,
      start: 7,
      end: 11,
    });
    phases.push({
      bowlerSpeciality: BowlerSpeciality.LATE_MIDDLE,
      start: 12,
      end: 16,
    });
    phases.push({
      bowlerSpeciality: BowlerSpeciality.DEATH,
      start: 17,
      end: 20,
    });

    return phases;
  }

  private static sum(values: number[]): number {
    let sum = 0;
    values.forEach((value) => (sum += value));
    return sum;
  }
}
