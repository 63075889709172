import { Component, ReactElement } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Direction } from "../../types/enums/direction";
import { UUID } from "../../types/uuid";
import { propsEqual } from "../component-utils";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

interface Props {
  items: ReactElement[];
  onMove: (
    playerId: UUID,
    direction: Direction,
    steps: number,
    onComplete: () => void
  ) => void;
}

interface State {
  items: ReactElement[];
  moving: boolean;
}

export class DraggableTable extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      moving: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount(): void {
    this.setState({ items: this.props.items });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!propsEqual(prevProps, this.props)) {
      if (!this.state.moving) {
        this.setState({ items: this.props.items });
      }
    }
  }

  private reorderDisplay(
    list: ReactElement[],
    startIndex,
    endIndex
  ): ReactElement[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  private reorderPlayers(result) {
    const start = result.source.index;
    const end = result.destination.index;
    const playerId = UUID.fromString(
      (result.draggableId as string).split("sim-stats-")[1]
    );
    const direction: Direction = start > end ? Direction.UP : Direction.DOWN;
    const steps: number = start > end ? start - end : end - start;
    this.props.onMove(playerId, direction, steps, () =>
      this.setState({ moving: false })
    );
  }

  private onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = this.reorderDisplay(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState(
      {
        items,
        moving: true,
      },
      () => this.reorderPlayers(result)
    );
  }

  render() {
    return (
      <LoadingOverlay active={this.state.moving} spinner text="Moving...">
        <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.items.map((item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          background: snapshot.isDragging
                            ? "rgba(0, 0, 0, 0.1)"
                            : "inherit",
                          ...provided.draggableProps.style,
                        }}
                      >
                        {item}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </LoadingOverlay>
    );
  }
}
