import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component, ReactNode } from "react";

import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../types/enums/push-bracket";
import { format } from "../simulator-page/simulator-utils";

interface Props {
  pushBrackets: Map<MatchType, [PushBracket, number][]>;
}

export class PushBracketDisplay extends Component<Props> {
  private buildTableRows(): ReactNode[] {
    const rows: ReactNode[] = [];
    this.props.pushBrackets.forEach(
      (brackets: [PushBracket, number][], matchType: MatchType) => {
        const cells: ReactNode[] = [];
        cells.push(
          <TableCell key={`push-bracket-table-cell-header-${matchType}`}>
            {matchTypeNames[matchType]}
          </TableCell>
        );
        brackets.forEach(([bracket, value]) => {
          cells.push(
            <TableCell
              key={`push-bracket-table-cell-value-${matchType}-${bracket}`}
            >
              {format(value)}
            </TableCell>
          );
        });
        const row = (
          <TableRow key={`push-bracket-table-row-${rows.length}`}>
            {cells}
          </TableRow>
        );
        rows.push(row);
      }
    );
    return rows;
  }

  public render() {
    const tableRows = this.buildTableRows();
    return (
      <div>
        Push Brackets
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Match Type</TableCell>
              <TableCell>{humanReadablePushBrackets.BLOCKING}</TableCell>
              <TableCell>{humanReadablePushBrackets.DEFENSIVE}</TableCell>
              <TableCell>{humanReadablePushBrackets.NORMAL}</TableCell>
              <TableCell>{humanReadablePushBrackets.PUSHING}</TableCell>
              <TableCell>{humanReadablePushBrackets.FULL_PUSH}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </div>
    );
  }
}
