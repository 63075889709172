import { Box, Dialog, DialogTitle } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { getTitleColour } from "../../match-page/game-state-display/tsbc";
import { DialogFooter } from "./dialog-footer";

interface Props {
  open: boolean;
  label: string;
  onProceed: Function;
  onCancel: () => void;
  invalid: boolean;
  disabled: boolean;
  cancelText?: string;
  proceedText: string;
  colour?: string;
  backgroundColour?: string;
  classes?: string;
  disabledText?: string;
  invalidText?: string;
  loading?: boolean;
  showCancelButton?: boolean;
  autofocus?: boolean;
  element?: ReactElement | null;
  fullScreen?: boolean;
  children?: ReactNode;
}

export function CreationDialog({
  open,
  label,
  onProceed,
  onCancel,
  invalid,
  disabled,
  cancelText = "BACK",
  proceedText,
  colour = "#651137",
  backgroundColour = "#f8f8ff",
  classes = "dialog-content",
  disabledText = "Cannot edit scraped object",
  invalidText = "",
  loading = false,
  showCancelButton = true,
  autofocus = true,
  element = null,
  fullScreen = false,
  children,
}: Props): React.JSX.Element {
  return (
    <Dialog
      open={open}
      maxWidth={"xl"}
      fullScreen={fullScreen}
      PaperComponent={Box}
    >
      <DialogTitle style={{ backgroundColor: colour }}>
        <div className="dialog-title">
          <div
            style={{
              color: colour === null ? "#000000" : getTitleColour(colour),
            }}
          >
            {" "}
            {label}{" "}
          </div>
          <div>{element}</div>
        </div>
      </DialogTitle>

      <div className={classes} style={{ backgroundColor: backgroundColour }}>
        {children}
      </div>

      <DialogFooter
        loading={loading}
        invalid={invalid}
        disabled={disabled}
        onCancel={() => onCancel()}
        onProceed={() => onProceed()}
        cancelText={cancelText}
        proceedText={proceedText}
        colour={colour}
        disabledText={disabledText}
        invalidText={invalidText}
        showCancelButton={showCancelButton}
        autofocus={autofocus}
      />
    </Dialog>
  );
}
