import { UUID } from "../uuid";
import { Entity } from "./entity";
import { BatType } from "../enums/bat-type";
import { BowlType } from "../enums/bowl-type";
import { Gender } from "../enums/gender";
import { PlayingRole } from "../enums/playing-role";
import { LongBowlType } from "../enums/long-bowl-type";

export class Player implements Entity {
  public entityId: UUID;
  public playerId: UUID;
  public longName: string;
  public shortName: string;
  public espnNum: number;
  public dob: number;
  public gender: Gender;
  public headshot: UUID;
  public batType: BatType;
  public bowlType: BowlType;
  public longBowlTypes: LongBowlType[];
  public playingRoles: PlayingRole[];
  static playerId: any;
  public createdBy: UUID;
  public createdAt: number;
  public nationalTeam: UUID;

  constructor(
    entityId: UUID,
    playerId: UUID,
    longName: string,
    shortName: string,
    espnNum: number,
    dob: number,
    gender: Gender,
    headshot: UUID,
    batType: BatType,
    bowlType: BowlType,
    longBowlTypes: LongBowlType[],
    playingRoles: PlayingRole[],
    createdBy: UUID,
    createdAt: number,
    nationalTeam: UUID
  ) {
    this.entityId = entityId;
    this.playerId = playerId;
    this.longName = longName;
    this.shortName = shortName;
    this.espnNum = espnNum;
    this.dob = dob;
    this.gender = gender;
    this.headshot = headshot;
    this.batType = batType;
    this.bowlType = bowlType;
    this.longBowlTypes = longBowlTypes;
    this.playingRoles = playingRoles;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.nationalTeam = nationalTeam;
  }

  public toString(): string {
    return this.longName;
  }

  public static deserializeList(json: any) {
    const players: Player[] = [];
    json.forEach((element) => {
      players.push(this.deserializeOne(element));
    });
    return players;
  }

  public static deserializeOne(responseJSON: any): Player {
    return new Player(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.playerId),
      responseJSON.longName,
      responseJSON.shortName,
      responseJSON.espnNum,
      responseJSON.dob,
      responseJSON.gender,
      UUID.fromString(responseJSON.headshot),
      responseJSON.batType,
      responseJSON.bowlType,
      responseJSON.longBowlTypes,
      responseJSON.playingRoles,
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      UUID.fromString(responseJSON.nationalTeam)
    );
  }

  public static emptyWithName(name: string): Player {
    return new Player(
      null,
      null,
      name,
      name,
      null,
      0,
      Gender.M,
      null,
      null,
      null,
      [],
      [],
      null,
      null,
      null
    );
  }

  public static getTypeName(): string {
    return "player";
  }

  public static serialize(player: Player): any {
    return {
      entityId: player.entityId === null ? null : player.entityId.value,
      playerId: player.playerId === null ? null : player.playerId.value,
      longName: player.longName,
      shortName: player.shortName,
      espnNum: player.espnNum,
      dob: player.dob,
      gender: player.gender,
      headshot: player.headshot === null ? null : player.headshot.value,
      batType: player.batType,
      bowlType: player.bowlType,
      longBowlTypes: player.longBowlTypes,
      playingRoles: player.playingRoles,
      createdBy: player.createdBy === null ? null : player.createdBy.value,
      createdAt: player.createdAt,
      nationalTeam: player.nationalTeam,
    };
  }
}
