import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";
import { services } from "../types/services";
import { SnackBarMessage } from "../types/snack-bar-message";

interface State {
  showSnackBar: boolean;
  snackBarMessage: SnackBarMessage;
}

export class SnackBarManager extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private showSnackBar: boolean = false;
  private snackBarMessage: SnackBarMessage = null;

  componentDidMount(): void {
    this.subscriptions.push(
      services.snackbarSubject.subscribe((snackBarMessage: SnackBarMessage) => {
        this.snackBarMessage = snackBarMessage;
        this.showSnackBar = true;
        this.setState({
          showSnackBar: this.showSnackBar,
          snackBarMessage: this.snackBarMessage,
        });
        services.loggingService.logMessage(
          this.snackBarMessage.color + ": " + this.snackBarMessage.message
        );
      })
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private onClose() {
    this.snackBarMessage = { message: "", color: "info" };
    this.showSnackBar = false;
    this.setState({
      showSnackBar: this.showSnackBar,
      snackBarMessage: this.snackBarMessage,
    });
  }

  render() {
    return (
      <div>
        {this.showSnackBar && (
          <Snackbar
            open={this.showSnackBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={() => this.onClose()}
          >
            <Alert severity={this.snackBarMessage.color}>
              {this.snackBarMessage.message}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  }
}
