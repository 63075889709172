export interface StoredGameStateOdds {
  matchId: string;
  gameStateId: string;
  team1Id: string;
  team2Id: string;
  simulatorMatchOdds: MatchOdd[];
  simulatorRunsLineOdds: Map<number, RunsLineOdd[]>;
  betfairMatchOdds: MatchOdd[];
  betfairRunsLineOdds: Map<number, RunsLineOdd[]>;
  innings: number;
  overs: string;
  score: string;

  nextGameStateInnings: number;
  nextGameStateOvers: string;
  nextGameStateScore: string;
}

export interface BetfairPrice {
  backPrice: number;
  layPrice: number;
  backSize: number;
  laySize: number;
  midPoint: number;
}

export interface RunsLineOdd {
  overs: number;
  price: number;
  numberOfSimulations: number;
  marketData: BetfairPrice;
}

export interface MatchOdd {
  teamId: string;
  price: number;
  numberOfSimulations: number;
  marketData: BetfairPrice;
}

export function deserializeGroupedStoredOdds(json: any): {
  [matchId: string]: StoredGameStateOdds[];
} {
  const groupedOdds: { [matchId: string]: StoredGameStateOdds[] } = {};

  Object.keys(json).forEach((matchId) => {
    groupedOdds[matchId] = json[matchId].map((element: any) => ({
      matchId: element.matchId,
      gameStateId: element.gameStateId,
      team1Id: element.team1Id,
      team2Id: element.team2Id,
      simulatorMatchOdds: element.simulatorMatchOdds,
      simulatorRunsLineOdds: deserializeRunsLineOddsMap(
        element.simulatorRunsLineOdds
      ),
      betfairMatchOdds: element.betfairMatchOdds,
      betfairRunsLineOdds: deserializeRunsLineOddsMap(
        element.betfairRunsLineOdds
      ),
      innings: element.innings,
      overs: element.overs,
      score: element.score,
      nextGameStateId: element.nextGameStateId,
      nextGameStateInnings: element.nextGameStateInnings,
      nextGameStateOvers: element.nextGameStateOvers,
      nextGameStateScore: element.nextGameStateScore,
    }));
  });

  return groupedOdds;
}

function deserializeRunsLineOddsMap(json: any): Map<number, RunsLineOdd[]> {
  if (!json) {
    return null;
  }

  const result: Map<number, RunsLineOdd[]> = new Map();

  Object.keys(json).forEach((key: string) => {
    result.set(Number(key), json[key]);
  });

  return result;
}
