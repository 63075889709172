import { Component, createRef } from "react";
import { MatchFormat } from "../../../types/entities/match-format";
import { BowlerSpecialityPhase } from "../../../types/enums/bowler-speciality";
import { propsEqual } from "../../component-utils";
import { MatchFormatPhaseSection } from "./match-format-phase-section";

interface Props {
  bowlerSpecialities: BowlerSpecialityPhase[];
  onEdit: (bowlerSpecialities: BowlerSpecialityPhase[]) => void;
  matchFormat: MatchFormat;
  innings: number;
  disabled: boolean;
}

interface State {
  bowlerSpecialities: BowlerSpecialityPhase[];
}

export class MatchFormatPhaseSelectionComponent extends Component<
  Props,
  State
> {
  private sliderRef;
  static defaultProps: {
    disabled: false;
  };

  constructor(props) {
    super(props);

    const bowlerSpecialities: BowlerSpecialityPhase[] = [];
    props.bowlerSpecialities.forEach((phase) =>
      bowlerSpecialities.push({
        bowlerSpeciality: phase.bowlerSpeciality,
        start: phase.start,
        end: phase.end,
      })
    );

    this.state = {
      bowlerSpecialities,
    };
    this.sliderRef = createRef();
  }

  componentDidMount(): void {
    this.updateWidths();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!propsEqual(this.props, prevProps)) {
      this.updateWidths();
    }
  }

  private updateWidths() {
    const bowlerSpecialities: BowlerSpecialityPhase[] = [];
    this.props.bowlerSpecialities.forEach((phase) =>
      bowlerSpecialities.push({
        bowlerSpeciality: phase.bowlerSpeciality,
        start: phase.start,
        end: phase.end,
      })
    );
    this.setState({ bowlerSpecialities });
  }

  private getPercentage(containerWidth: number, distanceMoved: number): number {
    return 100 * (distanceMoved / containerWidth);
  }

  private limitNumberWithinRange(
    value: number,
    min: number,
    max: number
  ): number {
    return Math.min(Math.max(value, min), max);
  }

  private getWidth(index: number): number {
    const start = this.state.bowlerSpecialities[index].start;
    const end = this.state.bowlerSpecialities[index].end;
    const totalOvers =
      this.props.matchFormat.overConfiguration[this.props.innings - 1].length;

    return 100 * ((1 + end - start) / totalOvers);
  }

  private calculateEndOverFromWidth(
    start: number,
    startOfNext: number,
    width: number
  ): number {
    const totalOvers =
      this.props.matchFormat.overConfiguration[this.props.innings - 1].length;

    const newEnd = (width / 100) * totalOvers + start - 1;
    return Math.max(
      1,
      Math.min(
        startOfNext - 1,
        Math.max(
          start,
          newEnd - Math.floor(newEnd) > 0.5
            ? Math.ceil(newEnd)
            : Math.floor(newEnd)
        )
      )
    );
  }

  private calculateStartOverFromPrevious(previousEnd: number): number {
    const totalOvers =
      this.props.matchFormat.overConfiguration[this.props.innings - 1].length;

    return totalOvers === previousEnd ? previousEnd : previousEnd + 1;
  }

  public render() {
    return (
      <div ref={this.sliderRef} className="bowler-speciality-chart-component">
        {this.state.bowlerSpecialities.map((phase, index) => (
          <MatchFormatPhaseSection
            width={this.getWidth(index)}
            key={index}
            phase={phase}
            noSliderButton={index === this.state.bowlerSpecialities.length - 1}
            disabled={this.props.disabled}
            onSliderSelect={(e) => {
              e.preventDefault();
              let startDragX = e.clientX;
              const sliderWidth = this.sliderRef.current.offsetWidth;
              document.body.style.cursor = "ew-resize";

              const resize = (e: MouseEvent & TouchEvent) => {
                const totalOvers =
                  this.props.matchFormat.overConfiguration[
                    this.props.innings - 1
                  ].length;
                const tickSize = 100 / totalOvers;
                const endDragX = e.touches ? e.touches[0].clientX : e.clientX;

                const numberOfTicks = Math.floor(endDragX / tickSize);
                const remainder = endDragX - numberOfTicks * tickSize;
                const snappedDragX =
                  numberOfTicks * tickSize +
                  (remainder > tickSize / 2 ? tickSize : 0);

                const distanceMoved = snappedDragX - startDragX;
                const percentageMoved = this.getPercentage(
                  sliderWidth,
                  distanceMoved
                );
                const bowlerSpecialities: BowlerSpecialityPhase[] =
                  this.state.bowlerSpecialities;

                const prevPercentage = this.getWidth(index);
                const newPercentage = prevPercentage + percentageMoved;
                const maxPercent =
                  this.getWidth(index) + this.getWidth(index + 1) - 1;

                const currentSectionWidth = this.limitNumberWithinRange(
                  newPercentage,
                  1,
                  maxPercent
                );
                const startOfNext =
                  index < this.state.bowlerSpecialities.length - 2
                    ? bowlerSpecialities[index + 2].start - 1
                    : totalOvers;

                const newEnd = this.calculateEndOverFromWidth(
                  bowlerSpecialities[index].start,
                  startOfNext,
                  currentSectionWidth
                );
                if (newEnd !== bowlerSpecialities[index].end) {
                  startDragX = snappedDragX;
                  bowlerSpecialities[index].end = newEnd;
                  if (bowlerSpecialities[index].start > newEnd) {
                    bowlerSpecialities[index].start = newEnd;
                  }
                  bowlerSpecialities[index + 1].start =
                    this.calculateStartOverFromPrevious(
                      bowlerSpecialities[index].end
                    );
                }

                this.setState({ bowlerSpecialities });
              };

              const handleEventUp = (e: Event) => {
                e.preventDefault();
                document.body.style.cursor = "initial";
                window.removeEventListener("pointermove", resize);
                window.removeEventListener("touchmove", resize);
                window.removeEventListener("touchend", handleEventUp);
                window.removeEventListener("pointerup", handleEventUp);
                this.props.onEdit(this.state.bowlerSpecialities);
              };

              window.addEventListener("pointermove", resize);
              window.addEventListener("touchmove", resize);
              window.addEventListener("touchend", handleEventUp);
              window.addEventListener("pointerup", handleEventUp);
            }}
          />
        ))}
      </div>
    );
  }
}
