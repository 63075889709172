import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component } from "react";
import { Bar } from "react-chartjs-2";
import { GameState } from "../../types/entities/game-state"; /*  */
import { Team } from "../../types/entities/team";
import { CalculatedStatisticType } from "../../types/enums/statistic-type";
import { SimulationResult } from "../../types/simulator/simulation-result";
import {
  buildChartOptions,
  compareValues,
  comparisonCellStyle,
} from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { format, getTeamName } from "./simulator-utils";

const chartOptions = buildChartOptions("bottom");

interface Props {
  gameState: GameState;
  latestResult: SimulationResult;
  comparedResults: SimulationResult;
  comparedUserName: string;
  team1: Team;
  team2: Team;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface TableValues {
  name: string;
  surgeTakenList: Map<number, number>;
  meanScore: number;
  stdDevScore: number;
  meanWickets: number;
  stdDevWickets: number;
  meanBalls: number;
  stdDevBalls: number;
  meanTaken: number;
  stdDevTaken: number;
  medianScore: number;
  medianWickets: number;
  medianBalls: number;
  medianTaken: number;
}

export class SimulatorSurgeStatsDisplay extends Component<Props, {}> {
  private getValues(
    team: number,
    simulationResult: SimulationResult
  ): TableValues {
    const name: string = getTeamName(
      team,
      simulationResult,
      this.props.team1,
      this.props.team2
    );
    if (!!simulationResult) {
      const medianListRuns = simulationResult.inningsMedianTotals.get(
        team === 1
          ? CalculatedStatisticType.TEAM_1_SURGE_RUNS
          : CalculatedStatisticType.TEAM_2_SURGE_RUNS
      );
      const medianListWickets = simulationResult.inningsMedianTotals.get(
        team === 1
          ? CalculatedStatisticType.TEAM_1_SURGE_WICKETS
          : CalculatedStatisticType.TEAM_2_SURGE_WICKETS
      );
      const medianListBalls = simulationResult.inningsMedianTotals.get(
        team === 1
          ? CalculatedStatisticType.TEAM_1_SURGE_BALLS
          : CalculatedStatisticType.TEAM_2_SURGE_BALLS
      );
      const medianListTaken = simulationResult.inningsMedianTotals.get(
        team === 1
          ? CalculatedStatisticType.TEAM_1_SURGE_TAKEN
          : CalculatedStatisticType.TEAM_2_SURGE_TAKEN
      );

      const meanAndStdDevRuns = this.getMeanAndStdDev(medianListRuns);
      const meanAndStdDevWickets = this.getMeanAndStdDev(medianListWickets);
      const meanAndStdDevBalls = this.getMeanAndStdDev(medianListBalls);
      const meanAndStdDevTaken = this.getMeanAndStdDev(medianListTaken);

      return {
        name,
        surgeTakenList: medianListTaken,
        meanScore: meanAndStdDevRuns[0],
        stdDevScore: meanAndStdDevRuns[1],
        meanWickets: meanAndStdDevWickets[0],
        stdDevWickets: meanAndStdDevWickets[1],
        meanBalls: meanAndStdDevBalls[0],
        stdDevBalls: meanAndStdDevBalls[1],
        meanTaken: meanAndStdDevTaken[0],
        stdDevTaken: meanAndStdDevTaken[1],
        medianScore: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_SURGE_RUNS
            : CalculatedStatisticType.TEAM_2_SURGE_RUNS
        ),
        medianWickets: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_SURGE_WICKETS
            : CalculatedStatisticType.TEAM_2_SURGE_WICKETS
        ),
        medianBalls: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_SURGE_BALLS
            : CalculatedStatisticType.TEAM_2_SURGE_BALLS
        ),
        medianTaken: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_SURGE_TAKEN
            : CalculatedStatisticType.TEAM_2_SURGE_TAKEN
        ),
      };
    } else {
      return {
        name,
        surgeTakenList: null,
        meanScore: null,
        stdDevScore: null,
        meanWickets: null,
        stdDevWickets: null,
        meanBalls: null,
        stdDevBalls: null,
        meanTaken: null,
        stdDevTaken: null,
        medianScore: null,
        medianWickets: null,
        medianBalls: null,
        medianTaken: null,
      };
    }
  }

  private getMeanAndStdDev(medianList: Map<number, number>): number[] {
    const values: number[] = [];
    let sum = 0;
    let n = 0;

    medianList?.forEach((value, key) => {
      for (let i = 0; i < value; i++) {
        values.push(key);
        sum += key;
        n++;
      }
    });

    const mean = sum / n;

    let sigmaDiffSquared = 0;
    values.forEach((value) => {
      sigmaDiffSquared += Math.pow(value - mean, 2);
    });
    const stdDev = Math.sqrt(sigmaDiffSquared / n);

    return [mean, stdDev];
  }

  private buildTableRow(
    team: number,
    values: TableValues,
    comparedValues: TableValues
  ) {
    const style = !!comparedValues ? comparisonCellStyle : null;

    const meanScoreColour = !!comparedValues
      ? compareValues(comparedValues.meanScore, values.meanScore)
      : "black";
    const stdDevScoreColour = !!comparedValues
      ? compareValues(comparedValues.stdDevScore, values.stdDevScore)
      : "black";
    const meanWicketsColour = !!comparedValues
      ? compareValues(comparedValues.meanWickets, values.meanWickets)
      : "black";
    const stdDevWicketsColour = !!comparedValues
      ? compareValues(comparedValues.stdDevWickets, values.stdDevWickets)
      : "black";
    const meanBallsColour = !!comparedValues
      ? compareValues(comparedValues.meanBalls, values.meanBalls)
      : "black";
    const stdDevBallsColour = !!comparedValues
      ? compareValues(comparedValues.stdDevBalls, values.stdDevBalls)
      : "black";
    const meanTakenColour = !!comparedValues
      ? compareValues(comparedValues.meanTaken, values.meanTaken)
      : "black";
    const stdDevTakenColour = !!comparedValues
      ? compareValues(comparedValues.stdDevTaken, values.stdDevTaken)
      : "black";
    const medianScoreColour = !!comparedValues
      ? compareValues(comparedValues.medianScore, values.medianScore)
      : "black";
    const medianWicketsColour = !!comparedValues
      ? compareValues(comparedValues.medianWickets, values.medianWickets)
      : "black";
    const medianBallsColour = !!comparedValues
      ? compareValues(comparedValues.medianBalls, values.medianBalls)
      : "black";
    const medianTakenColour = !!comparedValues
      ? compareValues(comparedValues.medianTaken, values.medianTaken)
      : "black";

    return (
      <TableRow
        key={`team-${team}-match-odds${!!comparedValues ? "-comparison" : ""}`}
      >
        <TableCell scope="row" id={`innings-averages-table-cell`} style={style}>
          {values.name}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: meanScoreColour }}
        >
          {format(values.meanScore)}{" "}
          <div style={{ color: stdDevScoreColour }}>
            ({format(values.stdDevScore)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: meanWicketsColour }}
        >
          {format(values.meanWickets)}{" "}
          <div style={{ color: stdDevWicketsColour }}>
            ({format(values.stdDevWickets)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: meanBallsColour }}
        >
          {format(values.meanBalls)}{" "}
          <div style={{ color: stdDevBallsColour }}>
            ({format(values.stdDevBalls)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: meanTakenColour }}
        >
          {format(values.meanTaken)}{" "}
          <div style={{ color: stdDevTakenColour }}>
            ({format(values.stdDevTaken)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianScoreColour }}
        >
          {format(values.medianScore)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianWicketsColour }}
        >
          {format(values.medianWickets)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianBallsColour }}
        >
          {format(values.medianBalls)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianTakenColour }}
        >
          {format(values.medianTaken)}
        </TableCell>
      </TableRow>
    );
  }

  private buildChartData(team1Values: TableValues, team2Values: TableValues) {
    let min = Infinity;
    let max = 0;
    const datasets = [];

    team1Values.surgeTakenList?.forEach((timesTaken, over) => {
      min = over < min ? over : min;
      max = over > max ? over : max;
    });
    team2Values.surgeTakenList?.forEach((timesTaken, over) => {
      min = over < min ? over : min;
      max = over > max ? over : max;
    });

    const data1 = [];
    const data2 = [];
    const overs = [];
    for (let i = min; i <= max; i++) {
      overs.push(i);
      data1.push(
        !!team1Values.surgeTakenList.get(i)
          ? team1Values.surgeTakenList.get(i)
          : 0
      );
      data2.push(
        !!team2Values.surgeTakenList.get(i)
          ? team2Values.surgeTakenList.get(i)
          : 0
      );
    }

    datasets.push({
      yAxisID: "yAxis",
      label: team1Values.name,
      data: data1,
      hidden: false,
      backgroundColor: "red",
      borderColor: "red",
    });
    datasets.push({
      yAxisID: "yAxis",
      label: team2Values.name,
      data: data2,
      hidden: false,
      backgroundColor: "blue",
      borderColor: "blue",
    });

    return {
      labels: overs,
      datasets: datasets,
    };
  }

  public render() {
    const team1Values = this.getValues(1, this.props.latestResult);
    const team2Values = this.getValues(2, this.props.latestResult);
    const team1Row = this.buildTableRow(1, team1Values, null);
    const team2Row = this.buildTableRow(2, team2Values, null);

    const team1ComparisonValues = this.getValues(1, this.props.comparedResults);
    const team2ComparisonValues = this.getValues(2, this.props.comparedResults);
    const team1ComparisonRow = this.buildTableRow(
      1,
      team1ComparisonValues,
      team1Values
    );
    const team2ComparisonRow = this.buildTableRow(
      2,
      team2ComparisonValues,
      team2Values
    );
    const chartData = this.buildChartData(team1Values, team2Values);

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Surge Stats
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text="Simulating..."
            >
              <TableContainer>
                <Table size="small" aria-label="simulator misc stats table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-4"
                        colSpan={4}
                      >
                        Mean
                      </TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-3"
                        colSpan={4}
                      >
                        Median
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="left"
                      >
                        Team
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Wickets
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Balls
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Over
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Wickets
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Balls
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Over
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {team1Row}
                    {!!this.props.comparedResults && team1ComparisonRow}
                    {team2Row}
                    {!!this.props.comparedResults && team2ComparisonRow}
                  </TableBody>
                </Table>
              </TableContainer>

              <div>
                <Bar options={chartOptions} data={chartData} />
              </div>
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
