import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ExperienceData } from "../../../types/form/player-form-modules";

export const experienceDataComponent = (data: ExperienceData) => {
  const tableConfig = [
    {
      title: "Global",
      confidenceProperty: "globalConfidence",
    },
    {
      title: "Powerplay",
      confidenceProperty: "powerplayConfidence",
    },
    {
      title: "Non-Powerplay",
      confidenceProperty: "nonPowerplayConfidence",
    },
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Phase</TableCell>
          <TableCell align="center">Confidence</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableConfig.map((config) => (
          <TableRow key={config.title}>
            <TableCell>{config.title}</TableCell>
            <TableCell align="center">
              {data[config.confidenceProperty]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
