import { formatCurrency } from "../../components/simulator-page/simulator-utils";
import { Transaction } from "../investment/transaction";

export enum TransactionType {
  DEPOSIT_REQUEST = "DEPOSIT_REQUEST",
  WITHDRAWAL_REQUEST = "WITHDRAWAL_REQUEST",
  DEPOSIT_APPROVAL = "DEPOSIT_APPROVAL",
  WITHDRAWAL_APPROVAL = "WITHDRAWAL_APPROVAL",
  DEPOSIT_REJECTION = "DEPOSIT_REJECTION",
  WITHDRAWAL_REJECTION = "WITHDRAWAL_REJECTION",
  PROFIT_AND_LOSS = "PROFIT_AND_LOSS",
  LONG_RUNNING_BET_START = "LONG_RUNNING_BET_START",
  LONG_RUNNING_BET_END = "LONG_RUNNING_BET_END",
  END_OF_PERIOD = "END_OF_PERIOD",
}

export const transactionTypeNames: Record<TransactionType, string> = {
  DEPOSIT_REQUEST: "Deposit Request",
  WITHDRAWAL_REQUEST: "Withdrawal Request",
  DEPOSIT_APPROVAL: "Deposit Approval",
  WITHDRAWAL_APPROVAL: "Withdrawal Approval",
  DEPOSIT_REJECTION: "Deposit Rejection",
  WITHDRAWAL_REJECTION: "Withdrawal Rejection",
  PROFIT_AND_LOSS: "Profit/Loss",
  LONG_RUNNING_BET_START: "Long Running Bet Opened",
  LONG_RUNNING_BET_END: "Long Running Bet Settled",
  END_OF_PERIOD: "End of Period",
};

export function displayTransactionData(
  transaction: Transaction,
  showVolume: boolean
) {
  switch (transaction.transactionType) {
    case TransactionType.DEPOSIT_REQUEST:
    case TransactionType.WITHDRAWAL_REQUEST:
      return (
        <div>
          {formatCurrency(transaction.transactionData.amount)}, Desired Date:{" "}
          {new Date(transaction.transactionData.desiredDate).toDateString()}
        </div>
      );
    case TransactionType.DEPOSIT_APPROVAL:
    case TransactionType.WITHDRAWAL_APPROVAL:
      return (
        <div>
          Approved Transaction:{" "}
          {transaction.transactionData.requestTransactionId}
        </div>
      );
    case TransactionType.DEPOSIT_REJECTION:
    case TransactionType.WITHDRAWAL_REJECTION:
      return (
        <div>
          Rejected Transaction:{" "}
          {transaction.transactionData.requestTransactionId}
        </div>
      );
    case TransactionType.PROFIT_AND_LOSS:
      return (
        <div>
          {transaction.transactionData.profit < 0 ? "Loss: " : "Profit: "}
          {formatCurrency(transaction.transactionData.profit)}
          {showVolume
            ? `, Volume: ${formatCurrency(transaction.transactionData.volume)}`
            : ``}
        </div>
      );
    case TransactionType.LONG_RUNNING_BET_START:
      return <div></div>;
    case TransactionType.LONG_RUNNING_BET_END:
      return <div></div>;
    case TransactionType.END_OF_PERIOD:
      return <div></div>;
  }
}
