import { Component } from "react";
import { Subscription } from "rxjs";
import { KeycloakUser } from "../../services/keycloak-service";
import { PotSummary } from "../../types/investment/pot-summary";
import { Transaction } from "../../types/investment/transaction";
import { InvestorAdminRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { InvestorList } from "./investor-list";
import { PotSummaryDisplay } from "./pot-summary-display";
import { AdminTransactionInputComponent } from "./admin-transaction-input-component";
import { TransactionHistoryDisplay } from "./transaction-history-display";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../match-page/scorecard/tab-panel";

interface State {
  investors: KeycloakUser[];
  potSummary: PotSummary;
  transactions: Transaction[];
  tabNumber: number;
}

export class InvestorAdminPage extends Component<{}, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      investors: [],
      potSummary: null,
      transactions: [],
      tabNumber: 0,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.keycloakService.knownInvestorsSubject.subscribe(
        (investors: KeycloakUser[]) => this.setState({ investors })
      )
    );
    this.subscriptions.push(
      services.investmentService.potSummarySubject.subscribe(
        (potSummary: PotSummary) => this.setState({ potSummary })
      )
    );
    this.subscriptions.push(
      services.investmentService.allTransactionsSubject.subscribe(
        (transactions: Transaction[]) => this.setState({ transactions })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public render() {
    return (
      <InvestorAdminRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Investor Admin Page</div>
          </div>

          {!!this.state.potSummary && (
            <div className="investor-admin-page">
              <div className="summary-and-actions">
                <PotSummaryDisplay
                  potSummary={this.state.potSummary}
                  transactions={this.state.transactions}
                  investors={this.state.investors}
                />
                <AdminTransactionInputComponent />
              </div>

              <div className="investor-admin-tabs">
                <Box>
                  <Tabs
                    value={this.state.tabNumber}
                    onChange={(value, newValue) =>
                      this.setState({ tabNumber: newValue })
                    }
                    aria-label="investor-admin-tabs"
                  >
                    <Tab
                      label={`Transactions`}
                      key={`investor-admin-tab-0`}
                      aria-controls={`investor-admin-tab-0`}
                    />
                    <Tab
                      label={`Investors`}
                      key={`investor-admin-tab-1`}
                      aria-controls={`investor-admin-tab-1`}
                    />
                  </Tabs>
                </Box>

                <TabPanel
                  value={this.state.tabNumber}
                  index={0}
                  key={`investor-admin-tab-panel-0`}
                >
                  <TransactionHistoryDisplay
                    investors={this.state.investors}
                    allTransactions={this.state.transactions}
                    displayedTransactions={this.state.transactions}
                    canAcceptAndReject={true}
                    showInvestorName={true}
                    showProfitVolume={true}
                  />
                </TabPanel>
                <TabPanel
                  value={this.state.tabNumber}
                  index={1}
                  key={`investor-admin-tab-panel-1`}
                >
                  <InvestorList
                    investors={this.state.investors}
                    potSummary={this.state.potSummary}
                  />
                </TabPanel>
              </div>
            </div>
          )}
        </div>
      </InvestorAdminRoute>
    );
  }
}
