import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Component } from "react";
import { Match } from "../../../types/entities/match";
import { Team } from "../../../types/entities/team";
import { TossChoice, tossChoiceNames } from "../../../types/enums/toss-choice";
import { propsEqual } from "../../component-utils";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (tossWinner: Team, decision: TossChoice) => void;
  match: Match;
  team1: Team;
  team2: Team;
}

interface State {
  tossWinner: Team;
  decision: TossChoice;
}

export class TossSelector extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      tossWinner: this.props.team1,
      decision: TossChoice.BAT,
    };
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({ tossWinner: this.props.team1, decision: TossChoice.BAT });
    }
  }

  private handleTeamChange(event) {
    const selectedTeam: string = event.target.value;
    if (selectedTeam === this.props.team1.teamId.value) {
      this.setState({ tossWinner: this.props.team1 });
    } else {
      this.setState({ tossWinner: this.props.team2 });
    }
  }

  private handleChoiceChange(event) {
    this.setState({ decision: TossChoice[event.target.value] });
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Toss"}
        invalid={false}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.state.tossWinner, this.state.decision)
        }
        proceedText="OK"
        colour="#31b788"
      >
        <FormControl>
          <FormLabel>Toss Winner:</FormLabel>
          <RadioGroup
            row
            onChange={(event) => this.handleTeamChange(event)}
            name="toss-winner-group"
            value={this.state.tossWinner && this.state.tossWinner.teamId.value}
          >
            <FormControlLabel
              value={this.props.team1 && this.props.team1.teamId.value}
              control={<Radio />}
              label={this.props.team1 && this.props.team1.name}
            />
            <FormControlLabel
              value={this.props.team2 && this.props.team2.teamId.value}
              control={<Radio />}
              label={this.props.team2 && this.props.team2.name}
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Choice:</FormLabel>
          <RadioGroup
            row
            onChange={(event) => this.handleChoiceChange(event)}
            value={this.state.decision}
            name="toss-choice-group"
          >
            <FormControlLabel
              value={TossChoice.BAT}
              control={<Radio />}
              label={tossChoiceNames[TossChoice.BAT]}
            />
            <FormControlLabel
              value={TossChoice.BOWL}
              control={<Radio />}
              label={tossChoiceNames[TossChoice.BOWL]}
            />
          </RadioGroup>
        </FormControl>
      </CreationDialog>
    );
  }
}
