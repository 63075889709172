import { ReactNode } from "react";

export enum OutcomeAdjustModuleType {
  DESPERATE_RUNNING = "DESPERATE_RUNNING",
}

export class OutcomeAdjustData {
  subType: string;
  enabled: boolean;
  constructor(enabled: boolean, subType: string) {
    this.enabled = enabled;
    this.subType = subType;
  }
}

export const outcomeAdjustModuleToDataTypes: Record<
  OutcomeAdjustModuleType,
  (any) => OutcomeAdjustData
> = {
  DESPERATE_RUNNING: (json: any) =>
    new OutcomeAdjustData(json.enabled, json.subType),
};

export const outcomeAdjustModuleNames: Record<OutcomeAdjustModuleType, string> =
  {
    DESPERATE_RUNNING: "Desperate running",
  };

export const outcomeAdjustModuleTooltips: Record<
  OutcomeAdjustModuleType,
  string
> = {
  DESPERATE_RUNNING: "Desperate running has no settings",
};

export interface OutcomeAdjustModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const outcomeAdjustDataProperties: Record<
  OutcomeAdjustModuleType,
  OutcomeAdjustModuleDataProperty[]
> = {
  DESPERATE_RUNNING: [],
};

export function deserializeOutcomeAdjustModules(
  json: any
): Map<OutcomeAdjustModuleType, OutcomeAdjustData> {
  const outcomeAdjustModules: Map<OutcomeAdjustModuleType, OutcomeAdjustData> =
    new Map();
  Object.keys(OutcomeAdjustModuleType).forEach((moduleType) => {
    outcomeAdjustModules.set(
      OutcomeAdjustModuleType[moduleType],
      outcomeAdjustModuleToDataTypes[OutcomeAdjustModuleType[moduleType]](
        json[moduleType]
      )
    );
  });
  return outcomeAdjustModules;
}

export function serializeOutcomeAdjustModules(
  outcomeAdjustModules: Map<OutcomeAdjustModuleType, OutcomeAdjustData>
): any {
  const result: any = {};
  outcomeAdjustModules.forEach((data, moduleType) => {
    result[moduleType] = data;
  });
  return result;
}
