import { DismissalMethod } from "../enums/dismissal-method";
import { EventType } from "../enums/event-type";
import { StatisticType } from "../enums/statistic-type";
import { UUID } from "../uuid";
import { Entity } from "./entity";
import { GameState } from "./game-state";

export class Ball implements Entity {
  public entityId: UUID;
  public bowlId: UUID;
  public matchId: UUID;
  public createdAt: number;

  public innings: number;
  public over: string;
  public overUnique: string;
  public ballAttempt: number;

  public bowler: UUID;

  public batsman1: UUID;
  public batsman1Faced: number;
  public batsman1NotFaced: number;

  public batsman2: UUID;
  public batsman2Faced: number;
  public batsman2NotFaced: number;

  public runs: number;
  public extras: number;

  public wide: boolean;
  public noBall: boolean;
  public bye: boolean;
  public legBye: boolean;

  public dismissal: boolean;
  public dismissalBatsman: UUID;
  public dismissalFielder: UUID;
  public dismissalMethod: DismissalMethod;
  public createdBy: UUID;

  public eventId: UUID;
  public eventSequence: number;
  public eventType: EventType;

  public timestamp: number;

  constructor(
    entityId: UUID,
    bowlId: UUID,
    matchId: UUID,
    createdAt: number,

    innings: number,
    over: string,
    overUnique: string,
    ballAttempt: number,

    bowler: UUID,
    batsman1: UUID,
    batsman1Faced: number,
    batsman1NotFaced: number,
    batsman2: UUID,
    batsman2Faced: number,
    batsman2NotFaced: number,

    runs: number,
    extras: number,

    wide: boolean,
    noBall: boolean,
    bye: boolean,
    legBye: boolean,

    dismissal: boolean,
    manOut: UUID,
    fielder: UUID,
    howOut: DismissalMethod,
    createdBy: UUID,
    eventId: UUID,
    eventSequence: number,

    timestamp: number
  ) {
    this.entityId = entityId;
    this.bowlId = bowlId;
    this.matchId = matchId;
    this.createdAt = createdAt;

    this.innings = innings;
    this.over = over;
    this.overUnique = overUnique;
    this.ballAttempt = ballAttempt;

    this.bowler = bowler;
    this.batsman1 = batsman1;
    this.batsman1Faced = batsman1Faced;
    this.batsman1NotFaced = batsman1NotFaced;
    this.batsman2 = batsman2;
    this.batsman2Faced = batsman2Faced;
    this.batsman2NotFaced = batsman2NotFaced;

    this.runs = runs;
    this.extras = extras;

    this.wide = wide;
    this.noBall = noBall;
    this.bye = bye;
    this.legBye = legBye;

    this.dismissal = dismissal;
    this.dismissalBatsman = manOut;
    this.dismissalFielder = fielder;
    this.dismissalMethod = howOut;
    this.createdBy = createdBy;

    this.eventId = eventId;
    this.eventSequence = eventSequence;
    this.eventType = EventType.BOWL;

    this.timestamp = timestamp;
  }

  public static deserializeList(json: any) {
    const balls: Ball[] = [];
    json.forEach((element) => {
      balls.push(this.deserializeOne(element));
    });
    return balls;
  }

  public static deserializeOne(responseJSON: any): Ball {
    return new Ball(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.bowlId),
      UUID.fromString(responseJSON.matchId),
      responseJSON.createdAt,

      responseJSON.innings,
      responseJSON.over,
      responseJSON.overUnique,
      responseJSON.ballAttempt,

      UUID.fromString(responseJSON.bowler),
      UUID.fromString(responseJSON.batsman1),
      responseJSON.batsman1Faced,
      responseJSON.batsman1NotFaced,
      UUID.fromString(responseJSON.batsman2),
      responseJSON.batsman2Faced,
      responseJSON.batsman2NotFaced,

      responseJSON.runs,
      responseJSON.extras,

      responseJSON.wide,
      responseJSON.noBall,
      responseJSON.bye,
      responseJSON.legBye,

      responseJSON.dismissal,
      UUID.fromString(responseJSON.dismissalBatsman),
      UUID.fromString(responseJSON.dismissalFielder),
      responseJSON.dismissalMethod,
      UUID.fromString(responseJSON.createdBy),

      UUID.fromString(responseJSON.eventId),
      responseJSON.eventSequence,

      responseJSON.timestamp
    );
  }

  public static serialize(ball: Ball): any {
    return {
      entityId: ball.entityId === null ? null : ball.entityId.value,
      bowlId: ball.bowlId === null ? null : ball.bowlId.value,
      matchId: ball.matchId === null ? null : ball.matchId.value,
      createdAt: ball.createdAt,
      innings: ball.innings,
      over: ball.over,
      overUnique: ball.overUnique,
      ballAttempt: ball.ballAttempt,
      bowler: ball.bowler === null ? null : ball.bowler.value,
      batsman1: ball.batsman1 === null ? null : ball.batsman1.value,
      batsman1Faced: ball.batsman1Faced,
      batsman1NotFaced: ball.batsman1NotFaced,
      batsman2: ball.batsman2 === null ? null : ball.batsman2.value,
      batsman2Faced: ball.batsman2Faced,
      batsman2NotFaced: ball.batsman2NotFaced,

      runs: ball.runs,
      extras: ball.extras,

      wide: ball.wide,
      noBall: ball.noBall,
      bye: ball.bye,
      legBye: ball.legBye,

      dismissal: ball.dismissal,
      dismissalBatsman:
        ball.dismissalBatsman === null ? null : ball.dismissalBatsman.value,
      dismissalFielder:
        ball.dismissalFielder === null ? null : ball.dismissalFielder.value,
      dismissalMethod: ball.dismissalMethod,
      createdBy: ball.createdBy === null ? null : ball.createdBy.value,

      eventId: ball.eventId === null ? null : ball.eventId.value,
      eventSequence: ball.eventSequence,
      eventType: EventType.BOWL,

      timestamp: ball.timestamp,
    };
  }

  public static fromGameState(gameState: GameState): Ball {
    return new Ball(
      null,
      UUID.randomUUID(),
      gameState.matchId,
      Date.now(),
      gameState.innings,
      null,
      null,
      gameState.ballAttempt,

      gameState.bowler,
      gameState.batsman1,
      gameState.getPlayerIntegerStat(
        StatisticType.BATSMAN_BALLS_ON_STRIKE,
        gameState.batsman1
      ),
      gameState.getPlayerIntegerStat(
        StatisticType.BATSMAN_BALLS_OFF_STRIKE,
        gameState.batsman1
      ),
      gameState.batsman2,
      gameState.getPlayerIntegerStat(
        StatisticType.BATSMAN_BALLS_ON_STRIKE,
        gameState.batsman2
      ),
      gameState.getPlayerIntegerStat(
        StatisticType.BATSMAN_BALLS_OFF_STRIKE,
        gameState.batsman2
      ),
      0,
      0,

      false,
      false,
      false,
      false,

      false,
      null,
      null,
      null,
      null,
      null,
      null,

      Date.now()
    );
  }

  public static emptyWithName(name: string): Ball {
    return new Ball(
      null,
      UUID.randomUUID(),
      null,
      Date.now(),
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      null,
      0,
      0,
      0,
      0,

      false,
      false,
      false,
      false,

      false,
      null,
      null,
      null,
      null,
      null,
      null,

      Date.now()
    );
  }

  public static getTypeName(): string {
    return "ball";
  }

  public toString(): string {
    return `Someone to someone : ${this.runs} runs`;
  }
}
