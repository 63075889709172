import { Component } from "react";
import { KeycloakUser } from "../../services/keycloak-service";
import { Transaction } from "../../types/investment/transaction";
import { TransactionHistoryChart } from "./transaction-history-chart";
import { TransactionHistoryList } from "./transaction-history-list";

interface Props {
  investors: KeycloakUser[];
  allTransactions: Transaction[];
  displayedTransactions: Transaction[];
  canAcceptAndReject: boolean;
  showProfitVolume: boolean;
  showInvestorName: boolean;
}

interface State {
  tabNumber: number;
}

export class TransactionHistoryDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      tabNumber: 0,
    };
  }

  public render() {
    return (
      <div className="transaction-history-container">
        <TransactionHistoryList
          investors={this.props.investors}
          allTransactions={this.props.allTransactions}
          displayedTransactions={this.props.displayedTransactions}
          showInvestorName={this.props.showInvestorName}
          showProfitVolume={this.props.showProfitVolume}
          canAcceptAndReject={this.props.canAcceptAndReject}
        />
        <TransactionHistoryChart
          transactions={this.props.displayedTransactions}
        />
      </div>
    );
  }
}
