import { ReactNode } from "react";
import { Country } from "../../types/enums/country";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  heading: string;
  subHeading: string;
  label1: string;
  label2: string;
  emptyMessage: string;
  allWeights: number;
  individualWeights: Map<Country, number>;
  readableNames: Record<Country, string>;
  images: Record<Country, ReactNode>;
  unavailableKeys: Country[];
  handleAllWeightsChange: (value: number) => void;
  handleEntrySelected: (key: Country) => void;
  handleEntryChanged: (key: Country, value: number) => void;
  handleEntryRemoved: (key: Country) => void;
}

export default function CountryCriteriaSelector({
  heading,
  subHeading,
  label1,
  label2,
  emptyMessage,
  allWeights,
  individualWeights,
  readableNames,
  images,
  unavailableKeys,
  handleAllWeightsChange,
  handleEntrySelected,
  handleEntryChanged,
  handleEntryRemoved,
}: Readonly<Props>): React.ReactElement {
  return (
    <div className="">
      <div className="form-settings-subheading">{heading}</div>
      <div className="edit-default-squads-criteria-box">
        <NumberSelector
          label={label1}
          min={0}
          max={100}
          step={0.1}
          decimalPlaces={1}
          initial={allWeights}
          onValid={(newValue) => handleAllWeightsChange(newValue)}
          onInvalid={(newValue) => handleAllWeightsChange(newValue)}
        />

        <div className="individual-weights-box">
          <div className="individual-weights-selection-panel">
            {subHeading}

            <EnumSelector
              classes="dialog-content"
              enumObject={Country}
              label={label2}
              showLabel={false}
              value={null}
              images={images}
              readableValues={readableNames}
              onSelect={(value) => handleEntrySelected(value)}
              unavailable={unavailableKeys}
              disabled={unavailableKeys.length === Object.keys(Country).length}
            />
          </div>
          {unavailableKeys.map((key: Country) => (
            <div
              className="individual-weightings-map-item"
              key={`used-key-${key.toString()}`}
            >
              <div className="small-image">{images[key]}</div>
              <NumberSelector
                label={readableNames[key]}
                min={0}
                max={100}
                step={0.1}
                decimalPlaces={1}
                initial={individualWeights.get(key)}
                onValid={(newValue) => handleEntryChanged(key, newValue)}
                onInvalid={(newValue) => handleEntryChanged(key, newValue)}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TooltipIconButton
                  title="Delete"
                  icon="delete"
                  onClick={() => handleEntryRemoved(key)}
                />
              </div>
            </div>
          ))}

          {individualWeights.size === 0 && (
            <div className="weightings-empty-message italic">
              {emptyMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
