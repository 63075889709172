import { EntityAutoSelector } from "../entity-selectors/entity-auto-selector";
import { Checkbox, TextField } from "@mui/material";
import { services, showMessage } from "../../../types/services";
import { Series } from "../../../types/entities/series";
import { Season } from "../../../types/entities/season";
import { Component } from "react";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  value: Series;
  open: boolean;
  label: string;
  buttonText: string;
  onProceed: Function;
  onCancel: Function;
  isNew: boolean;
}

interface State {
  value: Series;
  season: Season;
}

export class EditSeriesComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value, season: null };
  }

  componentDidMount(): void {
    this.setState({ value: this.props.value });
    this.updateSeason();
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
      this.updateSeason();
    }
  }

  private updateSeason() {
    if (this.props.value !== null && this.props.value.seasonId !== null) {
      services.seasonService
        .getOne(this.props.value.seasonId)
        .then((season) => this.setState({ season: season }))
        .catch((reason) => {
          showMessage(`Could not get season: ${reason}`, "error");
        });
    } else {
      this.setState({ season: null });
    }
  }

  public valid(): boolean {
    return (
      this.state.value &&
      this.state.value.name !== null &&
      this.state.value.name.trim().length > 0 &&
      this.state.value.seasonId !== null &&
      this.state.value.dateDescription !== null &&
      this.state.value.dateDescription.trim().length > 0 &&
      this.state.value.international !== null
    );
  }

  private clearAndCancel() {
    this.props.onCancel(this.props.isNew);
  }

  private disabled(): boolean {
    return !this.props.isNew && this.state.value && !this.state.value.createdBy;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        onProceed={() => this.props.onProceed(this.state.value)}
        onCancel={() => this.clearAndCancel()}
        invalid={!this.valid()}
        disabled={this.disabled()}
        proceedText={this.props.buttonText}
      >
        <div className="dialog-container">
          <div className="dialog-content">
            <span>Name:</span>
            <TextField
              value={this.state.value === null ? "" : this.state.value.name}
              onChange={(event) => this.handleNameChange(event)}
              placeholder={"Series name"}
              variant="outlined"
              disabled={this.disabled()}
            />
          </div>
          <div className="dialog-content">
            <span>Date (e.g. "Nov 2021"):</span>
            <TextField
              value={
                this.state.value === null
                  ? ""
                  : this.state.value.dateDescription === null
                  ? ""
                  : this.state.value.dateDescription
              }
              onChange={(event) => this.handleDateDescriptionChange(event)}
              placeholder={"Series date"}
              variant="outlined"
              disabled={this.disabled()}
            />
          </div>
          <EntityAutoSelector
            label="Season:"
            classes="dialog-content"
            value={this.state.season}
            options={services.seasonService.getAll()}
            optionService={services.seasonService}
            onSelect={(season) => this.handleSeasonChange(season as Season)}
            canAddNew={false}
            canEdit={false}
            disabled={this.disabled()}
          />
          <div className="dialog-content-sideways">
            <div className="dialog-content-sideways-item">International:</div>
            <Checkbox
              checked={
                this.state.value === null
                  ? false
                  : this.state.value.international
              }
              onChange={(event) => this.handleInternationalChange(event)}
              disabled={this.disabled()}
            />
          </div>
        </div>
      </CreationDialog>
    );
  }

  private handleNameChange(event) {
    this.setState({ value: { ...this.state.value, name: event.target.value } });
  }

  private handleDateDescriptionChange(event) {
    this.setState({
      value: { ...this.state.value, dateDescription: event.target.value },
    });
  }

  private handleSeasonChange(entity: Season) {
    this.setState({
      value: { ...this.state.value, seasonId: entity.seasonId },
      season: entity,
    });
  }

  private handleInternationalChange(event) {
    this.setState({
      value: { ...this.state.value, international: event.target.checked },
    });
  }
}
