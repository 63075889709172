export enum EventType {
  RUNS = "RUNS",
  EXTRAS = "EXTRAS",
  PENALTY = "PENALTY",
  WIDE = "WIDE",
  NO_BALL = "NO_BALL",
  BYE = "BYE",
  LEG_BYE = "LEG_BYE",
  DISMISSAL = "DISMISSAL",
  BATSMEN_SWITCHED = "BATSMEN_SWITCHED",
  NEW_MAN_IN = "NEW_MAN_IN",
  BOWLER_SELECTED = "BOWLER_SELECTED",
  BATSMAN_SELECTED = "BATSMAN_SELECTED",
  BOWLER_BOWLING_BEHAVIOUR = "BOWLER_BOWLING_BEHAVIOUR",
  END_OF_OVER = "END_OF_OVER",
  ONE_SHORT = "ONE_SHORT",
  LEGAL_DELIVERY = "LEGAL_DELIVERY",
  RETIRED_BATSMAN = "RETIRED_BATSMAN",
  RETIRED_BATSMAN_RETURNED = "RETIRED_BATSMAN_RETURNED",
  TIMED_OUT = "TIMED_OUT",
  RETIRED_BOWLER = "RETIRED_BOWLER",
  RETIRED_BOWLER_RETURNED = "RETIRED_BOWLER_RETURNED",
  BOWL = "BOWL",
  END_OF_INNINGS_CHOICE = "END_OF_INNINGS_CHOICE",
  DECLARATION = "DECLARATION",
  DECISIONS_CALCULATOR = "DECISIONS_CALCULATOR",
  SUBSTITUTION = "SUBSTITUTION",
  FORMAT_CHANGE = "FORMAT_CHANGE",
  SUPER_OVER = "SUPER_OVER",
  MOVE_PLAYER = "MOVE_PLAYER",
  TOSS = "TOSS",
  STATS_LOADED = "STATS_LOADED",
  SURGE_TAKEN = "SURGE_TAKEN",
  REMOVE_PLAYER = "REMOVE_PLAYER",
  ADD_PLAYER = "ADD_PLAYER",
}
