import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component, Fragment, ReactElement } from "react";
import { Subscription } from "rxjs";
import { ReactComponent as GreyBallSVG } from "../../img/events/bowler-not-selected.svg";
import { ReactComponent as BallSVG } from "../../img/events/bowler-selected.svg";
import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TeamPlayer } from "../../types/entities/team-player";
import { BowlType } from "../../types/enums/bowl-type";
import { BowlerSpeciality } from "../../types/enums/bowler-speciality";
import { MatchRole } from "../../types/enums/match-role";
import { CalculatedStatisticType } from "../../types/enums/statistic-type";
import { UserPreferences } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { nanSafeWithDefault } from "../../types/util-functions";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { compareValues, noSimulationsAllowed } from "../component-utils";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { SimulatorOversDistributionDisplay } from "../simulator-page/simulator-overs-distribution-display";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { BowlerSpecialityChartComponent } from "./bowler-speciality-chart-component";
import { batchUpdate, batchUpdateAll } from "./squad-page-utils";
import { StealAllPage } from "./steal-all-page";

interface Props {
  team: Team;
  match: Match;
  teamNumber: number;
  squad: Squad;
  matchFormat: MatchFormat;
  gameState: GameState;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  comparedUserName: string;
  playerStats: Map<string, PlayerStatsWrapper>;
  open: boolean;
  onProceed: () => void;
  userPreferences: UserPreferences;
}

interface State {
  simulationLoading: boolean;
  oversDistributionCollapsed: boolean;
  comparedPlayerStats: Map<string, PlayerStatsWrapper>;
}

export class BowlerPreferenceModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];
  private tableId: string = "bowler-selector-table";

  public constructor(props) {
    super(props);
    this.state = {
      simulationLoading: false,
      oversDistributionCollapsed: false,
      comparedPlayerStats: new Map(),
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.simulationService.loadingSubject.subscribe((simulationLoading) =>
        this.setState({ simulationLoading })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.comparedUserStatsSubject.subscribe(
        (comparedPlayerStats: Map<string, PlayerStatsWrapper>) => {
          const comparedStatsMap = new Map();
          comparedPlayerStats.forEach((value, key) => {
            comparedStatsMap.set(key, value);
          });
          this.setState({ comparedPlayerStats: comparedStatsMap });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private toggleBowler(playerStats: PlayerStatsWrapper) {
    const newIsBowlerValue = !playerStats.playerStats.bowler;
    services.playerStatsService.updatePlayerStats({
      ...playerStats,
      playerStats: {
        ...playerStats.playerStats,
        bowler: newIsBowlerValue,
        desiredBalls: newIsBowlerValue
          ? playerStats.playerStats.desiredBalls
          : 0,
      },
    });
  }

  private updateDesiredBalls(
    playerStats: PlayerStatsWrapper,
    newValue: number
  ) {
    services.playerStatsService.updatePlayerStats({
      ...playerStats,
      playerStats: {
        ...playerStats.playerStats,
        desiredBalls: newValue,
      },
    });
  }

  private updateBowlerSpecialities(
    playerStats: PlayerStatsWrapper,
    bowlerSpecialityPercents: Map<BowlerSpeciality, number>
  ) {
    services.playerStatsService.updatePlayerStats({
      ...playerStats,
      playerStats: {
        ...playerStats.playerStats,
        bowlerSpecialityPercents,
      },
    });
  }

  private getTeamPlayer(player: Player) {
    return (
      !!this.props.gameState &&
      !!this.props.gameState.squads &&
      !!this.props.gameState.squads[this.props.teamNumber - 1] &&
      this.props.gameState.squads[this.props.teamNumber - 1].find(
        (tp) => tp.playerId.value === player.playerId.value
      )
    );
  }

  private getPlayerStats(teamPlayer: TeamPlayer) {
    return (
      !!this.props.playerStats &&
      !!teamPlayer &&
      this.props.playerStats.get(teamPlayer.playerId.value)
    );
  }

  private validPlayer(
    teamPlayer: TeamPlayer,
    playerStats: PlayerStatsWrapper
  ): boolean {
    return (
      !!teamPlayer &&
      !!playerStats &&
      this.props.team &&
      teamPlayer.matchRole !== MatchRole.REMOVED
    );
  }

  private getLeftHandBatNumber(
    player: Player,
    playerStats: PlayerStatsWrapper
  ): number {
    const PACE_BOWL_MINIMUM = 0.4;
    const PACE_BOWL_MAXIMUM = 0.6;

    const paceBowler =
      player.bowlType === BowlType.RIGHT_ARM_PACE ||
      player.bowlType === BowlType.LEFT_ARM_PACE;
    const min = paceBowler ? PACE_BOWL_MINIMUM : 0;
    const max = paceBowler ? PACE_BOWL_MAXIMUM : 1;
    const value =
      10 -
      10 *
        Math.max(
          min,
          Math.min(
            max,
            0.5 -
              2 *
                (playerStats.playerStats
                  .bowlingAgainstLeftHandedStrikeRateBias /
                  playerStats.playerStats.bowlingAgainstLeftHandedWicketBias -
                  playerStats.playerStats
                    .bowlingAgainstRightHandedStrikeRateBias /
                    playerStats.playerStats.bowlingAgainstRightHandedWicketBias)
          )
        );

    return parseFloat(value.toFixed(2));
  }

  private getSimulationResult(
    getComparison: boolean = false
  ): SimulationResult {
    if (getComparison) {
      return this.props.comparedResult;
    }

    return this.props.simulationResult;
  }

  private getAverageBallsBowled(
    player: Player,
    getComparison: boolean = false
  ): number {
    const result = this.getSimulationResult(getComparison);

    if (!result) {
      return -1;
    }

    const bowlingBallsStatistic: CalculatedStatisticType =
      this.props.teamNumber === 1
        ? CalculatedStatisticType.TEAM_1_BOWLER_BALLS
        : CalculatedStatisticType.TEAM_2_BOWLER_BALLS;
    const totalBowlingBalls =
      result.playerTotals.get(bowlingBallsStatistic) &&
      result.playerTotals.get(bowlingBallsStatistic).get(player.playerId.value);
    return nanSafeWithDefault(
      (totalBowlingBalls / result.numberOfSimulations).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      null
    );
  }

  private tallyDesiredBalls(): string {
    let total = 0;
    this.props.squad.players.forEach((player, order) => {
      const teamPlayer: TeamPlayer = this.getTeamPlayer(player);
      const playerStats: PlayerStatsWrapper = this.getPlayerStats(teamPlayer);
      if (this.validPlayer(teamPlayer, playerStats)) {
        total += playerStats.playerStats.desiredBalls;
      }
    });
    return total.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  private refreshSim() {
    services.simulationService.removeSimulationResults();
    services.simulationService.simulate();
  }

  private stealAllHandler(): void {
    batchUpdateAll(
      this.props.playerStats,
      this.state.comparedPlayerStats,
      PlayerStatsWrapper.getAllProperties(StealAllPage.BOWLING_ALGORITHM_MODAL),
      (updatedStats: Map<string, PlayerStatsWrapper>) => {
        services.playerStatsService.updateMultiplePlayerStats(
          updatedStats,
          this.props.match.matchId
        );
      }
    );
  }

  private onStealHandler(playerId: string): void {
    batchUpdate(
      this.props.playerStats.get(playerId),
      this.state.comparedPlayerStats.get(playerId),
      PlayerStatsWrapper.getAllProperties(StealAllPage.BOWLING_ALGORITHM_MODAL),
      (updatedStats: PlayerStatsWrapper) => {
        services.playerStatsService.updatePlayerStats({
          ...updatedStats,
          playerStats: {
            ...updatedStats.playerStats,
          },
        });
      }
    );
  }

  private renderOriginalRow(
    player: Player,
    playerStats: PlayerStatsWrapper,
    order: number
  ): ReactElement {
    const ballsBowledAvg = this.getAverageBallsBowled(player);

    return (
      <TableRow
        key={player.playerId.value}
        id={this.tableId}
        style={{
          backgroundColor: order % 2 === 0 ? "#eeeeee" : "#f8f8ff",
        }}
      >
        <TableCell id={this.tableId} className="player-name-cell">
          {player.longName}
        </TableCell>
        <TableCell id={this.tableId}>
          {this.getLeftHandBatNumber(player, playerStats)}
        </TableCell>
        <TableCell id={this.tableId}>
          <BowlerSpecialityChartComponent
            bowlerSpecialities={
              playerStats.playerStats.bowlerSpecialityPercents
            }
            onEdit={(bowlerSpecialityPercents) =>
              this.updateBowlerSpecialities(
                playerStats,
                bowlerSpecialityPercents
              )
            }
            playerStatsId={playerStats.playerStatsId}
            disabled={!playerStats.playerStats.bowler}
            desiredBalls={playerStats.playerStats.desiredBalls}
          />
        </TableCell>
        <TableCell id={this.tableId}>
          <NumberSelector
            label=""
            min={0}
            max={1000000}
            step={1}
            decimalPlaces={0}
            initial={playerStats.playerStats.desiredBalls}
            onValid={(newValue) =>
              this.updateDesiredBalls(playerStats, newValue)
            }
            onInvalid={(newValue) =>
              this.updateDesiredBalls(playerStats, newValue)
            }
          />
        </TableCell>
        <TableCell id={this.tableId}>
          <TooltipIconButton
            title={
              playerStats.playerStats.bowler ? "Block bowler" : "Unblock bowler"
            }
            onClick={() => this.toggleBowler(playerStats)}
            icon={
              playerStats.playerStats.bowler ? (
                <BallSVG className="tooltip-icon-button" />
              ) : (
                <GreyBallSVG className="tooltip-icon-button" />
              )
            }
          />
        </TableCell>
        <TableCell id={this.tableId}>
          <div>{ballsBowledAvg === -1 ? "" : ballsBowledAvg}</div>
        </TableCell>
      </TableRow>
    );
  }

  private renderComparisonRow(
    player: Player,
    comparedPlayerStats: PlayerStatsWrapper
  ): ReactElement {
    const originalAverageBallsBowled = this.getAverageBallsBowled(player);
    const averageBallsBowledCompared = this.getAverageBallsBowled(player, true);

    const averageBallsBowledComparedContent =
      averageBallsBowledCompared === -1
        ? "No simulation data"
        : averageBallsBowledCompared;

    const averageBallsBowledComparedContentColour =
      averageBallsBowledCompared === -1
        ? "white"
        : compareValues(originalAverageBallsBowled, averageBallsBowledCompared);

    return (
      <TableRow id={this.tableId} className="comparison">
        <TableCell>
          <StealStatsButton
            comparedUserName={this.props.comparedUserName}
            disabled={areStatsEqual(
              this.props.playerStats.get(player.playerId.value),
              this.state.comparedPlayerStats.get(player.playerId.value),
              PlayerStatsWrapper.getAllProperties(
                StealAllPage.BOWLING_ALGORITHM_MODAL
              ),
              "playerStats"
            )}
            tooltipMessage={getToolTipMessage(
              this.props.playerStats.get(player.playerId.value),
              this.state.comparedPlayerStats.get(player.playerId.value),
              PlayerStatsWrapper.getAllProperties(
                StealAllPage.BOWLING_ALGORITHM_MODAL
              ),
              this.props.comparedUserName,
              "playerStats"
            )}
            onClickHandler={this.onStealHandler.bind(
              this,
              player.playerId.value
            )}
          />
        </TableCell>

        <TableCell
          id={this.tableId}
          style={{
            color: compareValues(
              this.getLeftHandBatNumber(
                player,
                this.props.playerStats.get(player.playerId.value)
              ),
              this.getLeftHandBatNumber(player, comparedPlayerStats)
            ),
          }}
        >
          {this.getLeftHandBatNumber(player, comparedPlayerStats)}
        </TableCell>

        <TableCell id={this.tableId}>
          <BowlerSpecialityChartComponent
            bowlerSpecialities={
              comparedPlayerStats.playerStats.bowlerSpecialityPercents
            }
            playerStatsId={comparedPlayerStats.playerStatsId}
            disabled={true}
            desiredBalls={comparedPlayerStats.playerStats.desiredBalls}
          />
        </TableCell>

        <TableCell
          id={this.tableId}
          style={{
            color: compareValues(
              this.props.playerStats.get(player.playerId.value).playerStats
                .desiredBalls,
              comparedPlayerStats.playerStats.desiredBalls
            ),
          }}
        >
          {comparedPlayerStats.playerStats.desiredBalls.toFixed(2)}
        </TableCell>

        <TableCell id={this.tableId}>
          {comparedPlayerStats.playerStats.bowler ? (
            <BallSVG />
          ) : (
            <GreyBallSVG />
          )}
        </TableCell>

        <TableCell
          id={this.tableId}
          style={{
            color: averageBallsBowledComparedContentColour,
          }}
        >
          {averageBallsBowledComparedContent}
        </TableCell>
      </TableRow>
    );
  }

  private buildRows(): ReactElement[] {
    return [...this.props.squad.players]
      .sort((a, b) => {
        const teamPlayerA: TeamPlayer = this.getTeamPlayer(a);
        const statsA = this.getPlayerStats(teamPlayerA);
        return !!teamPlayerA && !!statsA
          ? statsA.playerStats.bowler
            ? -1
            : 1
          : -1;
      })
      .flatMap((player, order) => {
        const teamPlayer: TeamPlayer = this.getTeamPlayer(player);
        const playerStats: PlayerStatsWrapper = this.getPlayerStats(teamPlayer);

        // Adding a new player causes playerStats to be temporarily null, hack to fix that.
        if (!playerStats) {
          return null;
        }

        const originalRow = this.renderOriginalRow(player, playerStats, order);

        const comparedPlayerStats = this.state.comparedPlayerStats.get(
          player.playerId.value
        );

        const comparisonRow = comparedPlayerStats
          ? this.renderComparisonRow(player, comparedPlayerStats)
          : null;

        return (
          <Fragment key={player.playerId.value}>
            {originalRow}
            {comparisonRow}
          </Fragment>
        );
      });
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Bowler Preferences"}
        invalid={false}
        disabled={false}
        onCancel={() => this.props.onProceed()}
        onProceed={() => this.props.onProceed()}
        proceedText="OK"
        showCancelButton={false}
        colour="#535455"
        fullScreen={true}
      >
        <div
          className="compared-selector-and-steal-button"
          style={{ justifyContent: "start" }}
        >
          <ComparedUserSelector />
          {/* ComparedStats comes in as an empty map when there's no comparison */}
          {this.state.comparedPlayerStats.size > 0 && (
            <StealStatsButton
              comparedUserName={this.props.comparedUserName}
              disabled={areStatsEqual(
                this.props.playerStats,
                this.state.comparedPlayerStats,
                PlayerStatsWrapper.getAllProperties(
                  StealAllPage.BOWLING_ALGORITHM_MODAL
                ),
                "playerStats"
              )}
              tooltipMessage={getToolTipMessage(
                this.props.playerStats,
                this.state.comparedPlayerStats,
                PlayerStatsWrapper.getAllProperties(
                  StealAllPage.BOWLING_ALGORITHM_MODAL
                ),
                this.props.comparedUserName,
                "playerStats"
              )}
              onClickHandler={this.stealAllHandler.bind(this)}
            />
          )}
        </div>
        <div className="bowler-preference-buttons">
          <RunSimulatorButton
            isDisabled={noSimulationsAllowed(
              this.props.match,
              this.props.userPreferences
            )}
            onClick={() => this.refreshSim()}
            colourOverride={{ trueColour: "#aaaaaa", falseColour: "#222222" }}
          />
        </div>
        <div>
          {this.state.simulationLoading && <CircularProgress />}
          <SimulatorOversDistributionDisplay
            latestResult={this.props.simulationResult}
            comparedResults={this.props.comparedResult}
            comparedUserName={this.props.comparedUserName}
            matchFormat={this.props.matchFormat}
            gameState={this.props.gameState}
            team={this.props.team}
            squad={this.props.squad}
            collapsed={this.state.oversDistributionCollapsed}
            toggleCollapse={() =>
              this.setState({
                oversDistributionCollapsed:
                  !this.state.oversDistributionCollapsed,
              })
            }
            loading={this.state.simulationLoading}
            innings={this.props.teamNumber === 2 ? 1 : 2}
            height="50px"
            hideByDefault={true}
          />
        </div>
        <div>Total Desired Balls: {this.tallyDesiredBalls()}</div>
        <Table id={this.tableId}>
          <TableHead id={this.tableId}>
            <TableRow id={this.tableId}>
              <TableCell id={this.tableId} />
              <TableCell id={this.tableId}>RHB Value</TableCell>
              <TableCell id={this.tableId} style={{ width: "60%" }}></TableCell>
              <TableCell id={this.tableId}>Desired Balls</TableCell>
              <TableCell id={this.tableId} />
              <TableCell id={this.tableId}>Simulated Balls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id={this.tableId}>{this.buildRows()}</TableBody>
        </Table>
      </CreationDialog>
    );
  }
}
