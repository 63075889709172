import { Icon, Tooltip } from "@mui/material";
import { Component, ReactElement } from "react";
import { BetweenBallsEvent } from "../../../types/entities/between-balls-event";
import { EventType } from "../../../types/enums/event-type";
import { services } from "../../../types/services";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { ReactComponent as SubstituteSVG } from "../../../img/events/substitute.svg";
import { ReactComponent as RetiredReturnedSVG } from "../../../img/events/retired-returned.svg";
import { ReactComponent as RetiredBatsmanSVG } from "../../../img/events/retired.svg";
import { ReactComponent as BatsmanSelectedSVG } from "../../../img/events/batsman-selected.svg";
import { ReactComponent as BowlerSelectedSVG } from "../../../img/events/bowler-selected.svg";
import { ReactComponent as DeclaredSVG } from "../../../img/events/declared.svg";
import { ReactComponent as IncompleteInningsSVG } from "../../../img/events/innings-incomplete.svg";
import { ReactComponent as BatsmenSwitchedSVG } from "../../../img/events/batsmen-switched.svg";
import { ReactComponent as TimedOutSVG } from "../../../img/events/timed-out.svg";
import { ReactComponent as PenaltySVG } from "../../../img/events/penalty.svg";
import { ReactComponent as RainSVG } from "../../../img/events/rain.svg";
import { ReactComponent as SuperOverSVG } from "../../../img/events/super-over.svg";
import { ReactComponent as TiedSVG } from "../../../img/events/tied.svg";
import { ReactComponent as MoveUpSVG } from "../../../img/events/move-up.svg";
import { ReactComponent as MoveDownSVG } from "../../../img/events/move-down.svg";
import { ReactComponent as TossSVG } from "../../../img/events/toss.svg";
import { ReactComponent as StatsLoadedSVG } from "../../../img/events/stats-loaded.svg";
import { ReactComponent as SurgeTakenSVG } from "../../../img/events/surge-taken.svg";
import { ReactComponent as AddPlayerSVG } from "../../../img/events/add-player.svg";
import { ReactComponent as RemovePlayerSVG } from "../../../img/events/remove-player.svg";
import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Direction } from "../../../types/enums/direction";
import { Team } from "../../../types/entities/team";
import { InningsStatisticType } from "../../../types/enums/statistic-type";

interface Props {
  event: BetweenBallsEvent;
  overToggle: boolean;
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
}

export class BetweenBallsEventDisplay extends Component<Props> {
  public static getEventTooltip(
    squad1: Squad,
    squad2: Squad,
    team1: Team,
    team2: Team,
    matchFormat: MatchFormat,
    event: BetweenBallsEvent
  ): string {
    if (!!squad1 && squad2 && team1 && team2 && matchFormat && event) {
      const battingTeam: Squad =
        !!matchFormat &&
        matchFormat.inningsConfiguration[event.innings - 1] &&
        matchFormat.inningsConfiguration[event.innings - 1].battingTeam === 1
          ? squad1
          : squad2;
      const bowlingTeam: Squad =
        !!matchFormat &&
        matchFormat.inningsConfiguration[event.innings - 1] &&
        matchFormat.inningsConfiguration[event.innings - 1].battingTeam === 1
          ? squad2
          : squad1;
      let playerId: UUID;

      switch (event.eventType) {
        case EventType.RETIRED_BATSMAN_RETURNED:
          playerId =
            event.eventData.player === null
              ? null
              : UUID.fromString(event.eventData.player);
          return (
            services.teamPlayerService.getPlayerName(playerId, battingTeam) +
            " returned from injury"
          );
        case EventType.RETIRED_BATSMAN:
          playerId =
            event.eventData.player === null
              ? null
              : UUID.fromString(event.eventData.player);
          return (
            services.teamPlayerService.getPlayerName(playerId, battingTeam) +
            " retired hurt"
          );
        case EventType.RETIRED_BOWLER_RETURNED:
          playerId =
            event.eventData.value === null
              ? null
              : UUID.fromString(event.eventData.value);
          return (
            services.teamPlayerService.getPlayerName(playerId, bowlingTeam) +
            " returned from injury"
          );
        case EventType.RETIRED_BOWLER:
          playerId =
            event.eventData.player === null
              ? null
              : UUID.fromString(event.eventData.player);
          return (
            services.teamPlayerService.getPlayerName(playerId, bowlingTeam) +
            " retired hurt"
          );
        case EventType.BATSMAN_SELECTED:
          playerId =
            event.eventData.player === null
              ? null
              : UUID.fromString(event.eventData.player);
          return (
            services.teamPlayerService.getPlayerName(playerId, battingTeam) +
            " selected to bat"
          );
        case EventType.SUBSTITUTION:
          const playerReplacing = event.eventData.substitution.playerReplacing;
          const playerReplaced = event.eventData.substitution.playerReplaced;
          playerId =
            playerReplacing === null ? null : UUID.fromString(playerReplacing);
          const team =
            event.eventData.substitution.teamId === battingTeam.teamId.value
              ? battingTeam
              : bowlingTeam;

          if (event.eventData.substitution.isConcussionSub) {
            const replacedPlayerId =
              playerReplaced === null ? null : UUID.fromString(playerReplaced);
            return `${services.teamPlayerService.getPlayerName(playerId, team)} 
                    replaced ${services.teamPlayerService.getPlayerName(
                      replacedPlayerId,
                      team
                    )} 
                    as a concussion substitute for the ${
                      team === battingTeam ? "batting" : "bowling"
                    } team`;
          } else {
            return `${services.teamPlayerService.getPlayerName(playerId, team)}
                    came on as a substitute for the ${
                      team === battingTeam ? "batting" : "bowling"
                    } team`;
          }
        case EventType.BOWLER_SELECTED:
          playerId =
            event && event.eventData.value === null
              ? null
              : UUID.fromString(event.eventData.value);
          return (
            services.teamPlayerService.getPlayerName(playerId, bowlingTeam) +
            " selected to bowl"
          );
        case EventType.END_OF_INNINGS_CHOICE:
          return "Innings ended, not enough batsmen to continue";
        case EventType.DECLARATION:
          return "Batting team declared";
        case EventType.BATSMEN_SWITCHED:
          return "Batsmen crossed";
        case EventType.FORMAT_CHANGE:
          return "Format Change";
        case EventType.TIMED_OUT:
          playerId =
            event.eventData.player === null
              ? null
              : UUID.fromString(event.eventData.player);
          return (
            services.teamPlayerService.getPlayerName(playerId, battingTeam) +
            " timed out"
          );
        case EventType.PENALTY:
          return (
            event.eventData.value +
            " penalty runs awarded to innings " +
            event.eventData.innings
          );
        case EventType.SUPER_OVER:
          return event.eventData.superOverTaken
            ? "Super over taken"
            : "Match drawn";
        case EventType.MOVE_PLAYER:
          playerId = !!event.eventData.playerId
            ? UUID.fromString(event.eventData.playerId)
            : null;
          const squad =
            team1 && event.eventData.teamId === team1.teamId.value
              ? squad1
              : squad2;
          const name = services.teamPlayerService.getPlayerName(
            playerId,
            squad
          );
          const direction =
            event.eventData.direction === Direction.UP ? "up" : "down";
          return `${name} moved ${direction} the order`;
        case EventType.TOSS:
          const teamId = event.eventData.tossWinningTeamId;
          const decision = event.eventData.decision;
          return `${this.getTeamName(
            teamId,
            team1,
            team2
          )} won the toss and elected to ${
            decision === "BAT" ? "bat" : "bowl"
          }`;
        case EventType.STATS_LOADED:
          return "Stats loaded";
        case EventType.SURGE_TAKEN:
          return "Surge taken";
        case EventType.ADD_PLAYER:
          const addedTeamPlayer = event.eventData.teamPlayer;
          playerId = !!addedTeamPlayer.playerId
            ? UUID.fromString(addedTeamPlayer.playerId)
            : null;
          const nameInSquad1 = services.teamPlayerService.getPlayerName(
            playerId,
            squad1
          );
          const nameInSquad2 = services.teamPlayerService.getPlayerName(
            playerId,
            squad2
          );
          return `${
            nameInSquad1 === "Unknown" ? nameInSquad2 : nameInSquad1
          } added to ${BetweenBallsEventDisplay.getTeamName(
            addedTeamPlayer.teamId,
            team1,
            team2
          )}`;
        case EventType.REMOVE_PLAYER:
          playerId = !!event.eventData.playerId
            ? UUID.fromString(event.eventData.playerId)
            : null;
          const removedFromTeam: UUID = !!event.eventData.teamId
            ? UUID.fromString(event.eventData.teamId)
            : null;
          const nameInTeam1: string = services.teamPlayerService.getPlayerName(
            playerId,
            squad1
          );
          const nameInTeam2: string = services.teamPlayerService.getPlayerName(
            playerId,
            squad2
          );
          return `${
            nameInTeam1 !== "Unknown" ? nameInTeam1 : nameInTeam2
          } removed from ${
            !!removedFromTeam && removedFromTeam.value === team1.teamId.value
              ? team1.name
              : team2.name
          }`;
        default:
          return "Unknown Event";
      }
    } else {
      return "Loading..";
    }
  }

  private getEventIcon(): ReactElement {
    switch (this.props.event.eventType) {
      case EventType.RETIRED_BATSMAN_RETURNED:
      case EventType.RETIRED_BOWLER_RETURNED:
        return <RetiredReturnedSVG />;
      case EventType.RETIRED_BATSMAN:
      case EventType.RETIRED_BOWLER:
        return <RetiredBatsmanSVG />;
      case EventType.BATSMAN_SELECTED:
        return <BatsmanSelectedSVG />;
      case EventType.SUBSTITUTION:
        return <SubstituteSVG />;
      case EventType.BOWLER_SELECTED:
        return <BowlerSelectedSVG />;
      case EventType.END_OF_INNINGS_CHOICE:
        return <IncompleteInningsSVG />;
      case EventType.BATSMEN_SWITCHED:
        return <BatsmenSwitchedSVG />;
      case EventType.DECLARATION:
        return <DeclaredSVG />;
      case EventType.TIMED_OUT:
        return <TimedOutSVG />;
      case EventType.PENALTY:
        return <PenaltySVG />;
      case EventType.FORMAT_CHANGE:
        return <RainSVG />;
      case EventType.SUPER_OVER:
        return this.props.event.eventData.superOverTaken ? (
          <SuperOverSVG />
        ) : (
          <TiedSVG />
        );
      case EventType.MOVE_PLAYER:
        return this.props.event.eventData.direction === Direction.UP ? (
          <MoveUpSVG />
        ) : (
          <MoveDownSVG />
        );
      case EventType.TOSS:
        return <TossSVG />;
      case EventType.STATS_LOADED:
        return <StatsLoadedSVG />;
      case EventType.SURGE_TAKEN:
        return <SurgeTakenSVG />;
      case EventType.ADD_PLAYER:
        return <AddPlayerSVG />;
      case EventType.REMOVE_PLAYER:
        return <RemovePlayerSVG />;
      default:
        return <Icon>question_mark</Icon>;
    }
  }

  private static getTeamName(teamId: string, team1: Team, team2: Team): string {
    return teamId === team1.teamId.value ? team1.name : team2.name;
  }

  public render() {
    if (!this.props.squad1 || !this.props.squad2 || !this.props.event) {
      return <div></div>;
    }
    const surgeOver = !!this.props.gameState
      ? this.props.gameState.getInningsStatForInnings(
          this.props.event.innings,
          InningsStatisticType.SURGE_OVER,
          -1
        )
      : -1;
    const inningsConfiguration =
      !!this.props.matchFormat &&
      this.props.matchFormat.inningsConfiguration[this.props.event.innings - 1];
    const ballInSurge: boolean =
      !!inningsConfiguration &&
      surgeOver !== -1 &&
      Number(this.props.event.over) >= surgeOver &&
      Number(this.props.event.over) <
        surgeOver + inningsConfiguration.surgeOvers;

    return (
      <Tooltip
        title={BetweenBallsEventDisplay.getEventTooltip(
          this.props.squad1,
          this.props.squad2,
          this.props.team1,
          this.props.team2,
          this.props.matchFormat,
          this.props.event
        )}
      >
        <div
          className={
            "between-balls-event-display" +
            (ballInSurge
              ? this.props.overToggle
                ? " over-even-surge"
                : " over-odd-surge"
              : this.props.overToggle
              ? " over-even"
              : " over-odd")
          }
        >
          {this.getEventIcon()}
        </div>
      </Tooltip>
    );
  }
}
