import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Player } from "../../../types/entities/player";
import {
  DismissalMethod,
  dismissalMethodNames,
  dismissalMethodRequiresFielder,
} from "../../../types/enums/dismissal-method";
import { Squad } from "../../../types/entities/squad";
import { propsEqual } from "../../component-utils";
import { EnumSelector } from "../../entity-management/entity-selectors/enum-selector";
import { TeamPlayerSelector } from "../../entity-management/entity-selectors/team-player-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (manOut: Player, howOut: DismissalMethod, fielder: Player) => void;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
  matchFormat: MatchFormat;
}

interface State {
  batsman1: Player;
  batsman2: Player;
  bowler: Player;
  manOut: Player;
  howOut: DismissalMethod;
  fielder: Player;
}

export class WicketModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      batsman1: null,
      batsman2: null,
      bowler: null,
      manOut: null,
      howOut: null,
      fielder: null,
    };
  }

  componentDidMount(): void {
    this.updatePlayers();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updatePlayers();
    }
  }

  private updatePlayers() {
    if (
      this.props.squad1 !== null &&
      this.props.squad2 !== null &&
      this.props.gameState !== null
    ) {
      const battingSquad: Squad =
        this.props.gameState.getBattingTeam(this.props.matchFormat) === 1
          ? this.props.squad1
          : this.props.squad2;
      const bowlingSquad: Squad =
        this.props.gameState.getBattingTeam(this.props.matchFormat) === 1
          ? this.props.squad2
          : this.props.squad1;

      const batsman1: Player =
        this.props.gameState.batsman1 &&
        battingSquad.players.find(
          (player) =>
            player.playerId.value === this.props.gameState.batsman1.value
        );
      const batsman2: Player =
        this.props.gameState.batsman2 &&
        battingSquad.players.find(
          (player) =>
            player.playerId.value === this.props.gameState.batsman2.value
        );
      const bowler: Player =
        this.props.gameState.bowler &&
        bowlingSquad.players.find(
          (player) =>
            player.playerId.value === this.props.gameState.bowler.value
        );
      this.setState({
        batsman1,
        batsman2,
        bowler,
        manOut: batsman1,
        howOut: DismissalMethod.BOWLED,
        fielder: bowler,
      });
    }
  }

  private handleManOutChange(event) {
    const selectedMan: string = event.target.value;
    if (selectedMan === this.state.batsman1.playerId.value) {
      this.setState({ manOut: this.state.batsman1 });
    } else {
      this.setState({ manOut: this.state.batsman2 });
    }
  }

  private handleMethodChange(howOut: DismissalMethod) {
    this.setState({
      howOut,
      fielder: dismissalMethodRequiresFielder[howOut]
        ? this.state.fielder
        : this.state.bowler,
    });
  }

  private invalid(): boolean {
    return (
      this.state.manOut === null ||
      this.state.fielder === null ||
      this.state.howOut === null
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Wicket"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(
            this.state.manOut,
            this.state.howOut,
            this.state.fielder
          )
        }
        proceedText="OK"
        colour="#f04343"
      >
        <FormControl>
          <FormLabel>Man Out:</FormLabel>
          <RadioGroup
            row
            onChange={(event) => this.handleManOutChange(event)}
            name="man-out-group"
            value={this.state.manOut && this.state.manOut.playerId.value}
          >
            <FormControlLabel
              value={this.state.batsman1 && this.state.batsman1.playerId.value}
              control={<Radio />}
              label={this.state.batsman1 && this.state.batsman1.longName}
            />
            <FormControlLabel
              value={this.state.batsman2 && this.state.batsman2.playerId.value}
              control={<Radio />}
              label={this.state.batsman2 && this.state.batsman2.longName}
            />
          </RadioGroup>
        </FormControl>

        <EnumSelector
          classes="dialog-content"
          enumObject={DismissalMethod}
          label="Method"
          value={this.state.howOut}
          readableValues={dismissalMethodNames}
          onSelect={(value) => this.handleMethodChange(value)}
          disabled={false}
          canType={false}
          unavailable={[DismissalMethod.TIMED_OUT]}
        />

        {this.state.howOut &&
          dismissalMethodRequiresFielder[this.state.howOut] && (
            <div>
              <TeamPlayerSelector
                selectedPlayer={
                  this.state.fielder === null
                    ? null
                    : this.state.fielder.playerId
                }
                squad={
                  this.props.gameState.getBattingTeam(
                    this.props.matchFormat
                  ) === 1
                    ? this.props.squad2
                    : this.props.squad1
                }
                notAvailable={[]}
                label={"Fielder:"}
                onSelect={(fielder: Player) => this.setState({ fielder })}
              />
            </div>
          )}
      </CreationDialog>
    );
  }
}
