import { UUID } from "../uuid";
import { Entity } from "./entity";

export class MatchInfo implements Entity {
  public matchId: UUID;
  public team1Name: string;
  public team2Name: string;
  public team1Colour: string;
  public team2Colour: string;
  public runs: number;
  public wickets: number;
  public lastUpdated: number;
  public date: number;
  public createdBy: UUID;

  constructor(
    matchId: UUID,
    team1Name: string,
    team2Name: string,
    team1Colour: string,
    team2Colour: string,
    runs: number,
    wickets: number,
    lastUpdated: number,
    date: number,
    createdBy: UUID
  ) {
    this.matchId = matchId;
    this.team1Name = team1Name;
    this.team2Name = team2Name;
    this.team1Colour = team1Colour;
    this.team2Colour = team2Colour;
    this.runs = runs;
    this.date = date;
    this.wickets = wickets;
    this.lastUpdated = lastUpdated;
    this.createdBy = createdBy;
  }

  public static deserializeList(json: any) {
    const matchInfos: MatchInfo[] = [];
    json.forEach((element) => {
      matchInfos.push(this.deserializeOne(element));
    });
    return matchInfos;
  }

  public static deserializeOne(responseJSON: any): MatchInfo {
    return new MatchInfo(
      UUID.fromString(responseJSON.matchId),
      responseJSON.team1Name,
      responseJSON.team2Name,
      responseJSON.team1Colour,
      responseJSON.team2Colour,
      responseJSON.runs,
      responseJSON.wickets,
      responseJSON.lastUpdated,
      responseJSON.date,
      UUID.fromString(responseJSON.createdBy)
    );
  }

  public toString(): string {
    return (
      this.team1Name +
      " vs " +
      this.team2Name +
      " - " +
      new Date(this.date).toDateString()
    );
  }
}
