import { Component } from "react";
import { PotSummary } from "../../types/investment/pot-summary";
import { Icon } from "@mui/material";
import { KeycloakUser } from "../../services/keycloak-service";
import { formatCurrency } from "../simulator-page/simulator-utils";

interface Props {
  potSummaryCurrent: PotSummary;
  potSummaryPreview: PotSummary;
  investor: KeycloakUser;
}

export class InvestorPotComparison extends Component<Props> {
  private propsLoaded(): boolean {
    return (
      !!this.props.potSummaryCurrent &&
      !!this.props.potSummaryPreview &&
      !!this.props.investor
    );
  }

  public render() {
    if (!this.propsLoaded()) {
      return <div></div>;
    }
    const investorPotStatusCurrent =
      this.props.potSummaryCurrent.investorPotStatusMap.get(
        this.props.investor.id
      );
    const investorPotStatusPreview =
      this.props.potSummaryPreview.investorPotStatusMap.get(
        this.props.investor.id
      );
    const currentHoldingsStyle = {
      color: investorPotStatusCurrent.totalHoldings < 0 ? "red" : "black",
    };
    const currentDepositsStyle = {
      color: investorPotStatusCurrent.totalDeposits < 0 ? "red" : "black",
    };
    const currentWithdrawalsStyle = {
      color: investorPotStatusCurrent.totalWithdrawals < 0 ? "red" : "black",
    };
    const previewHoldingsStyle = {
      color: investorPotStatusPreview.totalHoldings < 0 ? "red" : "black",
    };
    const previewDepositsStyle = {
      color: investorPotStatusPreview.totalDeposits < 0 ? "red" : "black",
    };
    const previewWithdrawalsStyle = {
      color: investorPotStatusPreview.totalWithdrawals < 0 ? "red" : "black",
    };
    return (
      <div>
        <hr />
        <div className="pot-comparison-title">{this.props.investor.name}</div>
        <hr />
        <div className="pot-summary-comparison">
          <div>Total Holdings</div>
          <div
            className="pot-summary-comparison-value"
            style={currentHoldingsStyle}
          >
            {formatCurrency(investorPotStatusCurrent.totalHoldings)}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewHoldingsStyle}
          >
            {formatCurrency(investorPotStatusPreview.totalHoldings)}
          </div>
        </div>
        <div className="pot-summary-comparison">
          <div>Total Desposits</div>
          <div
            className="pot-summary-comparison-value"
            style={currentDepositsStyle}
          >
            {formatCurrency(investorPotStatusCurrent.totalDeposits)}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewDepositsStyle}
          >
            {formatCurrency(investorPotStatusPreview.totalDeposits)}
          </div>
        </div>
        <div className="pot-summary-comparison">
          <div>Total Withdrawals</div>
          <div
            className="pot-summary-comparison-value"
            style={currentWithdrawalsStyle}
          >
            {formatCurrency(investorPotStatusCurrent.totalWithdrawals)}
          </div>
          <div className="pot-summary-comparison-value">
            <Icon>arrow_right</Icon>{" "}
          </div>
          <div
            className="pot-summary-comparison-value"
            style={previewWithdrawalsStyle}
          >
            {formatCurrency(investorPotStatusPreview.totalWithdrawals)}
          </div>
        </div>
      </div>
    );
  }
}
