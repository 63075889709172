import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";

import {
  PlayerFormModuleType,
  playerFormDataDisplayComponents,
  playerFormModuleIsBattingModule,
  playerFormModuleNames,
} from "../../types/form/player-form-modules";
import { PlayerFormExplanation } from "../../types/stats/form-explanation";
import BowlWithExpectationTable from "../common-components/bowl-with-expectation-table";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { TabPanel } from "../match-page/scorecard/tab-panel";

interface Props {
  formExplanation: PlayerFormExplanation;
  selectedTab: number;
}

export default function PlayerFormExplanationDisplay({
  formExplanation,
  selectedTab,
}: Props): React.JSX.Element {
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [displayedModule, setDisplayedModule] =
    useState<PlayerFormModuleType>(null);

  useEffect(() => {
    setDisplayedModule(
      selectedTab === 0
        ? PlayerFormModuleType.GLOBAL_STRIKE_RATE_BIAS
        : PlayerFormModuleType.BOWLING_STRIKE_RATE_BIAS
    );
  }, [selectedTab]);

  const unavailableModules = Object.keys(PlayerFormModuleType)
    .map((key) => {
      const module = PlayerFormModuleType[key];
      const battingModule = playerFormModuleIsBattingModule[module];
      if (
        (battingModule && selectedTab === 0) ||
        (!battingModule && selectedTab === 1)
      ) {
        return null;
      }
      return module;
    })
    .filter((module) => module !== null);

  return (
    <>
      {formExplanation && (
        <div className="form-explanation-container">
          <EnumSelector
            disabled={false}
            enumObject={PlayerFormModuleType}
            value={displayedModule}
            readableValues={playerFormModuleNames}
            onSelect={setDisplayedModule}
            unavailable={unavailableModules}
          />

          <Box>
            <Tabs
              value={tabNumber}
              onChange={(_value, tab) => setTabNumber(tab)}
              aria-label="form-explanation-tabs"
            >
              <Tab
                label={`Collected Data`}
                key={`form-explanation-tab-0`}
                aria-controls={`form-explanation-tab-0`}
              />
              <Tab
                label={`Balls`}
                key={`form-explanation-tab-1`}
                aria-controls={`form-explanation-tab-1`}
              />
            </Tabs>
          </Box>

          <TabPanel
            value={tabNumber}
            index={0}
            key={`form-explanation-tab-panel-0`}
          >
            {playerFormDataDisplayComponents(
              displayedModule,
              formExplanation.formModules.get(displayedModule)
            )}
          </TabPanel>

          <TabPanel
            value={tabNumber}
            index={1}
            key={`form-explanation-tab-panel-1`}
          >
            <BowlWithExpectationTable
              balls={formExplanation.balls.get(displayedModule)}
              displayedModuleType={displayedModule}
            />
          </TabPanel>
        </div>
      )}
    </>
  );
}
