import { Button, MenuItem, Select, Tooltip } from "@mui/material";
import { Component } from "react";
import { Chart, ReactGoogleChartEvent } from "react-google-charts";

import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { propsEqual } from "../component-utils";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { batchUpdate } from "../match-stats-page/match-stats-utils";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "./steal-stats-heading-and-button";

import { AddPointModal } from "./add-point-modal";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  matchFormat: MatchFormat;
  gameState: GameState;
  onUpdate: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
  graphsRecord: Record<string, string>;
  defaultGraph: string;
  canAddOrDeletePoints: boolean;
  title: string;
  xAxisLabel: string;
  showTitle: boolean;
}

interface State {
  selectedX: number;
  selectedY: number;
  selectedGraph: string;
  biasData;
  comparedBiasData;
  selectedXValid: boolean;
  selectedYValid: boolean;
  addPointModalOpen: boolean;
  ballNumber: number;
}

export class MatchStatsEditorChart extends Component<Props, State> {
  STATS_TO_STEAL: string[] = [
    "strikeRateBiasByBall",
    "wicketBiasByBall",
    "paceStrikeRateBiasByBall",
    "paceWicketBiasByBall",
    "spinStrikeRateBiasByBall",
    "spinWicketBiasByBall",
  ];

  private chartSelectionEvent: ReactGoogleChartEvent = {
    eventName: "ready",
    callback: ({ chartWrapper, google }) => {
      google.visualization.events.addListener(chartWrapper, "select", (e) => {
        this.graphClicked(e.getSelection());
      });
    },
  };

  private readonly comparisonChartOptions = {
    pointSize: 5,
    backgroundColor: "#282c34",
    colors: ["#ffffff"],
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
    },
    hAxis: {
      textStyle: {
        color: "white",
      },
    },
    vAxis: {
      textStyle: {
        color: "white",
      },
    },
    legend: {
      position: "bottom",
      textStyle: {
        color: "white",
      },
    },
    series: { 1: { visibleInLegend: false, pointSize: 0 } },
  };

  private static readonly DEFAULT_STATE = {
    selectedX: null,
    selectedY: null,
    comparedBiasData: [],
    biasData: [],
    selectedXValid: true,
    selectedYValid: true,
    addPointModalOpen: false,
    ballNumber: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...MatchStatsEditorChart.DEFAULT_STATE,
      selectedGraph: this.props.defaultGraph,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate.bind(this)
    );
  };

  private updateState() {
    const biasData = [];
    const comparedBiasData = [];
    const totalBallsInFormat = MatchFormat.getTotalBalls(
      this.props.matchFormat
    );
    let totalBallsBowled = 0;
    if (!!this.props.gameState && !!this.props.matchFormat) {
      for (let i = 1; i < this.props.gameState.innings; i++) {
        totalBallsBowled =
          totalBallsBowled +
          MatchFormat.getTotalBallsInInnings(i, this.props.matchFormat);
      }
      totalBallsBowled = totalBallsBowled + this.props.gameState.ballNumber;
      this.props.currentStats
        .getStats()
        [this.state.selectedGraph].forEach((value, key) =>
          biasData.push([key, value, null])
        );
      if (totalBallsInFormat > biasData[biasData.length - 1][0]) {
        this.addPoints([
          [biasData[biasData.length - 1][0] + 1, 1],
          [totalBallsInFormat, 1],
        ]);
      }
      biasData.push([totalBallsBowled, null, 0]);
      biasData.push([totalBallsBowled, null, 2]);
      biasData.unshift([
        "Ball",
        this.props.graphsRecord[this.state.selectedGraph],
        "Current",
      ]);

      if (!!this.props.comparedStats) {
        let totalBallsBowled = 0;
        for (let i = 1; i < this.props.gameState.innings; i++) {
          totalBallsBowled =
            totalBallsBowled +
            MatchFormat.getTotalBallsInInnings(i, this.props.matchFormat);
        }
        totalBallsBowled = totalBallsBowled + this.props.gameState.ballNumber;
        this.props.comparedStats
          .getStats()
          [this.state.selectedGraph].forEach((value, key) =>
            comparedBiasData.push([key, value, null])
          );
        if (
          totalBallsInFormat > comparedBiasData[comparedBiasData.length - 1][0]
        ) {
          this.addPoints([
            [comparedBiasData[comparedBiasData.length - 1][0] + 1, 1],
            [totalBallsInFormat, 1],
          ]);
        }
        comparedBiasData.push([totalBallsBowled, null, 0]);
        comparedBiasData.push([totalBallsBowled, null, 2]);
        comparedBiasData.unshift([
          "Ball",
          this.props.graphsRecord[this.state.selectedGraph],
          "Current",
        ]);
      }
    }

    this.setState({
      ...MatchStatsEditorChart.DEFAULT_STATE,
      selectedX: this.state.selectedX,
      selectedY: this.state.selectedY,
      selectedGraph: this.state.selectedGraph,
      biasData,
      comparedBiasData,
      ballNumber: totalBallsBowled,
    });
  }

  private valid(): boolean {
    return this.state.selectedXValid && this.state.selectedYValid;
  }

  private graphClicked(selection) {
    if (
      selection.length > 0 &&
      !!this.state.biasData[selection[0].row + 1] &&
      this.state.biasData[selection[0].row + 1][1] !== null
    ) {
      this.setState({
        selectedX: selection[0].row,
        selectedY: selection[0].column,
        selectedXValid: true,
        selectedYValid: true,
      });
    }
  }

  private movePoint() {
    const updatedDataPoints: Map<number, number> = this.copyMap(
      this.props.currentStats.getStats()[this.state.selectedGraph]
    );
    updatedDataPoints.set(
      Array.from(this.props.currentStats.getStats()[this.state.selectedGraph])[
        this.state.selectedX
      ][0],
      this.state.selectedY
    );
    this.callUpdateFunction(updatedDataPoints);
  }

  private deletePoint([x, y]: [number, number]) {
    const updatedDataPoints: Map<number, number> = this.copyMap(
      this.props.currentStats.getStats()[this.state.selectedGraph]
    );
    updatedDataPoints.delete(x);
    this.setState({ selectedX: null, selectedY: null });
    this.callUpdateFunction(updatedDataPoints);
  }

  private showAddPointModal() {
    this.setState({ addPointModalOpen: true });
  }

  private addPoints(points: [number, number][]) {
    const updatedDataPoints: Map<number, number> = this.copyMap(
      this.props.currentStats.getStats()[this.state.selectedGraph]
    );
    points.forEach(([x, y]) => updatedDataPoints.set(x, y));
    const sorted = this.sortMap(updatedDataPoints);
    this.callUpdateFunction(sorted);
  }

  private callUpdateFunction(updatedDataPoints: Map<number, number>) {
    this.setState({ addPointModalOpen: false });
    this.props.onUpdate(
      this.props.currentStats.newWithUpdatedPoints(
        updatedDataPoints,
        this.state.selectedGraph
      ),
      this.valid()
    );
  }

  private copyMap(src: Map<number, number>): Map<number, number> {
    const dest: Map<number, number> = new Map();
    Array.from(src).forEach(([x, y]) => dest.set(x, y));
    return dest;
  }

  private sortMap(src: Map<number, number>): Map<number, number> {
    const dest: Map<number, number> = new Map();
    Array.from(src)
      .sort(([x1], [x2]) => (x1 > x2 ? 1 : -1))
      .forEach(([x, y]) => dest.set(x, y));
    return dest;
  }

  private cannotDeleteDataPoint(selectedDataPoint): boolean {
    let finalDataPoint: [key: number, value: number, current: string] = null;
    this.state.biasData.forEach(([key, value, current]) => {
      if (current === null) {
        finalDataPoint = [key, value, current];
      }
    });

    return (
      this.state.selectedX === null ||
      !selectedDataPoint ||
      selectedDataPoint[0] === 0 ||
      selectedDataPoint[0] === finalDataPoint[0]
    );
  }

  private handleGraphSelection(selection) {
    this.setState(
      {
        selectedGraph: selection.target.value,
        selectedX: null,
        selectedY: null,
        selectedXValid: true,
        selectedYValid: true,
      },
      () => this.updateState()
    );
  }

  public render() {
    const selectedDataPoint =
      this.state.selectedGraph &&
      Array.from(this.props.currentStats.getStats()[this.state.selectedGraph])[
        this.state.selectedX
      ];

    return (
      <div className="match-stats-column-section">
        {this.props.showTitle && (
          <div className="stats-modal-section-head">{this.props.title}</div>
        )}

        <div className="ground-conditions-content">
          <div className="ground-conditions-chart-selector">
            <Select
              value={this.state.selectedGraph}
              onChange={(selection) => this.handleGraphSelection(selection)}
              variant="standard"
            >
              {Object.keys(this.props.graphsRecord).map((key) => (
                <MenuItem key={`ground-stat-type-${key}`} value={key}>
                  {this.props.graphsRecord[key]}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="ground-conditions-chart-wrapper">
            <Chart
              chartType="AreaChart"
              data={this.state.biasData}
              chartEvents={[this.chartSelectionEvent]}
              options={{
                pointSize: 5,
                backgroundColor: "#f8f8ff",
                explorer: {
                  actions: ["dragToZoom", "rightClickToReset"],
                },
                legend: "bottom",
                series: { 1: { visibleInLegend: false, pointSize: 0 } },
              }}
            ></Chart>
          </div>

          {!!selectedDataPoint &&
            (!!this.state.selectedX || this.state.selectedX === 0) && (
              <div>
                <div>
                  <div>{this.props.xAxisLabel}</div>
                  <div>{selectedDataPoint[0]}</div>
                </div>
                <NumberSelector
                  label={
                    this.props.graphsRecord[this.state.selectedGraph] + ":"
                  }
                  min={0}
                  max={2}
                  step={0.001}
                  decimalPlaces={3}
                  initial={selectedDataPoint[1]}
                  onValid={(newValue) =>
                    this.setState(
                      {
                        selectedYValid: true,
                        selectedY: newValue,
                      },
                      () => this.movePoint()
                    )
                  }
                  onInvalid={(newValue) =>
                    this.setState(
                      {
                        selectedYValid: false,
                        selectedY: newValue,
                      },
                      () => this.movePoint()
                    )
                  }
                />
              </div>
            )}
          {this.props.canAddOrDeletePoints && (
            <div className="ground-conditions-buttons">
              <Tooltip
                title={
                  this.cannotDeleteDataPoint(selectedDataPoint)
                    ? this.state.selectedX === null
                      ? "Select a data point to delete"
                      : "Cannot delete start or end point"
                    : "Delete data point"
                }
              >
                <div>
                  <Button
                    className="ground-stats-delete-button"
                    disabled={this.cannotDeleteDataPoint(selectedDataPoint)}
                    onClick={() =>
                      this.deletePoint(selectedDataPoint as [number, number])
                    }
                  >
                    <div>Delete Data Point</div>
                  </Button>
                </div>
              </Tooltip>
              <div>
                <Button
                  className="ground-stats-delete-button"
                  onClick={() => this.showAddPointModal()}
                >
                  Add Data Point
                </Button>
              </div>
            </div>
          )}

          {!!this.props.comparedStats && (
            <div className="comparison">
              <StealStatsHeadingAndButton
                comparedUserName={this.props.comparedUserName}
              >
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "matchStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "matchStats"
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </StealStatsHeadingAndButton>
              <div className="ground-conditions-chart-wrapper">
                <Chart
                  chartType="AreaChart"
                  data={this.state.comparedBiasData}
                  chartEvents={[]}
                  options={this.comparisonChartOptions}
                ></Chart>
              </div>
            </div>
          )}
        </div>
        <AddPointModal
          open={this.state.addPointModalOpen}
          onCancel={() => this.setState({ addPointModalOpen: false })}
          onProceed={(ballNumber: number, bias: number) =>
            this.addPoints([[ballNumber, bias]])
          }
          currentDataPoints={
            this.props.currentStats.getStats()[this.state.selectedGraph]
          }
          matchFormat={this.props.matchFormat}
          dataPointType={this.props.graphsRecord[this.state.selectedGraph]}
        />
      </div>
    );
  }
}
