import { useCallback, useEffect, useState } from "react";
import {
  IncidentType,
  incidentTypeNames,
} from "../../../types/enums/incident-type";
import {
  BetfairStatus,
  betfairStatusNames,
} from "../../../types/enums/betfair-status";

const BetfairBallSpeaker = (props) => {
  const speak = useCallback((text: string) => {
    const synth = window.speechSynthesis;
    if (!!synth) {
      const voices: SpeechSynthesisVoice[] = synth.getVoices();
      const msg = new SpeechSynthesisUtterance();
      msg.text = text;
      msg.voice = voices.find((v) => v.name === "Google UK English Male");
      synth.speak(msg);
    }
  }, []);

  const [incidentType, setIncidentType] = useState({
    incidentType: props.incidentType,
  });
  const [incidentValue, setIncidentValue] = useState({
    incidentValue: props.incidentValue,
  });
  const [incidentOver, setIncidentOver] = useState({
    incidentOver: props.incidentOver,
  });
  const [status, setStatus] = useState({ status: props.status });

  useEffect(() => {
    if (
      props.enabled &&
      (incidentValue !== props.incidentValue ||
        incidentType !== props.incidentType ||
        incidentOver !== props.incidentOver) &&
      props.incidentType !== null
    ) {
      speak(
        calculateSpeechFromIncident(
          props.incidentType,
          Number(props.incidentValue),
          props.incidentOver
        )
      );
    }
    setIncidentValue(props.incidentValue);
    setIncidentType(props.incidentType);
    setIncidentOver(props.incidentOver);
  }, [
    props.enabled,
    props.incidentValue,
    props.incidentOver,
    props.incidentType,
    speak,
    incidentValue,
    incidentType,
    incidentOver,
  ]);

  useEffect(() => {
    if (
      props.enabled &&
      !!props.status &&
      status !== props.status &&
      props.status !== betfairStatusNames[BetfairStatus.MATCH_STABLE]
    ) {
      speak(props.status);
    }
    setStatus(props.status);
  }, [props.enabled, props.status, speak, status]);

  return <div />;
};
export default BetfairBallSpeaker;

const calculateSpeechFromIncident = (type, value, overs): string => {
  let valueSpeech;
  let typeSpeech;
  switch (type) {
    case IncidentType.STRIKE:
      valueSpeech = value === 0 ? "Dot" : value;
      typeSpeech =
        value === 0
          ? ""
          : `Run${value > 1 ? "s" : ""}. ${
              value >= 4 ? createCommentary(0) : ""
            }`;
      break;
    case IncidentType.OUT:
      valueSpeech = "";
      typeSpeech = `${incidentTypeNames[type]}. ${createCommentary(1)}`;
      break;
    case IncidentType.INNING_END:
      valueSpeech = "";
      typeSpeech = incidentTypeNames[type];
      break;
    default:
      valueSpeech = value;
      typeSpeech = `${incidentTypeNames[type]}${value > 1 ? "s" : ""}`;
  }
  return `${valueSpeech} ${typeSpeech}. Over ${overs}.`;
};

const createCommentary = (type) => {
  const boundaryCommentary = [
    "A fine shot!",
    "Well played!",
    "Dah, he's got his dancing shoes on",
    "Sir. Very deft, sir",
    "Oh my God, what a shot!",
    "That's gone all the way",
    "Yes, my friend",
    "He's like a cat on a hot tin roof, dah",
    "Dah, he's the man on the moon",
    "Take a bow, my son",
    "He's seen that like a beach ball. Right out of the middle of the bat!",
  ];
  const wicketCommentary = [
    "Absolute garbage, that",
    "What was he thinking?",
    "Oh no no no no",
    "That won't do at all",
    "Hit the showers mate, you're done!",
    "He hasn't done his team any favours, there",
    "Must do better",
    "Cannot take",
    "Are you kidding me?!",
  ];
  let array;

  switch (type) {
    case 0:
      array = boundaryCommentary;
      break;
    case 1:
      array = wicketCommentary;
      break;
  }

  const random = Math.floor(Math.random() * 100) % array.length;
  return array[random];
};
