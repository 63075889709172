export enum SimulatorOddsQuestionOperator {
  EQUALS = "EQUALS",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
}
export const operatorNames: Record<SimulatorOddsQuestionOperator, string> = {
  EQUALS: "=",
  LESS_THAN: "<",
  GREATER_THAN: ">",
  LESS_THAN_OR_EQUAL_TO: "<=",
  GREATER_THAN_OR_EQUAL_TO: ">=",
}
