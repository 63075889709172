import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Component } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import {
  InningsSimulationQuestionStatistic,
  inningsSimulationQuestionStatisticNames,
  inningsSimulationQuestionStatisticRequiresInputs,
  unavailableMedianInningsSimulatorQuestionStatistics,
} from "../../types/enums/question-statistic";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector, {
  medianNumberSelector,
} from "../entity-management/entity-selectors/number-selector";
import { getTeamNameForId } from "./simulator-utils";

interface Props {
  matchFormat: MatchFormat;
  gameState: GameState;
  team1: Team;
  team2: Team;
  onChange: (
    questionStatistic: InningsSimulationQuestionStatistic,
    innings: number,
    overs: number,
    wickets: number,
    teamId: string
  ) => void;
}

interface State {
  questionStatistic: InningsSimulationQuestionStatistic;
  innings: number;
  overs: number;
  wickets: number;
  teamId: string;
  byInnings: boolean;
}

export class SimulatorInningsMedianEditor extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      questionStatistic: InningsSimulationQuestionStatistic.SCORE,
      innings: this.props.gameState.innings,
      overs: 0,
      wickets: 0,
      teamId: !!this.props.gameState
        ? this.props.gameState.getTeamId(1).value
        : "",
      byInnings: true,
    };
  }

  componentDidMount(): void {
    this.update();
  }

  private update() {
    this.props.onChange(
      this.state.questionStatistic,
      this.state.innings,
      this.state.overs,
      this.state.wickets,
      this.state.byInnings ? null : this.state.teamId
    );
  }

  public render() {
    return (
      <div>
        <div className="innings-median-editor-container">
          <EnumSelector
            classes="median-enum-selector"
            enumObject={InningsSimulationQuestionStatistic}
            value={this.state.questionStatistic}
            readableValues={inningsSimulationQuestionStatisticNames}
            onSelect={(value) =>
              this.setState({ questionStatistic: value }, () => this.update())
            }
            disabled={false}
            unavailable={unavailableMedianInningsSimulatorQuestionStatistics}
          />

          <div className="median-radio">
            <RadioGroup
              row
              onChange={(event) =>
                this.setState(
                  { byInnings: event.target.value === "by innings" },
                  () => this.update()
                )
              }
              name="by-innings-radio-group"
              value={this.state.byInnings ? "by innings" : "by team"}
            >
              <FormControlLabel
                className="median-radio-form"
                value={"by innings"}
                control={<Radio />}
                label={<div className="median-radio-form">By Innings</div>}
              />
              <FormControlLabel
                className="median-radio-form"
                value={"by team"}
                control={<Radio />}
                label={<div className="median-radio-form">By Team</div>}
              />
            </RadioGroup>
          </div>
          {this.state.byInnings && (
            <NumberSelector
              label={"Innings: "}
              style={medianNumberSelector}
              min={1}
              max={
                !!this.props.matchFormat
                  ? this.props.matchFormat.inningsConfiguration.length
                  : 1
              }
              step={1}
              decimalPlaces={0}
              initial={this.state.innings}
              onValid={(valid: number) =>
                this.setState({ innings: valid }, () => this.update())
              }
              disabled={!this.state.byInnings}
            />
          )}

          {!this.state.byInnings && (
            <div className="median-radio">
              <RadioGroup
                row
                onChange={(event) =>
                  this.setState({ teamId: event.target.value }, () =>
                    this.update()
                  )
                }
                name="team-radio-group"
                value={this.state.teamId}
              >
                <FormControlLabel
                  value={this.props.gameState.getTeamId(1).value}
                  className="median-radio-form"
                  control={<Radio />}
                  label={
                    <div className="median-radio-form">
                      {getTeamNameForId(
                        this.props.gameState.getTeamId(1).value,
                        this.props.team1,
                        this.props.team2
                      )}
                    </div>
                  }
                  disabled={this.state.byInnings}
                />
                <FormControlLabel
                  value={this.props.gameState.getTeamId(2).value}
                  className="median-radio-form"
                  control={<Radio />}
                  label={
                    <div className="median-radio-form">
                      {getTeamNameForId(
                        this.props.gameState.getTeamId(2).value,
                        this.props.team1,
                        this.props.team2
                      )}
                    </div>
                  }
                  disabled={this.state.byInnings}
                />
              </RadioGroup>
            </div>
          )}
          <div className="median-question-editor-number-selectors">
            {!!inningsSimulationQuestionStatisticRequiresInputs[
              this.state.questionStatistic
            ] &&
              inningsSimulationQuestionStatisticRequiresInputs[
                this.state.questionStatistic
              ][1] && (
                <NumberSelector
                  label={"Wickets: "}
                  style={medianNumberSelector}
                  min={0}
                  max={
                    !!this.props.matchFormat &&
                    !!this.props.matchFormat.inningsConfiguration &&
                    !!this.props.matchFormat.inningsConfiguration[
                      this.state.innings - 1
                    ]
                      ? this.props.matchFormat.inningsConfiguration[
                          this.state.innings - 1
                        ].maxWickets
                      : 0
                  }
                  step={1}
                  decimalPlaces={0}
                  initial={this.state.wickets}
                  onValid={(valid: number) =>
                    this.setState({ wickets: valid }, () => this.update())
                  }
                />
              )}
            {!!inningsSimulationQuestionStatisticRequiresInputs[
              this.state.questionStatistic
            ] &&
              inningsSimulationQuestionStatisticRequiresInputs[
                this.state.questionStatistic
              ][0] && (
                <NumberSelector
                  label={"Overs: "}
                  style={medianNumberSelector}
                  min={1}
                  max={
                    !!this.props.matchFormat &&
                    !!this.props.matchFormat.overConfiguration &&
                    !!this.props.matchFormat.overConfiguration[
                      this.state.innings - 1
                    ]
                      ? this.props.matchFormat.overConfiguration[
                          this.state.innings - 1
                        ].length
                      : 1
                  }
                  step={1}
                  decimalPlaces={0}
                  initial={this.state.overs}
                  onValid={(valid: number) =>
                    this.setState({ overs: valid }, () => this.update())
                  }
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}
