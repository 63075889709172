import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useState } from "react";

export function GroundMap(props) {
  const [initialPosition] = useState<[number, number]>([
    props.latitude === null ? 51.505 : props.latitude,
    props.longitude === null ? -0.09 : props.longitude,
  ]);
  const [selectedPosition, setSelectedPosition] = useState<[number, number]>([
    props.latitude === null ? 51.505 : props.latitude,
    props.longitude === null ? -0.09 : props.longitude,
  ]);

  const Markers = () => {
    useMapEvents({
      click(e) {
        if (!props.disabled) {
          setSelectedPosition([e.latlng.lat, e.latlng.lng]);
          props.onSelect([e.latlng.lat, e.latlng.lng]);
        }
      },
    });

    return selectedPosition[0] !== null && selectedPosition[1] !== null ? (
      <Marker
        key={selectedPosition[0]}
        position={selectedPosition}
        interactive={false}
      />
    ) : null;
  };

  return (
    <MapContainer
      id="map"
      center={selectedPosition || initialPosition}
      zoom={12}
      dragging={!props.disabled}
    >
      <Markers />
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
}
