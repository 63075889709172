import { Tooltip } from "@mui/material";
import { Component } from "react";
import { CricketEvent, getOver } from "../../../types/entities/cricket-event";
import {
  BowlerChoice,
  OverSummary,
} from "../../../types/simulator/simulation-result";
import { Squad } from "../../../types/entities/squad";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";
import { BowlerDecisionModal } from "./bowler-decision-modal";

interface Props {
  endOfInnings: boolean;
  event: CricketEvent;
  overSummary: OverSummary;
  bowlerChoice: BowlerChoice;
  squad1: Squad;
  squad2: Squad;
}

interface State {
  bowlerChoiceModalOpen: boolean;
}

export class EndOfOverDisplay extends Component<Props, State> {
  static defaultProps = {
    bowlerChoice: null,
    squad1: null,
    squad2: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      bowlerChoiceModalOpen: false,
    };
  }

  public render() {
    return (
      <div>
        <Tooltip
          title={
            this.props.endOfInnings
              ? "New Innings"
              : this.props.overSummary
              ? this.props.overSummary.overSummary
              : "Over " + (getOver(this.props.event) + 1)
          }
        >
          <div
            className={
              this.props.endOfInnings ? "end-of-innings" : "end-of-over"
            }
          >
            {!this.props.endOfInnings && (
              <div>
                <div className="end-of-over-text-title">Over</div>
                <div className="end-of-over-text">
                  {getOver(this.props.event) + 1}
                </div>
              </div>
            )}
            {this.props.endOfInnings && (
              <div>
                <div className="end-of-over-text-title">Innings</div>
                <div className="end-of-over-text">
                  {this.props.event.innings}
                </div>
              </div>
            )}
          </div>
        </Tooltip>
        {!!this.props.bowlerChoice && (
          <div className="end-of-over-bowler-choice-button">
            <TooltipIconButton
              title="View bowler decision"
              onClick={() => this.setState({ bowlerChoiceModalOpen: true })}
              icon="help_outline"
              colour="grey"
            />
          </div>
        )}
        <BowlerDecisionModal
          bowlerChoice={this.props.bowlerChoice}
          squad1={this.props.squad1}
          squad2={this.props.squad2}
          modalOpen={this.state.bowlerChoiceModalOpen}
          onClose={() => this.setState({ bowlerChoiceModalOpen: false })}
        />
      </div>
    );
  }
}
