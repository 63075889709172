import { GameState } from "../../../types/entities/game-state";
import { Squad } from "../../../types/entities/squad";
import { MatchFormat } from "../../../types/entities/match-format";
import { Team } from "../../../types/entities/team";
import { MatchInfo } from "../../../types/entities/match-info";
import { Match } from "../../../types/entities/match";
import { services } from "../../../types/services";
import { StatisticType } from "../../../types/enums/statistic-type";

interface Props {
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
  matchInfo: MatchInfo;
}

export default function ScorecardMinimalDisplay({
  gameState,
  squad1,
  squad2,
  team1,
  team2,
  matchFormat,
  match,
  matchInfo,
}: Props): React.JSX.Element {
  const battingTeam = gameState.getBattingTeam(matchFormat);
  const battingSquad = battingTeam === 1 ? squad1 : squad2;
  const bowlingSquad = battingTeam === 1 ? squad2 : squad1;
  const bold = 700;
  const battingTeamStyle = {
    fontWeight: bold,
    textTransform: "uppercase" as const,
  };
  const team1Style = battingTeam === 1 ? battingTeamStyle : {};
  const team2Style = battingTeam === 2 ? battingTeamStyle : {};
  const splitter = <div>|</div>;
  return (
    <div className="minimal-scorecard-display">
      {/* Team Names */}
      <div>
        <span style={team1Style}>{team1.name}</span>
        &nbsp;VS&nbsp;
        <span style={team2Style}>{team2.name}</span>
      </div>
      {splitter}
      {/* Score */}Score:
      <div>
        {matchInfo.runs || 0}/{matchInfo.wickets || 0}
      </div>
      {splitter}
      {/* Batsmen */}Batsmen:
      {gameState.batsman1 && (
        <div style={{ fontWeight: 800 }}>
          *
          {services.teamPlayerService.getPlayerName(
            gameState.batsman1,
            battingSquad
          )}
          :&nbsp;
          {gameState.getPlayerIntegerStat(
            StatisticType.BATSMAN_RUNS_SCORED,
            gameState.batsman1,
            0
          )}
          &nbsp;(
          {gameState.getPlayerIntegerStat(
            StatisticType.BATSMAN_BALLS_ON_STRIKE,
            gameState.batsman1,
            0
          )}
          )
        </div>
      )}
      {gameState.batsman2 && (
        <div>
          {services.teamPlayerService.getPlayerName(
            gameState.batsman2,
            battingSquad
          )}
          :&nbsp;
          {gameState.getPlayerIntegerStat(
            StatisticType.BATSMAN_RUNS_SCORED,
            gameState.batsman2,
            0
          )}
          &nbsp;(
          {gameState.getPlayerIntegerStat(
            StatisticType.BATSMAN_BALLS_ON_STRIKE,
            gameState.batsman2,
            0
          )}
          )
        </div>
      )}
      {splitter}
      {/* Bowler */} Bowler:
      {gameState.bowler && (
        <div style={{ fontWeight: bold }}>
          *
          {services.teamPlayerService.getPlayerName(
            gameState.bowler,
            bowlingSquad
          )}
          :&nbsp;
          {gameState.getPlayerIntegerStat(
            StatisticType.BOWLER_RUNS_CONCEDED,
            gameState.bowler,
            0
          )}
          /
          {gameState.getPlayerIntegerStat(
            StatisticType.BOWLER_WICKETS_TAKEN,
            gameState.bowler,
            0
          )}
          &nbsp;(
          {gameState.getOverTextForBalls(
            gameState.getPlayerIntegerStat(
              StatisticType.BOWLER_BALLS_BOWLED,
              gameState.bowler,
              0
            )
          )}
          )
        </div>
      )}
      {splitter}
      {/* Description */}
      <div>
        {gameState.calculateMatchStatusText(
          team1.name,
          team2.name,
          matchFormat.inningsConfiguration.length,
          matchFormat
        )}
      </div>
    </div>
  );
}
