import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { GameState } from "../../types/entities/game-state"; /*  */
import { CalculatedStatisticType } from "../../types/enums/statistic-type";
import { Team } from "../../types/entities/team";
import { format, getTeamName } from "./simulator-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { compareValues, comparisonCellStyle } from "../component-utils";

interface Props {
  gameState: GameState;
  latestResult: SimulationResult;
  comparedResults: SimulationResult;
  comparedUserName: string;
  team1: Team;
  team2: Team;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface TableValues {
  name: string;
  averageScore: number;
  stdDevScore: number;
  averageWickets: number;
  stdDevWickets: number;
  averagePowerplayScore: number;
  stdDevPowerplayScore: number;
  averagePowerplayWickets: number;
  stdDevPowerplayWickets: number;
  medianPowerplayScore: number;
  medianPowerplayWickets: number;
  averagePush: number;
  averageAllOuts: number;
  averageExtras: number;
}

export class SimulatorMiscStatsDisplay extends Component<Props, {}> {
  private getValues(
    team: number,
    simulationResult: SimulationResult
  ): TableValues {
    const name: string = getTeamName(
      team,
      simulationResult,
      this.props.team1,
      this.props.team2
    );
    if (!!simulationResult) {
      return {
        name,
        averageScore: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_RUNS
            : CalculatedStatisticType.TEAM_2_RUNS
        ),
        stdDevScore: simulationResult.inningsStdDevs.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_RUNS
            : CalculatedStatisticType.TEAM_2_RUNS
        ),
        averagePowerplayScore: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_RUNS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_RUNS
        ),
        medianPowerplayScore: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_RUNS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_RUNS
        ),
        stdDevPowerplayScore: simulationResult.inningsStdDevs.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_RUNS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_RUNS
        ),
        averageWickets: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_WICKETS
            : CalculatedStatisticType.TEAM_2_WICKETS
        ),
        stdDevWickets: simulationResult.inningsStdDevs.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_WICKETS
            : CalculatedStatisticType.TEAM_2_WICKETS
        ),
        averagePowerplayWickets: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_WICKETS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_WICKETS
        ),
        medianPowerplayWickets: simulationResult.inningsMedians.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_WICKETS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_WICKETS
        ),
        stdDevPowerplayWickets: simulationResult.inningsStdDevs.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_POWERPLAY_WICKETS
            : CalculatedStatisticType.TEAM_2_POWERPLAY_WICKETS
        ),
        averagePush: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_PUSH
            : CalculatedStatisticType.TEAM_2_PUSH
        ),
        averageAllOuts: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_ALL_OUTS
            : CalculatedStatisticType.TEAM_2_ALL_OUTS
        ),
        averageExtras: simulationResult.inningsMeans.get(
          team === 1
            ? CalculatedStatisticType.TEAM_1_EXTRAS
            : CalculatedStatisticType.TEAM_2_EXTRAS
        ),
      };
    } else {
      return {
        name,
        averageScore: null,
        stdDevScore: null,
        averageWickets: null,
        stdDevWickets: null,
        averagePowerplayScore: null,
        stdDevPowerplayScore: null,
        averagePowerplayWickets: null,
        stdDevPowerplayWickets: null,
        medianPowerplayScore: null,
        medianPowerplayWickets: null,
        averagePush: null,
        averageAllOuts: null,
        averageExtras: null,
      };
    }
  }

  private buildTableRow(
    team: number,
    values: TableValues,
    comparedValues: TableValues
  ) {
    const style = !!comparedValues ? comparisonCellStyle : null;

    const averageScoreColour = !!comparedValues
      ? compareValues(comparedValues.averageScore, values.averageScore)
      : "black";
    const averageWicketsColour = !!comparedValues
      ? compareValues(comparedValues.averageWickets, values.averageWickets)
      : "black";
    const averagePowerplayScoreColour = !!comparedValues
      ? compareValues(
          comparedValues.averagePowerplayScore,
          values.averagePowerplayScore
        )
      : "black";
    const averagePowerplayWicketsColour = !!comparedValues
      ? compareValues(
          comparedValues.averagePowerplayWickets,
          values.averagePowerplayWickets
        )
      : "black";
    const medianPowerplayScoreColour = !!comparedValues
      ? compareValues(
          comparedValues.medianPowerplayScore,
          values.medianPowerplayScore
        )
      : "black";
    const medianPowerplayWicketsColour = !!comparedValues
      ? compareValues(
          comparedValues.medianPowerplayWickets,
          values.medianPowerplayWickets
        )
      : "black";
    const averagePushColour = !!comparedValues
      ? compareValues(comparedValues.averagePush, values.averagePush)
      : "black";
    const averageAllOutsColour = !!comparedValues
      ? compareValues(comparedValues.averageAllOuts, values.averageAllOuts)
      : "black";
    const averageExtrasColour = !!comparedValues
      ? compareValues(comparedValues.averageExtras, values.averageExtras)
      : "black";

    const stdDevScoreColour = !!comparedValues
      ? compareValues(comparedValues.stdDevScore, values.stdDevScore)
      : "black";
    const stdDevWicketsColour = !!comparedValues
      ? compareValues(comparedValues.stdDevWickets, values.stdDevWickets)
      : "black";
    const stdDevPowerplayScoreColour = !!comparedValues
      ? compareValues(
          comparedValues.stdDevPowerplayScore,
          values.stdDevPowerplayScore
        )
      : "black";
    const stdDevPowerplayWicketsColour = !!comparedValues
      ? compareValues(
          comparedValues.stdDevPowerplayWickets,
          values.stdDevPowerplayWickets
        )
      : "black";

    return (
      <TableRow
        key={`team-${team}-match-odds${!!comparedValues ? "-comparison" : ""}`}
      >
        <TableCell scope="row" id={`innings-averages-table-cell`} style={style}>
          {values.name}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averageScoreColour }}
        >
          {format(values.averageScore)}{" "}
          <div style={{ color: stdDevScoreColour }}>
            ({format(values.stdDevScore)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averageWicketsColour }}
        >
          {format(values.averageWickets)}{" "}
          <div style={{ color: stdDevWicketsColour }}>
            ({format(values.stdDevWickets)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averagePowerplayScoreColour }}
        >
          {format(values.averagePowerplayScore)}{" "}
          <div style={{ color: stdDevPowerplayScoreColour }}>
            ({format(values.stdDevPowerplayScore)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averagePowerplayWicketsColour }}
        >
          {format(values.averagePowerplayWickets)}{" "}
          <div style={{ color: stdDevPowerplayWicketsColour }}>
            ({format(values.stdDevPowerplayWickets)})
          </div>
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianPowerplayScoreColour }}
        >
          {format(values.medianPowerplayScore)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: medianPowerplayWicketsColour }}
        >
          {format(values.medianPowerplayWickets)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averagePushColour }}
        >
          {format(values.averagePush)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averageAllOutsColour }}
        >
          {format(values.averageAllOuts)}
        </TableCell>
        <TableCell
          align="right"
          id={`innings-averages-table-cell`}
          style={{ ...style, color: averageExtrasColour }}
        >
          {format(values.averageExtras)}
        </TableCell>
      </TableRow>
    );
  }

  public render() {
    const team1Values = this.getValues(1, this.props.latestResult);
    const team2Values = this.getValues(2, this.props.latestResult);
    const team1Row = this.buildTableRow(1, team1Values, null);
    const team2Row = this.buildTableRow(2, team2Values, null);

    const team1ComparisonValues = this.getValues(1, this.props.comparedResults);
    const team2ComparisonValues = this.getValues(2, this.props.comparedResults);
    const team1ComparisonRow = this.buildTableRow(
      1,
      team1ComparisonValues,
      team1Values
    );
    const team2ComparisonRow = this.buildTableRow(
      2,
      team2ComparisonValues,
      team2Values
    );

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Innings Averages
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text="Simulating..."
            >
              <TableContainer>
                <Table size="small" aria-label="simulator misc stats table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        align="left"
                        id="innings-averages-table-header-group-0"
                        colSpan={2}
                      >
                        Innings
                      </TableCell>
                      <TableCell
                        align="left"
                        id="innings-averages-table-header-group-1"
                        colSpan={4}
                      >
                        Powerplay
                      </TableCell>
                      <TableCell
                        align="left"
                        id="innings-averages-table-header-group-2"
                        colSpan={3}
                      >
                        Miscellaneous
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-3"
                        colSpan={2}
                      >
                        Mean (SD)
                      </TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-3"
                        colSpan={2}
                      >
                        Mean (SD)
                      </TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-4"
                        colSpan={2}
                      >
                        Median
                      </TableCell>
                      <TableCell
                        align="center"
                        id="innings-averages-table-header-group-3"
                        colSpan={3}
                      >
                        Mean
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="left"
                      >
                        Team
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Wickets
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Wickets
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Score
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Wickets
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Push
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        All Out
                      </TableCell>
                      <TableCell
                        id={`innings-averages-table-cell`}
                        align="right"
                      >
                        Extras
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {team1Row}
                    {!!this.props.comparedResults && team1ComparisonRow}
                    {team2Row}
                    {!!this.props.comparedResults && team2ComparisonRow}
                  </TableBody>
                </Table>
              </TableContainer>
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
