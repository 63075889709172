export enum ExtrasType {
  BYE = "BYE",
  LEG_BYE = "LEG_BYE",
  WIDE = "WIDE",
  NO_BALL = "NO_BALL",
}

export const humanReadableExtrasType: Record<ExtrasType, String> = {
  BYE: "Bye",
  LEG_BYE: "Leg Bye",
  WIDE: "Wide",
  NO_BALL: "No Ball",
};
