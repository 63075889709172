export enum BowlerSpeciality {
  SWING = "SWING",
  FIRST_CHANGE = "FIRST_CHANGE",
  EARLY_MIDDLE = "EARLY_MIDDLE",
  LATE_MIDDLE = "LATE_MIDDLE",
  DEATH = "DEATH",
}

export const bowlerSpecialityNames: Record<BowlerSpeciality, string> = {
  SWING: "Swing",
  FIRST_CHANGE: "First Change",
  EARLY_MIDDLE: "Early Middle Overs",
  LATE_MIDDLE: "Late Middle Overs",
  DEATH: "Death",
};

export const bowlerSpecialityColours: Record<BowlerSpeciality, string> = {
  SWING: "#4748B8",
  FIRST_CHANGE: "#48B75D",
  EARLY_MIDDLE: "#EAD315",
  LATE_MIDDLE: "#EB9534",
  DEATH: "#D4442B",
};

export const bowlerSpecialityDisabledColours: Record<BowlerSpeciality, string> =
  {
    SWING: "#8687b5",
    FIRST_CHANGE: "#8ab592",
    EARLY_MIDDLE: "#e8e097",
    LATE_MIDDLE: "#edd1b2",
    DEATH: "#d4a39b",
  };

export interface BowlerSpecialityPhase {
  bowlerSpeciality: BowlerSpeciality;
  start: number;
  end: number;
}
