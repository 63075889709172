import { MatchRole } from "../enums/match-role";
import { UUID } from "../uuid";
import { Entity } from "./entity";

export class TeamPlayer implements Entity {
  public entityId: UUID;
  public createdAt: number;
  public createdBy: UUID;

  public teamPlayerId: UUID;
  public matchId: UUID;
  public teamId: UUID;
  public playerId: UUID;
  public battingOrder: number;
  public matchRole: MatchRole;

  public eventSequence: number;

  constructor(
    entityId: UUID,
    createdAt: number,
    createdBy: UUID,
    teamPlayerId: UUID,
    matchId: UUID,
    teamId: UUID,
    playerId: UUID,
    battingOrder: number,
    matchRole: MatchRole,
    eventSequence: number
  ) {
    this.entityId = entityId;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.teamPlayerId = teamPlayerId;
    this.matchId = matchId;
    this.teamId = teamId;
    this.playerId = playerId;
    this.battingOrder = battingOrder;
    this.matchRole = matchRole;
    this.eventSequence = eventSequence;
  }

  public toString(): string {
    return `Team ${this.teamId}, Order ${this.battingOrder}`;
  }

  public static deserializeList(json: any[]) {
    const teamPlayers: TeamPlayer[] = [];
    json.forEach((element) => {
      teamPlayers.push(this.deserializeOne(element));
    });
    return teamPlayers;
  }

  public static deserializeOne(responseJSON: any): TeamPlayer {
    return new TeamPlayer(
      UUID.fromString(responseJSON.entityId),
      responseJSON.createdAt,
      UUID.fromString(responseJSON.createdBy),
      UUID.fromString(responseJSON.teamPlayerId),
      UUID.fromString(responseJSON.matchId),
      UUID.fromString(responseJSON.teamId),
      UUID.fromString(responseJSON.playerId),
      responseJSON.battingOrder,
      MatchRole[responseJSON.matchRole],
      responseJSON.eventSequence
    );
  }

  public static emptyWithName(name: string): TeamPlayer {
    return null;
  }

  public static getTypeName(): string {
    return "team player";
  }

  public static serialize(teamPlayer: TeamPlayer): any {
    return {
      entityId: teamPlayer.entityId === null ? null : teamPlayer.entityId.value,
      createdAt: teamPlayer.createdAt,
      createdBy:
        teamPlayer.createdBy === null ? null : teamPlayer.createdBy.value,
      teamPlayerId:
        teamPlayer.teamPlayerId === null ? null : teamPlayer.teamPlayerId.value,
      matchId: teamPlayer.matchId === null ? null : teamPlayer.matchId.value,
      teamId: teamPlayer.teamId === null ? null : teamPlayer.teamId.value,
      playerId: teamPlayer.playerId === null ? null : teamPlayer.playerId.value,
      battingOrder: teamPlayer.battingOrder,
      matchRole: teamPlayer.matchRole,
      eventSequence: teamPlayer.eventSequence,
    };
  }
}
