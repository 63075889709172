import { Component } from "react";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import { Squad } from "../../types/entities/squad";
import { Match } from "../../types/entities/match";
import { Box, Tabs, Tab } from "@mui/material";
import { TabPanel } from "../match-page/scorecard/tab-panel";
import { UUID } from "../../types/uuid";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import { services } from "../../types/services";
import { Player } from "../../types/entities/player";

interface Props {
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
  open: boolean;
  onClose: () => void;
}

interface State {
  tabNumber: number;
}

export class BowlerListModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      tabNumber: 0,
    };
  }

  private getPlayer(playerId: UUID): Player {
    return services.teamPlayerService.getPlayer(
      playerId,
      this.props.squad1,
      this.props.squad2
    );
  }

  private getTabNumber(): number {
    return Math.min(
      this.props.matchFormat.inningsConfiguration.length - 1,
      this.state.tabNumber
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Bowler List"}
        invalid={false}
        disabled={false}
        onCancel={() => {}}
        onProceed={() => this.props.onClose()}
        showCancelButton={false}
        proceedText="OK"
        colour="#535455"
      >
        {!!this.props.gameState &&
          !!this.props.squad1 &&
          !!this.props.squad2 &&
          !!this.props.team1 &&
          !!this.props.team2 &&
          !!this.props.matchFormat &&
          !!this.props.match && (
            <div>
              <Box>
                <Tabs
                  value={this.state.tabNumber}
                  onChange={(value, newValue) =>
                    this.setState({ tabNumber: newValue })
                  }
                  aria-label="Bowler list tabs"
                >
                  {this.props.matchFormat.inningsConfiguration.map(
                    (_inningsConfiguration, index) => {
                      const innings = index + 1;
                      return (
                        <Tab
                          key={`innings-${innings}-bowlers-tab`}
                          label={`Innings ${innings}`}
                          id={`bowler-tab-${innings}`}
                          aria-controls={`bowler-tab-${innings}`}
                        />
                      );
                    }
                  )}
                </Tabs>
              </Box>

              {this.props.matchFormat.inningsConfiguration.map(
                (_inningsConfiguration, index) => {
                  const innings = index + 1;
                  return (
                    <TabPanel
                      value={this.getTabNumber()}
                      index={innings - 1}
                      key={`innings-${innings}-bowlers`}
                      className="bowler-list"
                    >
                      {!!this.props.gameState.getOverBowlers(innings) &&
                        this.props.gameState
                          .getOverBowlers(innings)
                          .map((playerIds: UUID[], index: number) => (
                            <div key={`${innings}-${index}`}>
                              {playerIds.length > 0 && (
                                <div className="bowler-list-item">
                                  <div>{index + 1}</div>
                                  <div>
                                    <PlayerView
                                      player={this.getPlayer(playerIds[0])}
                                      canEdit={false}
                                      canDelete={false}
                                      imgHeight={80}
                                      imgWidth={60}
                                      showName
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                    </TabPanel>
                  );
                }
              )}
            </div>
          )}
      </CreationDialog>
    );
  }
}
