import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component } from "react";
import { Subscription } from "rxjs";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { SimulatorOddsQuestionOperator } from "../../types/enums/questions-operator";
import { SimulatorScenario } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { InningsSimulationMedianQuestion } from "../../types/simulator/questions/simulation-median-question";
import { InningsSimulationOddsQuestion } from "../../types/simulator/questions/simulation-odds-question";
import { SimulationResult } from "../../types/simulator/simulation-result";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { MedianQuestionResultsComponent } from "./median-question-results-component";
import { SimulatorInningsMedianEditor } from "./simulator-innings-median-editor";

interface Props {
  gameState: GameState;
  matchFormat: MatchFormat;
  team1: Team;
  team2: Team;
  squad1: Squad;
  squad2: Squad;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface State {
  refreshing: boolean;
  medians: number[][];
  odds: number[];
  oddsOperand: number;
  questions: InningsSimulationMedianQuestion[];
}

export class SimulatorMedianQuestionDisplay extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      refreshing: false,
      medians: null,
      odds: null,
      oddsOperand: null,
      questions: null,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        (scenarioResults: [SimulatorScenario, SimulationResult]) => {
          if (!!scenarioResults) {
            this.refreshMedian();
          }
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateQuestions(
    questionStatistic,
    innings,
    over,
    wicketsFallen,
    teamId
  ) {
    const questions: InningsSimulationMedianQuestion[] = [
      {
        subType: ".InningsSimulationMedianQuestion",
        questionStatistic,
        innings,
        over,
        teamId,
        wicketsFallen,
      },
    ];
    this.setState({ questions, refreshing: true }, () => this.refreshMedian());
  }

  private refreshMedian() {
    if (!!this.props.gameState) {
      services.questionService
        .askMedianQuestions(
          this.props.gameState.matchId,
          this.props.gameState.eventSequence,
          this.state.questions,
          this.props.matchFormat
        )
        .then((response) =>
          this.setState(
            {
              medians: response,
              oddsOperand: !!response[0] ? response[0][0] : 0,
            },
            () => this.refreshOdds()
          )
        );
    }
  }

  private refreshOdds() {
    if (!!this.state.questions && !!this.state.questions[0]) {
      const questions: InningsSimulationOddsQuestion[] = [
        {
          subType: ".InningsSimulationOddsQuestion",
          questionStatistic: this.state.questions[0].questionStatistic,
          innings: this.state.questions[0].innings,
          over: this.state.questions[0].over,
          teamId: this.state.questions[0].teamId,
          wicketsFallen: this.state.questions[0].wicketsFallen,
          operator: SimulatorOddsQuestionOperator.GREATER_THAN_OR_EQUAL_TO,
          operand: this.state.oddsOperand,
        },
      ];
      if (!!this.props.gameState) {
        services.questionService
          .askOddsQuestions(
            this.props.gameState.matchId,
            this.props.gameState.eventSequence,
            questions,
            this.props.matchFormat
          )
          .then((response) =>
            this.setState({ odds: response, refreshing: false })
          );
      }
    }
  }

  public render() {
    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Median Question Machine
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading || this.state.refreshing}
              spinner
              text={
                this.props.loading ? "Simulating..." : "Asking Questions..."
              }
            >
              {!!this.state.medians && (
                <div className="simulation-medians-question">
                  <SimulatorInningsMedianEditor
                    gameState={this.props.gameState}
                    matchFormat={this.props.matchFormat}
                    team1={this.props.team1}
                    team2={this.props.team2}
                    onChange={(
                      questionStatistic,
                      innings,
                      overs,
                      wickets,
                      teamId
                    ) =>
                      this.updateQuestions(
                        questionStatistic,
                        innings,
                        overs,
                        wickets,
                        teamId
                      )
                    }
                  />
                  {!!this.state.odds && <hr />}
                  {!!this.state.odds && (
                    <MedianQuestionResultsComponent
                      median={
                        !!this.state.medians &&
                        !!this.state.medians[0] &&
                        this.state.medians[0][0]
                      }
                      mean={
                        !!this.state.medians &&
                        !!this.state.medians[0] &&
                        this.state.medians[0][1]
                      }
                      odds={this.state.odds[0]}
                      value={this.state.oddsOperand}
                      onChange={(operand) =>
                        this.setState({ oddsOperand: operand }, () =>
                          this.refreshOdds()
                        )
                      }
                    />
                  )}
                </div>
              )}
              {!this.state.medians && (
                <div className="simulation-medians-list italic">
                  Medians will display on Simulation Refresh
                </div>
              )}
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
