import { ReactComponent as GBNIR } from "../../img/flags/gb-nir.svg";
import { ReactComponent as WI } from "../../img/flags/wi.svg";
import { Country } from "./country";
import Flag from "react-world-flags";
import { ReactNode } from "react";

const flagStyle = {
  fontSize: "2em",
  lineHeight: "1em",
};

export const countryFlags: Record<Country, ReactNode> = {
  AFGHANISTAN: <Flag code="AF" style={flagStyle} />,
  ALBANIA: <Flag code="AL" style={flagStyle} />,
  ALGERIA: <Flag code="DZ" style={flagStyle} />,
  ANDORRA: <Flag code="AD" style={flagStyle} />,
  ANGOLA: <Flag code="AO" style={flagStyle} />,
  ANTIGUA_AND_BARBUDA: <Flag code="AG" style={flagStyle} />,
  ARGENTINA: <Flag code="AR" style={flagStyle} />,
  ARMENIA: <Flag code="AM" style={flagStyle} />,
  AUSTRALIA: <Flag code="AU" style={flagStyle} />,
  AUSTRIA: <Flag code="AT" style={flagStyle} />,
  AZERBAIJAN: <Flag code="AZ" style={flagStyle} />,
  BAHAMAS: <Flag code="BS" style={flagStyle} />,
  BAHRAIN: <Flag code="BH" style={flagStyle} />,
  BANGLADESH: <Flag code="BD" style={flagStyle} />,
  BARBADOS: <Flag code="BB" style={flagStyle} />,
  BELARUS: <Flag code="BY" style={flagStyle} />,
  BELGIUM: <Flag code="BE" style={flagStyle} />,
  BELIZE: <Flag code="BZ" style={flagStyle} />,
  BENIN: <Flag code="BJ" style={flagStyle} />,
  BERMUDA: <Flag code="BM" style={flagStyle} />,
  BHUTAN: <Flag code="BT" style={flagStyle} />,
  BOLIVIA: <Flag code="BO" style={flagStyle} />,
  BOSNIA_AND_HERZEGOVINA: <Flag code="BA" style={flagStyle} />,
  BOTSWANA: <Flag code="BW" style={flagStyle} />,
  BRAZIL: <Flag code="BR" style={flagStyle} />,
  BRUNEI: <Flag code="BN" style={flagStyle} />,
  BULGARIA: <Flag code="BG" style={flagStyle} />,
  BURKINA_FASO: <Flag code="BF" style={flagStyle} />,
  BURUNDI: <Flag code="BI" style={flagStyle} />,
  IVORY_COAST: <Flag code="CI" style={flagStyle} />,
  CAPE_VERDE: <Flag code="CV" style={flagStyle} />,
  CAMBODIA: <Flag code="KH" style={flagStyle} />,
  CAMEROON: <Flag code="CM" style={flagStyle} />,
  CANADA: <Flag code="CA" style={flagStyle} />,
  CAYMAN_ISLANDS: <Flag code="KY" style={flagStyle} />,
  CENTRAL_AFRICAN_REPUBLIC: <Flag code="CF" style={flagStyle} />,
  CHAD: <Flag code="TD" style={flagStyle} />,
  CHILE: <Flag code="CL" style={flagStyle} />,
  CHINA: <Flag code="CN" style={flagStyle} />,
  COLOMBIA: <Flag code="CO" style={flagStyle} />,
  COMOROS: <Flag code="KM" style={flagStyle} />,
  CONGO: <Flag code="CG" style={flagStyle} />,
  COOK_ISLANDS: <Flag code="CK" style={flagStyle} />,
  COSTA_RICA: <Flag code="CR" style={flagStyle} />,
  CROATIA: <Flag code="HR" style={flagStyle} />,
  CUBA: <Flag code="CU" style={flagStyle} />,
  CYPRUS: <Flag code="CY" style={flagStyle} />,
  CZECH_REPUBLIC: <Flag code="CZ" style={flagStyle} />,
  DEMOCRATIC_REPUBLIC_OF_THE_CONGO: <Flag code="CD" style={flagStyle} />,
  DENMARK: <Flag code="DK" style={flagStyle} />,
  DJIBOUTI: <Flag code="DJ" style={flagStyle} />,
  DOMINICA: <Flag code="DM" style={flagStyle} />,
  DOMINICAN_REPUBLIC: <Flag code="DO" style={flagStyle} />,
  ECUADOR: <Flag code="EC" style={flagStyle} />,
  EGYPT: <Flag code="EG" style={flagStyle} />,
  EL_SALVADOR: <Flag code="SV" style={flagStyle} />,
  EQUATORIAL_GUINEA: <Flag code="GQ" style={flagStyle} />,
  ERITREA: <Flag code="ER" style={flagStyle} />,
  ESTONIA: <Flag code="EE" style={flagStyle} />,
  ESWATINI: <Flag code="SZ" style={flagStyle} />,
  ETHIOPIA: <Flag code="ET" style={flagStyle} />,
  FIJI: <Flag code="FJ" style={flagStyle} />,
  FINLAND: <Flag code="FI" style={flagStyle} />,
  FRANCE: <Flag code="FR" style={flagStyle} />,
  GABON: <Flag code="GA" style={flagStyle} />,
  GAMBIA: <Flag code="GM" style={flagStyle} />,
  GEORGIA: <Flag code="GE" style={flagStyle} />,
  GERMANY: <Flag code="DE" style={flagStyle} />,
  GHANA: <Flag code="GH" style={flagStyle} />,
  GIBRALTAR: <Flag code="GB" style={flagStyle} />,
  GREECE: <Flag code="GR" style={flagStyle} />,
  GRENADA: <Flag code="GD" style={flagStyle} />,
  GUATEMALA: <Flag code="GT" style={flagStyle} />,
  GUERNSEY: <Flag code="GG" style={flagStyle} />,
  GUINEA: <Flag code="GN" style={flagStyle} />,
  GUINEA_BISSAU: <Flag code="GW" style={flagStyle} />,
  GUYANA: <Flag code="GY" style={flagStyle} />,
  HAITI: <Flag code="HT" style={flagStyle} />,
  HONDURAS: <Flag code="HN" style={flagStyle} />,
  HONG_KONG: <Flag code="HK" style={flagStyle} />,
  HUNGARY: <Flag code="HU" style={flagStyle} />,
  ICELAND: <Flag code="IS" style={flagStyle} />,
  INDIA: <Flag code="IN" style={flagStyle} />,
  INDONESIA: <Flag code="ID" style={flagStyle} />,
  IRAN: <Flag code="IR" style={flagStyle} />,
  IRAQ: <Flag code="IQ" style={flagStyle} />,
  IRELAND: <Flag code="IE" style={flagStyle} />,
  ISRAEL: <Flag code="IL" style={flagStyle} />,
  ITALY: <Flag code="IT" style={flagStyle} />,
  JAMAICA: <Flag code="JM" style={flagStyle} />,
  JAPAN: <Flag code="JP" style={flagStyle} />,
  JERSEY: <Flag code="JE" style={flagStyle} />,
  JORDAN: <Flag code="JO" style={flagStyle} />,
  KAZAKHSTAN: <Flag code="KZ" style={flagStyle} />,
  KENYA: <Flag code="KE" style={flagStyle} />,
  KIRIBATI: <Flag code="KI" style={flagStyle} />,
  KUWAIT: <Flag code="KW" style={flagStyle} />,
  KYRGYZSTAN: <Flag code="KG" style={flagStyle} />,
  LAOS: <Flag code="LA" style={flagStyle} />,
  LATVIA: <Flag code="LV" style={flagStyle} />,
  LEBANON: <Flag code="LB" style={flagStyle} />,
  LESOTHO: <Flag code="LS" style={flagStyle} />,
  LIBERIA: <Flag code="LR" style={flagStyle} />,
  LIBYA: <Flag code="LY" style={flagStyle} />,
  LIECHTENSTEIN: <Flag code="LI" style={flagStyle} />,
  LITHUANIA: <Flag code="LT" style={flagStyle} />,
  LUXEMBOURG: <Flag code="LU" style={flagStyle} />,
  MADAGASCAR: <Flag code="MG" style={flagStyle} />,
  MALAWI: <Flag code="MW" style={flagStyle} />,
  MALAYSIA: <Flag code="MY" style={flagStyle} />,
  MALDIVES: <Flag code="MV" style={flagStyle} />,
  MALI: <Flag code="ML" style={flagStyle} />,
  MALTA: <Flag code="MT" style={flagStyle} />,
  MARSHALL_ISLANDS: <Flag code="MH" style={flagStyle} />,
  MAURITANIA: <Flag code="MR" style={flagStyle} />,
  MAURITIUS: <Flag code="MU" style={flagStyle} />,
  MEXICO: <Flag code="MX" style={flagStyle} />,
  MICRONESIA: <Flag code="FM" style={flagStyle} />,
  MOLDOVA: <Flag code="MD" style={flagStyle} />,
  MONACO: <Flag code="MC" style={flagStyle} />,
  MONGOLIA: <Flag code="MN" style={flagStyle} />,
  MONTENEGRO: <Flag code="ME" style={flagStyle} />,
  MOROCCO: <Flag code="MA" style={flagStyle} />,
  MOZAMBIQUE: <Flag code="MZ" style={flagStyle} />,
  MYANMAR: <Flag code="MM" style={flagStyle} />,
  NAMIBIA: <Flag code="NA" style={flagStyle} />,
  NAURU: <Flag code="NR" style={flagStyle} />,
  NEPAL: <Flag code="NP" style={flagStyle} />,
  NETHERLANDS: <Flag code="NL" style={flagStyle} />,
  NEW_ZEALAND: <Flag code="NZ" style={flagStyle} />,
  NICARAGUA: <Flag code="NI" style={flagStyle} />,
  NIGER: <Flag code="NE" style={flagStyle} />,
  NIGERIA: <Flag code="NG" style={flagStyle} />,
  NORTH_KOREA: <Flag code="KP" style={flagStyle} />,
  NORTH_MACEDONIA: <Flag code="MK" style={flagStyle} />,
  NORWAY: <Flag code="NO" style={flagStyle} />,
  OMAN: <Flag code="OM" style={flagStyle} />,
  PAKISTAN: <Flag code="PK" style={flagStyle} />,
  PALAU: <Flag code="PW" style={flagStyle} />,
  PALESTINE_STATE: <Flag code="PS" style={flagStyle} />,
  PANAMA: <Flag code="PA" style={flagStyle} />,
  PAPUA_NEW_GUINEA: <Flag code="PG" style={flagStyle} />,
  PARAGUAY: <Flag code="PY" style={flagStyle} />,
  PERU: <Flag code="PE" style={flagStyle} />,
  PHILIPPINES: <Flag code="PH" style={flagStyle} />,
  POLAND: <Flag code="PL" style={flagStyle} />,
  PORTUGAL: <Flag code="PT" style={flagStyle} />,
  QATAR: <Flag code="QA" style={flagStyle} />,
  ROMANIA: <Flag code="RO" style={flagStyle} />,
  RUSSIA: <Flag code="RU" style={flagStyle} />,
  RWANDA: <Flag code="RW" style={flagStyle} />,
  SAINT_KITTS_AND_NEVIS: <Flag code="KN" style={flagStyle} />,
  SAINT_LUCIA: <Flag code="LC" style={flagStyle} />,
  SAINT_VINCENT_AND_THE_GRENADINES: <Flag code="VC" style={flagStyle} />,
  SAMOA: <Flag code="WS" style={flagStyle} />,
  SAN_MARINO: <Flag code="SM" style={flagStyle} />,
  SAO_TOME_AND_PRINCIPE: <Flag code="ST" style={flagStyle} />,
  SAUDI_ARABIA: <Flag code="SA" style={flagStyle} />,
  SENEGAL: <Flag code="SN" style={flagStyle} />,
  SERBIA: <Flag code="RS" style={flagStyle} />,
  SEYCHELLES: <Flag code="SC" style={flagStyle} />,
  SIERRA_LEONE: <Flag code="SL" style={flagStyle} />,
  SINGAPORE: <Flag code="SG" style={flagStyle} />,
  SLOVAKIA: <Flag code="SK" style={flagStyle} />,
  SLOVENIA: <Flag code="SI" style={flagStyle} />,
  SOLOMON_ISLANDS: <Flag code="SB" style={flagStyle} />,
  SOMALIA: <Flag code="SO" style={flagStyle} />,
  SOUTH_AFRICA: <Flag code="ZA" style={flagStyle} />,
  SOUTH_KOREA: <Flag code="KR" style={flagStyle} />,
  SOUTH_SUDAN: <Flag code="SS" style={flagStyle} />,
  SPAIN: <Flag code="ES" style={flagStyle} />,
  SRI_LANKA: <Flag code="LK" style={flagStyle} />,
  SUDAN: <Flag code="SD" style={flagStyle} />,
  SURINAME: <Flag code="SR" style={flagStyle} />,
  SWEDEN: <Flag code="SE" style={flagStyle} />,
  SWITZERLAND: <Flag code="CH" style={flagStyle} />,
  SYRIA: <Flag code="SY" style={flagStyle} />,
  TAJIKISTAN: <Flag code="TJ" style={flagStyle} />,
  TANZANIA: <Flag code="TZ" style={flagStyle} />,
  THAILAND: <Flag code="TH" style={flagStyle} />,
  TIMOR_LESTE: <Flag code="TL" style={flagStyle} />,
  TOGO: <Flag code="TG" style={flagStyle} />,
  TONGA: <Flag code="TO" style={flagStyle} />,
  TRINIDAD_AND_TOBAGO: <Flag code="TT" style={flagStyle} />,
  TUNISIA: <Flag code="TN" style={flagStyle} />,
  TURKEY: <Flag code="TR" style={flagStyle} />,
  TURKMENISTAN: <Flag code="TM" style={flagStyle} />,
  TUVALU: <Flag code="TV" style={flagStyle} />,
  UGANDA: <Flag code="UG" style={flagStyle} />,
  UKRAINE: <Flag code="UA" style={flagStyle} />,
  UNITED_ARAB_EMIRATES: <Flag code="AE" style={flagStyle} />,
  UNITED_KINGDOM: <Flag code="GB" style={flagStyle} />,
  ENGLAND: <Flag code="GB-ENG" style={flagStyle} />,
  WALES: <Flag code="GB-WLS" style={flagStyle} />,
  SCOTLAND: <Flag code="GB-SCT" style={flagStyle} />,
  NORTHERN_IRELAND: <GBNIR className="small-image" />,
  UNITED_STATES_OF_AMERICA: <Flag code="US" style={flagStyle} />,
  URUGUAY: <Flag code="UY" style={flagStyle} />,
  UZBEKISTAN: <Flag code="UZ" style={flagStyle} />,
  VANUATU: <Flag code="VU" style={flagStyle} />,
  VATICAN_CITY: <Flag code="VA" style={flagStyle} />,
  VENEZUELA: <Flag code="VE" style={flagStyle} />,
  VIETNAM: <Flag code="VN" style={flagStyle} />,
  YEMEN: <Flag code="YE" style={flagStyle} />,
  ZAMBIA: <Flag code="ZM" style={flagStyle} />,
  ZIMBABWE: <Flag code="ZW" style={flagStyle} />,
  WEST_INDIES: <WI className="small-image" />,
  ISLE_OF_MAN: <Flag code="GB" style={flagStyle} />,
  ISLE_OF_WIGHT: <Flag code="GB" style={flagStyle} />,
  ST_HELENA: <Flag code="GB" style={flagStyle} />,
  UNKNOWN: <div />,
};
