import { services } from "../../types/services";
import { BetType } from "../../types/stored-bets/bet-type";
import { StoredBet } from "../../types/stored-bets/stored-bet";
import {
  MatchOdd,
  RunsLineOdd,
  StoredGameStateOdds,
} from "../../types/stored-bets/stored-odds";
import { UUID } from "../../types/uuid";
import { format } from "../simulator-page/simulator-utils";
import { GetBetsResponse } from "./bets-page";
import {
  getInningsRunsLineOdds,
  getMatchOdds,
  getSessionRunsLineOdds,
} from "./utils";

export function createCSVFilename(): string {
  return `my-bets-${new Date().toLocaleString()}.csv`;
}

const headers: string[] = [
  "Market Name",
  "Match Name",
  "Placed Time",
  "Matched Time",
  "Matched Odds",
  "Sim Odds",
  "Original Stake",
  "Matched Stake",
  "Source",
  "Bet Direction",
  "Bet Result",
  "Bet Type",
  "Team Name",
  "Innings",
  "Runs",
  "Wickets",
  "Over Low",
  "Over High",
  "Player Name",
  "Balls Faced",
  "Boundaries",
  "Tied",
  "Current Innings",
  "Next Innings",
  "Current Over",
  "Next Over",
  "Current Score",
  "Next Score",
  "Back Price",
  "Back Size",
  "Lay Price",
  "Lay Size",
];

function findOdds(
  matchId: UUID,
  bet: StoredBet,
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): StoredGameStateOdds {
  if (!matchId?.value || !bet?.gameStateId?.value) {
    return null;
  }

  return simOdds?.[matchId.value]?.find(
    (odds: StoredGameStateOdds) =>
      odds.matchId === matchId.value &&
      odds.gameStateId === bet.gameStateId.value
  );
}

function getOdds(
  simulatorOdds: boolean,
  matchId: UUID,
  bet: StoredBet,
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): MatchOdd | RunsLineOdd {
  if (!matchId || !simOdds) {
    return null;
  }

  const gameStateOdds: StoredGameStateOdds = findOdds(matchId, bet, simOdds);

  if (!gameStateOdds) {
    return null;
  }

  switch (bet.betType) {
    case BetType.MATCH_ODDS:
    case BetType.SUPER_OVER:
      return getMatchOdds(
        bet,
        gameStateOdds,
        simulatorOdds ? "simulatorMatchOdds" : "betfairMatchOdds"
      );
    case BetType.INNINGS_RUNS:
      return getInningsRunsLineOdds(
        bet,
        gameStateOdds,
        simulatorOdds ? "simulatorRunsLineOdds" : "betfairRunsLineOdds"
      );
    case BetType.SESSION:
      return getSessionRunsLineOdds(
        bet,
        gameStateOdds,
        simulatorOdds ? "simulatorRunsLineOdds" : "betfairRunsLineOdds"
      );
    default:
      return null;
  }
}

function getUserOdds(
  matchId: UUID,
  bet: StoredBet,
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): MatchOdd | RunsLineOdd {
  return getOdds(true, matchId, bet, simOdds);
}

function getBetfairOdds(
  matchId: UUID,
  bet: StoredBet,
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): MatchOdd | RunsLineOdd {
  return getOdds(false, matchId, bet, simOdds);
}

export function createCSVContent(): Promise<string> {
  return services.betScraperService
    .getBets(0, -1)
    .then((getBetsResponse: GetBetsResponse) => {
      let csvContent: string = headers.join(",") + "\n";
      const bets = getBetsResponse.bets;
      const simOdds = getBetsResponse.odds;

      const rows = Object.keys(bets).flatMap((sourceEventId) =>
        bets[sourceEventId].map((bet) => {
          const {
            marketName,
            matchName,
            placedTime,
            matchedTime,
            odds,
            originalStake,
            matchedStake,
            source,
            betDirection,
            betResult,
            betType,
            betData,
          } = bet;

          const userOdds = getUserOdds(bet.matchId, bet, simOdds);
          const betfairOdds = getBetfairOdds(bet.matchId, bet, simOdds);

          const formattedUserOdds = format(userOdds?.price) || "";
          const formattedBetOdds = format(odds) || "";
          const formattedOriginalStake = format(originalStake) || "";
          const formattedMatchedStake = format(matchedStake) || "";

          const formattedBackPrice =
            format(betfairOdds?.marketData?.backPrice) || "";
          const formattedBackSize =
            format(betfairOdds?.marketData?.backSize) || "";
          const formattedLayPrice =
            format(betfairOdds?.marketData?.layPrice) || "";
          const formattedLaySize =
            format(betfairOdds?.marketData?.laySize) || "";

          const gameStateOdds: StoredGameStateOdds = findOdds(
            bet.matchId,
            bet,
            simOdds
          );

          const row = [
            marketName,
            matchName,
            new Date(placedTime).toISOString(),
            new Date(matchedTime).toISOString(),
            formattedBetOdds.replace(",", ""),
            formattedUserOdds.replace(",", ""),
            formattedOriginalStake.replace(",", ""),
            formattedMatchedStake.replace(",", ""),
            source,
            betDirection,
            betResult,
            betType,
            (betData as any)?.teamName,
            (betData as any)?.innings,
            (betData as any)?.runs,
            (betData as any)?.wickets,
            (betData as any)?.overLow,
            (betData as any)?.overHigh,
            (betData as any)?.playerName,
            (betData as any)?.ballsFaced,
            (betData as any)?.boundaries,
            (betData as any)?.tied,
            gameStateOdds?.innings,
            gameStateOdds?.nextGameStateInnings,
            gameStateOdds?.overs,
            gameStateOdds?.nextGameStateOvers,
            gameStateOdds?.score,
            gameStateOdds?.nextGameStateScore,
            formattedBackPrice.replace(",", ""),
            formattedBackSize.replace(",", ""),
            formattedLayPrice.replace(",", ""),
            formattedLaySize.replace(",", ""),
          ];

          return row.join(",");
        })
      );

      csvContent += rows.join("\n") + "\n";

      return csvContent;
    });
}
