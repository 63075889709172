import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component, ReactNode } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { CalculatedStatisticType } from "../../types/enums/statistic-type";
import {
  getTruePriceResults,
  SimulatorScenario,
} from "../../types/preferences/preferences";
import { services } from "../../types/services";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { UUID } from "../../types/uuid";
import {
  compareValues,
  comparisonCellStyle,
  findScenarioResultsFromMap,
} from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import {
  format,
  hexToGreyscale,
  OddsTableHeaderRow,
  sortResults,
  sortRowsByColumnNumber,
} from "./simulator-utils";

interface Props {
  gameState: GameState;
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedUserName: string;
  matchFormat: MatchFormat;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  loading: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface State {
  sortColumn: number;
  sortDirection: "asc" | "desc";
}

export class SimulatorNextManOutDisplay extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortColumn: 0,
      sortDirection: "asc",
    };
  }

  private getProbability(
    simulationResult: SimulationResult,
    playerId: string
  ): number {
    if (!!simulationResult) {
      const occurrences =
        simulationResult.playerStats.get(
          CalculatedStatisticType.NEXT_MAN_OUT
        ) &&
        simulationResult.playerStats
          .get(CalculatedStatisticType.NEXT_MAN_OUT)
          .get(playerId);
      let totalNextMenOut: number = 0;
      simulationResult.playerStats.get(CalculatedStatisticType.NEXT_MAN_OUT) &&
        simulationResult.playerStats
          .get(CalculatedStatisticType.NEXT_MAN_OUT)
          .forEach(
            (timesNextManOut, playerId) => (totalNextMenOut += timesNextManOut)
          );

      return totalNextMenOut / occurrences;
    } else {
      return NaN;
    }
  }

  private buildTableCell(
    scenario: SimulatorScenario,
    playerId: string,
    probability: number,
    comparedProbability: number
  ): ReactNode {
    const style = !!comparedProbability
      ? {
          ...comparisonCellStyle,
          color: compareValues(1 / comparedProbability, 1 / probability),
        }
      : {
          backgroundColor: scenario.enabled
            ? scenario.colour
            : hexToGreyscale(scenario.colour),
        };

    return (
      <TableCell
        key={`next-man-out-${playerId}-${scenario.name}`}
        align="right"
        style={style}
        id={`simulation-table-cell`}
      >
        {format(probability)}
      </TableCell>
    );
  }

  private sort(column: number, direction: "asc" | "desc") {
    this.setState({
      sortColumn: column,
      sortDirection: direction,
    });
  }

  public render() {
    const rows: ReactNode[] = [];
    const truePriceResults =
      !!this.props.latestResults &&
      getTruePriceResults(this.props.latestResults);
    let sortedResults: Map<string, [SimulatorScenario, SimulationResult]>;

    if (!!truePriceResults) {
      sortedResults = sortResults(this.props.latestResults);

      const nextManOut = truePriceResults[1].playerStats.get(
        CalculatedStatisticType.NEXT_MAN_OUT
      );
      if (!!nextManOut) {
        nextManOut.forEach((occurrences, playerId) => {
          const scenarioValues: ReactNode[] = [];
          const comparedValues: ReactNode[] = [];
          let allScenarioTotalOccurrences = 0;

          sortedResults.forEach(([scenario, simulationResult], scenarioId) => {
            const currentProbability = this.getProbability(
              simulationResult,
              playerId
            );
            scenarioValues.push(
              this.buildTableCell(scenario, playerId, currentProbability, null)
            );
            allScenarioTotalOccurrences += simulationResult.playerStats
              .get(CalculatedStatisticType.NEXT_MAN_OUT)
              .get(playerId);

            const comparedResults =
              !!this.props.comparedResults &&
              findScenarioResultsFromMap(
                scenario,
                this.props.comparedResults
              )[1];
            const comparedProbability = this.getProbability(
              comparedResults,
              playerId
            );
            comparedValues.push(
              this.buildTableCell(
                scenario,
                playerId,
                comparedProbability,
                currentProbability
              )
            );
            allScenarioTotalOccurrences +=
              !!comparedResults &&
              comparedResults.playerStats
                .get(CalculatedStatisticType.NEXT_MAN_OUT)
                .get(playerId);
          });

          const nameInSquad1: string = services.teamPlayerService.getPlayerName(
            UUID.fromString(playerId),
            this.props.squad1
          );
          const nameInSquad2: string = services.teamPlayerService.getPlayerName(
            UUID.fromString(playerId),
            this.props.squad2
          );
          if (allScenarioTotalOccurrences > 0) {
            rows.push(
              <TableRow key={`next-man-out-${playerId}`}>
                <TableCell scope="row" id={`simulation-table-cell`}>
                  {nameInSquad1 === "Unknown" ? nameInSquad2 : nameInSquad1}
                </TableCell>
                {scenarioValues}
              </TableRow>
            );

            this.props.comparedResults.size > 0 &&
              rows.push(
                <TableRow key={`next-man-out-${playerId}-comparison`}>
                  <TableCell
                    scope="row"
                    id={`simulation-table-cell`}
                    style={comparisonCellStyle}
                  >
                    {nameInSquad1 === "Unknown" ? nameInSquad2 : nameInSquad1}
                  </TableCell>
                  {comparedValues}
                </TableRow>
              );
          }
        });
      }
    }

    const sortedRows: ReactNode[] = sortRowsByColumnNumber(
      rows,
      this.state.sortColumn,
      this.state.sortDirection
    );

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Next Man Out
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text="Simulating..."
            >
              <TableContainer>
                <Table
                  aria-label="simulator next man out table"
                  size="small"
                  stickyHeader
                >
                  <TableHead>
                    {sortedResults && (
                      <OddsTableHeaderRow
                        sortedResults={sortedResults}
                        truePriceResults={truePriceResults[1]}
                        team1={this.props.team1}
                        team2={this.props.team2}
                        title={"Player"}
                        tableName={"next-man-out"}
                        enableSort={true}
                        sortColumn={this.state.sortColumn}
                        sortDirection={this.state.sortDirection}
                        sortFunction={(column, direction) =>
                          this.sort(column, direction)
                        }
                      />
                    )}
                  </TableHead>
                  <TableBody>{sortedRows}</TableBody>
                </Table>
              </TableContainer>
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
